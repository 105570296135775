import { IShapeSnapShot, WhiteBoardShape } from '../shapes/shape';
import { ShapeManager } from '../shapes/shape-manager';
import { BehaviorSubject } from 'rxjs';
import { ShapeTypes } from '../classes/white-board';

export enum SnapshotActon {
  addShape = 'addShape',
  editShape = 'editShape',
  removeShape = 'removeShape',
}

export interface ISnapshot {
  shape: WhiteBoardShape;
  snapshot: IShapeSnapShot;
  snapshotActon: SnapshotActon;
  multiId: string | null;
}

/*
 * Хранитель снепшотов
 */
export class MemoShaper {
  private MAX_SNAPSHOTS = 20;
  private snapshots: ISnapshot[] = [];
  private snapshotsRedu: ISnapshot[] = [];
  private _shapeManager: ShapeManager;
  readonly redoDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly undoDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(shapeManager: ShapeManager) {
    this._shapeManager = shapeManager;
  }

  /*
   * Добавить снепшот
   */
  addSnapshot(
    shape: WhiteBoardShape,
    snapshot: IShapeSnapShot,
    snapshotActon: SnapshotActon,
    clearRedo = true,
    multiId: string = null,
  ) {
    let currentAction = snapshotActon;
    let shapeAdded = true;

    if (this.snapshots.length >= this.MAX_SNAPSHOTS) {
      this.snapshots.shift();
    }

    if (currentAction === SnapshotActon.editShape || currentAction === SnapshotActon.removeShape) {
      shapeAdded = this.checkAdding(this.snapshots, shape);
    }

    if (!shapeAdded && shape.konvajsShape.attrs.type === ShapeTypes.text) {
      currentAction = snapshotActon === SnapshotActon.editShape ? SnapshotActon.addShape : snapshotActon;
      shapeAdded = snapshotActon === SnapshotActon.editShape;
    }

    if (!shapeAdded) {
      return;
    }

    this.snapshots.push({ shape: shape, snapshot: snapshot, snapshotActon: currentAction, multiId });
    if (clearRedo) {
      this.snapshotsRedu = [];
    }
    this.undoDisabled$.next(false);
  }

  checkAdding(snapshots: ISnapshot[], shape: WhiteBoardShape): boolean {
    if (snapshots.length > 0) {
      return snapshots?.some(snapshot => snapshot.shape.id === shape.id && snapshot.snapshotActon === 'addShape');
    }
    return this._shapeManager.shapes.get(shape.id).konvajsShape.attrs.text !== '';
  }

  /*
   * Найти последний снепшот и вызвать отмену дейсвтия
   */
  undo() {
    if (!this.snapshots.length) {
      this.undoDisabled$.next(true);
      return;
    }
    const lastIndex = this.snapshots.length - 1;
    const last = this.snapshots[lastIndex];
    if (last.multiId) {
      const items = this.snapshots.filter(x => x.multiId === last.multiId);
      items.forEach(x => {
        this.undoItem(x);
      });
    } else {
      this.undoItem(last);
    }
    this.undoDisabled$.next(this.snapshots.length === 0);
    this.redoDisabled$.next(this.snapshotsRedu.length === 0);
  }

  private undoItem(item: {
    shape: WhiteBoardShape;
    snapshot: IShapeSnapShot;
    snapshotActon: SnapshotActon;
    multiId: string;
  }) {
    if (item.snapshotActon === SnapshotActon.editShape) {
      this.snapshotsRedu.push({
        shape: item.shape,
        snapshot: item.shape.createHistorySnapshot(),
        snapshotActon: SnapshotActon.editShape,
        multiId: item.multiId,
      });
      item.shape.restoreHistorySnapshot(item.snapshot);
    }
    if (item.snapshotActon === SnapshotActon.addShape) {
      this.snapshotsRedu.push({
        shape: item.shape,
        snapshot: item.shape.createHistorySnapshot(),
        snapshotActon: SnapshotActon.removeShape,
        multiId: item.multiId,
      });
      this._shapeManager.removeShape(item.shape, true, false);
    }
    if (item.snapshotActon === SnapshotActon.removeShape) {
      this.snapshotsRedu.push({
        shape: item.shape,
        snapshot: item.shape.createHistorySnapshot(),
        snapshotActon: SnapshotActon.addShape,
        multiId: item.multiId,
      });
      this._shapeManager.restoreShape(item.shape, item.snapshot, false);
      item.shape.restoreHistorySnapshot(item.snapshot);
    }
    const index = this.snapshots.indexOf(item);
    this.snapshots.splice(index, 1);
  }

  redo() {
    if (!this.snapshotsRedu.length) {
      this.redoDisabled$.next(true);
      return;
    }

    const lastIndex = this.snapshotsRedu.length - 1;
    const last = this.snapshotsRedu[lastIndex];

    if (last.multiId) {
      const items = this.snapshotsRedu.filter(x => x.multiId === last.multiId);
      items.forEach(x => {
        this.redoItem(x);
      });
    } else {
      this.redoItem(last);
    }
    this.redoDisabled$.next(this.snapshotsRedu.length === 0);
  }

  private redoItem(item: {
    shape: WhiteBoardShape;
    snapshot: IShapeSnapShot;
    snapshotActon: SnapshotActon;
    multiId: string;
  }) {
    if (item.snapshotActon === SnapshotActon.editShape) {
      this.snapshots.push({
        shape: item.shape,
        snapshot: item.shape.createHistorySnapshot(),
        snapshotActon: SnapshotActon.editShape,
        multiId: item.multiId,
      });
      item.shape.restoreHistorySnapshot(item.snapshot);
    }

    if (item.snapshotActon === SnapshotActon.removeShape) {
      this._shapeManager.restoreShape(item.shape, item.snapshot);
    }
    if (item.snapshotActon === SnapshotActon.addShape) {
      this._shapeManager.removeShape(item.shape);
    }
    const index = this.snapshotsRedu.indexOf(item);
    this.snapshotsRedu.splice(index, 1);
  }

  updateShape(shape: WhiteBoardShape) {
    const snapshots = this.snapshots.filter(x => x.shape.id === shape.id);
    for (let i = 0; i < snapshots.length; i++) {
      snapshots[i].shape = shape;
    }

    const redosnapshots = this.snapshotsRedu.filter(x => x.shape.id === shape.id);
    for (let i = 0; i < redosnapshots.length; i++) {
      redosnapshots[i].shape = shape;
    }
  }
}
