<div class="modal_header">
  <div class="modal_header__text">
    {{ 'Для того чтобы начать событие необходимо привязать банковскую карту в личном кабинете' | transloco }}!
  </div>
</div>
<div class="modal__content">
  <div class="modal__actions">
    <a class="btn btn--blue btn--middle">
      <span
        class="btn__label"
        routerLink="/profile/cards"
        (click)="add()"
      >{{ 'Добавить новую карту' | transloco }}</span>
    </a>
    <a
      class="btn btn--red btn--middle"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Закрыть' | transloco }} </span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>
