import { Injectable } from '@angular/core';
import { getDataUrlFromImage } from '@ezteach/utils';

/**
 * @description Service used to cache images
 */
@Injectable({ providedIn: 'root' })
export class CachedImagesRegistryService {
  private _registry = new Map<string, string>();
  constructor() {}

  public addImageToRegistry(img: HTMLImageElement, path: string) {
    const dataUrl = getDataUrlFromImage(img);
    this._registry.set(path, dataUrl);
  }

  public getImageFromRegistry(path: string) {
    return this._registry.get(path);
  }

  public removeImageFromRegistry(path: string) {
    return this._registry.delete(path);
  }
}
