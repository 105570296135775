import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://84caab2d46cd4b2b8ed5ff9cb374d6b6@sentry.easy-teach.ru/7',
  integrations: [
    new TracingIntegrations.BrowserTracing({
      tracingOrigins: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/sampling/#setting-a-sampling-function
  tracesSampleRate: environment.production ? 0.1 : 0.7,
  environment: environment.name,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
