<div
  id="chat-room"
  class="widget-call-chat"
  #chatWrapper
  [ngClass]="{ 'mobile-chat-is-open': isMobile, 'mobile-landscape': orientationLandscape, 'textAreaIsFocused': textAreaIsFocused }"
>


  <div
    class="widget-call-chat-header"
    [class.isMobile]="isMobile"
  >
    <div
      class="widget-call-chat_closeBtn"
      (click)="close()"
      *ngIf="isGroupLessonChat"
    ><img src="assets/img/svg/group-lesson-next/icon-close.svg" /></div>

    <div
      *ngIf="currentDate"
      class="widget-call-chat-date"
    ><span class="date">{{ currentDate }}</span></div>
  </div>


  <app-pull-to-refresh [scrollableElement]="messageListWrapper">
    <div
      class="widget-call-chat_msgs"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollUpDistance]="1"
      [infiniteScrollThrottle]="0"
      [class.isMobile]="isMobile"
      [immediateCheck]="true"
      (scrolledUp)="onScrollMessageListUp()"
      #messageListWrapper
    >
      <ng-container *ngFor="let message of messageList$ | async">
        <ng-template *ngTemplateOutlet="msgTemplate; context: { ctxItem: message, isUnreadMsg: false }"></ng-template>
      </ng-container>


      <div
        class="new-messages-divider"
        *ngIf="unreadMessageList$.value.length > 0"
      >
        {{'Новые сообщения' | transloco}}
      </div>

      <ng-container *ngFor="let message of unreadMessageList$ | async">
        <ng-container *ngIf="!message.isTyping">
          <ng-template *ngTemplateOutlet="msgTemplate; context: { ctxItem: message, isUnreadMsg: true }"></ng-template>
        </ng-container>
      </ng-container>
    </div>
  </app-pull-to-refresh>



  <div
    class="widget-call-chat-footer"
    [class.isMobile]="isMobile"
    #footerWrapper
  >
    <div class="widget-call-chat-footer_inner">
      <div
        class="widget-call-chat-footer__files"
        *ngIf="filesToUpload?.length"
      >
        <div
          class="widget-call-chat-footer__file"
          *ngFor="let file of filesToUpload;let index = index"
        >
          <div
            class="widget-call-chat-footer__file-name"
            title="{{file.name}}"
          >
            {{ file.name }}
          </div>


          <div
            class="widget-call-chat-footer__file-remove"
            (click)="removeFile(index)"
          ><img src="assets/img/svg/group-lesson-next/icon-close.svg" /></div>
        </div>
      </div>

      <div
        *ngIf="isSending"
        class="new-message-block__bar"
      >
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>

      <textarea
        matInput
        #textAreaInput
        class="widget-call-chat-footer_input"
        [(ngModel)]="newMessageText"
        (ngModelChange)="type()"
        [placeholder]="'Введите сообщение...' | transloco"
        (keydown.enter)="onKeyDown($event)"
        (focus)="fixMobileMargin($event, true)"
        (blur)="fixMobileMargin($event, false)"
        cdkTextareaAutosize
        aria-multiline="true"
        rows="1"
        autocorrect="off"
        style="height: 25px;"
        aria-autocomplete="list"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        maxlength="1024"
      ></textarea>




      <div class="widget-call-chat-footer_buttons">
        <app-file-input
          accept=".pdf, .doc, .docx, .xls, .xlsx, .pptx, .ppt, .pages, image/*"
          class="widget-call-chat-footer_btn"
          [dropZone]="chatWrapper"
          (select)="onFileSelect($event)"
        >
          <img src="assets/img/svg/group-lesson-next/widget-call-chat-footer_attachment.svg" />
        </app-file-input>

        <div class="widget-call-chat-footer_btn"><img
            src="assets/img/svg/group-lesson-next/widget-call-chat-footer_emoticon.svg"
          /></div>
        <app-file-input
          accept="image/*"
          class="widget-call-chat-footer_btn"
          (select)="onFileSelect($event)"
        ><img src="assets/img/svg/group-lesson-next/widget-call-chat-footer_image.svg" /></app-file-input>

        <button
          class="widget-call-chat-footer_btn widget-call-chat-footer_send"
          (click)="send($event)"
          (mousedown)="$event.preventDefault()"
        >
          <img src="assets/img/svg/group-lesson-next/widget-call-chat-footer_send.svg" />
        </button>
      </div>
    </div>
  </div>
</div>


<ng-template
  #msgTemplate
  let-msg="ctxItem"
>
  <div
    class="msg"
    [class.msg-right]="!msg.isSelf && !msg.isTyping"
  >
    <div class="msg-avatar">
      <span *ngIf="!msg.authorAvatar">{{ msg.authorInitials }}</span>
      <img
        [src]="msg.authorAvatar"
        alt=""
        *ngIf="msg.authorAvatar"
      />
    </div>
    <div
      class="msg_content-wrap"
      [class.--isTyping]="msg.isTyping"
    >
      <div class="msg_content-header">
        <span class="msg_content-name">{{ msg.authorName }}</span>
        <div class="msg_content-time">{{ msg.time }}</div>
      </div>
      <div class="msg_content">
        <div
          class="msg_content-text"
          *ngIf="msg.content"
          [innerHTML]="msg.content | linkify"
        ></div>
        <ng-container *ngIf="msg.isTyping && !msg.isUnreadMsg">
          <ng-template *ngTemplateOutlet="typingContent"></ng-template>
        </ng-container>
        <div class="msg_content-attachments">
          <ezteach-group-lesson-chat-file
            *ngFor="let attach of msg.attachments"
            [attachment]="attach"
            (onAttachmentDownLoad)="download($event)"
          ></ezteach-group-lesson-chat-file>
        </div>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #typingContent>
  <div class="msg_content-text --isTyping">
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="7"
        r="2"
        fill="white"
      />
      <circle
        cx="8"
        cy="7"
        r="2"
        fill="white"
      />
      <circle
        cx="14"
        cy="7"
        r="2"
        fill="white"
      />
    </svg>
  </div>
</ng-template>