import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';
import { AdminService } from './services/admin.service';
import { BookmarksService } from './services/bookmarks.service';
import { ChatService } from './services/chat.service';
import { DictionariesService } from './services/dictionaries.service';
import { FilesService } from './services/files.service';
import { LandingService } from './services/landing.service';
import { LessonsService } from './services/lessons.service';
import { LookupService } from './services/lookup.service';
import { NotificationsService } from './services/notifications.service';
import { SearchService } from './services/search.service';
import { SystemService } from './services/system.service';
import { UsersService } from './services/users.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminService,
    BookmarksService,
    ChatService,
    DictionariesService,
    FilesService,
    LandingService,
    LessonsService,
    LookupService,
    NotificationsService,
    SearchService,
    SystemService,
    UsersService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
