import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

export interface CustomSnackBarData {
  closeButtonText?: string;
  avatarSrc?: string;
  contentText: string;
}
export interface CustomSnackBarMessageConfig {
  duration?: number;
  containerClass?: string;
  avatarClass?: string;
  contentClass?: string;
  buttonClass?: string;
}

export interface CustomSnackBarMessageOpenInfo {
  opened?: Date;
}

export const SNACK_BAR_MESSAGE_CONFIG: CustomSnackBarMessageConfig = {
  duration: 5000,
};

export type CustomSnackBarDataConfig = CustomSnackBarData & CustomSnackBarMessageOpenInfo & CustomSnackBarMessageConfig;

export interface CustomSnackBarResult {
  closed: CustomSnackBarData;
  all: number;
  opened: number;
}

export interface CustomSnackBarItemInfo {
  avatarSrc?: SafeResourceUrl;
  contentText: SafeHtml;
  data: CustomSnackBarData;
  config: CustomSnackBarMessageConfig;
}

export function isEqualSnackBarData(a: CustomSnackBarData, b: CustomSnackBarData): boolean {
  return a.closeButtonText === b.closeButtonText && a.avatarSrc === b.avatarSrc && a.contentText === b.contentText;
}

export function trackBySnackBarData(index: number, item: CustomSnackBarData): string {
  return `${item.closeButtonText}${item.avatarSrc}${item.contentText}`;
}

export function trackBySnackBarItemInfo(index: number, item: CustomSnackBarItemInfo): string {
  return trackBySnackBarData(index, item.data);
}
