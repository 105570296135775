import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShapeTextAttrsConfig } from '../../shapes/shape-attrs-config';
import { FormatCommands, TextShapeToolsFormatter } from './text-shape-tools-formatter';
import { FONT_FAMILIES, FONT_SIZES, makeFontSize } from './text-shape-tools.constants';

@UntilDestroy()
@Component({
  selector: 'ezteach-text-shape-tools',
  templateUrl: './text-shape-tools.component.html',
  styleUrls: ['./text-shape-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextShapeToolsComponent implements OnChanges {
  readonly fontFamilyControl = new FormControl();
  readonly fontSizeControl = new FormControl();
  readonly fontFamilies = FONT_FAMILIES;
  readonly fontSizes = FONT_SIZES;
  readonly compareBy = compareBy;
  readonly compareById = compareById;
  @Input() attrs: ShapeTextAttrsConfig = {};
  @Output() attrsChanged = new EventEmitter<ShapeTextAttrsConfig>();

  constructor(readonly cdr: ChangeDetectorRef, private readonly formatter: TextShapeToolsFormatter) {
    this.subscribeControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { attrs } = changes;
    if (attrs) {
      const { fontFamily, fontSize } = attrs.currentValue as ShapeTextAttrsConfig;
      if (fontFamily) this.fontFamilyControl.patchValue(fontFamily, { emitEvent: false });
      if (fontSize) this.fontSizeControl.patchValue(makeFontSize(fontSize), { emitEvent: false });
      this.formatter.setAttrs(this.attrs);
    }
  }

  setEditor(element: HTMLElement) {
    this.formatter.setAttrs(this.attrs);
  }

  isFormat(command: FormatCommands): boolean {
    return this.formatter.isFormat(command);
  }

  formatDoc(command: FormatCommands, value?: string): boolean {
    this.formatter.setAttrs(this.attrs);
    const attrs = this.formatter.formatDoc(command, value);
    this.attrsChanged.next(attrs);
    return true;
  }

  updateMenu(): void {}

  private subscribeControls() {
    this.fontFamilyControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.formatDoc('fontName', value));
    this.fontSizeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.formatDoc('fontSize', value.id));
  }
}

function compareBy(a1: unknown, a2: unknown): boolean {
  return a1 && a2 && a1 === a2;
}

function compareById(a1: { id: unknown }, a2: { id: unknown }): boolean {
  return a1 && a2 && a1.id === a2.id;
}
