import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatRoomStatistics } from '@ezteach/api/models';
import { ChatService } from '../../api/services/chat.service';
import { ModalMessagesFiles } from '../../modals/messages-files/modal-messages-files.component';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'messages_top',
  templateUrl: './messages_top.component.html',
})
export class MessagesTopComponent {
  @Input() roomId;
  @Input() roomUser;
  @Input() stat?: ChatRoomStatistics;
  dialogRef;
  userdata;

  constructor(
    public dialog: MatDialog,
    private chatService: ChatService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
  }

  openModal(type) {
    this.chatService
      .apiV1ChatChatRoomIdItemsGet({
        chatRoomId: this.roomId,
        itemType: type,
      })
      .subscribe(response => {
        let className = 'modal-messages-files';
        if (response.data.groupTypeId === 'Images') {
          className = 'modal-messages-images';
        }

        this.dialogRef = this.dialog.open(ModalMessagesFiles, {
          panelClass: className,
          data: {
            roomId: this.roomId,
            items: response.data,
          },
        });
      });
  }

  getStat() {
    this.chatService
      .apiV1ChatChatRoomIdStatsGet({
        chatRoomId: this.roomId,
      })
      .subscribe(request => {
        this.stat = request.data;
      });
  }
}
