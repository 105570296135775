<div
  class="message__wrap"
  *ngFor="let item of unreadMessages; let i = index"
>
  <div
    class="message__date"
    *ngIf="showDate(item, unreadMessages[i + 1])"
  >
    <span>{{ date(item) }}</span>
  </div>
  <div
    class="message"
    [class]="{ my: item.sentByUser.id === userdata.id }"
  >
    <div class="message__image">
      <a
        routerLink="/teachers/{{ item.sentByUser.id }}"
        *ngIf="item?.sentByUser?.isTutor; else showStudentImage"
      >
        <img
          *ngIf="item.sentByUser | userAvatar; else noUserImage"
          src="{{ item.sentByUser | userAvatar }}"
        />
      </a>
      <ng-template #showStudentImage>
        <img
          *ngIf="item.sentByUser | userAvatar; else noUserImage"
          src="{{ item.sentByUser | userAvatar }}"
        />
      </ng-template>
      <ng-template #noUserImage>
        <span>{{ item.sentByUser | userInitials }}</span>
      </ng-template>
    </div>
    <div class="message__author">
      <a
        routerLink="/teachers/{{ item.sentByUser.id }}"
        *ngIf="item.sentByUser.isTutor; else showStudentName"
      >
        <span class="name">{{ item.sentByUser?.name }}</span>
      </a>
      <ng-template #showStudentName>
        <span class="name">{{ item.sentByUser?.name }}</span>
      </ng-template>
      <span class="time">{{ getMessageTime(item) }}</span>
    </div>
    <div class="message__text">
      <div
        class="text"
        [innerHTML]="item.messageText | linkify"
      ></div>
      <div
        class="message__text__item"
        *ngFor="let file of item.attachments"
      >
        <div
          *ngIf="file.attachmentTypeId === 'Picture'"
          (click)="openImage(imageTemplate)"
          class="image"
        >
          <img
            *ngIf="file.previewFileName || file.fileName; else noPreviewFile"
            [src]="preview(file)"
          />
          <ng-template #noPreviewFile>
            <div class="message__nopreview">
              <span class="message__nopreview__text">{{ 'предпросмотр недоступен' | transloco }}</span>
            </div>
          </ng-template>

          <ng-template #imageTemplate>
            <div class="modal_header">
              <div class="modal_header__text">{{ 'Изображение' | transloco }}</div>
            </div>
            <div class="modal__content">
              <img
                [src]="link(file)"
                style="max-width: calc(100vw - 160px); max-height: calc(100vh - 320px)"
              />
              <div class="modal__actions">
                <button
                  class="btn btn--blue-border btn--middle"
                  (click)="closeImage()"
                >
                  <span class="btn__label">{{ 'Закрыть' | transloco }}</span>
                </button>
              </div>
              <button
                class="modal__close"
                (click)="closeImage()"
              >
                <img src="/assets/img/svg/icon-close.svg" />
              </button>
            </div>
          </ng-template>
        </div>
        <a
          *ngIf="file.attachmentTypeId !== 'Picture'"
          [href]="link(file)"
          class="file"
          target="_blank"
        >
          <span class="file__icon">
            <img src="/assets/img/svg/icon-file.svg" />
          </span>
          <span class="file__info">
            <span class="file__name">{{ file.sourceFileName }}</span>
            <span class="file__size">{{ file.fileSize | fileSize }}</span>
          </span>
          <span class="file__cloud">
            <img src="/assets/img/svg/icon-cloud.svg" />
          </span>
        </a>
      </div>
    </div>
  </div>
</div>

<div
  class="unread-messages-divider"
  *ngIf="unreadMessages?.length > 0"
>
  {{ 'Непрочитанные сообщения' | transloco }}
</div>

<div
  class="message__wrap"
  *ngFor="let item of messages; let i = index"
>
  <div
    class="message__date"
    *ngIf="showDate(item, messages[i + 1])"
  >
    <span>{{ date(item) }}</span>
  </div>
  <div
    class="message"
    [class]="{ my: item.sentByUser.id === userdata.id }"
  >
    <div class="message__image">
      <a
        routerLink="/teachers/{{ item.sentByUser.id }}"
        *ngIf="item?.sentByUser?.isTutor; else showStudentImage"
      >
        <img
          *ngIf="item.sentByUser | userAvatar; else noUserImage"
          src="{{ item.sentByUser | userAvatar }}"
        />
      </a>
      <ng-template #showStudentImage>
        <img
          *ngIf="item.sentByUser | userAvatar; else noUserImage"
          src="{{ item.sentByUser | userAvatar }}"
        />
      </ng-template>
      <ng-template #noUserImage>
        <span>{{ item.sentByUser | userInitials }}</span>
      </ng-template>
    </div>
    <div class="message__author">
      <a
        routerLink="/teachers/{{ item.sentByUser.id }}"
        *ngIf="item.sentByUser.isTutor; else showStudentName"
      >
        <span class="name">{{ item.sentByUser?.name }}</span>
      </a>
      <ng-template #showStudentName>
        <span class="name">{{ item.sentByUser?.name }}</span>
      </ng-template>
      <span class="time">{{ getMessageTime(item) }}</span>
    </div>
    <div class="message__text">
      <div
        class="text"
        [innerHTML]="item.messageText | linkify"
      ></div>
      <div
        class="message__text__item"
        *ngFor="let file of item.attachments"
      >
        <div
          *ngIf="file.attachmentTypeId === 'Picture'"
          (click)="openImage(imageTemplate)"
          class="image"
        >
          <img
            *ngIf="file.previewFileName || file.fileName; else noPreviewFile"
            [src]="preview(file)"
          />
          <ng-template #noPreviewFile>
            <div class="message__nopreview">
              <span class="message__nopreview__text">{{ 'предпросмотр недоступен' | transloco }}</span>
            </div>
          </ng-template>

          <ng-template #imageTemplate>
            <div class="modal_header">
              <div class="modal_header__text">{{ 'Изображение' | transloco }}</div>
            </div>
            <div class="modal__content">
              <img
                [src]="link(file)"
                style="max-width: calc(100vw - 160px); max-height: calc(100vh - 320px)"
              />
              <div class="modal__actions">
                <button
                  class="btn btn--blue-border btn--middle"
                  (click)="closeImage()"
                >
                  <span class="btn__label">{{ 'Закрыть' | transloco }}</span>
                </button>
              </div>
              <button
                class="modal__close"
                (click)="closeImage()"
              >
                <img src="/assets/img/svg/icon-close.svg" />
              </button>
            </div>
          </ng-template>
        </div>
        <a
          *ngIf="file.attachmentTypeId !== 'Picture'"
          [href]="link(file)"
          class="file"
          target="_blank"
        >
          <span class="file__icon">
            <img src="/assets/img/svg/icon-file.svg" />
          </span>
          <span class="file__info">
            <span class="file__name">{{ file.sourceFileName }}</span>
            <span class="file__size">{{ file.fileSize | fileSize }}</span>
          </span>
          <span class="file__cloud">
            <img src="/assets/img/svg/icon-cloud.svg" />
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
<div
  class="message__wrap"
  *ngIf="messages?.length > 0"
>
  <div class="message__date">
    <span>{{ date(messages[messages.length - 1]) }}</span>
  </div>
</div>