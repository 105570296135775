/* Contains codes for different validations */
import { fromEnum } from '@ezteach/utils';
import * as T from 'io-ts';
import {
  DefaultSteps,
  ITutorialData,
  OnboardingStudentSteps,
  OnboardingTeacherSteps,
  ProfileSteps,
  TutorialTypes,
} from './models';
/* Tutorial types codec */
export const tutorialTypesCodec = fromEnum<TutorialTypes>('TutorialTypes', TutorialTypes);
/* Tutorial steps codec */
export const tutorialStepsCodec = T.union(
  [
    fromEnum<OnboardingTeacherSteps>('OnboardingTeacherSteps', OnboardingTeacherSteps),
    fromEnum<OnboardingStudentSteps>('OnboardingStudentSteps', OnboardingStudentSteps),
    fromEnum<ProfileSteps>('ProfileSteps', ProfileSteps),
    fromEnum<DefaultSteps>('DefaultSteps', DefaultSteps),
  ],
  'TutorialSteps',
);
/* Tutorial + step codec */
export const tutorialWithStepCodec = T.tuple([tutorialTypesCodec, tutorialStepsCodec], 'TutorialWithStepCodec');
export const tutorialsWithStepCodecArray = T.array(tutorialWithStepCodec, 'TutorialWithStepCodecArray');
/* Api response codec */
export const tutorialDataCodec: T.Type<ITutorialData> = T.type(
  {
    type: tutorialTypesCodec,
    progress: tutorialStepsCodec,
  },
  'TutorialDataCodec',
);
