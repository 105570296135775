import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';

@Component({
  selector: 'ezteach-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})
export class InfoPopupComponent implements OnInit {
  isMobile = false;
  @Input() hasCloseBtn = false;
  @Output() onCloseBtnClick = new EventEmitter();
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  constructor() {}

  ngOnInit(): void {
    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
    });
  }

  onClose() {
    this.onCloseBtnClick.emit();
  }
}
