import * as OnboardingTeacherComponents from '@ezteach/tutorial/components/onboarding-teacher';
import { ITutorialStepConfig, OnboardingTeacherSteps, StepType } from '@ezteach/tutorial/models';
import { ONBOARDING_TEACHER_FINISH_STEP_VALIDATOR } from '../validators';

export const ONBOARDING_TEACHER_STEPS_LIST: readonly ITutorialStepConfig<OnboardingTeacherSteps>[] = [
  {
    name: OnboardingTeacherSteps.WELCOME,
    type: StepType.DIALOG,
    component: OnboardingTeacherComponents.Step0WelcomeDialogComponent,
  },
  {
    name: OnboardingTeacherSteps.STEP1_1,
    type: StepType.TOOLTIP,
    component: OnboardingTeacherComponents.Step1_1TooltipComponent,
  },
  {
    name: OnboardingTeacherSteps.STEP1_2,
    type: StepType.TOOLTIP,
    component: OnboardingTeacherComponents.Step1_2TooltipComponent,
  },
  {
    name: OnboardingTeacherSteps.STEP2,
    type: StepType.TOOLTIP,
    component: OnboardingTeacherComponents.Step2TooltipComponent,
  },
  {
    name: OnboardingTeacherSteps.STEP3,
    type: StepType.TOOLTIP,
    component: OnboardingTeacherComponents.Step3TooltipComponent,
  },
  {
    name: OnboardingTeacherSteps.FINISH,
    type: StepType.DIALOG,
    isLast: true,
    component: OnboardingTeacherComponents.Step4FinishDialogComponent,
    withValidator: ONBOARDING_TEACHER_FINISH_STEP_VALIDATOR,
  },
];
