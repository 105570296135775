import { NgModule } from '@angular/core';
import { CustomSnackBarComponent } from '@ezteach/_components/custom-snackbar/component/custom-snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CustomSnackBarComponent],
  imports: [MatSnackBarModule, CommonModule, MatButtonModule],
})
export class CustomSnackBarModule {}
