import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DictionariesService } from '../../api/services/dictionaries.service';

@UntilDestroy()
@Component({
  selector: 'form-search-instant',
  templateUrl: './instant.component.html',
})
export class FormSearchInstantComponent {
  disciplinesControl = new UntypedFormControl();
  disciplinesNames = [];
  disciplinesFiltered: Observable<string[]>;

  specializationsControl = new UntypedFormControl();
  specializationsNames = [];

  messageControl = new UntypedFormControl();
  messageCol = 140;
  messageValue = 0;
  specDefault = this.translocoService.translate('Выберите предмет');

  data;
  durations: { id: number; name: string }[] = [
    { id: 5, name: $localize`5 минут` },
    { id: 10, name: $localize`10 минут` },
    { id: 30, name: $localize`30 минут` },
  ];

  name: string;
  discipline: string;
  duration = 5;
  message: string;
  specialization;
  specializationGet;
  changed = false;
  autocompletePosition: 'auto' | 'above';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dictionariesService: DictionariesService,
    private translocoService: TranslocoService,
    private platform: Platform,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParamMap => {
      this.name = queryParamMap.get('name');
      this.discipline = queryParamMap.get('discipline');
      this.message = queryParamMap.get('message');
      this.specializationGet = queryParamMap.get('specialization');
      if (queryParamMap.get('duration')) {
        this.duration = parseInt(queryParamMap.get('duration'));
      }
    });

    this.getData();
    this.autocompletePosition = this.platform.ANDROID ? 'above' : 'auto';
  }

  getData() {
    this.dictionariesService.apiV1DictionariesDisciplineSpecializationsGet().subscribe(response => {
      this.data = response.data;
      this.disciplinesNames = this.data.map(value => this.translocoService.translate(value.discipline.name));
      this.disciplinesFiltered = this.disciplinesControl.valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        map(discipline => this._filter(discipline)),
      );
      if (this.discipline) {
        this.specializationsNames = this.data
          .filter(item => item.discipline.fullName.toLowerCase().indexOf(this.discipline.toLowerCase()) === 0)
          .map(item => item.specializations);
        if (this.specializationsNames.length > 0) {
          this.specializationsNames = this.specializationsNames[0];
        } else {
          this.specializationsNames = [];
        }
      }
      if (this.specializationGet) {
        this.specializationsControl.setValue(this.specializationGet * 1);
      }
      if (this.message) {
        this.messageValue = Math.round((this.message.length / 140) * 100);
        this.messageCol = 140 - this.message.length;
      }
    });
  }

  _filter(discipline: string): string[] {
    this.specializationsNames = [];
    this.specialization = null;
    this.specDefault = this.translocoService.translate('Выберите предмет');

    if (discipline.trim() != '') {
      const filterValue = discipline.toLowerCase();
      const data = this.disciplinesNames.filter(discipline => discipline.toLowerCase().indexOf(filterValue) === 0);

      return data;
    } else {
      return this.disciplinesNames.sort();
    }
  }

  onMessageChange(value) {
    this.messageValue = Math.round((value.length / 140) * 100);
    this.messageCol = 140 - value.length;
  }

  onDisciplineChange() {
    if (this.discipline != '') {
      this.specializationsNames = this.data
        .filter(
          item =>
            this.translocoService
              .translate(item.discipline.fullName)
              .toLowerCase()
              .indexOf(this.discipline.toLowerCase()) === 0,
        )
        .map(item => item.specializations);
      if (this.specializationsNames.length > 0) {
        this.specDefault = this.translocoService.translate('Любая цель');
        this.specializationsNames = this.specializationsNames[0];
      } else {
        this.specializationsNames = [];
      }
    }

    this.specialization = null;
  }

  onChange() {}

  onSubmit() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/search/instant'], {
        relativeTo: this.route,
        queryParams: {
          name: this.name ? this.name : null,
          discipline: this.discipline ? this.discipline : null,
          specialization: this.specialization ? this.specialization : null,
          duration: this.duration ? this.duration : null,
          message: this.message ? this.message : null,
        },
      }),
    );
  }
}
