import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ChatLesson } from '@ezteach/api/models';
import { LessonPaymentTypeEnum } from '@ezteach/api/models/lesson-format-enum';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'lessonPaymentInfo',
})
export class LessonPaymentInfo implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private translocoService: TranslocoService) { }
  transform(lesson: ChatLesson): string {
    return lesson.lessonPaymentType === LessonPaymentTypeEnum.Free
      ? this.translocoService.translate('Бесплатно')
      : this.currencyPipe.transform((lesson.calculatedPrice ?? 0).toString(), 'RUB ');
  }
}
