import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfile } from '@ezteach/api/models';
import { IResponse } from '@ezteach/api/models/response.model';
import { UserProfileValidationStatus } from '@ezteach/api/models/user-profile-validation-status';
import { RequestBuilder } from '@ezteach/api/request-builder';
import { environment } from '@ezteach/enviroments';
import * as dot from 'dot-object';
import { propOr } from 'ramda';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IProfileData } from '../models';
import { UserService } from "@ezteach/_services/user.service";

/* Profile api service */
@Injectable({ providedIn: 'root' })
export class ProfileApiService {
  private get baseUrl() {
    return `${environment.apiUrl}/api/v1`;
  }

  private get userId() {
    return propOr(null, 'id', this.userService.userData$.value);
  }

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService
  ) {}

  getProfile(): Observable<IProfileData> {
    return this.http.get<IProfileData>(`${this.baseUrl}/users/${this.userId}/profile`);
  }

  saveProfile(data: UserProfile): Observable<HttpResponse<any>> {
    const requestBuilder = new RequestBuilder(environment.apiUrl, `/api/v1/users/${data.userId}/profile`, 'put');
    requestBuilder.body(dot.dot(data), 'multipart/form-data');

    return this.http.request(requestBuilder.build({ responseType: 'json', accept: 'application/json' })).pipe(
      filter((response: any) => response instanceof HttpResponse),
      map(response => response as HttpResponse<any>),
    );
  }

  validateProfile(): Observable<IResponse<boolean>> {
    return this.http.get<IResponse<boolean>>(`${environment.apiUrl}/api/v1/users/${this.userId}/profile/validation`);
  }

  validationStatus(): Observable<IResponse<UserProfileValidationStatus>> {
    return this.http.get<IResponse<UserProfileValidationStatus>>(
      `${environment.apiUrl}/api/v1/users/${this.userId}/validation/status`,
    );
  }

  getTelegramAuthorizationPath() : Observable<IResponse<string>>{
		return this.http.get<IResponse<string>>(`${environment.apiUrl}/api/v1/socialnetwork/telegram/authorization`);
	}
}
