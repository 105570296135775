import { User, UserAvatar } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';

export function toUser(u: ChatLessonMember | User | UserAvatar): User | null {
  if (!u) {
    return null;
  }
  if (isUser(u)) {
    return u as User;
  }
  if (IsUserAvatar(u)) {
    const avatar = u as UserAvatar;
    return {
      id: avatar.userId,
      avatarFileName: avatar.avatarFileName,
    };
  }
  if ('user' in u) {
    return u.user;
  }
  return null;
}

export function isUser(u: object): boolean {
  if (!u) {
    return false;
  }
  return 'firstName' in u;
}

export function IsUserAvatar(u: object): boolean {
  if (!u) {
    return false;
  }
  return 'avatarFileName' in u && 'userId' in u;
}
