<div
  class="container"
  [class.has-value]="date"
>
  <input
    matInput
    [placeholder]="placeholder"
    class="input"
    (dateChange)="dateChanged($event)"
    [matDatepicker]="picker"
    [value]="date"
    (click)="openDatePicker()"
  >
  <mat-datepicker-toggle
    matIconSuffix
    class="btn"
    [for]="picker"
  >
    <img
      matDatepickerToggleIcon
      src="/assets/img/svg/datepicker-icon-calendar.svg"
    />
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <div
    (click)="clearDate()"
    class="clear"
    *ngIf="date"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_96_7388)">
        <line
          x1="14.2305"
          y1="5.84573"
          x2="5.74522"
          y2="14.331"
          stroke="#44444F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="bevel"
        />
        <line
          x1="5.74524"
          y1="5.66895"
          x2="14.2305"
          y2="14.1542"
          stroke="#44444F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="bevel"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_7388">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(9.89948 0.100464) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</div>