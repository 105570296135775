import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '../../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'modal-bankcards-remove',
  templateUrl: 'modal-bankcards-remove.component.html',
})
export class ModalBankcardsRemove {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalBankcardsRemove>,
    private usersService: UsersService,
    private userService: UserService
  ) {}

  removeBankcard(id) {
    const userdata = this.userService.userData$.value;
    this.loading = true;

    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete({
        userId: userdata.id,
        bankCardId: id,
      })
      .subscribe(result => {
        if (result.data) {
          location.reload();
        }
      });
  }

  close() {
    this.dialogRef.close('close');
  }
}
