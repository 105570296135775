import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { BaseApiResponse } from '../models/base-api-response';
import { ChatLessonReclamationStatusEnum } from '../models/chat-lesson-reclamation-status-enum';
import { ChatMessagePagedApiResponse } from '../models/chat-message-paged-api-response';
import { CommonValidationStatusEnum } from '../models/common-validation-status-enum';
import { LessonReclamationApiResponse } from '../models/lesson-reclamation-api-response';
import { LessonReclamationPagedApiResponse } from '../models/lesson-reclamation-paged-api-response';
import { StringApiResponse } from '../models/string-api-response';
import { UserApiResponse } from '../models/user-api-response';
import { UserDocumentApiResponse } from '../models/user-document-api-response';
import { UserStatusEnum } from '../models/user-status-enum';
import { UserWithCompleteInfoApiResponse } from '../models/user-with-complete-info-api-response';
import { UserWithCompleteInfoPagedApiResponse } from '../models/user-with-complete-info-paged-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AdminChatsChatRoomIdMessagesGet
   */
  static readonly ApiV1AdminChatsChatRoomIdMessagesGetPath = '/api/v1/admin/chats/{chatRoomId}/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminChatsChatRoomIdMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminChatsChatRoomIdMessagesGet$Response(params: {
    chatRoomId: number;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<ChatMessagePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminChatsChatRoomIdMessagesGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessagePagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminChatsChatRoomIdMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminChatsChatRoomIdMessagesGet(params: {
    chatRoomId: number;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<ChatMessagePagedApiResponse> {
    return this.apiV1AdminChatsChatRoomIdMessagesGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagePagedApiResponse>) => r.body as ChatMessagePagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet
   */
  static readonly ApiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGetPath =
    '/api/v1/admin/files/chat/messages/{messageId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet$Response(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AdminService.ApiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGetPath,
      'get',
    );
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<void> {
    return this.apiV1AdminFilesChatMessagesMessageIdAttachmentsAttachmentIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet
   */
  static readonly ApiV1AdminFilesUsercontentOwnerEntityIdContentTypeGetPath =
    '/api/v1/admin/files/usercontent/{ownerEntityId}/{contentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet$Response(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    file?: null | string;
    redirect?: boolean;
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AdminService.ApiV1AdminFilesUsercontentOwnerEntityIdContentTypeGetPath,
      'get',
    );
    if (params) {
      rb.path('ownerEntityId', params.ownerEntityId, {});
      rb.path('contentType', params.contentType, {});
      rb.query('file', params.file, {});
      rb.query('redirect', params.redirect, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    file?: null | string;
    redirect?: boolean;
  }): Observable<StringApiResponse> {
    return this.apiV1AdminFilesUsercontentOwnerEntityIdContentTypeGet$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet
   */
  static readonly ApiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGetPath =
    '/api/v1/admin/files/lessoncontent/{ownerEntityId}/{contentType}/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet$Response(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    userId: number;
    redirect?: boolean;
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AdminService.ApiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGetPath,
      'get',
    );
    if (params) {
      rb.path('ownerEntityId', params.ownerEntityId, {});
      rb.path('contentType', params.contentType, {});
      rb.path('userId', params.userId, {});
      rb.query('redirect', params.redirect, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    userId: number;
    redirect?: boolean;
  }): Observable<StringApiResponse> {
    return this.apiV1AdminFilesLessoncontentOwnerEntityIdContentTypeUserIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminLessonsReclamationsActiveGet
   */
  static readonly ApiV1AdminLessonsReclamationsActiveGetPath = '/api/v1/admin/lessons/reclamations/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminLessonsReclamationsActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminLessonsReclamationsActiveGet$Response(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<LessonReclamationPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminLessonsReclamationsActiveGetPath, 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<LessonReclamationPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminLessonsReclamationsActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminLessonsReclamationsActiveGet(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<LessonReclamationPagedApiResponse> {
    return this.apiV1AdminLessonsReclamationsActiveGet$Response(params).pipe(
      map((r: StrictHttpResponse<LessonReclamationPagedApiResponse>) => r.body as LessonReclamationPagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminLessonsReclamationsReclamationIdGet
   */
  static readonly ApiV1AdminLessonsReclamationsReclamationIdGetPath =
    '/api/v1/admin/lessons/reclamations/{reclamationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminLessonsReclamationsReclamationIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminLessonsReclamationsReclamationIdGet$Response(params: {
    reclamationId: number;
  }): Observable<StrictHttpResponse<LessonReclamationApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminLessonsReclamationsReclamationIdGetPath, 'get');
    if (params) {
      rb.path('reclamationId', params.reclamationId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<LessonReclamationApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminLessonsReclamationsReclamationIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminLessonsReclamationsReclamationIdGet(params: {
    reclamationId: number;
  }): Observable<LessonReclamationApiResponse> {
    return this.apiV1AdminLessonsReclamationsReclamationIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<LessonReclamationApiResponse>) => r.body as LessonReclamationApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminLessonsReclamationsPatch
   */
  static readonly ApiV1AdminLessonsReclamationsPatchPath = '/api/v1/admin/lessons/reclamations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminLessonsReclamationsPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AdminLessonsReclamationsPatch$Response(params?: {
    body?: { Id?: number; StatusId?: ChatLessonReclamationStatusEnum; ReplyMessageText?: string };
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminLessonsReclamationsPatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminLessonsReclamationsPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AdminLessonsReclamationsPatch(params?: {
    body?: { Id?: number; StatusId?: ChatLessonReclamationStatusEnum; ReplyMessageText?: string };
  }): Observable<BaseApiResponse> {
    return this.apiV1AdminLessonsReclamationsPatch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminUsersTutorsTovalidateGet
   */
  static readonly ApiV1AdminUsersTutorsTovalidateGetPath = '/api/v1/admin/users/tutors/tovalidate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUsersTutorsTovalidateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersTutorsTovalidateGet$Response(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<UserWithCompleteInfoPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUsersTutorsTovalidateGetPath, 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserWithCompleteInfoPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUsersTutorsTovalidateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersTutorsTovalidateGet(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<UserWithCompleteInfoPagedApiResponse> {
    return this.apiV1AdminUsersTutorsTovalidateGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserWithCompleteInfoPagedApiResponse>) => r.body as UserWithCompleteInfoPagedApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1AdminUsersUserIdStatusPatch
   */
  static readonly ApiV1AdminUsersUserIdStatusPatchPath = '/api/v1/admin/users/{userId}/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUsersUserIdStatusPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersUserIdStatusPatch$Response(params: {
    userId: number;
    statusId?: UserStatusEnum;
  }): Observable<StrictHttpResponse<UserApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUsersUserIdStatusPatchPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('statusId', params.statusId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUsersUserIdStatusPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersUserIdStatusPatch(params: { userId: number; statusId?: UserStatusEnum }): Observable<UserApiResponse> {
    return this.apiV1AdminUsersUserIdStatusPatch$Response(params).pipe(
      map((r: StrictHttpResponse<UserApiResponse>) => r.body as UserApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminUsersUserIdValidatetutorPatch
   */
  static readonly ApiV1AdminUsersUserIdValidatetutorPatchPath = '/api/v1/admin/users/{userId}/validatetutor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUsersUserIdValidatetutorPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersUserIdValidatetutorPatch$Response(params: {
    userId: number;
    statusId?: CommonValidationStatusEnum;
  }): Observable<StrictHttpResponse<UserApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUsersUserIdValidatetutorPatchPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('statusId', params.statusId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUsersUserIdValidatetutorPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersUserIdValidatetutorPatch(params: {
    userId: number;
    statusId?: CommonValidationStatusEnum;
  }): Observable<UserApiResponse> {
    return this.apiV1AdminUsersUserIdValidatetutorPatch$Response(params).pipe(
      map((r: StrictHttpResponse<UserApiResponse>) => r.body as UserApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminDocumentsUserDocumentIdValidatePatch
   */
  static readonly ApiV1AdminDocumentsUserDocumentIdValidatePatchPath =
    '/api/v1/admin/documents/{userDocumentId}/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminDocumentsUserDocumentIdValidatePatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminDocumentsUserDocumentIdValidatePatch$Response(params: {
    userDocumentId: number;
    validationStatusId?: CommonValidationStatusEnum;
  }): Observable<StrictHttpResponse<UserDocumentApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      AdminService.ApiV1AdminDocumentsUserDocumentIdValidatePatchPath,
      'patch',
    );
    if (params) {
      rb.path('userDocumentId', params.userDocumentId, {});
      rb.query('validationStatusId', params.validationStatusId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserDocumentApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminDocumentsUserDocumentIdValidatePatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminDocumentsUserDocumentIdValidatePatch(params: {
    userDocumentId: number;
    validationStatusId?: CommonValidationStatusEnum;
  }): Observable<UserDocumentApiResponse> {
    return this.apiV1AdminDocumentsUserDocumentIdValidatePatch$Response(params).pipe(
      map((r: StrictHttpResponse<UserDocumentApiResponse>) => r.body as UserDocumentApiResponse),
    );
  }

  /**
   * Path part for operation apiV1AdminUsersTutorsUserIdGet
   */
  static readonly ApiV1AdminUsersTutorsUserIdGetPath = '/api/v1/admin/users/tutors/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUsersTutorsUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersTutorsUserIdGet$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<UserWithCompleteInfoApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUsersTutorsUserIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserWithCompleteInfoApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUsersTutorsUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUsersTutorsUserIdGet(params: { userId: number }): Observable<UserWithCompleteInfoApiResponse> {
    return this.apiV1AdminUsersTutorsUserIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserWithCompleteInfoApiResponse>) => r.body as UserWithCompleteInfoApiResponse),
    );
  }
}
