import { ShapeTypes } from '../classes/white-board';
import { ShapeTextAttrsConfig } from './shape-attrs-config';
import {
  ElllipseFactory,
  FileFactory,
  FreeLineFactory,
  ImageFactory,
  RectFactory,
  ShapeFactory,
  StickerFactory,
  TextFieldFactory,
  TriangleFactory,
} from './shape-factory';

/*
 * Билдер создания фабрики конкретного объектка формы
 */
export class ShapeFactoryBuilder {
  private radius: number;
  private height: number;
  private width: number;
  private fill: string;
  private stroke: string;
  private radiusX: number;
  private radiusY: number;
  private points: number[];
  private strokeWidth: number;
  private text: string;
  private fileId: string;
  private srcPreview: string;
  private scaleX: number;
  private scaleY: number;
  private rotation: number;
  private opacity: number;
  private textAttrs: ShapeTextAttrsConfig;

  constructor(private x, private y) {}

  buildFactory(shapeTypes: ShapeTypes): ShapeFactory {
    switch (shapeTypes) {
      case ShapeTypes.rect:
        return new RectFactory(
          this.x,
          this.y,
          this.width,
          this.height,
          this.fill,
          this.stroke,
          this.strokeWidth,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
        );
      case ShapeTypes.triangle:
        return new TriangleFactory(
          this.x,
          this.y,
          this.radius,
          this.fill,
          this.stroke,
          this.strokeWidth,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
        );
      case ShapeTypes.ellipse:
        return new ElllipseFactory(
          this.x,
          this.y,
          this.radiusX,
          this.radiusY,
          this.fill,
          this.stroke,
          this.strokeWidth,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
        );
      case ShapeTypes.freeline:
        return new FreeLineFactory(
          this.points,
          this.stroke,
          this.strokeWidth,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
          this.x,
          this.y,
        );
      case ShapeTypes.text:
        return new TextFieldFactory(
          this.x,
          this.y,
          this.text,
          this.width,
          this.height,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
          this.textAttrs,
        );
      case ShapeTypes.sticker:
        return new StickerFactory(
          this.x,
          this.y,
          this.width,
          this.fill,
          this.text,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
          this.stroke,
          this.strokeWidth,
        );
      case ShapeTypes.file:
        return new FileFactory(
          this.x,
          this.y,
          this.width,
          this.fileId,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
        );
      case ShapeTypes.image:
        return new ImageFactory(
          this.x,
          this.y,
          this.width,
          this.height,
          this.fileId,
          this.srcPreview,
          this.scaleX,
          this.scaleY,
          this.rotation,
          this.opacity,
        );
    }
  }

  withRadius(radius: number): ShapeFactoryBuilder {
    this.radius = radius;
    return this;
  }

  withRadiusX(radius: number): ShapeFactoryBuilder {
    this.radiusX = radius;
    return this;
  }

  withRadiusY(radius: number): ShapeFactoryBuilder {
    this.radiusY = radius;
    return this;
  }

  withWidth(w: number): ShapeFactoryBuilder {
    this.width = w;
    return this;
  }

  withHeight(h: number): ShapeFactoryBuilder {
    this.height = h;
    return this;
  }

  withFill(fill: string): ShapeFactoryBuilder {
    this.fill = fill;
    return this;
  }
  withStroke(stroke: string): ShapeFactoryBuilder {
    this.stroke = stroke;
    return this;
  }
  withPoints(points: number[]) {
    this.points = points;
    return this;
  }
  withStrokeWidth(width: number) {
    this.strokeWidth = width;
    return this;
  }
  withText(text: string) {
    this.text = text;
    return this;
  }
  withFileId(fileId: string) {
    this.fileId = fileId;
    return this;
  }
  withSrcPrevice(src: string) {
    this.srcPreview = src;
    return this;
  }
  withScaleX(scaleX: number) {
    this.scaleX = scaleX;
    return this;
  }
  withScaleY(scaleY: number) {
    this.scaleY = scaleY;
    return this;
  }
  withRotation(rotation: number) {
    this.rotation = rotation;
    return this;
  }
  withOpacity(opacity: number) {
    this.opacity = opacity;
    return this;
  }
  withTextAttrs(textAttrs: ShapeTextAttrsConfig) {
    this.textAttrs = textAttrs;
    return this;
  }
}
