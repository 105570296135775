<div class="modal_header">
  <div class="modal_header__text">{{ 'Пожалуйста, укажите причину вашей жалобы и заполните форму ниже' | transloco }}!
  </div>
</div>
<div class="modal__content">
  <div
    class="modal_checks"
    *ngIf="load"
  >
    <div
      class="modal_checks__item"
      *ngFor="let reclamation of reclamationArray.controls; let i = index"
    >
      <mat-checkbox
        [value]="reclamation.value"
        [formControl]="reclamation | toFormControl"
      >{{ reclamationLists[i].name }}</mat-checkbox>
    </div>
  </div>
  <div class="modal__textarea">
    <mat-form-field
      name="message"
      class="textarea"
      appearance="outline"
    >
      <mat-label>{{ 'Введите ваше сообщение' | transloco }} </mat-label>
      <textarea
        matInput
        name="message"
        maxlength="140"
        [value]="message"
        [(ngModel)]="message"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="modal__actions">
    <a
      class="btn btn--blue btn--middle"
      (click)="send(data.id)"
    >
      <span class="btn__label">{{ 'Отправить' | transloco }} </span>
    </a>
    <a
      class="btn btn--blue-border btn--middle"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Заполнить позже' | transloco }}</span>
    </a>
    <a
      class="btn btn--red btn--middle"
      (click)="finish()"
    >
      <span class="btn__label">{{ 'Отменить' | transloco }}</span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>