<button
  [ngClass]="{
    filled: type === buttonType.filled,
    'not-filled': type === buttonType.notFilled,
    'warn-filled': type === buttonType.warnFilled,
    'warn-not-filled': type === buttonType.warnNotFilled,
    'info-popup-filled': type === buttonType.infoPopupFilled
  }"
  [style.width]="width"
  [style.height]="height"
  [style.borderRadius]="borderRadius"
  [disabled]="disabled"
  [style.max-width]="maxWidth"
  [style.fontSize]="fontSize"
  [style.padding]="padding"
>
  <ng-content select="[content]"></ng-content>
</button>