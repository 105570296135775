import { Injectable, InjectionToken } from '@angular/core';
import { ProfileService } from '@ezteach/profile';
import { pluck } from 'rxjs/operators';
import { ITutorialStepValidator } from '../models';

export const PROFILE_DETAILS_MISSING_STEP_VALIDATOR = new InjectionToken<ITutorialStepValidator>(
  'PROFILE_DETAILS_MISSING_STEP_VALIDATOR',
);

@Injectable({ providedIn: 'root' })
export class ProfileDetailsMissingValidatorService implements ITutorialStepValidator {
  constructor(private readonly profileService: ProfileService) {}

  shouldSkipStep() {
    return this.profileService.validateProfile().pipe(pluck('data'));
  }
}
