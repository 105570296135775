<div class="modal">
  <div class="modal__header"></div>
  <div class="modal__content">
    <div class="modal__title"> {{ 'К сожалению, платформа не поддерживается вашим браузером' | transloco }}</div>
    <div class="modal__text">
      <p>{{ 'Пожалуйста, зайдите с другого браузера' | transloco }} <br />
        {{ 'Мы рекомендуем использовать Google Chrome' | transloco }} </p>
    </div>
    <p> {{ 'Скачать браузер' | transloco }}:</p>
    <div class="modal__link">
      <a
        class="browsLink"
        href="https://www.google.com/chrome/browser/desktop/index.html"
      >
        <img
          class="chrome-icon"
          src="https://www.google.com/chrome/static/images/chrome-logo.svg"
          i18n-alt
          i18n-title
          alt="скачать Google Chrome"
          title="скачать Google Chrome"
        />
      </a>
      <a
        class="browsLink"
        href="https://www.mozilla.org/firefox/download/thanks/"
        i18n-title
        title="скачать Firefox"
      >
        <img
          src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_firefox-256.png"
          i18n-alt
          alt="скачать Google Chrome"
        />
      </a>
      <a
        class="browsLink"
        href="https://browser.yandex.ru/"
        i18n-title
        title="скачать Яндекс.Браузер"
      >
        <img
          src="data:image/svg+xml;charset=utf-8,%3Csvg width='44' height='44' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='a'%3E%3Cstop stop-color='%23FFF' offset='0%25'/%3E%3Cstop stop-color='%23EDEDEE' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='url(%23a)' cx='22' cy='22' r='22'/%3E%3Cpath d='M36.163 12.925L24.389 24.44v14.32c0 .258-1.118.464-2.39.48-1.22-.016-2.39-.222-2.39-.48V24.44L7.84 12.926c-.19-.19.412-1.083 1.34-2.01.928-.93 1.84-1.53 2.01-1.342L21.984 20.11 32.777 9.572c.19-.19 1.082.413 2.01 1.34.93.93 1.547 1.823 1.376 2.012z' fill='%23EC1C24'/%3E%3C/g%3E%3C/svg%3E"
          i18n-alt
          alt="скачать скачать Яндекс.Браузер"
        />
      </a>
      <a
        class="browsLink"
        href="https://www.opera.com/"
        i18n-title
        title="скачать Opera"
      >
        <img
          src="https://cdn-production-opera-website.operacdn.com/staticfiles/assets/images/logo/logo-o.64d9b43037de.svg"
          i18n-alt
          alt="скачать Opera"
        />
      </a>
      <a
        class="browsLink"
        href="https://www.microsoft.com/edge"
        i18n-title
        title="скачать Edge"
      >
        <img
          src="https://cdn.icon-icons.com/icons2/2552/PNG/256/edge_browser_logo_icon_152998.png"
          i18n-alt
          alt="скачать Microsoft Edge"
        />
      </a>
    </div>
  </div>
</div>