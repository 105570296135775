import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LessonsService } from '../api/services/lessons.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { SignalrService } from '../_services/signalr.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsService implements OnDestroy {
  readonly onChanged = new Subject<Boolean>();
  private readonly update$ = new Subject<void>();
  private readonly subscription = new Subscription();
  public sending$ = new BehaviorSubject(false);
  constructor(
    private readonly lessonsService: LessonsService,
    private readonly signalRService: SignalrService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  init() {
    this.subscription.add(
      merge(
        this.update$,
        this.signalRService.onChatRequestCreated,
        this.signalRService.onChatRequestCanceled,
        this.signalRService.onChatRequestAccepted,
      )
        .pipe(switchMap(() => this.lessonsService.apiV1LessonsInstantRequestsPendingGet({})))
        .subscribe(response => {
          this.localStorageService.set('requests', response.data);
          this.onChanged.next(true);
        }),
    );
  }

  update() {
    this.update$.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
