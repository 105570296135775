import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventInput } from '@fullcalendar/core';

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
})
export class AppPriceInputComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() showValueWithPlaceholder: boolean = false;
  @Input() value: string = '';
  @Input() style: any = null;

  @Output() onChange = new EventEmitter<string>();

  constructor() { }

  onChangeValue(event: EventInput) {
    this.value = event.target.value;
    this.onChange.emit(event.target.value);
  }

  onKeyPress(event: any) {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
  }
}
