import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from "@ezteach/_services/user.service";
import { IResponse } from '@ezteach/api/models/response.model';
import { UserProfileValidationStatus } from '@ezteach/api/models/user-profile-validation-status';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import * as dot from 'dot-object';
import { propOr } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileDocumentIEnumerableApiResponse, UserProfile } from '../api/models';
import { RequestBuilder } from '../api/request-builder';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  user: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  profile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  note: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private get userId() {
    return propOr(null, 'id', this.userService.userData$.value);
  }

  constructor(private http: HttpClient,
    private userService: UserService
  ) { }

  setProfile(data: UserProfile) {
    const requestBuilder = new RequestBuilder(environment.apiUrl, `/api/v1/users/${data.userId}/profile`, 'put');
    requestBuilder.body(dot.dot(data), 'multipart/form-data');

    return this.http.request(requestBuilder.build({ responseType: 'json', accept: 'application/json' })).pipe(
      filter((response: any) => response instanceof HttpResponse),
      tap((response: HttpResponse<any>) => {
        if (response.status === 200) {
          this.profile.next(data);
        }
      }),
    );
  }

  validateProfile(): Observable<IResponse<boolean>> {
    return this.http.get<IResponse<boolean>>(`${environment.apiUrl}/api/v1/users/${this.userId}/profile/validation`);
  }

  validationStatus(): Observable<IResponse<UserProfileValidationStatus>> {
    return this.http.get<IResponse<UserProfileValidationStatus>>(
      `${environment.apiUrl}/api/v1/users/${this.userId}/validation/status`,
    );
  }

  loadAdditionalProperty(): Observable<boolean> {
    return this.http
      .get<boolean>(`${environment.apiUrl}/api/v1/users/${this.userId}/property`)
      .pipe(pluck('disableValidationNotification'));
  }
  setAdditionalProperty(body): Observable<{}> {
    return this.http.put<{}>(`${environment.apiUrl}/api/v1/users/${this.userId}/property`, body);
  }

  uploadUserAvatar(body?: { file?}): Observable<FileDocumentIEnumerableApiResponse> {
    const rb = new RequestBuilder(environment.apiUrl, `/api/v1/users/${this.userId}/profile/avatar`, 'put');
    rb.body(body, 'multipart/form-data');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: StrictHttpResponse<FileDocumentIEnumerableApiResponse>) => r.body as FileDocumentIEnumerableApiResponse),
      );
  }
}
