<div class="modal__header">
  <div class="modal__icon">
    <img src="/assets/img/svg/icon-smile-bad.svg" />
  </div>
</div>
<div class="modal__content modal__content--icon">
  <div class="modal__title"> {{ 'Событие отменен' | transloco }}</div>
  <div class="modal__actions">
    <a
      class="btn btn--blue btn--middle"
      (click)="close()"
    >
      <span class="btn__label"> {{ 'Вернуться на доску' | transloco }} </span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>
