import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper stepProgressText="1.2 из 3">
      <h1 class="ezteach-tutorial-tooltip__heading">Календарь</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Чтобы создать слот, нажмите кнопку “Настроить свободные слоты” и отметьте удобное время на календаре.
      </p>
      <img
        class="ezteach-tutorial-tooltip__image"
        [attr.src]="'/assets/img/tutorial/common/lesson.svg' | cachedImage"
      />
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step1_2TooltipComponent {}
