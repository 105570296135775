export enum LessonFormatEnum {
  Instant = 'Instant',
  Scheduled = 'Scheduled',
  Group = 'Group',
}

export enum LessonPaymentTypeEnum {
  Payable = 'Payable',
  Free = 'Free',
}
