import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProlongService } from '../prolong.service';

@UntilDestroy()
@Component({
  selector: 'easy-teach-timer',
  templateUrl: './timer.component.svg',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent {
  timeDuration$: Observable<number>;
  @Input() totalSecondsLeft: number;

  constructor(private prolongService: ProlongService) {}
  circleLength: string;
  readonly CONSTANTS = CONSTANTS;

  ngOnInit() {
    this.timeDuration$ = this.prolongService.leftSeconds.pipe(
      map(seconds => {
        if (seconds > 0) {
          this.setCircleDasharray(seconds);
        }
        return seconds;
      }),
    );

    this.circleLength = this.CONSTANTS.FULL_DASH_ARRAY.toString();
  }

  calculateTimeFraction(seconds: number) {
    const rawTimeFraction = seconds / this.totalSecondsLeft > 1 ? 1 : seconds / this.totalSecondsLeft;
    return rawTimeFraction - (1 / this.totalSecondsLeft) * (1 - rawTimeFraction);
  }

  setCircleDasharray(seconds: number) {
    const circleDasharray = `${(Math.abs(
      this.CONSTANTS.FULL_DASH_ARRAY -
      this.calculateTimeFraction(seconds) * this.CONSTANTS.FULL_DASH_ARRAY
    )).toFixed(0)} ${this.CONSTANTS.FULL_DASH_ARRAY}`;

    this.circleLength = circleDasharray;
  }
}

export const CONSTANTS = {
  // длина нашего круга в параметре stroke-dasharray в timer.component.svg
  FULL_DASH_ARRAY: 283,
};
