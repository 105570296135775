import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
  isFileUploading,
  isProfileSaving,
  removeProfilePassportFile,
  saveProfilePassportDetails,
  selectPassportDetails,
  selectPassportDetailsFiles,
  selectPersonalDetails,
  setProfileTitle,
  uploadProfilePassportFile,
} from '../../+state';
import { environment } from '../../../../environments/environment';
import { FileDocument, PassportDetails, PersonalDetails } from '../../../api/models';
import { getDate } from '../../../shared/helpers/helpers';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-passport',
  templateUrl: './profile-passport.component.html',
  styleUrls: ['./profile-passport.component.scss'],
})
export class ProfilePassportComponent implements OnInit {
  localStorageUser: any;
  form: UntypedFormGroup;
  uploading = false;
  maxDate = new Date();
  minDate = new Date();
  saving$: Observable<boolean>;

  token: string;
  environment = environment;
  loadButtonText: string;

  private title = $localize`Паспортные данные`;
  acceptFileTypes = '.tiff, .jpeg, .jpg, .pdf, .png';
  isReadonlyFields: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileLocalStorageService: ProfileLocalStorageService,
    private authStorage: OAuthStorage,
    private store: Store,
  ) {
    this.minDate.setFullYear(this.maxDate.getFullYear() - 30);
  }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.token = this.authStorage.getItem('access_token');
    this.subscribeStore();
    this.loadButtonText = !this.uploading ? 'Загрузить' : 'Загружается';
  }

  subscribeStore() {
    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.localStorageUser = user;
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPassportDetails),
        filter(pasportDetails => !!pasportDetails),
        take(1),
        tap((pasportDetails: PassportDetails) => {
          this.updateForm(pasportDetails);
          this.isReadonlyFields = false;
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPersonalDetails),
        filter((personalDetails: PersonalDetails) => !!personalDetails?.birthDate),
        take(1),
        tap((personalDetails: PersonalDetails) => {
          this.minDate = new Date(personalDetails.birthDate);
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPassportDetailsFiles),
        filter(response => !!response),
        tap((response: FileDocument[]) => {
          this.form.get('documentFiles').patchValue([...response]);
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(isFileUploading),
        tap((uploading: boolean) => {
          this.uploading = uploading;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving), tap(isProfileSaving => {
      if (this.isReadonlyFields && !isProfileSaving) {
        this.isReadonlyFields = false;
      }
    }));
  }

  createForm() {
    this.form = this.formBuilder.group({
      lastName: ['', [Validators.required, Validators.maxLength(20)]],
      firstName: ['', [Validators.required, Validators.maxLength(20)]],
      patronymic: ['', [Validators.required, Validators.maxLength(30)]],
      number: ['', Validators.required],
      issueDate: ['', Validators.required],
      issueAuthority: ['', Validators.required],
      registrationAddress: this.formBuilder.group({
        region: ['', Validators.required],
        city: ['', Validators.required],
        addressDetails: ['', Validators.required],
      }),
      documentFiles: [[], Validators.required],
    });
  }

  updateForm(data: PassportDetails) {
    this.form.patchValue(data);
  }

  saveProfile() {
    const value = this.form.value;
    const detail: PassportDetails = {
      ...value,
      issueDate: getDate(value.issueDate),
    };

    console.log(detail);

    this.store.dispatch(saveProfilePassportDetails(detail));
  }

  addFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;
      const form = new FormData();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < files.length; x++) {
        const file = files[x];
        form.append('files', file);
      }

      const request = {
        contentType: 'passport',
        body: {
          files: form.getAll('files'),
          ownerEntityId: `${this.localStorageUser.id}`,
        },
      };
      this.store.dispatch(uploadProfilePassportFile(request));
      event.target.value = '';
    }
  }

  removeFile(index: number) {
    this.isReadonlyFields = true;
    this.form
      .get('documentFiles')
      .patchValue(this.form.get('documentFiles').value.filter((file: FileDocument, i: number) => i !== index));

    this.store.dispatch(removeProfilePassportFile({ data: this.form.get('documentFiles').value }));
  }
}
