import { DatePipe } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from "@ezteach/_services/user.service";
import { OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'messages_list',
  templateUrl: './messages_list.component.html',
})
export class MessagesListComponent {
  @Input() roomId;
  @Input() messages = [];
  @Input() unreadMessages = [];
  environment;
  userdata;
  currDate = '';

  constructor(
    private oauthService: OAuthService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.environment = environment;
    moment.locale('ru');
    this.userdata = this.userService.userData$.value;
    this.currDate = '';
  }

  getMessageTime(item) {
    return moment(item.publicationDate).format('HH:mm');
  }

  showDate(item, nextitem) {
    if (!nextitem) {
      return false;
    }
    if (moment(item.publicationDate).format('YY.MM.DD') != moment(nextitem.publicationDate).format('YY.MM.DD')) {
      return true;
    } else {
      return false;
    }
  }

  date(item) {
    return this.datePipe.transform(item.publicationDate, 'd MMMM');
  }

  link(file) {
    return file.fileName + '?access_token=' + this.oauthService.getAccessToken();
  }

  preview(file) {
    return (file.previewFileName || file.fileName) + '?access_token=' + this.oauthService.getAccessToken();
  }

  openImage(template: TemplateRef<any>) {
    this.dialog.open(template);
  }

  closeImage() {
    this.dialog.closeAll();
  }
}
