import { IShapeSnapShot } from './shape';
import { ShapeTextAttrsConfig } from './shape-attrs-config';

export class ShapeUtils {
  public static textAttrsFromTextSnapshot(snap: IShapeSnapShot): ShapeTextAttrsConfig {
    return {
      fontFamily: snap.getFontFamily(),
      fontSize: snap.getFontSize(),
      fontStyle: snap.getFontStyle(),
      textDecoration: snap.getTextDecoration(),
      textAlign: snap.getAlign(),
      fontWeight: snap.getFontVariant(),
    };
  }
}
