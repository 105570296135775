import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable, InjectionToken, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { TypedAction } from '@ngrx/store/src/models';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITooltipOverlayRef } from '../models';

export const OVERLAY_REF = new InjectionToken('OVERLAY_REF');
export const TOOLTIP_DATA = new InjectionToken('TOOLTIP_DATA');

/* Helper service responsible for showing tooltips */
@Injectable({
  providedIn: 'root',
})
export class TutorialTooltipService {
  private overlayRef: ITooltipOverlayRef;
  private elRef: ElementRef;
  private renderer: Renderer2;

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  hideCurrentTooltip() {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.close();
      this.overlayRef = null;
      this.elRef = null;
    }
  }

  showTooltipForElement(elementRef: ElementRef, tooltip: ComponentType<any>, data: any = {}): ITooltipOverlayRef {
    this.hideCurrentTooltip();
    const ngOverlayRef = this.createOverlay(elementRef);
    (ngOverlayRef.overlayElement.closest('.cdk-overlay-container') as HTMLElement).style.zIndex = '1002';
    const _closeSource$ = new Subject<TypedAction<string>>();
    this.elRef = elementRef;
    this.overlayRef = Object.assign(ngOverlayRef, {
      close: (v?: TypedAction<string>) => {
        ngOverlayRef.dispose();
        _closeSource$.next(v);
        _closeSource$.complete();
        this.overlayRef = null;
        this.elRef = null;
      },
      afterClosed: () => _closeSource$.asObservable(),
    });
    this.overlayRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.renderer.removeClass(this.elRef.nativeElement, 'ezteach-tutorial-tooltip__target-element');
        }),
      )
      .subscribe();
    this.renderer.addClass(this.elRef.nativeElement, 'ezteach-tutorial-tooltip__target-element');
    const injector = Injector.create({
      providers: [
        {
          provide: OVERLAY_REF,
          useValue: this.overlayRef,
        },
        {
          provide: TOOLTIP_DATA,
          useValue: data,
        },
      ],
      parent: this.injector,
    });
    this.overlayRef.attach(new ComponentPortal(tooltip, null, injector));

    return this.overlayRef;
  }

  private createOverlay(elementRef: ElementRef): OverlayRef {
    const config = new OverlayConfig({
      positionStrategy: this.getPositionStrategy(elementRef),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      maxWidth: '415px',
      width: '100%',
      hasBackdrop: true,
      backdropClass: 'ezteach-tutorial-tooltip-backdrop',
      panelClass: 'ezteach-tutorial-tooltip-panel',
      disposeOnNavigation: true,
    });

    return this.overlay.create(config);
  }

  private getPositionStrategy(elementRef: ElementRef): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withViewportMargin(0)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          panelClass: 'ezteach-tutorial-tooltip-panel--bottom',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          panelClass: 'ezteach-tutorial-tooltip-panel--top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          panelClass: 'ezteach-tutorial-tooltip-panel--left',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          panelClass: 'ezteach-tutorial-tooltip-panel--right',
        },
      ]);
  }
}
