import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ChatLessonStatusEnum } from '@ezteach/api/models/chat-lesson-status-enum';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FinishedLessonGuard implements CanActivate {
  constructor(private lessonsService: LessonsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const chatLessonStatusEnum = ChatLessonStatusEnum;

    const currentNavigation = this.router.getCurrentNavigation();

    if (this.router.url.includes('wait')) {
      return true;
    }

    if (
      currentNavigation.finalUrl.root.children.primary.segments[0].path.indexOf('group-lesson') !==
        -1 &&
      currentNavigation.finalUrl.queryParams.id &&
      currentNavigation.finalUrl.queryParams.fromEmbeddedWindow
    ) {
      return true;               
    }

    if (
      currentNavigation.finalUrl.root.children.primary.segments[0].path.indexOf('group-lesson') !==
        -1 &&
      currentNavigation.finalUrl.queryParams.id
    ) {
      return this.lessonsService
        .apiV1LessonsLessonIdGet({ lessonId: currentNavigation.finalUrl.queryParams.id })
        .pipe(
          map(({ data }) => {
            if (
              !data ||
              (data && data.lessonStatusId === chatLessonStatusEnum.Finalized) ||
              data.lessonStatusId === chatLessonStatusEnum.FinishedByTimer ||
              data.lessonStatusId === chatLessonStatusEnum.FinishedByTutor
            ) {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  lessonIsFinished: true,
                },
              };
              this.router.navigate(['wait'], navigationExtras);
              return false;
            } else if (data) {
              this.router.navigate(['wait'], {
                queryParams: { id: data.joinIdentity },
              });
              return false;
            } else {
              return true;
            }
          }),
          catchError(
            // @ts-ignore
            () => {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  notJoined: true,
                },
              };
              this.router.navigate(['wait'], navigationExtras);
              return false;
            },
          ),
        );
    }
    return false;
  }
}
