import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-lesson-finished',
  templateUrl: 'modal-lesson-finished.component.html',
})
export class ModalLessonFinished {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalLessonFinished>,
    private _cdr: ChangeDetectorRef,
  ) {
    const self = this;
    setTimeout(() => self._cdr.detectChanges(), 100);
  }

  close() {
    this.dialogRef.close('close');
  }
}
