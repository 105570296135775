import { Injectable } from '@angular/core';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonPermissionService {
  chatLessonMember$ = new BehaviorSubject<ChatLessonMember>({});
  allLessonMembers$ = new BehaviorSubject<ChatLessonMember[]>([]);

  audioPermission =
    this.chatLessonMember$.value?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Audio) !== -1;
  videoPermission =
    this.chatLessonMember$.value?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Video) !== -1;

  audioEnabled$ = new BehaviorSubject<boolean>(false);
  videoEnabled$ = new BehaviorSubject<boolean>(false);

  audioPermission$: Observable<boolean> = this.chatLessonMember$
    .asObservable()
    .pipe(
      map(
        (member: ChatLessonMember) =>
          member?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Audio) !== -1,
        distinctUntilChanged(),
      ),
    );

  videoPermission$: Observable<boolean> = this.chatLessonMember$
    .asObservable()
    .pipe(
      map(
        (member: ChatLessonMember) =>
          member?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Video) !== -1,
        distinctUntilChanged(),
      ),
    );

  screenPermission$: Observable<boolean> = this.chatLessonMember$
    .asObservable()
    .pipe(
      map(
        (member: ChatLessonMember) =>
          member?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Screen) !== -1,
        distinctUntilChanged(),
      ),
    );

  chatPermission$: Observable<boolean> = this.chatLessonMember$
    .asObservable()
    .pipe(
      map(
        (member: ChatLessonMember) =>
          member?.publishingPermissions?.indexOf(ChatLessonMemberPublishingPermission.Chat) !== -1,
        distinctUntilChanged(),
      ),
    );

  role$: Observable<ChatLessonMemberRole> = this.chatLessonMember$
    .asObservable()
    .pipe(map((member: ChatLessonMember) => member?.role, distinctUntilChanged()));



  setChatLessonMember(value: ChatLessonMember) {
    this.chatLessonMember$.next(value);
  }

  setAllLessonMembers(value: ChatLessonMember[]) {
    this.allLessonMembers$.next(value);
  }

  addToAllLessonMembers(value: ChatLessonMember) {
    if (!value.publishingPermissions.find(x => x !== undefined)) {
      value.publishingPermissions = [
        ChatLessonMemberPublishingPermission.Audio,
        ChatLessonMemberPublishingPermission.Video,
        ChatLessonMemberPublishingPermission.Chat,
        ChatLessonMemberPublishingPermission.Screen,
      ];
    }
    if (this.allLessonMembers$.value.findIndex(x => x.memberId === value.memberId) === -1) {
      const newValue = [...this.allLessonMembers$.value, value];
      this.allLessonMembers$.next(newValue);
    }
  }

  removeFromAllLessonMembers(id: number) {
    const newValue = this.allLessonMembers$.value.filter(x => x.memberId !== id);
    this.allLessonMembers$.next(newValue);
  }

  setVideoState(value: boolean) {
    this.videoEnabled$.next(value);
  }
}
