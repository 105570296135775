import { Injectable } from '@angular/core';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonPinService {
  pinnedMode$ = new Subject<boolean>();
  pinnedMember$ = new Subject<ChatLessonMemberClient | undefined>();
  constructor() { }
}
