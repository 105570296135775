import { Pipe, PipeTransform } from '@angular/core';
import { ChatLesson, User } from '@ezteach/api/models';
import { getTutorUser } from '@ezteach/api/utils/chat-lesson-member-replacement';

@Pipe({
  name: 'findTutorUser',
})
export class FindTutorUser implements PipeTransform {
  transform(lesson: ChatLesson): User {
    return getTutorUser(lesson);
  }
}
