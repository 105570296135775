export enum SessionEvents {
  streamCreated = 'streamCreated',
  streamDestroyed = 'streamDestroyed',
  connectionDestroyed = 'connectionDestroyed',
  connectionCreated = 'connectionCreated',
  publisherStartSpeaking = 'publisherStartSpeaking',
  publisherStopSpeaking = 'publisherStopSpeaking',
  sessionDisconnected = 'sessionDisconnected',
  reconnecting = 'reconnecting',
  reconnected = 'reconnected',
  exception = 'exception',
}
