<div class="bookmarks_header">
  <div class="bookmarks_header__name">{{ 'Закладки' | transloco }}</div>
  <div class="bookmarks_header__icon">
    <svg-icon-sprite
      [src]="'icon-bookmark'"
      [width]="'62px'"
      [height]="'62px'"
      [viewBox]="'0 0 62 62'"
    ></svg-icon-sprite>
  </div>
</div>
<div *ngIf="loaded">
  <div
    class="results_none"
    *ngIf="teachers.length === 0"
  >
    <div class="global__icon">
      <svg-icon-sprite
        [src]="'icon-no-bookmarks'"
        [width]="'86px'"
        [height]="'80px'"
        [viewBox]="'0 0 86 80'"
      ></svg-icon-sprite>
    </div>
    <div class="global__title">{{ 'У вас пока нет закладок' | transloco }} </div>
    <!--div class="global__text">{{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' |
      transloco }} </div-->
  </div>
  <div
    class="search__list"
    *ngIf="results.totalRecords > 0"
  >
    <tutor-user-search-result
      [item]="item"
      [type]="'bookmark'"
      [marked]="true"
      (bookmarksUpdated)="bookmarksUpdated($event)"
      *ngFor="let item of teachers"
    ></tutor-user-search-result>
  </div>
  <div
    class="search__action"
    *ngIf="results.totalPages > results.pageNumber"
  >
    <button class="btn btn--blue">
      <span
        class="btn__label"
        (click)="getMore()"
      >{{ 'Показать еще' | transloco }}</span>
    </button>
  </div>
</div>