import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { BlockLessonComponent } from '@ezteach/blocks/components/block-lesson/block-lesson.component';
import { BlockTaskComponent } from '@ezteach/blocks/components/block-task/block-task.component';
import { BlockComponent } from '@ezteach/blocks/components/block/block.component';
import { SharedModule } from '@ezteach/shared';
import { SelectAutocompleteModule } from '@ezteach/shared/components/select-autocomplete/select-autocomplete.module';
import { TranslocoModule } from '@ngneat/transloco';
import { TuiLoaderModule } from '@taiga-ui/core';
import { BlockItemComponent } from './components/block-item/block-item.component';
import { BlockListComponent } from './components/block-list/block-list.component';
import { CreateBlockComponent } from './components/create-block/create-block.component';
import { SelectHistoryLessonComponent } from './components/select-history-lesson/select-history-lesson.component';

@NgModule({
  declarations: [
    BlockListComponent,
    BlockItemComponent,
    BlockComponent,
    BlockLessonComponent,
    BlockTaskComponent,
    CreateBlockComponent,
    SelectHistoryLessonComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ButtonModule,
    FormsModule,
    SelectAutocompleteModule,
    TranslocoModule,
    MatDividerModule,
    TuiLoaderModule,
    RouterModule
  ],
  exports: [BlockListComponent, CreateBlockComponent, SelectHistoryLessonComponent],
})
export class BlocksModule { }
