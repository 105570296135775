import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogNavigationStep } from '../helpers';

@Component({
  template: `
    <ezteach-tutorial-dialog-wrapper>
      <a
        #skipLink
        class="ezteach-tutorial-dialog__skip"
        (click)="finishTutorial()"
      >
        Пропустить
      </a>
      <img
        class="ezteach-tutorial-dialog__image"
        [attr.src]="'/assets/img/tutorial/onboarding-teacher/onboarding-finish.svg' | cachedImage"
      />
      <h1 class="ezteach-tutorial-dialog__heading">Ну и самое главное!</h1>
      <p class="ezteach-tutorial-dialog__info">Чтобы начать преподавать, не забудьте указать свою специализацию.</p>
      <div class="ezteach-tutorial-dialog__actions">
        <a
          class="ezteach-tutorial-dialog__link-button"
          (click)="finishTutorial()"
          >Заполню позже</a
        >
        <ezteach-button (click)="goToProfile()">Заполнить профиль сейчас</ezteach-button>
      </div>
    </ezteach-tutorial-dialog-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step4FinishDialogComponent extends DialogNavigationStep {
  constructor(
    protected readonly store: Store<any>,
    protected readonly router: Router,
    protected readonly dialogRef: MatDialogRef<Step4FinishDialogComponent>,
  ) {
    super();
  }

  goToProfile() {
    this.router.navigateByUrl('/profile/specialty');
    this.finishTutorial();
  }
}
