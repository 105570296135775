import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';

@Component({
  selector: 'modal-screen-sharing-access',
  templateUrl: 'modal-screen-sharing-access.component.html',
})
export class ModalScreenSharingAccess {
  smileyType = SmileyType;
  buttonType = ButtonType;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<any>) {}

  close() {
    this.dialogRef.close('close');
  }
}
