<div class="container">
  <mat-form-field
    class="select-autocomplete float-field"
    [ngClass]="!auto.isOpen ? 'select-autocomplete-closed' : ''"
    appearance="fill"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      class="ez-input"
      tabindex="-1"
      matInput
      #input
      #trigger="matAutocompleteTrigger"
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matAutocompletePosition]="'above'"
      (focus)="onFocusInput()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      ngDefaultControl
      [formControl]="values"
    >
      <ng-container>
        <mat-option
          *ngFor="let item of items"
          [value]="item"
        >
          <ng-container *ngTemplateOutlet="itemTempl; context: { ctxItem: item }"> </ng-container>
        </mat-option>
      </ng-container>

      <ng-container *ngIf="filteredItems$ | async as filteredItems">
        <ng-container *ngFor="let item of filteredItems">
          <mat-option
            *ngIf="!isSelectedById(item)"
            [value]="item"
          >
            <ng-container *ngTemplateOutlet="itemTempl; context: { ctxItem: item }"> </ng-container>
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-autocomplete>

    <div
      *ngIf="!auto.isOpen && suffix"
      matSuffix
      (click)="openPanel($event)"
    >
      <img src="assets/img/svg/icon-arraw-down.svg" />
    </div>
    <div
      *ngIf="auto.isOpen"
      matSuffix
      (click)="closePanel($event)"
    >
      <img src="assets/img/svg/icon-arrow-up.svg" />
    </div>
  </mat-form-field>
</div>



<ng-template
  #itemTempl
  let-item="ctxItem"
>
  <div
    class="select-autocomplete-item"
    (click)="changeSelectItem($event, item)"
  >
    <ng-container *ngIf="item.id !== 0">
      <img
        class="select-autocomplete-item__img"
        *ngIf="item.avatarFileName; else noUserAvatar"
        src="{{ item | userAvatar }}"
      />
      <ng-template #noUserAvatar>
        <span class="select-autocomplete-item__img-emt"><span>{{ item | userInitials }}</span></span>
      </ng-template>
    </ng-container>
    <span class="select-autocomplete-item__txt">
      <span class="select-autocomplete-item__txt__name"> {{ item.firstName }} {{ item.lastName }} </span>
      <span
        class="select-autocomplete-item__checkbox"
        *ngIf="item.id !== 0"
      >
        <label class="checkbox">
          <input
            type="checkbox"
            [checked]="isSelected(item)"
          />
          <div class="checkbox__checkmark"></div>
        </label>
      </span>
    </span>
  </div>
</ng-template>