import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from '@ezteach/api/services';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from "@ezteach/_services/user.service";

@UntilDestroy()
@Component({
  selector: 'ezteach-widget-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['finance.component.style.scss'],
})
export class WidgetFinanceComponent implements OnInit {
  @Input() isNavItem = false;
  @Input() isHalfView = false;
  userdata: any;
  total$: Observable<number>;
  monthlyTotal = 0;
  monthlyValues: number[] = [];
  value = [[], []];
  axisXLabels: string[] = [];
  date: Date[] = [];
  selectedDate: Date;
  axisYLabels: string[] = [];
  readonly defaultAxisYLabels = ['', '5к', '10к', '15к', '20к'];
  maxChartValue = 0;
  axisOffset = 1.4;
  isMobile: boolean;
  private mediaQueryService = new MediaQueryService('(max-width: 414px)');

  constructor(private usersService: UsersService,
              private userService: UserService
  ) {}

  ngOnInit() {
    moment.locale('ru');

    this.mediaQueryService.match$.subscribe(value => {
      if (value) {
        this.isHalfView = false;
      }
      this.isMobile = value;
    });
    this.userdata = this.userService.userData$.value;
    this.initDates();
    this.loadTotal();
    this.loadMonthlyPaymentAmount();
  }

  dateChange($event) {
    this.loadMonthlyPaymentAmount();
  }

  // инициализация данных в компонент выбора даты
  initDates() {
    const currentDate = moment(new Date());
    const fisrtDate = moment(currentDate, 'YYYYMMDD').startOf('month');
    const days = [...Array(12).keys()].map(i => fisrtDate.clone().subtract(i, 'months'));
    this.date = days.map(x => x.toDate());
    this.selectedDate = this.date[0];
  }

  // загрузка доходы за все время
  loadTotal() {
    this.total$ = this.usersService.apiV1UsersUserIdFinancialsBankcardsGetTotalPayment$Response({
      userId: this.userdata.id,
    });
  }

  // загрузка данных за месяц
  loadMonthlyPaymentAmount() {
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsGetMonthlyPaymentAmount({
        userId: this.userdata.id,
        Date: moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss'),
      })
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.monthlyValues = x;
          this.createMonthlyChartData(x);
        }),
      )
      .subscribe();
  }

  // создание данных за месяц для графика
  createMonthlyChartData(data: number[]) {
    this.axisXLabels = data.map((x, i) => (i + 1 < 10 ? '0' + (i + 1).toString() : (i + 1).toString()));
    this.monthlyTotal = data.map(x => x).reduce((x, p) => x + p);

    // для графика по дизайну создаем 2 массива и устанавливаем collapsed
    const max = Math.max(...data);
    const valueDataMain = data.map((x, i) => (x === max ? 0 : x));
    const valueDataWithMax = data.map((x, i) => (x !== max ? 0 : x));
    this.value = [valueDataMain, valueDataWithMax];
    this.maxChartValue = max * this.axisOffset;

    this.createAxisYLabels(data);
  }

  // данные для подписи по оси Y
  createAxisYLabels(data: number[]) {
    if (this.maxChartValue === 0) {
      this.axisYLabels = this.defaultAxisYLabels;
      return;
    }

    const lbls = [...Array(4).keys()]
      .filter(x => x !== 0)
      .map((x, i) => {
        const value = Math.round((this.maxChartValue / x) * 100) / 100;
        if (value >= 1000) {
          return (Math.round(value / 1000) + 1).toString() + 'к';
        }
        return (Math.round((this.maxChartValue / x) * 100) / 100).toString();
      });
    lbls.push('');
    this.axisYLabels = lbls.reverse();
  }

  getAmount(i) {
    return this.monthlyValues[i];
  }

  getDate(i) {
    const date = moment(this.selectedDate);
    date.add(i, 'days');
    return date;
  }
}
