import { Component, Input, OnInit } from '@angular/core';
import { ActiveService } from '@ezteach/_services/active.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private activeService: ActiveService) {}

  activeTemporarySolution: boolean;
  isMobileFooter: boolean;
  year = new Date().getFullYear();
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  @Input() isTutor: boolean;
  @Input() menuCollapsed: boolean;

  ngOnInit() {
    this.activeService.activeTemporarySolution$.subscribe(v => (this.activeTemporarySolution = v));
    this.mediaQueryService.match$.subscribe(value => (this.isMobileFooter = value));
  }
}
