import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-lesson-cancel',
  templateUrl: 'modal-lesson-cancel.component.html',
})
export class ModalLessonCancel {
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ModalLessonCancel>) {}

  close() {
    this.dialogRef.close('close');
  }
}
