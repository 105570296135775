import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';
import { ModerateUser } from '@ezteach/group-lesson/components/group-lesson-participants-list/group-lesson-participants-list.component';

@Pipe({
  name: 'chatMembersSort',
})
export class ChatMembersSortPipe implements PipeTransform {
  transform(value: ModerateUser[]): ModerateUser[] {
    return sortBy(value, m => m.name);
  }
}
