import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { LessonsService } from '../../api/services/lessons.service';
import { UsersService } from '../../api/services/users.service';
import { ModalBankCardDialogModel } from './modal-bankcards.model';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'modal-bankcards',
  templateUrl: 'modal-bankcards.component.html',
})
export class ModalBankcards {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalBankCardDialogModel,
    public dialogRef: MatDialogRef<ModalBankcards, ModalBankcardsResult>,
    private usersService: UsersService,
    private lessonsService: LessonsService,
    private userService: UserService
  ) {}

  addBankcard() {
    const userdata = this.userService.userData$.value;
    this.loading = true;
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsPost({
        userId: userdata.id,
        body: {
          ReturnUrl: environment.appUrl + '/lesson?addcard',
          FailUrl: environment.appUrl + '/lesson?addcard',
        },
      })
      .subscribe(result => {
        if (result.data) {
          window.location.href = result.data;
        }
      });
  }

  pay(id) {
    let cardId = null;
    const card = document.querySelector('.profile_cardlist input[name=bankcard]:checked') as HTMLInputElement;
    if (card) {
      cardId = card.value;
    }

    const userdata = this.userService.userData$.value;

    this.loading = true;
    this.usersService
      .apiV1UsersUserIdFinancialsLessonsBookPost({
        userId: userdata.id,
        body: {
          ChatLessonId: id,
          BankCardId: cardId,
          ReturnUrl: environment.appUrl + '/lesson?id=' + this.data.item.id,
          FailUrl: environment.appUrl + '/lesson?id=' + this.data.item.id,
        },
      })
      .subscribe(
        response => {
          this.dialogRef.close('pay');
        },
        response => {
          this.dialogRef.close({
            type: 'error',
            error: response.error.Error.Description,
          });
        },
      );
  }

  cancel(id) {
    this.lessonsService
      .apiV1LessonsLessonIdCancelPatch({
        lessonId: id,
      })
      .subscribe(response => {
        this.dialogRef.close('cancel');
      });
  }

  close() {
    this.dialogRef.close('close');
  }
}

export interface ModalBankcardsResultError {
  type: 'error';
  error: string;
}

export type ModalBankcardsResult = 'pay' | 'cancel' | 'close' | ModalBankcardsResultError;
