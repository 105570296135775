<div class="messages_files">
  <div class="messages_files__title">{{ title }}</div>
  <div class="messages_files__list">
    <div
      class="links"
      *ngIf="data.items.groupTypeId === 'Links'"
    >
      <a
        class="file"
        target="_blank"
        [href]="link"
        *ngFor="let link of data.items.links"
      >
        <span class="file__icon">
          <img src="/assets/img/svg/icon-link.svg" />
        </span>
        <span class="file__info">
          <span class="file__name">{{ link }}</span>
        </span>
        <span class="file__cloud">
          <img src="/assets/img/svg/icon-cloud.svg" />
        </span>
      </a>
    </div>
    <div
      class="links"
      *ngIf="data.items.groupTypeId === 'Files'"
    >
      <a
        class="file"
        target="_blank"
        [href]="link(file, data.roomId)"
        *ngFor="let file of data.items.attachments"
      >
        <span class="file__icon">
          <img src="/assets/img/svg/icon-file.svg" />
        </span>
        <span class="file__info">
          <span class="file__name">{{ file.sourceFileName }}</span>
          <span class="file__size">{{ file.fileSize | fileSize }}</span>
        </span>
        <span class="file__cloud">
          <img src="/assets/img/svg/icon-cloud.svg" />
        </span>
      </a>
    </div>
    <div
      class="images"
      *ngIf="data.items.groupTypeId === 'Images'"
    >
      <a
        class="image"
        target="_blank"
        [href]="image(file, data.roomId)"
        *ngFor="let file of data.items.attachments"
      >
        <img [src]="image(file, data.roomId)" />
      </a>
    </div>
  </div>
</div>
<button
  class="modal__close"
  (click)="close()"
>
  <img src="/assets/img/svg/icon-close.svg" />
</button>
