import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class WaitingroomService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  scheduledlessonactivatedPath = '/api/v1/waitingroom/{joinIdentity}/scheduledlessonactivated';

  scheduledlessonactivated(joinIdentity: string): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, this.scheduledlessonactivatedPath, 'post');
    rb.path('joinIdentity', joinIdentity, {});

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }
}
