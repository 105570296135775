import { ReferenceLettersDocument, UserProfile } from '@ezteach/api/models';
import { createReducer, on } from '@ngrx/store';
import { EducationDocumentTypes } from '../models/file.model';
import * as ProfileActions from './profile.actions';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  profile: UserProfile;
  title: string;
  isLoading: boolean;
  isSaving: boolean;
  isFileUploading: boolean;
  uploadedEducationDocumentType?: EducationDocumentTypes;
}

export const initialState: ProfileState = {
  profile: null,
  title: '',
  isLoading: false,
  isSaving: false,
  isFileUploading: false,
  uploadedEducationDocumentType: null,
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.profileLoadedSuccess, (state, profile) => ({ ...state, profile })),
  on(ProfileActions.saveProfile, (state, { profile }) => ({ ...state, profile, isSaving: true })),
  on(ProfileActions.saveProfileSuccess, state => ({ ...state, isSaving: false })),
  on(ProfileActions.saveProfileError, state => ({
    ...state,
    isSaving: false,
  })),
  on(ProfileActions.saveProfilePersonalDetails, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      personalDetails: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.saveProfilePassportDetails, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      passportDetails: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.setProfileTitle, (state, { title }) => ({ ...state, title })),
  on(ProfileActions.uploadProfilePassportFile, state => ({ ...state, isFileUploading: true })),
  on(ProfileActions.uploadProfilePassportFileSuccess, (state, value) => ({
    ...state,
    isFileUploading: false,
    profile: {
      ...state.profile,
      passportDetails: {
        ...state.profile.passportDetails,
        documentFiles: [...(state.profile.passportDetails?.documentFiles || []), ...value.data],
      },
    },
  })),
  on(ProfileActions.removeProfilePassportFileSuccess, (state, value) => ({
    ...state,
    profile: {
      ...state.profile,
      passportDetails: {
        ...state.profile.passportDetails,
        documentFiles: value.data || [],
      },
    },
  })),
  on(ProfileActions.removeWorkExperienceFileSuccess, (state, value) => ({
    ...state,
    profile: {
      ...state.profile,
      workExperience: {
        ...state.profile.workExperience,
        documentFiles: value.data || [],
      },
    },
  })),
  on(ProfileActions.removeReferenceLettersSuccess || ProfileActions.removeEducationFileSuccess, (state, value) => ({
      ...state,
      profile: {
        ...state.profile,
        referenceLetters: {
          ...state.profile.referenceLetters,
          documents: value.data || [],
        },
      },
    }
  )),
  on(ProfileActions.saveProfileReferenceLetters, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      referenceLetters: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.uploadReferenceLettersFile, state => ({ ...state, isFileUploading: true })),
  on(ProfileActions.uploadReferenceLettersFileSuccess, (state, value) => {
    const document = {
      documentFiles: value.data,
      documentTypeId: 'ReferenceLetter',
      userId: state.profile.userId,
    } as ReferenceLettersDocument;

    return {
      ...state,
      isFileUploading: false,
      profile: {
        ...state.profile,
        referenceLetters: {
          ...state.profile.referenceLetters,
          documents: [...state.profile.referenceLetters.documents, document],
        },
      },
    };
  }),
  on(ProfileActions.saveProfileFinancialDetails, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      financialDetails: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.uploadEducationFile, (state, value) => ({
    ...state,
    uploadedEducationDocumentType: value.contentType,
  })),
  on(ProfileActions.uploadEducationFileSuccess, (state, value) => {
    const document = {
      documentFiles: value.data,
      documentTypeId: 'ReferenceLetter',
      userId: state.profile.userId,
    } as ReferenceLettersDocument;

    return {
      ...state,
      isFileUploading: false,
      uploadedEducationDocumentType: null,
      profile: {
        ...state.profile,
        referenceLetters: {
          ...state.profile.referenceLetters,
          documents: [...state.profile.referenceLetters.documents, document],
        },
      },
    };
  }),
  on(ProfileActions.saveProfileEducation, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      educationDetails: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.saveProfilePatron, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      studentPatron: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.saveWorkExperience, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      workExperience: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.uploadWorkExperienceFileSuccess, (state, value) => ({
    ...state,
    isFileUploading: false,
    uploadedEducationDocumentType: null,
    profile: {
      ...state.profile,
      workExperience: {
        ...state.profile.workExperience,
        documentFiles: [...state.profile.workExperience.documentFiles, ...value.data],
      },
    },
  })),
  on(ProfileActions.saveBankCardBindings, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      bankCardBindings: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.updateBankCardBindings, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      bankCardBindings: newValue,
    },
  })),
  on(ProfileActions.saveSpecialityDetails, (state, newValue) => ({
    ...state,
    profile: {
      ...state.profile,
      specialityDetails: newValue,
    },
    isSaving: true,
  })),
  on(ProfileActions.updateUserAvatar, (state, newValue) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userAvatar: newValue,
      },
    };
  }),
);
