<ng-container *ngIf="this.diffY > 50">
    <div
        class="box"
        #boxElement
    >

        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 28 28"
        >

            <defs>
                <mask id="a1">
                    <circle
                        id='cc'
                        cx='12'
                        cy='12'
                        r='6'
                        fill='none'
                        stroke-width='12'
                        stroke='white'
                        stroke-dasharray='37.69911'
                        stroke-dashoffset='37.69911'
                        style="stroke-dashoffset: calc((1 - var(--strokePercent)) * 37.69911);
                     stroke-opacity: var(--strokePercent)"
                    />
                </mask>
                <filter id="f1">
                    <feDropshadow
                        dx="0"
                        dy="0"
                        stdDeviation="0.3"
                    />
                </filter>
            </defs>

            <circle
                cx="12"
                cy="12"
                r="13"
                fill="white"
                filter="url(#f1)"
            />

            <path
                d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
                mask="url(#a1)"
                fill="#678AFF"
                style="transform: rotate(calc(270deg * var(--percent) - 60deg)); 
                 transform-origin: 12px 12px"
            />
        </svg>

    </div>

</ng-container>
<ng-content></ng-content>