import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatLessonMemberRole } from '@ezteach/api/models/chat-lesson-member-permisson';
import { ChatRoomUserInformationApiResponse } from '@ezteach/api/models/chat-room-user-information';
import { LessonActiveCheckApiResponse } from '@ezteach/api/models/lesson-active-check-api-response';
import { CalendarEvent } from '@ezteach/calendar/models';
import { ApiResult } from '@ezteach/core/models/api-response.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { ChatLessonPagedApiResponse } from '../models';
import { BaseApiResponse } from '../models/base-api-response';
import {
  BatchChangePublishingPermissionRequest,
  BatchChatLessonMemberReponse,
} from '../models/batch-change-publishing-permission-request';
import { ChatLessonApiResponse, ChatLessonsApiResponse } from '../models/chat-lesson-api-response';
import { ChatLessonDeletedIdsApiResponse } from '../models/chat-lesson-deleted-ids-api-response';
import { ChangeFieldsApiResponse, ChangeFieldsRequest } from '../models/chat-lesson-fieldValue';
import { ChatLessonMemberPublishingStateValue, RequestChangePublishingStateWithId } from '../models/chat-lesson-member';
import { ChatRequestApiResponse } from '../models/chat-request-api-response';
import { ChatRequestIEnumerableApiResponse } from '../models/chat-request-i-enumerable-api-response';
import { CreateTokenResponseApiResponse } from '../models/create-token-response-api-response';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { LessonPaymentTypeEnum } from '../models/lesson-payment-type-enum';
import { BatchChangeReactionRequest } from '../models/lesson/chat-lesson-reaction';
import { ReactionTypeEnum } from '../models/lesson/reaction-enum';
import { LessonProlongData } from '../models/prolong-data';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class LessonsService extends BaseService {
  isProlongModalVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  canProlong$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  canProlongData$: BehaviorSubject<LessonProlongData> = new BehaviorSubject<LessonProlongData>(null);
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1LessonsInstantRequestsPendingGet
   */
  static readonly ApiV1LessonsInstantRequestsPendingGetPath = '/api/v1/lessons/instant/requests/pending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsInstantRequestsPendingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsPendingGet$Response(params?: {}): Observable<
    StrictHttpResponse<ChatRequestIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsInstantRequestsPendingGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRequestIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsInstantRequestsPendingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsPendingGet(params?: {}): Observable<ChatRequestIEnumerableApiResponse> {
    return this.apiV1LessonsInstantRequestsPendingGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRequestIEnumerableApiResponse>) => r.body as ChatRequestIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsInstantRequestsRequestIdGet
   */
  static readonly ApiV1LessonsInstantRequestsRequestIdGetPath = '/api/v1/lessons/instant/requests/{requestId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsInstantRequestsRequestIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdGet$Response(params: {
    requestId: number;
  }): Observable<StrictHttpResponse<ChatRequestApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsInstantRequestsRequestIdGetPath, 'get');
    if (params) {
      rb.path('requestId', params.requestId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRequestApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsInstantRequestsRequestIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdGet(params: { requestId: number }): Observable<ChatRequestApiResponse> {
    return this.apiV1LessonsInstantRequestsRequestIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRequestApiResponse>) => r.body as ChatRequestApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsInstantRequestsPost
   */
  static readonly ApiV1LessonsInstantRequestsPostPath = '/api/v1/lessons/instant/requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsInstantRequestsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsInstantRequestsPost$Response(params?: {
    body?: { CalleeId?: number; SubjectTitle?: string; DurationMinutes?: number; MessageText?: string };
  }): Observable<StrictHttpResponse<ChatRequestApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsInstantRequestsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRequestApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsInstantRequestsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsInstantRequestsPost(params?: {
    body?: { CalleeId?: number; SubjectTitle?: string; DurationMinutes?: number; MessageText?: string };
  }): Observable<ChatRequestApiResponse> {
    return this.apiV1LessonsInstantRequestsPost$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRequestApiResponse>) => r.body as ChatRequestApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsInstantRequestsRequestIdCancelPatch
   */
  static readonly ApiV1LessonsInstantRequestsRequestIdCancelPatchPath =
    '/api/v1/lessons/instant/requests/{requestId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsInstantRequestsRequestIdCancelPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdCancelPatch$Response(params: {
    requestId: number;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LessonsService.ApiV1LessonsInstantRequestsRequestIdCancelPatchPath,
      'patch',
    );
    if (params) {
      rb.path('requestId', params.requestId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsInstantRequestsRequestIdCancelPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdCancelPatch(params: { requestId: number }): Observable<BaseApiResponse> {
    return this.apiV1LessonsInstantRequestsRequestIdCancelPatch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsInstantRequestsRequestIdAcceptPatch
   */
  static readonly ApiV1LessonsInstantRequestsRequestIdAcceptPatchPath =
    '/api/v1/lessons/instant/requests/{requestId}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsInstantRequestsRequestIdAcceptPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdAcceptPatch$Response(params: {
    requestId: number;
    paymentType: LessonPaymentTypeEnum;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LessonsService.ApiV1LessonsInstantRequestsRequestIdAcceptPatchPath,
      'patch',
    );
    if (params) {
      rb.path('requestId', params.requestId, {});
    }

    if (params.paymentType == LessonPaymentTypeEnum.Free) {
      rb.query('lessonPaymentType', params.paymentType);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsInstantRequestsRequestIdAcceptPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsInstantRequestsRequestIdAcceptPatch(params: {
    requestId: number;
    paymentType: LessonPaymentTypeEnum;
  }): Observable<BaseApiResponse> {
    return this.apiV1LessonsInstantRequestsRequestIdAcceptPatch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdFinalizePatch
   */
  static readonly ApiV1LessonsLessonIdFinalizePatchPath = '/api/v1/lessons/{lessonId}/finalize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdFinalizePatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdFinalizePatch$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdFinalizePatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdFinalizePatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdFinalizePatch(params: { lessonId: number }): Observable<Int32ApiResponse> {
    return this.apiV1LessonsLessonIdFinalizePatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdBeginReclamationPatch
   */
  static readonly ApiV1LessonsLessonIdBeginReclamationPatchPath = '/api/v1/lessons/{lessonId}/begin-reclamation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdBeginReclamationPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdBeginReclamationPatch$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdBeginReclamationPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdBeginReclamationPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdBeginReclamationPatch(params: { lessonId: number }): Observable<Int32ApiResponse> {
    return this.apiV1LessonsLessonIdBeginReclamationPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdFinishPatch
   */
  static readonly ApiV1LessonsLessonIdFinishPatchPath = '/api/v1/lessons/{lessonId}/finish';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdFinishPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdFinishPatch$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdFinishPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdFinishPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdFinishPatch(params: { lessonId: number }): Observable<Int32ApiResponse> {
    return this.apiV1LessonsLessonIdFinishPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  static readonly ApiV1LessonsLessonIdMediaTokenPostPath = '/api/v1/lessons/{lessonId}/media/token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdMediaTokensPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdMediaTokenPost$Response(params: {
    lessonId: number;
    body: { publishingState: ChatLessonMemberPublishingStateValue[]; connectionId: string };
  }): Observable<StrictHttpResponse<CreateTokenResponseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdMediaTokenPostPath, 'post');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CreateTokenResponseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdMediaTokensPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdMediaTokenPost(params: {
    lessonId: number;
    body: { publishingState: ChatLessonMemberPublishingStateValue[]; connectionId: string };
  }): Observable<CreateTokenResponseApiResponse> {
    return this.apiV1LessonsLessonIdMediaTokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<CreateTokenResponseApiResponse>) => r.body as CreateTokenResponseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdCancelPatch
   */
  static readonly ApiV1LessonsLessonIdCancelPatchPath = '/api/v1/lessons/{lessonId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdCancelPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdCancelPatch$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdCancelPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdCancelPatch
   */
  static readonly ApiV1LessonsCancelPatchPath = '/api/v1/lessons/cancel?joinIdentity=';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdCancelPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsCancelPatchPath$Response(params: {
    joinIdentity: string;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LessonsService.ApiV1LessonsCancelPatchPath + params.joinIdentity,
      'patch',
    );

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdCancelPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdCancelPatch(params: { lessonId: number }): Observable<Int32ApiResponse> {
    return this.apiV1LessonsLessonIdCancelPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdProlongPatch
   */
  static readonly ApiV1LessonsLessonIdProlongPatchPath = '/api/v1/lessons/{lessonId}/prolong';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdProlongPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsLessonIdProlongPatch$Response(params: {
    lessonId: number;
    body?: { DurationMinutes?: number };
  }): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdProlongPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdProlongPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsLessonIdProlongPatch(params: {
    lessonId: number;
    body?: { DurationMinutes?: number };
  }): Observable<ChatLessonApiResponse> {
    return this.apiV1LessonsLessonIdProlongPatch$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonApiResponse>) => r.body as ChatLessonApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdReclamationsPost
   */
  static readonly ApiV1LessonsLessonIdReclamationsPostPath = '/api/v1/lessons/{lessonId}/reclamations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdReclamationsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsLessonIdReclamationsPost$Response(params: {
    lessonId: string;
    body?: { ChatLessonId?: number; Description?: string; ReclamationItemIds?: Array<number> };
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdReclamationsPostPath, 'post');
    if (params) {
      rb.path('lessonId', params.lessonId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdReclamationsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1LessonsLessonIdReclamationsPost(params: {
    lessonId: string;
    body?: { ChatLessonId?: number; Description?: string; ReclamationItemIds?: Array<number> };
  }): Observable<Int32ApiResponse> {
    return this.apiV1LessonsLessonIdReclamationsPost$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsActiveGet
   */
  static readonly ApiV1LessonsActiveGetPath = '/api/v1/lessons/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsActiveGet$Response(params?: {}): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsActiveGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  /**
   * @deprecated The method should not be used
   */
  apiV1LessonsActiveGet(params?: {}): Observable<ChatLessonApiResponse> {
    return this.apiV1LessonsActiveGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonApiResponse>) => r.body as ChatLessonApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsGet
   */
  static readonly ApiV1LessonsHistoryGetPath = '/api/v1/lessons/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsHistoryGet$Response(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<ChatLessonPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsHistoryGetPath, 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsHistoryGet(params?: { PageNumber?: number; PageSize?: number }): Observable<ChatLessonPagedApiResponse> {
    return this.apiV1LessonsHistoryGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonPagedApiResponse>) => r.body as ChatLessonPagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsRemovedDelete
   */
  static readonly ApiV1LessonsRemovedDeletePath = '/api/v1/lessons/{lessonId}/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsRemovedDelete()` instead.
   *
   * This method doesn't expect any request body.history
   */
  private apiV1LessonsRemovedDelete$Response(params?: {
    lessonId: number;
  }): Observable<StrictHttpResponse<ChatLessonDeletedIdsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsRemovedDeletePath, 'delete');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonDeletedIdsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsRemovedDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsRemovedDelete(params?: { lessonId: number }): Observable<ChatLessonDeletedIdsApiResponse> {
    return this.apiV1LessonsRemovedDelete$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonDeletedIdsApiResponse>) => r.body as ChatLessonDeletedIdsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsRemovedGet
   */
  static readonly ApiV1LessonsRemovedGetPath = '/api/v1/lessons/removed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsRemovedGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  private apiV1LessonsRemovedGet$Response(params?: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<ChatLessonPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsRemovedGetPath, 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsRemovedGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsRemovedGet(params?: { PageNumber?: number; PageSize?: number }): Observable<ChatLessonPagedApiResponse> {
    return this.apiV1LessonsRemovedGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonPagedApiResponse>) => r.body as ChatLessonPagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsGet
   */
  static readonly ApiV1LessonsGetPath = '/api/v1/lessons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsGet$Response(): Observable<StrictHttpResponse<ChatLessonsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsGetPath, 'get');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonsApiResponse>),
      );
  }

  apiV1LessonsGet(): Observable<ChatLessonsApiResponse> {
    return this.apiV1LessonsGet$Response().pipe(
      map((r: StrictHttpResponse<ChatLessonsApiResponse>) => r.body as ChatLessonsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsHistoryLessonIdGet
   */
  static readonly ApiV1LessonsHistoryLessonIdGetPath = '/api/v1/lessons/{lessonId}/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsHistoryLessonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsHistoryLessonIdGet$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsHistoryLessonIdGetPath, 'get');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsHistoryLessonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsHistoryLessonIdGet(params: { lessonId: number }): Observable<ChatLessonApiResponse> {
    return this.apiV1LessonsHistoryLessonIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonApiResponse>) => r.body as ChatLessonApiResponse),
    );
  }

  /**
   * Path part for operation apiV1LessonsLessonIdGet
   */
  static readonly ApiV1LessonsLessonIdGetPath = '/api/v1/lessons/{lessonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LessonsLessonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdGet$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsLessonIdGetPath, 'get');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LessonsLessonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LessonsLessonIdGet(params: { lessonId: number }): Observable<ChatLessonApiResponse> {
    return this.apiV1LessonsLessonIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatLessonApiResponse>) => r.body as ChatLessonApiResponse),
    );
  }

  static readonly ApiV1LessonsJoinGetPath = '/api/v1/calendar/{lessonId}/join';

  join(params: { scheduledLessonId: number }): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsJoinGetPath, 'patch');
    if (params) {
      rb.path('lessonId', params.scheduledLessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly ApiV1LessonsWaitPath = '/api/v1/lessons/wait';

  wait(params: { waitId: number }): Observable<StrictHttpResponse<ChatLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonsWaitPath, 'get');
    if (params) {
      rb.query('waitId', params.waitId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatLessonApiResponse>),
      );
  }

  static readonly ApiV1CalendarJoinIdentityPath = '/api/v1/calendar/event';

  calendarJoinIdentity(params: {
    joinIdentity: string;
    inviteIdentity: string;
  }): Observable<StrictHttpResponse<CalendarEvent>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1CalendarJoinIdentityPath, 'get');
    if (params) {
      rb.query('joinIdentity', params.joinIdentity, {});
      rb.query('inviteIdentity', params.inviteIdentity, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly ApiV1LessonJointPath = '/api/v1/lessons/joinme';

  joinLesson(params: { joinIdentity; inviteIdentity }): Observable<ChatLessonApiResponse> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonJointPath, 'patch');
    if (params) {
      rb.query('joinIdentity', params.joinIdentity);
      rb.query('inviteIdentity', params.inviteIdentity);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body as ChatLessonApiResponse),
      );
  }

  static readonly ApiBatchChangePublishinPermissionPath = '/api/v1/lessons/{lessonId}/members/publishingPermissions';

  batchChangePublishinPermission(params: { lessonId: number; body: BatchChangePublishingPermissionRequest }) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiBatchChangePublishinPermissionPath, 'patch');
    if (params) {
      console.log(params.body);
      rb.path('lessonId', params.lessonId, {});
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BatchChatLessonMemberReponse>),
      );
  }

  static readonly chatLessonMemberRoleChangedPath = '/api/v1/lessons/{lessonId}/members/{userId}/role';

  chatLessonMemberRoleChanged(params: { lessonId: number; userId: number; newRole: ChatLessonMemberRole }) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.chatLessonMemberRoleChangedPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
      rb.path('userId', params.userId, {});
      rb.body({ newRole: params.newRole }, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly changePublishingStatePath = '/api/v1/lessons/{lessonId}/members/publishingState';
  changePublishingState(params: { lessonId: number; newPublishingState: ChatLessonMemberPublishingStateValue[] }) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.changePublishingStatePath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
      rb.body({ newPublishingState: params.newPublishingState }, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly onMemberLeavePath = '/api/v1/lessons/leaveme';

  leaveLesson(params: { joinIdentity }) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.onMemberLeavePath, 'patch');
    if (params) {
      rb.query('joinIdentity', params.joinIdentity);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly getChatLessonMemberPath = '/api/v1/lessons/members/{memberId}';

  getChatLessonMember(params: { memberId: number }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.getChatLessonMemberPath, 'get');
    if (params.memberId) {
      rb.path('memberId', params.memberId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly lessonExtensionDataPath = '/api/v1/lessons/{lessonId}/prolong';

  lessonExtensionData(lessonId: number) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.lessonExtensionDataPath, 'get');
    if (lessonId) {
      rb.path('lessonId', lessonId);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly chatUnreadMessagesCheckPath = '/api/v1/chat/messages/{chatRoomId}/me';

  chatUnreadMessagesCheck(chatRoomId: string) {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.chatUnreadMessagesCheckPath, 'get');
    if (chatRoomId) {
      rb.path('chatRoomId', chatRoomId);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map(
          (r: HttpResponse<ChatRoomUserInformationApiResponse>) =>
            r as StrictHttpResponse<ChatRoomUserInformationApiResponse>,
        ),
      );
  }

  static readonly activeLessonsCheckPath = '/api/v1/lessons/me';

  activeLessonsCheck() {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.activeLessonsCheckPath, 'get');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<LessonActiveCheckApiResponse>) => r as StrictHttpResponse<LessonActiveCheckApiResponse>),
      );
  }

  static readonly chatLessonReactionsPath = '/api/v1/lessons/{lessonId}/members/reactions';

  chatLessonReactionsPost(params?: {
    lessonId: number;
    body?: { type: ReactionTypeEnum; show: boolean };
  }): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.chatLessonReactionsPath, 'post');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly chatLessonReactionsPatch = '/api/v1/lessons/{lessonId}/moderation/members/reactions';

  chatLessonReactionsPatch(params?: {
    lessonId: string;
    body?: BatchChangeReactionRequest;
  }): Observable<StrictHttpResponse<any>> {
    console.log('params', params);
    const rb = new RequestBuilder(this.rootUrl, LessonsService.chatLessonReactionsPatch, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  static readonly ApiV1CourseDataGetPath = '/api/v1/course/data';

  apiV1CourseDataGet$Response(): Observable<any> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1CourseDataGetPath, 'get');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  apiV1CourseDataGet(): Observable<any> {
    return this.apiV1CourseDataGet$Response();
  }

  static readonly ApiV1LessonFieldsPutPath = '/api/v1/lessons/{lessonId}/fields';

  apiV1LessonFieldsPutPath$Response(
    request: ChangeFieldsRequest,
    lessonId: number,
  ): Observable<StrictHttpResponse<ChangeFieldsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonFieldsPutPath, 'put');
    rb.path('lessonId', lessonId, {});
    rb.body(request, 'application/json');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<ChangeFieldsApiResponse>) => r as StrictHttpResponse<ChangeFieldsApiResponse>),
      );
  }

  static readonly ApiV1LessonRequestChangePublishingStatePutPath =
    '/api/v1/lessons/{lessonId}/members/publishingState/change';

  apiV1LessonRequestChangePublishingState$Response(
    request: RequestChangePublishingStateWithId,
    lessonId: number,
  ): Observable<StrictHttpResponse<ChangeFieldsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LessonsService.ApiV1LessonRequestChangePublishingStatePutPath, 'put');
    rb.path('lessonId', lessonId, {});
    rb.body(request, 'application/json');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<ChangeFieldsApiResponse>) => r as StrictHttpResponse<ChangeFieldsApiResponse>),
      );
  }
  addedHomeworkInLesson(param: { lessonId: number; homeWorkTemplateId: number }) {
    return this.http.patch<ApiResult<{}>>(this.rootUrl + `/api/v1/calendar/${param.lessonId}/homework?homeWorkTemplateId=${param.homeWorkTemplateId}`, {});
  }

  addHomework({ lessonId, homeWorkTemplateId }) {
    return this.http.patch<ApiResult<{}>>(`${this.rootUrl}/api/v1/lessons/${lessonId}/homework?homeWorkTemplate=${homeWorkTemplateId}`, {});
  }
}
