<div class="container">
  <div class="container__title"> {{ 'Финансы' | transloco }} </div>
  <div class="container__nav">
    <div
      class="container__nav-item"
      (click)="activateNavItem(navItem)"
      *ngFor="let navItem of navItems"
      [ngClass]="navItem.isActive ? 'active' : ''"
    >
      {{ navItem.name | transloco }}
    </div>
  </div>
  <div class="container__nav-content">
    <ezteach-widget-finance
      *ngIf="navItems[0].isActive"
      [isHalfView]="true"
      [isNavItem]="true"
    ></ezteach-widget-finance>
    <ezteach-finance-history
      *ngIf="navItems[1].isActive"
      [isHalfView]="true"
      [isNavItem]="true"
    ></ezteach-finance-history>
  </div>
</div>