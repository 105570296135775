import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ezteach-date-label',
  template: `
    <ezteach-icon-label
      icon="/assets/img/svg/calendar-outline.svg"
      [label]="date | date: 'dd MMMM'"
    ></ezteach-icon-label>
  `,
  styleUrls: ['./_style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DateLabelComponent {
  @HostBinding('class.ezteach-icon-label') className = true;
  @Input() date: Date | string;
}
