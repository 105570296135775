import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()

// в качестве основы взял файл локализации расширения moment.js
export class LocalDateNameSpaceService {
  constructor(private translocoService: TranslocoService) {}
  getTimeName(word, num) {
    const forms = word.split('_');
    return num % 10 === 1 && num % 100 !== 11
      ? forms[0]
      : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
      ? forms[1]
      : forms[2];
  }

  relativeTimeName(number, withoutSuffix, key) {
    const format = {
      second: withoutSuffix
        ? this.translocoService.translate('секунда_секунды_секунд')
        : this.translocoService.translate('секунду_секунды_секунд'),
      minute: withoutSuffix
        ? this.translocoService.translate('минута_минуты_минут')
        : this.translocoService.translate('минуту_минуты_минут'),
      hour: this.translocoService.translate('час_часа_часов'),
      day: this.translocoService.translate('день_дня_дней'),
      week: this.translocoService.translate('неделя_недели_недель'),
      month: this.translocoService.translate('месяц_месяца_месяцев'),
      year: this.translocoService.translate('год_года_лет'),
    };
    if (key === 'm') {
      return withoutSuffix ? this.translocoService.translate('минута') : this.translocoService.translate('минуту');
    } else {
      return number + ' ' + this.getTimeName(format[key], +number);
    }
  }
}
