<ezteach-tutorial-dialog-wrapper [isSkippedToLastStep]="false">
  <ezteach-stepper [allDone]="allDone">
    <cdk-step>
      <img
        class="ezteach-tutorial-dialog__image"
        [attr.src]="'/assets/img/tutorial/onboarding-student/welcome1.svg' | cachedImage"
      />
      <h1 class="ezteach-tutorial-dialog__heading"> {{ 'Добро пожаловать' | transloco }}!</h1>
      <p class="ezteach-tutorial-dialog__info">
        {{ 'Давайте сделаем так, чтобы вам было легко учиться! Вы сможете быстро найти себе организатора' | transloco
        }}
      </p>
      <div class="ezteach-tutorial-dialog__actions">
        <ezteach-button
          minWidth="248px"
          (click)="goNextStep()"
        > {{ 'Начнем' | transloco }}!</ezteach-button>
      </div>
    </cdk-step>

    <cdk-step>
      <img
        class="ezteach-tutorial-dialog__image"
        [attr.src]="'/assets/img/tutorial/onboarding-student/welcome2.png' | cachedImage"
      />
      <h1 class="ezteach-tutorial-dialog__heading"> {{ 'Наш быстрый поиск' | transloco }}</h1>
      <p class="ezteach-tutorial-dialog__info">
        {{ 'Если вы хотите попробовать позаниматься прямо сейчас, выберите быстрый поиск — он покажет только тех организаторов, которые сейчас онлайн' | transloco }}.
      </p>
      <div class="ezteach-tutorial-dialog__actions">
        <a
          class="ezteach-tutorial-dialog__link-button"
          (click)="goPrevStep()"
        > {{ 'Назад' | transloco }}</a>
        <ezteach-button
          minWidth="136px"
          (click)="goNextStep()"
        > {{ 'Далее' | transloco }}</ezteach-button>
      </div>
    </cdk-step>

    <cdk-step>
      <img
        class="ezteach-tutorial-dialog__image"
        [attr.src]="'/assets/img/tutorial/onboarding-student/welcome3.svg' | cachedImage"
      />
      <h1 class="ezteach-tutorial-dialog__heading"> {{ 'Записаться заранее' | transloco }}</h1>
      <p class="ezteach-tutorial-dialog__info">
        {{ 'Еще можно записаться заранее — используйте «Поиск организатора», найдите нужного организатора и перейдите в его расписание' | transloco }}.
      </p>
      <div class="ezteach-tutorial-dialog__actions">
        <a
          class="ezteach-tutorial-dialog__link-button"
          (click)="goPrevStep()"
        > {{ 'Назад' | transloco }}</a>
        <ezteach-button
          minWidth="136px"
          (click)="goNextStep()"
        > {{ 'Готово' | transloco }}!</ezteach-button>
      </div>
    </cdk-step>
  </ezteach-stepper>
</ezteach-tutorial-dialog-wrapper>
