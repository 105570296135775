import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LessonsService } from '@ezteach/api/services';

@Component({
  selector: 'app-select-history-lesson',
  templateUrl: './select-history-lesson.component.html',
  styleUrls: ['./select-history-lesson.component.scss'],
})
export class SelectHistoryLessonComponent implements OnInit {
  search = '';
  allLessons = [];
  filtered = [];
  loading = true;
  @Output()
  selectedChanged = new EventEmitter();
  constructor(private lessonsService: LessonsService, private cdr: ChangeDetectorRef) {}

  async ngOnInit() {
    this.allLessons = (
      await this.lessonsService.apiV1LessonsHistoryGet({ PageNumber: 1, PageSize: 200 }).toPromise()
    )?.data
      ?.filter(x => x.postProcessingCompleted)
      ?.map(x => ({ id: x.id, name: x.discipline?.name, lessonStartDate: x.lessonStartDate, selected: false }));
    this.filtered = this.allLessons;
    this.loading = false;
    this.cdr.detectChanges();
  }

  changed($event) {
    if (!$event) {
      this.filtered = this.allLessons;
    }
    this.filtered = this.allLessons.filter(
      x => x?.name?.toUpperCase().indexOf($event?.toUpperCase()) !== -1 && x?.name,
    );
  }

  selectState(lesson, selected: boolean) {
    lesson.selected = selected;
    this.selectedChanged.emit(this.allLessons.filter(x => x.selected));
  }
}
