import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
  isFileUploading,
  isProfileSaving,
  removeWorkExperienceFile,
  saveWorkExperience,
  selectWorkExperience,
  setProfileTitle,
  uploadWorkExperienceFile,
} from '../../+state';
import { environment } from '../../../../environments/environment';
import { WorkExperience, WorkPlace } from '../../../api/models';
import { getDate } from '../../../shared/helpers/helpers';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-work',
  templateUrl: './profile-work.component.html',
  styleUrls: ['./profile-work.component.scss'],
})
export class ProfileWorkComponent implements OnInit, OnDestroy {
  user: any;
  workExperience: WorkExperience;
  form: UntypedFormGroup;

  saving = false;
  uploading = false;

  maxDate = new Date();
  minDate = new Date();

  title = $localize`Трудовая история`;

  token: string;
  environment = environment;

  saving$: Observable<boolean>;
  subscriptions: Subscription = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileLocalStorageService: ProfileLocalStorageService,
    private authStorage: OAuthStorage,
    private store: Store,
  ) {
    this.minDate.setFullYear(this.maxDate.getFullYear() - 50);
  }

  ngOnInit() {
    this.createForm();

    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.token = this.authStorage.getItem('access_token');
    this.subscribeStore();
  }

  subscribeStore() {
    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectWorkExperience),
        filter(workExperience => !!workExperience),
        tap((workExperience: WorkExperience) => {
          this.workExperience = workExperience;
          this.updateForm(workExperience);
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving));

    this.store
      .pipe(
        untilDestroyed(this),
        select(isFileUploading),
        tap((uploading: boolean) => {
          this.uploading = uploading;
        }),
      )
      .subscribe();
  }

  createForm() {
    this.form = this.formBuilder.group({
      workPlaces: this.formBuilder.array([], Validators.required),
      documentFiles: [[]],
    });
  }

  updateForm(data: WorkExperience) {
    this.form = this.formBuilder.group({
      workPlaces: this.formBuilder.array([]),
      documentFiles: [data.documentFiles || []],
    });
    data.workPlaces.forEach(work => this.addWork(work));
  }

  get works() {
    return this.form.get('workPlaces') as UntypedFormArray;
  }

  get documents() {
    return this.form.get('documentFiles') as UntypedFormControl;
  }

  addWork(work: WorkPlace = null) {
    this.works.push(
      this.formBuilder.group({
        companyName: [work ? work.companyName : '', Validators.required],
        enrollmentDate: [work ? work.enrollmentDate : '', Validators.required],
        leavingDate: [work ? work.leavingDate : ''],
      }),
    );
  }

  removeWork(index: number) {
    this.works.removeAt(index);
  }

  setProfile() {
    const value = this.form.value;
    const workExperience = {
      ...this.workExperience,
      ...value,
      workPlaces: value.workPlaces.map((work: any) => ({
        ...work,
        enrollmentDate: getDate(work.enrollmentDate),
        leavingDate: getDate(work.leavingDate),
      })),
    };

    this.store.dispatch(saveWorkExperience(workExperience));
  }

  addFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;
      const form = new FormData();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < files.length; x++) {
        const file = files[x];
        form.append('files', file);
      }

      const data = {
        contentType: 'work-experience',
        body: {
          files: form.getAll('files'),
          ownerEntityId: `${this.user.id}`,
        },
      };

      this.store.dispatch(uploadWorkExperienceFile(data));
      event.target.value = '';
    }
  }

  removeFile(index: number) {
    const value = this.documents.value.filter((file, i) => i !== index);
    this.documents.patchValue(value);

    this.store.dispatch(removeWorkExperienceFile({ data: this.documents.value }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
