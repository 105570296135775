import { NgModule } from '@angular/core';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { BlocksModule } from '@ezteach/blocks/blocks.module';
import { VideoViewComponent } from '@ezteach/my-lessons/components/video-view/video-view.component';
import { PlaybackHoverDirective } from '@ezteach/my-lessons/directives/playback-hover.directive';
import { SharedModule } from '@ezteach/shared';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { MyLessonsWidgetModule } from '@ezteach/widgets/my-lessons-widget/my-lessons-widget.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MyLessonItemComponent } from './components/lesson-item/lesson-item.component';
import { MyLessonsComponent } from './my-lessons.component';
import { MyLessonsRoutingModule } from './my-lessons.routing.module';

@NgModule({
  imports: [MyLessonsRoutingModule, MyLessonsWidgetModule, ButtonModule, SharedModule, TranslocoRootModule, IconSpriteModule, BlocksModule],
  declarations: [MyLessonsComponent, VideoViewComponent, PlaybackHoverDirective, MyLessonItemComponent],
  exports: [MyLessonsComponent],
})
export class MyLessonsModule { }
