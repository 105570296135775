import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { ShapeTextAttrsConfig } from '../../shapes/shape-attrs-config';

/** Класс для форматирования текста */
@Injectable()
export class TextShapeToolsFormatter {
  private attrs?: ShapeTextAttrsConfig;
  constructor(@Inject(WINDOW) private windowRef: Window, @Inject(DOCUMENT) private documentRef: Document) {}

  setAttrs(attrs: ShapeTextAttrsConfig): void {
    this.attrs = attrs;
  }

  getSelection(): Selection {
    return this.windowRef.getSelection() ?? this.documentRef.getSelection();
  }

  /** Возвращает установлен ли у элеменнта параметр command */
  isFormat(command: FormatCommands): boolean {
    const style = this.attrs;
    if (!style) return false;
    switch (command) {
      case 'fontSize':
        return !!style.fontSize;
      case 'fontName':
        return !!style.fontFamily;
      case 'underline':
        return !!style.textDecoration && style.textDecoration === 'underline';
      case 'italic':
        return !!style.fontStyle && style.fontStyle === 'italic';
      case 'bold':
        return !!style.fontWeight && style.fontWeight === 'bold';
      case 'justifyfull':
        return !!style.textAlign && style.textAlign === 'justify';
      case 'justifyleft':
        return !!style.textAlign && style.textAlign === 'left';
      case 'justifyright':
        return !!style.textAlign && style.textAlign === 'right';
      case 'justifycenter':
        return !!style.textAlign && style.textAlign === 'center';
    }
  }

  /** Форматирование документа */
  formatDoc(command: FormatCommands, value?: string): ShapeTextAttrsConfig {
    let attrs = this.attrs as ShapeTextAttrsConfig;
    switch (command) {
      case 'fontSize':
        attrs = { ...attrs, fontSize: Number(value) };
        break;
      case 'fontName':
        attrs = { ...attrs, fontFamily: value };
        break;
      case 'underline':
        attrs = { ...attrs, textDecoration: toggleNormalIfValue(attrs.textDecoration, 'underline') };
        break;
      case 'italic':
        attrs = { ...attrs, fontStyle: toggleNormalIfValue(attrs.fontStyle, 'italic') };
        break;
      case 'bold':
        attrs = { ...attrs, fontWeight: toggleNormalIfValue(attrs.fontWeight, 'bold') };
        break;
      case 'justifyfull':
        attrs = { ...attrs, textAlign: 'justify' };
        break;
      case 'justifyleft':
        attrs = { ...attrs, textAlign: 'left' };
        break;
      case 'justifyright':
        attrs = { ...attrs, textAlign: 'right' };
        break;
      case 'justifycenter':
        attrs = { ...attrs, textAlign: 'center' };
        break;
    }
    return attrs;
  }
}

@Injectable()
export class TextShapeToolsFormatter2 {
  private editor?: HTMLElement;
  constructor(@Inject(WINDOW) private windowRef: Window, @Inject(DOCUMENT) private documentRef: Document) {}

  setEditor(element: HTMLElement): void {
    this.editor = element;
  }

  getSelection(): Selection {
    return this.windowRef.getSelection() ?? this.documentRef.getSelection();
  }

  /** Возвращает установлен ли у элеменнта параметр command */
  isFormat(command: FormatCommands): boolean {
    let result = false;
    if (this.documentRef.queryCommandState) {
      result = this.documentRef.queryCommandState(command);
    }
    return result;
  }

  /** Форматирование документа */
  formatDoc(command: FormatCommands, value?: string): boolean {
    const result = this.documentRef.execCommand(command, false, value);
    this.editor?.focus();
    return result;
  }
}

export type FormatCommands =
  | 'fontSize'
  | 'fontName'
  | 'underline'
  | 'italic'
  | 'bold'
  | 'justifyfull'
  | 'justifyleft'
  | 'justifyright'
  | 'justifycenter';

function toggleNormalIfValue(actual: string, value: string): string {
  return actual === value ? 'normal' : value;
}
