import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskInfo } from '@ezteach/blocks/components/block/block.component';

@Component({
  selector: 'ezteach-block-task',
  templateUrl: './block-task.component.html',
  styleUrls: ['./block-task.component.scss'],
})
export class BlockTaskComponent implements OnInit {
  @Input() task: TaskInfo;
  @Input() index: number;
  @Input() isMobile: boolean;
  @Input() isEdit: boolean;

  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  deleteTask() {
    this.onRemove.emit(this.task.id);
  }
}
