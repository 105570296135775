import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LessonsService } from '../api/services/lessons.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveService {
  lesson;
  isActive = false;
  onChanged = new EventEmitter<{}>();
  lessonFormat: string;
  activeTemporarySolution$ = new BehaviorSubject<boolean>(false);

  constructor(private lessonsService: LessonsService) {}

  empty() {
    this.activeTemporarySolution$.next(false);
    this.isActive = false;
    this.onChanged.emit(true);
  }
  
 /**
 * @deprecated The method should not be used
 */
  update() {
    //fix: remove
    this.lessonsService.apiV1LessonsActiveGet().subscribe(response => {
      if (response.data) {
        this.lesson = response.data;
        if (response.data.id) {
          this.isActive = true;
          this.activeTemporarySolution$.next(true);
          this.lessonFormat = this.lesson.lessonFormatId;
          this.onChanged.emit({
            active: this.isActive,
            type: this.lessonFormat,
          });
        }
      }
    });
  }

  status() {
    return {
      active: this.isActive,
      type: this.lessonFormat, //todo remove
    };
  }
}
