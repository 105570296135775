import * as moment from 'moment';

export function fixFullcalendarBugOffset(d: Date) {
  return moment(d).add(d.getTimezoneOffset(), 'minutes').toDate();
}

/*
Проблема
Календарь считает, что он работает в UTC.
Поэтому, в событиях т.н. ресайз\перетаскивание start, end в "локальном" для календаря времени (по сути это вдвойне локальное время).
*/
export function fromCalendarPseudoLocalToUtc(date: Date): moment.Moment {
  return moment(date).add(date.getTimezoneOffset() * 2, 'minutes');
}

export function dateTimeToApiText(date: moment.Moment): string {
  return date.format('YYYY-MM-DDTHH:mm:ss');
}
