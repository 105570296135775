import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ezteach-group-lesson-dialog-first-load',
  templateUrl: './group-lesson-dialog-first-load.component.html',
  styleUrls: ['./group-lesson-dialog-first-load.component.scss'],
})
export class GroupLessonDialogFirstLoadComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GroupLessonDialogFirstLoadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
