import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';

@Component({
  selector: 'ezteach-not-available-waitid-popup',
  templateUrl: './not-available-waitid-popup.component.html',
  styleUrls: ['./not-available-waitid-popup.component.scss'],
})
export class NotAvailableWaitidPopupComponent implements OnInit {
  smileyType = SmileyType;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  back() {
    this.router.navigate(['/']);
  }
}
