export enum OnboardingStudentSteps {
  WELCOME1 = 'WELCOME1',
  WELCOME2 = 'WELCOME2',
  WELCOME3 = 'WELCOME3',
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
  STEP5 = 'STEP5',
  STEP6 = 'STEP6',
}
