import { Injectable } from '@angular/core';
import { environment } from '@ezteach/enviroments';

/*
 * Сервис отключает лог в консоль в прод версии приложения
 * Для логирования в прод версии можно использовать console.info
 */
@Injectable({ providedIn: 'root' })
export class ConsoleLogSpyService {
  constructor() {
    if (environment.name === 'production') {
      if (typeof console !== 'undefined') {
        console.log = () => {};
        console.warn = () => {};
      }
    }
  }
}
