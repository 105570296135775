<div
  class="requests2__header"
  *ngIf="requests.length > 0"
>
  <span>{{"Управление запросами" | transloco}}</span>
</div>
<div
  class="requests2__content"
  *ngIf="requests.length > 0"
>
  <requests-student-item
    [request]="request"
    class="requests2_item"
    *ngFor="let request of requests"
  ></requests-student-item>
</div>
