import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@ezteach/shared';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationLessonComponent } from './notification-lesson-item/notification-lesson.component';
import { NotificationsFilterPipe } from './notifications-filter.pipe';
import { NotificationsNotFoundComponent } from './notifications-not-found/notifications-not-found.component';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  imports: [CommonModule, SharedModule, TranslocoRootModule],
  exports: [NotificationsComponent],
  declarations: [
    NotificationsComponent,
    NotificationItemComponent,
    NotificationLessonComponent,
    NotificationsNotFoundComponent,
    NotificationsFilterPipe,
  ],
})
export class NotificationsModule {}
