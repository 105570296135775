<div
  class="user_page"
  *ngIf="loaded"
>
  <div
    class="user_page_header"
    [ngClass]="{ bookmarked: bookmarked }"
  >
    <div class="user_page_header__image">
      <img
        *ngIf="image(user)"
        src="{{ image(user) }}"
      />
      <span *ngIf="!image(user)">{{ initials(user) }}</span>
      <div
        class="status"
        [class]="{ online: user.isOnline }"
      ></div>
    </div>
    <div class="user_page_header__top">
      <div class="user_page_header__name">
        {{ user.name }}
        <span
          class="confirm"
          *ngIf="user.tutor.validationStatusId === 'Approved'"
        >
          <img src="/assets/img/content/confirm.svg" />
        </span>
      </div>
      <div class="user_page_header__disciplines">
        <span>{{ 'Специалист в области' | transloco }}:</span>
        <div class="user_page_header__disciplines_value">{{ disciplines }}</div>
      </div>
      <div class="user_page_header__icon">
        <img src="/assets/img/content/icon_user.svg" />
      </div>
      <!-- <div class="user_page_header__rate">
				<span>{{user.tutor.rating}}</span>
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 1" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 2" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 3" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 4" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 5" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 5" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 4" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 3" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 2" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 1" />
			</div> -->
    </div>
    <div class="user_page_header__bottom">
      <div class="user_page_header__menu">
        <a
          routerLink="/teachers/{{ user.id }}"
          [class]="{ active: !mod }"
        >{{ 'Общее' | transloco }}</a>
        <a
          routerLink="/teachers/{{ user.id }}/calendar"
          [class]="{ active: mod === 'calendar' }"
          *ngIf="userdata.isStudent"
        >{{ 'Запись к организатору' | transloco }} </a>
        <a
          routerLink="/teachers/{{ user.id }}/calendar"
          [class]="{ active: mod === 'calendar' }"
          *ngIf="userdata.isTutor"
        >{{ 'Расписание' | transloco }} </a>
      </div>
      <div class="user_page_header__name mobile">
        {{ user.name }}
        <span
          class="confirm"
          *ngIf="user.tutor.validationStatusId === 'Approved'"
        >
          <img src="/assets/img/content/confirm.svg" />
        </span>
      </div>
      <div class="user_page_header__disciplines mobile">
        <span>{{ 'Специалист в области' | transloco }}:</span>
        <div class="user_page_header__disciplines_value">{{ disciplines }}</div>
      </div>
      <!-- <div class="user_page_header__rate mobile">
				<span>{{user.tutor.rating}}</span>
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 1" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 2" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 3" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 4" />
				<img src="/assets/img/content/star-active.svg" *ngIf="user.tutor.rating >= 5" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 5" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 4" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 3" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 2" />
				<img src="/assets/img/content/star.svg" *ngIf="user.tutor.rating < 1" />
			</div> -->
      <div
        class="bookmark"
        (click)="bookmark($event)"
        *ngIf="userdata.isStudent"
      >
        <svg-icon-sprite
          [src]="'bookmark'"
          [width]="'29px'"
          [height]="'42px'"
          [viewBox]="'0 0 29 42'"
        ></svg-icon-sprite>
      </div>
    </div>
  </div>
  <div class="user_page_header__menu mobile">
    <a
      routerLink="/teachers/{{ user.id }}"
      [class]="{ active: !mod }"
    >{{ 'Общее' | transloco }} </a>
    <a
      routerLink="/teachers/{{ user.id }}/calendar"
      [class]="{ active: mod === 'calendar' }"
      *ngIf="userdata.isStudent"
    >{{ 'Запись к организатору' | transloco }} </a>
    <a
      routerLink="/teachers/{{ user.id }}/calendar"
      [class]="{ active: mod === 'calendar' }"
      *ngIf="userdata.isTutor"
    >{{ 'Расписание' | transloco }} </a>
  </div>
  <ng-container *ngIf="mod === 'calendar'">
    <calendar [user]="user.id"></calendar>
  </ng-container>
  <div
    class="user_page__content"
    *ngIf="!mod"
  >
    <div class="user_page__left">
      <div class="user_page_block">
        <div class="user_page_block__title">{{ 'Образование' | transloco }} </div>
        <div class="user_page_block__content">
          <ng-container *ngFor="let doc of userDocuments">
            <span
              class="user_page__tag"
              *ngIf="doc.educationalInstitutionName"
            >{{ doc.educationalInstitutionName }}</span>
          </ng-container>
        </div>
      </div>
      <div class="user_page_block">
        <div class="user_page_block__title">{{ 'Специализация' | transloco }}</div>
        <div class="user_page_block__content">
          <ng-container *ngFor="let doc of user.tutor.disciplineSpecializations">
            <span class="user_page__tag">{{ doc.discipline.name | transloco}}</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="user_page__right">
      <div class="user_page_block">
        <div class="user_page_block__title">{{ 'Цены' | transloco }} </div>
        <div class="user_page_block__content">
          <p>{{ 'Быстрое событие' | transloco }} : {{ user.tutor.instantLesson5MinuteRate }} ₽ / {{'5 минут'|
            transloco}}</p>
          <p>{{ 'Классическое событие' | transloco }} : {{ user.tutor.scheduledLessonHourlyRate }} ₽ / {{ 'событие' |
            transloco }} {{'(45 минут)'| transloco}}
          </p>
          <a
            class="btn btn--blue"
            routerLink="/teachers/{{ user.id }}/calendar"
            *ngIf="userdata.isStudent"
          >
            <span class="btn__label">{{'Записаться на событие' | transloco}}</span>
          </a>
        </div>
      </div>
      <div class="user_page_block">
        <div class="user_page_block__title">{{ 'Дополнительная информация' | transloco}}</div>
        <div class="user_page_block__content">
          <div class="user_page_icons">
            <div class="user_page_icon">
              <div class="user_page_icon__image">
                <img src="/assets/img/content/user-icon-doc.svg" />
              </div>
              <div class="user_page_icon__name">{{ 'Ученая степень' | transloco}} </div>
              <ng-container *ngFor="let doc of userDocuments">
                <div
                  class="user_page_icon__text"
                  *ngIf="doc.documentTypeId === 'AcademicDegree'"
                >
                  {{ doc.academicDegreeName | transloco}}
                </div>
              </ng-container>
            </div>
            <div class="user_page_icon">
              <div class="user_page_icon__image">
                <img src="/assets/img/content/user-icon-lesson.svg" />
              </div>
              <div class="user_page_icon__name">{{ 'Проведено' | transloco}} </div>
              <div class="user_page_icon__text">
                {{ user.tutor.chatLessonsAccomplished }}
                <span
                  appPlural
                  [value]="user.tutor.chatLessonsAccomplished"
                  [arVariants]="['событие', 'события', 'событий']"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="user_page_block"
        *ngIf="userdata.isStudent"
      >
        <div class="user_page_block__title">{{ 'Отправить сообщение' | transloco}}</div>
        <div
          class="user_page_block__content"
          *ngIf="!sended"
        >
          <div class="user_page_message">
            <mat-form-field
              class="message_input"
              appearance="outline"
            >
              <textarea
                matInput
                name="message"
                [placeholder]="'Введите сообщение...' | transloco"
              ></textarea>
            </mat-form-field>
          </div>
          <a
            class="btn btn--blue"
            (click)="sendMessage()"
          >
            <span class="btn__label">{{ 'Отправить сообщение' | transloco}}</span>
          </a>
        </div>
        <div
          class="user_page_block__content"
          *ngIf="sended"
        >
          <p>{{'Сообщение было отправлено.' | transloco}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
