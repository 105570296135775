import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const enterMotion: AnimationTriggerMetadata = trigger('enterMotion', [
  transition('void => *', [style({ opacity: 0 }), animate(50, style({ opacity: 1 }))]),
]);

export const exitMotion: AnimationTriggerMetadata = trigger('exitMotion', [
  transition('* => void', [style({ opacity: 1 }), animate(50, style({ opacity: 0 }))]),
]);

export const enterBottonMenuMotion: AnimationTriggerMetadata = trigger('enterBottonMenuMotion', [
  transition('void => *', [style({ height: '0px' }), animate(250, style({ height: '310px' }))]),
]);

export const exitBottonMenuMotion: AnimationTriggerMetadata = trigger('exitBottonMenuMotion', [
  transition(':leave', [style({ top: '0', backgroundColor: 'transparent' }), animate(250, style({ top: '310px' }))]),
]);
