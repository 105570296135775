import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChatLesson } from '@ezteach/api/models/chat-lesson';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import {
  MuteMember,
  ShareScreenStatusMember,
  VideoStatusMember,
} from '@ezteach/group-lesson/components/group-lesson-owner/group-lesson-owner.component';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { StreamUserData } from '@ezteach/group-lesson/models/stream-user-data';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk/decorators';
import { StreamManager } from 'openvidu-browser';
import { tap } from 'rxjs/operators';
import { MemberViewMode } from '../group-lesson-member/group-lesson-member.component';
import { UserService } from "@ezteach/_services/user.service";

@UntilDestroy()
@Component({
  selector: 'ezteach-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit, AfterViewInit {
  membersPermission: ChatLessonMember[] = [];
  openedMember = null;
  isSpeech: boolean;
  @Input() streamManager: StreamManager;
  @Input() memberClients: ChatLessonMemberClient[];
  @Input() ownerClient: ChatLessonMemberClient;
  @Input() members: StreamManager[];
  @Input() speakers: string[];
  @Input() chatLesson: ChatLesson;
  @Input() orientationLandscape: boolean;

  @Output() onMuteMemberChanged = new EventEmitter<MuteMember>();
  @Output() onVideoStatusMemberChanged = new EventEmitter<VideoStatusMember>();
  @Output() onShareScreenStatusMemberChanged = new EventEmitter<ShareScreenStatusMember>();
  @Input() isMobile: boolean;
  @Input() isChatOpen: boolean;
  @Input() isOwner: boolean;
  @Input() isModerator: boolean;
  @Input() memberListIsShown: boolean;
  @Input() memberElemHeight: string;
  @Input() memberViewMode: MemberViewMode;
  @Input() pinnedMode: boolean;
  @Input() pinnedMember: ChatLessonMemberClient | undefined;
  @Input() away: boolean;
  memberViewModeType = MemberViewMode;
  @Input() lazyIniting = false;
  isSplitterClicked = false;
  pinBlocked = false;

  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }, 40, -6),
  ];

  constructor(
    private groupLessonPermissionService: GroupLessonPermissionService,
    private groupLessonService: GroupLessonService,
    private lessonsService: LessonsService,
    private openViduService: OpenViduService,
    private renderer: Renderer2,
    private userService: UserService
  ) {
    this.groupLessonPermissionService.allLessonMembers$.subscribe(x => {
      this.membersPermission = x;
    });

    this.groupLessonService.memberContainerIsChanged$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.onSlitterClick();
        }),
      )
      .subscribe();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (document.querySelector('.as-split-gutter-icon')) {
        this.renderer.listen(document.querySelector('.as-split-gutter-icon'), 'click', () => {
          this.groupLessonService.memberContainerIsChanged$.next();
          this.onSlitterClick();
        });
      }
    }, 100);
  }

  onSlitterClick() {
    this.isSplitterClicked = true;
    this.groupLessonService.memberListIsShown = !this.memberListIsShown;
    this.groupLessonService.memberListIsShown$.next(!this.memberListIsShown);
  }

  ngOnInit() {
    // this.isAudioChecked(this.streamManager, this.membersPermission);
    // this.isVideoChecked(this.streamManager, this.membersPermission);
    this.groupLessonService.isSpeech.pipe(tap(v => (this.isSpeech = v))).subscribe();
  }
  openMenu(member) {
    if (this.openedMember === member) {
      this.backdropClick(null);
    } else {
      this.backdropClick(null);
      this.openedMember = member;
    }
  }

  isOpen(member) {
    return member === this.openedMember;
  }

  backdropClick($event) {
    this.openedMember = null;
  }

  isMe({ member }): boolean {
    const user = this.userService.userData$.value;
    return member.user.id === user.id;
  }

  @tuiPure
  isAudioChecked(member: ChatLessonMemberClient, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x?.memberId === member?.member?.memberId);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Audio) !== -1;
      }
      return true;
    }
    return false;
  }

  @tuiPure
  isVideoChecked(member, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x?.memberId === member?.member?.memberId);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Video) !== -1;
      }
      return true;
    }
    return false;
  }

  @tuiPure
  isShareScreenChecked(member, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x?.user?.id === member?.member?.user?.id);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Screen) !== -1;
      }
      return true;
    }
    return false;
  }

  audioChanged($event, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onMuteMemberChanged.emit({ userData: data, audioEnabled: $event.checked });
  }

  videoChanged($event: MatCheckboxChange, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onVideoStatusMemberChanged.emit({ userData: data, videoEnabled: $event.checked });
  }

  shareScreenChanged($event: MatCheckboxChange, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onShareScreenStatusMemberChanged.emit({
      userData: data,
      shareScreenEnabled: $event.checked,
    });
  }

  onBanned(member: ChatLessonMemberClient) {
    const data = { userid: member.member.memberId } as StreamUserData;
    const lessonId = this.chatLesson.id;
    const params = {
      lessonId,
      userId: data.userid,
      newRole: ChatLessonMemberRole.None,
    };
    this.lessonsService.chatLessonMemberRoleChanged(params).subscribe();
    this.openViduService.banUser(member.stream);
  }
}
