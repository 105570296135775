import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageVisibilityService {
  private visibilitySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
  }

  private handleVisibilityChange(): void {
    this.visibilitySubject.next(!document.hidden);
  }

  public getVisibilityState(): Observable<boolean> {
    return this.visibilitySubject.asObservable();
  }
}