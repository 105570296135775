import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';

export enum SmileyType {
  none,
  sad,
  happy,
}

@Component({
  selector: 'ezteach-popup-with-bg',
  templateUrl: './popup-with-bg.component.html',
  styleUrls: ['./popup-with-bg.component.scss'],
})
export class PopupWithBgComponent implements OnInit {
  smileyType = SmileyType;
  isMobile = false;
  @Input() smiley = SmileyType.none;
  @Input() isDefaultWidth = true;
  @Input() hasCloseBtn = false;
  @Output() onCloseBtnClick = new EventEmitter();
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  constructor() {}

  ngOnInit(): void {
    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
    });
  }

  onClose() {
    this.onCloseBtnClick.emit();
  }
}
