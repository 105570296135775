<div class="requests_item__header">
  <span>{{"Запрос на событие" | transloco}}</span>
  <span>{{ countdown }}</span>
</div>
<div class="requests_item__content tutor-request-margin">
  <div class="requests_item__profile">
    <div class="requests_item__image">
      <img
        *ngIf="image(request)"
        src="{{ image(request) }}"
      />
      <span *ngIf="!image(request)">{{ initials(request) }}</span>
    </div>
    <div class="requests_item__user">
      <div class="requests_item__name">{{ request.initiator.name }}</div>
    </div>
  </div>
  <div class="requests_item__info">
    <div class="requests_item_info__list">
      <div
        class="requests_item_info"
        *ngIf="request.subjectTitle"
      >
        <div class="requests_item_info__name">{{ 'Событие по' | transloco }}:</div>
        <div class="requests_item_info__value">{{ request.subjectTitle }}</div>
      </div>
      <div
        class="requests_item_info"
        *ngIf="request.calculatedPrice"
      >
        <div class="requests_item_info__name">{{ 'Стоимость события' | transloco }} :</div>
        <div class="requests_item_info__value">{{ request.calculatedPrice }}₽</div>
      </div>
      <div
        class="requests_item_info"
        *ngIf="request.durationMinutes"
      >
        <div class="requests_item_info__name">{{ 'Длительность' | transloco }}:</div>
        <div class="requests_item_info__value">{{ request.durationMinutes }} {{ 'минут' | transloco }} </div>
      </div>
    </div>
    <div
      class="requests_item_msg"
      *ngIf="request.messageText"
    >
      {{ request.messageText }}
    </div>
  </div>
  <div class="requests_item__actions">
    <a
      class="btn btn--red-border btn--full"
      (click)="cancelRequest(request)"
    >
      <span class="btn__label"> {{ 'Отклонить' | transloco }} </span>
    </a>
    <a
      class="btn btn--blue btn--full"
      (click)="acceptRequest(request, LESSON_PAYMENT_FREE)"
    >
      <span class="btn__label"> {{ 'Принять' | transloco }} </span>
    </a>
  </div>
</div>
