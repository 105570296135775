import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ezteach/enviroments';
import { unwrapValidationWithError, Validation } from '@ezteach/utils';
import { propOr } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultSteps, ITutorialData, ITutorialResponse, TutorialTypes } from '../models';
import { tutorialDataCodec } from '../tutorial.codecs';
import { UserService } from "@ezteach/_services/user.service";
/* Tutorial api service */
@Injectable({ providedIn: 'root' })
export class TutorialApiService {
  isDashboardComponent = false;

  private get baseUrl() {
    return `${environment.apiUrl}/api/v1`;
  }

  private get userId() {
    return propOr(null, 'id', this.userService.userData$.value);
  }

  constructor(private readonly http: HttpClient,
              private userService: UserService
  ) {}

  getTutorial(): Observable<ITutorialData> {
    return this.http.get<ITutorialResponse>(`${this.baseUrl}/users/${this.userId}/tutorial`).pipe(
      map((value) => this.onboardingIsAvailable(value.data)),
      unwrapValidationWithError(),
    );
  }

  onboardingIsAvailable(data: ITutorialData): Validation<ITutorialData> {
    if (
      (data.type === TutorialTypes.ONBOARDING_STUDENT || data.type === TutorialTypes.ONBOARDING_TEACHER) &&
      !this.isDashboardComponent
    ) {
      const newData = {} as ITutorialData;
      newData.type = TutorialTypes.NOOP;
      newData.progress = DefaultSteps.NOOP;
      return tutorialDataCodec.decode(newData);
    } else {
      return tutorialDataCodec.decode(data);
    }
  }

  updateTutorial(data: ITutorialData): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/users/${this.userId}/tutorial`, data);
  }

  finishTutorial(): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/users/${this.userId}/tutorial/finish`, {});
  }
}
