import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { isProfileSaving, saveProfilePersonalDetails, selectPersonalDetails, setProfileTitle } from '../../+state';
import { PersonalDetails } from '../../../api/models';
import { getDate } from '../../../shared/helpers/helpers';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';
import { ProfilePersonalFormService } from '../../services/profile-personal.component.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss'],
  providers: [ProfilePersonalFormService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePersonalComponent implements OnInit {
  private title = $localize`Общая информация`;
  private maxDate = new Date();
  private minDate = new Date();

  localStorageUser: any;
  form: UntypedFormGroup;

  saving$: Observable<boolean>;
  isReadonlyFields: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profilePersonalFormService: ProfilePersonalFormService,
    private profileLocalStorageService: ProfileLocalStorageService,
    private store: Store,
  ) {
    this.minDate.setFullYear(this.maxDate.getFullYear() - 70);
  }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.subscribeStore();
  }

  subscribeStore() {
    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.localStorageUser = user;
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPersonalDetails),
        filter(personalDetails => personalDetails),
        take(1),
        tap(personalDetails => {
          this.updateForm(personalDetails);
          this.isReadonlyFields = false;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving), tap(isProfileSaving => {
      if (this.isReadonlyFields && !isProfileSaving) {
        this.isReadonlyFields = false;
      }
    }));
  }

  createForm() {
    this.form = this.profilePersonalFormService.createForm(this.formBuilder);
  }

  updateForm(data: PersonalDetails) {
    if (!data?.birthDate?.length) {
      this.form?.controls?.birthDate?.clearValidators();
      this.form?.controls?.birthDate?.updateValueAndValidity();
    }

    this.form.patchValue({
      ...data,
      phone: (data.phone || '').slice(1),
      birthDate: data.birthDate ? new Date(data.birthDate) : null,
    });
  }

  saveProfile() {
    this.isReadonlyFields = true;
    const value = this.form.value;

    if (!this.localStorageUser?.isStudent) {
      delete value.educationalInstitutionName;
    }

    const detail: PersonalDetails = {
      ...value,
      phone: `7${value.phone}`,
      birthDate: getDate(value.birthDate),
    };

    this.store.dispatch(saveProfilePersonalDetails(detail));
  }
}
