import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserService {
  private isIEOrEdgeRegex = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

  get isSupportedBrowser(): boolean {
    return !this.isUnsupportedBrowser;
  }

  get isUnsupportedBrowser(): boolean {
    return this.isIEOrEdgeRegex;
  }
}
