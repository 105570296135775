import { Injectable } from '@angular/core';
import { CalendarService, ICreateSlotPopupClose } from '@ezteach/calendar';
import { CalendarEventApiResponse, CalendarEventIEnumerableApiResponse } from '@ezteach/calendar/models';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarApiService {

  refetchCalendarEvents$: Subject<void> = new Subject<void>();

  constructor(private calendarService: CalendarService) { }

  getDate(date: Date) {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  createSlot(start, end) {
    return this.calendarService.apiV1CalendarSlotsPost({
      body: {
        StartDate: this.getDate(start),
        EndDate: this.getDate(end),
      },
    });
  }

  createGroupLesson(params) {
    return this.calendarService.apiV1CalendarGroupPost(params);
  }

  loadEvents(start, end, member, mode): Observable<CalendarEventIEnumerableApiResponse> {
    return this.calendarService.apiV1CalendarEventsGet({
      StartDate: this.getDate(start),
      EndDate: this.getDate(end),
      LinkedMemberId: member,
      CalendarMode: mode,
    });
  }

  editScheduledLesson(
    lessonId,
    lessonDate,
    lessonStartTime,
    lessonEndTime,
    disciplineId,
    publishingPolicy,
    subject,
    invitedUsers
  ): Observable<CalendarEventApiResponse> {
    const params = {
      lessonId,
      body: { lessonDate, lessonStartTime, lessonEndTime, disciplineId, publishingPolicy, subject, invitedUsers },
    };

    return this.calendarService.apiV1CalendarUpdateScheduledLesson(params);
  }

  editSlot({ eventId, start, end }: ICreateSlotPopupClose) {
    start = moment(moment(start)).utc().format('DD.MM.YYYY HH:mm');
    end = moment(moment(end)).utc().format('DD.MM.YYYY HH:mm');

    const body = {
      startDate: start,
      endDate: end,
      Id: eventId,
    };

    const params = {
      eventId,
      body,
    };
    return this.calendarService.apiV1CalendarUpdateSlot(params);
  }
}
