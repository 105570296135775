import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { CalendarModule } from '@ezteach/calendar';
import { SharedModule } from '@ezteach/shared';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ActiveLessonItemComponent } from './active-lesson-item.component';

@NgModule({
  imports: [CommonModule, SharedModule, IconSpriteModule, ButtonModule, CalendarModule, RouterModule],
  declarations: [ActiveLessonItemComponent],
  exports: [ActiveLessonItemComponent],
})
export class ActiveLessonItemModule { }
