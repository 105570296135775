import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AntiQuickClickThrottleDirective } from './anti-quick-click-throttle.directive';
import { AntiQuickClickDirective } from './anti-quick-click.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { MatLabelRequiredDirective } from './mat-label-required.directive';
import { PluralDirective } from './plural.directive';

@NgModule({
  declarations: [MatLabelRequiredDirective, PluralDirective, AntiQuickClickDirective, AntiQuickClickThrottleDirective, ClickOutsideDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  exports: [MatLabelRequiredDirective, PluralDirective, AntiQuickClickDirective, AntiQuickClickThrottleDirective, ClickOutsideDirective],
})
export class SharedDirectivesModule { }
