<div
  *ngIf="opened"
  class="ez-file-tools-wrapper"
>
  <div class="ez-file-tools ">
    <div
      class="ez-file-tools__item"
      (click)="download()"
      (mouseover)="setActive()"
      (mouseleave)="unsetActive()"
    >
      <ezteach-icon
        name='download'
        [isActive]="activeIcon"
      ></ezteach-icon>
    </div>
  </div>
</div>
