import { MatDialogRef } from '@angular/material/dialog';
import { finishTutorial, lastStep, nextStep, previousStep } from '@ezteach/tutorial/+state/tutorial.actions';

export abstract class DialogNavigationStep {
  protected abstract readonly dialogRef: MatDialogRef<any>;

  goPrevStep() {
    this.dialogRef.close(previousStep());
  }

  goNextStep() {
    this.dialogRef.close(nextStep());
  }

  goLastStep() {
    this.dialogRef.close(lastStep());
  }

  finishTutorial() {
    this.dialogRef.close(finishTutorial());
  }
}
