import { Injectable } from '@angular/core';
import { ObjectDescription } from '../classes/objects-args';

/** Класс - хранилище для объектов вайтборда, чтобы хоть немного разгрузить shape-manager */
@Injectable()
export class WhiteboardStoreService {
  private shapesBuffer: ObjectDescription[] = [];
  addShapesToBuffer(shapes: ObjectDescription[]): void {
    this.shapesBuffer = shapes ?? [];
  }
  getShapesFromBuffer(): ObjectDescription[] {
    return this.shapesBuffer;
  }
}
