import Konva from 'konva';
import { WhiteBoard } from '../whiteboard';

/*
 * Упраление мышкой для коллоборации
 */
export class MemberMouseManager {
  private whiteBoard: WhiteBoard;
  private memberMouse = new Map();

  constructor(whiteBoard: WhiteBoard) {
    this.whiteBoard = whiteBoard;
  }

  setMemberMouse(data: { memberId: number; x: number; y: number; name: string }) {
    const memberShape = this.memberMouse.get(data.memberId);
    if (!memberShape) {
      var group = new Konva.Group({
        x: data.x,
        y: data.y,
        fill: 'red',
      });

      var text = new Konva.Text({
        x: 15,
        y: 15,
        text: data.name,
        fontSize: 10,
        fontFamily: 'Roboto',
        fill: 'white',
        width: 100,
        padding: 4,
        align: 'center',
      });

      var rect = new Konva.Rect({
        x: 15,
        y: 15,
        fill: '#678AFF',
        width: 100,
        height: text.height(),
        cornerRadius: 2,
      });

      group.add(rect);
      group.add(text);

      Konva.Image.fromURL('assets/img/conva/mouse.svg', shape => {
        shape.setX(1);
        shape.setY(1);
        group.add(shape);
        if (!this.memberMouse.get(data.memberId)) {
          this.whiteBoard.mouseLayerGet().add(group);
          this.memberMouse.set(data.memberId, group);
        }
      });
    } else {
      memberShape.to({
        duration: 0.2,
        x: data.x,
        y: data.y,
      });
    }
  }

  removeMemberMouse(memberId: number) {
    const memberShape = this.memberMouse.get(memberId);
    if (memberShape) {
      memberShape.destroy();
      this.memberMouse.delete(memberId);
    }
  }
}
