import * as _ from 'lodash';
import { flatPointsToXY } from '../shapes/math/freedraw';
import { getBaseRectXY } from '../shapes/math/rorate';

/*
 * Границы элемента внутри кнутри которого появляются плавающие элементы
 */
export interface HostBounds {
  top: number;
  left: number;
  width: number;
  height: number;
}

/*
 * Результат координат позиционирования всплывающего элемента
 */
export interface Position {
  left: number;
  top: number;
}

/*
 *  Координата
 */
export interface Coordinate {
  x: number;
  y: number;
}

/*
 *  Описывает координаты сверху и снизу
 */
export interface TargetCoordinate {
  top: Coordinate;
  bottom: Coordinate;
}

/*
 * Вычисляет позицию всплывающего элемента
 */
export function getPosition(
  shape: any,
  host: HostBounds,
  attrs: any,
  hostOffsetX: number,
  hostOffsetY: number,
  scaleOffset: number,
): Position {
  const targetCoord = getTargetCoordinate(shape, attrs, hostOffsetX, hostOffsetY, scaleOffset);
  let canFloatTop = targetCoord.top.y - host.top > 150;
  if (canFloatTop) {
    return { left: host.left + targetCoord.top.x, top: targetCoord.top.y };
  }
  return { left: host.left + targetCoord.bottom.x, top: targetCoord.bottom.y };
}

/*
 * Вычисляет координаты сверху и снизу  для любой фигуры
 */
function getTargetCoordinate(
  shape: any,
  attrs: any,
  hostOffsetX: number,
  hostOffsetY: number,
  scaleOffset: number,
): TargetCoordinate {
  let baseX = attrs.x;
  let baseYTop = attrs.y;
  let baseYBottom = attrs.y + (attrs.height ?? 0);

  // базовая координата фигур
  if (attrs.type === 'rect') {
    let x1 = attrs.x;
    let y1 = attrs.y;
    let y1Bottom = y1;

    // если была трансформация нужно пересчитать точку
    if (attrs.rotation) {
      const dxdy = getBaseRectXY(shape);
      x1 = x1 + dxdy.dx;
      y1 = y1 + dxdy.dy;
      y1Bottom = y1Bottom + dxdy.dy;
    }

    baseX = attrs.width / 2 + x1 - 50;
    baseYTop = y1 - 100;
    if (attrs.scaleY) {
      baseYBottom = y1Bottom + attrs.height * attrs.scaleY + 200;
    } else {
      baseYBottom = y1Bottom + attrs.height + 200;
    }
  }

  if (attrs.radiusY && attrs.type === 'ellipse') {
    baseX = attrs.x - 50;
    baseYTop = attrs.y - attrs.radiusY - 150;

    if (attrs.scaleY) {
      baseYBottom = attrs.y + attrs.radiusY * attrs.scaleY + 150;
    } else {
      baseYBottom = attrs.y + attrs.radiusY + 150;
    }
  }

  if (attrs.type === 'triangle') {
    baseX = attrs.x - 50;
    baseYTop = attrs.y - attrs.radius - 50;
    if (attrs.rotation && Math.abs(attrs.rotation) > 20) {
      if (attrs.scaleY) {
        baseYBottom = attrs.y + attrs.radius * attrs.scaleY + 200;
      } else {
        baseYBottom = attrs.y + attrs.radius + 150;
      }
    } else {
      if (attrs.scaleY) {
        baseYBottom = attrs.y + attrs.radius * attrs.scaleY + 200;
      } else {
        baseYBottom = attrs.y + attrs.radius * attrs.scaleY + 100;
      }
    }
  }

  if (attrs.type === 'freeline') {
    let mx = 0;
    let minY = 0;
    let maxY = 0;

    const xy = flatPointsToXY(attrs.points);
    const xpoints = xy.map(x => x[0]);
    const ypoints = xy.map(x => x[1]);

    const minX = _.min(xpoints);
    const maxX = _.max(xpoints);
    mx = (minX + maxX) / 2;

    minY = _.min(ypoints);
    maxY = _.max(ypoints);

    // смещение от драг э дроп
    if (attrs.x && attrs.y) {
      mx = mx + attrs.x;
      minY = minY + attrs.y;
      maxY = maxY + attrs.y;
    }

    baseX = mx - 25;
    baseYTop = minY - 100;
    baseYBottom = maxY + 150;
  }

  // с учетом пролистывания доски
  baseX = baseX - hostOffsetX;
  baseYTop = baseYTop - hostOffsetY;
  baseYBottom = baseYBottom - hostOffsetY;

  return {
    top: { x: baseX, y: baseYTop },
    bottom: { x: baseX, y: baseYBottom },
  };
}

/** Вычисление центра координат переданных фигур */
export function getCenterCoordinates(coordinates: Coordinate[]): Coordinate | null {
  if (coordinates.length === 0) return null;
  const coordinate = coordinates[0];
  let left = coordinate.x;
  let top = coordinate.y;
  let right = coordinate.x;
  let bottom = coordinate.y;
  for (const { x, y } of coordinates) {
    if (x < left) left = x;
    if (x > right) right = x;
    if (y < top) top = y;
    if (y < bottom) bottom = y;
  }
  const x = left + (right - left) / 2;
  const y = top + (bottom - top) / 2;
  return { x, y };
}
