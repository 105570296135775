import { Component, Input, OnInit } from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UserService } from '@ezteach/_services/user.service';
import { LessonFormatEnum } from '@ezteach/api/models/lesson-format-enum';
import { PaymentHistorySortsRequest } from '@ezteach/api/models/payment-history-sorts-api-request';
import {
  PaymentResultEnum,
  ProfilePaymentHistoryResponse,
  ProfilePaymentHistoryResponseData,
} from '@ezteach/api/models/profile-payment-history.responce';
import { UsersService } from '@ezteach/api/services/users.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

interface IPaymentHistoryOptions {
  userId: number;
  offset?: number;
  take?: number;
  orderBy: PaymentHistorySortsRequest;
  pageNumber: number;
  pageSize: number;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-finance-history',
  templateUrl: './finance-history.component.html',
  styleUrls: ['./finance-history.component.scss'],
})
export class FinanceHistoryComponent implements OnInit {
  @Input() isNavItem = false;
  @Input() isHalfView = false;
  userData: any;
  sortType: Array<any> = [
    { name: PaymentHistorySortsRequest.ByDate, value: this.translocoService.translate('По дате') },
    { name: PaymentHistorySortsRequest.ByAmount, value: this.translocoService.translate('По сумме') },
  ];

  paymentDataHistory: ProfilePaymentHistoryResponseData[] = [];
  selectedType: PaymentHistorySortsRequest = this.sortType[0].name;
  isMobile = false;
  private mediaQueryService = new MediaQueryService('(max-width: 414px)');
  paymentResult = PaymentResultEnum;
  lessonFormat = LessonFormatEnum;
  private mobileNumberPaymentPerPage = 4;
  private desktopNumberPaymentPerPage = 20;
  private pageSize: number;
  public totalPages: number;
  public currentPage = 1;

  constructor(
    private usersService: UsersService,
    private translocoService: TranslocoService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.mediaQueryService.match$.subscribe(value => {
      if (value) {
        this.isHalfView = true;
      }
      this.isMobile = value;
    });

    this.userData = this.userService.userData$.value;
    this.pageSize =
      this.isHalfView || this.isMobile ? this.mobileNumberPaymentPerPage : this.desktopNumberPaymentPerPage;

    this.loadFinanceHistory(this.getOptions());
  }

  loadFinanceHistory(options: IPaymentHistoryOptions, more: boolean = false): void {
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsGetPaymentHistory(options)
      .pipe(
        untilDestroyed(this),
        tap((resp: ProfilePaymentHistoryResponse) => {
          if (more) {
            this.paymentDataHistory = [...this.paymentDataHistory, ...resp.data];
          } else {
            this.paymentDataHistory = resp.data;
          }
          this.totalPages = resp.totalPages;
        }),
      )
      .subscribe();
  }

  public updateSortType(): void {
    this.currentPage = 1;
    const options = this.getOptions();
    this.loadFinanceHistory(options);
  }

  private getOptions(): IPaymentHistoryOptions {
    return {
      userId: this.userData.id,
      orderBy: this.selectedType,
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
    };
  }

  showMoreBtnClick(): void {
    this.currentPage++;
    this.loadFinanceHistory(this.getOptions(), true);
  }
}
