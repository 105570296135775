<div
  class="ezteach-tool"
  [ngClass]="{'full-mode': enableFullPageMode}"
>
  <div class="ezteach-tool-wrapper">
    <div
      class="ezteach-tool-wrapper__name"
      [ngClass]="{'edit': editIconDisplay}"
    >
      <div
        class="title"
        *ngIf="!editState"
        (mouseenter)="editIconDisplay=true"
        (mouseleave)="editIconDisplay=false"
        (click)="changeEditState()"
      >
        {{ title | transloco }}
        <div *ngIf="editIconDisplay">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.4668 12.8364L2.46679 12.8364V12.4222L2.75969 12.1293L3.4668 12.8364ZM6.39821 15.7678L7.10532 16.4749L6.81242 16.7678L6.39821 16.7678L6.39821 15.7678ZM3.4668 15.7678L3.46679 16.7678L2.4668 16.7678L2.46679 15.7678L3.4668 15.7678ZM15.1131 4.22453L15.0101 4.12155L16.4243 2.70733L16.5273 2.81032L15.1131 4.22453ZM4.4668 12.8364L4.4668 15.7678L2.46679 15.7678L2.46679 12.8364L4.4668 12.8364ZM3.4668 14.7678L6.39821 14.7678L6.39821 16.7678L3.46679 16.7678L3.4668 14.7678ZM13.5959 4.12155L12.1376 5.57982L10.7234 4.16561L12.1816 2.70733L13.5959 4.12155ZM12.1376 5.57982L4.1739 13.5435L2.75969 12.1293L10.7234 4.16561L12.1376 5.57982ZM5.6911 15.0607L13.6548 7.09702L15.069 8.51124L7.10532 16.4749L5.6911 15.0607ZM13.6548 7.09702L15.1131 5.63875L16.5273 7.05296L15.069 8.51124L13.6548 7.09702ZM12.1376 4.16561L15.069 7.09702L13.6548 8.51124L10.7234 5.57982L12.1376 4.16561ZM15.0101 4.12155C14.6195 3.73102 13.9864 3.73102 13.5959 4.12155L12.1816 2.70733C13.3532 1.53576 15.2527 1.53576 16.4243 2.70733L15.0101 4.12155ZM16.5273 2.81032C17.6988 3.98189 17.6988 5.88139 16.5273 7.05296L15.1131 5.63875C15.5036 5.24822 15.5036 4.61506 15.1131 4.22453L16.5273 2.81032Z"
              fill="#678AFF"
            />
          </svg>
        </div>
      </div>

      <div *ngIf="editState">
        <input
          #renameField
          class="edit-field"
          [(ngModel)]="title"
          (keyup.enter)="renameWhiteboard()"
          (keyup.escape)="discardRename()"
        >
      </div>
    </div>
    <div
      *ngIf="whiteBoardMode !== whiteBoardModes.inside"
      class="ez-whiteboard-users"
    >
      <div *ngFor="let user of users">
        <img
          class="ez-whiteboard-user"
          *ngIf="user.avatarIsGiven"
          src="{{ user.model | userAvatar }}"
        />
        <div
          class="ez-whiteboard-user__initials-icon"
          *ngIf="!user.avatarIsGiven"
        >
          <span>{{ user.model | userInitials }}</span>
        </div>
      </div>
    </div>
    <!--<div *ngIf="whiteBoardMode !== whiteBoardModes.inside" class="ez-whiteboard-share">
      <div class="ez-whiteboard-share-icon">
        <ezteach-icon name='share'></ezteach-icon>
      </div>
      <div class="ez-whiteboard-share__text">Поделить>ся</div>
    </div>-->
  </div>

  <div
    *ngIf="isEnabledActions"
    class="ezteach-tool-wrapper__tools"
    [ngClass]="{
        'ezteach-tools-inside': whiteBoardMode === whiteBoardModes.inside
      }"
  >
    <ezteach-tools
      (onToolChanged)="onToolChanged($event)"
      (onAddFile)="addFile($event)"
    > </ezteach-tools>
  </div>

  <ng-template #textToolPortalContent>
    <ezteach-text-shape-tools
      #textShapeTool
      [attrs]="textAttrs"
      (attrsChanged)="textAttrsChanged($event)"
    >
    </ezteach-text-shape-tools>


  </ng-template>

  <ng-template #shapeToolPortalContent>
    <ezteach-shape-tools
      #shapeTool
      [whiteBoardMode]="whiteBoardMode"
      [isFullMode]="enableFullPageMode"
      [selectedShape]="editedShape"
      (colorStrokeChanged)="colorStrokeChanged($event)"
      (colorFillChanged)="colorFillChanged($event)"
      (opacityChanged)="opacityChanged($event)"
      (strokeWidthChanged)="strokeWidthChanged($event)"
    >
    </ezteach-shape-tools>

  </ng-template>

  <ng-template #fileToolPortalContent>
    <ezteach-file-shape-tools
      #fileShapeTool
      (onDownload)="downloadFile()"
    >
    </ezteach-file-shape-tools>

  </ng-template>


  <div
    *ngIf="fileUploading"
    class="ezteach-fileloader"
  >
    загрузка файла...
  </div>
  <div
    #whiteboard
    [@whiteboard]="whiteboardFullPageState"
    (@whiteboard.done)="animationDone()"
    id="ez-canvas"
    class="ez-canvas"
    tabindex="0"
    [wbDragFile]="isEnabledActions"
    (onFileDrop)="onFileDrop($event)"
  ></div>
  <div
    *ngIf="isEnabledActions"
    class="ez-state ez-state-undo"
    [ngStyle]="{
      top: (whiteBoardTopOffset !== 0 && whiteBoardTopOffset) ? isMobile ?  (whiteBoardTopOffset - 60) + 'px' : whiteBoardTopOffset + 'px' : ''
    }"
    (click)="undo($event)"
  >
    <div class="ez-state-el">
      <ezteach-icon
        [disabled]="undoDisabled"
        name="undo"
        [onlyFill]="true"
      ></ezteach-icon>
    </div>
  </div>
  <div
    *ngIf="isEnabledActions"
    class="ez-state ez-state-redo"
    [ngStyle]="{
      top: whiteBoardTopOffset !== 0 && whiteBoardTopOffset ? whiteBoardTopOffset + 'px' : ''
    }"
    (click)="redo($event)"
  >
    <div class="ez-state-el">
      <ezteach-icon
        name="redo"
        [disabled]="redoDisabled"
        [onlyFill]="true"
      ></ezteach-icon>
    </div>
  </div>
  <div
    class="ez-zoom"
    [ngStyle]="{
      top: whiteBoardTopOffset !== 0 && whiteBoardTopOffset ? whiteBoardTopOffset + 'px' : ''
    }"
  >
    <div
      class="ez-zoom-el ez-zoom__out"
      (click)="zoomOut()"
    >-</div>
    <div class="ez-zoom__val ez-zoom-el">{{ zoom }}%</div>
    <div
      class="ez-zoom-el ez-zoom__in"
      (click)="zoomIn()"
    >+</div>
  </div>
  <div
    class="full-screen-btn"
    [ngStyle]="{
      top: whiteBoardTopOffset !== 0 && whiteBoardTopOffset ? whiteBoardTopOffset + 'px' : ''
    }"
    (click)="fullPageView()"
  >
    <div
      class="ez-state-el"
      [style.width]="'55%'"
    >
      <ezteach-icon
        name="enableFullScreen"
        [onlyFill]="true"
        *ngIf="!enableFullPageMode"
      ></ezteach-icon>
      <ezteach-icon
        name="disableFullScreen"
        [onlyFill]="true"
        *ngIf="enableFullPageMode"
      ></ezteach-icon>
    </div>
  </div>
</div>

<div
  id="menu"
  class="whiteboard-menu-hide"
  [ngClass]="{
    'whiteboard-menu-hidden': !isEnabledActions || !selectedShapes?.length
  }"
>
  <div
    class="whiteboard-menu__item"
    (click)="copyShapes()"
  >Копировать</div>
  <div
    class="whiteboard-menu__item"
    (click)="pasteShapes()"
  >Вставить</div>
  <div
    class="whiteboard-menu__item"
    (click)="moveFront()"
  >Переместить вперед</div>
  <div
    class="whiteboard-menu__item"
    (click)="moveBack()"
  >Переместить назад</div>

  <div
    class="whiteboard-menu__item"
    (click)="removeShapes()"
  >Удалить</div>
</div>

<!-- Отладка состояний - придумать как для дебаг версии включать
<div style="position: fixed; top: 5px; left: 5px;">
  <h4 *ngIf="whiteBoard && whiteBoard.whiteBoardState"> {{whiteBoard?.whiteBoardState?.constructor?.name}}</h4>
  <div *ngFor="let s of shapes">
    {{s.whiteBoardShapeState?.constructor?.name}}

  </div>
</div>
-->