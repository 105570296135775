import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, startWith, take, tap } from 'rxjs/operators';
import { isProfileSaving, saveSpecialityDetails, selectSpecialityDetails, setProfileTitle } from '../../+state';
import {
  DisciplineSpecializationIEnumerableApiResponse,
  FinancialDetails,
  SpecialityDetails,
} from '../../../api/models';
import { DictionariesService } from '../../../api/services';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';
import { lessonMaxRateValidator, lessonMinRateValidator } from './validators/profile-specialty-validators';
import { Langs, LangStoreService} from '@ezteach/shared/services/lang-store.service';

interface Discipline {
  id: number;
  name: string;
  specializations: {
    id: number;
    name: string;
  }[];
}

@UntilDestroy()
@Component({
  selector: 'app-profile-specialty',
  templateUrl: './profile-specialty.component.html',
  styleUrls: ['./profile-specialty.component.scss'],
})
export class ProfileSpecialtyComponent implements OnInit {
  user: any;
  specialityDetails: SpecialityDetails;
  form: UntypedFormGroup;

  loaded = false;
  saving$: Observable<boolean>;

  title = `Специализация`;

  allDisciplines: Discipline[] = [];
  filteredDisciplines: Observable<Discipline[]>;
  selectedDisciplines: Discipline[] = [];

  discipline: Discipline;
  specializationIds: number[] = [];

  disciplineInput = new UntypedFormControl();
  currentLang: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dictionariesService: DictionariesService,
    private profileLocalStorageService: ProfileLocalStorageService,
    private store: Store,
    langStoreService: LangStoreService
  ) {
    this.currentLang = langStoreService.getCurrentLang();
  }

  private _filter(searchQuery: any) {
    if (searchQuery.name) {
      searchQuery = searchQuery.name;
    }

    const selectedIds = this.selectedDisciplines.map(s => s.id);
    const remainingDisciplines = this.allDisciplines.filter(d => !selectedIds.includes(d.id)).filter(x=> x.specializations.length !== 0);
    return searchQuery
      ? remainingDisciplines.filter(d => d.name.toLowerCase().startsWith(searchQuery.toLowerCase()))
      : remainingDisciplines;
  }

  ngOnInit() {
    this.createForm();
    this.getDisciplines();
    this.subscribeStore();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.form.markAllAsTouched();
  }

  subscribeStore() {
    this.store
      .pipe(
        untilDestroyed(this),
        select(selectSpecialityDetails),
        filter(specialityDetails => specialityDetails),
        take(1),
        tap(specialityDetails => {
          this.specialityDetails = specialityDetails;
          this.updateForm(specialityDetails);
          this.setSelectedSpecializations();
          this.loaded = true;
        }),
      )
      .subscribe();

    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving));
  }

  createForm() {
    this.form = this.formBuilder.group({
      instantLesson5MinuteRate: ['', [Validators.required, lessonMinRateValidator(170), lessonMaxRateValidator(2500)]],
      scheduledLessonHourlyRate: [
        '',
        [Validators.required, lessonMinRateValidator(170), lessonMaxRateValidator(25000)],
      ],
    });
  }

  updateForm(data: FinancialDetails) {
    this.form.patchValue(data);
  }

  getDisciplines() {
    this.dictionariesService
      .apiV1DictionariesDisciplineSpecializationsFlatGet()
      .subscribe((response: DisciplineSpecializationIEnumerableApiResponse) => {
        if (response.data) {
          if(this.currentLang === Langs.EN){
            this.allDisciplines = Object.values(
              response.data
                .map(item => ({
                  id: item.discipline.id,
                  name: item.discipline.name,
                  specializations: [{ ...item.specialization, id: item.id }].filter(x => x.name !== 'ЕГЭ' && x.name !== 'ОГЭ' && x.name !== 'Олимпиады'),
                }))
                .reduce(
                  (group, discipline) => ({
                    ...group,
                    [discipline.id]: group[discipline.id]
                      ? {
                        ...group[discipline.id],
                        specializations: [...group[discipline.id].specializations, ...discipline.specializations],
                      }
                      : discipline,
                  }),
                  {},
                ),
            );
            return this.setSelectedSpecializations();
          }
          this.allDisciplines = Object.values(
            response.data
              .map(item => ({
                id: item.discipline.id,
                name: item.discipline.name,
                specializations: [{ ...item.specialization, id: item.id }],
              }))
              .reduce(
                (group, discipline) => ({
                  ...group,
                  [discipline.id]: group[discipline.id]
                    ? {
                        ...group[discipline.id],
                        specializations: [...group[discipline.id].specializations, ...discipline.specializations],
                      }
                    : discipline,
                }),
                {},
              ),
          );

          this.setSelectedSpecializations();
        }
      });
  }

  addDiscipline(event: any) {
    this.selectedDisciplines.unshift(event.option.value);
    this.disciplineInput.setValue('');
    this.discipline = null;
  }

  removeDiscipline(disciplineId: number) {
    this.selectedDisciplines = this.selectedDisciplines.filter(d => d.id !== disciplineId);
    const deleteSpecializations = this.allDisciplines
      .find(discipline => discipline.id === disciplineId)
      .specializations.map(specialization => specialization.id);

    this.specializationIds = this.specializationIds.filter(e => deleteSpecializations.indexOf(e) < 0);
  }

  getDisciplineValue(discipline: Discipline) {
    return discipline?.name || '';
  }

  setSelectedSpecializations() {
    if (this.specialityDetails && this.allDisciplines) {
      this.specializationIds = this.specialityDetails.disciplineSpecializations.map(
        specialization => specialization.id,
      );
      this.selectedDisciplines = this.allDisciplines.filter(discipline =>
        discipline.specializations.find(specialization => this.specializationIds.includes(specialization.id)),
      );
      this.filteredDisciplines = this.disciplineInput.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    }
  }

  changeSpecializationIds(event: any) {
    const specializationId = +event.target.value;

    if (this.specializationIds.includes(specializationId)) {
      this.specializationIds = this.specializationIds.filter(id => id !== specializationId);
    } else {
      this.specializationIds.push(specializationId);
    }
  }

  setProfile() {
    const value = this.form.value;
    const specialityDetails = {
      ...this.specialityDetails,
      ...value,
      disciplineSpecializations: this.specializationIds.map(id => ({ id })),
    };

    this.store.dispatch(saveSpecialityDetails(specialityDetails));
  }
}
