import {
  BankCardBindings,
  EducationDetails,
  FileDocument,
  FileDocumentIEnumerableApiResponse,
  FinancialDetails,
  PassportDetails,
  PersonalDetails,
  ReferenceLetters,
  ReferenceLettersDocument,
  SpecialityDetails,
  StudentPatron,
  UserAvatar,
  UserProfile,
  WorkExperience,
} from '@ezteach/api/models';
import { createAction, props } from '@ngrx/store';

export const setProfileTitle = createAction('[Profile] Set Profile Title', props<{ title: any }>());

export const loadProfile = createAction('[Profile] Load Profile');

export const profileLoadedSuccess = createAction('[Profile] Loaded Success', (profile: UserProfile) => profile);

export const profileLoadedFailed = createAction('[Profile] Loaded Failed');

export const saveProfile = createAction('[Profile] Save Profile', props<{ profile: any }>());

export const savingStartProfile = createAction('[Profile] Save Profile Success');

export const saveProfileSuccess = createAction('[Profile] Save Profile Success');

export const saveProfileError = createAction('[Profile] Save Profile Error');

export const saveProfilePersonalDetails = createAction(
  '[Profile] Save Profile PersonalDetails',
  (personalDetails: PersonalDetails) => personalDetails,
);

export const saveProfilePassportDetails = createAction(
  '[Profile] Save Profile PassportDetails',
  (pasportDetails: PassportDetails) => pasportDetails,
);

export const uploadProfilePassportFile = createAction('[Profile] Upload Profile Passport File', request => request);

export const uploadProfilePassportFileSuccess = createAction(
  '[Profile] Upload Profile Passport File Success',
  (response: FileDocumentIEnumerableApiResponse) => response,
);

export const removeProfilePassportFile = createAction('[Profile] Remove Profile Passport File', (files: {data?: null | Array<FileDocument>}) => files);

export const removeProfilePassportFileSuccess = createAction(
  '[Profile] Remove Profile Passport File Success',
  (files: {data?: null | Array<FileDocument>}) => files,
);

export const removeWorkExperienceFile = createAction('[Profile] Remove Profile Work Experience File', (files: {data?: null | Array<FileDocument>}) => files);

export const removeWorkExperienceFileSuccess = createAction(
  '[Profile] Remove Profile Work Experience File Success',
  (files: {data?: null | Array<FileDocument>}) => files,
);


export const removeEducationFile = createAction('[Profile] Remove Profile Education File', (files: {data?: null | Array<ReferenceLettersDocument>}) => files);

export const removeEducationFileSuccess = createAction(
  '[Profile] Remove Profile Education File Success',
  (files: {data?: null | Array<ReferenceLettersDocument>}) => files,
);

export const removeReferenceLettersFile = createAction('[Profile] Remove Profile Reference Letters File', (files: {data?: null | Array<ReferenceLettersDocument>}) => files);

export const removeReferenceLettersSuccess = createAction(
  '[Profile] Remove Profile Reference Letters File Success',
  (files: {data?: null | Array<ReferenceLettersDocument>}) => files,
);

export const saveProfileReferenceLetters = createAction(
  '[Profile] Save Profile ReferenceLetters',
  (referenceLetters: ReferenceLetters) => referenceLetters,
);

export const uploadReferenceLettersFile = createAction(
  '[Profile] Upload Profile ReferenceLetters File',
  request => request,
);

export const uploadReferenceLettersFileSuccess = createAction(
  '[Profile] Upload Profile ReferenceLetters File Success',
  (response: FileDocumentIEnumerableApiResponse) => response,
);

export const saveProfileFinancialDetails = createAction(
  '[Profile] Save Profile FinancialDetails',
  (financialDetails: FinancialDetails) => financialDetails,
);

export const uploadEducationFile = createAction('[Profile] Upload Profile Education File', request => request);

export const uploadEducationFileSuccess = createAction(
  '[Profile] Upload Profile Education File Success',
  (response: FileDocumentIEnumerableApiResponse) => response,
);

export const saveProfileEducation = createAction(
  '[Profile] Save Profile Education',
  (educationDetails: EducationDetails) => educationDetails,
);

export const saveProfilePatron = createAction(
  '[Profile] Save Profile Patron',
  (studentPatron: StudentPatron) => studentPatron,
);

export const saveWorkExperience = createAction(
  '[Profile] Save Profile WorkExperience',
  (workExperience: WorkExperience) => workExperience,
);

export const uploadWorkExperienceFile = createAction(
  '[Profile] Upload Profile Work Experience File',
  request => request,
);

export const uploadWorkExperienceFileSuccess = createAction(
  '[Profile] Upload Profile Work Experience File Success',
  (response: FileDocumentIEnumerableApiResponse) => response,
);

export const saveBankCardBindings = createAction(
  '[Profile] Save Profile BankCardBindings',
  (bankCardBindings: BankCardBindings) => bankCardBindings,
);

export const saveSpecialityDetails = createAction(
  '[Profile] Save Profile SpecialityDetails',
  (specialityDetails: SpecialityDetails) => specialityDetails,
);

export const updateUserAvatar = createAction('[Profile] Save Profile User Avatar', (avatar: UserAvatar) => avatar);

export const updateBankCardBindings = createAction(
  '[Profile] Update Profile BankCardBindings',
  (bankCardBindings: BankCardBindings) => bankCardBindings,
);
