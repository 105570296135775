import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TooltipPosition } from '@angular/material/tooltip';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { User } from '@ezteach/api/models';
import { BlockApiService, BlockCreateRequest, BlockСostCurrencyEnum, BlockСostTypeEnum } from '@ezteach/blocks/services/block-api.service';
import { SelectAutocompleteComponent } from '@ezteach/shared/components/select-autocomplete/select-autocomplete.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

interface LessonCheckboxSelect {
  id: number;
  name: string;
  checked: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-create-block',
  templateUrl: './create-block.component.html',
  styleUrls: ['./create-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateBlockComponent implements OnInit {

  @ViewChild(SelectAutocompleteComponent) participantsSelect: SelectAutocompleteComponent;
  participants: number[];
  lessons: LessonCheckboxSelect[] = [{
    id: 0,
    name: 'Астрономия',
    checked: false
  },
  {
    id: 1,
    name: 'Русский язык',
    checked: true
  }];
  selectedLessonIds = [1];
  showOnSearchTooltip: string;
  readonly tooltipPosition: TooltipPosition = 'after';
  buttonType = ButtonType
  isShowSearch: boolean;
  previewImage: File;
  imageSrc: string;
  form: FormGroup;
  constructor(private blockApiService: BlockApiService,
              private cdr: ChangeDetectorRef,
              private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.blockApiService.createBlock$.pipe(
      untilDestroyed(this),
      switchMap((x) => {
        if (x && this.form.valid) {
          const request: BlockCreateRequest = {
            name: this.form.value.name,
            description: this.form.value.description,
            isCertificate: false,
            costType: BlockСostTypeEnum.Free,
            costAmount: 0,
            costCurrency: BlockСostCurrencyEnum.RUB,
            preview: this.previewImage,
            membersIds: this.participantsSelect?.items?.map(x => x.id)
          }
          return this.blockApiService.createBlock(request);
        }
        return of(null);
      }),
      tap((result) => {
        if (result?.ok) {
          this.blockApiService.updateBlocksList$.next(true);
          return ;
        }
        this.showWarnings();

      })
    ).subscribe();

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    })
  }

  private showWarnings() {
    this.form.markAllAsTouched();
    this.cdr.detectChanges();
  }

  checkLesson($event: MatSelectChange) {
    this.selectedLessonIds = $event.value;
  }
  selectParticipant($event: MatSelectChange) {
    throw new Error('Method not implemented.');
  }

  toggleShowSearch() {
    this.isShowSearch = !this.isShowSearch;
  }

  onSelectLesson(lesson: LessonCheckboxSelect) {
    throw new Error('Method not implemented.');
  }

  addPreviewImage($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (!target?.files || target.files.length === 0) {
      return;
    }
    this.previewImage = target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSrc = reader.result as string;
      this.cdr.detectChanges();
    }

    reader.readAsDataURL(this.previewImage);

    target.value = null;
  }

  displayWithUser(user: User | null): string {
    return user?.firstName + ' ' + user?.lastName;
  }

}
