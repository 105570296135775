import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@ezteach/api/api-configuration';
import { BaseService } from '@ezteach/api/base-service';
import { User } from '@ezteach/api/models';
import { RequestBuilder } from '@ezteach/api/request-builder';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface BlockApiResponse {
  data?: BlockItem;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}

export interface BlockCreateRequest {
  name: string;
  description: string;
  isCertificate: boolean;
  costType: BlockСostTypeEnum;
  costAmount: number | null;
  costCurrency: BlockСostCurrencyEnum | null;
  preview: File;
  membersIds: number[];
}

export interface BlockExerciseRequest {
  number: number | null;
  externalUrl: string;
  title: string;
  startDate: string;
  duration: number;
  scheduledLessonId: number | null;
  chatLessonId: number | null;
  type: BlockExerciseTypeEnum;
  metaData: string;
}

export enum BlockСostTypeEnum {
  Free,
  Pay,
}

export enum BlockСostCurrencyEnum {
  RUB = 643,
  USD = 840,
  KZT = 398,
}

export interface Block {
  user: User;
  name: string;
  description: string;
  exercises: BlockExercise[];
  members: BlockMember[];
  tasks: BlockTask[];
  isCertificate: boolean;
  costType: BlockСostTypeEnum;
  costAmount: number;
  costCurrency: BlockСostCurrencyEnum;
  preview: string;
}

export interface BlockItem {
  id: number;
  creator: BlockCreator;
  name: string;
  description: string;
  exerciseQty: number;
  membersQty: number;
  hasCertificate: boolean;
  costType: BlockСostTypeEnum;
  costAmount: number;
  costCurrency: BlockСostCurrencyEnum;
  preview: string;
  members: BlockMember[];
  tasks: TutorBlockTask[];
  exercises: BlockExercise[];
}

export interface BlockCreator {
  id: number;
  name: string;
}

export interface TutorBlockTask {
  correctAnswer: string;
  successfulText: string;
  unsuccessfulText: string;
}

export interface BlockExercise {
  title?: string;
  type?: BlockExerciseTypeEnum;
  blockId: number;
  block?: Block;
  metaData?: string;
  scheduledLessonId?: number | null;
  chatLessonId?: number | null;
  startDate?: Date;
  duration?: number | null;
}

export enum BlockExerciseTypeEnum {
  None = 'none',
  Text = 'text',
  Lesson = 'lesson',
  ScheduledLesson = 'scheduledLesson',
  HistoryScheduledLesson = 'historyScheduledLesson',
}

export interface BlockMember {
  userId: number;
  name: string;
  block: Block;
  questions: number;
  availableQuestions: number;
  tasks: number;
  availableTasks: number;
  paymentOrderId: number;
}

export interface BlockTask {
  id?: number;
  blockId: number;
  title: string;
  description: string;
  type: BlockTaskTypeEnum;
  correctAnswer?: string;
  successfulText?: string;
  unsuccessfulText?: string;
  metaData: string;
  block?: Block;
}

export enum BlockTaskTypeEnum {
  None = 0,
  Text = 1,
  RadioButton = 2,
  MultiCheckBox = 3,
  SingleCheckboxField = 4,
}

export interface BlockApiPagedDto {
  currentPage: number;
  records: BlockItem[];
  totalPages: number;
  totalRecords: number;
}

export interface BlockPagedApiResponse {
  data?: null | BlockApiPagedDto;
  pageNumber?: null | number;
  pageSize?: null | number;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
  totalPages?: null | number;
  totalRecords?: null | number;
}

@Injectable({
  providedIn: 'root',
})
export class BlockApiService extends BaseService {
  updateBlocksList$ = new Subject<boolean>();
  createBlock$ = new Subject<boolean>();
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getBlockList(params: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<BlockPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/block', 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockPagedApiResponse>),
      );
  }

  createBlock(blockCreateRequest: BlockCreateRequest): Observable<StrictHttpResponse<BlockApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/block', 'post');
    if (blockCreateRequest) {
      rb.body(blockCreateRequest, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockApiResponse>),
      );
  }

  addBlockExercise(exercise: BlockExercise) {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/block/{blockId}/exercise', 'patch');
    if (exercise) {
      rb.body(exercise, 'multipart/form-data');
    }
    if (exercise?.blockId) {
      rb.path('blockId', exercise.blockId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockApiResponse>),
      );
  }

  deleteBlockExercise(blockId: number, removeExerciseId: number) {
    const rb = new RequestBuilder(
      this.rootUrl,
      '/api/v1/block/{blockId}/exercise' + '?removeExerciseId=' + removeExerciseId,
      'delete',
    );

    if (blockId) {
      rb.path('blockId', blockId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockApiResponse>),
      );
  }

  deleteBlockTask(blockId: number, taskId: number) {
    const rb = new RequestBuilder(
      this.rootUrl,
      '/api/v1/block/{blockId}/task' + '?removeQuestionId=' + taskId,
      'delete',
    );

    rb.path('blockId', blockId, {});

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockApiResponse>),
      );
  }

  getBlock(blockId: number): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/block/' + blockId, 'get');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
      );
  }

  addBlockTask(task: BlockTask) {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/block/{blockId}/task', 'patch');
    if (task) {
      rb.body(task, 'multipart/form-data');
    }
    if (task?.blockId) {
      rb.path('blockId', task.blockId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BlockApiResponse>),
      );
  }
}
