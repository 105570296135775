<div
  [formGroup]="form"
  class="profile_reference"
>
  <div class="profile_content__subtitle"> {{ 'Загруженные файлы рекомендательных писем' | transloco }} </div>
  <div class="reference_list">
    <ng-container *ngFor="let document of documents.value; let index = index">
      <ng-container *ngFor="let page of document.documentFiles">
        <div class="reference_item">
          <div class="reference_item__image">
            <img
              src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                localStorageUser.id
              }}/reference-letters?redirect=true&file={{ page.fileName }}&access_token={{ token }}" />
          </div>
          <div
            class="reference_item__content"
            *ngIf="document"
          >
            <div class="reference_item__name">{{ page.displayFileName }}</div>
            <div class="reference_item__delete"><a (click)="removeFile(index)">{{ 'Удалить' | transloco }} </a></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <input
    class="hideinput"
    multiple
    type="file"
    accept="image/jpeg,image/png,application/pdf"
    (change)="addFile($event)"
    #file
  />
  <button
    (click)="file.click()"
    class="btn btn--blue-border"
    [disabled]="uploading"
  >
    <span
      class="btn__label"
      i18n
    >{{ (!uploading ? 'Загрузить файл' : 'Загружается') | transloco }}</span>
  </button>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="setProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span
        class="btn__label"
        i18n
      >{{ 'Сохранить изменения' | transloco }} </span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>
