import { BATCH_ACTIONS, BROADCAST_ACTIONS } from '@ezteach/constants';
import { Action } from '@ngrx/store';
import { equals, filter as Rfilter, find, includes, intersection, lt, map, prop, propSatisfies } from 'ramda';
import { from, of, OperatorFunction, pipe } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

/**
 * Unwraps actions out of broadcast action.
 *
 * @returns Observable<Action> actions not unwrapped.
 *
 * @note should be used in places when you expect the action may come wrapped into broadcast action
 * @note should be used as alternative to `unwrapBroadcasted` Effect or broadcastActions meta reducer
 */
export function unwrapBroadcasted(): OperatorFunction<Action, Action> {
  return mergeMap((action: Action) => {
    if (equals(action.type, BROADCAST_ACTIONS)) {
      const broadcastAction = action as any;
      const actions: Action[] = broadcastAction.payload;

      return from(actions);
    }

    return of(action);
  });
}

/**
 * Filters actions by type, including batched actions.
 *
 * @params array of strings types.
 * @returns Observable<Action> same action not unwrapped.
 */
export function ofTypeDeepFiltered(...allowedTypes: string[]): OperatorFunction<Action, Action> {
  return filter<Action>((action: Action) => {
    if (equals(action.type, BATCH_ACTIONS)) {
      const batchAction = action as any;
      const actions = batchAction.payload;
      const actionsTypes: string[] = map(prop('type'), actions);
      const intersect = intersection(allowedTypes, actionsTypes);

      return propSatisfies(lt(0), 'length', intersect);
    }

    return allowedTypes.some(type => type === action.type);
  });
}

/**
 * Filters actions by type, including batched actions.
 *
 * @params array of strings types.
 * @returns Observable<Action> one action (may be unwraped). E.g. if it was BatchAction
 *   then it unwraps it and returns just ONE first that matches the types provided
 */
export function ofTypeDeepOne(...allowedTypes: string[]): OperatorFunction<Action, Action> {
  return pipe(
    ofTypeDeepFiltered(...allowedTypes),
    mergeMap((action: Action) => {
      if (equals(action.type, BATCH_ACTIONS)) {
        const batchAction = action as any;
        const actions = batchAction.payload;
        const typeAction: Action = find(({ type }) => includes(type, allowedTypes), actions);

        return of(typeAction);
      }

      return of(action);
    }),
  );
}

/**
 * Filters actions by type, including batched actions.
 *
 * @params array of strings types.
 * @returns Observable<Action> same actions but unwrapped. E.g. if it was BatchAction
 *   then it unwraps it and returns ALL actions that matches the types provided
 *
 * @note use this one only if needed, coz it may cause strong backpressure
 */
export function ofTypeDeepMany(...allowedTypes: string[]): OperatorFunction<Action, Action> {
  return pipe(
    ofTypeDeepFiltered(...allowedTypes),
    mergeMap((action: Action) => {
      if (equals(action.type, BATCH_ACTIONS)) {
        const batchAction = action as any;
        const actions = batchAction.payload;
        const typeActions: Action[] = Rfilter(({ type }) => includes(type, allowedTypes), actions);

        return from(typeActions);
      }

      return of(action);
    }),
  );
}
