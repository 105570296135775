import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhiteBoardLayoutService {
  width$ = new BehaviorSubject<number>(0);
  height$ = new BehaviorSubject<number>(0);
  animationEnded$ = new BehaviorSubject<boolean>(true);
  resized$ = new Subject<void>();
}
