import { Pipe, PipeTransform } from '@angular/core';

export enum TimeType {
  Hour,
  Minute,
  Second,
}

@Pipe({
  name: 'timeDeclaration',
})
export class TimeDeclarationPipe implements PipeTransform {
  hourTextForms = ['час', 'часа', 'часов'];
  minuteTextForms = ['минута', 'минуты', 'минут'];
  secondTextForms = ['секунда', 'секунды', 'секунд'];

  transform(value: number, type: TimeType): string {
    if (type === TimeType.Hour) {
      return this.declarationOfHour(value);
    } else if (type === TimeType.Minute) {
      return this.declarationOfMinute(value);
    } else if (type === TimeType.Second) {
      return this.declarationOfSecond(value);
    }
    return '';
  }

  declarationOfHour(date: number) {
    return this.declarationOfNum(new Date(date).getHours(), this.hourTextForms);
  }

  declarationOfMinute(date: number) {
    return this.declarationOfNum(new Date(date).getMinutes(), this.minuteTextForms);
  }

  declarationOfSecond(date: number) {
    return this.declarationOfNum(new Date(date).getSeconds(), this.secondTextForms);
  }

  declarationOfNum(n: number, textForms: string[]) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
      return textForms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return textForms[1];
    }
    if (n1 === 1) {
      return textForms[0];
    }
    return textForms[2];
  }
}
