import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UsersService } from '@ezteach/api/services/users.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { select, Store } from '@ngrx/store';
import { isProfileSaving } from '@ezteach/profile/+state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  updateUserData$: Subject<void> = new Subject<void>();

  constructor(private usersService: UsersService, private oauthService: OAuthService, private store: Store) {
    this.updateUserData$.pipe(tap(_ => this.updateUserData())).subscribe();

    this.store
      .pipe(select(isProfileSaving))
      .pipe(
        tap(isSaving => {
          if ( isSaving === false ) {
            this.updateUserData();
          }
        }),
      )
      .subscribe();
  }

  private updateUserData(): void {
    const claims = this.oauthService.getIdentityClaims();

    if (!claims) {
      return;
    }

    //@ts-ignore
    const userId = +claims?.sub;

    this.usersService
      .apiV1UsersUserIdGet({ userId })
      .pipe(
        switchMap(
          () => this.usersService.apiV1UsersUserIdProfileGet({ userId }),
          (user, profile) => ({ user, profile }),
        ),
      )
      .subscribe(
        ({ user, profile }) => {
          this.userData$.next({
            ...user.data,
            email: profile.data.personalDetails.email,
            phone: `+${profile.data.personalDetails.phone}`,
            profileTypeId: profile.data.profileTypeId,
          });
        },
        () => {
          this.signin();
          location.reload();
        },
      );
  }

  private signin() {
    this.oauthService.initCodeFlow();
  }
}
