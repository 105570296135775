import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupWithBgComponent } from './popup-with-bg/popup-with-bg.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';

@NgModule({
  declarations: [PopupWithBgComponent, InfoPopupComponent],
  exports: [PopupWithBgComponent, InfoPopupComponent],
  imports: [CommonModule],
})
export class PopupModule {}
