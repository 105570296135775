import { NgModule } from '@angular/core';
import { ASSETS_PRELOAD_CONFIG, SharedModule } from '@ezteach/shared';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer, TutorialEffects, tutorialFeatureKey } from './+state';
import { NoopComponent, TutorialDialogWrapperComponent, TutorialTooltipWrapperComponent } from './components/common';
import * as OnboardingStudentSteps from './components/onboarding-student';
import * as OnboardingTeacherSteps from './components/onboarding-teacher';
import * as ProfileSteps from './components/profile';
import { TutorialStepDirective } from './directives';
import * as Validators from './validators';

@NgModule({
  declarations: [
    TutorialStepDirective,
    TutorialDialogWrapperComponent,
    TutorialTooltipWrapperComponent,
    OnboardingTeacherSteps.Step0WelcomeDialogComponent,
    OnboardingTeacherSteps.Step1_1TooltipComponent,
    OnboardingTeacherSteps.Step1_2TooltipComponent,
    OnboardingTeacherSteps.Step2TooltipComponent,
    OnboardingTeacherSteps.Step3TooltipComponent,
    OnboardingTeacherSteps.Step4FinishDialogComponent,
    ProfileSteps.ProfileDetailsMissingDialogComponent,
    NoopComponent,
    OnboardingStudentSteps.WelcomeDialogComponent,
    OnboardingStudentSteps.Step1TooltipComponent,
    OnboardingStudentSteps.Step2TooltipComponent,
    OnboardingStudentSteps.Step3TooltipComponent,
    OnboardingStudentSteps.Step4TooltipComponent,
    OnboardingStudentSteps.Step5TooltipComponent,
    OnboardingStudentSteps.Step6TooltipComponent,
  ],
  imports: [
    SharedModule,
    StoreModule.forFeature(tutorialFeatureKey, reducer),
    EffectsModule.forFeature([TutorialEffects]),
    TranslocoRootModule,
  ],
  exports: [TutorialStepDirective],
  providers: [
    {
      provide: ASSETS_PRELOAD_CONFIG,
      useValue: {
        images: [
          '/assets/img/tutorial/common/lesson.svg',
          '/assets/img/tutorial/common/student.svg',
          '/assets/img/tutorial/onboarding-teacher/onboarding-finish.svg',
          '/assets/img/tutorial/onboarding-teacher/onboarding-welcome.svg',
          '/assets/img/tutorial/onboarding-teacher/profile-missing-header.svg',
          '/assets/img/tutorial/onboarding-teacher/sad-smiley.svg',
          '/assets/img/tutorial/onboarding-student/welcome1.svg',
          '/assets/img/tutorial/onboarding-student/welcome2.png',
          '/assets/img/tutorial/onboarding-student/welcome3.svg',
          '/assets/img/tutorial/onboarding-student/step6.svg',
        ],
      },
      multi: true,
    },
    {
      provide: Validators.ONBOARDING_TEACHER_FINISH_STEP_VALIDATOR,
      useClass: Validators.OnboardingTeacherFinishStepValidatorService,
    },
    {
      provide: Validators.COMMON_VALIDATOR,
      useClass: Validators.CommonValidatorService,
    },
    {
      provide: Validators.PROFILE_DETAILS_MISSING_STEP_VALIDATOR,
      useClass: Validators.ProfileDetailsMissingValidatorService,
    },
  ],
})
export class TutorialModule {}
