import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WhiteboardMenuService {
  private readonly openSubject = new BehaviorSubject<boolean>(false);
  private readonly positionSubject = new BehaviorSubject<MenuPosition | undefined>(undefined);

  readonly open$ = this.openSubject.asObservable();
  readonly position$ = this.positionSubject.asObservable();

  open(position: MenuPosition): void {
    this.openSubject.next(true);
    this.positionSubject.next(position);
  }
  close(): void {
    this.openSubject.next(false);
  }
}

export interface MenuPosition {
  left: number;
  top: number;
}
