import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="3 из 3"
      nextButtonText="Готово!"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Быстрое событие</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Если вы готовы провести событие прямо сейчас, переведите переключатель в положение “Я онлайн“
      </p>
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step3TooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
