import { NgModule } from '@angular/core';
import { FindModeratorPipe } from '@ezteach/shared/pipes/find-moderator.pipe';
import { FindStudentUser } from '@ezteach/shared/pipes/find-student-user.pipe';
import { FindTutorUser } from '@ezteach/shared/pipes/find-tutor-user.pipe';
import { WithoutModeratorPipe } from '@ezteach/shared/pipes/without-moderator.pipe';
import { AddDurationPipe } from './add-duration.pipe';
import { CachedImagePipe } from './cached-image.pipe';
import { DurationPipe } from './duration.pipe';
import { FileSizePipe } from './filesize.pipe';
import { FilterSpecializationNamesPipe } from './filter-specialization-names.pipe';
import { FoldPipe } from './fold.pipe';
import { ToFormArrayPipe, ToFormControlPipe, ToFormGroupPipe } from './form.pipe';
import { LessonDateTimeInfo } from './lesson-datetime.pipe';
import { LessonPaymentInfo } from './lesson-payment-info.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { LogPipe } from './log.pipe';
import { MomentPipe } from './moment.pipe';
import { UserAvatarPipe } from './user-avatar.pipe';
import { UserInitialsPipe } from './user-initials.pipe';
import { UserOppositeRoleOrMePipe } from './user-opposite-role-or-me.pipe';
import {FormatBytesPipe} from '@ezteach/shared/pipes/formatBytes.pipe';

const PIPES = [
  AddDurationPipe,
  DurationPipe,
  LinkifyPipe,
  LogPipe,
  FoldPipe,
  CachedImagePipe,
  WithoutModeratorPipe,
  FindModeratorPipe,
  FileSizePipe,
  FindStudentUser,
  FindTutorUser,
  UserAvatarPipe,
  UserInitialsPipe,
  UserOppositeRoleOrMePipe,
  LessonPaymentInfo,
  LessonDateTimeInfo,
  MomentPipe,
  ToFormControlPipe,
  ToFormGroupPipe,
  ToFormArrayPipe,
  FilterSpecializationNamesPipe,
  FormatBytesPipe,
];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
})
export class PipesModule {}
