import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ezteach-file-shape-tools',
  templateUrl: './file-shape-tools.component.html',
  styleUrls: ['./file-shape-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileShapeToolsComponent implements OnInit {
  @Output()
  onDownload = new EventEmitter();

  opened = false;
  activeIcon = false;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  open() {
    this.opened = true;
    this.cdr.detectChanges();
  }

  close() {
    this.opened = false;
    this.cdr.detectChanges();
  }

  download() {
    this.onDownload.emit();
  }

  setActive(): void {
    this.activeIcon = true;
  }

  unsetActive(): void {
    this.activeIcon = false;
  }
}
