import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { AutoCompleteItemIEnumerableApiResponse } from '../models/auto-complete-item-i-enumerable-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class LookupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1LookupDisciplineGet
   */
  static readonly ApiV1LookupDisciplineGetPath = '/api/v1/lookup/discipline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupDisciplineGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupDisciplineGet$Response(params?: {
    term?: null | string;
  }): Observable<StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupDisciplineGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupDisciplineGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupDisciplineGet(params?: { term?: null | string }): Observable<AutoCompleteItemIEnumerableApiResponse> {
    return this.apiV1LookupDisciplineGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>) =>
          r.body as AutoCompleteItemIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1LookupDisciplineSpecializationGet
   */
  static readonly ApiV1LookupDisciplineSpecializationGetPath = '/api/v1/lookup/discipline-specialization';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupDisciplineSpecializationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupDisciplineSpecializationGet$Response(params?: {
    term?: null | string;
  }): Observable<StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupDisciplineSpecializationGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupDisciplineSpecializationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupDisciplineSpecializationGet(params?: {
    term?: null | string;
  }): Observable<AutoCompleteItemIEnumerableApiResponse> {
    return this.apiV1LookupDisciplineSpecializationGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>) =>
          r.body as AutoCompleteItemIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1LookupAcademicDegreeGet
   */
  static readonly ApiV1LookupAcademicDegreeGetPath = '/api/v1/lookup/academic-degree';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupAcademicDegreeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupAcademicDegreeGet$Response(params?: {
    term?: null | string;
  }): Observable<StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupAcademicDegreeGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupAcademicDegreeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupAcademicDegreeGet(params?: { term?: null | string }): Observable<AutoCompleteItemIEnumerableApiResponse> {
    return this.apiV1LookupAcademicDegreeGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>) =>
          r.body as AutoCompleteItemIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1LookupBankFieldGet
   */
  static readonly ApiV1LookupBankFieldGetPath = '/api/v1/lookup/bank/{field}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupBankFieldGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupBankFieldGet$Response(params: {
    term?: null | string;
    field: null | string;
  }): Observable<StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupBankFieldGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
      rb.path('field', params.field, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupBankFieldGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupBankFieldGet(params: {
    term?: null | string;
    field: null | string;
  }): Observable<AutoCompleteItemIEnumerableApiResponse> {
    return this.apiV1LookupBankFieldGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>) =>
          r.body as AutoCompleteItemIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1LookupAddressFieldGet
   */
  static readonly ApiV1LookupAddressFieldGetPath = '/api/v1/lookup/address/{field}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1LookupAddressFieldGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupAddressFieldGet$Response(params: {
    term?: null | string;
    field: null | string;
  }): Observable<StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.ApiV1LookupAddressFieldGetPath, 'get');
    if (params) {
      rb.query('term', params.term, {});
      rb.path('field', params.field, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1LookupAddressFieldGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1LookupAddressFieldGet(params: {
    term?: null | string;
    field: null | string;
  }): Observable<AutoCompleteItemIEnumerableApiResponse> {
    return this.apiV1LookupAddressFieldGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AutoCompleteItemIEnumerableApiResponse>) =>
          r.body as AutoCompleteItemIEnumerableApiResponse,
      ),
    );
  }
}
