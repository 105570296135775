import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { WhiteboardModule } from 'projects/ng-konva/src/lib/whiteboard/components/whiteboard/whiteboard.module';
import { WhiteBoardFileService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-file.service';
import { WhiteboardMenuService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-menu.service';
import { WhiteBoardSceneService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-scene.service';
import { WhiteBoardShapeService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-shape.service';
import { WhiteboardStoreService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-store.service';
import { WhiteBoardUserService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-users.service';
import { WhiteboardSignalrService } from './services/whiteboard-signalr.service';
import { WhiteboardAppComponent } from './whiteboard-app.component';
import { WhiteboardRoutingModule } from './whiteboard.routing.module';

@NgModule({
  imports: [CommonModule, WhiteboardRoutingModule, WhiteboardModule, ButtonModule, TranslocoRootModule],
  declarations: [WhiteboardAppComponent],
  exports: [WhiteboardAppComponent],
  providers: [
    WhiteboardSignalrService,
    WhiteBoardUserService,
    WhiteBoardShapeService,
    WhiteboardStoreService,
    WhiteboardMenuService,
    WhiteBoardSceneService,
    WhiteBoardFileService,
  ],
})
export class WhiteboardAppModule {}
