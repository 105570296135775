import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@ezteach/_services/user.service';
import { InstantLessonDurationEnum } from '@ezteach/api/models';
import { Langs } from '@ezteach/shared/services/lang-store.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { TutorUserSearchResult } from '../api/models/tutor-user-search-result';
import { TutorUserSearchResultPagedApiResponse } from '../api/models/tutor-user-search-result-paged-api-response';
import { SearchService } from '../api/services/search.service';

@UntilDestroy()
@Component({
  selector: 'search-instant',
  templateUrl: './search-instant.component.html',
})
export class SearchInstantComponent {
  private readonly load$ = new BehaviorSubject<number>(1);
  private readonly pageSize = 4;
  private SpecializationId: number;
  loaded = false;
  results: TutorUserSearchResultPagedApiResponse;
  teachers: Array<TutorUserSearchResult>;
  InstantLessonDuration: number;
  DisciplinesNames: string[];
  // TODO: DisciplineName нигде не инициализируется, уточнить зачем он вообще здесь нужен
  DisciplineName: string;
  Message: string;
  TutorName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private userService: UserService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const { isStudent } = this.userService.userData$.value;
    if (!isStudent) {
      this.router.navigateByUrl('/');
      return;
    }
    this.subscribeQueryParams();
    this.subscribeLoad();
  }

  getResults(): void {
    this.load$.next(1);
  }

  getMore(): void {
    this.load$.next(this.load$.value + 1);
  }

  private subscribeQueryParams(): void {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe(queryParamMap => {
      const duration = queryParamMap.get('duration');
      const specialization = queryParamMap.get('specialization');
      this.DisciplinesNames = queryParamMap.getAll('discipline');
      this.InstantLessonDuration = +(duration ?? '5');
      this.SpecializationId = specialization ? +specialization : null;
      this.Message = queryParamMap.get('message') as string;
      this.TutorName = queryParamMap.get('name') as string;
      this.getResults();
    });
  }

  private subscribeLoad(): void {
    this.load$
      .pipe(
        debounceTime(100),
        tap(() => (this.loaded = false)),
        switchMap(pageNumber =>
          this.searchService.apiV1SearchTutorsInstantGet({
            InstantLessonDuration: toInstantLessonDurationEnum(this.InstantLessonDuration),
            DisciplinesNames:
              this.translocoService.getActiveLang() === Langs.RU
                ? this.DisciplinesNames
                : this.DisciplinesNames.map(x => this.translocoService.translate(x + '_s')),
            SpecializationId: this.SpecializationId,
            PageSize: this.pageSize,
            PageNumber: pageNumber,
            TutorName: this.TutorName,
          }),
        ),
        tap(() => (this.loaded = true)),
        untilDestroyed(this),
      )
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.results = results;
        const data = results.data || [];
        this.teachers = results.pageNumber === 1 ? data : this.teachers.concat(data);
      });
  }
}

function toInstantLessonDurationEnum(value: number): InstantLessonDurationEnum {
  switch (value) {
    case 5:
      return InstantLessonDurationEnum.Minutes5;
    case 10:
      return InstantLessonDurationEnum.Minutes10;
    case 30:
      return InstantLessonDurationEnum.Minutes30;
    default:
      return InstantLessonDurationEnum.Minutes5;
  }
}
