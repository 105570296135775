import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml | string {
    if (!value) {
      return '';
    }

    const result: string = this.stylize(value);
    if (result.includes('</a>')) {
      return this._domSanitizer.bypassSecurityTrustHtml(result);
    }
    if (result.includes('<')) {
      return result
        .split('')
        .map(char => (char === '<' ? '&lt' : char))
        .join('');
    }

    return result;
  }

  private stylize(text: string): string {
    const arrayFromString: string[] = text.split(' ');
    return arrayFromString.reduce((acc, cur) => {
      if ((cur.startsWith('http://') || cur.startsWith('https://')) && cur.length > 1) {
        return `${acc} <a href="${cur}" target="_blank">${cur}</a> `;
      } else {
        return `${acc} ${cur}`;
      }
    }, '');
  }
}
