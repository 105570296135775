import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { FileShapeToolsComponent } from './file-shape-tools.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule
  ],
  declarations: [FileShapeToolsComponent],
  exports: [FileShapeToolsComponent]
})
export class FileShapeToolsModule { }
