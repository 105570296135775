import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidateComponentInput, Validation } from '@ezteach/utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { string } from 'io-ts';
import { equals } from 'ramda';
import { take } from 'rxjs/operators';
import { selectTutorialState } from '../+state';
import { refreshTutorialStep } from '../+state/tutorial.actions';
import { TutorialRegistryService } from '../services';

/* Directive to be used for adding element to registry based on tutorial + step info  */
@UntilDestroy()
@Directive({
  selector: '[ezteachTutorialStep]',
})
export class TutorialStepDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  @ValidateComponentInput(string)
  tutorialElementName: Validation<string>;

  constructor(
    private readonly elem: ElementRef,
    private readonly store: Store<any>,
    private readonly elemsRegistry: TutorialRegistryService,
  ) {}

  ngOnInit() {
    pipe(
      this.tutorialElementName,
      map(name => this.elemsRegistry.registerElementWithName(name, this.elem)),
    );
  }

  ngOnDestroy() {
    pipe(
      this.tutorialElementName,
      map(name => this.elemsRegistry.unregisterElementWithName(name)),
    );
  }

  async ngAfterViewInit() {
    const { type, progress } = await this.store.select(selectTutorialState).pipe(take(1)).toPromise();
    const maybeElementName = this.elemsRegistry.getElementIdForStepDAta([type, progress]);

    pipe(
      maybeElementName,
      O.map(n1 =>
        pipe(
          O.fromEither(this.tutorialElementName),
          O.map(n2 => [n1, n2]),
        ),
      ),
      O.flatten,
      O.filter(([n1, n2]) => equals(n1, n2)),
      O.map(() => this.store.dispatch(refreshTutorialStep())),
    );
  }
}
