export const FONT_FAMILIES = ['Raleway', 'Helvetica Neue', 'Roboto', 'Arial', 'Times New Roman'] as const;
export const FONT_SIZES = [
  makeFontSize(8),
  makeFontSize(10),
  makeFontSize(12),
  makeFontSize(14),
  makeFontSize(16),
  makeFontSize(18),
  makeFontSize(24),
] as const;

export function makeFontSize(size: number) {
  return { id: size, name: `${size}`, size, sizePX: size + 'px' };
}
