import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ezteach/api/models/user';
import * as _ from 'lodash';

@Pipe({
  name: 'multiTypingAuthor',
})
export class MultiTypingAuthorPipe implements PipeTransform {
  transform(value: User[]) {
    let result = _.join(
      _.take(
        value.map(usr => usr.firstName),
        2,
      ),
      ',',
    );
    if (value.length > 2) {
      result = result + '...';
    }
    return result;
  }
}
