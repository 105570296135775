<ezteach-popup-with-bg
  [smiley]="smileyType.sad"
  [hasCloseBtn]="true"
  (onCloseBtnClick)="close()"
  [isDefaultWidth]="true"
>
  <div title> {{ 'Легко Учиться нужен доступ к камере и микрофону' | transloco }}</div>
  <div content>
    {{ 'Нажмите на значок заблокированной камеры' | transloco }}
    <img src="assets/img/svg/blocked_camera_chrome.svg" />
    {{ 'в адресной' | transloco }} <br />
    {{ 'строке браузера, разрешите камеру и микрофон, а затем перезагрузите страницу' | transloco }} .
  </div>
  <div buttons>
    <ezteach-btn
      (click)="close()"
      [type]="buttonType.warnFilled"
    >
      <div content> {{ 'Закрыть' | transloco }} </div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>