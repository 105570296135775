import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { toUser } from '../helpers/user-helper';

@Pipe({ name: 'userInitials' })
export class UserInitialsPipe implements PipeTransform {
  transform(u: ChatLessonMember | User): string {
    const user = toUser(u);
    if (!user) {
      return '';
    }
    return this.getUpperFirstCharOrNull(user.firstName) + this.getUpperFirstCharOrNull(user.lastName);
  }

  private getUpperFirstCharOrNull(text: string): string {
    return text?.charAt(0)?.toUpperCase();
  }
}
