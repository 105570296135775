import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { GroupLessonDialogFirstLoadComponent } from './group-lesson-dialog-first-load.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, TranslocoRootModule],
  declarations: [GroupLessonDialogFirstLoadComponent],
  exports: [GroupLessonDialogFirstLoadComponent],
})
export class GroupLessonDialogFirstLoadModule {}
