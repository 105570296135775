<div
  *ngIf="handOn(reactions, user?.user?.id)"
  class="widget-call-participants_reaction"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M1.01308 1.84337C0.494811 1.84337 0.0943298 2.26337 0.0943298 2.77671V9.6367H1.93183V2.77671C1.93183 2.26337 1.53135 1.84337 1.01308 1.84337ZM5.9602 9.6367H7.91548V1.82004C7.91548 1.30671 7.46789 0.863373 6.94962 0.863373C6.40779 0.863373 5.98375 1.30671 5.98375 1.84337C5.9602 1.84337 5.9602 9.6367 5.9602 9.6367Z"
      fill="#FFC849"
    />
    <path
      d="M6.95024 0.863373C6.87957 0.863373 6.83245 0.863373 6.78534 0.886706C7.23294 0.956706 7.5863 1.35337 7.5863 1.82004V9.6367H7.93967V1.82004C7.91611 1.28337 7.46851 0.863373 6.95024 0.863373Z"
      fill="#92929D"
    />
    <path
      d="M1.93216 9.63685H3.93456V1.65685C3.93456 1.09685 3.48696 0.653519 2.92158 0.653519C2.3562 0.653519 1.9086 1.09685 1.9086 1.65685L1.93216 9.63685Z"
      fill="#FFC849"
    />
    <path
      d="M2.94438 0.653519C2.87371 0.653519 2.8266 0.653519 2.77948 0.676852C3.25063 0.770185 3.604 1.16685 3.604 1.68019V6.65019L3.95736 7.14019V1.65685C3.95736 1.09685 3.50977 0.653519 2.94438 0.653519Z"
      fill="#92929D"
    />
    <path
      d="M3.95697 8.98333H5.95937V1.00333C5.95937 0.443333 5.51178 0 4.94639 0C4.38101 0 3.93341 0.443333 3.93341 1.00333L3.95697 8.98333Z"
      fill="#FFC849"
    />
    <path
      d="M4.94807 0C4.87739 0 4.83028 -2.26001e-08 4.78316 0.0233333C5.25432 0.116667 5.60768 0.513333 5.60768 1.02667V6.65L5.96105 7.14V1.00333C5.96105 0.443333 5.51345 0 4.94807 0ZM1.01393 1.84333C0.94326 1.84333 0.896145 1.84333 0.84903 1.86667C1.27307 1.96 1.60288 2.33333 1.60288 2.77667V6.86L1.95624 7.35V2.77667C1.93268 2.26333 1.5322 1.84333 1.01393 1.84333Z"
      fill="#92929D"
    />
    <path
      d="M12.1801 6.36983C11.8032 5.6465 10.6253 5.69317 9.4239 6.99983C8.57582 7.90983 8.41092 8.21317 7.56284 8.18983V7.23317C7.56284 7.23317 0.0950512 7.2565 0.0950512 7.83983C0.0950512 7.83983 -0.258314 10.4298 0.471974 12.0632C1.55563 14.4898 6.64409 14.8632 8.78784 10.9198C10.0364 8.5865 12.5806 7.13983 12.1801 6.36983Z"
      fill="#FFC849"
    />
    <path
      d="M12.1796 6.37032C12.109 6.23032 12.0147 6.13699 11.897 6.04366C11.9205 6.06699 11.9441 6.09032 11.9441 6.13699C12.3445 6.93032 11.1431 7.74699 10.295 8.58699C9.61185 9.26366 8.95224 9.96366 8.62243 10.7803C7.11474 14.3503 2.73301 14.3503 0.895508 12.717C2.5681 14.5837 7.25608 14.747 8.85801 11.0137C9.21137 10.197 9.82387 9.52032 10.5306 8.82032C11.3787 7.95699 12.5801 7.14032 12.1796 6.37032ZM7.79791 8.19032C6.40801 7.70032 3.53397 8.65699 3.91089 11.387C3.91089 8.84366 6.2431 8.21366 7.51522 8.21366C7.68012 8.19032 7.79791 8.19032 7.79791 8.19032Z"
      fill="#92929D"
    />
  </svg>
</div>