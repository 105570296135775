import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonWhiteBoardService {
  whiteBoardInitStart$ = new Subject<boolean>();
  whiteBoardInitEnd$ = new Subject<string>();
  whiteBoardOpened$ = new Subject<boolean>();
  whiteBoardClosed$ = new Subject<boolean>();
}
