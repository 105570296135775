import { EventEmitter, Injectable } from '@angular/core';
import { ChatMessage } from '@ezteach/api/models/chat-message';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export interface UserMessageSentEvent {
  chatMessage: ChatMessage;
}
interface UserMessageTypingEvent {
  userId: number;
}

@Injectable({
  providedIn: 'root',
})
export class SignalrChatService {
  private hubConnection: HubConnection;
  private connectionUrl = environment.apiUrl + '/hubs/chatroom';
  private roomId;

  connectionEstablished = new EventEmitter<boolean>();
  onSignal = new EventEmitter<any>();
  onMessageSent = new EventEmitter<any>();
  onUserMessageSent = new EventEmitter<UserMessageSentEvent>();
  onUserMessageTyping = new EventEmitter<any>();

  constructor(private authStorage: OAuthStorage) { }

  public connect = roomId => {
    this.roomId = roomId;

    this.startConnection();
    this.addListeners();
  };

  private getConnection(): HubConnection {
    return (
      new HubConnectionBuilder()
        .withUrl(this.connectionUrl + '?roomId=' + this.roomId, {
          accessTokenFactory: () => {
            const token = this.authStorage.getItem('access_token');
            return token;
          },
        })
        .withAutomaticReconnect()
        //  .configureLogging(LogLevel.Trace)
        .build()
    );
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection
      .start()
      .then(() => {
        this.connectionEstablished.emit(true);
      })
      .catch(err => console.log('error while establishing signalr connection: ' + err));
  }

  send(command: SignalRChatCommands) {
    this.hubConnection.invoke('SignalToOthers', command);
  }

  private addListeners() {
    this.hubConnection.on('onSignal', data => {
      if (data.signalName === 'onMessageSent') {
        this.onMessageSent.emit(data);
        console.log(data);
      }
      this.onSignal.emit(data);
    });
    this.hubConnection.on('onUserMessageSent', (chatMessageObj: UserMessageSentEvent) => {
      this.onUserMessageSent.emit(chatMessageObj);
    });
    this.hubConnection.on('onUserMessageTyping', (typingObj: UserMessageTypingEvent) => {
      this.onUserMessageTyping.emit(typingObj);
      // console.log(typingObj);
    });
  }
}

export type SignalRChatCommands = 'startLesson' | 'getProlong' | 'cancelLesson' | 'ready' | 'areyouready' | 'destroyed';
