import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { AcademicDegreeIEnumerableApiResponse } from '../models/academic-degree-i-enumerable-api-response';
import { DisciplineIEnumerableApiResponse } from '../models/discipline-i-enumerable-api-response';
import { DisciplineSpecializationIEnumerableApiResponse } from '../models/discipline-specialization-i-enumerable-api-response';
import { DisciplineSpecializationsIEnumerableApiResponse } from '../models/discipline-specializations-i-enumerable-api-response';
import { ExternalAccountTypeForSyncIEnumerableApiResponse } from '../models/external-account-type-for-sync-i-enumerable-api-response';
import { ExternalCalendarTypeForSyncIEnumerableApiResponse } from '../models/external-calendar-type-for-sync-i-enumerable-api-response';
import { GenderIEnumerableApiResponse } from '../models/gender-i-enumerable-api-response';
import { NotificationMethodIEnumerableApiResponse } from '../models/notification-method-i-enumerable-api-response';
import { ReclamationItemIEnumerableApiResponse } from '../models/reclamation-item-i-enumerable-api-response';
import { SpecializationIEnumerableApiResponse } from '../models/specialization-i-enumerable-api-response';
import { UserBusinessRoleIEnumerableApiResponse } from '../models/user-business-role-i-enumerable-api-response';
import { UserStatusIEnumerableApiResponse } from '../models/user-status-i-enumerable-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1DictionariesDisciplinesGet
   */
  static readonly ApiV1DictionariesDisciplinesGetPath = '/api/v1/dictionaries/disciplines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesDisciplinesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplinesGet$Response(params?: {}): Observable<
    StrictHttpResponse<DisciplineIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesDisciplinesGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<DisciplineIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesDisciplinesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplinesGet(params?: {}): Observable<DisciplineIEnumerableApiResponse> {
    return this.apiV1DictionariesDisciplinesGet$Response(params).pipe(
      map((r: StrictHttpResponse<DisciplineIEnumerableApiResponse>) => r.body as DisciplineIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1DictionariesSpecializationsGet
   */
  static readonly ApiV1DictionariesSpecializationsGetPath = '/api/v1/dictionaries/specializations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesSpecializationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesSpecializationsGet$Response(params?: {}): Observable<
    StrictHttpResponse<SpecializationIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesSpecializationsGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<SpecializationIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesSpecializationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesSpecializationsGet(params?: {}): Observable<SpecializationIEnumerableApiResponse> {
    return this.apiV1DictionariesSpecializationsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<SpecializationIEnumerableApiResponse>) => r.body as SpecializationIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesDisciplineSpecializationsGet
   */
  static readonly ApiV1DictionariesDisciplineSpecializationsGetPath = '/api/v1/dictionaries/discipline-specializations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesDisciplineSpecializationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplineSpecializationsGet$Response(params?: {}): Observable<
    StrictHttpResponse<DisciplineSpecializationsIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DictionariesService.ApiV1DictionariesDisciplineSpecializationsGetPath,
      'get',
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<DisciplineSpecializationsIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesDisciplineSpecializationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplineSpecializationsGet(params?: {}): Observable<DisciplineSpecializationsIEnumerableApiResponse> {
    return this.apiV1DictionariesDisciplineSpecializationsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DisciplineSpecializationsIEnumerableApiResponse>) =>
          r.body as DisciplineSpecializationsIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesDisciplineSpecializationsFlatGet
   */
  static readonly ApiV1DictionariesDisciplineSpecializationsFlatGetPath =
    '/api/v1/dictionaries/discipline-specializations/flat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesDisciplineSpecializationsFlatGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplineSpecializationsFlatGet$Response(params?: {}): Observable<
    StrictHttpResponse<DisciplineSpecializationIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DictionariesService.ApiV1DictionariesDisciplineSpecializationsFlatGetPath,
      'get',
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<DisciplineSpecializationIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesDisciplineSpecializationsFlatGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesDisciplineSpecializationsFlatGet(params?: {}): Observable<DisciplineSpecializationIEnumerableApiResponse> {
    return this.apiV1DictionariesDisciplineSpecializationsFlatGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DisciplineSpecializationIEnumerableApiResponse>) =>
          r.body as DisciplineSpecializationIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesUserstatusesGet
   */
  static readonly ApiV1DictionariesUserstatusesGetPath = '/api/v1/dictionaries/userstatuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesUserstatusesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesUserstatusesGet$Response(params?: {}): Observable<
    StrictHttpResponse<UserStatusIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesUserstatusesGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserStatusIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesUserstatusesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesUserstatusesGet(params?: {}): Observable<UserStatusIEnumerableApiResponse> {
    return this.apiV1DictionariesUserstatusesGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserStatusIEnumerableApiResponse>) => r.body as UserStatusIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1DictionariesUserbusinessrolesGet
   */
  static readonly ApiV1DictionariesUserbusinessrolesGetPath = '/api/v1/dictionaries/userbusinessroles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesUserbusinessrolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesUserbusinessrolesGet$Response(params?: {}): Observable<
    StrictHttpResponse<UserBusinessRoleIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesUserbusinessrolesGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserBusinessRoleIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesUserbusinessrolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesUserbusinessrolesGet(params?: {}): Observable<UserBusinessRoleIEnumerableApiResponse> {
    return this.apiV1DictionariesUserbusinessrolesGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserBusinessRoleIEnumerableApiResponse>) =>
          r.body as UserBusinessRoleIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesGenderGet
   */
  static readonly ApiV1DictionariesGenderGetPath = '/api/v1/dictionaries/gender';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesGenderGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesGenderGet$Response(params?: {}): Observable<StrictHttpResponse<GenderIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesGenderGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<GenderIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesGenderGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesGenderGet(params?: {}): Observable<GenderIEnumerableApiResponse> {
    return this.apiV1DictionariesGenderGet$Response(params).pipe(
      map((r: StrictHttpResponse<GenderIEnumerableApiResponse>) => r.body as GenderIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1DictionariesNotificationmethodsGet
   */
  static readonly ApiV1DictionariesNotificationmethodsGetPath = '/api/v1/dictionaries/notificationmethods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesNotificationmethodsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesNotificationmethodsGet$Response(params?: {}): Observable<
    StrictHttpResponse<NotificationMethodIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesNotificationmethodsGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<NotificationMethodIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesNotificationmethodsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesNotificationmethodsGet(params?: {}): Observable<NotificationMethodIEnumerableApiResponse> {
    return this.apiV1DictionariesNotificationmethodsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<NotificationMethodIEnumerableApiResponse>) =>
          r.body as NotificationMethodIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesExternalaccounttypesforsyncGet
   */
  static readonly ApiV1DictionariesExternalaccounttypesforsyncGetPath =
    '/api/v1/dictionaries/externalaccounttypesforsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesExternalaccounttypesforsyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesExternalaccounttypesforsyncGet$Response(params?: {}): Observable<
    StrictHttpResponse<ExternalAccountTypeForSyncIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DictionariesService.ApiV1DictionariesExternalaccounttypesforsyncGetPath,
      'get',
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ExternalAccountTypeForSyncIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesExternalaccounttypesforsyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesExternalaccounttypesforsyncGet(params?: {}): Observable<ExternalAccountTypeForSyncIEnumerableApiResponse> {
    return this.apiV1DictionariesExternalaccounttypesforsyncGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ExternalAccountTypeForSyncIEnumerableApiResponse>) =>
          r.body as ExternalAccountTypeForSyncIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesExternalcalendartypesforsyncGet
   */
  static readonly ApiV1DictionariesExternalcalendartypesforsyncGetPath =
    '/api/v1/dictionaries/externalcalendartypesforsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesExternalcalendartypesforsyncGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesExternalcalendartypesforsyncGet$Response(params?: {}): Observable<
    StrictHttpResponse<ExternalCalendarTypeForSyncIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      DictionariesService.ApiV1DictionariesExternalcalendartypesforsyncGetPath,
      'get',
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ExternalCalendarTypeForSyncIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesExternalcalendartypesforsyncGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesExternalcalendartypesforsyncGet(params?: {}): Observable<ExternalCalendarTypeForSyncIEnumerableApiResponse> {
    return this.apiV1DictionariesExternalcalendartypesforsyncGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ExternalCalendarTypeForSyncIEnumerableApiResponse>) =>
          r.body as ExternalCalendarTypeForSyncIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesAcademicdegreesGet
   */
  static readonly ApiV1DictionariesAcademicdegreesGetPath = '/api/v1/dictionaries/academicdegrees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesAcademicdegreesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesAcademicdegreesGet$Response(params?: {}): Observable<
    StrictHttpResponse<AcademicDegreeIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesAcademicdegreesGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<AcademicDegreeIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesAcademicdegreesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesAcademicdegreesGet(params?: {}): Observable<AcademicDegreeIEnumerableApiResponse> {
    return this.apiV1DictionariesAcademicdegreesGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AcademicDegreeIEnumerableApiResponse>) => r.body as AcademicDegreeIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1DictionariesReclamationitemsGet
   */
  static readonly ApiV1DictionariesReclamationitemsGetPath = '/api/v1/dictionaries/reclamationitems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DictionariesReclamationitemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesReclamationitemsGet$Response(params?: {}): Observable<
    StrictHttpResponse<ReclamationItemIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, DictionariesService.ApiV1DictionariesReclamationitemsGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ReclamationItemIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1DictionariesReclamationitemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DictionariesReclamationitemsGet(params?: {}): Observable<ReclamationItemIEnumerableApiResponse> {
    return this.apiV1DictionariesReclamationitemsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ReclamationItemIEnumerableApiResponse>) =>
          r.body as ReclamationItemIEnumerableApiResponse,
      ),
    );
  }
}
