import { Component, Input } from '@angular/core';
import { LocalDateNameSpaceService } from '@ezteach/_services/localDateNameSpace.service';
import { UserService } from "@ezteach/_services/user.service";
import { getStudentUser, getTutorUser } from '@ezteach/api/utils/chat-lesson-member-replacement';
import * as moment from 'moment';
import { ChatLesson, User } from '../../api/models';

@Component({
  selector: 'lesson_item',
  templateUrl: './lesson_item.component.html',
  styleUrls: ['./lesson_item.component.scss'],
})
export class LessonItemComponent {

  @Input() set item(value: ChatLesson) {
    this._item = value;
    this.isHaveParticipant = this._item.members.length > 1;
    this.disciplineName = value.discipline?.name ?? $localize`Событие не названо`;
    this.disciplineName = value.discipline?.name ?? $localize`Событие №`;
    this.disciplineNameShort = this.disciplineName?.charAt(0) ?? value.id.toString().charAt[0];
    this._id = !value.discipline?.name ? value.id : null
  }
  _id: number;
  _item: ChatLesson;
  isTutor: boolean;
  isHaveParticipant: boolean;
  participant: User;
  disciplineName = '';
  disciplineNameShort = '';
  months = '';
  days = '';
  hours = '';
  unknownDuration = false;
  unknownDurationText = 'Неизвестно';
  isDateExpireText = 'Истекло';
  isDateExpire = false;

  constructor(
    private localDateNameSpaceService: LocalDateNameSpaceService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    moment.locale('ru');
    this.isTutor = this.userService.userData$.value.isTutor === true;
    this.getParticipant();
    this.getExpireTime();
  }

  getExpireTime() {
    if (this._item.deletedAt !== null) {
      const expireDate = moment(this._item.deletedAt);
      const now = moment();

      const months = expireDate.diff(now, 'months');
      now.add(months, 'months');
      if (months > 0) {
        this.months = this.localDateNameSpaceService.relativeTimeName(months, true, 'month');
      }

      const days = expireDate.diff(now, 'days');
      now.add(days, 'days');
      if (days > 0) {
        this.days = this.localDateNameSpaceService.relativeTimeName(days, true, 'day');
      }

      const hours = expireDate.diff(now, 'hours');
      now.add(hours, 'hours');
      if (hours > 0) {
        this.hours = this.localDateNameSpaceService.relativeTimeName(hours, true, 'hour');
      }

      if (!this.months && !this.days && !this.hours) {
        this.isDateExpire = true;
      }
    } else {
      this.unknownDuration = true;
    }
  }

  getParticipant() {
    if (this.isTutor && this.isHaveParticipant) {
      this.participant = getStudentUser(this._item);
    }
    if (!this.isTutor || !this.isHaveParticipant) {
      this.participant = getTutorUser(this._item);
    }
  }

  get lesson() {
    return this._item;
  }

  get date() {
    return moment(this._item.lessonFinishDate).format('DD MMMM YYYY');
  }

  get duration() {
    const duration = moment(this._item.lessonFinishDate).diff(moment(this._item.lessonStartDate)) / 1000;
    const hours = Math.floor((duration / (60 * 60)) % 24);
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);
    const time = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

    return time;
  }

  get time() {
    return moment(this._item.lessonFinishDate).format('HH:mm');
  }
}
