import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import * as dot from 'dot-object';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestBuilder } from '../api/request-builder';
import { DictionariesService } from '../api/services/dictionaries.service';
import { FilesService } from '../api/services/files.service';
import { UsersService } from '../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
})
export class WizardComponent {
  loaded = false;
  loading = false;
  token;
  data;
  userdata;
  currentData;
  results;
  mod;
  currentMod = 'PersonalDetails';
  title;
  environment;
  modstep = 0;
  steps = [
    'PersonalDetails',
    'Avatar',
    'EducationDetails',
    'WorkExperience',
    'SpecialityDetails',
    'ReferenceLetters',
    'FinancialDetails',
  ];
  stepsMods = ['passport', 'avatar', 'education', 'work', 'specs', 'reference', 'bank'];

  workError = false;
  errors = {
    passport: false,
    avatar: false,
    reference: false,
    price: false,
    work: false,
    education: false,
    specs: false,
  };

  bankName;
  bankControl = new UntypedFormControl();
  bankNames = [];
  bankFiltered: Observable<string[]>;

  discipline: string;
  disciplinesControl = new UntypedFormControl();
  disciplinesNames = [];
  disciplinesNamesId = [];
  disciplinesFiltered: Observable<string[]>;
  disciplines = [];
  disciplinesCurrent = [];
  disciplinesCurrentArray = [];
  disciplinesAll = [];
  disciplinesError = false;
  academicDegrees = [];

  educationError = false;

  wait = false;
  declined = false;
  show = true;

  constructor(
    private authStorage: OAuthStorage,
    private dateAdapter: DateAdapter<any>,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private filesService: FilesService,
    private dictionariesService: DictionariesService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
    private translocoService: TranslocoService
  ) {
    router.events.subscribe(val => {
      this.mod = this.route.snapshot.paramMap.get('mod');
    });
  }

  ngOnInit() {
    this.token = this.authStorage.getItem('access_token');

    this.dateAdapter.setLocale('ru');

    this.environment = environment;
    this.userdata = this.userService.userData$.value;

    const mod = this.route.snapshot.paramMap.get('mod');

    if (mod) {
      this.mod = mod;

      if (mod === 'addcard') {
        this.route.queryParams.subscribe(params => {
          if (params.orderId) {
            this.usersService
              .apiV1UsersUserIdFinancialsBankcardsFinalizePatch({
                userId: this.userdata.id,
                body: {
                  OrderId: params.orderId,
                },
              })
              .subscribe(result => {
                window.location.href = '/wizard/bankcards';
              });
          }
        });
      }
    }

    if (this.userdata.tutor.profileWizardValidationStatusId === 'Approved') {
      window.location.href = '/';
      this.show = false;
    }
    if (this.userdata.tutor.profileWizardValidationStatusId === 'Pending') {
      this.wait = true;
      this.show = false;
    }
    if (this.userdata.tutor.profileWizardValidationStatusId === 'Declined') {
      this.declined = true;
      this.show = false;
    }

    // this.bankFiltered = this.bankControl.valueChanges
    // 	.pipe(
    // 		startWith(''),
    // 		map(bank => {
    // 			if (bank.length > 1) {
    // 				this.lookupService.apiV1LookupBankFieldGet({
    // 					'term': bank,
    // 					'field': 'name'
    // 				}).subscribe(results => {
    // 					this.bankFiltered = [
    // 						{
    // 							'id': '1',
    // 							'name': 'adfasdf'
    // 						},
    // 						{
    // 							'id': '2',
    // 							'name': 'adfasdf'
    // 						}
    // 					]
    // 				});
    // 			}
    // 		})
    // 	);

    this.usersService
      .apiV1UsersUserIdProfileGet({
        userId: this.userdata.id,
      })
      .subscribe(result => {
        this.currentData = result.data;
        this.loaded = true;

        if (!this.currentData.passportDetails) {
          this.currentData.passportDetails = {};
        }

        this.modstep = this.steps.indexOf(this.currentData.profileWizardStepId);
        if (!this.mod) {
          this.router.navigate(['/wizard/' + this.stepsMods[this.modstep]], {
            relativeTo: this.route,
          });
          this.mod = this.stepsMods[this.modstep];
        } else {
          //
        }

        this.dictionariesService.apiV1DictionariesAcademicdegreesGet().subscribe(response => {
          this.data = response.data;
          this.academicDegrees = this.data;
        });

        this.dictionariesService.apiV1DictionariesDisciplineSpecializationsGet().subscribe(response => {
          this.data = response.data;
          this.disciplinesNames = this.data.map(value => this.translocoService.translate(value.discipline.name));
          this.disciplinesNamesId = this.data.map(value => value.discipline);
        });

        this.dictionariesService.apiV1DictionariesDisciplineSpecializationsFlatGet().subscribe(result => {
          if (result.data) {
            result.data.forEach(item => {
              this.disciplinesAll.push({
                id: item.discipline.id,
                name: item.discipline.name,
              });
            });

            const map = new Map();
            for (const item of this.disciplinesAll) {
              if (!map.has(item.id)) {
                map.set(item.id, true);
                this.disciplines.push({
                  id: item.id,
                  name: item.name,
                });
              }
            }

            // current disciplines
            const disciplines = [];
            this.disciplinesAll = result.data;
            this.currentData.specialityDetails.disciplineSpecializations.forEach(item => {
              disciplines.push(
                this.disciplinesAll.filter(discipline => discipline.id === item.id).map(i => i.discipline.id)[0],
              );
            });

            const disciplinesCurrent = disciplines.filter((value, index, self) => self.indexOf(value) === index);

            this.disciplinesCurrentArray = disciplinesCurrent;

            for (const discipline of disciplinesCurrent) {
              this.disciplinesCurrent.push(this.disciplines.filter(i => i.id === discipline)[0]);
            }
          }
        });

        this._cdr.detectChanges();
      });

    this.disciplinesFiltered = this.disciplinesControl.valueChanges.pipe(
      startWith(''),
      map(discipline => this._filter(discipline)),
    );
  }

  _filter(discipline: string): string[] {
    if (discipline != '') {
      const filterValue = discipline.toLowerCase();
      const data = this.disciplinesNames.filter(discipline => discipline.toLowerCase().indexOf(filterValue) === 0);

      return data;
    } else {
      return [];
    }
  }

  addDiscipline() {
    if (this.discipline && this.discipline !== '') {
      const discipline = this.disciplinesNamesId.filter(
        discipline => this.translocoService.translate(discipline.name).toLowerCase().indexOf(this.discipline.toLowerCase()) === 0,
      )[0];
      if (discipline) {
        if (!this.disciplinesCurrentArray.includes(discipline.id)) {
          this.disciplinesCurrent.push(this.disciplines.filter(i => i.id === discipline.id)[0]);
        }
        this.discipline = '';
      }
    }
  }

  removeDiscipline(index) {
    delete this.disciplinesCurrent[index];
    this._cdr.detectChanges();
  }

  getSpecs(discipline) {
    return this.disciplinesAll.filter(i => i.discipline.id === discipline);
  }

  isSpec(id) {
    return this.currentData.specialityDetails.disciplineSpecializations.filter(item => item.id === id).length;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  saveSpecs() {
    let error = false;
    this.errors.specs = false;
    this.errors.price = false;
    this.disciplinesError = false;

    const disciplinesElements = document.querySelectorAll('.profile_specs .discipline');
    disciplinesElements.forEach(item => {
      const oneItem = item.querySelector('input[type="checkbox"]:checked');
      if (!oneItem) {
        error = true;
      }
    });
    if (!error) {
      const specs = [];
      const specsElements = document.querySelectorAll('.profile_specs input.spec');
      specsElements.forEach(item => {
        if ((item as HTMLInputElement).checked) {
          specs.push({
            id: (item as HTMLInputElement).value,
          });
        }
      });
      if (specs.length === 0) {
        this.errors.specs = true;
      }

      const instantLesson5MinuteRate = document.querySelector(
        '.profile_specs input[name=instantLesson5MinuteRate]',
      ) as HTMLInputElement;
      const scheduledLessonHourlyRate = document.querySelector(
        '.profile_specs input[name=scheduledLessonHourlyRate]',
      ) as HTMLInputElement;

      if (!this.checkValueInt(instantLesson5MinuteRate) || !this.checkValueInt(scheduledLessonHourlyRate)) {
        this.errors.price = true;
      } else {
        if (instantLesson5MinuteRate && instantLesson5MinuteRate.value != '') {
          this.currentData.specialityDetails.instantLesson5MinuteRate = instantLesson5MinuteRate.value;
        }
        if (scheduledLessonHourlyRate && scheduledLessonHourlyRate.value != '') {
          this.currentData.specialityDetails.scheduledLessonHourlyRate = scheduledLessonHourlyRate.value;
        }
      }

      if (!this.errors.specs && !this.errors.price) {
        this.currentData.specialityDetails.disciplineSpecializations = specs;
        this.save('specs');
      }
    } else {
      this.disciplinesError = true;
    }
  }

  addSec() {
    const add = {
      documentFiles: [],
      documentTypeId: 'SecondaryEducation',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addSecFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'secondary-education',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              this.currentData.educationDetails.documents[i].documentFiles.push(request.data[0]);

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeSecFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this._cdr.detectChanges();
  }

  removeSec(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this._cdr.detectChanges();
  }

  addHigh() {
    const add = {
      documentFiles: [],
      documentTypeId: 'HigherEducation',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addHighFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'higher-education',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              this.currentData.educationDetails.documents[i].documentFiles.push(request.data[0]);

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeHighFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this._cdr.detectChanges();
  }

  removeHigh(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this._cdr.detectChanges();
  }

  addAcademic() {
    const add = {
      documentFiles: [],
      documentTypeId: 'AcademicDegree',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addAcademicFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'academic-degree',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              this.currentData.educationDetails.documents[i].documentFiles.push(request.data[0]);

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeAcademicFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this._cdr.detectChanges();
  }

  removeAcademic(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this._cdr.detectChanges();
  }

  saveEducation() {
    let error = false;
    const self = this;

    let x = 0;

    this.errors.education = false;

    document.querySelectorAll('.profile_education .group').forEach(item => {
      const id = (item as HTMLLinkElement).rel;

      if (item.classList.contains('secondary') || item.classList.contains('high')) {
        var name = item.querySelector('.name') as HTMLInputElement;
        const from = item.querySelector('.from') as HTMLInputElement;
        const to = item.querySelector('.to') as HTMLInputElement;

        if (name.value === '') {
          name.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          name.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (from.value === '') {
          from.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          from.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (!error) {
          self.currentData.educationDetails.documents[id].educationalInstitutionName = name.value;
          self.currentData.educationDetails.documents[id].enrollmentDate = from.value;
          self.currentData.educationDetails.documents[id].graduateDate = to.value;
        }
      }

      if (item.classList.contains('academic')) {
        var name = item.querySelector('.name') as HTMLInputElement;
        const date = item.querySelector('.date') as HTMLInputElement;

        if (name.value === '') {
          name.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          name.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (date.value === '') {
          date.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          date.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (!error) {
          self.currentData.educationDetails.documents[id].academicDegreeId = name.value;
          self.currentData.educationDetails.documents[id].issueDate = date.value;
        }
      }

      x++;
    });

    if (x === 0) {
      this.errors.education = true;
    }

    if (!this.errors.education) {
      this.currentData = self.currentData;

      if (error) {
        this.educationError = true;
      } else {
        this.educationError = false;
        this.save('education');
      }
    }
  }

  avatarChange(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'user-avatar',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              this.currentData.userAvatar.avatarFileName = request.data[0].fileName;
              //this.save('avatar');
            }
          });
      }
    }
  }

  addReference(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=reference] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'reference-letters',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              const add = {
                documentFiles: [],
                documentTypeId: 'ReferenceLetter',
              };
              add.documentFiles.push(request.data[0]);
              this.currentData.referenceLetters.documents.push(add);

              document.querySelector('label[for=reference] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeReference(index) {
    delete this.currentData.referenceLetters.documents[index];
    this._cdr.detectChanges();
  }

  addWork() {
    const add = {};
    if (!this.currentData.workExperience) {
      this.currentData.workExperience = {};
    }
    if (!this.currentData.workExperience.workPlaces) {
      this.currentData.workExperience.workPlaces = [];
    }
    this.currentData.workExperience.workPlaces.push(add);
  }

  removeWork(index) {
    delete this.currentData.workExperience.workPlaces[index];
    this._cdr.detectChanges();
  }

  addPassportFile(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=passport] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'passport',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              if (!this.currentData.passportDetails.documentFiles) {
                this.currentData.passportDetails.documentFiles = [];
              }
              this.currentData.passportDetails.documentFiles.push(request.data[0]);

              document.querySelector('label[for=passport] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removePassportFile(index) {
    delete this.currentData.passportDetails.documentFiles[index];
    this._cdr.detectChanges();
  }

  checkValue(input) {
    if (input.value === '') {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValue(event) {
    const value = event.target.value;
    if (value === '') {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  checkValueInt(input) {
    const intformat = /^[0-9 ]+$/;

    if (input.value === '' || input.value === '0' || !input.value.match(intformat)) {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValueInt(event) {
    const intformat = /^\d+$/;
    const value = event.target.value;
    if (value === '' || value === '0' || !value.match(intformat)) {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  checkValueDate(input) {
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;

    if (input.value === '' || !input.value.match(dateformat)) {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValueDate(event) {
    const value = event.target.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;
    if (value === '' || !value.match(dateformat)) {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  saveAvatar() {
    this.save('avatar');
  }

  savePassport() {
    this.errors.passport = false;

    const firstName = document.querySelector('.profile_passport input[name=firstName]') as HTMLInputElement;
    const lastName = document.querySelector('.profile_passport input[name=lastName]') as HTMLInputElement;
    const patronymic = document.querySelector('.profile_passport input[name=patronymic]') as HTMLInputElement;

    const addressDetails = document.querySelector('.profile_passport input[name=addressDetails]') as HTMLInputElement;
    const city = document.querySelector('.profile_passport input[name=city]') as HTMLInputElement;
    const region = document.querySelector('.profile_passport input[name=region]') as HTMLInputElement;

    const number = document.querySelector('.profile_passport input[name=number]') as HTMLInputElement;
    const issueDate = document.querySelector('.profile_passport input[name=issueDate]') as HTMLInputElement;
    const issueAuthority = document.querySelector('.profile_passport input[name=issueAuthority]') as HTMLInputElement;

    if (
      !this.checkValue(firstName) ||
      !this.checkValue(lastName) ||
      !this.checkValue(patronymic) ||
      !this.checkValue(addressDetails) ||
      !this.checkValue(city) ||
      !this.checkValue(number) ||
      !this.checkValue(issueDate) ||
      !this.checkValue(issueAuthority)
    ) {
      this.errors.passport = true;
    }

    if (!this.checkValueDate(issueDate)) {
      this.errors.passport = true;
    }

    if (this.currentData.passportDetails?.documentFiles?.length === 0) {
      this.errors.passport = true;
    }

    if (!this.errors.passport) {
      if (!this.currentData.passportDetails) {
        this.currentData.passportDetails = {};
      }

      if (firstName && firstName.value != '') {
        this.currentData.passportDetails.firstName = firstName.value;
      }

      if (lastName && lastName.value != '') {
        this.currentData.passportDetails.lastName = lastName.value;
      }

      if (patronymic && patronymic.value != '') {
        this.currentData.passportDetails.patronymic = patronymic.value;
      }

      this.currentData.passportDetails.registrationAddress = {};

      if (addressDetails && addressDetails.value != '') {
        this.currentData.passportDetails.registrationAddress.addressDetails = addressDetails.value;
      }

      if (city && city.value != '') {
        this.currentData.passportDetails.registrationAddress.city = city.value;
      }

      if (region && region.value != '') {
        this.currentData.passportDetails.registrationAddress.region = region.value;
      }

      if (number && number.value != '') {
        this.currentData.passportDetails.number = number.value;
      }

      if (issueDate && issueDate.value != '') {
        this.currentData.passportDetails.issueDate = issueDate.value;
      }

      if (issueAuthority && issueAuthority.value != '') {
        this.currentData.passportDetails.issueAuthority = issueAuthority.value;
      }

      this.save('passport');
    }
  }

  addWorkFile(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        document.querySelector('label[for=work] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'work-experience',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              if (!this.currentData.workExperience) {
                this.currentData.workExperience = {};
              }
              if (!this.currentData.workExperience.documentFiles) {
                this.currentData.workExperience.documentFiles = [];
              }
              this.currentData.workExperience.documentFiles.push(request.data[0]);

              document.querySelector('label[for=work] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeWorkFile(index) {
    delete this.currentData.workExperience.documentFiles[index];
    this._cdr.detectChanges();
  }

  selectAll(event) {
    const obj = event.target;
    setTimeout(() => {
      obj.focus();
      obj.select();
    }, 50);
  }

  saveWork() {
    let error = false;
    this.errors.work = false;

    const workPlaces = [];
    let x = 0;

    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;

    document.querySelectorAll('.profile_work .companyName').forEach(item => {
      const it = item as HTMLInputElement;
      const enrollmentDate = document.querySelectorAll('.profile_work .enrollmentDate')[x] as HTMLInputElement;
      const leavingDate = document.querySelectorAll('.profile_work .leavingDate')[x] as HTMLInputElement;

      if (it.value === '') {
        it.closest('.mat-form-field').classList.add('mat-form-field-invalid');
        error = true;
      } else {
        it.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      }

      if (enrollmentDate.value === '' || !enrollmentDate.value.match(dateformat)) {
        enrollmentDate.closest('.mat-form-field').classList.add('mat-form-field-invalid');
        error = true;
      } else {
        enrollmentDate.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      }

      workPlaces.push({
        companyName: it.value,
        enrollmentDate: enrollmentDate.value,
        leavingDate: leavingDate.value,
      });

      x++;
    });

    if (error) {
      this.workError = true;
    } else {
      this.workError = false;
      if (!this.currentData.workExperience) {
        this.currentData.workExperience = {};
      }
      this.currentData.workExperience.workPlaces = workPlaces;
      this.save('work');
    }
  }

  get giverInitials() {
    if (this.userdata) {
      return this.userdata.firstName.charAt(0).toUpperCase() + this.userdata.lastName.charAt(0).toUpperCase();
    }
  }

  get givenAvatar() {
    if (this.currentData && this.currentData.userAvatar.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        this.userdata.id +
        '/user-avatar?redirect=true&file=' +
        this.currentData.userAvatar.avatarFileName
      );
    } else {
      return null;
    }
  }

  savePersonal() {
    const firstName = document.querySelector('.profile_common input[name=firstName]') as HTMLInputElement;
    const lastName = document.querySelector('.profile_common input[name=lastName]') as HTMLInputElement;
    const email = document.querySelector('.profile_common input[name=email]') as HTMLInputElement;
    const phone = document.querySelector('.profile_common input[name=phone]') as HTMLInputElement;
    const birthDate = document.querySelector('.profile_common input[name=birthDate]') as HTMLInputElement;
    const educationalInstitutionName = document.querySelector(
      '.profile_common input[name=educationalInstitutionName]',
    ) as HTMLInputElement;

    if (firstName && firstName.value != '') {
      this.currentData.personalDetails.firstName = firstName.value;
    }

    if (lastName && lastName.value != '') {
      this.currentData.personalDetails.lastName = lastName.value;
    }

    if (email && email.value != '') {
      this.currentData.personalDetails.email = email.value;
    }

    if (phone && phone.value != '') {
      this.currentData.personalDetails.phone = phone.value;
    }

    if (birthDate && birthDate.value != '') {
      this.currentData.personalDetails.birthDate = birthDate.value;
    }

    if (educationalInstitutionName && educationalInstitutionName.value != '') {
      this.currentData.personalDetails.educationalInstitutionName = educationalInstitutionName.value;
    }

    this.save('common');
  }

  saveReference() {
    this.errors.reference = false;

    // if(this.currentData.referenceLetters.documents.length === 0) {
    // 	this.errors.reference = true;
    // };

    if (!this.errors.reference) {
      this.save('reference');
    }
  }

  savePatron() {
    const firstName = document.querySelector('.profile_patron input[name=firstName]') as HTMLInputElement;
    const lastName = document.querySelector('.profile_patron input[name=lastName]') as HTMLInputElement;
    const email = document.querySelector('.profile_patron input[name=email]') as HTMLInputElement;
    const phone = document.querySelector('.profile_patron input[name=phone]') as HTMLInputElement;

    if (firstName && firstName.value != '') {
      this.currentData.studentPatron.patron.firstName = firstName.value;
    }

    if (lastName && lastName.value != '') {
      this.currentData.studentPatron.patron.lastName = lastName.value;
    }

    if (email && email.value != '') {
      this.currentData.studentPatron.patron.email = email.value;
    }

    if (phone && phone.value != '') {
      this.currentData.studentPatron.patron.phone = phone.value;
    }

    this.save('patron');
  }

  onBankChange() {}

  saveBank() {
    const accountNumber = document.querySelector('.profile_bank input[name=accountNumber]') as HTMLInputElement;
    const fullName = document.querySelector('.profile_bank input[name=fullName]') as HTMLInputElement;
    const shortName = document.querySelector('.profile_bank input[name=shortName]') as HTMLInputElement;
    const correspondentAccountNumber = document.querySelector(
      '.profile_bank input[name=correspondentAccountNumber]',
    ) as HTMLInputElement;
    const bic = document.querySelector('.profile_bank input[name=bic]') as HTMLInputElement;
    const swift = document.querySelector('.profile_bank input[name=swift]') as HTMLInputElement;
    const kpp = document.querySelector('.profile_bank input[name=kpp]') as HTMLInputElement;
    const address = document.querySelector('.profile_bank input[name=address]') as HTMLInputElement;

    if (accountNumber && accountNumber.value != '') {
      this.currentData.financialDetails.bankDetails.accountNumber = accountNumber.value;
    }

    if (fullName && fullName.value != '') {
      this.currentData.financialDetails.bankDetails.bank.fullName = fullName.value;
    }

    if (shortName && shortName.value != '') {
      this.currentData.financialDetails.bankDetails.bank.shortName = shortName.value;
    }

    if (correspondentAccountNumber && correspondentAccountNumber.value != '') {
      this.currentData.financialDetails.bankDetails.bank.correspondentAccountNumber = correspondentAccountNumber.value;
    }

    if (bic && bic.value != '') {
      this.currentData.financialDetails.bankDetails.bank.bic = bic.value;
    }

    if (swift && swift.value != '') {
      this.currentData.financialDetails.bankDetails.bank.swift = swift.value;
    }

    if (kpp && kpp.value != '') {
      this.currentData.financialDetails.bankDetails.bank.kpp = kpp.value;
    }

    if (address && address.value != '') {
      this.currentData.financialDetails.bankDetails.bank.address = address.value;
    }
    this.save('bank');
  }

  addBankcard() {
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsPost({
        userId: this.userdata.id,
        body: {
          ReturnUrl: environment.appUrl + '/wizard/addcard',
          FailUrl: environment.appUrl + '/wizard/addcard',
        },
      })
      .subscribe(result => {
        if (result.data) {
          window.location.href = result.data;
        }
      });
  }

  removeBankcard(id) {
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete({
        userId: this.userdata.id,
        bankCardId: id,
      })
      .subscribe(result => {
        if (result.data) {
          location.reload();
        }
      });
  }

  saveBankCards() {
    const defaultBank = document.querySelector('.profile_bankcards input[name=bankcard]:checked') as HTMLInputElement;
    this.currentData.bankCardBindings.defaultBankCardId = defaultBank.value;

    this.save('bankcards');
  }

  size(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes == 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 bytes';
    }
    return bytes;
  }

  save(mod = '') {
    const userdata = this.userService.userData$.value;

    let wizardStepId = 0;
    let next = '';
    if (this.mod === 'passport') {
      next = '/wizard/avatar';
    }
    if (this.mod === 'avatar') {
      wizardStepId = 1;
      next = '/wizard/education';
    }
    if (this.mod === 'education') {
      wizardStepId = 2;
      next = '/wizard/work';
    }
    if (this.mod === 'work') {
      wizardStepId = 3;
      next = '/wizard/specs';
    }
    if (this.mod === 'specs') {
      wizardStepId = 4;
      next = '/wizard/reference';
    }
    if (this.mod === 'reference') {
      wizardStepId = 5;
      next = '/wizard/bank';
    }
    if (this.mod === 'bank') {
      wizardStepId = 6;
      next = '/wizard';
    }
    this.loading = true;
    this.currentData.isProfileWizard = true;

    const dotData = dot.dot(this.currentData);

    const rb = new RequestBuilder(environment.apiUrl, '/api/v1/users/{userId}/profile', 'put');
    rb.path('userId', userdata.id, {});
    rb.query('wizardStepId', wizardStepId, {});

    const body = dotData;
    rb.body(body, 'multipart/form-data');

    this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r),
      )
      .subscribe(result => {
        if (document.querySelector('.profile_' + mod + ' .btn--submit .btn__label')) {
          document.querySelector('.profile_' + mod + ' .btn--submit .btn__label').innerHTML = 'Сохранено';
          setTimeout(
            () =>
              (document.querySelector('.profile_' + mod + ' .btn--submit .btn__label').innerHTML =
                'Сохранить изменения'),
            2000,
          );
        }
        document.location.href = next;
        //location.reload();
      });
  }
}
