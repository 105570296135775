import { map, reduce, concat } from 'ramda';
import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';
import { CachedImagesRegistryService } from './cached-images-registry.service';

export const ASSETS_PRELOAD_CONFIG = new InjectionToken<AssetsPreloadConfig>('AssetsPreloadConfig');
interface AssetsPreloadConfig {
  images: string[];
}

/**
 * @description Service used to preload different kinds of assets
 */
@Injectable({ providedIn: 'root' })
export class AssetsPreloadService {
  constructor(
    @Optional()
    @Inject(ASSETS_PRELOAD_CONFIG)
    private readonly config: AssetsPreloadConfig[],
    private readonly imageCache: CachedImagesRegistryService,
  ) {}

  public async preloadImages() {
    const images = reduce((acc, cur) => concat(acc, cur.images), [], this.config || []);
    const preloadImage = src =>
      new Promise((res, rej) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          this.imageCache.addImageToRegistry(img, src);
          res(img);
        };
        img.onerror = rej;
      });
    const assetsToPreload: Promise<any>[] = map(preloadImage, images);
    return await Promise.all(assetsToPreload);
  }
}
