import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDuration',
})
export class AddDurationPipe implements PipeTransform {
  transform(date: Date | string, duration: number, part: DurationParts): Date {
    const d = date instanceof Date ? date : new Date(date);
    const milliseconds = this.getFactorByPart(part) * duration;
    return new Date(d.getTime() + milliseconds);
  }

  private getFactorByPart(part: DurationParts): number {
    const value = 1000;
    switch (part) {
      case 'h':
        return value * 60 * 60;
      case 'm':
        return value * 60;
      case 's':
        return value;
    }
  }
}
export type DurationParts = 'h' | 'm' | 's';
