import { Injectable } from '@angular/core';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { ChatLessonMemberRole } from '@ezteach/api/models/chat-lesson-member-permisson';
import { Publisher, StreamManager } from 'openvidu-browser';
import { BehaviorSubject } from 'rxjs';
import { getStreamData } from '../helpers/stream.data';
import { ChatLessonMemberClient } from '../models/chat-lesson-member-client';

/*
 * Менеджер работы с потоками от OV и участниками занятия.
 * Управляет списков учасников, добавляет удаляет, устанавливает стрим, если есть.
 */

@Injectable({
  providedIn: 'root',
})
export class GroupLessonMemberManagerService {
  private memberClients: ChatLessonMemberClient[] = [];
  private readonly streamList: Map<number, StreamManager | Publisher> = new Map();
  public readonly memberClients$ = new BehaviorSubject<ChatLessonMemberClient[]>([]);
  public readonly owner$ = new BehaviorSubject<ChatLessonMemberClient>(null);

  addOwner(owner: ChatLessonMember) {
    const stream = this.tryGetStream(owner.memberId);
    this.owner$.next(new ChatLessonMemberClient(owner, stream));
  }

  tryAddMember(member: ChatLessonMember) {
    if (this.memberClients$.value.findIndex(x => x?.member?.memberId === member?.memberId) >= 0) {
      return false;
    }

    if (member.role === ChatLessonMemberRole.Owner) {
      return false;
    }

    const stream = this.tryGetStream(member.memberId);
    this.memberClients.push(new ChatLessonMemberClient(member, stream));
    this.memberClients$.next(this.memberClients);
    return true;
  }

  addMembers(members: ChatLessonMember[]) {
    for (const member of members) {
      this.tryAddMember(member);
    }
  }

  getMemberClients() {
    return this.memberClients.length;
  }

  setStream(stream: StreamManager | Publisher | null) {
    if (!stream) {
      console.error('setStream stream is null');
    }
    const userData = getStreamData(stream);
    if (userData) {
      let isStreamSetToMember = false;
      if (this.owner$?.value && this.owner$?.value?.member?.memberId === userData.memberId) {
        this.owner$.value.setStream(stream);
        isStreamSetToMember = true;
      } else {
        const member = this.memberClients.find(x => x.member.memberId === userData.memberId);
        if (member) {
          member.setStream(stream);
          isStreamSetToMember = true;
        } else {
          console.error('setStream member not found', this.memberClients);
        }
      }

      // мы не можем угадать кто первый придет member по сокету или стрим из OV
      if (!isStreamSetToMember) {
        if (this.streamList.has(+userData.memberId)) {
          this.streamList.delete(+userData.memberId);
        }
        this.streamList.set(+userData.memberId, stream);
      }
    }
  }

  setStreams(streams: any[]) {
    for (const stream of streams) {
      this.setStream(stream);
    }
  }

  // получаем стрим участника - обычно бывает, если OV стрим сработал раньше сокета.
  tryGetStream(memberId: number): StreamManager | Publisher | null {
    if (this.streamList.has(memberId)) {
      return this.streamList.get(memberId);
    }

    return null;
  }

  removeMemberClient(memberId: number) {
    const index = this.memberClients.findIndex(x => x.member.memberId === memberId);
    if (index !== -1) {
      this.memberClients.splice(index, 1);
      this.memberClients$.next(this.memberClients);
    }
  }

  setMemberIsDisconnected(memberId: number, online: boolean) {
    this.memberClients$.next(this.memberClients.map((m: ChatLessonMemberClient) => {
      if (m.member.memberId === memberId) {
        m.member.isDisconnected = online;
      }
      return m;
    }));
  }

  synchronizeMembers(members: ChatLessonMember[]) {
    const clients = this.memberClients.map(mc => mc.member.memberId);
    const membersIds = members.map(m => m.memberId);
    const membersToRemove = clients.filter(cId => !membersIds.includes(cId));
    for (const memberId of membersToRemove) {
      this.removeMemberClient(memberId);
    }

    const membersToAdd = members.filter(x => membersToRemove.findIndex(r => r === x.memberId) === -1);
    this.addMembers(membersToAdd);
  }

  isOwner(memberId: number): boolean {
    return this.owner$.value?.member?.memberId === memberId;
  }

  dispose() {
    this.memberClients = [];
    this.memberClients$.next([]);
  }
}
