import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DictionariesService } from '../../api/services/dictionaries.service';

@UntilDestroy()
@Component({
  selector: 'form-search-regular',
  templateUrl: './regular.component.html',
})
export class FormSearchRegularComponent implements OnInit {
  disciplinesControl = new UntypedFormControl();
  disciplinesNames = [];
  disciplinesFiltered: Observable<string[]>;

  specializationsControl = new UntypedFormControl();
  specializationsNames = [];

  specDefault = this.translocoService.translate('Выберите предмет');

  data;
  name: string;
  discipline: string;
  specialization;
  specializationGet;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dictionariesService: DictionariesService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParamMap => {
      this.name = queryParamMap.get('name');

      // todo: пофиксить, когда будет логика выбора нескольких предметов.
      // берём предмет, только если указан один предмет
      const disciplines = queryParamMap.getAll('discipline');
      this.discipline = disciplines?.length === 1 ? disciplines[0] : '';

      this.specialization = parseInt(queryParamMap.get('specialization'));
      this.specializationGet = queryParamMap.get('specialization');
    });

    this.getData();
  }

  getData() {
    this.dictionariesService.apiV1DictionariesDisciplineSpecializationsGet().subscribe(response => {
      this.data = response.data;
      this.disciplinesNames = this.data.map(value => this.translocoService.translate(value.discipline.name));
      this.disciplinesFiltered = this.disciplinesControl.valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        map(discipline => this._filter(discipline)),
      );
      if (this.discipline) {
        this.specializationsNames = this.data
          .filter(item => item.discipline.fullName.toLowerCase().indexOf(this.discipline.toLowerCase()) === 0)
          .map(item => item.specializations);
        if (this.specializationsNames.length > 0) {
          this.specializationsNames = this.specializationsNames[0];
        } else {
          this.specializationsNames = [];
        }
      }
      if (this.specializationGet) {
        this.specializationsControl.setValue(this.specializationGet * 1);
      }
    });
  }

  _filter(discipline: string): string[] {
    this.specializationsNames = [];
    this.specialization = null;
    this.specDefault = this.translocoService.translate('Выберите предмет');

    if (discipline.trim() != '') {
      const filterValue = discipline.toLowerCase();
      const data = this.disciplinesNames.filter(disciplineName => disciplineName.toLowerCase().indexOf(filterValue) === 0);

      return data;
    } else {
      return this.disciplinesNames.sort();
    }
  }

  onDisciplineChange() {
    if (this.discipline != '') {
      this.specializationsNames = this.data
        .filter(item => this.translocoService.translate(item.discipline.fullName).toLowerCase().indexOf(this.discipline.toLowerCase()) === 0)
        .map(item => item.specializations);
      if (this.specializationsNames.length > 0) {
        this.specDefault = this.translocoService.translate('Любая цель');
        this.specializationsNames = this.specializationsNames[0];
      } else {
        this.specializationsNames = [];
      }
    }

    this.specialization = null;
  }
  onSubmit() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/search/regular'], {
        relativeTo: this.route,
        //фильтруем только по тем параметрам, что ввёл пользователь.
        //todo: пофиксить, когда будет логика выбора нескольких предметов.
        queryParams: {
          name: this.name ? this.name : null,
          discipline: this.discipline ? this.discipline : null,
          specialization: this.specialization ? this.specialization : null,
        },
      }),
    );
  }
}
