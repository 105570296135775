<div class="group-lesson-chat-attach">
  <div *ngIf="isImage; else itisDocument">
    <a (click)="openImage(imageTemplate)">
      <div class="group-lesson-chat-attach__imgfile">
        <img
          class="group-lesson-chat-attach__imgfile__img"
          (load)="imageCompleted()"
          (error)="imageCompleted()"
          [src]="previewUrl"
          [ngClass]="mouseEntered ? 'group-lesson-chat-attach__imgfile_blur' : 'group-lesson-chat-attach__imgfile'"
        />
      </div>
    </a>
  </div>
  <ng-template #imageTemplate>
    <div>
      <img class="group-lesson-chat-attach__image-preview"
        [src]="attachmentUrl"
      />
      <button
        class="modal__close"
        (click)="closeImage()"
      >
        <img src="/assets/img/svg/icon-close.svg" />
      </button>
      <button
        class="group-lesson-chat-attach__download-icon"
      >
        <a  href="{{ attachmentUrl }}"
            target="_blank">
          <img src="/assets/img/svg/icon-cloud.svg" />
        </a>
      </button>
    </div>
  </ng-template>
  <ng-template #itisDocument>
    <a
      href="{{ attachmentUrl }}"
      target="_blank"
    >
      <div class="group-lesson-chat-attach__docfile">
        <span class="group-lesson-chat-attach__docfile__icon">
          <img src="/assets/img/svg/icon-file.svg" />
        </span>
        <span class="group-lesson-chat-attach__docfile__info">
          <span class="group-lesson-chat-attach__docfile__name">{{ fileName }}</span>
          <span class="group-lesson-chat-attach__docfile__size">{{ fileSize | fileSize }}</span>
        </span>
        <span class="group-lesson-chat-attach__docfile__cloud">
          <img src="/assets/img/svg/icon-cloud.svg" />
        </span>
      </div>
    </a>
  </ng-template>
</div>
