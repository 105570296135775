<div class="container">
  <div class="img-block">
    <img
      [src]="item?.preview ?? 'assets/img/block-default.png'"
      alt=""
    />
  </div>
  <div class="content">
    <div class="header">
      <a
        routerLink="/teachers/{{ item?.creator?.id }}"
        class="header__author"
      >
        {{ item?.creator?.name }}
      </a>
      <div
        class="header__title"
        (click)="openBlock()"
      >
        {{ item?.name | transloco }}
      </div>
      <div class="activity">
        <div class="activity-item">
          <div class="activity-item__img">
            <img
              src="/assets/img/svg/blocks/lessons.svg"
              alt="lessons"
            />
          </div>
          <div class="activity-item__txt">{{ 'Событий' | transloco }}: {{ item?.exerciseQty }}</div>
        </div>
        <div class="activity-item">
          <div class="activity-item__img">
            <img
              src="/assets/img/svg/blocks/participants.svg"
              alt="participants"
            />
          </div>
          <div class="activity-item__txt">{{ 'Участников' | transloco }}: {{ item?.membersQty }}</div>
        </div>
        <div
          class="activity-item"
          *ngIf="item?.hasCertificate"
        >
          <div class="activity-item__img">
            <img
              src="/assets/img/svg/blocks/certificate.svg"
              alt="certificate"
            />
          </div>
          <div class="activity-item__txt">{{ 'Сертификат' | transloco }}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        *ngIf="costType === 'PAY'"
        class="footer__cost"
      >
        {{ item?.costAmount | currency: costCurrency:'symbol-narrow':'2.0':'ru-RU' }}
      </div>
      <div
        *ngIf="costType === 'FREE'"
        class="footer__cost"
      >
        Бесплатно
      </div>
      <ezteach-btn
        class="footer__btn"
        [padding]="'8px 16px'"
        [width]="'100%'"
        [height]="'38px'"
        (click)="selectBlock()"
      >
        <div content>{{ 'Записаться на курс' | transloco }}</div>
      </ezteach-btn>
    </div>
  </div>
</div>
