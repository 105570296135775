import { TutorialTypes } from '@ezteach/tutorial/models';
import { NoopStepper } from './common.helpers';
import { OnboardingStudentStepper } from './onboarding-student.helpers';
import { OnboardingTeacherStepper } from './onboarding-teacher.helpers';
import { ProfileStepper } from './profile.helpers';
import { Stepper } from './stepper';

/* Helper strategy to provide needed type of stepper based on tutorial type */
export class TutorialHelpersStrategy implements Record<TutorialTypes, Stepper> {
  [TutorialTypes.ONBOARDING_TEACHER] = new OnboardingTeacherStepper();
  [TutorialTypes.ONBOARDING_STUDENT] = new OnboardingStudentStepper();
  [TutorialTypes.PROFILE] = new ProfileStepper();
  [TutorialTypes.NOOP] = new NoopStepper();
}
