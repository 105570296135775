import { Inject, Injectable } from '@angular/core';
import { ET_IS_APPLE_MOBILE } from '@ezteach/shared/tokens/is-mobile-apple';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

export type OrientationTypes = 'landscape' | 'portrait' | 'desktop';

@Injectable({
  providedIn: 'root',
})
export class OrientationService {
  private orientation = new BehaviorSubject<OrientationTypes>('portrait');
  public orientation$ = this.orientation.asObservable();

  constructor(
    @Inject(WINDOW) private readonly window: any,
    @Inject(ET_IS_APPLE_MOBILE) private readonly isIOS: boolean,
  ) {
    this.init();
  }

  init() {
    this.detectOrientation();
    fromEvent(this.window, 'orientationchange')
      .pipe(
        tap(x => {
          this.detectOrientation();
        }),
      )
      .subscribe();
  }

  detectOrientation() {
    if (!this.isIOS) {
      const orientation = !navigator.maxTouchPoints
        ? 'desktop'
        : !window.screen.orientation.angle
        ? 'portrait'
        : 'landscape';
      this.orientation.next(orientation);
    } else {
      const orientation = window.orientation === 0 ? 'portrait' : 'landscape';
      this.orientation.next(orientation);
    }
  }
}
