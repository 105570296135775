import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ezteach-time-label',
  template: `
    <ezteach-icon-label
      icon="/assets/img/svg/clock-outline.svg"
      [label]="(start | date: 'HH:mm') + ' — ' + (end | date: 'HH:mm')"
    ></ezteach-icon-label>
  `,
  styleUrls: ['./_style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TimeLabelComponent {
  @HostBinding('class.ezteach-time-label') className = true;
  @Input() start: Date | string;
  @Input() end: Date | string;
}
