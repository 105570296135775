import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@ezteach/enviroments';
import { LOCATION } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { AuthConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from './_services/local-storage.service';
import { SignalrService } from './_services/signalr.service';
import { LangStoreService } from './shared/services/lang-store.service';
import { PageVisibilityMonitorService } from './shared/services/page-visibility-monitor.service';

const authCodeFlowConfig: AuthConfig = {
  issuer: environment.authUrl,
  redirectUri: window.location.origin,
  clientId: 'eteach.webapp.cabinet.ui.spa',
  responseType: 'code',
  scope: 'openid profile offline_access eteach.webapp.cabinet.api.public.full eteach.scope',
  clearHashAfterLogin: false,
  showDebugInformation: true,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ready = false;

  constructor(
    private oauthService: OAuthService,
    private authStorage: OAuthStorage,
    private signalRService: SignalrService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCATION) private location: Location,
    private pageVisibilityMonitorService: PageVisibilityMonitorService,
    private langStoreService: LangStoreService,
    private localStorageService: LocalStorageService,
    private title: Title,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    this.translocoService.langChanges$
      .pipe(switchMap(() => this.translocoService.selectTranslation()))
      .subscribe(translation => {
        this.title.setTitle(translation?.['Название вкладки'] ?? '');
      });

    this.configureCodeFlow();

    this.pageVisibilityMonitorService.startMonitor();
  }

  async loadDiscoveryDocumentAndLogin(url: string): Promise<boolean> {
    try {
      return await this.oauthService.loadDiscoveryDocumentAndLogin({ state: url });
    } catch (e: any) {
      return e.type == 'invalid_nonce_in_state' ? await this.loadDiscoveryDocumentAndLogin(url) : false;
    }
  }

  private configureCodeFlow() {
    const token = this.authStorage.getItem('access_token');

    if (this.location.href.indexOf('guest') !== -1 && !token) {
      this.ready = true;
      return;
    }

    const culture = new URLSearchParams(this.location.search).get('ui_locales');
    const isGuestLink = window.location.href.includes('invite');
    if (culture && !isGuestLink) {
      this.localStorageService.set('CurrentLang', culture);
      window.location.href = window.location.href.split('?')[0];
    }
    if (isGuestLink) {
      this.localStorageService.set('CurrentLang', culture);
      window.location.href = window.location.href.split('&')[0];
    }

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.customQueryParams = {
      ui_locales: this.langStoreService.getCurrentLang(),
    };
    const navigateUrlAfterLogin = window.location.href.slice(window.location.origin.length);
    this.loadDiscoveryDocumentAndLogin(navigateUrlAfterLogin).then(hasReceivedTokens => {
      if (!hasReceivedTokens) {
        return false;
      }
      this.onReceivedToken();
      this.signalRService.connect();
      this.ready = true;
      return true;
    });

    //todo: поместить в правильное расположение
    this.signalRService.onScheduledLessonStarted.subscribe((data: any) => {
      const id = data.joinIdentity;
      this.router.navigate(['/wait'], {
        queryParams: { id },
        relativeTo: this.route,
      });
    });

    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe(x => {
      if (x.type === 'invalid_nonce_in_state') {
        //  this.localStorage.remove('access_token');
        //  this.localStorage.remove('refresh_token');
      }
    });

    this.signalRService.onScheduledLessonActivated.pipe().subscribe(data => {
      const id = data.joinIdentity;
      this.router.navigate(['/wait'], {
        queryParams: { id },
        relativeTo: this.route,
      });
    });
  }

  private onReceivedToken() {
    if (!this.oauthService.state) {
      return false;
    }

    const navigateToRelativePath = decodeURIComponent(this.oauthService.state);
    return this.router.navigateByUrl(navigateToRelativePath);
  }
}
