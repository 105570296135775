import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getMembers(lessonId: number) {
    const url = this.rootUrl + '/api/v1/analytics/lesson/' + lessonId + '/members';
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getChat(lessonId: number) {
    const url = this.rootUrl + '/api/v1/analytics/lesson/' + lessonId + '/chat';
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
}
