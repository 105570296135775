import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from "@ezteach/_services/user.service";
import { EducationDetailsDocument } from '@ezteach/api/models';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../_services/local-storage.service';
import { User } from '../api/models/user';
import { UserApiResponse } from '../api/models/user-api-response';
import { BookmarksService } from '../api/services/bookmarks.service';
import { ChatService } from '../api/services/chat.service';
import { UsersService } from '../api/services/users.service';

@Component({
  selector: 'teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss'],
})
export class TeacherComponent implements OnInit {
  userResponse: UserApiResponse;
  userdata;
  user: User;
  userDocuments: EducationDetailsDocument[];
  disciplines;
  loaded = false;
  bookmarks = [];
  bookmarked = false;
  sended = false;
  mod;

  constructor(
    private route: ActivatedRoute,
    private bookmarksService: BookmarksService,
    private usersService: UsersService,
    private chatService: ChatService,
    private localStorageService: LocalStorageService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.userdata = this.userService.userData$.value;

    this.loaded = false;
    this.getResult();
  }

  getResult(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const mod = this.route.snapshot.paramMap.get('mod');

    this.mod = mod;

    this.usersService
      .apiV1UsersUserIdGet({
        // eslint-disable-next-line radix
        userId: parseInt(id),
        mode: 'public',
      })
      .subscribe((userResponse: UserApiResponse) => {
        this.userResponse = userResponse;
        this.user = userResponse.data;
        this.userDocuments = this.user.documents as EducationDetailsDocument[];
        this.loaded = true;

        console.log(this.user);

        this.disciplines = this.user.tutor.disciplineSpecializations
          .map(item => this.translocoService.translate(item.discipline.name))
          .join(', ')
          .toLowerCase();
        this.disciplines = this.disciplines.charAt(0).toUpperCase() + this.disciplines.substring(1);

        if (this.userdata.isStudent) {
          this.bookmarks = this.localStorageService.get('bookmarks') ?? [];
          this.bookmarked = this.bookmarks.includes(this.user?.tutor?.id);
        }

        this._cdr.detectChanges();
      });
  }

  image(item) {
    if (item.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        item.id +
        '/user-avatar?redirect=true&file=' +
        item.avatarFileName
      );
    } else {
      return null;
    }
  }

  initials(item) {
    return item.firstName.charAt(0).toUpperCase() + item.lastName.charAt(0).toUpperCase();
  }

  sendMessage() {
    const message = document.querySelector('.user_page textarea[name=message]') as HTMLInputElement;
    if (message.value !== '') {
      this.chatService
        .apiV1ChatUserDirectMessagesPost({
          userId: this.user.id,
          body: {
            messageText: message.value,
          },
        })
        .subscribe(response => {
          if (response.succeeded) {
            this.sended = true;
          }
        });
    }
  }

  bookmark(event) {
    event.target.closest('.user_page_header').classList.toggle('bookmarked');
    const userdata = this.userService.userData$.value;
    const tutorId = this.user.tutor.id;

    this.bookmarksService
      .apiV1BookmarksTutorTogglePost({
        body: {
          UserId: userdata.id,
          TutorId: tutorId,
        },
      })
      .subscribe(response => {
        if (response.data) {
          this.bookmarks.push(tutorId);
        } else {
          this.bookmarks = this.bookmarks.filter(obj => obj !== tutorId);
        }
        this.localStorageService.set('bookmarks', this.bookmarks);
        this._cdr.detectChanges();
      });
  }
}
