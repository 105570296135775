<div
  class="popup"
  [ngClass]="{ 'default-width': isDefaultWidth && !isMobile }"
>
  <div class="popup__header">
    <div
      class="popup__smiley-icon"
      *ngIf="smiley !== smileyType.none"
    >
      <img
        src="assets/img/svg/icon-smile-good.svg"
        *ngIf="smiley === smileyType.happy"
      />
      <img
        src="assets/img/svg/icon-smile-bad.svg"
        *ngIf="smiley === smileyType.sad"
      />
    </div>
  </div>
  <div class="popup__content">
    <div class="popup__content-title">
      <ng-content select="[title]"></ng-content>
    </div>
    <div class="popup__content-text">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
  <div class="popup__btns">
    <ng-content select="[buttons]"></ng-content>
  </div>
  <button
    class="popup__close-btn"
    *ngIf="hasCloseBtn"
    (click)="onClose()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>
