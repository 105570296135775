export const DAY_NAMES_KK = ['жексенбі', 'дүйсенбі', 'сейсенбі', 'сәрсенбі', 'бейсенбі', 'жұма', 'сенбі'];

export const DAY_NAMES_RU = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

export const DAY_NAMES_EN = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const DAY_NAMES_SHORT_RU = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

export const DAY_NAMES_SHORT_KK = ['ЖС', 'ДС', 'СС', 'СР', 'БС', 'ЖМ', 'СБ'];

export const DAY_NAMES_SHORT_EN = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
