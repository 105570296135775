import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ezteach/shared';
import { PipesModule } from '@ezteach/shared/pipes';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { LessonItemComponent } from './lesson_item.component';

@NgModule({
  imports: [CommonModule, SharedModule, IconSpriteModule, PipesModule, RouterModule, TranslocoRootModule],
  declarations: [LessonItemComponent],
  exports: [LessonItemComponent],
  providers: [DatePipe, CurrencyPipe],
})
export class LessonItemModule {}
