export enum ScheduledLessonStatusEnum {
  Initiated = 'Initiated',
  InitiatedGroupLesson = 'InitiatedGroupLesson',
  Accepted = 'Accepted',
  CanceledByStudent = 'CanceledByStudent',
  CanceledByStudentGroupLesson = 'CanceledByStudentGroupLesson',
  CanceledByTutor = 'CanceledByTutor',
  CanceledByTutorGroupLesson = 'CanceledByTutorGroupLesson',
  LessonStarted = 'LessonStarted',
  GroupLessonStarted = 'GroupLessonStarted',
  LessonFinished = 'LessonFinished',
  GroupLessonFinished = 'GroupLessonFinished',
}
