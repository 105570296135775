import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileLocalStorageService } from '@ezteach/profile/services/profile-localstorage.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { UsersService } from '../../../api/services';

@UntilDestroy()
@Component({
  selector: 'app-profile-card-add',
  templateUrl: './profile-card-add.component.html',
  styleUrls: ['./profile-card-add.component.scss'],
})
export class ProfileCardAddComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private profileLocalStorageService: ProfileLocalStorageService,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    const subscription = this.profileLocalStorageService.localStorageUser$.subscribe((user: any) => {
      if (user && this.route.snapshot.queryParams.orderId) {
        this.usersService
          .apiV1UsersUserIdFinancialsBankcardsFinalizePatch({
            userId: user.id,
            body: {
              OrderId: this.route.snapshot.queryParams.orderId,
            },
          })
          .subscribe(() => (window.location.href = '/profile/cards'));
      }
    });
    this.subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
