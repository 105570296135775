import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChatLesson } from '@ezteach/api/models';
import { ChatLessonFieldsChangedArgs } from '@ezteach/api/models/lesson/chat-lesson-reaction';
import { LessonsService } from '@ezteach/api/services';
import { UserRoles } from '@ezteach/group-lesson/group-lesson.component';
import { GroupLessonSignalrService } from '@ezteach/group-lesson/services/group-lesson-signalr-service/group-lesson-signalr-service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'group-lesson-subject',
  templateUrl: './group-lesson-subject.component.html',
  styleUrls: ['./group-lesson-subject.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupLessonSubjectComponent implements OnInit, OnDestroy {
  @ViewChild('inputSubject') inputSubject: ElementRef<HTMLElement>;
  lesson: ChatLesson;
  edit = false;
  subject = '';
  roles = UserRoles;
  originalSubject = '';
  constructor(
    public groupLessonService: GroupLessonService,
    private cd: ChangeDetectorRef,
    private lessonsService: LessonsService,
    private groupLessonSignalrService: GroupLessonSignalrService,
  ) {}

  ngOnInit() {
    this.subject = this.groupLessonService.chatLessonSubject;
    this.groupLessonService.chatLesson$.pipe(untilDestroyed(this)).subscribe((x: ChatLesson) => {
      this.lesson = x;
      this.subject = this.groupLessonService.chatLessonSubject;
      this.cd.detectChanges();
    });

    this.groupLessonSignalrService.onChatLessonFieldsChanged
      .pipe(
        untilDestroyed(this),
        tap((x: ChatLessonFieldsChangedArgs) => {
          this.groupLessonService.chatLessonSubject = this.subject = x?.currentValue;
          this.cd.detectChanges();
        }),
      )
      .subscribe();
  }

  onEdit() {
    if (this.groupLessonService.role === this.roles.Member) {
      return;
    }
    this.edit = true;
    this.originalSubject = this.subject;
    this.cd.detectChanges();
    setTimeout(x => {
      this.inputSubject.nativeElement.focus();
    }, 10);
  }

  onCancel() {
    this.edit = false;
    this.subject = this.originalSubject;
    this.cd.detectChanges();
  }

  onApprove() {
    this.edit = false;
    this.originalSubject = this.subject;
    this.cd.detectChanges();

    const data = {
      fields: [{ name: 'subject', value: this.subject }],
    };

    this.lessonsService
      .apiV1LessonFieldsPutPath$Response(data, this.lesson.id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => (this.groupLessonService.chatLessonSubject = this.subject));
  }

  handleKey($event: KeyboardEvent) {
    if (this.groupLessonService.role === this.roles.Member) {
      return;
    }
    switch ($event?.code) {
      case 'Enter':
        this.onApprove();
        break;
      case 'Escape':
        this.onCancel();
    }
  }

  ngOnDestroy(): void {}
}
