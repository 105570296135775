import { ShapeManager } from '../shapes/shape-manager';
import { ShapeSceneQueue, ShapeSceneQueueActon, ShapeSceneQueueItem } from '../shapes/shape-scene-queue';

/*
 * Строит сцену whiteboard при первой инициализации + дорисовывает изменения
 */
export class SceneBuilder {
  public static build(queue: ShapeSceneQueue, shapeManager: ShapeManager) {
    while (queue.size() > 0) {
      const item: ShapeSceneQueueItem = queue.dequeue();

      if (item.action === ShapeSceneQueueActon.addShape) {
        shapeManager.addShape(item.data);
        continue;
      }

      if (item.action === ShapeSceneQueueActon.editShape) {
        shapeManager.updateShape(item.data);
        continue;
      }

      if (item.action === ShapeSceneQueueActon.lock) {
        shapeManager.lockShapes([item.data.ezId]);
        continue;
      }

      if (item.action === ShapeSceneQueueActon.unlock) {
        shapeManager.unlockShapes([item.data.ezId]);
        continue;
      }
    }
  }
}
