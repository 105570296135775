<ng-container *ngIf="sortColumn !== columnProperty && !disabled">
  <div class="sort-arrow">
    <div class="sort-icons">
      <img
        src="assets/img/svg/caret-up.svg"
        style="color: #50545d"
        alt="up"
      >
    </div>
    <div class="sort-icons">
      <img
        src="assets/img/svg/caret-down.svg"
        style="color: #50545d"
        alt="down"
      >
    </div>
  </div>
</ng-container>
<ng-container *ngIf="sortColumn === columnProperty && !disabled">
  <div class="sort-arrow">
    <div class="sort-icons">
      <img
        [ngClass]="{active: sortColumn === columnProperty && sortDirection === SortDirection.Desc}"
        src="assets/img/svg/caret-up.svg"
        style="color: #50545d"
        alt="up"
      >
    </div>
    <div class="sort-icons">
      <img
        [ngClass]="{active: sortColumn === columnProperty && sortDirection === SortDirection.Asc}"
        src="assets/img/svg/caret-down.svg"
        style="color: #50545d"
        alt="down"
      >
    </div>
  </div>
</ng-container>