import Konva from 'konva';
import { BackLayerPoint, BackLayerPointRange } from './backlayer.model';
import { PointStorage } from './point-storage';

/** Начальная точка (Начало положения) */
const POINT: BackLayerPoint = { x: -120, y: -120 };

/** Размер клетки (шаг) */
const STEP = 60;
/*
 * Рендер бекграунда бесконечной доски
 */
export class BackLayerRender {
  /*
   * Ссылка на слой для бекграунда клетки
   */
  private readonly layer: Konva.Layer;

  /**
   * Кеширование отрисованных точек
   */
  private readonly pointStorage = new PointStorage();

  constructor(layer: Konva.Layer) {
    this.layer = layer;
  }

  /*
   * Дорисовывает недоставющие клетки фона
   */
  updateGrid(width: number, height: number, x: number, y: number, scaleOffset: number) {
    width /= scaleOffset;
    height /= scaleOffset;
    x /= scaleOffset;
    y /= scaleOffset;
    // получаем точку апроксимации
    const position = this.getLayerPoint(x, y);

    // увеличиваем холст если прокрутка была вниз, путем прибавления текущих размеров экрана + инверсия позиции
    if (position.x < 0) {
      width += -position.x;
    }
    if (position.y < 0) {
      height += -position.y;
    }
    // тк координаты идут слева вниз в цифровом пространстве делаем инверсию
    const newx = -position.x;
    const newy = -position.y;

    // Получаем границы видимости
    const range = this.getVisibleRange(width, height, newx, newy);

    // Удаляем объекты разметки, которые находятся за пределами границ видимости
    const removeItems = this.pointStorage.filter(point => !this.hasPointRange(range, point));
    for (const { point, rect } of removeItems) {
      this.pointStorage.remove(point);
      rect.destroy();
    }

    const newShapes: Konva.Shape[] = [];
    for (let y = newy; y < height; y += STEP) {
      for (let x = newx; x < width; x += STEP) {
        const point = { x, y } as BackLayerPoint;
        if (this.hasPointRange(range, point) && !this.pointStorage.has(point)) {
          const rect = this.createRect(point)
            .listening(false)
            .perfectDrawEnabled(false)
            .shadowForStrokeEnabled(false)
            .hitStrokeWidth(0);
          newShapes.push(rect);
          this.pointStorage.add(point, rect);
        }
      }
    }
    if (newShapes.length > 0) {
      this.layer.add(...newShapes);
    }
  }

  updateGridLines(width: number, height: number, x: number, y: number, scaleOffset: number) {
    width /= scaleOffset;
    height /= scaleOffset;
    x /= scaleOffset;
    y /= scaleOffset;
    const position = this.getLayerPoint(x, y);
    // увеличиваем холст если прокрутка была вниз, путем прибавления текущих размеров экрана + инверсия позиции
    if (position.x < 0) {
      width += -position.x;
    }
    if (position.y < 0) {
      height += -position.y;
    }
    // тк координаты идут слева вниз в цифровом пространстве делаем инверсию
    const newx = -position.x;
    const newy = -position.y;
    for (let y = newy; y < height / 60 + 1; y = y + STEP) {
      const line = this.createLine(newx, y, width, y);
      this.layer.add(line);
    }

    for (let x = newx; x < width / 60 + 1; x = x + STEP) {
      const line = this.createLine(x, newy, x, height);
      this.layer.add(line);
    }
  }
  /*
   * Рисует бекграунд при старте
   */
  renderGrid(width: number, height: number) {
    const position = this.getLayerPoint(POINT.x, POINT.y);
    for (let y = position.y; y < height; y = y + STEP) {
      for (let x = position.x; x < width; x = x + STEP) {
        const point = { x, y } as BackLayerPoint;
        const rect = this.createRect(point)
          .listening(false)
          .perfectDrawEnabled(false)
          .shadowForStrokeEnabled(false)
          .hitStrokeWidth(0);
        this.pointStorage.add(point, rect);
        this.layer.add(rect);
      }
    }
    this.layer.draw();
  }

  private getVisibleRange(width: number, height: number, x: number, y: number): BackLayerPointRange {
    return { x: { min: x, max: x + width }, y: { min: y, max: y + height } };
  }

  private hasPointRange(range: BackLayerPointRange, point: BackLayerPoint): boolean {
    return point.x >= range.x.min && point.x <= range.x.max && point.y >= range.y.min && point.y <= range.y.max;
  }

  private createRect(point: BackLayerPoint) {
    var rect = new Konva.Rect({
      x: point.x,
      y: point.y,
      width: STEP,
      height: STEP,
      stroke: '#e2e2ea',
      strokeWidth: 1,
      lineCap: 'round',
      lineJoin: 'round',
    });
    return rect;
  }

  private createLine(x: number, y: number, x1: number, y1: number) {
    var rect = new Konva.Line({
      points: [x, y, x1, y1],
      stroke: '#e2e2ea',
      strokeWidth: 1,
      lineCap: 'round',
      lineJoin: 'round',
    });
    return rect;
  }

  /*
   * Сглаживает точку для рисования фона с равным шагом независимо от длины прокрутки канваса
   */
  private getLayerPoint(x: number, y: number): BackLayerPoint {
    let nextx = x;
    let nexty = y;

    while (Math.round(nextx) % STEP !== 0) {
      nextx++;
    }
    while (Math.round(nexty) % STEP !== 0) {
      nexty++;
    }

    return { x: Math.round(nextx), y: Math.round(nexty) };
  }
}
