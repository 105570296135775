import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService, IFeedbackRequestType } from '@ezteach/api';
import { TranslocoService } from '@ngneat/transloco';
import { always, any, endsWith, flip, ifElse, lte, not, propEq, toLower } from 'ramda';
import { tap } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { UserService } from "@ezteach/_services/user.service";

const MAX_LENGTH = 4000;

@Component({
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent implements OnInit {
  userData;
  feedbackGroup: UntypedFormGroup;
  requestTypeOptions: IFeedbackRequestType[] = [];
  extentions = ['.jpg', '.png', '.txt'];
  maxFileSize = 5 * 1000 * 1000; // ~5MB
  private file: File;
  private _inProgress = false;
  isMobile: boolean;

  get fileText() {
    return this.file ? this.file.name : this.translocoService.translate('Прикрепить документ');
  }

  get inProgress() {
    return this._inProgress;
  }

  set inProgress(value: boolean) {
    this._inProgress = value;
    if (value) {
      this.disableFormgroupControls();
    } else {
      this.enableFormgroupControls();
    }
  }

  constructor(
    private readonly dialogRef: MatDialogRef<FeedbackComponent>,
    private readonly cdr: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly feedbackService: FeedbackService,
    private readonly snack: MatSnackBar,
    private translocoService: TranslocoService,
    private platform: Platform,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.feedbackGroup = this.fb.group({
      requestType: [null, Validators.required],
      message: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(MAX_LENGTH)]],
    });
    this.userData = this.userService.userData$.value;

    this.inProgress = true;
    this.feedbackService
      .feedbackRequestTypes()
      .pipe(
        tap(({ data }) => (this.requestTypeOptions = data)),
        tap(() => (this.inProgress = false)),
        tap(() => this.cdr.detectChanges()),
      )
      .subscribe();

    this.isMobile = this.platform.IOS || this.platform.ANDROID;
  }

  onFileSelect(file: File | FileList) {
    if (not(file)) {
      return;
    }
    if ((file as FileList)?.length) {
      return;
    }
    const f = file as File;
    if (!this.validateFile(f)) {
      return;
    }
    this.file = f;
  }

  private disableFormgroupControls() {
    this.feedbackGroup.get('requestType').disable();
    this.feedbackGroup.get('message').disable();
  }

  private enableFormgroupControls() {
    this.feedbackGroup.get('requestType').enable();
    this.feedbackGroup.get('message').enable();
  }

  private validateFile(file: File): boolean {
    const isValidSize = this.validateFileSize(file);
    if (not(isValidSize)) {
      this.snack.open(this.translocoService.translate( 'Размер файла слишком большой!'), null, {
        duration: 3000,
      });
      return false;
    }

    const isValidType = this.validateFileType(file);
    if (not(isValidType)) {
      this.snack.open(this.translocoService.translate('Тип файла не поддерживается!'), null, { duration: 3000,});
      return false;
    }

    return true;
  }

  private validateFileSize = (file: File): boolean => lte(file.size, this.maxFileSize);

  private validateFileType = (file: File): boolean => {
    const name = toLower(file.name);
    const valid = ifElse(propEq('length', 0), always(true), any(flip(endsWith)(name)))(this.extentions);

    return valid;
  };

  markAsTouched() {
    this.feedbackGroup.get('requestType').markAsTouched();
    this.feedbackGroup.get('message').markAsTouched();
  }

  async sendFeedback() {
    if (this.feedbackGroup.invalid) {
      return;
    }
    try {
      this.inProgress = true;
      const value = this.feedbackGroup.getRawValue();
      let attachments = [];
      if (this.file) {
        const { data } = await this.feedbackService.uploadFeedbackAttachment(this.file, this.userData.id).toPromise();
        attachments = [...data];
      }
      await this.feedbackService
        .sendFeedback({
          ...value,
          attachments,
        })
        .toPromise();
      this.snack.open(this.translocoService.translate('Благодарим за отзыв!'), null, { duration: 3000 });
      this.inProgress = false;
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
      this.inProgress = false;
      this.snack.open(this.translocoService.translate('Извините, произошла ошибка'), null, { duration: 3000 });
    }
  }
}
