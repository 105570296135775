import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { ShapeColorDirective } from './shape-color.directive';
import { ShapeToolsComponent } from './shape-tools.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatSliderModule, ColorPickerModule],
  declarations: [ShapeToolsComponent, ShapeColorDirective],
  exports: [ShapeToolsComponent],
})
export class ShapeToolsModule {}
