/*
 * Описывает конфигурацию фигуры
 */
export interface ShapeAttrsConfig {
  stroke?: string | null;
  strokeWidth?: number | null;
  fill?: string | null;
  opacity?: number | null;
}

/*
 * Управляет настройками фигуры
 */
export class ShapeAttrsConfigStorage {
  private config: ShapeAttrsConfig = {};

  public constructor(config: ShapeAttrsConfig) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }

  setConfig(config: ShapeAttrsConfig) {
    this.config = config;
  }

  setConfigProp(propName: string, value: any) {
    this.config[propName] = value;
  }
}

export interface ShapeTextAttrsConfig {
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: string;
  fontWeight?: string;
  fontColor?: string;
  backgroundColor?: string;
  textDecoration?: string;
  textAlign?: string;
}
