export enum WidgetTypeEnum {
  TutorSearchInstant = 'TutorSearch_Instant',
  TutorSearchScheduled = 'TutorSearch_Scheduled',
  Calendar = 'Calendar',
  LessonsHistory = 'LessonsHistory',
  Bookmarks = 'Bookmarks',
  Financials = 'Financials',
  StudentProfile = 'StudentProfile',
  TutorProfile = 'TutorProfile',
  TutorProfileWizard = 'TutorProfileWizard',
  UserNotifications = 'UserNotifications',
  OnlineStatus = 'OnlineStatus',
  Messenger = 'Messenger',
}
