import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProlongService {
  leftSeconds = new Subject<number>();

  constructor() {}
}
