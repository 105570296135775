<div class="modal__header"></div>
<div class="modal__content modal__content--icon">
  <div class="modal__title">{{ 'Страница была перезагружена' | transloco }}</div>
  <div class="modal__text">
    <p>{{ 'Нажмите кнопку "Продолжить" для продолжения видеотрансляции' | transloco }}</p>
  </div>
  <div class="modal__actions">
    <a
      class="btn btn--blue btn--middle"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Продолжить' | transloco }} </span>
    </a>
  </div>
</div>