import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { WbDragFileDirective } from '../../directives/wb-drag-file.directive';
import { FileShapeToolsModule } from '../file-shape-tools/file-shape-tools.module';
import { IconModule } from '../icon/icon.module';
import { disableFullScreen, enableFullScreen, redo, share, undo } from '../icon/icons';
import { UiIconsRegistry } from '../icon/icons-registry.service';
import { ShapeToolsModule } from '../shape-tools/shape-tools.module';
import { TextShapeToolsModule } from '../text-shape-tools/text-shape-tools.module';
import { ToolsModule } from '../tools/tools.module';
import { WhiteboardComponent } from './whiteboard.component';
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  imports: [
    CommonModule,
    ToolsModule,
    IconModule,
    ShapeToolsModule,
    TextShapeToolsModule,
    PipesModule,
    FileShapeToolsModule,
    TranslocoRootModule,
    FormsModule,
    MatInputModule
  ],
  declarations: [WhiteboardComponent, WbDragFileDirective],
  exports: [WhiteboardComponent],
})
export class WhiteboardModule {
  constructor(readonly iconsRegistry: UiIconsRegistry) {
    iconsRegistry.registerIcons([share, undo, redo, enableFullScreen, disableFullScreen]);
  }
}
