<div class="container">
  <img
    class="play"
    src="assets/img/svg/blocks/play.svg"
    (click)="openLesson()"
  />
  <div class="info-block">
    <div class="description">{{ 'Событие' | transloco}} {{index}}</div>
    <div class="detail">
      <div class="subject">{{lesson.subject | transloco}}</div>
      <div class="description">
        <div class="date">{{lesson.lessonStartDate | date}}</div>
        <div class="duration">{{lessonDuration}}</div>
        <video
          id="lessonVideo{{lesson?.id}}"
          style="display: none;"
          src="{{lesson?.historyLink}}"
          (loadedmetadata)="videoLoaded()"
        ></video>
      </div>
    </div>
    <img
      *ngIf="!isMobile && isEdit"
      src="assets/img/svg/blocks/delete.svg"
      class="icon icon-act"
      (click)="delete()"
    >
  </div>