import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { ActiveService } from '@ezteach/_services/active.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  title: string;
  activeTemporarySolution: boolean;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private activeService: ActiveService) {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        map(event => (event as ActivationEnd).snapshot),
        map(snapshot => (snapshot as ActivatedRouteSnapshot).data),
      )
      .subscribe(data => {
        if (this.router.url.replace('/', '') === '') {
          this.title = '';
        }

        if (data && data.title) {
          this.title = data.title;
        }
      });

    const component = this.activatedRoute.snapshot.routeConfig.children.filter(
      conf => conf.path === this.router.url.replace('/', ''),
    );
    if (component.length > 0 && component[0].data) {
      this.title = component[0].data.title;
    }
  }

  ngOnInit() {
    this.activeService.activeTemporarySolution$.subscribe(v => (this.activeTemporarySolution = v));
  }
}
