import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'modal-disable-media-by-moderator',
  templateUrl: 'modal-disable-media-by-moderator.html',
  styleUrls: ['modal-disable-media-by-moderator.scss'],
})
export class ModalDisableMediaByModerator {
  deviceType: string;
  buttonMessage = 'Понятно';
  buttonType = ButtonType;
  buttonWidth = '194px';
  notificationMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalDisableMediaByModerator>,
    private translocoService: TranslocoService,
  ) {
    this.setNotificationMessage();
  }

  private setDeviceType(deviceType: ChatLessonMemberPublishingStateEnum) {
    this.deviceType = deviceType === ChatLessonMemberPublishingStateEnum.Video ? 'камеру' : 'микрофон';
  }

  private setNotificationMessage() {
    this.setDeviceType(this.data.type);
    this.notificationMessage = this.translocoService.translate(`Модератор отключил Вам ${this.deviceType}`);
  }

  close() {
    this.dialogRef.close();
  }
}
