import { Directive, Input, AfterViewInit, Renderer2, ViewContainerRef } from '@angular/core';

/* Directive for setting required sign to needed labels */
@Directive({
  selector: 'mat-label[appMatLabelRequired]',
})
export class MatLabelRequiredDirective implements AfterViewInit {
  private _isRequired = true;

  @Input()
  set isRequired(value: boolean) {
    console.log(typeof value);
    this._isRequired = typeof value !== 'boolean' ? true : value;

    this.updateClasslist();
  }

  constructor(private readonly _vcr: ViewContainerRef, private readonly _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.updateClasslist();
  }

  private updateClasslist() {
    if (!this._vcr.element?.nativeElement) {
      return;
    }

    if (
      this._isRequired &&
      new Array(this._vcr.element.nativeElement.classList).indexOf('mat-label--required') === -1
    ) {
      this._renderer.addClass(this._vcr.element.nativeElement, 'mat-label--required');
    } else {
      this._renderer.removeClass(this._vcr.element.nativeElement, 'mat-label--required');
    }
  }
}
