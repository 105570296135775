import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { SortArrowsComponent } from '@ezteach/shared/components/sort-arrows/sort-arrows.component';
import { ButtonComponent } from './button/button.component';
import { AppDatepickerComponent } from './datepicker-component/datepicker-component.component';
import { EllipsisLoaderComponent } from './ellipsis-loader/ellipsis-loader.component';
import { AppPriceInputComponent } from './price-input/price-input.component';
import { PullToRefreshComponent } from './pull-to-refresh/pull-to-refresh.component';
import { AppSelectComponent } from './select-component/select-component.component';
import { StepperModule } from './stepper';
import { TableComponent } from './table/table.component';

const COMPONENTS = [AppPriceInputComponent, ButtonComponent, EllipsisLoaderComponent, AppSelectComponent, TableComponent, SortArrowsComponent, PullToRefreshComponent, AppDatepickerComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, StepperModule, MatInputModule, MatDatepickerModule, FormsModule],
  exports: [...COMPONENTS, StepperModule],
})
export class SharedComponentsModule { }
