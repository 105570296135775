import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BlockApiService,
  BlockCreator,
  BlockItem,
  BlockСostCurrencyEnum,
  BlockСostTypeEnum,
} from '@ezteach/blocks/services/block-api.service';
import { SelectedBlockService } from '@ezteach/blocks/services/selected-block.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ezteach-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockListComponent implements OnInit {
  @Output()
  onAddLesson = new EventEmitter();

  physics: BlockItem = {
    id: 26812,
    preview: 'https://collegerealitycheck.com/wp-content/uploads/physics-major-1988419205-1024x768.jpg',
    name: 'Блок событий №2 Тема: Физика 11 класс',
    // eslint-disable-next-line @typescript-eslint/quotes
    description: `Учебный онлайн курс по предмету`,
    creator: { name: 'Kurchatov Igor' } as BlockCreator,
    exerciseQty: 0,
    membersQty: 0,
    hasCertificate: false,
    costType: BlockСostTypeEnum.Free,
    costAmount: 0,
    costCurrency: BlockСostCurrencyEnum.RUB,
    members: [],
    tasks: [],
    exercises: [],
  };

  MOCK_ITEMS = [this.physics];

  items: BlockItem[] = [];
  constructor(
    public selectedBlockService: SelectedBlockService,
    private route: ActivatedRoute,
    private blockApiService: BlockApiService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    const blockId = this.route.snapshot.queryParams.blockId;
    if (blockId) {
      this.selectedBlockService.selectedBlock$.next(blockId.toString());
    }

    this.blockApiService
      .getBlockList({})
      .pipe(
        untilDestroyed(this),
        tap(response => {
          if (response.ok) {
            this.items = [...this.MOCK_ITEMS, ...response.body.data.records.reverse()];
            this.cdr.detectChanges();
          } else {
            this.items = this.MOCK_ITEMS;
            this.cdr.detectChanges();
          }
        }),
      )
      .subscribe();

    this.blockApiService.updateBlocksList$
      .pipe(
        untilDestroyed(this),
        switchMap(x => {
          if (x) {
            return this.blockApiService.getBlockList({});
          }
          return EMPTY;
        }),
        tap(blockListResponse => {
          const blockList = blockListResponse?.body?.data?.records?.reverse() ?? [];
          this.items = [...blockList, ...this.MOCK_ITEMS];
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  addLesson(el) {
    this.onAddLesson.emit({ elementRef: el, blockId: this.selectedBlockService.selectedBlock$.value });
  }
}
