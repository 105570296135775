import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UserSerchService } from "@ezteach/api/services/user-search.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-select-parcipants',
  templateUrl: './select-parcipants.component.html',
  styleUrls: ['./select-parcipants.component.scss'],
})
export class SelectParcipantsComponent implements OnInit, AfterViewInit {
  private _items: any[];
  get items() {
    return this._items;
  }
  @Input() displayWith: ((value: any) => string) | null = null;
  @Input() onClosed: (() => void) | null = null;
  @Input() label: string;
  @Input() suffix: boolean = true;
  @Input() defaultValue: any[] | null = null;

  @Output()
  searchtextChanged: EventEmitter<string | undefined> = new EventEmitter();
  inputControl = new FormControl('');
  values = new FormControl([]);
  filtered: Observable<string[]>;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('trigger') trigger: MatAutocompleteTrigger;
  @ViewChild('auto') auto: MatAutocomplete;

  constructor(
    private userSearchService: UserSerchService
  ) { }

  filteredItems$: Observable<any>;
  ngAfterViewInit(): void {
    this.filteredItems$ = this.inputControl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap(x => this.serverRequest(x)),
      )

    this.auto.closed
      .pipe(
        untilDestroyed(this),
        tap(_ => {
          this.inputControl.patchValue(this.getDisplay(), { emitEvent: false });
          !!this.onClosed && this.onClosed();
        }),
      )
      .subscribe();

    this.trigger.position = "below";

  }
  ngOnInit(): void {
    if (!this.defaultValue?.length) {
      return;
    }

    this.values.patchValue(this.defaultValue);
    this._items = [...this.values.value];
    this.inputControl.patchValue(this.getDisplay(), { emitEvent: false });
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  changeSelectItem($event: any, item: any) {
    $event.preventDefault();
    $event.stopPropagation();

    if (item.id === 0) {
      return;
    }

    if (this.values.value.indexOf(item) === -1) {
      const newValue = [...this.values.value, item];
      this.values.patchValue(newValue);
    } else {
      const newValue = this.values.value.filter(x => x !== item);
      this.values.patchValue(newValue);
    }

    this._items = [...this.values.value];
  }

  isSelected(item: any) {
    return this.values.value.indexOf(item) !== -1;
  }

  isSelectedById(item: any) {
    return this.values.value.findIndex(x => x.id === item.id) !== -1;
  }

  openPanel($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.inputControl.patchValue(undefined);
    this.trigger.openPanel();
  }

  closePanel($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.trigger.closePanel();
    this.inputControl.patchValue(this.getDisplay(), { emitEvent: false });
  }

  inputFocus() {
    this.inputControl.patchValue(undefined);
  }

  getDisplay() {
    if (this.values.value.length > 2) {
      const sliced = this.values.value.slice(0, 2);
      return sliced.map(v => this.displayWith(v)).join(', ') + ' + ' + (this.values.value.length - 2).toString();
    }

    return this.values.value.map(v => this.displayWith(v)).join(', ');
  }

  onFocusInput() {
    if (!this.input?.nativeElement) {
      return;
    }

    this.input.nativeElement.value = '';
  }

  private serverRequest(searchQuery: string | null): Observable<any[]> {
    if (!searchQuery) {
      return of([])
    }
    return this.userSearchService.getUsersList(searchQuery).pipe(
      map(x =>
        x.data.length === 0
          ? [
            {
              firstName: 'Не найдено',
              lastName: '',
              value: undefined,
              id: 0,
            },
          ]
          : x.data,
      ),
    );
  }
}
