import { Injectable } from '@angular/core';
import { FileDocumentIEnumerableApiResponse } from '@ezteach/api/models';
import { FilesService } from '@ezteach/api/services';
import { logErrorSilently } from '@ezteach/utils';
import { BrowserService } from '@ezteach/_services/browser.service';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IProfileData } from '../models';
import { ProfileApiService } from '../services/profile-api.service';
import * as ProfileActions from './profile.actions';
import * as ProfileState from './profile.reducer';
import { selectProfile } from './profile.selectors';

@Injectable()
export class ProfileEffects implements OnRunEffects {
  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadProfile),
      switchMap(() =>
        this.api.getProfile().pipe(map((profile: IProfileData) => ProfileActions.profileLoadedSuccess(profile.data))),
      ),
      logErrorSilently(),
    ),
  );

  saveProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfile),
      tap(() => console.log('effect save')),
      switchMap(action =>
        this.api.saveProfile(action.profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveProfilePersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfilePersonalDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveProfilePassportDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfilePassportDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  uploadProfilePassportFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadProfilePassportFile),
      switchMap(request =>
        this.filesService.apiV1FilesUsercontentContentTypePost(request).pipe(
          map((response: FileDocumentIEnumerableApiResponse) =>
            ProfileActions.uploadProfilePassportFileSuccess(response),
          ),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  removeProfilePassportFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.removeProfilePassportFile),
      map(files =>
            ProfileActions.removeProfilePassportFileSuccess(files)
      ),
    ),
  );

  removeWorkExperienceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.removeWorkExperienceFile),
      map(files =>
            ProfileActions.removeWorkExperienceFileSuccess(files)
      ),
    ),
  );

  removeEducationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.removeEducationFile),
      map(files =>
            ProfileActions.removeEducationFileSuccess(files)
      ),
    ),
  );

  removeReferenceLettersFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.removeReferenceLettersFile),
      map(files =>
            ProfileActions.removeReferenceLettersSuccess(files)
      ),
    ),
  );

  saveProfileReferenceLetters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfileReferenceLetters),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  uploadProfileReferenceLettersFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadReferenceLettersFile),
      switchMap(request =>
        this.filesService.apiV1FilesUsercontentContentTypePost(request).pipe(
          map((response: FileDocumentIEnumerableApiResponse) =>
            ProfileActions.uploadReferenceLettersFileSuccess(response),
          ),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveProfileFinancialDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfileFinancialDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  uploadProfileEducationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadEducationFile),
      switchMap(request =>
        this.filesService.apiV1FilesUsercontentContentTypePost(request.file).pipe(
          map((response: FileDocumentIEnumerableApiResponse) => ProfileActions.uploadEducationFileSuccess(response)),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveProfileEducation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfileEducation),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveWorkExperience$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveWorkExperience),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  uploadWorkExperienceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadWorkExperienceFile),
      switchMap(request =>
        this.filesService.apiV1FilesUsercontentContentTypePost(request).pipe(
          map((response: FileDocumentIEnumerableApiResponse) =>
            ProfileActions.uploadWorkExperienceFileSuccess(response),
          ),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveBankCardBindings = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveBankCardBindings),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveProfilePatron = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveProfilePatron),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  saveSpecialityDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveSpecialityDetails),
      withLatestFrom(this.store.select(selectProfile)),
      switchMap(([action, profile]) =>
        this.api.saveProfile(profile).pipe(
          map(() => ProfileActions.saveProfileSuccess()),
          catchError(error => of(ProfileActions.saveProfileError())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly browserService: BrowserService,
    private readonly api: ProfileApiService,
    private filesService: FilesService,
    private readonly store: Store<{ [ProfileState.profileFeatureKey]: ProfileState.ProfileState }>,
  ) {}
  /* Block effects in case unsupported browser */
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.browserService.isSupportedBrowser ? resolvedEffects$ : NEVER;
  }
}
