<div class="select-his-lesson">
  <div>
    <div class="select-his-lesson-inp-wrap">
      <mat-form-field
        class="select-his-lesson-inp-wrap"
        appearance="outline"
      >
        <input
          class="ez-input"
          matInput
          type="text"
          [placeholder]="'Поиск' | transloco"
          [ngModel]="search"
          (ngModelChange)="changed($event)"
          maxlength="50"
        >
      </mat-form-field>
    </div>

  </div>
  <div
    *ngIf="!loading"
    class="history-lessons"
  >
    <div *ngFor="let les of filtered">
      <div style="display: flex;
      justify-content: space-between; align-items: center;">
        <div>{{les.name}} {{les.lessonStartDate | date}}</div>
        <div
          *ngIf="les.selected"
          class="history-item"
          (click)="selectState(les, false)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="24"
              height="24"
              rx="12"
              fill="#678AFF"
            />
            <path
              d="M11.1372 17.1538C10.7529 17.5541 10.1162 17.5649 9.71845 17.178L6.70199 14.243C6.31196 13.8635 6.29698 13.2418 6.66829 12.844L6.97375 12.5167C7.3598 12.1031 8.01183 12.0918 8.41191 12.4919L10.44 14.52L16.5977 7.93024C16.9746 7.52688 17.607 7.50525 18.0106 7.88193L18.3386 8.18803C18.7464 8.56862 18.7639 9.20924 18.3777 9.61162L11.1372 17.1538Z"
              fill="white"
            />
          </svg>

        </div>
        <div
          *ngIf="!les.selected"
          class="history-item"
          (click)="selectState(les, true)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="11.5"
              stroke="#678AFF"
            />
            <path
              d="M11.1372 17.1538C10.7529 17.5541 10.1162 17.5649 9.71845 17.178L6.70199 14.243C6.31196 13.8635 6.29698 13.2418 6.66829 12.844L6.97375 12.5167C7.3598 12.1031 8.01183 12.0918 8.41191 12.4919L10.44 14.52L16.5977 7.93024C16.9746 7.52688 17.607 7.50525 18.0106 7.88193L18.3386 8.18803C18.7464 8.56862 18.7639 9.20924 18.3777 9.61162L11.1372 17.1538Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="loading"
    class="loading"
  >
    <tui-loader [inheritColor]="true">
    </tui-loader>
  </div>
</div>