import * as T from 'io-ts';

export { Validation } from 'io-ts';

// this utility function can be used to turn a TypeScript enum into a io-ts codec.
export function fromEnum<EnumType>(enumName: string, theEnum: Record<string, string | number>) {
  const isEnumValue = (input: unknown): input is EnumType => Object.values<unknown>(theEnum).includes(input);

  return new T.Type<EnumType>(
    enumName,
    isEnumValue,
    (input, context) => (isEnumValue(input) ? T.success(input) : T.failure(input, context)),
    T.identity,
  );
}
