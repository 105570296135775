import { Component, OnInit } from '@angular/core';
import { Langs, LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { TranslocoService } from '@ngneat/transloco';
@Component({
  selector: 'lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent implements OnInit {
  lang = Langs.RU;
  langs = [
    { id: Langs.RU, name: 'RU' },
    { id: Langs.KK, name: 'KZ' },
    { id: Langs.EN, name: 'EN' },
  ];
  constructor(private translocoService: TranslocoService, private langStoreService: LangStoreService) {}

  ngOnInit() {
    this.lang = this.langStoreService.getCurrentLang();
  }

  onLangChange($event) {
    this.translocoService.setActiveLang($event.value);
    this.langStoreService.setCurrentLang($event.value);
  }
}
