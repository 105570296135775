import { isFirefox } from '@taiga-ui/cdk';
import Konva from 'konva';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WhiteBoardShape } from './shape';
import { ShapeTextAttrsConfig } from './shape-attrs-config';

export interface TextAriaChangedEvent {
  text: string;
  height?: number;
  shapeId: string;
  attrs?: ShapeTextAttrsConfig;
}

/*
 * Создает/уничтожает область для редактирования текста фигуры
 */
export class ShapeTextEditor {
  private _textArea: HTMLTextAreaElement;
  private _wrap: HTMLElement;
  private _isEdit: boolean;
  private _shape: WhiteBoardShape;

  private _initialHeight: number;
  private readonly _maxIncreaseHeight = 300;

  readonly changed$: Subject<TextAriaChangedEvent> = new Subject();
  readonly opened$: Subject<TextAriaChangedEvent> = new Subject();
  readonly closed$: Subject<TextAriaChangedEvent> = new Subject();

  open(shape: WhiteBoardShape, stage: Konva.Stage) {
    if (this._isEdit) {
      return;
    }

    this._isEdit = true;
    this._shape = shape;
    this._initialHeight = this._shape.konvajsShape.hasChildren()
      ? this.getChildShapeByType(this._shape.konvajsShape, Konva.Rect.name).height()
      : this._shape.konvajsShape.getHeight();
    this.initTextAria(shape.konvajsShape, stage);
    this.opened$.next({
      text: this._textArea.value,
      height: null,
      shapeId: this._shape.konvajsShape.getAttr('ezId'),
    });
  }

  close() {
    if (!this._isEdit) {
      return;
    }

    if (
      this._textArea.scrollHeight > this._initialHeight &&
      this._initialHeight < this._maxIncreaseHeight &&
      !this._shape.konvajsShape.hasChildren()
    ) {
      this.updateHeight();
    }

    this._isEdit = false;

    let newHeight = null;
    if (this._wrap?.clientHeight) {
      newHeight = this._wrap.clientHeight;
    }

    this.closed$.next({
      text: this._textArea.value,
      height: newHeight,
      shapeId: this._shape.konvajsShape.getAttr('ezId'),
      attrs: this.getShapeTextAttrsConfig(),
    });

    const whiteBoardDiv = document.querySelector('.ezteach-tool');
    if (this._wrap) {
      whiteBoardDiv.removeChild(this._wrap);
      this._wrap = undefined;
      this._textArea = undefined;
    } else {
      whiteBoardDiv.removeChild(this._textArea);
      this._textArea = undefined;
    }
  }

  updateHeight() {
    let newHeight =
      this._textArea.scrollHeight < this._maxIncreaseHeight ? this._textArea.scrollHeight : this._maxIncreaseHeight;
    this.updateChange(newHeight);
  }

  get isEdit(): boolean {
    return this._isEdit;
  }

  get currentShape(): WhiteBoardShape {
    return this._shape;
  }

  private initTextAria(shape: any, stage: Konva.Stage) {
    if (shape.hasChildren()) {
      this.initStikerTextArea(shape);
    } else {
      this.initSimpleTextAria(shape, stage);
    }
  }

  updateTextAreaPosition(stage: Konva.Stage) {
    const textPosition = this._shape.konvajsShape.absolutePosition();
    const areaPosition = {
      x: stage.container().offsetLeft + textPosition.x,
      y: stage.container().offsetTop + textPosition.y,
    };
    this._wrap.style.top = areaPosition.y + 'px';
    this._wrap.style.left = areaPosition.x + 'px';
  }

  updateTextAriaWidth() {
    if (this._shape.konvajsShape.hasChildren()) {
      const newWidth = Math.round(this.getChildShapeByType(this._shape.konvajsShape, Konva.Rect.name).width());
      // берем 90% общей ширины для отступов
      this._textArea.style.width = newWidth * 0.9 + 'px';
      this._wrap.style.width = newWidth + 'px';
    } else {
      this._textArea.style.width =
        this._shape.konvajsShape.width() * this._shape.konvajsShape.scaleX() -
        this._shape.konvajsShape.padding() * 2 +
        'px';
    }
  }

  updateTextAriaHeight() {
    if (this._shape.konvajsShape.hasChildren()) {
      const rectShape = this.getChildShapeByType(this._shape.konvajsShape, Konva.Rect.name);
      const newWrapHeight = rectShape.height() * rectShape.scaleY();
      this._wrap.style.height = newWrapHeight + 'px';
      const newTextAreaHeight =
        this._textArea.scrollHeight > newWrapHeight ? newWrapHeight : this._textArea.scrollHeight;
      this._textArea.style.height = newTextAreaHeight + 'px';
    } else {
      this._textArea.style.height =
        this._shape.konvajsShape.height() * this._shape.konvajsShape.scaleY() -
        this._shape.konvajsShape.padding() * 2 +
        'px';
    }
  }

  updateTextAriaFormatAttrs(attrs: ShapeTextAttrsConfig) {
    if (!attrs) {
      return;
    }

    this._textArea.style.fontFamily = attrs.fontFamily;
    this._textArea.style.fontSize = attrs.fontSize + 'px';
    this._textArea.style.fontStyle = attrs.fontStyle;
    this._textArea.style.fontWeight = attrs.fontWeight;
    this._textArea.style.color = attrs.fontColor;
    this._textArea.style.backgroundColor = attrs.backgroundColor;
    this._textArea.style.textDecoration = attrs.textDecoration;
    this._textArea.style.textAlign = attrs.textAlign;
    this.updateChange();
  }

  private getShapeTextAttrsConfig(): ShapeTextAttrsConfig {
    return {
      fontFamily: this._textArea.style.fontFamily,
      fontSize: Number(this._textArea.style.fontSize.replace('px', '')),
      fontStyle: this._textArea.style.fontStyle,
      fontWeight: this._textArea.style.fontWeight,
      textDecoration: this._textArea.style.textDecoration,
      textAlign: this._textArea.style.textAlign,
    } as ShapeTextAttrsConfig;
  }

  updateTextAreaRotation() {
    let transform = '';
    let px = 0;
    const rotation = this._shape.konvajsShape.rotation();
    if (rotation) {
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }
      if (isFirefox(navigator.userAgent)) {
        px += 2 + Math.round(this._shape.konvajsShape.fontSize() / 20);
      }
    }

    transform += 'translateY(-' + px + 'px)';
    this._textArea.style.transformOrigin = 'left top';
    this._textArea.style.transform = transform;
  }

  initSimpleTextAria(shape: any, stage: Konva.Stage) {
    this._textArea = document.createElement('textarea');
    this._textArea.value = shape.getText();
    const whiteBoardDiv = document.querySelector('.ezteach-tool');
    whiteBoardDiv.appendChild(this._textArea);

    const textPosition = shape.absolutePosition();
    const areaPosition = {
      x: stage.container().offsetLeft + textPosition.x,
      y: stage.container().offsetTop + textPosition.y,
    };
    this._textArea.style.position = 'absolute';
    this._textArea.style.top = areaPosition.y - 0.5 + 'px';
    this._textArea.style.left = areaPosition.x + 'px';
    this._textArea.style.width = shape.width() * shape.scaleX() - shape.padding() * 2 + 'px';
    this._textArea.style.height = shape.height() * shape.scaleY() - shape.padding() * 2 + 'px';
    this._textArea.style.zIndex = '5';
    this._textArea.style.lineHeight = shape.lineHeight();
    this._textArea.style.fontSize = shape.fontSize() + 'px';
    this._textArea.style.overflow = '';
    this._textArea.style.resize = 'none';
    this._textArea.focus();

    this.updateTextAreaRotation();

    fromEvent(this._textArea, 'keydown')
      .pipe(
        takeUntil(this.closed$),
        tap(x => {
          this._textArea.style.height = 'auto';
          this._textArea.style.height = this._textArea.scrollHeight + shape.fontSize() + 'px';
          this.updateChange(this._textArea.scrollHeight + shape.fontSize());
        }),
      )
      .subscribe();
  }

  getChildShapeByType(parentShape, type: string) {
    return parentShape.children.find(x => x.constructor.name === type);
  }

  private initStikerTextArea(shape: any) {
    const scaleX = this._shape?.getShapeManager()?.whiteBoard?.stage?.scaleX();
    const scaleY = this._shape?.getShapeManager()?.whiteBoard?.stage?.scaleX();

    const rectShape = this.getChildShapeByType(shape, Konva.Rect.name);
    const textShape = this.getChildShapeByType(shape, Konva.Text.name);

    this._textArea = document.createElement('textarea');

    const fill = rectShape?.fill();
    this._textArea.value = textShape.getText();
    const whiteBoardDiv = document.querySelector('.ezteach-tool');

    this._textArea.style.position = 'absolute';

    this._textArea.style.resize = 'none';
    this._textArea.style.zIndex = '5';
    this._textArea.style.lineHeight = '1';

    this._textArea.style.fontSize = (shape.fontSize ?? 12) * scaleX + 'px';

    this._wrap = document.createElement('div');
    this._wrap.appendChild(this._textArea);
    whiteBoardDiv.appendChild(this._wrap);

    const textAreaHeight =
      rectShape.height() > this._textArea.scrollHeight ? '20%' : rectShape.height() + 'px';

    this._textArea.style.width = '90%';
    this._textArea.style.height = textAreaHeight;
    this._textArea.style.position = '';
    this._textArea.style.top = 'none';
    this._textArea.style.left = 'none';
    this._textArea.style.textAlign = 'center';
    this._textArea.style.marginTop = '10px';
    this._textArea.style.color = 'black';

    this._wrap.style.backgroundColor = fill;
    this._wrap.style.display = 'flex';
    this._wrap.style.alignItems = 'center';
    this._wrap.style.justifyContent = 'center';
    this._wrap.style.position = 'absolute';
    this._wrap.style.top =
      this._shape?.getShapeManager()?.whiteBoard?.stage?.container()?.offsetTop + shape.absolutePosition().y + 'px';
    this._wrap.style.left =
      this._shape?.getShapeManager()?.whiteBoard?.stage?.container()?.offsetLeft + shape.absolutePosition().x + 'px';
    this._wrap.style.width = rectShape.width() * scaleX + 'px';
    this._wrap.style.height = rectShape.height() * scaleY + 'px';
    this._wrap.style.boxShadow = '0px 0px 10px 0px grey';
    this._wrap.style.borderRadius = '5px';

    this._textArea.focus();

    const rotation = shape.rotation();
    if (rotation) {
      let transform = '';
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)';
      }

      let px = 0;
      if (isFirefox(navigator.userAgent)) {
        px += 2 + Math.round(shape.fontSize() / 20);
      }
      transform += 'translateY(-' + px + 'px)';
      this._wrap.style.transformOrigin = 'left top';
      this._wrap.style.transform = transform;
    }

    fromEvent(this._textArea, 'keydown')
      .pipe(
        takeUntil(this.closed$),
        tap(x => {
          this._textArea.style.height = 'auto';
          this._textArea.style.height = this.updatedTextAreaHeight(textShape) + 'px';
          this.updateChange();
        }),
      )
      .subscribe();
  }

  updatedTextAreaHeight(shape: Konva.Text) {
    const updatedTextAreaHeight = this._textArea.scrollHeight + shape.fontSize();
    const wrapHeightNumber = +this._wrap.style.height.slice(0, -2);

    if (updatedTextAreaHeight > wrapHeightNumber) {
      return wrapHeightNumber;
    }
    return updatedTextAreaHeight;
  }

  private updateChange(height?: number) {
    this.changed$.next({
      text: this._textArea.value,
      height: height,
      shapeId: this._shape.konvajsShape.getAttr('ezId'),
      attrs: this.getShapeTextAttrsConfig(),
    });
  }
}
