import { Pipe, PipeTransform } from '@angular/core';
import { ChatLesson, User } from '@ezteach/api/models';
import { getStudentUser } from '@ezteach/api/utils/chat-lesson-member-replacement';

@Pipe({
  name: 'findStudentUser',
})
export class FindStudentUser implements PipeTransform {
  transform(lesson: ChatLesson): User {
    return getStudentUser(lesson);
  }
}
