import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import tippy, { Instance, Props } from 'tippy.js';

export type SelectedTool =
  | 'shapes'
  | 'rect'
  | 'ellipse'
  | 'triangle'
  | 'mouse'
  | 'move'
  | 'freeDraw'
  | 'eraser'
  | 'text'
  | 'sticker'
  | 'fileAdd';
export type Shape = 'rect' | 'ellipse' | 'triangle';

export enum WhiteboardTooltipType {
  shapes,
  mouse,
  pen,
  eraser,
  text,
  sticker,
  fileAdd,
  imageAdd,
}

export enum ShapeTooltipType {
  rectangle,
  ellipse,
  triangle,
}

@UntilDestroy()
@Component({
  selector: 'ezteach-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsComponent implements AfterViewInit {
  private readonly shapeList: SelectedTool[] = ['rect', 'ellipse', 'triangle'];
  private lastSelectedShape: Shape;

  @ViewChild('mouseTooltipBtn') mouseTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('shapesTooltipBtn') shapesTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('penTooltipBtn') penTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('rectTooltipBtn') rectTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('ellipseTooltipBtn') ellipseTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('triangleTooltipBtn') triangleTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('eraserTooltipBtn') eraserTooltipBtn: ElementRef<HTMLElement>;
  @ViewChild('textFieldBtn') textFieldBtn: ElementRef<HTMLElement>;
  @ViewChild('stickerBtn') stickerBtn: ElementRef<HTMLElement>;
  @ViewChild('addFiledBtn') addFiledBtn: ElementRef<HTMLElement>;
  @ViewChild('addImageBtn') addImageBtn: ElementRef<HTMLElement>;

  readonly whiteBoardTooltipType = WhiteboardTooltipType;
  readonly shapeTooltipType = ShapeTooltipType;
  private currentTooltip?: Instance<Props>;
  showShapes = false;
  penHover = false;
  toolsIsOpened = true;
  tool: SelectedTool = 'mouse';

  @Input()
  set selectedTool(tool: SelectedTool) {
    this.tool = tool;
    this.cdr.detectChanges();
  }
  @Output()
  onToolChanged = new EventEmitter<SelectedTool>();
  @Output()
  onAddFile = new EventEmitter<File>();

  constructor(private cdr: ChangeDetectorRef, private translocoService: TranslocoService) {}

  ngAfterViewInit(): void {
    if (this.penTooltipBtn) {
      fromEvent(this.penTooltipBtn.nativeElement, 'mouseover')
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.penHover = true;
            this.cdr.detectChanges();
          }),
        )
        .subscribe();

      fromEvent(this.penTooltipBtn.nativeElement, 'mouseleave')
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.penHover = false;
            this.cdr.detectChanges();
          }),
        )
        .subscribe();
    }
  }

  updateSelectedTool(tool: SelectedTool): void {
    this.tool = tool;
    this.cdr.detectChanges();
  }

  showShapesTools(): void {
    this.showShapes = !this.showShapes;
    if (this.lastSelectedShape) {
      this.tool = this.lastSelectedShape;
      this.onToolChanged.emit(this.tool);
    }
    this.cdr.detectChanges();
  }

  changeMouseState(): void {
    const currentMouseState = this.tool === 'mouse' ? 'move' : 'mouse';
    this.changeActiveTool(currentMouseState);
  }

  toFreeDrawState(): void {
    this.changeActiveTool('freeDraw');
  }

  toTextFieldState(): void {
    this.changeActiveTool('text');
  }

  toEraserState(): void {
    this.changeActiveTool('eraser');
  }

  setRect(): void {
    this.changeActiveTool('rect');
  }

  setEllipse(): void {
    this.changeActiveTool('ellipse');
  }

  setTriangle(): void {
    this.changeActiveTool('triangle');
  }

  setStickerState() {
    this.changeActiveTool('sticker');
  }

  private changeActiveTool(selectedTool: SelectedTool): void {
    if (selectedTool === this.tool) {
      this.changeActiveTool('move');
      return;
    }

    this.tool = selectedTool;

    if (this.shapeList.includes(selectedTool)) {
      this.lastSelectedShape = this.tool as Shape;
    }

    this.showShapes = false;
    this.onToolChanged.emit(this.tool);
    this.cdr.detectChanges();
  }

  @tuiPure
  isShapeSelected(selected: SelectedTool): boolean {
    return this.shapeList.includes(selected);
  }

  toggleTools(): void {
    this.toolsIsOpened = !this.toolsIsOpened;
    if (!this.toolsIsOpened) {
      this.showShapes = false;
    }
  }

  private isTooltipExist(): boolean {
    return !!this.currentTooltip;
  }

  createWhiteBoardTooltip(whiteBoardTooltip: WhiteboardTooltipType): void {
    if (this.isTooltipExist()) {
      return;
    }
    switch (whiteBoardTooltip) {
      case WhiteboardTooltipType.mouse:
        this.currentTooltip = tippy(this.mouseTooltipBtn.nativeElement, this.getTooltipOptions('Выбрать'));
        break;
      case WhiteboardTooltipType.shapes:
        this.currentTooltip = tippy(this.shapesTooltipBtn.nativeElement, this.getTooltipOptions('Фигура'));
        break;
      case WhiteboardTooltipType.pen:
        this.currentTooltip = tippy(this.penTooltipBtn.nativeElement, this.getTooltipOptions('Карандаш'));
        break;
      case WhiteboardTooltipType.eraser:
        this.currentTooltip = tippy(this.eraserTooltipBtn.nativeElement, this.getTooltipOptions('Стерка'));
        break;
      case WhiteboardTooltipType.text:
        this.currentTooltip = tippy(this.textFieldBtn.nativeElement, this.getTooltipOptions('Текст'));
        break;
      case WhiteboardTooltipType.fileAdd:
        this.currentTooltip = tippy(this.addFiledBtn.nativeElement, this.getTooltipOptions('Документ'));
        break;
      case WhiteboardTooltipType.imageAdd:
        this.currentTooltip = tippy(this.addImageBtn.nativeElement, this.getTooltipOptions('Изображение'));
        break;
      case WhiteboardTooltipType.sticker:
        this.currentTooltip = tippy(this.stickerBtn.nativeElement, this.getTooltipOptions('Заметка'));
        break;
    }
  }

  createShapeTooltip(shapeTooltip: ShapeTooltipType): void {
    if (this.isTooltipExist()) {
      return;
    }
    switch (shapeTooltip) {
      case ShapeTooltipType.rectangle:
        this.currentTooltip = tippy(this.rectTooltipBtn.nativeElement, this.getTooltipOptions('Квадрат'));
        break;
      case ShapeTooltipType.ellipse:
        this.currentTooltip = tippy(this.ellipseTooltipBtn.nativeElement, this.getTooltipOptions('Эллипс'));
        break;
      case ShapeTooltipType.triangle:
        this.currentTooltip = tippy(this.triangleTooltipBtn.nativeElement, this.getTooltipOptions('Треугольник'));
        break;
    }
  }

  removeTooltip(): void {
    if (!this.isTooltipExist()) {
      return;
    }
    this.currentTooltip.destroy();
    this.currentTooltip = null;
  }

  private getTooltipOptions(content: string): Partial<Props> {
    return {
      content: this.translocoService.translate(content),
      allowHTML: true,
      theme: 'whiteboard-tooltip',
      placement: 'bottom',
    };
  }

  addFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (!target?.files || target.files.length === 0) {
      return;
    }
    this.onAddFile.emit(target.files[0]);
    target.value = null;
  }
}
