<div class="file-input-container">
  <button
    mat-button
    class="file-input-button mini"
    type="button"
    [disabled]="disabled"
    (keyup.enter)="fileInput.click()"
    (click)="fileInput.click()"
  >
    <ng-content></ng-content>
  </button>
  <input
    #fileInput
    class="app-file-input-hidden"
    type="file"
    [attr.accept]="accept"
    (fileSelect)="handleSelect($event)"
    [multiple]="multiple"
    [disabled]="disabled"
    appFileSelect
  />
</div>