<ezteach-popup-with-bg
  [smiley]="smileyType.sad"
  [hasCloseBtn]="true"
  (onCloseBtnClick)="close()"
  [isDefaultWidth]="true"
>
  <div title> {{ 'Легко Учиться нужна возможность' | transloco }} <br><br>
    {{ 'демонстрации экрана' | transloco }} </div>
  <div content>
    {{ 'Перейдите в' | transloco }}
    <a
      [style.color]="'#678aff'"
      href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
    > {{ 'системные настройки' | transloco }} </a>
    {{ 'и разрешите запись экрана' | transloco }} .
  </div>

  <div buttons>
    <ezteach-btn
      (click)="close()"
      [type]="buttonType.warnFilled"
    >
      <div content>{{ 'Закрыть' | transloco }} </div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>