import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-lesson-finish',
  templateUrl: 'modal-lesson-finish.component.html',
})
export class ModalLessonFinish {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalLessonFinish, ModalLessonFinishResult>,
  ) {}

  finish(id) {
    this.dialogRef.close('finish');
  }

  close() {
    this.dialogRef.close('close');
  }
}
export type ModalLessonFinishResult = 'finish' | 'close';
