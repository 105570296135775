import { HttpParams } from "@angular/common/http";

export const getParamValueQueryString = (link: string, paramName: string) => {
    const url = link;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }