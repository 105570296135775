<div class="ez-date-time-range-wrapper">
  <mat-form-field
    class="ez-date-range"
    appearance="outline"
  >
    <input
      #dateInput
      class="ez-date-input"
      matInput
      [(ngModel)]="date"
      (dateChange)="dateChanged($event.value)"
      [matDatepicker]="picker"
      required
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
    >
      <img
        matDatepickerToggleIcon
        src="/assets/img/svg/calendar-outline.svg"
        alt="calendar-outline.svg"
      />
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="ez-time-range-wrapper">
    <mat-form-field
      class="ez-time-range"
      appearance="outline"
    >
      <input
        #timeStartInput
        matInput
        type="time"
        [(ngModel)]="timeStart"
        required
      />
    </mat-form-field>
    <span class="ez-time-range-separator">—</span>
    <mat-form-field
      class="ez-time-range"
      appearance="outline"
    >
      <input
        #timeEndInput
        type="time"
        matInput
        [(ngModel)]="timeEnd"
        required
      />
    </mat-form-field>
  </div>
</div>
