import { LessonFormatEnum } from "./lesson-format-enum";

export interface ProfilePaymentHistoryResponseData {
  name: string;
  chatLessonId: number,
  lessonCost: number;
  tutorPayout: number;
  date: Date;
  checkNumber: string;
  resultType: PaymentResultEnum;
  deal: string;
  payment: string;
  lessonFormatId: LessonFormatEnum,
  videoFileUrl: string
}

export interface ProfilePaymentHistoryResponse{
  data: ProfilePaymentHistoryResponseData[],
  pageNumber: number,
  pageSize: number,
  statusCode: string,
  succeeded: boolean
  totalPages: number,
  totalRecords: number
}

export enum PaymentResultEnum {
  Error = 'Error',
  Success = 'Success',
}
