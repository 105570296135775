import Konva from 'konva';
import { ShapeTypes } from './classes/white-board';
import { SelectedTool } from './components/tools/tools.component';
import { WhiteBoard } from './whiteboard';

/*
 * Класс отвечающий за обработку событий вайтборда в зависсимости от текущего состояния вайтборда
 * Например, если состояние ReadOnly || Moved - то на событие захвата и движение мыши мы должны передвигать достку и рендерить объекты на пространстве
 * Если состояние Shaped - то при захвате мыши и движении мы должны рисовать фигуру
 * Если состояние просто Mouse - то при захвате мышью и передвижении мы долны рисовать область выделения фигур.
 */
export abstract class WhiteBoardState {
  protected whiteBoard: WhiteBoard;

  setWhiteBoard(whiteBoard: WhiteBoard) {
    this.whiteBoard = whiteBoard;
  }

  withOffsetX(x) {
    const start = this.whiteBoard.getCanvasStartRectPosition();
    return (x + this.whiteBoard.offsetX - start.x) / this.whiteBoard.scaleOffset;
  }

  withOffsetY(y) {
    const start = this.whiteBoard.getCanvasStartRectPosition();
    return (y + this.whiteBoard.offsetY - start.y) / this.whiteBoard.scaleOffset;
  }

  public abstract getTool(): SelectedTool;
  public abstract handleClick(e): void;
  public abstract handleDragend(e): void;
  public abstract handleMousedown(e): void;
  public abstract handleMouseup(e): void;
  public abstract handleMousemove(e): void;

  public abstract handleTouchstart(e): void;
  public abstract handleTouchend(e): void;
  public abstract handleTouchmove(e): void;
  public abstract handleWheel(e): void;
}

export class WhiteBoardMovedState extends WhiteBoardState {
  public handleTouchstart(e: any): void {
    this.whiteBoard.touchStartStage(e.evt);
  }
  public handleTouchend(e: any): void {
    const position = e.currentTarget.position();
    this.whiteBoard.offsetX = -position.x;
    this.whiteBoard.offsetY = -position.y;
    this.whiteBoard.backLayerRender.updateGrid(
      this.whiteBoard.width,
      this.whiteBoard.height,
      position.x,
      position.y,
      this.whiteBoard.scaleOffset,
    );
  }
  public handleTouchmove(e: any): void {
    this.whiteBoard.touchMoveStage(e.evt);
  }
  public getTool(): SelectedTool {
    return 'move';
  }
  public handleMousedown(e: any): void {}
  public handleMouseup(e: any): void {}
  public handleMousemove(e: any): void {}
  public handleDragend(e: any): void {
    const position = e.currentTarget.position();
    this.whiteBoard.offsetX = -position.x;
    this.whiteBoard.offsetY = -position.y;
    this.whiteBoard.backLayerRender.updateGrid(
      this.whiteBoard.width,
      this.whiteBoard.height,
      position.x,
      position.y,
      this.whiteBoard.scaleOffset,
    );
  }
  public handleClick(e): void {}

  public handleWheel(e: any) {
    if (!e.evt.ctrlKey) {
      this.whiteBoard.wheelMoveStage(e.evt);
    } else {
      this.whiteBoard.wheelScaleStage(e.evt);
    }

    const position = e.currentTarget.position();
    this.whiteBoard.offsetX = -position.x;
    this.whiteBoard.offsetY = -position.y;

    this.whiteBoard.backLayerRender.updateGrid(
      this.whiteBoard.width,
      this.whiteBoard.height,
      position.x,
      position.y,
      this.whiteBoard.scaleOffset,
    );
  }
}

export class WhiteBoardReadOnlyState extends WhiteBoardState {
  public handleTouchstart(e: any): void {}
  public handleTouchend(e: any): void {}
  public handleTouchmove(e: any): void {}
  public getTool(): SelectedTool {
    return 'move';
  }
  public handleMousedown(e: any): void {}
  public handleMouseup(e: any): void {}
  public handleMousemove(e: any): void {}
  public handleDragend(e: any): void {}
  public handleClick(e): void {}
  public handleWheel(e): void {}
}

export class WhiteBoardShapedState extends WhiteBoardState {
  public handleTouchstart(e: any): void {
    const target = e.evt.targetTouches[0];
    this.whiteBoard.shapeManager.beginDraw({
      x: this.withOffsetX(target.clientX),
      y: this.withOffsetY(target.clientY),
    });
  }
  public handleTouchend(e: any): void {
    this.whiteBoard.shapeManager.endDraw(e.currentTarget.position());
    if (this.whiteBoard.shapeManager.getCurrentShape() !== ShapeTypes.freeline) {
      this.whiteBoard.setState(new WhiteBoardMouseState(), true);
    }
  }
  public handleTouchmove(e: any): void {
    const target = e.evt.targetTouches[0];
    this.whiteBoard.shapeManager.draw({ x: this.withOffsetX(target.clientX), y: this.withOffsetY(target.clientY) });
  }
  public getTool(): SelectedTool {
    return 'shapes';
  }
  public handleMousedown(e: any): void {
    this.whiteBoard.shapeManager.beginDraw({ x: this.withOffsetX(e.evt.clientX), y: this.withOffsetY(e.evt.clientY) });
  }
  public handleMouseup(e: any): void {
    this.whiteBoard.shapeManager.endDraw(e.currentTarget.position());
    if (this.whiteBoard.shapeManager.getCurrentShape() !== ShapeTypes.freeline) {
      this.whiteBoard.setState(new WhiteBoardMouseState(), true);
    }
  }
  public handleMousemove(e: any): void {
    this.whiteBoard.shapeManager.draw({ x: this.withOffsetX(e.evt.clientX), y: this.withOffsetY(e.evt.clientY) });
  }
  public handleDragend(e: any): void {}
  public handleClick(e): void {}

  public handleWheel(e): void {}
}

export class WhiteBoardEraserState extends WhiteBoardState {
  public getTool(): SelectedTool {
    return 'eraser';
  }
  public handleClick(e: any): void {}
  public handleDragend(e: any): void {}
  public handleMousedown(e: any): void {
    this.whiteBoard?.shapeManager?.setEraserMode(true);
   
  }
  public handleMouseup(e: any): void {
    this.whiteBoard?.shapeManager?.setEraserMode(false);
  }
  public handleMousemove(e: any): void {
    this.whiteBoard?.shapeManager?.turnOnRemoveFreeDrawMode();
  }
  public handleTouchstart(e: any): void {
    this.whiteBoard?.stage?.setPointersPositions(e.evt);
    this.whiteBoard.getEraserFollower()?.enableEraserFollowerCanvas();
    this.whiteBoard?.shapeManager?.setEraserMode(true);
  }
  public handleTouchend(e: any): void {
    this.whiteBoard.getEraserFollower()?.disableEraserFollowerCanvas();
    this.whiteBoard?.shapeManager?.setEraserMode(false);
  }
  public handleTouchmove(e: any): void {
    this.whiteBoard?.shapeManager?.turnOnRemoveFreeDrawMode();
  }
  public handleWheel(e): void {}
}

export class WhiteBoardMouseState extends WhiteBoardState {
  public handleTouchstart(e: any): void {
    const target = e.evt.targetTouches[0];
    this.whiteBoard.shapeManager.startSelect(
      { x: this.withOffsetX(target.clientX), y: this.withOffsetY(target.clientY) },
      false,
      true,
    );
    this.whiteBoard.shapeManager.closeTextEditor();
  }
  public handleTouchend(e: any): void {
    this.whiteBoard.shapeManager.endSelect();
  }
  public handleTouchmove(e: any): void {
    const target = e.evt.targetTouches[0];
    this.whiteBoard.shapeManager.select({ x: this.withOffsetX(target.clientX), y: this.withOffsetY(target.clientY) });
  }
  public getTool(): SelectedTool {
    return 'mouse';
  }
  public handleMousedown(e: any): void {
    if (e.target.attrs.type === ShapeTypes.text || e.target.attrs.type === 'sticker') {
      return;
    }

    const multiSelect = e.target.constructor.name === Konva.Stage.name;
    this.whiteBoard.shapeManager.startSelect(
      { x: this.withOffsetX(e.evt.clientX), y: this.withOffsetY(e.evt.clientY) },
      multiSelect,
      false,
    );
    this.whiteBoard.shapeManager.closeTextEditor();
  }
  public handleMouseup(e: any): void {
    this.whiteBoard.shapeManager.closeTextEditor();
    this.whiteBoard.shapeManager.endSelect();
  }
  public handleMousemove(e: any): void {
    this.whiteBoard.shapeManager.select({ x: this.withOffsetX(e.evt.clientX), y: this.withOffsetY(e.evt.clientY) });
  }
  public handleDragend(e: any): void {}
  public handleClick(e): void {
    this.whiteBoard.shapeManager.toWhiteBoardReadonlyState();
  }
  public handleWheel(e): void {}
}

export class WhiteBoardEditShapedState extends WhiteBoardState {
  public handleTouchstart(e: any): void {
    this.whiteBoard.shapeManager.toWhiteBoardReadonlyState();
    this.whiteBoard.setState(new WhiteBoardMouseState());
    this.whiteBoard.handleTouchstart(e);
    this.whiteBoard.handleTouchend(e);
  }
  public handleTouchend(e: any): void {}
  public handleTouchmove(e: any): void {}
  public getTool(): SelectedTool {
    throw '';
  }
  public handleClick(e: any): void {
    this.whiteBoard.shapeManager.toWhiteBoardReadonlyState();
    this.whiteBoard.setState(new WhiteBoardMouseState());
    this.whiteBoard.handleMouseDown(e);
    this.whiteBoard.handleMouseUp(e);
  }
  public handleDragend(e: any): void {}
  public handleMousedown(e: any): void {
    this.whiteBoard.shapeManager.textEditing$.next(false);
  }
  public handleMouseup(e: any): void {}
  public handleMousemove(e: any): void {}
  public handleWheel(e): void {}
}
