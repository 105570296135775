<div class="widget_bookmarks">
  <div class="widget_bookmarks__title"> {{ 'Закладки' | transloco }} </div>
  <div class="widget_bookmarks__icon">
    <svg-icon-sprite
      [src]="'icon-bookmark'"
      [width]="'62px'"
      [height]="'62px'"
      [viewBox]="'0 0 62 62'"
    ></svg-icon-sprite>
  </div>
  <div
    class="widget_bookmarks_none"
    *ngIf="results && results.totalRecords === 0"
  >
    <div class="widget_bookmarks_none__wrap">
      <div class="widget_bookmarks_none__icon">
        <svg-icon-sprite
          [src]="'icon-no-bookmarks'"
          [width]="'86px'"
          [height]="'80px'"
          [viewBox]="'0 0 86 80'"
        ></svg-icon-sprite>
      </div>
      <div class="widget_bookmarks_none__title"> {{ 'У вас пока нет закладок' | transloco }}</div>
      <!--div class="widget_bookmarks_none__text">
        {{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' | transloco }}
      </div-->
      <div class="widget_bookmarks_none__button">
        <a
          routerLink="/search/regular"
          class="btn btn--blue"
        >
          <span class="btn__icon"><svg-icon-sprite
              [src]="'icon-search'"
              [width]="'20px'"
              [height]="'20px'"
              [viewBox]="'0 0 20 20'"
            ></svg-icon-sprite></span><span class="btn__label"> {{ 'Найти организатора' | transloco }}</span>
        </a>
      </div>
    </div>
  </div>
  <div
    class="widget_bookmarks__list"
    *ngIf="results && results.totalRecords > 0"
  >
    <div
      class="bookmark_item"
      *ngFor="let item of teachers"
    >
      <div
        class="bookmark_item__image"
        routerLink="/teachers/{{ item.id }}"
      >
        <img
          *ngIf="image(item)"
          src="{{ image(item) }}"
        />
        <span *ngIf="!image(item)">{{ initials(item) }}</span>
      </div>
      <div class="bookmark_item__info">
        <div
          class="bookmark_item__name"
          routerLink="/teachers/{{ item.id }}"
        >
          {{ item.name }}
        </div>
        <div class="disciplines">
          <div class="disciplines__icon">
            <svg-icon-sprite
              [src]="'icon-desciplines'"
              [width]="'19px'"
              [height]="'17px'"
              [viewBox]="'0 0 19 17'"
            ></svg-icon-sprite>
          </div>
          <div class="disciplines__name">
            {{ getDisciplines(item.tutor) }}
          </div>
        </div>
      </div>
      <div class="bookmark_item__stat">
        <div
          class="bookmark_item__rating"
          *ngIf="item.tutor.rating"
          [ngClass]="{ green: item.tutor.rating > 4, blue: item.tutor.rating === 4, yellow: item.tutor.rating < 4 }"
        >
          {{ item.tutor.rating }}
        </div>
        <div class="bookmark_item__lessons">
          {{ item.tutor.chatLessonsAccomplished }}
          <span
            appPlural
            [value]="item.tutor.chatLessonsAccomplished"
            [arVariants]="['событие', 'события', 'событий']"
          ></span>
        </div>
      </div>
      <div class="bookmark_item__price">
        <div class="price">
          <div class="price__title"> {{ '5 минут / классический' | transloco }}</div>
          <div class="price__value">
            {{ item.tutor.instantLesson5MinuteRate }} ₽ / {{ item.tutor.scheduledLessonHourlyRate }} ₽
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="lessons__all"
    *ngIf="results && results.totalRecords > 0"
  >
    <a routerLink="/bookmarks"> {{ 'Посмотреть все закладки' | transloco }}</a>
  </div>
</div>
