import { createAction, props } from '@ngrx/store';
import { Option } from 'fp-ts/lib/Option';
import { ITutorialData, TutorialSteps, TutorialWithStepTuple } from '../models';

export const loadTutorial = createAction('[Tutorial] Load Tutorial');

export const updateTutorial = createAction('[Tutorial] Update Tutorial', props<{ tutorial: ITutorialData }>());

export const updateTutorialStep = createAction('[Tutorial] Update Tutorial Step', props<{ progress: TutorialSteps }>());

export const updateCurrentStepShown = createAction(
  '[Tutorial] Update Current Step Shown',
  props<{ currentStepShown: Option<TutorialWithStepTuple> }>(),
);

export const startTutorial = createAction('[Tutorial] Start Tutorial', props<{ tutorial: ITutorialData }>());

export const finishTutorial = createAction('[Tutorial] Finish Tutorial');

export const refreshTutorialStep = createAction('[Tutorial] Refresh Tutorial Step');

export const nextStep = createAction('[Tutorial] Next Step');

export const previousStep = createAction('[Tutorial] Previous Step');

export const lastStep = createAction('[Tutorial] Last Step');
