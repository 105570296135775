import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SignalrChatService } from '@ezteach/_services/signalr.chat.service';
import { UserService } from "@ezteach/_services/user.service";
import { ChatLesson } from '@ezteach/api/models/chat-lesson';
import { LessonActiveCheckApiResponse } from '@ezteach/api/models/lesson-active-check-api-response';
import { ChatService } from '@ezteach/api/services';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import {
  OnboardingStudentSteps,
  OnboardingTeacherSteps,
  TutorialRegistryService,
  TutorialTypes,
  TutorialWithStepTuple,
} from '@ezteach/tutorial';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, first, tap } from 'rxjs/operators';
import { ActiveService } from '../_services/active.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { RequestsService } from '../_services/requests.service';
import { SignalrService } from '../_services/signalr.service';

@UntilDestroy()
@Component({
  selector: 'nav',
  templateUrl: './nav.component.html',
})
export class NavComponent implements OnInit, OnDestroy {
  active;
  isTutor;
  isStudent;
  userId: number;

  dashboard;
  instant;
  regular;
  navLink;
  lessons: ChatLesson[];

  requests = [];
  nav = true;
  activeTemporarySolution: boolean;
  dialogRef: MatDialogRef<any>;
  isHaveActiveLessons: boolean;
  isHaveUnreadMessages: boolean;
  private tutorialData: readonly Tuple<TutorialWithStepTuple, string>[] = [
    [[TutorialTypes.ONBOARDING_TEACHER, OnboardingTeacherSteps.STEP2], 'personal-cabinet-link'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP1], 'quick-lesson-link'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP2], 'teacher-search-link'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP3], 'personal-cabinet-link'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP4], 'notifications-link'],
  ];

  @Input() unreadMessagesCount: number;
  @Input() asideIsCollapsed: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private requestsService: RequestsService,
    private activeService: ActiveService,
    private signalrService: SignalrService,
    private signalrChatService: SignalrChatService,
    private chatService: ChatService,
    private readonly tutorialRegistryService: TutorialRegistryService,
    private lessonsService: LessonsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    const userdata = this.userService.userData$.value;
    this.userId = userdata.id;
    this.active = this.activeService.status().active;
    this.navLink = '/group-lesson';
    this.activeService.onChanged.subscribe((data: any) => {
      this.active = data.active;
      this.navLink = '/group-lesson';
    });

    if (userdata.isTutor) {
      this.isTutor = true;

      // if (userdata.tutor.validationStatusId !== 'Approved') {
      // 	this.nav = false;
      // }
    }

    if (userdata.isStudent) {
      this.isStudent = true;
    }

    this.updateRequests();

    this.requestsService.onChanged.subscribe(data => {
      this.updateRequests();
    });

    this.signalrService.onLessonStatusChanged.subscribe(data => {
      this.activeService.update();
    });

    this.signalrService.onILeftLesson.subscribe(() => {
      this.checkActiveLessons();
    });

    this.signalrChatService.onUserMessageSent.subscribe(() => {
      this.checkUnreadMessages();
    });

    this.signalrService.onIJoinedToLesson.subscribe(() => {
      this.isHaveActiveLessons = true;
    });

    this.chatService.isHaveUnreadMessages
      .pipe(
        untilDestroyed(this),
        tap((x) => {
          this.isHaveUnreadMessages = x
        })).subscribe();

    this.tutorialData.forEach(([data, elemName]) =>
      this.tutorialRegistryService.registerElementNameForStepData(data, elemName),
    );

    this.activeService.activeTemporarySolution$.subscribe(v => (this.activeTemporarySolution = v));

    this.checkActiveLessons();

    this.checkUnreadMessages();
  }

  ngOnDestroy() {
    this.tutorialData.forEach(([data]) => this.tutorialRegistryService.unregisterElementNameForStepData(data));
  }

  updateRequests() {
    this.requests = this.localStorageService.get('requests') ?? [];
    if (this.isStudent) {
      if (this.requests) {
        this.instant = this.requests.length;
      }
    }
    if (this.isTutor) {
      if (this.requests) {
        this.dashboard = this.requests.length;
      }
    }
    this._cdr.detectChanges();
  }

  checkActiveLessons() {
    const checkActiveLessonsSubs = this.lessonsService
      .activeLessonsCheck()
      .pipe(
        first(),
        tap(({ body }: StrictHttpResponse<LessonActiveCheckApiResponse>) => {
          this.isHaveActiveLessons = body.data.anyActiveLessons;
        }),
        finalize(() => checkActiveLessonsSubs.unsubscribe())
      )
      .subscribe();
  }

  checkUnreadMessages() {
    const checkUnreadMessagesSubs = this.chatService
      .apiV1ChatMessagesUnreadGet()
      .pipe(
        tap(chatMessageResponse => {
          if (chatMessageResponse.succeeded && chatMessageResponse.data) {
            this.isHaveUnreadMessages = chatMessageResponse.data?.length > 0;
          }
          else {
            this.isHaveUnreadMessages = false;
          }
          checkUnreadMessagesSubs.unsubscribe();
        }),
        finalize(() => checkUnreadMessagesSubs.unsubscribe())
      ).subscribe();
  }
}
