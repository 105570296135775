import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { environment } from '@ezteach/enviroments';
import { toUser } from '../helpers/user-helper';

@Pipe({ name: 'userAvatar' })
export class UserAvatarPipe implements PipeTransform {
  transform(item: ChatLessonMember | User): string {
    const user = toUser(item);
    if (!user?.avatarFileName) {
      return '';
    }
    return (
      environment.apiUrl +
      '/api/v1/files/usercontent/' +
      user.id +
      '/user-avatar?redirect=true&file=' +
      user.avatarFileName
    );
  }
}
