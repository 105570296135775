<ng-template #mobileMembersContainer>
  <ng-container>
    <button
      class="bt-carousel bt-carousel-prev"
      [ngClass]="{'landscape': orientationLandscape}"
      (click)="carouselMobile.prev()"
      *ngIf="memberClients.length > 1 && !openedMember"
    >
      <img
        class="horizontal-carousel__img"
        src="assets/img/svg/group-lesson-view-horizontal-prev-btn.svg"
      />
    </button>

    <tui-carousel
      #carouselMobile
      [draggable]="true"
      class="ez-video-carousel"
    >
      <ng-container *ngFor="let member of memberClients">
        <div
          class="member-elem"
          *tuiItem
        >
          <ezteach-ov-video-merge
            [ngClass]="{ 'add-blur': isOpen(member) }"
            [isTooltipOpen]="isOpen(member)"
            [streamManager]="member.stream"
            [memberClient]="member"
            [speakers]="speakers"
            [isMobile]="isMobile"
            [owner]="isOwner"
            [lazyIniting]="lazyIniting"
          ></ezteach-ov-video-merge>
          <ng-container *ngIf="(isOwner || isModerator) && !isSpeech && !isMe(member)">
            <div
              class="action action-mobile"
              type="button"
              text="Open menu"
              (click)="openMenu(member)"
            >
              <img
                src="/assets/img/svg/icon-menu_white.svg"
                alt="moderate-menu"
              />
            </div>

            <ng-container *ngIf="isOpen(member)">
              <div
                class="action overlay"
                type="button"
                text="Open menu"
                (click)="openMenu(member)"
              >
                <img
                  src="/assets/img/svg/icon-menu_white.svg"
                  alt="moderate-menu"
                />
              </div>
              <div class="dropdown">
                <div class="checkbox__wrapper">
                  <mat-checkbox
                    [checked]="isAudioChecked(member, this.membersPermission)"
                    (change)="audioChanged($event, member)"
                  >{{ 'Доступ к микрофону' | transloco }}
                  </mat-checkbox>
                  <mat-checkbox
                    [checked]="isVideoChecked(member, this.membersPermission)"
                    (change)="videoChanged($event, member)"
                    i18n
                  >{{ isMobile ? 'Вкл./откл. видео' : 'Включить/отключить видео' }}
                  </mat-checkbox>
                  <mat-checkbox
                    [checked]="isShareScreenChecked(member, membersPermission)"
                    (change)="shareScreenChanged($event, member)"
                  > {{ 'Доступ к экрану' | transloco }}
                  </mat-checkbox>
                </div>
                <hr />
                <div
                  class="delete__title"
                  (click)="onBanned(member)"
                >
                  {{ 'Удалить из звонка' | transloco }}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </tui-carousel>

    <button
      class="bt-carousel bt-carousel-next"
      [ngClass]="{'landscape': orientationLandscape}"
      (click)="carouselMobile.next()"
      *ngIf="memberClients.length > 1 && !openedMember"
    >
      <img
        class="horizontal-carousel__img"
        src="assets/img/svg/group-lesson-view-horizontal-next-btn.svg"
      />
    </button>
  </ng-container>
</ng-template>

<ng-container *ngIf="isMobile">
  <ng-template *ngTemplateOutlet="mobileMembersContainer"></ng-template>
</ng-container>