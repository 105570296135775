import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GroupLessonMemberMergeComponent } from './components/group-lesson-member/group-lesson-member.component';
import { GroupLessonOwnerMergeComponent } from './components/group-lesson-owner/group-lesson-owner.component';
import { GroupLessonMergeComponent } from './group-lesson.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: GroupLessonMergeComponent,
        children: [
          { path: 'owner', component: GroupLessonOwnerMergeComponent },
          { path: 'member', component: GroupLessonMemberMergeComponent },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class GroupLessonRoutingMergeModule { }
