import { ViewportScroller } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@ezteach/_services/user.service';
import { Langs } from '@ezteach/shared/services/lang-store.service';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, first, tap } from 'rxjs/operators';
import { tryGetAndConvertToDate } from 'src/utils/route.utils';
import { LocalStorageService } from '../_services/local-storage.service';
import { TutorUserSearchResult } from '../api/models/tutor-user-search-result';
import { TutorUserSearchResultPagedApiResponse } from '../api/models/tutor-user-search-result-paged-api-response';
import { SearchService } from '../api/services/search.service';

export interface TutorUserSearchResultWithBookmarkStatus extends TutorUserSearchResult {
  marked: boolean;
}

@UntilDestroy()
@Component({
  selector: 'search-regular',
  templateUrl: './search-regular.component.html',
})
export class SearchRegularComponent {
  pageSize = 4;
  pageNumber = 1;
  loaded = false;

  results: TutorUserSearchResultPagedApiResponse;
  teachers: Array<TutorUserSearchResultWithBookmarkStatus>;

  Name: string | null;
  DisciplinesNames: string[];
  SpecializationId: number;
  bookmarks: number[];

  private registeredAfter?: Date;
  private lessonAfter?: Date;
  private currentScrollY: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private localStorageService: LocalStorageService,
    @Inject(WINDOW) private windowRef: Window,
    private viewportScroller: ViewportScroller,
    private userService: UserService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const userdata = this.userService.userData$.value;
    if (!userdata.isStudent) {
      this.router.navigateByUrl('/');
    }
    this.bookmarks = this.localStorageService.get('bookmarks') || [];
    this.route.queryParamMap.subscribe(queryParamMap => {
      this.Name = queryParamMap.get('name');
      this.DisciplinesNames = queryParamMap.getAll('discipline');

      this.registeredAfter = tryGetAndConvertToDate(queryParamMap, 'registeredAfter');
      this.lessonAfter = tryGetAndConvertToDate(queryParamMap, 'lessonAfter');

      this.SpecializationId = parseInt(queryParamMap.get('specialization') as string);
      this.bookmarks = this.localStorageService.get('bookmarks') || [];
      this.getResults();
    });

    fromEvent(this.windowRef, 'scroll')
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(_ => this.searchService.scrollY$.next(this.windowRef.scrollY)),
      )
      .subscribe();

    this.searchService.scrollY$
      .pipe(
        untilDestroyed(this),
        tap(scrollY => (this.currentScrollY = scrollY)),
        first(),
      )
      .subscribe();

    this.searchService.pageNumber$
      .pipe(
        untilDestroyed(this),
        tap(pageNumber => (this.pageNumber = pageNumber)),
      )
      .subscribe();

    this.getResults();
  }

  getResults(): void {
    this.searchService
      .apiV1SearchTutorsScheduledGet({
        Name: this.Name,
        DisciplinesNames:
          this.translocoService.getActiveLang() === Langs.RU
            ? this.DisciplinesNames
            : this.DisciplinesNames.map(x => this.translocoService.translate(x + '_s')),
        SpecializationId: this.SpecializationId,
        PageSize: this.pageSize,
        RegisteredAfter: this.registeredAfter,
        LessonAfter: this.lessonAfter,
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.results = results;
        this.bookmarks = this.localStorageService.get('bookmarks') || [];
        if (this.searchService.searchTutorHistory$.value) {
          this.addingBookmarkInfo(this.searchService.searchTutorHistory$.value);
        } else {
          this.addingBookmarkInfo(results.data);
          this.searchService.pageNumber$.next(this.pageNumber++);
        }
        this.loaded = true;
        this.viewportScroller.scrollToPosition([0, this.currentScrollY]);
      });
  }

  addingBookmarkInfo(data: TutorUserSearchResult[]) {
    this.teachers = data.map((teacher: any) => {
      const marked = this.bookmarks.includes(teacher.tutor.id);
      return { ...teacher, marked };
    });
  }

  bookmarksUpdated(bookmarks) {
    this.teachers.map(teacher => (teacher.marked = bookmarks.includes(teacher.tutor.id)));
  }

  getMore(): void {
    this.searchService.pageNumber$.next(++this.pageNumber);

    this.searchService
      .apiV1SearchTutorsScheduledGet({
        Name: this.Name,
        DisciplinesNames:
          this.translocoService.getActiveLang() === Langs.RU
            ? this.DisciplinesNames
            : this.DisciplinesNames.map(x => this.translocoService.translate(x + '_s')),
        SpecializationId: this.SpecializationId,
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
        RegisteredAfter: this.registeredAfter,
        LessonAfter: this.lessonAfter,
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.loaded = true;
        this.results = results;
        // @ts-ignore
        this.teachers = this.teachers.concat(results.data || []);
        this.bookmarks = this.localStorageService.get('bookmarks') || [];
        this.teachers.map(teacher => (teacher.marked = this.bookmarks.includes(teacher.tutor.id)));
        this.searchService.searchTutorHistory$.next(this.teachers);
      });
  }
}
