import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss'],
})
export class PullToRefreshComponent {
  @Input() scrollableElement: HTMLDivElement;
  @ViewChild('boxElement') boxElement: ElementRef;

  public diffY: number = 0;
  private isRefreshing = false;
  private duration: number = 300;
  private startY: number = 0;
  private isDragging: boolean = false;
  private isAtTop: boolean = false;
  private animationStarted: boolean = false;
  private minSwipeDistance: number = 75;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    if (this.scrollableElement) {
      this.isAtTop = this.scrollableElement.scrollTop <= 0;

      this.renderer.listen(this.scrollableElement, 'scroll', this.onScroll.bind(this));
      this.renderer.listen(this.scrollableElement, 'touchstart', this.onDragStart.bind(this));
      this.renderer.listen(this.scrollableElement, 'touchmove', this.onDrag.bind(this));
      this.renderer.listen(this.scrollableElement, 'touchend', this.onDragEnd.bind(this));
    }
  }

  onScroll() {
    this.isAtTop = this.scrollableElement.scrollTop <= 0;

    if (this.isAtTop && !this.animationStarted) {
      this.diffY = 0;
      this.update(0);
    }
  }

  onDragStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isDragging = true;
    this.diffY = 0;
    this.animationStarted = false;
  }

  onDrag(event: TouchEvent) {
    if (this.isDragging) {
      const currentY = event.touches[0].clientY;
      const deltaY = currentY - this.startY;

      if (deltaY > 0) {
        this.diffY += deltaY;

        // если элемент доскролен до конца или контент не превышает высоту элемента, 
        // начинаем анимацию без учета minSwipeDistance
        if (this.isAtTop) {
          this.animationStarted = true;
        } else if (!this.animationStarted && this.diffY >= this.minSwipeDistance) {
          // если еще есть куда скролить вверх, учитываем minSwipeDistance
          this.animationStarted = true;
          this.diffY = 0; // сбрасываем diffY после достижения minSwipeDistance
        }
      } else if (deltaY < 0 && this.animationStarted) {
        this.diffY += deltaY;
        if (this.diffY < 0) this.diffY = 0;
      }

      if (this.animationStarted) {
        this.update(this.diffY);
      }

      this.startY = currentY;

      // хотелось бы подать вибрацию перед тем как пользователь отпустит палец
      // но кажется это все заблокировано на мобильных устройствах
      if (this.diffY > this.duration - 50) {
        if (navigator.vibrate) {
          navigator.vibrate(200);
        }
      }
    }
  }

  onDragEnd() {
    this.isDragging = false;

    if (this.diffY > this.duration && this.animationStarted) {
      this.triggerRefresh();
    } else {
      this.update(0);
    }
    this.diffY = 0;
    this.animationStarted = false;
  }

  update(value: number) {
    const normalizedValue = Math.min(value / this.duration, 1);
    const percent = Math.sqrt(normalizedValue);
    const strokePercent = Math.sqrt(normalizedValue);
    const translateY = Math.min(value, 80) + 'px';

    if (this.boxElement && this.boxElement.nativeElement) {
      this.boxElement.nativeElement.style.setProperty('--percent', percent.toString());
      this.boxElement.nativeElement.style.setProperty('--strokePercent', strokePercent.toString());
      this.boxElement.nativeElement.style.setProperty('--translateY', translateY);
    }
  }

  triggerRefresh() {
    if (!this.isRefreshing) {
      window.location.reload();
    }
  }
}
