import {
  assoc,
  complement,
  compose,
  curry,
  defaultTo,
  equals,
  is,
  isNil,
  map,
  not,
  omit,
  partialRight,
  prop,
  propEq,
  sortWith,
  toLower,
  useWith,
} from 'ramda';

export function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
export const sortByTextField = curry((propName, sortDirection, data) => {
  const sorter = partialRight(compare, [sortDirection === 'asc']);
  //@ts-ignore
  const toLowerName = compose(toLower, prop(propName));

  return sortWith([useWith(sorter, [toLowerName, toLowerName])], data);
});

export function to<T = any>(x: T) {
  return () => x;
}
export const existing = complement(isNil);
export const truthy = complement(not);
export const defaultToEmtpyList = defaultTo([]);
export const lengthEquals = propEq('length');
export const notEquals: (a1, a2) => boolean = compose(not, equals);

interface BaseObject {
  [key: string]: any;
}
type OmitValue = BaseObject | BaseObject[];
export const omitDeep: CurriedFunction2<string[] | string, OmitValue, OmitValue> = curry((keys, value) => {
  let _value = value;
  if (equals(value, undefined)) {
    return {};
  }

  if (is(Array, value)) {
    return map(el => omitDeep(keys, el), value);
  }

  if (!is(Object, value)) {
    return value;
  }

  if (is(String, keys)) {
    keys = [keys];
  }

  if (!is(Array, keys)) {
    return value;
  }

  _value = omit(keys, value);

  for (const key in _value) {
    if (_value.hasOwnProperty(key)) {
      _value = assoc(key, omitDeep(keys, value[key]))(_value);
    }
  }

  return _value;
});

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
