import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { UserProfileTypeEnum } from '../models';
import { UserService } from '@ezteach/_services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserSerchService {
  environment;

  constructor(private http: HttpClient, private userService: UserService) {}

  getUsersList(query: string): Observable<any> {
    const userType = this.userService.userData$.value.isTutor ? UserProfileTypeEnum.Student : UserProfileTypeEnum.Tutor;
    return this.http.get(`${environment.apiUrl}/api/v1/search/users?UserType=${userType}&Name=${query}`);
  }
}
