import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { FileDocumentIEnumerableApiResponse } from '../models/file-document-i-enumerable-api-response';
import { StringApiResponse } from '../models/string-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1FilesUsercontentContentTypePost
   */
  static readonly ApiV1FilesUsercontentContentTypePostPath = '/api/v1/files/usercontent/{contentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FilesUsercontentContentTypePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1FilesUsercontentContentTypePost$Response(params: {
    contentType: null | string;
    body?: { files?; formFieldsPrefix?: string; ownerEntityId?: string };
  }): Observable<StrictHttpResponse<FileDocumentIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.ApiV1FilesUsercontentContentTypePostPath, 'post');
    if (params) {
      rb.path('contentType', params.contentType, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<FileDocumentIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FilesUsercontentContentTypePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1FilesUsercontentContentTypePost(params: {
    contentType: null | string;
    body?: { files?; formFieldsPrefix?: string; ownerEntityId?: string };
  }): Observable<FileDocumentIEnumerableApiResponse> {
    return this.apiV1FilesUsercontentContentTypePost$Response(params).pipe(
      map((r: StrictHttpResponse<FileDocumentIEnumerableApiResponse>) => r.body as FileDocumentIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1FilesUsercontentOwnerEntityIdContentTypeGet
   */
  static readonly ApiV1FilesUsercontentOwnerEntityIdContentTypeGetPath =
    '/api/v1/files/usercontent/{ownerEntityId}/{contentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FilesUsercontentOwnerEntityIdContentTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FilesUsercontentOwnerEntityIdContentTypeGet$Response(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    file?: null | string;
    redirect?: boolean;
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FilesService.ApiV1FilesUsercontentOwnerEntityIdContentTypeGetPath,
      'get',
    );
    if (params) {
      rb.path('ownerEntityId', params.ownerEntityId, {});
      rb.path('contentType', params.contentType, {});
      rb.query('file', params.file, {});
      rb.query('redirect', params.redirect, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FilesUsercontentOwnerEntityIdContentTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FilesUsercontentOwnerEntityIdContentTypeGet(params: {
    ownerEntityId: null | string;
    contentType: null | string;
    file?: null | string;
    redirect?: boolean;
  }): Observable<StringApiResponse> {
    return this.apiV1FilesUsercontentOwnerEntityIdContentTypeGet$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }

  /**
   * Path part for operation apiV1FilesLessoncontentOwnerEntityIdContentTypeGet
   */
  static readonly ApiV1FilesLessoncontentOwnerEntityIdContentTypeGetPath =
    '/api/v1/files/lessoncontent/{ownerEntityId}/{contentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FilesLessoncontentOwnerEntityIdContentTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FilesLessoncontentOwnerEntityIdContentTypeGet$Response(params: {
    ownerEntityId: null | string;
    contentType: null | string;
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FilesService.ApiV1FilesLessoncontentOwnerEntityIdContentTypeGetPath,
      'get',
    );
    if (params) {
      rb.path('ownerEntityId', params.ownerEntityId, {});
      rb.path('contentType', params.contentType, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FilesLessoncontentOwnerEntityIdContentTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FilesLessoncontentOwnerEntityIdContentTypeGet(params: {
    ownerEntityId: null | string;
    contentType: null | string;
  }): Observable<StringApiResponse> {
    return this.apiV1FilesLessoncontentOwnerEntityIdContentTypeGet$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }
}
