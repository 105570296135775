import { Component, ElementRef, Input, OnInit } from '@angular/core';

export enum ButtonType {
  filled,
  notFilled,
  warnFilled,
  warnNotFilled,
  infoPopupFilled,
}

@Component({
  selector: 'ezteach-btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  buttonType = ButtonType;
  @Input() type = ButtonType.filled;
  @Input() width = '';
  @Input() height = '';
  @Input() borderRadius = '';
  @Input() disabled = false;
  @Input() maxWidth = '';
  @Input() fontSize = '';
  @Input() padding = '';
  nativeElement: HTMLElement;

  constructor(element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  ngOnInit(): void {}
}
