<div
  class="background__wrapper"
  *ngIf="!wasBanned"
>
  <div
    class="container"
    *ngIf="
      (userRole === 'owner' && (calendarEvent?.statusId === scheduledLessonStatus.Initiated || calendarEvent?.statusId === scheduledLessonStatus.Accepted
        || (calendarEvent?.statusId === scheduledLessonStatus.LessonStarted && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending || activeLesson?.lessonStatusId === chatLessonStatusEnum.ReadyToStart))
      )) ||
      (userRole === 'member' &&
        calendarEvent?.statusId === scheduledLessonStatus.LessonStarted &&
        !lessonFinished &&
        !isSpeech &&
        !numberOfMembersExceeded &&
        !isWrongTutor)
    "
  >
    <div
      *ngIf="!showSettings || (showSettings && isMobile)"
      class="container__settings-icon"
      (click)="showSettingsClick()"
    >
      <svg-icon-sprite
        [src]="'icon-settings2'"
        [width]="'23px'"
        [height]="'24px'"
        [viewBox]="'0 0 27 27'"
      ></svg-icon-sprite>
    </div>
    <div
      *ngIf="showSettings"
      (click)="hideSettingsClick()"
      class="container__back-icon"
    >
      <svg-icon-sprite
        [src]="'icon-arrow-back'"
        [width]="'23px'"
        [height]="'24px'"
        [viewBox]="'0 0 27 27'"
      ></svg-icon-sprite>
    </div>

    <div class="wrapper-without-bg">
      <div class="container__w-inner-container">
        <div>
          <div
            *ngIf="userRole === 'owner'"
            class="container__header header"
          >

            <ng-container *ngIf="calendarEvent?.lessonPaymentType ===  lessonPaymentTypeEnum.Free">
              {{ 'Занятие еще не началось' | transloco }}
            </ng-container>

            <ng-container *ngIf="calendarEvent?.lessonPaymentType ===  lessonPaymentTypeEnum.Payable">
              <ng-container *ngIf="calendarEvent?.statusId === scheduledLessonStatus.Accepted">
                {{ 'Ждем оплату' | transloco }}
              </ng-container>

            </ng-container>

          </div>
          <div
            *ngIf="userRole === 'member' && calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Free"
            class="container__header header"
          >
            {{ 'Занятие началось' | transloco }}!
          </div>
          <div
            *ngIf="userRole === 'member' && calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending)"
            class="container__header header"
          >
            {{ 'Идет оплата' | transloco }}!
          </div>
          <div
            *ngIf="userRole === 'owner' && (calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Free || (calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && activeLesson?.lessonStatusId === chatLessonStatusEnum.ReadyToStart))"
            class="container__w-join-title"
          >
            {{ 'Вы можете начать занятие прямо сейчас или же дождаться его начала' | transloco }}
          </div>
          <div
            *ngIf="userRole === 'owner' && calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending)"
            class="container__w-join-title"
          >
            {{ 'Ждем оплату ученика' | transloco }}
          </div>
          <div
            *ngIf="userRole === 'member' && (calendarEvent?.lessonPaymentType !== lessonPaymentTypeEnum.Payable || activeLesson?.lessonStatusId === chatLessonStatusEnum.Started)"
            class="container__w-join-title"
          >
            {{ 'Вы можете подключиться к занятию прямо сейчас' | transloco }}
          </div>
          <div
            *ngIf="userRole === 'member' && calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && activeLesson && activeLesson?.lessonStatusId === chatLessonStatusEnum.ReadyToStart"
            class="container__w-join-title"
          >
            {{ 'Ждем начала занятия' | transloco }}
          </div>
          <div class="container__w-join-date-info">
            <div
              *ngIf="!isMobile"
              class="w-info__date"
            >
              <img src="assets/img/svg/icon-date.svg" />
              {{ calendarEvent?.startDate | date: 'dd MMMM' }}
              {{ calendarEvent?.startDate | date: 'EEEE' | titlecase }}
            </div>
            <div
              *ngIf="!isMobile"
              class="w-info__time"
            >
              <img src="assets/img/svg/icon-time.svg" />
              {{ calendarEvent?.startDate | date: 'HH:mm' }} -
              {{ calendarEvent?.endDate | date: 'HH:mm' }}
            </div>
            <div
              *ngIf="isMobile"
              class="w-info__date"
            >
              <img src="assets/img/svg/icon-date.svg" />
              {{ calendarEvent?.startDate | date: 'dd MMMM' }}
              <span class="time">{{ calendarEvent?.startDate | date: 'HH:mm' }} - 
              {{ calendarEvent?.endDate | date: 'HH:mm' }}</span>
            </div>
          </div>
          <div class="container__preview">
            <div
              *ngIf="!switchCameraRotated && isMobile && videoEnabled && !showSettings"
              class="container__preview__switch-camera"
              (click)="switchCameraMobile()"
            >
              <svg-icon-sprite
                [src]="'icon-camera'"
                [width]="'15px'"
                [height]="'14px'"
                [viewBox]="'0 0 14 14'"
              ></svg-icon-sprite>
              
            </div>
            <video
              [@rotatedState]="state"
              @enterMotion
              muted
              playsinline
              *ngIf="videoEnabled"
              class="container__preview__video"
              #videoPreview
            ></video>
            <div
              @enterMotion
              *ngIf="!videoEnabled"
              class="container__preview__video-disabled"
            >
              <img
                *ngIf="user.avatarFileName; else noAvatarBlock"
                class="container__preview__video-disabled__img"
                [src]="user | userAvatar"
              />
              <ng-template #noAvatarBlock>
                <span class="no-avatar">{{ user | userInitials }}</span>
              </ng-template>
              
            </div>
            <div class="member_header">
              <div class="member_cam-mic">
                <div
                  class="member_mic-toggle"
                  *ngIf="!audioEnabled"
                >
                  <img src="assets/img/svg/group-lesson-next/mic-off.svg" />
                </div>
                <div
                  class="member_cam-toggle"
                  *ngIf="!videoEnabled"
                >
                  <img src="assets/img/svg/group-lesson-next/cam-off.svg" />
                </div>
                <div
                  class="member_mic-toggle"
                  *ngIf="audioEnabled"
                >
                  <img src="assets/img/svg/group-lesson-next/mic-on.svg" />
                </div>
                <div
                  class="member_cam-toggle"
                  *ngIf="videoEnabled"
                >
                  <img src="assets/img/svg/group-lesson-next/cam-on.svg" />
                </div>
              </div>
              <span class="member_fio">{{ user.name }}</span>
            </div>
            <div class="member_footer">
                
                <span class="member_role">{{user.profileTypeId=="Student" ? "Участник" : "Организатор" | transloco}}</span>
            </div>
          </div>
          <!--div
            *ngIf="isMobile"
            class="container__w-join-title owner"
          >
            {{ 'Вы также можете включить или отключить микрофон, камеру заранее' | transloco }}:
          </div-->
          <div class="container__join-media-btns container__w-join-media-btns">
            <div
              *ngIf="canAudio"
              class="container__join-media-btn"
              (click)="audioClicked()"
            >
              <ng-container *ngIf="audioStatusChanging">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </ng-container>
              <img
                *ngIf="!audioEnabled && !audioStatusChanging"
                src="assets/img/svg/icon-mic-off_white.svg"
                alt="audioDisabled"
              />
              <img
                *ngIf="audioEnabled && !audioStatusChanging"
                src="assets/img/svg/icon-mic-on_white.svg"
                alt="audioEnabled"
              />
            </div>
            <div
              *ngIf="!canAudio"
              class="container__join-media-btn"
            >
              <img
                src="assets/img/svg/icon-mic-off_white.svg"
                alt="audioDisabled"
              />
            </div>
            <div
              *ngIf="canVideo"
              class="container__join-media-btn"
              (click)="videoClicked()"
            >
              <ng-container *ngIf="videoStatusChanging">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </ng-container>
              <img
                *ngIf="!videoEnabled && !videoStatusChanging"
                src="assets/img/svg/icon-video-off_white.svg"
                alt="videoDisabled"
              />
              <img
                *ngIf="videoEnabled && !videoStatusChanging"
                src="assets/img/svg/icon-video-on_white.svg"
                alt="videoEnabled"
              />
            </div>
            <div
              *ngIf="!canVideo"
              class="container__join-media-btn"
            >
              <img
                src="assets/img/svg/icon-video-off_white.svg"
                alt="videoDisabled"
              />
            </div>
          </div>

          <div
            *ngIf="userRole === 'owner'"
            class="container__w-btns"
          >
            <div class="container__w-btns__btn">
              <ezteach-btn
                [width]="'100%'"
                [type]="buttonType.filled"
                (singleClick)="joinLesson()"
                [disabled]="(calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && !activeLesson) || calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending)"
                ezteachAntiQuickClick
              >
                <div content> {{ 'Начать занятие прямо сейчас' | transloco }}</div>
              </ezteach-btn>
            </div>
            <div
              *ngIf="!((calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && !activeLesson) || calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending))"
              class="container__w-btns__btn"
            >
              <ezteach-btn
                [width]="'100%'"
                [type]="buttonType.notFilled"
                (click)="back()"
              >
                <div content> {{ 'Назад на платформу' | transloco }}</div>
              </ezteach-btn>
            </div>
            <div
              *ngIf="activeLesson && (calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && !activeLesson) || calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending)"
              class="container__w-btns__btn"
            >
              <ezteach-btn
                [width]="'100%'"
                [type]="buttonType.notFilled"
                (click)="cancelLesson()"
              >
                <div content> {{ 'Отменить урок' | transloco }}</div>
              </ezteach-btn>
            </div>
          </div>

          <div
            *ngIf="userRole === 'member'"
            class="container__w-btns"
          >
            <div class="container__w-btns__btn">
              <button
                class="btn btn--blue wait container__w-btns__btn__join"
                ezteachAntiQuickClick
                (singleClick)="joinLesson()"
                [disabled]="calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (!activeLesson || activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending || activeLesson?.lessonStatusId === chatLessonStatusEnum.ReadyToStart)"
              >
                {{ 'Подключиться к занятию' | transloco }}
              </button>
            </div>
            <div
              *ngIf="!(calendarEvent?.lessonPaymentType === lessonPaymentTypeEnum.Payable && (!activeLesson || activeLesson?.lessonStatusId === chatLessonStatusEnum.Initiated || activeLesson?.lessonStatusId === chatLessonStatusEnum.PaymentPending || activeLesson?.lessonStatusId === chatLessonStatusEnum.ReadyToStart))"
              class="container__w-btns__btn"
            >
              <ezteach-btn
                [width]="'100%'"
                [type]="buttonType.notFilled"
                (click)="back()"
              >
                <div content> {{ 'Назад на платформу' | transloco }} </div>
              </ezteach-btn>
            </div>
          </div>
        </div>
      </div>

      <div
        @enterMotion
        @exitMotion
        class="container__setting-content"
        *ngIf="showSettings"
      >
        <ng-container *ngTemplateOutlet="settings"> </ng-container>
      </div>

      <!-- оставил для мобильной выпадашки в будущем -->
      <!--
        <div @exitBottonMenuMotion class="container__setting-content-mobile" *ngIf="showSettings && isMobile">
          <div>
            <ng-container
              *ngTemplateOutlet="mobileSettings; context:ctx">
            </ng-container>
          </div>

        </div>
        -->
    </div>
  </div>

  <ng-template #settings>
    <div class="container__setting-wrap">
      <div class="container__select-device">
        <div class="container__select-device-subtitle">{{ 'Настройки камеры и микрофона' | transloco }}</div>
        <div class="container__select-device-type">
          <div class="container__select-device-type-title">{{ 'Укажите Микрофон' | transloco }} </div>
          <mat-form-field class="container__select-device-type-control">
            <mat-select
              [(value)]="selectedMicrophone"
              [disabled]="!allDeviceIsLoaded"
              (selectionChange)="onSelectMicrophone($event.value)"
            >
              <mat-option
                *ngFor="let microphone of microphones"
                [value]="microphone.deviceId"
              >{{ microphone.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container__select-device-type">
          <div class="container__select-device-type-title">{{ 'Укажите Камеру' | transloco }}</div>
          <mat-form-field class="container__select-device-type-control">
            <mat-select
              [(value)]="selectedCamera"
              [disabled]="!allDeviceIsLoaded"
              (selectionChange)="onSelectCamera($event.value)"
            >
              <mat-option
                *ngFor="let camera of cameras"
                [value]="camera.deviceId"
              >{{ camera.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container__select-device-type">
          <div class="container__select-device-type-title">{{ 'Укажите Динамики' | transloco }}</div>
          <mat-form-field class="container__select-device-type-control">
            <mat-select
              [(value)]="selectedAudioOutput"
              [disabled]="!allDeviceIsLoaded"
              (selectionChange)="onSelectAudioOutput($event.value)"
            >
              <mat-option
                *ngFor="let audioOutput of audioOutputs"
                [value]="audioOutput.deviceId"
              >{{ audioOutput.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #mobileSettings>
    <div>
      <div
        @enterBottonMenuMotion
        class="container__setting-content-mobile__main"
      >
        <div class="container__setting-content-mobile__main__item">
          <div class="container__setting-content-mobile__main__item__icon">
            <img src="assets/img/svg/image-speak.svg" />
          </div>
          <div>{{ 'Динамик' | transloco }}</div>
        </div>
        <div class="container__setting-content-mobile__main__item">
          <div class="container__setting-content-mobile__main__item__icon">
            <img src="assets/img/svg/image-phone.svg" />
          </div>
          <div>{{ 'Телефон' | transloco }}</div>
        </div>
        <div class="container__setting-content-mobile__main__item">
          <div class="container__setting-content-mobile__main__item__icon">
            <img src="assets/img/svg/image-bluetooth.svg" />
          </div>
          <div>{{ 'Bluetooth' | transloco }}</div>
        </div>
        <div class="container__setting-content-mobile__main__item">
          <div class="container__setting-content-mobile__main__item__icon">
            <img src="assets/img/svg/image-sound-off.svg" />
          </div>
          <div>{{ 'Выключить вывод звука' | transloco }}</div>
        </div>
        <div
          (click)="hideSettingsClick()"
          class="container__setting-content-mobile__main__item"
        >
          <div class="container__setting-content-mobile__main__item__icon">
            <img src="assets/img/svg/image-cancel.svg" />
          </div>
          <div>{{ 'Отмена' | transloco }}</div>
        </div>
      </div>
    </div>
  </ng-template>

  <div
    class="container"
    *ngIf="userRole === 'member' && (calendarEvent?.statusId === scheduledLessonStatus.Initiated || calendarEvent?.statusId === scheduledLessonStatus.Accepted)"
  >
    <div class="wrapper-without-bg">
      <img
        src="assets/img/content/modal_call-info_board.png"
        class="board-picture"
      />
      <p class="header"> {{ 'Занятие еще не началось' | transloco }}</p>
      <p class="info__description">
        {{ 'Преподаватель еще не начал занятие' | transloco }}.<br />
        {{ 'Занятие запланировано на' | transloco }}:
      </p>
      <div class="inner__wrapper">
        <div class="lesson-parameters">
          <div class="info__date member">
            <img src="assets/img/svg/icon-date.svg" /> &nbsp;

            {{ calendarEvent?.startDate | date: 'dd MMMM' }}
            {{ calendarEvent?.startDate | date: 'EEEE' | titlecase }}
          </div>
          <div class="info__time member">
            <img src="assets/img/svg/icon-time.svg" /> &nbsp; {{ calendarEvent?.startDate | date: 'HH:mm' }} -
            {{ calendarEvent?.endDate | date: 'HH:mm' }}
          </div>
        </div>

        <div
          class="info_lesson member"
          *ngIf="calendarEvent?.tutorUser?.name"
        >
          {{ 'Преподаватель' | transloco }}:&nbsp;
          <span class="info_lesson-description">{{ calendarEvent?.tutorUser?.name }}</span>
        </div>
        <div
          class="info_lesson member"
          *ngIf="calendarEvent?.title"
        >
          {{ 'Предмет' | transloco }}:&nbsp;
          <span class="info_lesson-description">{{ calendarEvent?.title | transloco}}</span>
        </div>
        <div
          class="info_lesson member"
          *ngIf="calendarEvent?.publishingPolicy?.publisher"
        >
          {{ 'Тип занятия' | transloco }}:&nbsp;
          <span
            *ngIf="calendarEvent?.publishingPolicy?.publisher === 'Any'"
            class="info_lesson-description"
          >{{'Семинар' | transloco}}</span>
          <span
            *ngIf="calendarEvent?.publishingPolicy?.publisher !== 'Any'"
            class="info_lesson-description"
          >{{'Лекция' | transloco}}</span>
        </div>
        <div class="nav member">
          <ezteach-btn
            (click)="back()"
            [type]="buttonType.notFilled"
          >
            <div content> {{ 'Назад на платформу' | transloco }}</div>
          </ezteach-btn>
        </div>
      </div>
    </div>
  </div>

  <ezteach-popup-with-bg
    *ngIf="calendarEvent?.statusId === scheduledLessonStatus.LessonStarted && isSpeech && !isOwner && !lessonFinished"
    [smiley]="smileyType.happy"
  >
    <div title> {{ 'Занятие началось' | transloco }}!</div>
    <div content> {{ 'Поспешите, ведь занятие уже идёт' | transloco }}</div>
    <div buttons>
      <ezteach-btn
        ezteachAntiQuickClick
        (singleClick)="joinLesson()"
      >
        <div content>{{ 'Подключиться к занятию' | transloco }}</div>
      </ezteach-btn>
    </div>
  </ezteach-popup-with-bg>

  <ezteach-popup-with-bg
    *ngIf="
      calendarEvent?.statusId === scheduledLessonStatus.LessonStarted && isOwner && !lessonFinished && !isWrongTutor
      && (calendarEvent.lessonPaymentType !== lessonPaymentTypeEnum.Payable || activeLesson?.lessonStatusId === chatLessonStatusEnum.Started)
    "
    [smiley]="smileyType.happy"
  >
    <div title>{{ 'Вы уже начали занятие' | transloco }}!</div>
    <div content>{{ 'Возвращайтесь скорее' | transloco }} </div>
    <div buttons>
      <ezteach-btn
        (singleClick)="joinLesson()"
        ezteachAntiQuickClick
      >
        <div content>{{ 'Вернуться на занятие' | transloco }} </div>
      </ezteach-btn>
    </div>
  </ezteach-popup-with-bg>

  <ezteach-popup-with-bg
    [smiley]="smileyType.happy"
    *ngIf="isWrongTutor"
  >
    <div title>{{ 'Вы не можете перейти на это занятие' | transloco }} </div>
    <div content>{{ 'Эта ссылка относится к занятию другого преподавателя' | transloco }} .</div>
    <div buttons>
      <ezteach-btn
        (click)="back()"
        [type]="buttonType.notFilled"
      >
        <div content>{{ 'Назад на платформу' | transloco }}</div>
      </ezteach-btn>
    </div>
  </ezteach-popup-with-bg>

  <div
    class="container"
    *ngIf="numberOfMembersExceeded"
  >
    <div class="wrapper-with-bg">
      <img
        src="assets/img/content/bg_modal_header.jpg"
        class="bg-picture-without-smile"
      />
      <p class="header">{{ 'Комната заполнена' | transloco }}</p>
      <p class="info__description">{{ 'К сожалению, сейчас данная комната' | transloco }}<br /> {{ 'полностью заполнена'
        | transloco }}.</p>
      <div class="nav">
        <button
          (click)="back()"
          class="btn btn--blue wait"
        >
          {{ 'Назад на платформу' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="container"
    *ngIf="lessonFinished"
  >
    <div class="wrapper-with-bg">
      <img
        src="assets/img/content/bg_modal_header.jpg"
        class="bg-picture-without-smile"
      />
      <p class="header"> {{ 'Занятие уже закончилось' | transloco }} </p>
      <p class="info__description">
        {{ 'К сожалению, вы не успели на занятие, для' | transloco }} <br />
        {{ 'возвращения на платформу нажмите' | transloco }} <br />
        {{ 'на кнопку' | transloco }} :
      </p>
      <div class="nav">
        <button
          (click)="back()"
          class="btn btn--blue wait"
        >
          {{ 'Назад на платформу' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="container"
    *ngIf="notJoined"
  >
    <div class="wrapper-with-bg">
      <img
        src="assets/img/content/bg_modal_header.jpg"
        class="bg-picture-without-smile"
      />
      <p class="header"> {{ 'Ссылка недоступна' | transloco }}</p>
      <p class="info__description">
        {{ 'Возможно, Вы ещё не являетесь участником занятия' | transloco }}.<br />
        {{ 'Вы можете зайти на занятие через ссылку-приглашение' | transloco }}
      </p>
      <div class="nav">
        <button
          (click)="back()"
          class="btn btn--blue"
        >
          {{ 'Назад на платформу' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <ezteach-not-available-waitid-popup *ngIf="isNotAvailableWaitId"> </ezteach-not-available-waitid-popup>
</div>

<div
  class="background__wrapper"
  *ngIf="wasBanned"
>
  <div class="container">
    <div class="wrapper-with-bg">
      <img
        src="assets/img/content/bg_modal_header.jpg"
        class="bg-picture-without-smile"
      />
      <p class="header"> {{ 'Ссылка недоступна' | transloco }}</p>
      <p class="info__description">
        {{ 'Ссылка на данное занятие больше не' | transloco }} <br />
        {{ 'активна, преподаватель посчитал' | transloco }} <br />
        {{ 'нужным исключить вас с занятия' | transloco }} .
      </p>
      <div class="nav">
        <button
          (click)="back()"
          class="btn btn--blue wait"
        >
          {{ 'Вернуться на платформу' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>