import {
  Directive,
  Input,
  Output,
  EventEmitter,
  HostListener,
  HostBinding,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { coerceBooleanProperty } from './utils';

@Directive({
  selector: '[appFileDrop]',
})
export class AppFileDropDirective {
  @Input() disabled: boolean;

  private _multiple = false;
  @Input()
  set multiple(multiple: boolean) {
    this._multiple = coerceBooleanProperty(multiple);
  }

  @Output() fileDrop: EventEmitter<FileList | File> = new EventEmitter<FileList | File>();

  @HostBinding('attr.multiple')
  get multipleBinding(): string {
    return this._multiple ? '' : undefined;
  }

  @HostBinding('attr.disabled')
  get disabledBinding(): string {
    return this.disabled ? '' : undefined;
  }

  constructor(private _renderer: Renderer2, private _element: ElementRef) {}

  @HostListener('drop', ['$event'])
  onDrop(event: Event): void {
    if (!this.disabled) {
      const transfer: DataTransfer = (<DragEvent>event).dataTransfer;
      const files: FileList = transfer.files;
      if (files.length) {
        const value: FileList | File = this._multiple ? (files.length > 1 ? files : files[0]) : files[0];
        this.fileDrop.emit(value);
      }
    }
    this._renderer.removeClass(this._element.nativeElement, 'drop-zone');
    this._stopEvent(event);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: Event): void {
    const transfer: DataTransfer = (<DragEvent>event).dataTransfer;
    transfer.dropEffect = this._typeCheck(transfer.types) as any;
    if (
      this.disabled ||
      (!this._multiple && ((transfer.items && transfer.items.length > 1) || (<any>transfer).mozItemCount > 1))
    ) {
      transfer.dropEffect = 'none';
    } else {
      transfer.dropEffect = 'copy';
    }
    this._stopEvent(event);
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: Event): void {
    if (!this.disabled) {
      this._renderer.addClass(this._element.nativeElement, 'drop-zone');
    }
    this._stopEvent(event);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: Event): void {
    this._renderer.removeClass(this._element.nativeElement, 'drop-zone');
    this._stopEvent(event);
  }

  private _typeCheck(types: string[] | DOMStringList | ReadonlyArray<string>): string {
    let dropEffect = 'none';
    if (types) {
      if (
        ((<any>types).contains && (<any>types).contains('Files')) ||
        ((<any>types).indexOf && (<any>types).indexOf('Files') !== -1)
      ) {
        dropEffect = 'copy';
      }
    }
    return dropEffect;
  }

  private _stopEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
