<div
  class="lessons_item is-history showMessages"
  [class]="{ hasVideo: file }"
  *ngIf="item"
>
  <div class="lessons_item__left">
    <div class="lesson">
      <div class="lesson__date">
        {{ date }} <span>{{ time }}</span>
      </div>
      <div class="lesson__name">{{ (me | userOppositeRoleOrMe: lesson)?.name }}</div>
      <div class="lesson__image">
        <img
          *ngIf="item | userAvatar; else noUserAvatar"
          src="{{ me | userOppositeRoleOrMe: lesson | userAvatar }}"
        />
        <ng-template #noUserAvatar>
          <span>{{ me | userOppositeRoleOrMe: lesson | userInitials }}</span>
        </ng-template>
      </div>
      <div
        class="lesson__video"
        *ngIf="!hideVideo"
      >
        <video
          src="{{ video }}"
          type='video/x-matroska; codecs="theora, vorbis"'
          autoplay
          controls
          (onerror)="failed()"
        ></video>
      </div>
    </div>
  </div>
  <div class="lessons_item__right">
    <messages_top
      class="messages__top"
      [roomId]="item.chatRoomId"
      [roomUser]="roomUser"
      *ngIf="messages"
    ></messages_top>
    <messages_list
      class="messages_list"
      [roomId]="item.chatRoomId"
      [messages]="messages"
      *ngIf="messages"
    ></messages_list>
  </div>
  <div
    class="lessons_item__footer"
    *ngIf="file"
  >
    <a
      href="{{ videoLink }}"
      class="lessons_item_file"
      target="_blank"
      (click)="updateLink()"
    >
      <span class="lessons_item_file__name"> {{ 'Скачать запись события ' | transloco }}</span>
      <span class="lessons_item_file__icon">
        <img src="/assets/img/svg/icon-cloud.svg" />
      </span>
    </a>
  </div>
</div>
