import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorUserCodes } from '@ezteach/api/constants/api-error-user-codes.constants';
import {
  ChatRequest,
  LessonPaymentTypeEnum,
  NotificationStatusEnum,
  ScheduledLessonStatusEnum,
  User,
  UserNotificationPagedApiResponse,
} from '@ezteach/api/models';
import { UserNotificationLesson } from '@ezteach/api/models/user-notification-lesson';
import { LessonsService, UsersService } from '@ezteach/api/services';
import { ModalSimpleError } from '@ezteach/modals/modal-simple-error/modal-simple-error.component';
import { ActiveService } from '@ezteach/_services/active.service';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { RequestsService } from '@ezteach/_services/requests.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, expand, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '../api/services/notifications.service';
import { SignalrService } from '../_services/signalr.service';
import { CalendarService } from '@ezteach/calendar';
import { UserService } from "@ezteach/_services/user.service";

export const NOTIFICATION_IDS_NEW_KEY = 'notificationIdsNew';
@UntilDestroy()
@Component({
  selector: 'ezteach-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private readonly pageSize = 10;
  pageNumber = 1;
  response: UserNotificationPagedApiResponse | null;
  notes: UserNotificationLesson[] = [];
  newIds: number[] = [];
  isEmptyCards = false;
  userdata: User;
  isTutor = false;
  private readonly load$ = new Subject<number>();
  private readonly delete$ = new Subject<number>();
  private readonly archive$ = new Subject<number>();
  readonly statusesNew = [NotificationStatusEnum.Initiated, NotificationStatusEnum.Delivered];
  readonly statusesArchive = [NotificationStatusEnum.Viewed, NotificationStatusEnum.Archived];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly notificationsService: NotificationsService,
    private readonly calendarService: CalendarService,
    private readonly signalrService: SignalrService,
    private readonly usersService: UsersService,
    private readonly requestsService: RequestsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly lessonsService: LessonsService,
    private readonly activeService: ActiveService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userdata = this.userService.userData$.value
    this.isTutor = this.userdata.isTutor;
    this.subscribeLoad();
    this.subscribeDelete();
    this.subscribeArchive();
    this.subscribeSignalR();
    this.subscribeRequests();
    this.subscribeCards();
    this.newIds = this.localStorageService.get(NOTIFICATION_IDS_NEW_KEY);
    this.load$.next(this.pageNumber);
  }

  ngOnDestroy(): void {
    this.localStorageService.set(NOTIFICATION_IDS_NEW_KEY, []);
  }

  get hasMoreNotifications() {
    return this.response && this.response.totalPages > this.response.pageNumber;
  }

  delete(id: number, itemsCount: number): void {
    this.delete$.next(id);
    // Когда удаляют последний элемент (кол-во элементов до удаления === 1), догружаем уведомления начиная с 1 страницы
    if (itemsCount === 1 && this.hasMoreNotifications) {
      this.load$.next((this.pageNumber = 1));
    }
  }

  archive(id: number): void {
    this.archive$.next(id);
  }

  cancelLesson(notification: UserNotificationLesson) {
    return this.calendarService
      .apiV1CalendarLessonsLessonIdCancelPatch({ lessonId: notification.lesson.id })
      .pipe(
        tap(({ data }) => {
          this.changeLessonStatus(notification.id, data.statusId);
          this.cdr.detectChanges();
          this.signalrService.updateNotes();
        }),
      )
      .toPromise();
  }

  acceptLessonByType(item: UserNotificationLesson, isQuickLesson: boolean) {
    this.viewNotification(item.id).pipe(untilDestroyed(this), first()).subscribe();
    if (isQuickLesson) {
      this.acceptQuickLesson(item);
    } else {
      this.acceptLesson(item);
    }
  }

  cancelLessonByType(item: UserNotificationLesson, isQuickLesson: boolean) {
    this.viewNotification(item.id).pipe(untilDestroyed(this), first()).subscribe();
    if (isQuickLesson) {
      this.cancelQuickLesson(item);
    } else {
      this.cancelLesson(item);
    }
  }

  acceptLesson(notification: UserNotificationLesson) {
    return this.calendarService
      .apiV1CalendarLessonsLessonIdAcceptPatch({
        lessonId: notification.lesson.id,
        paymentType: LessonPaymentTypeEnum.Free,
      })
      .pipe(
        tap(({ data }) => {
          this.changeLessonStatus(notification.id, data.statusId);
          this.cdr.detectChanges();
          this.signalrService.updateNotes();
        }),
      )
      .toPromise();
  }

  private get isLoadedAllNewIds(): boolean {
    return this.newIds.length === 0 || this.newIds.every(id => this.notes.find(n => n.id === id));
  }

  private subscribeLoad(): void {
    this.load$
      .pipe(
        switchMap(pageNumber => this.getNotifications(pageNumber)),
        expand(() => (this.isLoadedAllNewIds ? EMPTY : this.getNotifications(++this.pageNumber))),
        map(() =>
          this.notes
            .filter(
              n =>
                n?.statusId === NotificationStatusEnum.Initiated &&
                n?.lesson.statusId !== ScheduledLessonStatusEnum.Initiated,
            )
            .map(n => n.id as number),
        ),
        filter(ids => ids.length > 0),
        switchMap(ids => forkJoin(ids.map(notificationId => this.viewNotification(notificationId)))),
        tap(() => this.signalrService.updateNotes()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeDelete(): void {
    this.delete$
      .pipe(
        tap(id => {
          this.notes = this.notes.filter(n => n.id !== id);
          this.cdr.detectChanges();
        }),
        mergeMap(id => this.deleteNotification(id)),
        filter(id => !!id),
        tap(id => {
          this.signalrService.updateNotes();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeArchive(): void {
    this.archive$
      .pipe(
        tap(id => {
          this.changeNotificationStatus(id, NotificationStatusEnum.Archived);
          this.newIds = this.newIds.filter(n => n !== id);
          this.cdr.detectChanges();
        }),
        mergeMap(id => this.archiveNotification(id)),
        filter(id => !!id),
        tap(id => {
          this.signalrService.updateNotes();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeSignalR(): void {
    this.signalrService.onUserNotificationCreated
      .pipe(untilDestroyed(this))
      .subscribe(() => this.load$.next((this.pageNumber = 1)));
  }

  private subscribeRequests() {
    this.requestsService.onChanged
      .pipe(
        untilDestroyed(this),
        tap(data => {
          const requests: ChatRequest[] = this.localStorageService.get('requests') ?? [];
          this.notes = this.notes.filter(n => requests.indexOf(n.quickLesson) !== -1 || !n.quickLesson);
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  private subscribeCards() {
    this.usersService
      .apiV1UsersUserIdProfileGet({
        userId: this.userdata.id as number,
      })
      .pipe(
        untilDestroyed(this),
        map(profile => profile.data?.bankCardBindings?.bankCards?.length === 0),
      )
      .subscribe(isEmptyCards => {
        this.isEmptyCards = isEmptyCards;
        this.cdr.detectChanges();
      });
  }

  private changeNotificationStatus(id: number, status: NotificationStatusEnum): void {
    const index = this.notes.findIndex(n => n.id === id);
    if (index >= 0) {
      const note = { ...this.notes[index], statusId: status };
      this.notes[index] = note;
      this.notes = [...this.notes];
    }
  }

  private changeLessonStatus(notificationId: number, status: ScheduledLessonStatusEnum): void {
    const index = this.notes.findIndex(n => n.id === notificationId);
    if (index >= 0) {
      const old = this.notes[index];
      const note = { ...old, lesson: { ...old.lesson, statusId: status } };
      this.notes[index] = note;
      this.notes = [...this.notes];
    }
  }

  private convertToUserNotificationLesson(request: ChatRequest): UserNotificationLesson {
    return {
      id: request.id,
      statusId: NotificationStatusEnum.Initiated,
      title: $localize`Запрос на событие`,
      quickLesson: request,
      linkedMember: request.initiator,
      user: request.callee,
    };
  }

  private getNotifications(pageNumber: number) {
    let quickLessonRequests = this.localStorageService.get('requests');
    if (quickLessonRequests && quickLessonRequests.length > 0) {
      quickLessonRequests = quickLessonRequests.map(request => this.convertToUserNotificationLesson(request));

      quickLessonRequests.forEach(note => this.newIds.push(note.id));

      this.notes = [...quickLessonRequests, ...this.notes];
    }

    return this.notificationsService
      .apiV1NotificationsGet({
        PageNumber: pageNumber,
        PageSize: this.pageSize,
      })
      .pipe(
        tap(response => {
          this.response = response;
          this.notes = this.notes.concat(response.data);
          this.cdr.detectChanges();
        }),
      );
  }

  private viewNotification(notificationId: number) {
    return this.notificationsService.apiV1NotificationsNotificationIdViewedPatch({ notificationId }).pipe(
      map(() => notificationId),
      catchError(() => of(null)),
    );
  }

  private archiveNotification(notificationId: number) {
    return this.notificationsService.apiV1NotificationsNotificationIdArchivedPatch({ notificationId }).pipe(
      map(() => notificationId),
      catchError(() => of(null)),
    );
  }

  private deleteNotification(notificationId: number) {
    return this.notificationsService.apiV1NotificationsNotificationIdDelete({ notificationId }).pipe(
      map(() => notificationId),
      catchError(() => of(null)),
    );
  }

  cancelQuickLesson(item: UserNotificationLesson) {
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdCancelPatch({
        requestId: item.quickLesson.id as number,
      })
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.localStorageService.set('requests', []);
          const notificationsDialog = this.dialog.openDialogs.find(
            dialog => dialog.componentInstance instanceof NotificationsComponent,
          );
          if (notificationsDialog) {
            notificationsDialog.close();
          }
          this.requestsService.update();
        }),
      )
      .subscribe();
  }

  acceptQuickLesson(item: UserNotificationLesson) {
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdAcceptPatch({
        requestId: item.quickLesson.id as number,
        paymentType: LessonPaymentTypeEnum.Free,
      })
      .pipe(
        untilDestroyed(this),
        tap(
          response => {
            const notificationsDialog = this.dialog.openDialogs.find(
              dialog => dialog.componentInstance instanceof NotificationsComponent,
            );
            if (notificationsDialog) {
              notificationsDialog.close();
            }

            const id = item.quickLesson.joinIdentity;
            this.activeService.update();

            this.router.navigate(['/wait'], {
              queryParams: { id },
              relativeTo: this.route,
            });
            this.localStorageService.set('requests', []);
          },
          error => {
            const errorApiResponse = error.error.Error;
            this.dialog.open(ModalSimpleError, {
              panelClass: 'modal-simple-error',
              data: {
                title: $localize`Запрос на событие не принят`,
                text: ApiErrorUserCodes[errorApiResponse.Code] ?? errorApiResponse.Description,
              },
            });
          },
        ),
      )
      .subscribe();
  }
}
