<div
  [formGroup]="form"
  class="profile_work"
>
  <div class="profile_content__title">{{ 'Данные трудовой книжки' | transloco }} </div>

  <ng-container
    formArrayName="workPlaces"
    *ngFor="let control of works.controls; let index = index"
  >
    <div
      [formGroupName]="index"
      class="workCompany"
    >
      <div class="profile_content__cols">
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label>{{ 'Учреждение' | transloco }} </mat-label>
            <input
              matInput
              formControlName="companyName"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="profile_content__cols">
        <div class="profile_content__col">
          <app-date-picker
            formControlName="enrollmentDate"
            i18n-label
            label="Начало рабочей деятельности"
            [min]="works.at(index).get('enrollmentDate').value || minDate"
            [max]="maxDate"
            [isRequired]="true"
            cssClass="profile_content__input"
          >
          </app-date-picker>
        </div>
        <div class="profile_content__col">
          <app-date-picker
            formControlName="leavingDate"
            i18n-label
            [label]="!works.at(index).get('leavingDate').value ? ('Нынешнее время' | transloco) : ('Окончание рабочей деятельности' | transloco)"
            [min]="works.at(index).get('enrollmentDate').value"
            [max]="maxDate"
            cssClass="profile_content__input"
          >
          </app-date-picker>
        </div>
      </div>
      <div class="profile_content__delete">
        <a (click)="removeWork(index)"> {{ 'Удалить запись' | transloco }} </a>
      </div>
    </div>
  </ng-container>

  <button
    class="btn btn--blue-border"
    (click)="addWork()"
  >
    <span class="btn__label">{{ 'Добавить запись' | transloco }} </span>
  </button>
  <br /><br />

  <div class="profile_content__subtitle">{{ 'Загруженные файлы трудовой книжки' | transloco }}</div>
  <div class="reference_list">
    <div class="reference_list">
      <ng-container *ngFor="let document of documents.value; let index = index">
        <div class="reference_item">
          <div class="reference_item__image">
            <img src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{ user.id }}/work-experience?redirect=true&file={{
                document.fileName
              }}&access_token={{ token }}" />
          </div>
          <div class="reference_item__content">
            <div class="reference_item__name">{{ document.displayFileName }}</div>
            <div class="reference_item__delete"><a (click)="removeFile(index)"> {{ 'Удалить' | transloco }}</a></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <input
    class="hideinput"
    multiple
    type="file"
    accept="image/jpeg,image/png,application/pdf"
    (change)="addFile($event)"
    #file
  />
  <button
    (click)="file.click()"
    class="btn btn--blue-border"
    [disabled]="uploading"
  >
    <span
      class="btn__label"
      i18n
    >{{ (!uploading ? 'Загрузить файл' : 'Загружается') | transloco }}</span>
  </button>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="setProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>