import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
  isProfileSaving,
  removeEducationFile,
  saveProfileEducation,
  selectEducationDetails,
  setProfileTitle,
  uploadEducationFile,
} from '../../+state';
import { environment } from '../../../../environments/environment';
import {
  AcademicDegree,
  AcademicDegreeIEnumerableApiResponse,
  EducationDetails,
  EducationDetailsDocument,
  FileDocumentIEnumerableApiResponse,
} from '../../../api/models';
import { DictionariesService, FilesService } from '../../../api/services';
import { getDate } from '../../../shared/helpers/helpers';
import { EducationDocumentTypes } from '../../models/file.model';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-education',
  templateUrl: './profile-education.component.html',
  styleUrls: ['./profile-education.component.scss'],
})
export class ProfileEducationComponent implements OnInit {
  user: any;
  form: UntypedFormGroup;

  maxDate = new Date();
  minDate = new Date();

  title = 'Образование';

  academicDegrees: AcademicDegree[];

  token: string;
  environment = environment;

  educationDocumentTypes = EducationDocumentTypes;

  saving$: Observable<boolean>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authStorage: OAuthStorage,
    private filesService: FilesService,
    private profileLocalStorageService: ProfileLocalStorageService,
    private dictionariesService: DictionariesService,
    private store: Store,
  ) {
    this.minDate.setFullYear(this.maxDate.getFullYear() - 50);
  }

  ngOnInit() {
    this.createForm();
    this.getAcademicDegrees();

    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.token = this.authStorage.getItem('access_token');
    this.subscribeStore();
  }

  subscribeStore() {
    this.store
      .pipe(
        untilDestroyed(this),
        select(selectEducationDetails),
        filter((educationDetails: EducationDetails) => !!educationDetails),
        take(1),
        tap((educationDetails: EducationDetails) => {
          this.updateForm(educationDetails.documents);
        }),
      )
      .subscribe();

    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving));
  }

  // todo store
  getAcademicDegrees() {
    this.dictionariesService
      .apiV1DictionariesAcademicdegreesGet()
      .subscribe((response: AcademicDegreeIEnumerableApiResponse) => {
        this.academicDegrees = response.data;
      });
  }

  getFilteredAcademicDegrees(index: number) {
    const ids = this.getControl('AcademicDegree')
      .value.map(item => +item.academicDegreeId)
      .filter((item, i) => index !== i);
    return this.academicDegrees ? this.academicDegrees.filter(item => !ids.includes(item.id)) : [];
  }

  createForm() {
    this.form = this.formBuilder.group({
      SecondaryEducation: this.formBuilder.array([]),
      HigherEducation: this.formBuilder.array([]),
      AcademicDegree: this.formBuilder.array([]),
    });
  }

  updateForm(data: EducationDetailsDocument[]) {
    this.form = this.formBuilder.group({
      SecondaryEducation: this.formBuilder.array([]),
      HigherEducation: this.formBuilder.array([]),
      AcademicDegree: this.formBuilder.array([]),
    });
    data.forEach(document => this.addControl(document.documentTypeId, document));
  }

  getControl(name: string) {
    return this.form.get(name) as UntypedFormArray;
  }

  getControlItem(controlName: string, index: number, name: string) {
    return this.getControl(controlName).at(index).get(name) as UntypedFormControl;
  }

  getControlItemValue(controlName: string, index: number, name: string) {
    return this.getControlItem(controlName, index, name).value;
  }

  addControl(name: string, document: EducationDetailsDocument = null) {
    const group: any = {
      documentTypeId: [name, Validators.required],
      documentFiles: [document ? document.documentFiles : []],
      uploading: [false],
    };

    if (name === 'AcademicDegree') {
      group.academicDegreeId = [document ? document.academicDegreeId : '', Validators.required];
      group.issueDate = [document ? document.issueDate : '', Validators.required];
    } else {
      group.educationalInstitutionName = [document ? document.educationalInstitutionName : '', Validators.required];
      group.enrollmentDate = [document ? document.enrollmentDate : '', Validators.required];
      group.graduateDate = [document ? document.graduateDate : '', Validators.required];
    }

    this.getControl(name).push(this.formBuilder.group(group));
  }

  removeControl(name: string, index: number) {
    this.getControl(name).removeAt(index);
  }

  setProfile() {
    const value = this.form.value;
    value.SecondaryEducation = value.SecondaryEducation.map(document => ({
      ...document,
      enrollmentDate: getDate(document.enrollmentDate),
      graduateDate: getDate(document.graduateDate),
    }));
    value.HigherEducation = value.HigherEducation.map(document => ({
      ...document,
      enrollmentDate: getDate(document.enrollmentDate),
      graduateDate: getDate(document.graduateDate),
    }));
    value.AcademicDegree = value.AcademicDegree.map(document => ({
      ...document,
      academicDegreeId: +document.academicDegreeId,
      issueDate: getDate(document.issueDate),
    }));

    const educationDetails = {
      documents: Object.values(value).flat() as EducationDetailsDocument[],
    };

    this.store.dispatch(saveProfileEducation(educationDetails));
  }

  addFile(event: any, name: string, index: number) {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;
      const form = new FormData();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < files.length; x++) {
        const file = files[x];
        form.append('files', file);
      }

      const controlName = name
        .split('-')
        .map(item => item[0].toUpperCase() + item.slice(1))
        .join('');
      this.getControlItem(controlName, index, 'uploading').patchValue(true);

      this.filesService
        .apiV1FilesUsercontentContentTypePost({
          contentType: name,
          body: {
            files: form.getAll('files'),
            ownerEntityId: `${this.user.id}`,
          },
        })
        .subscribe((response: FileDocumentIEnumerableApiResponse) => {
          if (response.data) {
            this.getControlItem(controlName, index, 'documentFiles').patchValue([
              ...this.getControlItemValue(controlName, index, 'documentFiles'),
              ...response.data,
            ]);
          }

          event.target.value = null;
          this.getControlItem(controlName, index, 'uploading').patchValue(false);
        });

      return;

      // todo перевести на стор
      const data = {
        contentType: name,
        file: {
          contentType: name,
          body: {
            files: form.getAll('files'),
            ownerEntityId: `${this.user.id}`,
          },
        },
      };
      this.store.dispatch(uploadEducationFile(data));
    }
  }

  removeFile(name: string, indexFile: number, parentIndex?: number) {
    const value = this.getControlItemValue(name, parentIndex, 'documentFiles').filter((file, i) => i !== indexFile);
    this.getControlItem(name, parentIndex, 'documentFiles').patchValue(value);

    this.store.dispatch(removeEducationFile({ data: this.getControlItem(name, parentIndex, 'documentFiles').value }));
  }
}
