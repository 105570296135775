export enum ChatLessonStatusEnum {
  Initiated = 'Initiated',
  PaymentPending = 'PaymentPending',
  ReadyToStart = 'ReadyToStart',
  Started = 'Started',
  FinishedByTimer = 'FinishedByTimer',
  FinishedByStudent = 'FinishedByStudent',
  FinishedByTutor = 'FinishedByTutor',
  ReclamationBegun = 'ReclamationBegun',
  ReclamationSubmitted = 'ReclamationSubmitted',
  Finalized = 'Finalized',
  CanceledByStudent = 'CanceledByStudent',
  CanceledByTutor = 'CanceledByTutor',
}

export enum ChatLessonSocketStatusEnum {
  // пендинги
  Initiated = 1,
  PaymentPending = 2,
  ReadyToStart = 9,
  Started = 10,
  FinishedByTimer = 200,
  FinishedByStudent = 201,
  FinishedByTutor = 210,
  ReclamationBegun = 250,
  // конечные состояния
  ReclamationSubmitted = 260,
  Finalized = 300,
  CanceledByStudent = 501,
  CanceledByTutor = 502,
}
