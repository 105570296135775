import { Pipe, PipeTransform } from '@angular/core';
import { CachedImagesRegistryService } from '../services';

@Pipe({ name: 'cachedImage' })
export class CachedImagePipe implements PipeTransform {
  constructor(private readonly imageCache: CachedImagesRegistryService) {}

  transform(path: string): string {
    const cachedImage = this.imageCache.getImageFromRegistry(path);

    return cachedImage || path;
  }
}
