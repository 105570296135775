import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '@ezteach/_services/user.service';
import {
  ProcessLessonPaymentModeEnum,
  ProcessLessonPaymentResultTypeEnum,
  ScheduledLessonStatusEnum,
} from '@ezteach/api/models';
import { getStudentUser, getTutorUser } from '@ezteach/api/utils/chat-lesson-member-replacement';
import { YookassaLoaderService } from '@ezteach/shared/services/yookassa-loader.service';
import { WINDOW } from '@ng-web-apis/common';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { LessonsService } from '../../api/services/lessons.service';
import { UsersService } from '../../api/services/users.service';

@Component({
  selector: 'modal-book',
  templateUrl: 'modal-book.component.html',
})
export class ModalBook implements AfterViewInit {
  loading = false;
  confirmWidgetSrc: SafeResourceUrl = null;
  isPaymant = false;
  showCheckoutForm = false;
  scheduledLessonStatusEnum = ScheduledLessonStatusEnum;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, //data {item:ChatLesson, }
    public dialogRef: MatDialogRef<ModalBook, ModalBookResult>,
    private usersService: UsersService,
    private lessonsService: LessonsService,
    private yookassaLoaderService: YookassaLoaderService,
    @Inject(WINDOW) private windowRef: Window,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.initYookassa();
  }

  initYookassa() {
    if (!(this.windowRef as any).YooMoneyCheckoutWidget) {
      this.yookassaLoaderService.renderСheckoutScript().onload = () => {
        console.log('checkout loaded');
      };
    }
  }

  pay(id, joinIdentity) {
    const userdata = this.userService.userData$.value;
    this.loading = true;
    this.usersService
      .apiV1UsersUserIdFinancialsLessonsBookPost({
        userId: userdata.id,
        body: {
          ChatLessonId: id,
          JoinIdentity: joinIdentity,
        },
      })
      .subscribe(
        response => {
          this.isPaymant = true;
          if (response.data.acsToken) {
            this.addCheckOutWidget(response.data.acsToken);
          } else {
            if (response.data.resultType === ProcessLessonPaymentResultTypeEnum.RedirectToAcs) {
              this.addСonfirmWidget(response.data.acsUrl);
            } else {
              this.dialogRef.close('pay');
            }
          }
        },
        response => {
          console.log(response);
          this.dialogRef.close({
            type: 'error',
            error: response.error.Error.Description,
          });
        },
      );
  }

  close() {
    this.dialogRef.close('close');
  }

  addCheckOutWidget(confirmationToken: string) {
    this.showCheckoutForm = true;
    const windowPayout = this.windowRef as any;
    const checkout = new windowPayout.YooMoneyCheckoutWidget({
      confirmation_token: confirmationToken, // Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: environment.appUrl + '/wait?id=' + this.data.item.joinIdentity + '&booked=true', // Ссылка на страницу завершения оплаты
      customization: {
        payment_methods: ['bank_card'],
      },
      error_callback(error) {
        // Обработка ошибок инициализации
      },
    });
    checkout.render('checkout-form');
  }

  addСonfirmWidget(url: string) {
    this.confirmWidgetSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  cancel(id, joinIdentity) {
    if (this.data.statusId === this.scheduledLessonStatusEnum.Accepted || this.isPaymant) {
      const userdata = this.userService.userData$.value;
      this.usersService
        .apiV1UsersUserIdFinancialsLessonsBookPost({
          userId: userdata.id,
          body: {
            ChatLessonId: id,
            JoinIdentity: joinIdentity,
            Mode: ProcessLessonPaymentModeEnum.CancelLesson,
          },
        })
        .subscribe(x => {
          this.dialogRef.close('cancel');
        });
    } else {
      this.lessonsService
        .apiV1LessonsCancelPatchPath$Response({
          joinIdentity,
        })
        .subscribe(response => {
          this.dialogRef.close('cancel');
          this.router.navigate(['/']);
        });
    }
  }

  get name() {
    return '';
    const userdata = this.userService.userData$.value;
    if (userdata.isStudent) {
      return getTutorUser(this.data.item).name;
    } else if (userdata.isTutor) {
      return getStudentUser(this.data.item).name;
    }
    return '';
  }

  get image() {
    const userdata = this.userService.userData$.value;
    const avatarFileName = userdata?.avatarFileName;
    const path =
      environment.apiUrl +
      '/api/v1/files/usercontent/' +
      userdata.id +
      '/user-avatar?redirect=true&file=' +
      avatarFileName;
    return path;
  }

  get initials() {
    const userdata = this.userService.userData$.value;
    return userdata.firstName.charAt(0).toUpperCase() + userdata.lastName.charAt(0).toUpperCase();
  }

  get date() {
    return moment(this.data.item.lessonFinishDate).format('DD MMMM YYYY');
  }

  get duration() {
    return '';
    const duration = this.data.item.plannedDurationMinutes * 60;
    const hours = Math.floor((duration / (60 * 60)) % 24);
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);
    const time = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

    return time;
  }

  get time() {
    return moment(this.data.item.lessonFinishDate).format('HH:mm');
  }
}

export interface ModalBookResultError {
  type: 'error';
  error: string;
}
export type ModalBookResult = 'pay' | 'cancel' | 'close' | ModalBookResultError;
