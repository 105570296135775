import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileCardAddComponent } from './components/profile-card-add/profile-card-add.component';
import { ProfileCardsComponent } from './components/profile-cards/profile-cards.component';
import { ProfileEducationComponent } from './components/profile-education/profile-education.component';
import { ProfileFinancialComponent } from './components/profile-financial/profile-financial.component';
import { ProfilePassportComponent } from './components/profile-passport/profile-passport.component';
import { ProfilePatronComponent } from './components/profile-patron/profile-patron.component';
import { ProfilePersonalComponent } from './components/profile-personal/profile-personal.component';
import { ProfileReferenceComponent } from './components/profile-reference/profile-reference.component';
import { ProfileSpecialtyComponent } from './components/profile-specialty/profile-specialty.component';
import { ProfileWorkComponent } from './components/profile-work/profile-work.component';
import { ProfileComponent } from './profile.component';
import { ProfileTelegramBotComponent } from './components/profile-telegram-bot/profile-telegram-bot.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'personal',
        component: ProfilePersonalComponent,
      },
      {
        path: 'patron',
        component: ProfilePatronComponent,
      },
      {
        path: 'cards',
        component: ProfileCardsComponent,
      },
      {
        path: 'card-add',
        component: ProfileCardAddComponent,
      },
      {
        path: 'passport',
        component: ProfilePassportComponent,
      },
      {
        path: 'specialty',
        component: ProfileSpecialtyComponent,
      },
      {
        path: 'education',
        component: ProfileEducationComponent,
      },
      {
        path: 'reference',
        component: ProfileReferenceComponent,
      },
      {
        path: 'work',
        component: ProfileWorkComponent,
      },
      {
        path: 'financial',
        component: ProfileFinancialComponent,
      },
      {
        path: 'telegram-bot',
        component: ProfileTelegramBotComponent,
      },
      {
        path: '',
        redirectTo: 'personal',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
