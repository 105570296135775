import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'modal-messages-files',
  templateUrl: 'modal-messages-files.component.html',
})
export class ModalMessagesFiles {
  title;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalMessagesFiles>,
    private oauthService: OAuthService,
    private translocoService: TranslocoService,
  ) {
    if (this.data.items.groupTypeId === 'Links') {
      this.title = this.translocoService.translate('Ссылки');
    }

    if (this.data.items.groupTypeId === 'Files') {
      this.title = this.translocoService.translate('Файлы');
    }

    if (this.data.items.groupTypeId === 'Images') {
      this.title = this.translocoService.translate('Изображения');
    }
  }

  link(file, id) {
    return (
      environment.apiUrl +
      '/api/v1/chat/messages/' +
      file.chatMessageId +
      '/attachments/' +
      file.id +
      '?access_token=' +
      this.oauthService.getAccessToken()
    );
  }

  image(file, id) {
    return (
      environment.apiUrl +
      '/api/v1/chat/messages/' +
      file.chatMessageId +
      '/attachments/' +
      file.id +
      '?access_token=' +
      this.oauthService.getAccessToken()
    );
  }

  close() {
    this.dialogRef.close({
      status: 'close',
    });
  }
}
