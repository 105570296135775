import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[ezteachAntiQuickClickThrottle]',
})
export class AntiQuickClickThrottleDirective {

    @Input()
    throttleTime = 500;
    @Output()
    singleClick = new EventEmitter<MouseEvent>();

    private clicks$ = new Subject<MouseEvent>();
    private subscription: Subscription;

    @HostListener('click', ['$event'])
    OnClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks$.next(event);
    }

    ngOnInit() {
        this.subscription = this.clicks$.pipe(
            throttleTime(this.throttleTime))
            .subscribe(e => this.singleClick.emit(e));
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
