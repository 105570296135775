import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import * as dot from 'dot-object';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestBuilder } from '../api/request-builder';
import { DictionariesService } from '../api/services/dictionaries.service';
import { FilesService } from '../api/services/files.service';
import { UsersService } from '../api/services/users.service';
import { ModalBankcardsRemove } from '../modals/bankcards-remove/modal-bankcards-remove.component';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  loaded = false;
  token;
  data;
  userdata;
  currentData;
  results;
  mod;
  title;
  environment;
  loading = false;

  workError = false;
  errors = {
    personal: false,
    patron: false,
    passport: false,
    avatar: false,
    reference: false,
    price: false,
    work: false,
    education: false,
    specs: false,
  };

  discipline: string;
  disciplinesControl = new UntypedFormControl();
  disciplinesNames = [];
  disciplinesNamesId = [];
  disciplinesFiltered: Observable<string[]>;
  disciplines = [];
  disciplinesCurrent = [];
  disciplinesCurrentArray = [];
  disciplinesAll = [];
  disciplinesError = false;
  academicDegrees = [];

  educationError = false;

  patterns = {
    email: /^\S+@\S+\.\S{2,}$/,
  };

  maxDate = new Date();
  minDate = new Date();

  constructor(
    public dialog: MatDialog,
    private authStorage: OAuthStorage,
    private dateAdapter: DateAdapter<any>,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private filesService: FilesService,
    private dictionariesService: DictionariesService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService
  ) {
    route.params.subscribe((params: any) => {
      console.log('params', params);
      this.mod = params.mod;

      if (this.mod === 'common') {
        this.title = 'Общая информация';
      }

      if (this.mod === 'patron') {
        this.title = 'Данные родителя';
      }

      if (this.mod === 'passport') {
        this.title = 'Паспортные данные';
        this._cdr.detectChanges();
      }

      if (this.mod === 'specs') {
        this.title = 'Специализация';
        this._cdr.detectChanges();
      }

      if (this.mod === 'education') {
        this.title = 'Образование';
        this._cdr.detectChanges();
      }

      if (this.mod === 'reference') {
        this.title = 'Рекомендательные письма';
      }

      if (this.mod === 'work') {
        this.title = 'Трудовая история';
      }

      if (this.mod === 'bankcards' || this.mod === 'bank') {
        this.title = 'Финансовая информация';
      }
    });

    this.token = this.authStorage.getItem('access_token');
    this.minDate.setFullYear(this.maxDate.getFullYear() - 70);
  }

  ngOnInit() {
    this.dateAdapter.setLocale('ru');

    this.environment = environment;
    this.userdata = this.userService.userData$.value;

    const mod = this.route.snapshot.paramMap.get('mod');

    if (!mod) {
      this.router.navigate(['/profile-old/common'], {
        relativeTo: this.route,
      });
    } else {
      this.mod = mod;

      if (mod === 'addcard') {
        this.route.queryParams.subscribe(params => {
          if (params.orderId) {
            this.usersService
              .apiV1UsersUserIdFinancialsBankcardsFinalizePatch({
                userId: this.userdata.id,
                body: {
                  OrderId: params.orderId,
                },
              })
              .subscribe(result => {
                window.location.href = '/profile-old/bankcards';
              });
          }
        });
      }
    }

    this.usersService
      .apiV1UsersUserIdProfileGet({
        userId: this.userdata.id,
      })
      .subscribe((result: any) => {
        result.data.personalDetails.phone = (result.data.personalDetails.phone || '').slice(1);
        if (result.data.personalDetails.birthDate) {
          result.data.personalDetails.birthDate = new Date(
            result.data.personalDetails.birthDate.split('.').reverse().join('.'),
          );
        }

        console.log('result.data', result.data);

        if (result.data.studentPatron?.patron) {
          result.data.studentPatron.patron.phone = (result.data.studentPatron.patron.phone || '').slice(1);
        }

        this.currentData = result.data;
        this.loaded = true;

        console.log(this.currentData);

        this.dictionariesService.apiV1DictionariesAcademicdegreesGet().subscribe(response => {
          this.data = response.data;
          this.academicDegrees = this.data;
        });

        this.dictionariesService.apiV1DictionariesDisciplineSpecializationsGet().subscribe(response => {
          console.log('apiV1DictionariesDisciplineSpecializationsGet => response', response);
          this.data = response.data;
          this.disciplinesNames = this.data.map(value => value.discipline.name);
          this.disciplinesNamesId = this.data.map(value => value.discipline);
        });

        this.dictionariesService.apiV1DictionariesDisciplineSpecializationsFlatGet().subscribe(result => {
          console.log('apiV1DictionariesDisciplineSpecializationsFlatGet => result', result);
          if (result.data) {
            result.data.forEach(item => {
              this.disciplinesAll.push({
                id: item.discipline.id,
                name: item.discipline.name,
              });
            });

            const map = new Map();
            for (const item of this.disciplinesAll) {
              if (!map.has(item.id)) {
                map.set(item.id, true);
                this.disciplines.push({
                  id: item.id,
                  name: item.name,
                });
              }
            }

            // current disciplines
            const disciplines = [];
            this.disciplinesAll = result.data;
            this.currentData.specialityDetails?.disciplineSpecializations.forEach(item => {
              disciplines.push(
                this.disciplinesAll.filter(discipline => discipline.id === item.id).map(i => i.discipline.id)[0],
              );
            });

            const disciplinesCurrent = disciplines.filter((value, index, self) => self.indexOf(value) === index);

            this.disciplinesCurrentArray = disciplinesCurrent;

            for (const discipline of disciplinesCurrent) {
              this.disciplinesCurrent.push(this.disciplines.filter(i => i.id === discipline)[0]);
            }
          }
        });

        this._cdr.detectChanges();
      });

    this.disciplinesFiltered = this.disciplinesControl.valueChanges.pipe(
      startWith(''),
      map(discipline => this._filter(discipline)),
    );
  }

  _filter(discipline: string): string[] {
    if (discipline != '') {
      const filterValue = discipline.toLowerCase();
      const data = this.disciplinesNames.filter(discipline => discipline.toLowerCase().indexOf(filterValue) === 0);

      return data;
    } else {
      return [];
    }
  }

  addDiscipline() {
    if (this.discipline && this.discipline !== '') {
      const discipline = this.disciplinesNamesId.filter(
        discipline => discipline.name.toLowerCase().indexOf(this.discipline.toLowerCase()) === 0,
      )[0];
      if (discipline) {
        if (!this.disciplinesCurrentArray.includes(discipline.id)) {
          this.disciplinesCurrent.push(this.disciplines.filter(i => i.id === discipline.id)[0]);
        }
        this.discipline = '';
      }
    }
  }

  removeDiscipline(index) {
    delete this.disciplinesCurrent[index];
    this._cdr.detectChanges();
  }

  getSpecs(discipline) {
    return this.disciplinesAll.filter(i => i.discipline.id === discipline);
  }

  isSpec(id) {
    return this.currentData.specialityDetails.disciplineSpecializations.filter(item => item.id === id).length;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  saveSpecs() {
    let error = false;
    this.errors.specs = false;
    this.errors.price = false;

    const disciplinesElements = document.querySelectorAll('.profile_specs .discipline');
    disciplinesElements.forEach(item => {
      const oneItem = item.querySelector('input[type="checkbox"]:checked');
      if (!oneItem) {
        error = true;
      }
    });
    if (!error) {
      const specs = [];
      const specsElements = document.querySelectorAll('.profile_specs input.spec');
      specsElements.forEach(item => {
        if ((item as HTMLInputElement).checked) {
          specs.push({
            id: (item as HTMLInputElement).value,
          });
        }
      });
      if (specs.length === 0) {
        this.errors.specs = true;
      }

      const instantLesson5MinuteRate = document.querySelector(
        '.profile_specs input[name=instantLesson5MinuteRate]',
      ) as HTMLInputElement;
      const scheduledLessonHourlyRate = document.querySelector(
        '.profile_specs input[name=scheduledLessonHourlyRate]',
      ) as HTMLInputElement;

      if (!this.checkValueInt(instantLesson5MinuteRate) || !this.checkValueInt(scheduledLessonHourlyRate)) {
        this.errors.price = true;
      } else {
        if (instantLesson5MinuteRate && instantLesson5MinuteRate.value != '') {
          this.currentData.specialityDetails.instantLesson5MinuteRate = instantLesson5MinuteRate.value;
        }
        if (scheduledLessonHourlyRate && scheduledLessonHourlyRate.value != '') {
          this.currentData.specialityDetails.scheduledLessonHourlyRate = scheduledLessonHourlyRate.value;
        }
      }

      if (!this.errors.specs && !this.errors.price) {
        this.currentData.specialityDetails.disciplineSpecializations = specs;
        this.save('specs');
      }
    } else {
      this.disciplinesError = true;
    }
  }

  checkValue(input) {
    if (input.value === '') {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValue(event) {
    const value = event.target.value;
    if (value === '') {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  checkValueInt(input) {
    const intformat = /^[0-9 ]+$/;

    if (input.value === '' || input.value === '0' || !input.value.match(intformat)) {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValueInt(event) {
    const intformat = /^\d+$/;
    const value = event.target.value;
    if (value === '' || value === '0' || !value.match(intformat)) {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  checkValueDate(input) {
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;

    if (input.value === '' || !input.value.match(dateformat)) {
      input.closest('.mat-form-field').classList.add('mat-form-field-invalid');
      return false;
    } else {
      input.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      return true;
    }
  }

  blurValueDate(event) {
    const value = event.target.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;
    if (value === '' || !value.match(dateformat)) {
      event.target.closest('.mat-form-field').classList.add('mat-form-field-invalid');
    } else {
      event.target.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
    }
  }

  addSec() {
    const add = {
      documentFiles: [],
      documentTypeId: 'SecondaryEducation',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addSecFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'secondary-education',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                this.currentData.educationDetails.documents[i].documentFiles.push(item);
              });

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeSecFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this.currentData.educationDetails.documents[ind].documentFiles = this.currentData.educationDetails.documents[
      ind
    ].documentFiles.filter(val => val);

    this._cdr.detectChanges();
  }

  removeSec(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this.currentData.educationDetails.documents = this.currentData.educationDetails.documents.filter(val => val);

    this._cdr.detectChanges();
  }

  addHigh() {
    const add = {
      documentFiles: [],
      documentTypeId: 'HigherEducation',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addHighFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'higher-education',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                this.currentData.educationDetails.documents[i].documentFiles.push(item);
              });

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeHighFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this.currentData.educationDetails.documents[ind].documentFiles = this.currentData.educationDetails.documents[
      ind
    ].documentFiles.filter(val => val);

    this._cdr.detectChanges();
  }

  removeHigh(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this.currentData.educationDetails.documents = this.currentData.educationDetails.documents.filter(val => val);

    this._cdr.detectChanges();
  }

  addAcademic() {
    const add = {
      documentFiles: [],
      documentTypeId: 'AcademicDegree',
    };
    this.currentData.educationDetails.documents.push(add);
  }

  addAcademicFile(event) {
    if (event.target.files) {
      const i = event.srcElement.rel;
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'academic-degree',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                this.currentData.educationDetails.documents[i].documentFiles.push(item);
              });

              document.querySelector('label[for=' + event.target.id + '] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeAcademicFile(i, ind) {
    delete this.currentData.educationDetails.documents[ind].documentFiles[i];
    this.currentData.educationDetails.documents[ind].documentFiles = this.currentData.educationDetails.documents[
      ind
    ].documentFiles.filter(val => val);
    this._cdr.detectChanges();
  }

  removeAcademic(ind) {
    delete this.currentData.educationDetails.documents[ind];
    this.currentData.educationDetails.documents = this.currentData.educationDetails.documents.filter(val => val);
    this._cdr.detectChanges();
  }

  saveEducation() {
    let error = false;
    const self = this;

    document.querySelectorAll('.profile_education .group').forEach(item => {
      const id = (item as HTMLLinkElement).rel;

      if (item.classList.contains('secondary') || item.classList.contains('high')) {
        var name = item.querySelector('.name') as HTMLInputElement;
        const from = item.querySelector('.from') as HTMLInputElement;
        const to = item.querySelector('.to') as HTMLInputElement;

        if (name.value === '') {
          name.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          name.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (from.value === '') {
          from.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          from.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (!error) {
          self.currentData.educationDetails.documents[id].educationalInstitutionName = name.value;
          self.currentData.educationDetails.documents[id].enrollmentDate = from.value;
          self.currentData.educationDetails.documents[id].graduateDate = to.value;
        }
      }

      if (item.classList.contains('academic')) {
        var name = item.querySelector('.name') as HTMLInputElement;
        const date = item.querySelector('.date') as HTMLInputElement;

        if (name.value === '') {
          name.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          name.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (date.value === '') {
          date.closest('.mat-form-field').classList.add('mat-form-field-invalid');
          error = true;
        } else {
          date.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
        }

        if (!error) {
          self.currentData.educationDetails.documents[id].academicDegreeId = name.value;
          self.currentData.educationDetails.documents[id].issueDate = date.value;
        }
      }
    });

    this.currentData = self.currentData;

    if (error) {
      this.educationError = true;
    } else {
      this.educationError = false;
      this.save('education');
    }
  }

  avatarChange(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const formData = new FormData();

        formData.set('files', file, file.name);
        formData.set('ownerEntityId', this.currentData.userId);

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'user-avatar',
            body: {
              files: formData.get('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              this.currentData.userAvatar.avatarFileName = request.data[0].fileName;
              // this.save();
            }
          });
      }
    }
  }

  addReference(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=reference] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'reference-letters',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                const add = {
                  documentFiles: [],
                  documentTypeId: 'ReferenceLetter',
                };
                add.documentFiles.push(item);

                this.currentData.referenceLetters.documents.push(add);
              });

              document.querySelector('label[for=reference] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeReference(index) {
    delete this.currentData.referenceLetters.documents[index];
    this.currentData.referenceLetters.documents = this.currentData.referenceLetters.documents.filter(val => val);

    this._cdr.detectChanges();
  }

  addWork() {
    const add = {};
    this.currentData.workExperience.workPlaces.push(add);
  }

  removeWork(index) {
    delete this.currentData.workExperience.workPlaces[index];
    this.currentData.workExperience.workPlaces = this.currentData.workExperience.workPlaces.filter(val => val);

    this._cdr.detectChanges();
  }

  addPassportFile(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=passport] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'passport',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                this.currentData.passportDetails.documentFiles.push(item);
              });

              document.querySelector('label[for=passport] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removePassportFile(index) {
    delete this.currentData.passportDetails.documentFiles[index];
    this.currentData.passportDetails.documentFiles = this.currentData.passportDetails.documentFiles.filter(val => val);

    this._cdr.detectChanges();
  }

  savePassport() {
    const firstName = document.querySelector('.profile_passport input[name=firstName]') as HTMLInputElement;
    const lastName = document.querySelector('.profile_passport input[name=lastName]') as HTMLInputElement;
    const patronymic = document.querySelector('.profile_passport input[name=patronymic]') as HTMLInputElement;

    const addressDetails = document.querySelector('.profile_passport input[name=addressDetails]') as HTMLInputElement;
    const city = document.querySelector('.profile_passport input[name=city]') as HTMLInputElement;
    const region = document.querySelector('.profile_passport input[name=region]') as HTMLInputElement;

    const number = document.querySelector('.profile_passport input[name=number]') as HTMLInputElement;
    const issueDate = document.querySelector('.profile_passport input[name=issueDate]') as HTMLInputElement;
    const issueAuthority = document.querySelector('.profile_passport input[name=issueAuthority]') as HTMLInputElement;

    if (firstName && firstName.value != '') {
      this.currentData.passportDetails.firstName = firstName.value;
    }

    if (lastName && lastName.value != '') {
      this.currentData.passportDetails.lastName = lastName.value;
    }

    if (patronymic && patronymic.value != '') {
      this.currentData.passportDetails.patronymic = patronymic.value;
    }

    if (addressDetails && addressDetails.value != '') {
      this.currentData.passportDetails.registrationAddress.addressDetails = addressDetails.value;
    }

    if (city && city.value != '') {
      this.currentData.passportDetails.registrationAddress.city = city.value;
    }

    if (region && region.value != '') {
      this.currentData.passportDetails.registrationAddress.region = region.value;
    }

    if (number && number.value != '') {
      this.currentData.passportDetails.number = number.value;
    }

    if (issueDate && issueDate.value != '') {
      this.currentData.passportDetails.issueDate = issueDate.value;
    }

    if (issueAuthority && issueAuthority.value != '') {
      this.currentData.passportDetails.issueAuthority = issueAuthority.value;
    }

    this.save('passport');
  }

  addWorkFile(event) {
    if (event.target.files) {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const formData = new FormData();
        formData.set('ownerEntityId', this.currentData.userId);

        for (let x = 0; x < fileList.length; x++) {
          const file = fileList[x];
          formData.append('files', file);
        }

        document.querySelector('label[for=work] .btn__label').innerHTML = 'Загружается';

        this.filesService
          .apiV1FilesUsercontentContentTypePost({
            contentType: 'work-experience',
            body: {
              files: formData.getAll('files'),
              ownerEntityId: formData.get('ownerEntityId') + '',
            },
          })
          .subscribe(request => {
            if (request.data) {
              request.data.forEach(item => {
                this.currentData.workExperience.documentFiles.push(item);
              });

              document.querySelector('label[for=work] .btn__label').innerHTML = 'Загрузить';
            }
          });
      }
    }
  }

  removeWorkFile(index) {
    delete this.currentData.workExperience.documentFiles[index];
    this.currentData.workExperience.documentFiles = this.currentData.workExperience.documentFiles.filter(val => val);
    this._cdr.detectChanges();
  }

  selectAll(event) {
    const obj = event.target;
    setTimeout(() => {
      obj.focus();
      obj.select();
    }, 50);
  }

  saveWork() {
    let error = false;

    const workPlaces = [];
    let x = 0;

    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-.](0?[1-9]|1[012])[\/\-.]\d{4}$/;

    document.querySelectorAll('.profile_work .companyName').forEach(item => {
      const it = item as HTMLInputElement;
      const enrollmentDate = document.querySelectorAll('.profile_work .enrollmentDate')[x] as HTMLInputElement;
      const leavingDate = document.querySelectorAll('.profile_work .leavingDate')[x] as HTMLInputElement;

      if (it.value === '') {
        it.closest('.mat-form-field').classList.add('mat-form-field-invalid');
        error = true;
      } else {
        it.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      }

      if (enrollmentDate.value === '' || !enrollmentDate.value.match(dateformat)) {
        enrollmentDate.closest('.mat-form-field').classList.add('mat-form-field-invalid');
        error = true;
      } else {
        enrollmentDate.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
      }

      workPlaces.push({
        companyName: it.value,
        enrollmentDate: enrollmentDate.value,
        leavingDate: leavingDate.value,
      });

      x++;
    });

    if (error) {
      this.workError = true;
    } else {
      this.workError = false;
      if (!this.currentData.workExperience) {
        this.currentData.workExperience = {};
      }
      this.currentData.workExperience.workPlaces = workPlaces;
      this.save('work');
    }
  }

  get giverInitials() {
    if (this.userdata) {
      return this.userdata.firstName.charAt(0).toUpperCase() + this.userdata.lastName.charAt(0).toUpperCase();
    }
  }

  get givenAvatar() {
    if (this.currentData && this.currentData.userAvatar.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        this.userdata.id +
        '/user-avatar?redirect=true&file=' +
        this.currentData.userAvatar.avatarFileName
      );
    } else {
      return null;
    }
  }

  dateChanged(event: any, input: HTMLInputElement, path: string) {
    const value = event.target.value;
    let date;

    if (!value || value.length < 10) {
      date = this.minDate;
    } else {
      let [day, month, year] = value.split('.');
      if (month < 1) {
        month = '01';
      }

      if (month > 12) {
        month = 12;
      }

      const count = new Date(+year, +month, 0).getDate();

      if (day < 1) {
        day = '01';
      }

      if (day > count) {
        day = count;
      }

      date = new Date(`${year}.${month}.${day}`);
    }

    if (date < this.minDate) {
      date = this.minDate;
    } else if (date > this.maxDate) {
      date = this.maxDate;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const updateDate = (data: any, path: string, value: Date) => {
      const fields = path.split('.');
      const field = fields.pop();
      const parent = fields.reduce((d, p) => d[p], data);
      parent[field] = value;
    };

    input.value = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    updateDate(this.currentData, path, date);
  }

  savePersonal() {
    this.errors.personal = false;

    if (!this.currentData.personalDetails.firstName) {
      this.errors.personal = true;
    }

    if (!this.currentData.personalDetails.lastName) {
      this.errors.personal = true;
    }

    if (!this.currentData.personalDetails.email || !this.patterns.email.test(this.currentData.personalDetails.email)) {
      this.errors.personal = true;
    }

    if (!this.currentData.personalDetails.phone || this.currentData.personalDetails.phone.length < 10) {
      this.errors.personal = true;
    }

    if (!this.currentData.personalDetails.birthDate) {
      this.errors.personal = true;
    }

    if (!this.errors.personal) {
      this.save('common');
    }
  }

  saveReference() {
    this.save('reference');
  }

  savePatron() {
    this.errors.patron = false;

    if (!this.currentData.studentPatron.patron.firstName) {
      this.errors.patron = true;
    }

    if (!this.currentData.studentPatron.patron.lastName) {
      this.errors.patron = true;
    }

    if (
      !this.currentData.studentPatron.patron.email ||
      !this.patterns.email.test(this.currentData.studentPatron.patron.email)
    ) {
      this.errors.patron = true;
    }

    if (!this.currentData.studentPatron.patron.phone || this.currentData.studentPatron.patron.phone.length < 10) {
      this.errors.patron = true;
    }

    if (!this.errors.patron) {
      this.save('patron');
    }
  }

  saveBank() {
    const inn = document.querySelector('.profile_bank input[name=inn]') as HTMLInputElement;
    const accountNumber = document.querySelector('.profile_bank input[name=accountNumber]') as HTMLInputElement;
    const fullName = document.querySelector('.profile_bank input[name=fullName]') as HTMLInputElement;
    const shortName = document.querySelector('.profile_bank input[name=shortName]') as HTMLInputElement;
    const correspondentAccountNumber = document.querySelector(
      '.profile_bank input[name=correspondentAccountNumber]',
    ) as HTMLInputElement;
    const bic = document.querySelector('.profile_bank input[name=bic]') as HTMLInputElement;
    const swift = document.querySelector('.profile_bank input[name=swift]') as HTMLInputElement;
    const kpp = document.querySelector('.profile_bank input[name=kpp]') as HTMLInputElement;
    const address = document.querySelector('.profile_bank input[name=address]') as HTMLInputElement;

    if (inn && inn.value != '') {
      this.currentData.financialDetails.inn = inn.value;
    }

    if (accountNumber && accountNumber.value != '') {
      this.currentData.financialDetails.bankDetails.accountNumber = accountNumber.value;
    }

    if (fullName && fullName.value != '') {
      this.currentData.financialDetails.bankDetails.bank.fullName = fullName.value;
    }

    if (shortName && shortName.value != '') {
      this.currentData.financialDetails.bankDetails.bank.shortName = shortName.value;
    }

    if (correspondentAccountNumber && correspondentAccountNumber.value != '') {
      this.currentData.financialDetails.bankDetails.bank.correspondentAccountNumber = correspondentAccountNumber.value;
    }

    if (bic && bic.value != '') {
      this.currentData.financialDetails.bankDetails.bank.bic = bic.value;
    }

    if (swift && swift.value != '') {
      this.currentData.financialDetails.bankDetails.bank.swift = swift.value;
    }

    if (kpp && kpp.value != '') {
      this.currentData.financialDetails.bankDetails.bank.kpp = kpp.value;
    }

    if (address && address.value != '') {
      this.currentData.financialDetails.bankDetails.bank.address = address.value;
    }

    // console.log(this.currentData);
    this.save('bank');
  }

  addBankcard() {
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsPost({
        userId: this.userdata.id,
        body: {
          ReturnUrl: environment.appUrl + '/profile-old/addcard',
          FailUrl: environment.appUrl + '/profile-old/addcard',
        },
      })
      .subscribe(result => {
        if (result.data) {
          window.location.href = result.data;
        }
      });
  }

  removeBankcard(id) {
    this.dialog.open(ModalBankcardsRemove, {
      panelClass: 'modal-bankcards-remove',
      data: {
        id,
      },
    });
  }

  saveBankCards() {
    const defaultBank = document.querySelector('.profile_bankcards input[name=bankcard]:checked') as HTMLInputElement;
    this.currentData.bankCardBindings.defaultBankCardId = defaultBank.value;

    this.save('bankcards');
  }

  save(mod = '') {
    const data = { ...this.currentData, personalDetails: { ...this.currentData.personalDetails } };
    data.personalDetails.phone = '7' + data.personalDetails.phone;
    data.personalDetails.birthDate = moment(data.personalDetails.birthDate).format('DD.MM.YYYY');

    if (data?.studentPatron?.patron) {
      data.studentPatron.patron.phone = '7' + data.studentPatron.patron.phone;
    }

    console.log('data', data);

    const userdata = this.userService.userData$.value;
    const dotData = dot.dot(data);
    console.log('dotData', dotData);
    // return;

    this.loading = true;

    const rb = new RequestBuilder(environment.apiUrl, '/api/v1/users/{userId}/profile', 'put');
    rb.path('userId', userdata.id, {});

    const body = dotData;
    rb.body(body, 'multipart/form-data');

    this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r),
      )
      .subscribe(result => {
        if (document.querySelector('.profile_' + mod + ' .btn--submit .btn__label')) {
          document.querySelector('.profile_' + mod + ' .btn--submit .btn__label').innerHTML = 'Сохранено';
          setTimeout(() => {
            document.querySelector('.profile_' + mod + ' .btn--submit .btn__label').innerHTML = 'Сохранить изменения';
          }, 2000);
        }
        location.reload();
      });
  }
}
