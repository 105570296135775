import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextShapeToolsFormatter } from './text-shape-tools-formatter';
import { TextShapeToolsComponent } from './text-shape-tools.component';

const MAT_MODULES = [
  MatButtonModule,
  MatButtonToggleModule,
  MatButtonModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatTooltipModule,
  MatDividerModule,
];
@NgModule({
  imports: [...MAT_MODULES, CommonModule, ReactiveFormsModule],
  exports: [TextShapeToolsComponent],
  declarations: [TextShapeToolsComponent],
  providers: [TextShapeToolsFormatter],
})
export class TextShapeToolsModule {}
