import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../_services/local-storage.service';
import { UsersService } from '../api/services/users.service';
import { SignalrService } from '../_services/signalr.service';
import { UserService } from "@ezteach/_services/user.service";

@Injectable({
  providedIn: 'root',
})
export class AvailableService {
  onChanged = new EventEmitter<Boolean>();

  constructor(
    private usersService: UsersService,
    private signalRService: SignalrService,
    private localStorageService: LocalStorageService,
    private userService: UserService
  ) {}

  // TODO 06.04.2021 Get rid of nested subscribtion
  init() {
    this.signalRService.onTutorAvailabilityChanged.subscribe(() => {
      this.getData();
    });

    this.getData();
  }

  getData() {
    const userdata = this.userService.userData$.value;

    this.usersService
      .apiV1UsersUserIdAvailabilityGet({
        userId: userdata.id,
      })
      .subscribe(response => {
        this.localStorageService.set('available', response.data);
        this.onChanged.emit(true);
      });
  }
}
