import { DatePipe, TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Langs, LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { sameDay } from '@ezteach/utils';

@Pipe({
  name: 'daysRange',
})
export class DaysRangePipe implements PipeTransform {
  constructor(private langStoreService: LangStoreService) {}
  transform(value: any): string {
    const datePipe = new DatePipe(this.langStoreService.getCurrentLang() ?? Langs.RU);
    const titleCasePipe = new TitleCasePipe();
    /* If it is same day display just this day */
    if (sameDay(value.start, value.end)) {
      return `${datePipe.transform(value.start, 'dd MMMM')}, ${titleCasePipe.transform(
        datePipe.transform(value.start, 'EEEE'),
      )}`;
    }
    /* Otherwise display 2 dates */
    return `${datePipe.transform(value.start, 'dd MMMM')} - ${datePipe.transform(value.end, 'dd MMMM')}`;
  }
}
