import { Renderer2 } from '@angular/core';
import Konva from 'konva';
import { WhiteBoard } from '../../whiteboard';

export class EraserFollower {
  private renderer: Renderer2;
  private documentRef: Document;
  private canvas: any;
  private whiteBoard: WhiteBoard;
  public layer: any;
  private drawEveryFrame = 1;
  private frame = 0;
  private erasing = false;
  private listeners: any[] = [];
  private x: number;
  private y: number;
  anim: any;
  private follower: Konva.Circle;

  constructor(documentRef: Document, renderer: Renderer2, whiteBoard: WhiteBoard) {
    this.renderer = renderer;
    this.documentRef = documentRef;
    this.whiteBoard = whiteBoard;
    this.layer = new Konva.Layer();
    this.layer.listening(false);
    this.whiteBoard.stage.add(this.layer);
  }

  public enableEraserFollowerCanvas() {
    if (this.canvas === undefined) {
      this.canvas = document.createElement('canvas');
    }
    this.follower = null;
    const position = this.layer.getRelativePointerPosition();
    this.createFollower(position.x, position.y);
  }

  public disableEraserFollowerCanvas() {
    this.layer.removeChildren();
  }

  setEraseModeOnListeners() {
    const events = ['mousedown', 'touchstart'];
    events.map(event => {
      this.listeners.push(this.renderer.listen(this.documentRef.querySelector('#ez-canvas'), event, e => {
          this.erasing = true;
          this.layer.add(this.follower);
        this.frame = 0;
      }));
    });
  }

  setEraseModeOffListeners() {
    const events = ['mouseup', 'touchend'];
    events.map(event => {
      this.listeners.push(this.renderer.listen(this.documentRef.documentElement, event, () => {
        this.erasing = false;
        this.anim.stop();
      }));
    });
  }

  setEraseMoveListeners() {
    const events = ['mousemove', 'touchmove'];
    events.map(event => {
      this.listeners.push(this.renderer.listen(this.documentRef.querySelector('#ez-canvas'), event, e => {
        if (!this.erasing) return;
        if (this.frame === this.drawEveryFrame) {
          const position = this.layer.getRelativePointerPosition();
          this.animateFollower(position.x, position.y);
          this.frame = 0;
        }
        this.frame++;
      }));
    });
  }

  // Метод анимации "хвоста" до переделывания
  // private animatePoints() {
  //     this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
  //
  //     let duration = this.lineDuration * 1000 / 60;
  //     let point, lastPoint;
  //
  //
  //     for (let i = 0; i < this.points.length; i++) {
  //         point = this.points[i];
  //
  //         if (this.points[i - 1] !== undefined) {
  //             lastPoint = this.points[i - 1];
  //         } else {
  //             lastPoint = this.points[i];
  //         }
  //
  //         point.lifetime += 1;
  //
  //         if (point.lifetime > duration) {
  //             this.points.splice(i, 1);
  //             continue;
  //         }
  //
  //
  //         const inc = (point.lifetime / duration);
  //
  //
  //
  //         this.spreadRate = this.lineWidthStart * (1 - inc);
  //
  //
  //         this.context.lineJoin = "round";
  //         this.context.lineWidth = "10";
  //         this.context.lineCap = 'round';
  //         this.context.strokeStyle = '#dfdfdf';
  //
  //         this.context.beginPath();
  //
  //         this.context.moveTo(lastPoint.x, lastPoint.y);
  //         this.context.lineTo(point.x, point.y);
  //
  //         this.context.stroke();
  //         this.context.closePath();
  //
  //         this.layer.draw()
  //     }
  //
  //
  // }

  createFollower(x, y) {
    this.x = x;
    this.y = y;
    this.follower = new Konva.Circle({
      x,
      y,
      radius: 8,
      fill: '#dfdfdf',
      opacity: 0.6,
      id: 'eraserFollower',
    });
    this.anim = new Konva.Animation(this.animationFn.bind(this), this.layer);
  }

  animateFollower(x, y) {
    this.x = x;
    this.y = y;
    this.anim.start();
    this.layer.draw();
  }

  animationFn() {
    this.follower.x(this.x);
    this.follower.y(this.y);
  }

  public enableListeners() {
    
    this.setEraseModeOnListeners();
    this.setEraseModeOffListeners();
    this.setEraseMoveListeners();

    //   $('#myCanvas').on('mouseover', function(e) {});
    //   $('#myCanvas').on('mouseleave', function(e) {});

    this.renderer.listen(this.documentRef.querySelector('#ez-canvas'), 'touchstart', e => {
      if (!this.erasing) return;
      //@ts-ignore
      const touch = e.touches[0];
    });
    //this.documentRef.querySelector('#ez-canvas').addEventListener('touchend', function(e) {});
  }

  public disableListeners(){
    this.listeners.forEach(listener => listener && listener());
    this.listeners = [];
  }
}
