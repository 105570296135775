import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FullViewPortGroupLessonService {
  fullTransitionStart$ = new Subject();
  fullTransitionStop$ = new Subject();
  private _transitionFullMode = false;
  private _transitionToSimpleMode = false;
  private _asideCollapsed = false;

  constructor(@Inject(DOCUMENT) private document, private localStorageService: LocalStorageService) {
    this._asideCollapsed = this.localStorageService.get('asideIsCollapsed') ?? false;
  }

  startFullTransition() {
    this._asideCollapsed = this.localStorageService.get('asideIsCollapsed') ?? false;
    this.fullTransitionStart$.next();
    this._transitionToSimpleMode = false;
    this._transitionFullMode = true;
    window.scrollTo(0, 0);
    this.document.body.style.overflow = 'hidden';
  }

  stopFullTransition() {
    if (!this._transitionFullMode) {
      return;
    }

    this._asideCollapsed = this.localStorageService.get('asideIsCollapsed') ?? false;
    this.fullTransitionStop$.next();
    this._transitionFullMode = false;
    this._transitionToSimpleMode = true;
    this.document.body.style.overflow = '';
  }

  get isStateFullMode() {
    return this._transitionFullMode;
  }

  get isStateToSimpleMode() {
    return this._transitionToSimpleMode;
  }

  get asideCollased() {
    return this._asideCollapsed;
  }
}
