import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  ObjectCreatedArgs,
  ObjectDeletedArgs,
  ObjectLockedArgs,
  ObjectUnlockedArgs,
  ObjectUpdatedArgs,
} from '../classes/objects-args';

@Injectable()
export class WhiteBoardShapeService {
  /*
   * Для оповещения в сокеты
   */
  objectsCreated$ = new Subject<Shape | Konva.Group>();
  objectsLocked$ = new Subject<string[]>();
  objectsUnlocked$ = new Subject<string[]>();
  objectsUpdated$ = new Subject<any[]>();
  objectsDeleted$ = new Subject<any[]>();

  /*
   * Для подписок от сокетов
   */
  onObjectsCreated$ = new Subject<ObjectCreatedArgs>();
  onObjectsLocked$ = new Subject<ObjectLockedArgs>();
  onObjectsUnlocked$ = new Subject<ObjectUnlockedArgs>();
  onObjectsUpdated$ = new Subject<ObjectUpdatedArgs>();
  onObjectsDeleted$ = new Subject<ObjectDeletedArgs>();

  private lockedShapes: Map<number, string[]> = new Map<number, string[]>();
  lockedShapes$ = new BehaviorSubject<Map<number, string[]>>(new Map<number, string[]>());
  unLockedShapes$ = new BehaviorSubject<string[]>([]);

  addToLocked(locked: ObjectLockedArgs) {
    this.lockedShapes.set(locked.memberId, locked.oids);
    this.lockedShapes$.next(this.lockedShapes);
  }

  unlockShapes(unlocked: ObjectUnlockedArgs) {
    if (this.lockedShapes.values.length === 0) {
      this.unLockedShapes$.next(unlocked.oids);
    } else {
      if (this.lockedShapes.has(unlocked.memberId)) {
        this.lockedShapes.delete(unlocked.memberId);
        this.unLockedShapes$.next(unlocked.oids);
      }
    }
  }

  unlockByUser(memberId: number) {
    if (this.lockedShapes.has(memberId)) {
      this.lockedShapes$.next(this.lockedShapes);
    }
  }

  destroy() {
    this.lockedShapes$ = new BehaviorSubject<Map<number, string[]>>(new Map<number, string[]>());
    this.unLockedShapes$ = new BehaviorSubject<string[]>([]);
    this.lockedShapes = new Map<number, string[]>();
  }
}
