<mat-form-field [ngClass]="cssClass">
  <mat-label
    appMatLabelRequired
    [isRequired]="isRequired"
    >{{ label | transloco }}</mat-label
  >
  <input
    matInput
    (change)="validateValue($event, date)"
    [ngModel]="value | date: 'dd.MM.yyyy'"
    mask="00.00.0000"
    [dropSpecialCharacters]="false"
    [placeholder]="placeholder"
    [disabled]="disabled"
    #date
    readonly="{{ isReadonly }}"
  />
  <input
    matInput
    (ngModelChange)="updateValue($event)"
    [ngModel]="value"
    [matDatepicker]="picker"
    [min]="min"
    [max]="max"
    style="position: absolute; z-index: -1; opacity: 0"
    readonly="{{ isReadonly }}"
  />
  <mat-datepicker-toggle
    *ngIf="!isReadonly"
    matSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
