import { ITutorialStepConfig, OnboardingStudentSteps, StepType } from '@ezteach/tutorial/models';
import * as OnboardingStudentComponents from '@ezteach/tutorial/components/onboarding-student';

const WELCOME_STEPS_LIST = [
  OnboardingStudentSteps.WELCOME1,
  OnboardingStudentSteps.WELCOME2,
  OnboardingStudentSteps.WELCOME3,
];

export const ONBOARDING_STUDENT_STEPS_LIST: readonly ITutorialStepConfig<
  OnboardingStudentSteps,
  OnboardingStudentComponents.IWelcomeDialogData
>[] = [
  {
    name: OnboardingStudentSteps.WELCOME1,
    type: StepType.DIALOG,
    component: OnboardingStudentComponents.WelcomeDialogComponent,
    componentData: {
      steps: WELCOME_STEPS_LIST,
      stepName: OnboardingStudentSteps.WELCOME1,
    },
  },
  {
    name: OnboardingStudentSteps.WELCOME2,
    type: StepType.DIALOG,
    component: OnboardingStudentComponents.WelcomeDialogComponent,
    componentData: {
      steps: WELCOME_STEPS_LIST,
      stepName: OnboardingStudentSteps.WELCOME2,
    },
  },
  {
    name: OnboardingStudentSteps.WELCOME3,
    type: StepType.DIALOG,
    component: OnboardingStudentComponents.WelcomeDialogComponent,
    componentData: {
      steps: WELCOME_STEPS_LIST,
      stepName: OnboardingStudentSteps.WELCOME3,
    },
  },
  {
    name: OnboardingStudentSteps.STEP1,
    type: StepType.TOOLTIP,
    component: OnboardingStudentComponents.Step1TooltipComponent,
  },
  {
    name: OnboardingStudentSteps.STEP2,
    type: StepType.TOOLTIP,
    component: OnboardingStudentComponents.Step2TooltipComponent,
  },
  {
    name: OnboardingStudentSteps.STEP3,
    type: StepType.TOOLTIP,
    component: OnboardingStudentComponents.Step3TooltipComponent,
  },
  {
    name: OnboardingStudentSteps.STEP4,
    type: StepType.TOOLTIP,
    component: OnboardingStudentComponents.Step4TooltipComponent,
  },
  {
    name: OnboardingStudentSteps.STEP5,
    type: StepType.TOOLTIP,
    component: OnboardingStudentComponents.Step5TooltipComponent,
  },
  {
    name: OnboardingStudentSteps.STEP6,
    type: StepType.TOOLTIP,
    isLast: true,
    component: OnboardingStudentComponents.Step6TooltipComponent,
  },
];
