import { getOrElse, isLeft } from 'fp-ts/lib/Either';
import { Validation } from 'io-ts';
import reporter from 'io-ts-reporters';
import { NEVER, OperatorFunction, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function unwrapValidationWithError<T>(): OperatorFunction<Validation<T>, T> {
  return pipe(
    map(eitherData => {
      if (isLeft(eitherData)) {
        throw new Error(reporter.report(eitherData).join('\n'));
      }

      return getOrElse<T, T>(() => null)(eitherData);
    }),
  );
}

export function unwrapValidation<T = any>(): OperatorFunction<Validation<T>, T> {
  return pipe(map(eitherData => getOrElse<any, T>(() => null)(eitherData)));
}

export function logErrorSilently<T = any>(): OperatorFunction<T, T> {
  return pipe(
    catchError(e => {
      console.error(e);
      return NEVER;
    }),
  );
}
