import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ITooltipOverlayRef } from '@ezteach/tutorial/models';
import { OVERLAY_REF } from '@ezteach/tutorial/services';
import { TooltipNavigationStep } from '../helpers';

@Component({
  selector: 'ezteach-tutorial-tooltip-wrapper',
  template: `
    <div class="ezteach-tutorial-tooltip__header">
      <span>{{ stepProgressText }}</span>
      <a (click)="handleSkipClick()">Пропустить</a>
    </div>
    <ng-content></ng-content>
    <div class="ezteach-tutorial-tooltip__actions">
      <a
        class="ezteach-tutorial-tooltip__link-button"
        (click)="goPrevStep()"
        >Назад</a
      >
      <ezteach-button
        (click)="handleNextClick()"
        bgColor="#FFC849"
        minWidth="130px"
        >{{ nextButtonText }}</ezteach-button
      >
    </div>
  `,
  styleUrls: ['./tutorial-tooltip-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialTooltipWrapperComponent extends TooltipNavigationStep {
  @HostBinding('class.ezteach-tutorial-tooltip') className = true;

  @Input() isLastStep = false;
  @Input() isSkippedToLastStep = true;
  @Input() stepProgressText = '';
  @Input() nextButtonText = 'Далее';

  constructor(@Inject(OVERLAY_REF) protected readonly overlayRef: ITooltipOverlayRef) {
    super();
  }

  handleNextClick() {
    if (this.isLastStep) {
      this.finishTutorial();
      return;
    }

    this.goNextStep();
  }

  handleSkipClick() {
    if (this.isSkippedToLastStep) {
      this.goLastStep();
      return;
    }

    this.finishTutorial();
  }
}
