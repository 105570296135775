import { Injectable, InjectionToken } from '@angular/core';
import { ProfileService } from '@ezteach/profile';
import { pluck } from 'rxjs/operators';
import { ITutorialStepValidator } from '../models';

export const ONBOARDING_TEACHER_FINISH_STEP_VALIDATOR = new InjectionToken<ITutorialStepValidator>(
  'ONBOARDING_TEACHER_FINISH_VALIDATOR',
);

@Injectable({ providedIn: 'root' })
export class OnboardingTeacherFinishStepValidatorService implements ITutorialStepValidator {
  constructor(private readonly profileService: ProfileService) {}

  shouldSkipStep() {
    return this.profileService.validateProfile().pipe(pluck('data'));
  }
}
