<div class="history-lesson-item">
  <ng-content select="[first]"></ng-content>

  <div class="history-lesson-item__row">
    <div class="history-lesson-item__left">
      <div class="history-lesson-item__content">
        <div class="history-lesson-item__content__icon">
          <span class="history-lesson-item__content__icon__text">
            {{ disciplineNameShort }}
          </span>
        </div>
        <div class="history-lesson-item__content__info">
          <div class="history-lesson-item__content__info__name">{{ disciplineName | transloco }}{{ _id }}</div>
          <div class="history-lesson-item__content__info__subname">
            <a
              routerLink="/teachers/{{ (lesson | findTutorUser)?.id }}"
              class="history-lesson-item__content__info__subname"
            >
              {{ (lesson | findTutorUser).name }}
            </a>
          </div>
          <div class="lesson_item__info">
            <div class="lesson_item__info_icon">
              <svg-icon-sprite
                [src]="'icon-date'"
                [width]="'20px'"
                [height]="'20px'"
                [viewBox]="'0 0 20 20'"
              ></svg-icon-sprite>
            </div>
            <div
              class="history-lesson-item__content__info__date"
              [innerHtml]="lesson | lessonDateTime"
            ></div>
          </div>
          <div class="lesson_item__info">
            <div class="lesson_item__info_icon">
              <svg-icon-sprite
                [src]="'icon-card'"
                [width]="'20px'"
                [height]="'20px'"
                [viewBox]="'0 0 20 20'"
              ></svg-icon-sprite>
            </div>
            <div class="lesson_item__info_name">
              {{ lesson | lessonPaymentInfo }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="history-lesson-item__right">
      <div
        class="history-lesson-item__content__status"
        *ngIf="lesson.postProcessingCompleted && !isDateExpire"
        routerLink="/history/{{ _item?.id }}"
      >
        <div class="lesson_item_status__icon">
          <svg-icon-sprite
            [src]="'icon-video-done'"
            [width]="'29px'"
            [height]="'29px'"
            [viewBox]="'0 0 29 29'"
          ></svg-icon-sprite>
        </div>
        <div
          class="history-lesson-item__content__status__text"
          *ngIf="lesson.postProcessingCompleted"
        >
          <div class="history-lesson-item__content__status__text__lbl">{{ 'Смотреть запись' | transloco }}</div>
          <div class="history-lesson-item__content__status__text__time">{{ duration }}</div>
        </div>
      </div>

      <div
        class="history-lesson-item__content__status history-lesson-item__content__status__expire"
        *ngIf="lesson.postProcessingCompleted && isDateExpire"
      >
        <div class="lesson_item_status__icon">
          <svg-icon-sprite
            [src]="'icon-video-expire'"
            [width]="'29px'"
            [height]="'29px'"
            [viewBox]="'0 0 29 29'"
            [ngStyle]="{ opacity: '0.3' }"
          ></svg-icon-sprite>
        </div>
        <div class="history-lesson-item__content__status__text">{{ 'Время хранения истекло' | transloco }}</div>
      </div>

      <div
        class="history-lesson-item__content__status history-lesson-item__content__status__process"
        *ngIf="!lesson.postProcessingCompleted"
        routerLink="/history/{{ _item?.id }}"
      >
        <div class="lesson_item_status__icon">
          <svg-icon-sprite
            [src]="'icon-video-process'"
            [width]="'29px'"
            [height]="'29px'"
            [viewBox]="'0 0 29 29'"
          ></svg-icon-sprite>
        </div>
        <div class="history-lesson-item__content__status__text">{{ 'Запись обрабатывается' | transloco }}</div>
      </div>
    </div>
  </div>
  <div class="history-lesson-item__row">
    <div class="history-lesson-item__text-small">{{ 'Время хранения записи' | transloco }}:&#160;</div>
    <div class="history-lesson-item__lifetime">
      <div *ngIf="months && !isDateExpire">{{ months }} &#160;</div>
      <div *ngIf="days && !isDateExpire">{{ days }} &#160;</div>
      <div *ngIf="hours && !isDateExpire">{{ hours }}</div>
      <div *ngIf="unknownDuration">{{ unknownDurationText | transloco }}</div>
      <div *ngIf="isDateExpire">{{ isDateExpireText | transloco }}</div>
    </div>
  </div>
</div>
