import { Directive, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[playbackHover]',
})
export class PlaybackHoverDirective implements OnInit {
  @Input('playbackHover')
  set hint(value) {
    this._hint = value;
    if (this.isHover) {
      this.showHint();
    }
  }

  _hint: any;
  thumb: any;
  hintNode: any = null;
  isHover = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.viewContainer.createEmbeddedView(this.template);
    this.thumb = this.renderer.selectRootElement('.mat-slider-thumb');

    fromEvent(this.thumb, 'mouseover')
      .pipe(
        untilDestroyed(this),
        filter(() => !this.isHover),
        tap(() => {
          this.isHover = true;
          this.showHint();
        }),
      )
      .subscribe();

    fromEvent(this.thumb, 'mouseleave')
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.isHover = false;
          this.removeHint();
        }),
      )
      .subscribe();
  }

  showHint() {
    if (this.hintNode) {
      this.removeHint();
    }

    this.hintNode = this.renderer.createElement('div');
    const hintValue = this._hint + '';

    this.renderer.addClass(this.hintNode, 'playback-hint');
    this.renderer.setProperty(this.hintNode, 'innerText', hintValue);

    this.renderer.appendChild(this.thumb, this.hintNode);
  }

  removeHint() {
    this.renderer.removeChild(this.thumb, this.hintNode);
    this.hintNode = null;
  }
}
