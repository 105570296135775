import { Pipe, PipeTransform } from '@angular/core';
import { ChatLesson, User } from '@ezteach/api/models';
import { getOppositeUserOrMe } from '@ezteach/api/utils/chat-lesson-member-replacement';

@Pipe({ name: 'userOppositeRoleOrMe' })
export class UserOppositeRoleOrMePipe implements PipeTransform {
  transform(me: User, lessonWithMe: ChatLesson): User {
    return getOppositeUserOrMe(me, lessonWithMe);
  }
}
