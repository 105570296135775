<div *ngIf="!wId && whiteBoardMode !== whiteBoardModes.inside">
  <ezteach-btn (click)="create()">
    <div content>{{'Создать доску' | transloco}}</div>
  </ezteach-btn>
</div>
<ezteach-whiteboard
  [wId]="wId"
  [title]="title"
  [isOwner]="isOwner"
  [currentUserId]="user.id"
  [whiteBoardMode]="whiteBoardMode"
  [whiteBoardTopOffset]="whiteBoardTopOffset"
  [isArchived]="isArchived"
  (onMousePositionChanged)="onMousePositionChanged($event)"
>
</ezteach-whiteboard>

<div
  *ngIf="isUnavailable"
  class="unavailable"
>
  <div>
    {{ ' Интерактивная доска для гостя пока не доступна' | transloco }}
  </div>
</div>