import { Pipe, PipeTransform } from '@angular/core';
import { getFileSize } from '../helpers/helpers';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number) {
    return getFileSize(bytes);
  }
}
