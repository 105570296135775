import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiResponse } from '@ezteach/api/models/base-api-response';
import { ChatRoomApiResponse } from '@ezteach/api/models/chat-room-api-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { ChatMessageApiResponse } from '../models/chat-message-api-response';
import { ChatMessageAttachmentUrls } from '../models/chat-message-attachment';
import { ChatMessagePagedApiResponse } from '../models/chat-message-paged-api-response';
import { ChatRoomIEnumerableApiResponse } from '../models/chat-room-i-enumerable-api-response';
import { ChatRoomItemsApiResponse } from '../models/chat-room-items-api-response';
import { ChatRoomStatisticsApiResponse } from '../models/chat-room-statistics-api-response';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { UnReadMessagesCountByChatRoomIEnumerableApiResponse } from '../models/un-read-messages-count-by-chat-room-i-enumerable-api-response';
import { UserIEnumerableApiResponse } from '../models/user-i-enumerable-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {

  isHaveUnreadMessages = new BehaviorSubject<boolean>(false);
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1ChatPost
   */
  static readonly ApiV1ChatPostPath = '/api/v1/chat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatPost$Response(params?: { sendToUserId?: number }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatPostPath, 'post');
    if (params) {
      rb.query('sendToUserId', params.sendToUserId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatPost(params?: { sendToUserId?: number }): Observable<Int32ApiResponse> {
    return this.apiV1ChatPost$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesPost
   */
  static readonly ApiV1ChatMessagesPostPath = '/api/v1/chat/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatMessagesPost$Response(params?: {
    body?: {
      Mode?;
      ChatRoomId?: number;
      TutorUserId?: number;
      SentByUserId?: number;
      MessageText?: string;
      AttachedFileName?: string;
      AttachedPreviewFileName?: string;
      AttachedSourceFileName?: string;
    };
  }): Observable<StrictHttpResponse<ChatMessageApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessageApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatMessagesPost(params?: {
    body?: {
      Mode?;
      ChatRoomId?: number;
      TutorUserId?: number;
      SentByUserId?: number;
      MessageText?: string;
      AttachedFileName?: string;
      AttachedPreviewFileName?: string;
      AttachedSourceFileName?: string;
    };
  }): Observable<ChatMessageApiResponse> {
    return this.apiV1ChatMessagesPost$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessageApiResponse>) => r.body as ChatMessageApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatChatRoomIdMessagesGet
   */
  static readonly ApiV1ChatChatRoomIdMessagesGetPath = '/api/v1/chat/{chatRoomId}/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatChatRoomIdMessagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdMessagesGet$Response(params: {
    chatRoomId: number;
    PageNumber?: number;
    PageSize?: number;
    DescendingOrder?: boolean;
  }): Observable<StrictHttpResponse<ChatMessagePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatChatRoomIdMessagesGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('DescendingOrder', params.DescendingOrder, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessagePagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatChatRoomIdMessagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdMessagesGet(params: {
    chatRoomId: number;
    PageNumber?: number;
    PageSize?: number;
    DescendingOrder?: boolean;
  }): Observable<ChatMessagePagedApiResponse> {
    return this.apiV1ChatChatRoomIdMessagesGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagePagedApiResponse>) => r.body as ChatMessagePagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatChatRoomIdMessagesPost
   */
  static readonly ApiV1ChatChatRoomIdMessagesPostPath = '/api/v1/chat/{chatRoomId}/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatChatRoomIdMessagesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatChatRoomIdMessagesPost$Response(params?: {
    chatRoomId: number;
    body?: { messageText?: string; files?: File[] };
  }): Observable<StrictHttpResponse<ChatMessagePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatChatRoomIdMessagesPostPath, 'post');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessagePagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatChatRoomIdMessagesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatChatRoomIdMessagesPost(params: {
    chatRoomId: number;
    body?: { messageText?: string; files?: File[] };
  }): Observable<ChatMessagePagedApiResponse> {
    return this.apiV1ChatChatRoomIdMessagesPost$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagePagedApiResponse>) => r.body as ChatMessagePagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatUserDirectMessagesPost
   */
  static readonly ApiV1ChatUserDirectMessagesPostPath = '/api/v1/chat/direct/{userId}/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatUserDirectMessagesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatUserDirectMessagesPost$Response(params?: {
    userId: number;
    body?: { messageText?: string };
  }): Observable<StrictHttpResponse<ChatMessagePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatUserDirectMessagesPostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessagePagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatUserDirectMessagesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ChatUserDirectMessagesPost(params: {
    userId: number;
    body?: { messageText?: string };
  }): Observable<ChatMessagePagedApiResponse> {
    return this.apiV1ChatUserDirectMessagesPost$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagePagedApiResponse>) => r.body as ChatMessagePagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet
   */
  static readonly ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetPath =
    '/api/v1/chat/messages/{messageId}/attachments/{attachmentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet$Response(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ChatService.ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetPath,
      'get',
    );
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<void> {
    return this.apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesMessageIdGet
   */
  static readonly ApiV1ChatMessagesMessageIdGetPath = '/api/v1/chat/messages/{messageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesMessageIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdGet$Response(params: {
    messageId: number;
  }): Observable<StrictHttpResponse<ChatMessageApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatMessagesMessageIdGetPath, 'get');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatMessageApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesMessageIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdGet(params: { messageId: number }): Observable<ChatMessageApiResponse> {
    return this.apiV1ChatMessagesMessageIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessageApiResponse>) => r.body as ChatMessageApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatRoomsGet
   */
  static readonly ApiV1ChatRoomsGetPath = '/api/v1/chat/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatRoomsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatRoomsGet$Response(params?: {}): Observable<StrictHttpResponse<ChatRoomIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatRoomsGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRoomIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatRoomsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatRoomsGet(params?: {}): Observable<ChatRoomIEnumerableApiResponse> {
    return this.apiV1ChatRoomsGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomIEnumerableApiResponse>) => r.body as ChatRoomIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatRoomsGet
   */
  static readonly ApiV1ChatRoomByIdGetPath = '/api/v1/chat/rooms/{chatRoomId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatRoomByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatRoomByIdGet$Response(params?: { chatRoomId: number }): Observable<StrictHttpResponse<ChatRoomApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatRoomByIdGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRoomApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatRoomByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatRoomByIdGet(params?: { chatRoomId: number }): Observable<ChatRoomApiResponse> {
    return this.apiV1ChatRoomByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomApiResponse>) => r.body as ChatRoomApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatPotentialmembersGet
   */
  static readonly ApiV1ChatPotentialmembersGetPath = '/api/v1/chat/potentialmembers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatPotentialmembersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatPotentialmembersGet$Response(params?: {}): Observable<StrictHttpResponse<UserIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatPotentialmembersGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatPotentialmembersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatPotentialmembersGet(params?: {}): Observable<UserIEnumerableApiResponse> {
    return this.apiV1ChatPotentialmembersGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserIEnumerableApiResponse>) => r.body as UserIEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatChatRoomIdStatsGet
   */
  static readonly ApiV1ChatChatRoomIdStatsGetPath = '/api/v1/chat/{chatRoomId}/stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatChatRoomIdStatsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdStatsGet$Response(params: {
    chatRoomId: number;
  }): Observable<StrictHttpResponse<ChatRoomStatisticsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatChatRoomIdStatsGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRoomStatisticsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatChatRoomIdStatsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdStatsGet(params: { chatRoomId: number }): Observable<ChatRoomStatisticsApiResponse> {
    return this.apiV1ChatChatRoomIdStatsGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomStatisticsApiResponse>) => r.body as ChatRoomStatisticsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatChatRoomIdItemsGet
   */
  static readonly ApiV1ChatChatRoomIdItemsGetPath = '/api/v1/chat/{chatRoomId}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatChatRoomIdItemsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdItemsGet$Response(params: {
    chatRoomId: number;
    itemType?: null | string;
  }): Observable<StrictHttpResponse<ChatRoomItemsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatChatRoomIdItemsGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.query('itemType', params.itemType, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ChatRoomItemsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatChatRoomIdItemsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdItemsGet(params: {
    chatRoomId: number;
    itemType?: null | string;
  }): Observable<ChatRoomItemsApiResponse> {
    return this.apiV1ChatChatRoomIdItemsGet$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomItemsApiResponse>) => r.body as ChatRoomItemsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesUnreadGet
   */
  static readonly ApiV1ChatMessagesUnreadGetPath = '/api/v1/chat/messages/unread-messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesUnreadGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesUnreadGet$Response(params?: {}): Observable<
    StrictHttpResponse<UnReadMessagesCountByChatRoomIEnumerableApiResponse>
  > {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatMessagesUnreadGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UnReadMessagesCountByChatRoomIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesUnreadGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesUnreadGet(params?: {}): Observable<UnReadMessagesCountByChatRoomIEnumerableApiResponse> {
    return this.apiV1ChatMessagesUnreadGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UnReadMessagesCountByChatRoomIEnumerableApiResponse>) =>
          r.body as UnReadMessagesCountByChatRoomIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1ChatChatRoomIdMessagesUnreadGet
   */
  static readonly ApiV1ChatChatRoomIdMessagesUnreadGetPath = '/api/v1/chat/{chatRoomId}/messages/unread-messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatChatRoomIdMessagesUnreadGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdMessagesUnreadGet$Response(params: {
    chatRoomId: number;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatChatRoomIdMessagesUnreadGetPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatChatRoomIdMessagesUnreadGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatChatRoomIdMessagesUnreadGet(params: { chatRoomId: number }): Observable<Int32ApiResponse> {
    return this.apiV1ChatChatRoomIdMessagesUnreadGet$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesSetreadPatch
   */
  static readonly ApiV1ChatMessagesSetreadPatchPath = '/api/v1/chat/messages/setread';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesSetreadPatch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1ChatMessagesSetreadPatch$Response(params?: {
    body?: null | Array<number>;
  }): Observable<StrictHttpResponse<Int32ApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatMessagesSetreadPatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32ApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesSetreadPatch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1ChatMessagesSetreadPatch(params?: { body?: null | Array<number> }): Observable<Int32ApiResponse> {
    return this.apiV1ChatMessagesSetreadPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>) => r.body as Int32ApiResponse),
    );
  }

  /**
   * Path part for operation apiV1ChatMessagesSetreadPatch
   */
  static readonly ApiV1ChatMessagesTypingPatchPath = '/api/v1/chat/{chatRoomId}/messages/typing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesTypingPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesTypingPatch$Response(params?: {
    chatRoomId: number;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ChatService.ApiV1ChatMessagesTypingPatchPath, 'patch');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ChatMessagesTypingPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesTypingPatch(params?: { chatRoomId: number }): Observable<BaseApiResponse> {
    return this.apiV1ChatMessagesTypingPatch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  static readonly ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdUrlsGetPath =
    '/api/v1/chat/messages/{messageId}/attachments/{attachmentId}/urls';

  apiV1ChatMessagesMessageIdAttachmentsAttachmentUrlsGet(params?: {
    messageId: number;
    attachmentId: number;
  }): Observable<ChatMessageAttachmentUrls> {
    return this.apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetUrls$Response(params).pipe(
      map((r: any) => r.data as ChatMessageAttachmentUrls),
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetUrls$Response(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<ChatMessageAttachmentUrls>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ChatService.ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdUrlsGetPath,
      'get',
    );
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body),
      );
  }

  static readonly ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdPreviewGetPath =
    '/api/v1/chat/messages/{messageId}/attachments/{attachmentId}/preview';

  apiV1ChatMessagesMessageIdAttachmentsAttachmentIdPreviewGet(params?: {
    messageId: number;
    attachmentId: number;
  }): Observable<ChatMessageAttachmentUrls> {
    return this.apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetPreview$Response(params).pipe(
      map((r: any) => r.body),
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ChatMessagesMessageIdAttachmentsAttachmentIdGetPreview$Response(params: {
    messageId: number;
    attachmentId: number;
  }): Observable<StrictHttpResponse<ChatMessageAttachmentUrls>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ChatService.ApiV1ChatMessagesMessageIdAttachmentsAttachmentIdPreviewGetPath,
      'get',
    );
    if (params) {
      rb.path('messageId', params.messageId, {});
      rb.path('attachmentId', params.attachmentId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'blob',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r),
      );
  }
}
