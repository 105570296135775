import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'bookmarks',
  templateUrl: './bookmarks.component.html',
})
export class BookmarksComponent {
  pageSize = 10;
  pageNumber = 1;
  loaded = false;

  userdata;
  results;
  teachers;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    if (!this.userdata.isStudent) {
      this.router.navigateByUrl('/');
    }

    this.getResults();
  }

  getResults(): void {
    this.pageNumber = 1;

    this.usersService
      .apiV1UsersUserIdBookmarksTutorsGet({
        userId: this.userdata.id,
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;
        this.teachers = results.data;

        this._cdr.detectChanges();
      });
  }

  bookmarksUpdated(bookmarks){
    this.teachers = this.teachers.filter(teacher => bookmarks.includes(teacher.tutor.id));
    this._cdr.detectChanges();
  }

  getMore(): void {
    this.pageNumber++;

    this.usersService
      .apiV1UsersUserIdBookmarksTutorsGet({
        userId: this.userdata.id,
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;

        this.teachers = this.teachers.concat(results.data);

        this._cdr.detectChanges();
      });
  }
}
