import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from '../modals';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  openFeedbackDialog() {
    return this.dialog.open(FeedbackComponent, {
      panelClass: 'modal-feedback',
      width: '100%',
      maxWidth: '590px',
      height: 'auto',
      maxHeight: '80%',
      autoFocus: false,
    });
  }
}
