export const shapes: UiIcon = {
  name: 'shapes',
  data: `<svg  width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.7074 17.6896C14.5638 17.6896 17.6901 14.5633 17.6901 10.7069C17.6901 6.85041 14.5638 3.72412 10.7074 3.72412C6.8509 3.72412 3.72461 6.85041 3.72461 10.7069C3.72461 14.5633 6.8509 17.6896 10.7074 17.6896Z"  stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.0747 11.1724H13.3725C12.1571 11.1724 11.1719 12.1576 11.1719 13.373V21.0752C11.1719 22.2906 12.1571 23.2758 13.3725 23.2758H21.0747C22.2901 23.2758 23.2753 22.2906 23.2753 21.0752V13.373C23.2753 12.1576 22.2901 11.1724 21.0747 11.1724Z"  stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `,
};

export const rect: UiIcon = {
  name: 'rect',
  data: `<svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="24" height="24" stroke-width="2"/>
  </svg>
  `,
};

export const circle: UiIcon = {
  name: 'circle',
  data: `<svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="24" height="24" rx="11.5" stroke-width="2"/>
  </svg>
  `,
};

export const triangle: UiIcon = {
  name: 'triangle',
  data: `<svg width="24" height="24" viewBox="1 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.73603 24L14.5 1.99315L27.264 24H1.73603Z"  stroke-width="2"/>
  </svg>
  `,
};

export const mouse: UiIcon = {
  name: 'mouse',
  data: `<svg  width="24" height="24" viewBox="1 2 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.75 15H7.5"  stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 3.75V7.5"   stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 9.74951L7 6.99951"   stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.25 9.74951L23 6.99951"   stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 20.25L7 23"  stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 15L26.25 18.75L21.25 21.25L18.75 26.25L15 15Z"  stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

  `,
};

export const eraser: UiIcon = {
  name: 'eraser',
  data: `<svg width="24" fill="none" height="24" viewBox="2 1 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.9588 22.9582H9.66713L4.8338 18.1249C4.60874 17.8985 4.48242 17.5923 4.48242 17.273C4.48242 16.9538 4.60874 16.6476 4.8338 16.4212L16.9171 4.33782C17.1435 4.11277 17.4498 3.98645 17.769 3.98645C18.0882 3.98645 18.3945 4.11277 18.6209 4.33782L24.6625 10.3795C24.8876 10.6059 25.0139 10.9121 25.0139 11.2314C25.0139 11.5506 24.8876 11.8568 24.6625 12.0832L13.7875 22.9582" stroke-width="2.3" />
  <path d="M21.7492 14.8625L14.1367 7.25" stroke-width="2.3" />
  </svg>
  `,
};

export const text: UiIcon = {
  name: 'text',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path stroke-width="0.1" d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V7C21 7.55228 20.5523 8 20 8C19.4477 8 19 7.55228 19 7V5H13V19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19H11V5H5V7C5 7.55228 4.55228 8 4 8C3.44772 8 3 7.55228 3 7V5Z"/>
</svg>

  `,
};

export const sticker: UiIcon = {
  name: 'sticker',
  data: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path stroke-width="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M13.8501 3.15001H5.63646C4.26328 3.15001 3.1501 4.26319 3.1501 5.63637V18.3636C3.1501 19.7368 4.26328 20.85 5.63646 20.85H18.3637C19.7369 20.85 20.8501 19.7368 20.8501 18.3636V10.15H17.5456C15.5046 10.15 13.8501 8.4955 13.8501 6.45455V3.15001ZM22.8133 8.18684C22.8338 8.20739 22.8535 8.2286 22.8722 8.25039C23.0423 8.4481 23.1373 8.69446 23.1489 8.94742M23.1501 9.00904V18.3636C23.1501 21.0071 21.0072 23.15 18.3637 23.15H5.63646C2.99303 23.15 0.850098 21.0071 0.850098 18.3636V5.63637C0.850098 2.99294 2.99303 0.850009 5.63646 0.850009H14.9911C15.0116 0.849852 15.0322 0.850247 15.0527 0.85119C15.3497 0.864556 15.6173 0.990561 15.8139 1.1875M15.8139 1.1875L22.8133 8.18684L15.8139 1.1875ZM16.1501 6.45455V4.77635L19.2238 7.85001H17.5456C16.7749 7.85001 16.1501 7.22524 16.1501 6.45455Z"/>
</svg>

  `,
};

export const share: UiIcon = {
  name: 'share',
  data: `<svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.2899 9.29L9.28994 13.29C9.19621 13.383 9.12182 13.4936 9.07105 13.6154C9.02028 13.7373 8.99414 13.868 8.99414 14C8.99414 14.132 9.02028 14.2627 9.07105 14.3846C9.12182 14.5064 9.19621 14.617 9.28994 14.71C9.3829 14.8037 9.4935 14.8781 9.61536 14.9289C9.73722 14.9797 9.86793 15.0058 9.99994 15.0058C10.132 15.0058 10.2627 14.9797 10.3845 14.9289C10.5064 14.8781 10.617 14.8037 10.7099 14.71L14.7099 10.71C14.8982 10.5217 15.004 10.2663 15.004 10C15.004 9.7337 14.8982 9.47831 14.7099 9.29C14.5216 9.1017 14.2662 8.99591 13.9999 8.99591C13.7336 8.99591 13.4782 9.1017 13.2899 9.29V9.29Z" stroke-width="0" fill="#678AFF"/>
  <path d="M12.2795 17.4L10.9995 18.67C10.281 19.4104 9.31498 19.8605 8.28583 19.9342C7.25668 20.008 6.23638 19.7004 5.41954 19.07C4.98781 18.7142 4.63551 18.2719 4.38538 17.7715C4.13526 17.2711 3.99287 16.7238 3.96743 16.165C3.94198 15.6061 4.03404 15.0482 4.23766 14.5271C4.44128 14.0061 4.75193 13.5335 5.14954 13.14L6.56954 11.71C6.66327 11.617 6.73767 11.5064 6.78843 11.3846C6.8392 11.2627 6.86534 11.132 6.86534 11C6.86534 10.868 6.8392 10.7373 6.78843 10.6154C6.73767 10.4936 6.66327 10.383 6.56954 10.29C6.47658 10.1963 6.36598 10.1219 6.24412 10.0711C6.12226 10.0203 5.99155 9.9942 5.85954 9.9942C5.72753 9.9942 5.59683 10.0203 5.47497 10.0711C5.35311 10.1219 5.24251 10.1963 5.14954 10.29L3.87954 11.57C2.8097 12.606 2.15213 13.995 2.02891 15.4792C1.90569 16.9633 2.3252 18.4418 3.20954 19.64C3.73442 20.3209 4.39818 20.8824 5.15673 21.2871C5.91528 21.6917 6.75125 21.9304 7.60912 21.9871C8.46699 22.0439 9.32711 21.9174 10.1324 21.6162C10.9376 21.315 11.6695 20.8459 12.2795 20.24L13.6995 18.82C13.8878 18.6317 13.9936 18.3763 13.9936 18.11C13.9936 17.8437 13.8878 17.5883 13.6995 17.4C13.5112 17.2117 13.2558 17.1059 12.9895 17.1059C12.7232 17.1059 12.4678 17.2117 12.2795 17.4V17.4Z" stroke-width="0" fill="#678AFF"/>
  <path d="M19.6591 3.21998C18.4526 2.32644 16.962 1.90266 15.4659 2.0278C13.9698 2.15294 12.5704 2.81843 11.5291 3.89998L10.4491 4.99998C10.3256 5.08971 10.2224 5.20437 10.1461 5.33653C10.0698 5.46869 10.0221 5.6154 10.006 5.76717C9.99 5.91894 10.006 6.07238 10.0531 6.21757C10.1001 6.36275 10.1771 6.49644 10.2791 6.60998C10.372 6.7037 10.4826 6.7781 10.6045 6.82887C10.7264 6.87964 10.8571 6.90577 10.9891 6.90577C11.1211 6.90577 11.2518 6.87964 11.3736 6.82887C11.4955 6.7781 11.6061 6.7037 11.6991 6.60998L12.9991 5.29998C13.7137 4.55629 14.6785 4.10381 15.7072 4.02994C16.7359 3.95606 17.7555 4.26603 18.5691 4.89998C19.004 5.25578 19.3591 5.69923 19.6112 6.20144C19.8633 6.70365 20.0068 7.25334 20.0323 7.8147C20.0578 8.37606 19.9647 8.93649 19.7591 9.45947C19.5535 9.98245 19.24 10.4562 18.8391 10.85L17.4191 12.28C17.3253 12.3729 17.2509 12.4835 17.2002 12.6054C17.1494 12.7273 17.1233 12.858 17.1233 12.99C17.1233 13.122 17.1494 13.2527 17.2002 13.3746C17.2509 13.4964 17.3253 13.607 17.4191 13.7C17.512 13.7937 17.6226 13.8681 17.7445 13.9189C17.8664 13.9696 17.9971 13.9958 18.1291 13.9958C18.2611 13.9958 18.3918 13.9696 18.5136 13.9189C18.6355 13.8681 18.7461 13.7937 18.8391 13.7L20.2591 12.28C20.8632 11.6701 21.3309 10.9388 21.6311 10.1345C21.9313 9.33025 22.0573 8.47138 22.0005 7.61478C21.9438 6.75818 21.7057 5.92341 21.3021 5.16576C20.8984 4.40811 20.3384 3.74488 19.6591 3.21998V3.21998Z" stroke-width="0" fill="#678AFF"/>
  </svg>

  `,
};

export const undo: UiIcon = {
  name: 'undo',
  data: `<svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
  <path stroke-width="0.1" d="M25.2756 26.25C24.9901 26.2583 24.7103 26.1686 24.4829 25.9957C24.2554 25.8229 24.0941 25.5773 24.0256 25.3C23.4628 23.07 22.2234 21.0689 20.4776 19.5715C18.7319 18.0742 16.5654 17.1541 14.2756 16.9375V18.3375C14.2754 18.7753 14.146 19.2034 13.9037 19.568C13.6613 19.9327 13.3168 20.2177 12.9131 20.3875C12.4755 20.5887 11.989 20.6591 11.5122 20.5904C11.0354 20.5216 10.5887 20.3166 10.2256 20L3.90064 14.4875C3.6581 14.2809 3.4633 14.0242 3.32972 13.735C3.19614 13.4458 3.12695 13.131 3.12695 12.8125C3.12695 12.4939 3.19614 12.1791 3.32972 11.8899C3.4633 11.6007 3.6581 11.344 3.90064 11.1375L10.2256 5.61247C10.5934 5.29036 11.0476 5.08316 11.5319 5.01651C12.0163 4.94986 12.5095 5.02668 12.9506 5.23747C13.3543 5.4072 13.6988 5.69224 13.9412 6.0569C14.1836 6.42156 14.3129 6.84962 14.3131 7.28747V8.74997C17.7084 9.01248 20.882 10.5381 23.2076 13.0257C25.5332 15.5133 26.842 18.7822 26.8756 22.1875C26.8739 23.2369 26.7438 24.2822 26.4881 25.3C26.4197 25.5773 26.2583 25.8229 26.0309 25.9957C25.8035 26.1686 25.5237 26.2583 25.2381 26.25H25.2756ZM13.0631 14.375C15.1841 14.3592 17.2803 14.8312 19.1897 15.7545C21.0992 16.6778 22.7709 18.0278 24.0756 19.7C23.4495 17.2628 22.0243 15.1059 20.028 13.5741C18.0316 12.0422 15.5793 11.2239 13.0631 11.25C12.7316 11.25 12.4137 11.1183 12.1793 10.8839C11.9448 10.6494 11.8131 10.3315 11.8131 9.99997V7.59997L5.78814 12.8125L11.8131 18.075V15.575C11.8261 15.2522 11.9634 14.9469 12.1965 14.7232C12.4295 14.4995 12.7401 14.3747 13.0631 14.375Z"/>
  </svg>
  `,
};

export const redo: UiIcon = {
  name: 'redo',
  data: `<svg width="30" height="30" viewBox="0 0 30 30"   xmlns="http://www.w3.org/2000/svg">
  <path stroke-width="0.1" d="M4.72436 26.25C5.00991 26.2583 5.28968 26.1686 5.51712 25.9957C5.74455 25.8229 5.90593 25.5773 5.97436 25.3C6.53719 23.07 7.77659 21.0689 9.52236 19.5715C11.2681 18.0742 13.4346 17.1541 15.7244 16.9375V18.3375C15.7246 18.7753 15.854 19.2034 16.0963 19.568C16.3387 19.9327 16.6832 20.2177 17.0869 20.3875C17.5245 20.5887 18.011 20.6591 18.4878 20.5904C18.9646 20.5216 19.4113 20.3166 19.7744 20L26.0994 14.4875C26.3419 14.2809 26.5367 14.0242 26.6703 13.735C26.8039 13.4458 26.873 13.131 26.873 12.8125C26.873 12.4939 26.8039 12.1791 26.6703 11.8899C26.5367 11.6007 26.3419 11.344 26.0994 11.1375L19.7744 5.61247C19.4066 5.29036 18.9524 5.08316 18.4681 5.01651C17.9837 4.94986 17.4905 5.02668 17.0494 5.23747C16.6457 5.4072 16.3012 5.69224 16.0588 6.0569C15.8164 6.42156 15.6871 6.84962 15.6869 7.28747V8.74997C12.2916 9.01248 9.11803 10.5381 6.7924 13.0257C4.46677 15.5133 3.15801 18.7822 3.12436 22.1875C3.12608 23.2369 3.25621 24.2822 3.51186 25.3C3.5803 25.5773 3.74167 25.8229 3.96911 25.9957C4.19654 26.1686 4.47632 26.2583 4.76186 26.25H4.72436ZM16.9369 14.375C14.8159 14.3592 12.7197 14.8312 10.8103 15.7545C8.90077 16.6778 7.22909 18.0278 5.92436 19.7C6.55051 17.2628 7.97568 15.1059 9.97203 13.5741C11.9684 12.0422 14.4207 11.2239 16.9369 11.25C17.2684 11.25 17.5863 11.1183 17.8207 10.8839C18.0552 10.6494 18.1869 10.3315 18.1869 9.99997V7.59997L24.2119 12.8125L18.1869 18.075V15.575C18.1739 15.2522 18.0366 14.9469 17.8035 14.7232C17.5705 14.4995 17.2599 14.3747 16.9369 14.375Z" />
  </svg>
  `,
};

export const pen: UiIcon = {
  name: 'pen',
  data: `<svg width="24" height="24" viewBox="0 2 26 26" xmlns="http://www.w3.org/2000/svg">
  <path stroke-width="0.1" d="M24.8971 9.23956L21.3579 5.70039C20.896 5.26652 20.2907 5.01757 19.6572 5.00089C19.0237 4.98422 18.4062 5.201 17.9221 5.60998L6.2971 17.235C5.87958 17.656 5.61962 18.2079 5.56085 18.7979L5.00543 24.1841C4.98803 24.3733 5.01258 24.564 5.07732 24.7426C5.14207 24.9213 5.24542 25.0834 5.38001 25.2175C5.50071 25.3372 5.64384 25.4319 5.80122 25.4962C5.95859 25.5605 6.1271 25.593 6.2971 25.5921H6.41335L11.7996 25.1012C12.3896 25.0425 12.9415 24.7825 13.3625 24.365L24.9875 12.74C25.4387 12.2633 25.6826 11.6272 25.6656 10.9711C25.6487 10.315 25.3723 9.6923 24.8971 9.23956ZM11.5671 22.5179L7.6921 22.8796L8.04085 19.0046L15.3388 11.7971L18.8263 15.2846L11.5671 22.5179ZM20.5054 13.5537L17.0438 10.0921L19.5625 7.50873L23.0888 11.035L20.5054 13.5537Z"/>
  </svg>
  `,
};

export const enableFullScreen: UiIcon = {
  name: 'enableFullScreen',
  data: `<svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
          <path stroke-width="0.1" d="M0 0L2.34817 8.7635L8.7635 2.34817L0 0ZM22 22L19.6518 13.2365L13.2365 19.6518L22 22ZM4.44467 5.55584L16.4442 17.5553L17.5553 16.4442L5.55584 4.44467L4.44467 5.55584Z"/>
        </svg>`,
};

export const disableFullScreen: UiIcon = {
  name: 'disableFullScreen',
  data: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-width="0.1" d="M12 12L9.65183 3.2365L3.2365 9.65183L12 12ZM7.55533 6.44416L1.55558 0.444416L0.444416 1.55558L6.44416 7.55533L7.55533 6.44416Z" />
          <path stroke-width="0.1" d="M12 12L14.3482 20.7635L20.7635 14.3482L12 12ZM16.4447 17.5558L22.4444 23.5556L23.5556 22.4444L17.5558 16.4447L16.4447 17.5558Z" />
        </svg>`,
};

export const fileAdd: UiIcon = {
  name: 'fileAdd',
  data: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path stroke-width="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.30003H7.78636C6.41318 3.30003 5.3 4.41322 5.3 5.7864V18.5137C5.3 19.8869 6.41318 21 7.78636 21H16.5136C17.8868 21 19 19.8869 19 18.5137V10.3H15.6955C13.6545 10.3 12 8.64552 12 6.60458V3.30003ZM20.9632 8.33686C20.9837 8.35742 21.0034 8.37862 21.0221 8.40041C21.1922 8.59813 21.2872 8.84448 21.2988 9.09745M21.3 9.15907V18.5137C21.3 21.1571 19.1571 23.3 16.5136 23.3H7.78636C5.14293 23.3 3 21.1571 3 18.5137V5.7864C3 3.14296 5.14293 1.00003 7.78636 1.00003H13.141C13.1615 0.999876 13.1821 1.00027 13.2026 1.00121C13.4633 1.01295 13.7015 1.11153 13.8888 1.26871C13.9144 1.2902 13.9392 1.31292 13.9632 1.33686C13.963 1.33665 13.9634 1.33707 13.9632 1.33686L20.9632 8.33686M14.3 6.60458V4.92638L17.3737 8.00003H15.6955C14.9248 8.00003 14.3 7.37527 14.3 6.60458Z"/>
<path stroke-width="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M6.8501 12.15C6.8501 11.5149 7.36497 11 8.0001 11H12.0001C12.6352 11 13.1501 11.5149 13.1501 12.15C13.1501 12.7851 12.6352 13.3 12.0001 13.3H8.0001C7.36497 13.3 6.8501 12.7851 6.8501 12.15Z" />
<path stroke-width="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M6.8501 17.15C6.8501 16.5149 7.36497 16 8.0001 16H16.0001C16.6352 16 17.1501 16.5149 17.1501 17.15C17.1501 17.7851 16.6352 18.3 16.0001 18.3H8.0001C7.36497 18.3 6.8501 17.7851 6.8501 17.15Z" />
</svg>
`,
};

export const download: UiIcon = {
  name: 'download',
  data: `<svg width="28" height="28" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="#151D3A"/>
    <g clip-path="url(#clip0_49412_33306)">
      <path  stroke-width="0.1" d="M30 12.9L29.9997 12.9C27.1713 12.9089 24.4394 13.929 22.2974 15.776C20.6766 17.1726 19.4903 18.9925 19.1645 20.8328C15.5928 21.6519 12.9 24.7776 12.9 28.5507C12.9 32.961 16.5773 36.475 21.0346 36.475H25.75C26.0583 36.475 26.354 36.3525 26.572 36.1345C26.79 35.9165 26.9125 35.6208 26.9125 35.3125C26.9125 35.0042 26.79 34.7085 26.572 34.4905C26.354 34.2725 26.0583 34.15 25.75 34.15H21.0346C17.7912 34.15 15.225 31.6102 15.225 28.5507C15.225 25.7017 17.4448 23.3048 20.3655 22.9849L20.3656 22.9849L21.3112 22.8808L21.4003 22.871V22.7814V21.8294C21.4003 20.5567 22.2208 18.9119 23.8156 17.5383L23.8157 17.5382C25.5355 16.0545 27.7289 15.2341 30.0002 15.225C34.8947 15.2251 38.7995 19.0612 38.7974 23.7206V23.7206V24.7831V24.8831H38.8974H39.9599C42.655 24.8831 44.775 26.9915 44.775 29.5176C44.775 32.0416 42.6529 34.15 39.962 34.15H34.25C33.9417 34.15 33.646 34.2725 33.428 34.4905C33.21 34.7085 33.0875 35.0042 33.0875 35.3125C33.0875 35.6208 33.21 35.9165 33.428 36.1345C33.646 36.3525 33.9417 36.475 34.25 36.475H39.9599C43.8689 36.475 47.1 33.3924 47.1 29.5176C47.1 26.015 44.4614 23.1596 41.0697 22.6428C40.5107 17.1468 35.7386 12.9 30 12.9Z" />
      <path  stroke-width="0.1" d="M31.1629 43.1311L35.5524 38.7396L35.5524 38.7395C35.7707 38.5213 36.0667 38.3987 36.3754 38.3987C36.684 38.3987 36.9801 38.5213 37.1983 38.7395C37.4166 38.9578 37.5392 39.2538 37.5392 39.5625C37.5392 39.8712 37.4166 40.1672 37.1983 40.3855L30.8234 46.7604L31.1629 43.1311ZM31.1629 43.1311V24.6875C31.1629 24.3792 31.0404 24.0835 30.8224 23.8655C30.6044 23.6475 30.3087 23.525 30.0004 23.525C29.6921 23.525 29.3964 23.6475 29.1784 23.8655C28.9603 24.0835 28.8379 24.3792 28.8379 24.6875V43.1311L24.4483 38.7396L24.4483 38.7395C24.2301 38.5213 23.934 38.3987 23.6254 38.3987C23.3167 38.3987 23.0207 38.5213 22.8024 38.7395C22.5841 38.9578 22.4615 39.2538 22.4615 39.5625C22.4615 39.8712 22.5841 40.1672 22.8024 40.3855L29.1773 46.7604L31.1629 43.1311ZM29.5548 47.0131C29.4136 46.9545 29.2854 46.8687 29.1774 46.7605L29.5548 47.0131ZM29.5548 47.0131C29.696 47.0717 29.8475 47.1019 30.0004 47.1019M29.5548 47.0131L30.0004 47.1019M30.0004 47.1019C30.1533 47.1019 30.3047 47.0717 30.4459 47.0131M30.0004 47.1019L30.4459 47.0131M30.4459 47.0131C30.5871 46.9545 30.7154 46.8687 30.8233 46.7605L30.4459 47.0131Z"/>
    </g>
    <defs>
      <clipPath id="clip0_49412_33306">
        <rect width="34" height="34" fill="white" transform="translate(13 13)"/>
      </clipPath>
    </defs>
  </svg>`,
};

export const imageAdd: UiIcon = {
  name: 'imageAdd',
  data: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path stroke-width="0.1" d="M1 6.15C1 3.30573 3.30573 1 6.15 1H18.15C20.9943 1 23.3 3.30573 23.3 6.15V18.15C23.3 20.9943 20.9943 23.3 18.15 23.3H6.15C3.30573 23.3 1 20.9943 1 18.15V6.15ZM6.15 3.3C4.57599 3.3 3.3 4.57599 3.3 6.15V18.15C3.3 19.724 4.57599 21 6.15 21H18.15C19.724 21 21 19.724 21 18.15V6.15C21 4.57599 19.724 3.3 18.15 3.3H6.15Z"/>
<path stroke-width="0.1" d="M7.11229 8.56146L12.6123 13.5615L15.5795 10.5942L22.3863 16.2666L20.9139 18.0335L15.7207 13.7058L12.6879 16.7386L7.18789 11.7386L2.96326 15.9632L1.33691 14.3368L7.11229 8.56146Z"/>
<path stroke-width="0.1" d="M12.65 6.3C10.276 6.3 8.3 8.28461 8.3 10.7971H6C6 7.07194 8.94889 4 12.65 4C16.3511 4 19.3 7.07194 19.3 10.7971C19.3 11.801 19.0863 12.7577 18.7012 13.6193L16.6014 12.6807C16.8566 12.1098 17 11.4726 17 10.7971C17 8.28461 15.024 6.3 12.65 6.3Z"/>
</svg>

  `,
};

export type uiIcon =
  | 'shapes'
  | 'rect'
  | 'circle'
  | 'triangle'
  | 'mouse'
  | 'eraser'
  | 'share'
  | 'undo'
  | 'redo'
  | 'pen'
  | 'enableFullScreen'
  | 'disableFullScreen'
  | 'text'
  | 'sticker'
  | 'fileAdd'
  | 'download'
  | 'imageAdd';

export interface UiIcon {
  name: uiIcon;
  data: string;
}
