<div class="ez-tools-wrapper">
  <div
    class="ez-tools"
    [ngClass]="{ opened: toolsIsOpened }"
  >
    <div
      class="ez-tools__item"
      (click)="showShapesTools()"
      [ngClass]="{ opened: toolsIsOpened }"
      #shapesTooltipBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.shapes)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="isShapeSelected(this.tool) || showShapes"
        name="shapes"
      ></ezteach-icon>
    </div>
    <div
      class="ez-tools__item"
      (click)="changeMouseState()"
      [ngClass]="{ opened: toolsIsOpened }"
      #mouseTooltipBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.mouse)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        name="mouse"
        [isActive]="tool === 'mouse'"
      ></ezteach-icon>
    </div>
    <div
      class="ez-tools__item"
      (click)="toFreeDrawState()"
      [ngClass]="{ opened: toolsIsOpened }"
      #penTooltipBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.pen)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        name="pen"
        [isActive]="'freeDraw' === tool"
        [onlyFill]="true"
      ></ezteach-icon>
    </div>

    <div
      class="ez-tools__item"
      (click)="toTextFieldState()"
      [ngClass]="{ opened: toolsIsOpened }"
      #textFieldBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.text)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="tool === 'text'"
        [onlyFill]="true"
        name="text"
      ></ezteach-icon>
    </div>

    <div
      class="ez-tools__item"
      (click)="setStickerState()"
      [ngClass]="{ opened: toolsIsOpened }"
      #stickerBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.sticker)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="tool === 'sticker'"
        [onlyFill]="true"
        name="sticker"
      ></ezteach-icon>
    </div>

    <div
      class="ez-tools__item"
      (click)="file.click()"
      [ngClass]="{ opened: toolsIsOpened }"
      #addFiledBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.fileAdd)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        name="fileAdd"
        [onlyFill]="true"
      ></ezteach-icon>
    </div>

    <input
      class="hideinput"
      type="file"
      accept="application/pdf, application/vnd.ms-excel, application/msword, .doc, .docx, .XLSX"
      (change)="addFile($event)"
      #file
    />

    <div
      class="ez-tools__item"
      (click)="image.click()"
      [ngClass]="{ opened: toolsIsOpened }"
      #addImageBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.imageAdd)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        name="imageAdd"
        [onlyFill]="true"
      ></ezteach-icon>
    </div>

    <input
      class="hideinput"
      type="file"
      accept="image/png, image/jpeg"
      (change)="addFile($event)"
      #image
    />

    <div
      class="ez-tools__item"
      (click)="toEraserState()"
      [ngClass]="{ opened: toolsIsOpened }"
      #eraserTooltipBtn
      (mouseover)="createWhiteBoardTooltip(whiteBoardTooltipType.eraser)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        name="eraser"
        [isActive]="tool === 'eraser'"
      ></ezteach-icon>
    </div>
  </div>
  <div
    *ngIf="showShapes"
    class="ex-tools-shapes"
  >
    <div
      (click)="setRect()"
      #rectTooltipBtn
      (mouseover)="createShapeTooltip(shapeTooltipType.rectangle)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="tool === 'rect'"
        name="rect"
      ></ezteach-icon>
    </div>
    <div
      (click)="setEllipse()"
      class="ex-tools-shapes__shape"
      #ellipseTooltipBtn
      (mouseover)="createShapeTooltip(shapeTooltipType.ellipse)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="tool === 'ellipse'"
        name="circle"
      ></ezteach-icon>
    </div>
    <div
      (click)="setTriangle()"
      class="ex-tools-shapes__shape"
      #triangleTooltipBtn
      (mouseover)="createShapeTooltip(shapeTooltipType.triangle)"
      (mouseleave)="removeTooltip()"
    >
      <ezteach-icon
        [isActive]="tool === 'triangle'"
        name="triangle"
      ></ezteach-icon>
    </div>
  </div>
  <div
    [style.display]="!toolsIsOpened ? 'none' : ''"
    class="ez-tools-wrapper__divider"
  ></div>
  <button
    class="ez-tools-wrapper__toggle-btn"
    [ngClass]="{ opened: toolsIsOpened }"
    ezteachAntiQuickClick
    (singleClick)="toggleTools()"
  >
    <img [src]="
        toolsIsOpened ? 'assets/img/svg/whiteboard-tools-open-btn.svg' : 'assets/img/svg/whiteboard-tools-close-btn.svg'
      " />
  </button>
</div>
