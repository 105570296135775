import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { ButtonModule } from '../buttons/button.module';
import { ProlongComponent } from './prolong.component';
import { ProlongService } from './prolong.service';
import { TimerComponent } from './timer-component/timer.component';

@NgModule({
  declarations: [ProlongComponent, TimerComponent],
  exports: [ProlongComponent, TimerComponent],
  imports: [CommonModule, ButtonModule, TranslocoRootModule],
  providers: [ProlongService],
})
export class ProlongModule {}
