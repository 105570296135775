import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  template: `
    <ezteach-tutorial-dialog-wrapper>
      <a
        #skipLink
        class="ezteach-tutorial-dialog__skip"
        (click)="close()"
      >
        <img
          class="profile-missing-step-close"
          src="/assets/img/svg/icon-close.svg"
        />
      </a>
      <div class="profile-missing-step-image">
        <img
          [attr.src]="'/assets/img/tutorial/onboarding-teacher/profile-missing-header.svg' | cachedImage"
        />
        <img
          class="profile-missing-step-image__smiley"
          [attr.src]="'/assets/img/tutorial/onboarding-teacher/sad-smiley.svg' | cachedImage"
        />
      </div>
      <h1 class="ezteach-tutorial-dialog__heading profile-missing-step-heading">
          {{'Вы не заполнили раздел специализация' | transloco}}
      </h1>
      <p class="ezteach-tutorial-dialog__info profile-missing-step-info">
          {{'Чтобы начать преподавать, а также показывать вашу анкету в поиске, нужно заполнить раздел Специализация.' | transloco}}
      </p>
      <div class="ezteach-tutorial-dialog__actions profile-missing-step__actions">
        <ezteach-button
          minWidth="248px"
          (click)="goToProfile()"
          >Заполнить профиль</ezteach-button
        >
      </div>
    </ezteach-tutorial-dialog-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      @media (max-height: 1000px) {
        .profile-missing-step__actions{
          margin-bottom: 0;
        }
      }

      .profile-missing-step-close {
        display: block;
        width: 25px;
        color: #0000000;
      }

      .profile-missing-step-heading {
        margin-top: 70px !important;
        max-width: 400px !important;
      }

      .profile-missing-step-info {
        max-width: 470px !important;
      }

      .profile-missing-step-image {
        margin-top: -50px;
        position: relative;
      }

      .profile-missing-step-requirements {
        list-style: url('/assets/img/svg/list-item-point.svg');

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        max-width: 360px;
        margin: 30px auto;
        padding: 0 10px;

        color: #6d6d7e;
      }

      .profile-missing-step-requirements li {
        margin: 0 auto 15px;
        padding-left: 10px;
      }

      .profile-missing-step-image__smiley {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -45px;
      }

      .profile-missing-step-cross-icon {
        color: #000;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDetailsMissingDialogComponent implements OnInit {
  constructor(
    private readonly dialogRef: MatDialogRef<ProfileDetailsMissingDialogComponent>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void { }

  close() {
    this.dialogRef.close();
  }

  goToProfile() {
    this.router.navigateByUrl('/profile/specialty');
    this.dialogRef.close();
  }
}
