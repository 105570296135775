import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '@ezteach/_services/user.service';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { StreamUserData } from '@ezteach/group-lesson/models/stream-user-data';
import { GroupLessonParticipantsOverlayService } from '@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service';

@Component({
  selector: 'ezteach-pin-control-merge',
  templateUrl: './pin-control.component.html',
  styleUrls: ['./pin-control.component.scss'],
})
export class PinControlComponent implements OnInit {
  @Input() member: ChatLessonMemberClient;
  @Input() isPinned = false;
  @Input() audioEnabled: boolean;
  @Input() videoEnabled: boolean;
  @Input() shareEnabled = false;
  @Input() isOwner: boolean;
  @Input() isOwnerPin = false;
  @Input() isSpeech: boolean;
  @Input() isModerator: boolean;
  @Output() onPinClicked = new EventEmitter();
  @Output() onMicClicked = new EventEmitter();
  @Output() onVideoStatusMemberChanged = new EventEmitter();
  @Output() onCallRemoveClicked = new EventEmitter();

  isOpen = false;
  timeoutId: any;

  // является ли участник звонка текущим пользователем 
  memberIsCurrentUser = false;

  positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' }
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }
    )
  ];

  constructor(private userService: UserService, private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService) { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes ', changes)
  }

  ngOnInit(): void {
    const user = this.userService.userData$.value;


    // console.log('this.member ', this.member)

    if (this.member && this.member.member && user) {
      this.memberIsCurrentUser = this.member.member.user?.id === user?.id;
      // console.log('this.memberIsCurrentUser ', this.memberIsCurrentUser, this.isOwnerPin)
    }
  }

  goToProfile() {

  }

  onVideoModerateUserChangedClick() {
    const _user = this.member?.member?.user;

    console.log('_user ', _user)

    if (this.isOwner && _user) {
      this.videoEnabled = !this.videoEnabled;
      this.groupLessonParticipantsOverlayService.onModerateUserVideoStatusChanged.next({
        userData: { userid: _user.id } as StreamUserData,
        videoEnabled: this.videoEnabled,
      });
    }
  }

  toggleVideoEnabled() {
    const data = { userid: this.member.member.user.id } as StreamUserData;
    this.onVideoStatusMemberChanged.emit({
      userData: data,
      videoEnabled: !this.videoEnabled,
    });
  }

  openMenu() {
    clearTimeout(this.timeoutId);
    this.isOpen = true;
  }

  closeMenu() {
    this.timeoutId = setTimeout(() => {
      this.isOpen = false;
    }, 100);
  }

  overlayAttached() {
    clearTimeout(this.timeoutId);
  }

  overlayDetached() {
    clearTimeout(this.timeoutId);
  }

  openOptions() {
    this.isOpen = !this.isOpen;
  }

  emitOnPinClick() {
    this.onPinClicked.emit();
  }

  emitOnMicClick() {
    this.onMicClicked.emit();
  }

  emitOnCallRemoveClick() {
    this.onCallRemoveClicked.emit();
  }
}
