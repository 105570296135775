import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from "@ezteach/_services/user.service";
import { LessonsService } from '@ezteach/api/services';
import { environment } from '../../environments/environment';
import { User } from '../api/models/user';
import { UserApiResponse } from '../api/models/user-api-response';
import { ChatService } from '../api/services/chat.service';
import { UsersService } from '../api/services/users.service';

@Component({
  selector: 'student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class StudentComponent implements OnInit {
  userResponse: UserApiResponse;
  userdata;
  user: User;
  disciplines;
  loaded = false;
  sended = false;
  history = [];
  mod;

  constructor(
    private route: ActivatedRoute,
    private lessonsService: LessonsService,
    private usersService: UsersService,
    private chatService: ChatService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.userdata = this.userService.userData$.value;


    this.loaded = false;
    this.getResult();

    this.getHistory();
  }

  getUserAge(birthDate: string) {
    const date = new Date(birthDate);
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    function getAgeSuffix(age: number) {
      if (age % 10 === 1 && age % 100 !== 11) {
        return 'год';
      } else {
        return 'лет';
      }
    }

    return `${age} ${getAgeSuffix(age)}`;
  }


  getResult(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.usersService
      .apiV1UsersUserIdGet({
        // eslint-disable-next-line radix
        userId: parseInt(id),
        mode: 'public',
      })
      .subscribe((userResponse: UserApiResponse) => {
        this.userResponse = userResponse;
        this.user = userResponse.data;
        this.loaded = true;

        console.log(this.user);

        this._cdr.detectChanges();
      });
  }

  getHistory(): void {
    this.lessonsService
      .apiV1LessonsHistoryGet({
        PageSize: 3,
      })
      .subscribe(results => {
        this.history = results?.data;
      });
  }

  image(item) {
    if (item.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        item.id +
        '/user-avatar?redirect=true&file=' +
        item.avatarFileName
      );
    } else {
      return null;
    }
  }

  initials(item) {
    return item.firstName.charAt(0).toUpperCase() + item.lastName.charAt(0).toUpperCase();
  }

  sendMessage() {
    const message = document.querySelector('.student_page textarea[name=message]') as HTMLInputElement;
    if (message.value !== '') {
      this.chatService
        .apiV1ChatUserDirectMessagesPost({
          userId: this.user.id,
          body: {
            messageText: message.value,
          },
        })
        .subscribe(response => {
          if (response.succeeded) {
            this.sended = true;
          }
        });
    }
  }
}
