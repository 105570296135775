import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScheduledLessonStatusEnum } from '@ezteach/api/models';
import { BlockExerciseTypeEnum } from '@ezteach/blocks/services/block-api.service';
import { ModalSimpleError } from '@ezteach/modals/modal-simple-error/modal-simple-error.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment/moment';
import { BlockLesson } from '../block/block.component';

@UntilDestroy()
@Component({
  selector: 'ezteach-block-lesson',
  templateUrl: './block-lesson.component.html',
  styleUrls: ['./block-lesson.component.scss'],
})
export class BlockLessonComponent implements OnInit {
  @Input() lesson: BlockLesson;
  @Input() index: number;
  @Input() isMobile: boolean;
  @Input() isEdit: boolean;
  @Output() onRemove = new EventEmitter();
  lessonDuration = "0";

  constructor(private route: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    const duration = moment(this.lesson.lessonFinishDate).diff(moment(this.lesson.lessonStartDate)) / 1000;
    const hours = Math.floor((duration / (60 * 60)) % 24);
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);
    this.lessonDuration = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  }

  videoLoaded() {
    var video: any = document.getElementById("lessonVideo" + this.lesson?.id);
    if (video) {
      const duration = video.duration;
      const hours = Math.floor((duration / (60 * 60)) % 24);
      const minutes = Math.floor((duration / 60) % 60);
      const seconds = Math.floor(duration % 60);
      this.lessonDuration = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }
  }

  get duration() {

    const duration = moment(this.lesson.lessonFinishDate).diff(moment(this.lesson.lessonStartDate)) / 1000;
    const hours = Math.floor((duration / (60 * 60)) % 24);
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);
    const time = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    return time;
  }

  openLesson() {

    if (this.lesson?.type?.toLocaleUpperCase() === BlockExerciseTypeEnum.ScheduledLesson?.toLocaleUpperCase()) {
      const { scheduledLesson } = this.lesson as any;
      if (
        scheduledLesson.statusId !== ScheduledLessonStatusEnum.GroupLessonFinished &&
        scheduledLesson?.statusId !== ScheduledLessonStatusEnum.CanceledByStudent &&
        scheduledLesson?.statusId !== ScheduledLessonStatusEnum.CanceledByTutor
      ) {
        const title = 'Урок еще не начат';
        const text = 'Необходимо дождаться начала урока';
        this.dialog.open(ModalSimpleError, {
          panelClass: 'modal-simple-error',
          data: { title, text },
        });
      }
      if (
        scheduledLesson.statusId == ScheduledLessonStatusEnum.GroupLessonFinished ||
        scheduledLesson?.statusId === ScheduledLessonStatusEnum.CanceledByStudent ||
        scheduledLesson?.statusId === ScheduledLessonStatusEnum.CanceledByTutor
      ) {
        this.route.navigate(['/history/' + scheduledLesson?.id]);
      }
      return;
    }
    this.route.navigate(['/history/0'], { queryParams: { blockLessonId: this.lesson?.id } });
  }
  delete() {
    this.onRemove.emit(this.lesson);
  }
}
