<div class="profile">
  <div class="profile_header">
    <div class="profile_header__left">
      <div class="profile_header__title">{{ 'Личный кабинет' | transloco }}</div>
    </div>
    <div class="profile_header__right">
      <div class="profile_header__title">{{ title | transloco }}</div>
    </div>
  </div>

  <div class="profile_content">
    <div class="profile_content__left">
      <div class="profile_image">
        <img
          *ngIf="profile?.userAvatar.avatarFileName; else avatarPlaceholderTemplate"
          [src]="profile.userAvatar | userAvatar"
        />
        <ng-template #avatarPlaceholderTemplate>
          <div class="profile_image__letters">{{ user | userInitials | uppercase }}</div>
        </ng-template>

        <input
          id="avatar"
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          (change)="avatarChange($event)"
          #file
        />
        <span
          (click)="file.click()"
          class="button button-avatar"
        >
          <svg-icon-sprite
            [src]="'icon-upload-image'"
            [width]="'23px'"
            [height]="'23px'"
            [viewBox]="'0 0 23 23'"
          ></svg-icon-sprite>
        </span>
      </div>
      <div class="profile_name">{{ user?.name }}</div>
      <span class="uId">{{ user.uId }}</span>
      <div class="profile_nav">
        <div class="profile_nav__item">
          <a
            routerLink="/profile/personal"
            routerLinkActive="active"
          >
            {{ 'Общая информация' | transloco }}</a>
        </div>
        <!--div
          class="profile_nav__item"
          *ngIf="user?.isStudent"
        >
          <a
            routerLink="/profile/patron"
            routerLinkActive="active"
          >
            {{ 'Данные родителя' | transloco }}</a>
        </div-->
        <!--div
          class="profile_nav__item"
          *ngIf="user?.isStudent"
        >
          <a
            routerLink="/profile/cards"
            routerLinkActive="active"
          >
            {{ 'Финансовая информация' | transloco }}</a>
        </div-->
        <!--div
          class="profile_nav__item"
          *ngIf="user?.isTutor"
        >
          <a
            routerLink="/profile/passport"
            routerLinkActive="active"
          >
            {{ 'Паспортные данные' | transloco }}</a>
        </div>
        <div
          class="profile_nav__item"
          *ngIf="user?.isTutor"
        >
          <a
            routerLink="/profile/specialty"
            routerLinkActive="active"
          >
            {{ 'Специализация' | transloco }}</a>
        </div>
        <div
          class="profile_nav__item"
          *ngIf="user?.isTutor"
        >
          <a
            routerLink="/profile/education"
            routerLinkActive="active"
          >
            {{ 'Образование' | transloco }}</a>
        </div>
        <div
          class="profile_nav__item"
          *ngIf="user?.isTutor"
        >
          <a
            routerLink="/profile/reference"
            routerLinkActive="active"
          >
            {{ 'Рекомендательные письма' | transloco }}</a>
        </div>
        <div
          class="profile_nav__item"
          *ngIf="user?.isTutor"
        >
          <a
            routerLink="/profile/work"
            routerLinkActive="active"
          >
            {{ 'Трудовая история' | transloco }}</a>
        </div-->
        <!--div
          class="profile_nav__item"
          *ngIf="!user?.isStudent"
        >
          <a
            routerLink="/profile/cards"
            routerLinkActive="active"
          >
            {{ 'Финансовая информация' | transloco }}</a>
        </div-->
        <!--<div class="profile_nav__item" *ngIf="user?.isTutor">
          <a routerLink="/profile/financial" routerLinkActive="active">Финансовая информация</a>
        </div>
        -->
        <!--div class="profile_nav__item">
          <a
            href="{{ environment.authUrl }}/auth/changepassword?returnUrl={{ environment.appUrl }}/profile&ui_locales={{lang}}">
            {{ 'Смена пароля' | transloco }}</a>
        </div-->
        <!--div
          class="profile_nav__item"
          *ngIf="!user?.isStudent"
        >
          <a
            routerLink="/profile/telegram-bot"
            routerLinkActive="active"
          >
            {{ 'Телеграм бот' | transloco }}</a>
        </div-->
      </div>
    </div>
    <div class="profile_content__right">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>