import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { stepTransitionAnimation } from './stepper.animations';

@UntilDestroy()
@Component({
  selector: 'ezteach-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  animations: [stepTransitionAnimation],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Input() allDone = false;

  ngOnInit(): void {
    this.steps.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this._stateChanged();
    });
  }
}
