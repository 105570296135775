import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs';
import { bufferTime, debounceTime, filter, first, map, mergeMap, tap, throttleTime } from 'rxjs/operators';
import { ToolManager, ToolType } from '../../classes/tool-manager';
import { ConvaMember, ShapeTypes, WhiteBoardMode } from '../../classes/white-board';
import { WhiteBoardFileService } from '../../services/whiteboard-file.service';
import { WhiteBoardLayoutService } from '../../services/whiteboard-layout.service';
import { MenuPosition, WhiteboardMenuService } from '../../services/whiteboard-menu.service';
import { WhiteBoardSceneService } from '../../services/whiteboard-scene.service';
import { WhiteBoardShapeService } from '../../services/whiteboard-shape.service';
import { VideoViewType } from '@ezteach/group-lesson/components/group-lesson-header/group-lesson-header.component';
import { WhiteboardStoreService } from '../../services/whiteboard-store.service';
import { WhiteBoardUserService } from '../../services/whiteboard-users.service';
import { WhiteBoardShape } from '../../shapes/shape';
import { ShapeTextAttrsConfig } from '../../shapes/shape-attrs-config';
import { ITouch, WhiteBoard } from '../../whiteboard';

import { WhiteboardService } from "@ezteach/api/services/whiteboard.service";
import { GroupLessonLayoutMergeService } from '@ezteach/group-lesson/services/group-lesson-layout.service';
import { TranslocoService } from '@ngneat/transloco';
import { WhiteBoardMouseState } from '../../whiteboard-state';
import { FileShapeToolsComponent } from '../file-shape-tools/file-shape-tools.component';
import {
  DefaultShapeToolConfig,
  ShapeColor,
  ShapeToolConfig,
  ShapeToolsComponent,
} from '../shape-tools/shape-tools.component';
import { TextShapeToolsComponent } from '../text-shape-tools/text-shape-tools.component';
import { EraserFollower } from '../tools/eraserFollower';
import { SelectedTool, ToolsComponent } from '../tools/tools.component';

const MENU = 'whiteboard-menu';
const MENU_HIDE = 'whiteboard-menu-hide';

interface IMenuDirection {
  forwardDirectionX: boolean;
  forwardDirectionY: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss'],
  animations: [
    trigger('whiteboard', [
      state(
        'disableFullPageMode',
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }),
      ),
      state(
        'enableFullPageMode',
        style({
          position: 'fixed',
          minWidth: '100%',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
        }),
      ),
      transition('disableFullPageMode => enableFullPageMode', [
        animate(
          1300,
          keyframes([
            style({
              position: 'fixed',
              minWidth: '100%',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              opacity: 0,
              offset: 0,
            }),
            style({ opacity: 0, offset: 0.3 }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
      transition('enableFullPageMode => disableFullPageMode', [
        animate(
          '1s',
          keyframes([
            style({
              position: 'absolute',
              height: '100%',
              opacity: 0,
              offset: 0,
              minWidth: '*',
            }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteboardComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  stage: any;
  backLayer: any;
  prevX = 0;
  prevY = 0;
  whiteBoard: WhiteBoard;
  selectedTool: SelectedTool;
  zoom = 100;
  zoomStep = 10;
  shapes: any;
  animationEnded: boolean = true;
  toolState: SelectedTool;
  temporaryState: SelectedTool;
  insideMode: boolean;
  whiteboardFullPageState: string = 'disableFullPageMode';
  fileUploading = false;
  textAttrs: ShapeTextAttrsConfig = {};
  editedShape: WhiteBoardShape;
  _isArchived: boolean;
  whiteBoardModes = WhiteBoardMode;
  enableFullPageMode: boolean = false;
  selectedShapes: WhiteBoardShape[];
  eraserFollower: EraserFollower;
  shapeTauched = false;
  undoDisabled: boolean;
  redoDisabled: boolean;
  toolManager: ToolManager;
  whiteboardToolsVisible = true;
  private previousDistance = 0;
  isMobile: boolean;
  editIconDisplay = false;
  editState = false;
  private previousWhiteboardName: string;
  private overlayRef: OverlayRef;

  @ViewChild(ToolsComponent) tools: ToolsComponent;
  @ViewChild(ShapeToolsComponent) shapeTools: ShapeToolsComponent;
  @ViewChild(TextShapeToolsComponent) textTools: TextShapeToolsComponent;
  @ViewChild('whiteboard') whiteboard: ElementRef;
  @ViewChild(FileShapeToolsComponent) fileTool: FileShapeToolsComponent;
  @ViewChild('toolPortalContent') toolPortalContent: TemplateRef<ToolType>;
  @ViewChild('renameField') set renameField(v: ElementRef<HTMLInputElement>) {
    if (v) {
      v.nativeElement.focus();
      // Таймаут добавил потому что ngModel срабатывает после set
      // и setSelectionRange выполняется до первого присвоения значения
      const timeoutId = setTimeout(() => {
        v.nativeElement.setSelectionRange(0, 0);
        clearTimeout(timeoutId)
      }, 0)
      this.title = this.translocoService.translate('Новая интерактивная доска')
      this.previousWhiteboardName = this.title;
    }
  };


  @ViewChild('textToolPortalContent') textToolPortalContent: TemplateRef<ToolType>;
  @ViewChild('shapeToolPortalContent') shapeToolPortalContent: TemplateRef<ToolType>;
  @ViewChild('fileToolPortalContent') fileToolPortalContent: TemplateRef<ToolType>;

  tempEditBox: HTMLDivElement | null = null;

  private mediaQueryService = new MediaQueryService('(max-width: 600px)');

  @HostListener('keydown', ['$event:keypress']) keyDownHandler(event: KeyboardEvent) {
    const key = event.code;
    if (key === 'Space' && this.toolState !== 'move' && !this.whiteBoard.shapeManager.textEditing$.value && !this.editState) {
      event.preventDefault();
      this.setMoveStateAndSavePrevious(event);
    }
  }

  @HostListener('keyup', ['$event:keypress']) keyUpHandler(event: KeyboardEvent) {
    const key = event.code;
    if (key === 'Space' && this.temporaryState) {
      this.restorePreviousState();
    }
  }

  @Output()
  onMousePositionChanged = new EventEmitter<any>();

  @Input()
  isOwner = false;
  @Input()
  users: ConvaMember[] = [];
  @Input()
  wId: string;
  @Input()
  title: string;
  @Input()
  currentUserId: number;
  @Input()
  whiteBoardMode: WhiteBoardMode;
  @Input() whiteBoardTopOffset: number;
  @Input('isArchived') set isArchived(value: boolean) {
    this._isArchived = value;
    if (value) {
      this.setMoved();
      this.setReadOnly();
    }
  }

  get isArchived(): boolean {
    return this._isArchived;
  }

  get isEnabledActions(): boolean {
    return (this.isOwner || this.whiteBoardMode !== this.whiteBoardModes.inside) && !this.isArchived && this.whiteboardToolsVisible;
  }

  constructor(
    @Inject(WINDOW) private windowRef: Window,
    @Inject(DOCUMENT) private documentRef: Document,
    private renderer: Renderer2,
    private whiteBoardUserService: WhiteBoardUserService,
    private whiteBoardShapeService: WhiteBoardShapeService,
    private whiteBoardSceneService: WhiteBoardSceneService,
    private whiteBoardLayoutService: WhiteBoardLayoutService,
    private whiteBoardFileService: WhiteBoardFileService,
    private whiteboardStoreService: WhiteboardStoreService,
    private whiteboardMenuService: WhiteboardMenuService,
    private whiteboardService: WhiteboardService,
    private cdr: ChangeDetectorRef,
    private readonly overlay: Overlay,
    private groupLessonLayoutService: GroupLessonLayoutMergeService,
    private readonly viewContainerRef: ViewContainerRef,
    private translocoService: TranslocoService,
  ) { }

  public changeEditState(): void {
    this.editState = !this.editState;
    this.editIconDisplay = false;
  }

  public renameWhiteboard() {
    this.changeEditState();
    const params = {
      wId: this.whiteBoard.wId,
      body: {
        field: 'title',
        value: this.title
      }
    }
    this.whiteboardService.rename(params)
      .pipe(
        first(),
        untilDestroyed(this)
      ).subscribe()
  }

  public discardRename() {
    this.changeEditState();
    this.title = this.previousWhiteboardName;
  }

  setMoveStateAndSavePrevious(event: Event) {
    event.preventDefault();
    this.temporaryState = this.toolState;
    this.setMoved();
  }

  restorePreviousState() {
    this.onToolChanged(this.temporaryState);
    this.temporaryState = null;
  }

  ngOnInit() {
    const menuElement = this.windowRef.document.getElementById('menu');
    this.whiteBoardLayoutService.animationEnded$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.animationEnded = value;
          if (value) {
            this.whiteBoardLayoutService.resized$.next();
          }
        }),
      )
      .subscribe();
      this.groupLessonLayoutService.view$.subscribe(view => {
        this.enableFullPageMode = (view === VideoViewType.full);
        this.cdr.detectChanges();
      });
    //this.enableFullPageMode = this.whiteBoardUserService.enableFullPageMode$.value;

    this.setInsideModeState();

    // принудительно вызываю обновление размеров доски при каждой смене режимов звонка
    this.groupLessonLayoutService.view$.subscribe(_v => {
      this.whiteBoardLayoutService.resized$.next();

      // из-за анимаций доп раз обновление
      setTimeout(() => {
        this.whiteBoardLayoutService.resized$.next();
      }, 400);
    });

    this.whiteboardMenuService.open$.pipe(untilDestroyed(this)).subscribe(open => {
      this.renderer.removeClass(menuElement, open ? MENU_HIDE : MENU);
      this.renderer.addClass(menuElement, open ? MENU : MENU_HIDE);
    });
    this.whiteboardMenuService.position$
      .pipe(
        filter(v => !!v),
        tap((position: MenuPosition) => {
          this.setMenuPosition(menuElement, position);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.toolManager = new ToolManager(this.overlay, this.viewContainerRef);

    this.whiteBoardLayoutService.resized$
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        tap(_ => {
          this.whiteBoard.whiteBoardResize();
        }),
      )
      .subscribe();

    this.mediaQueryService.match$.pipe(untilDestroyed(this)).subscribe(x => {
      this.isMobile = x;
      this.cdr.detectChanges();
    });
  }

  private getDistance(touch1: ITouch, touch2: ITouch): number {
    return Math.floor(Math.sqrt(Math.pow(touch2.x - touch1.x, 2) + Math.pow(touch2.y - touch1.y, 2)));
  }

  private zoomIncreasing(touch1: ITouch, touch2: ITouch, step: number): boolean | void {
    const currentDistance = this.getDistance(touch1, touch2);
    const diff = currentDistance - this.previousDistance;
    const res = diff > step;
    if (res) {
      const direction = currentDistance > this.previousDistance;
      this.previousDistance = currentDistance;
      return direction;
    }
    // return res;
  }

  ngAfterViewInit() {
    this.whiteBoard = new WhiteBoard(
      this.whiteboard,
      this.documentRef,
      this.renderer,
      this.whiteboardStoreService,
      this.whiteboardMenuService,
      new WhiteBoardMouseState(),
    );


    const width = this.whiteboard.nativeElement.clientWidth; // - WidthOffSetBase;
    const height = this.whiteboard.nativeElement.clientHeight; // - HeightOffsetBase;

    console.log('this.whiteboard.nativeElement ', this.whiteboard.nativeElement, width, height)

    this.whiteBoard.initStage(width, height);
    this.whiteBoard.initBackLayer(width, height);
    this.whiteBoard.initMainLayer(width, height);
    this.whiteBoard.initMouseLayer(width, height);
    this.eraserFollower = new EraserFollower(this.documentRef, this.renderer, this.whiteBoard);
    this.whiteBoard.setEraserFollower(this.eraserFollower);
    this.whiteBoard.onToolChanged$
      .pipe(
        tap(x => {
          this.tools.updateSelectedTool(x);
          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    this.whiteBoard.onMousePositionChanged$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.onMousePositionChanged.emit(x);
        }),
      )
      .subscribe();

    this.whiteBoard
      .getShapes$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.shapes = x;
        }),
      )
      .subscribe();

    this.whiteBoard
      .selectedShapes$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.selectedShapes = x;
          this.updateShapeTool(x);
        }),
      )
      .subscribe();

    this.whiteBoard
      .shapeDragStart$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.shapeManager.closeTextEditor();
          this.hideShapeTools();
        }),
      )
      .subscribe();

    this.whiteBoard
      .shapeDragEnd$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.showShapeTools();
        }),
      )
      .subscribe();

    this.whiteBoard
      .shapeTransformStart$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.hideShapeTools();
        }),
      )
      .subscribe();

    this.whiteBoard
      .shapeTransformEnd$()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.showShapeTools();
        }),
      )
      .subscribe();

    this.whiteBoardUserService.memberMouseUpdate$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          if (x) {
            this.whiteBoard.setUserMouse(x);
          }
        }),
      )
      .subscribe();

    this.whiteBoardUserService.memberRemoved$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.removeUser(x);
        }),
      )
      .subscribe();

    this.whiteBoardUserService.members$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.users = x;
          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    this.whiteBoard.onObjectsCreated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.objectsCreated$.next(x);
        }),
      )
      .subscribe();

    this.whiteBoard.onObjectsDeleted$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.objectsDeleted$.next(x);
        }),
      )
      .subscribe();

    this.whiteBoardShapeService.onObjectsCreated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.addShape(x);
        }),
      )
      .subscribe();

    this.whiteBoardShapeService.onObjectsDeleted$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          for (let i = 0; i < x.oids.length; i++) {
            const shapeToRemove = this.shapes?.find(shape => shape.konvajsShape.getAttr('ezId') === x.oids[i]);
            if (shapeToRemove) {
              this.whiteBoard.shapeManager.removeShape(shapeToRemove);
            }
          }
        }),
      )
      .subscribe();

    this.whiteBoardShapeService.lockedShapes$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const values = _.flatten(Array.from(x.values()));
          this.whiteBoard.shapeManager.lockShapesOrQueue(values);
        }),
      )
      .subscribe();

    this.whiteBoardShapeService.unLockedShapes$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.shapeManager.unlockShapesOrQueue(x);
        }),
      )
      .subscribe();

    this.whiteBoardShapeService.onObjectsUpdated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.shapeManager.updateShapeOrQueue(x);
        }),
      )
      .subscribe();

    this.whiteBoard.onObjectsLocked$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.objectsLocked$.next(x);
        }),
      )
      .subscribe();

    this.whiteBoard.onObjectsUnLocked$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.objectsUnlocked$.next(x);
        }),
      )
      .subscribe();

    this.whiteBoard.onObjectsUpdated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.objectsUpdated$.next(x);
        }),
      )
      .subscribe();

    this.whiteBoardSceneService.sceneData$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoard.shapeManager.renderScene(x);
        }),
      )
      .subscribe();

    this.whiteBoard.shapeTauched$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.shapeTauched = x;
          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    this.whiteBoard.scaleChange$
      .pipe(
        untilDestroyed(this),
        tap(deltaY => {
          if (deltaY > 0) {
            this.zoomOut();
          } else {
            this.zoomIn();
          }
        }),
      )
      .subscribe();

    this.whiteBoardFileService.fileUploading$
      .pipe(
        tap(x => {
          this.fileUploading = x;
          this.cdr.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoardFileService.fileUploaded$
      .pipe(
        tap(x => {
          this.whiteBoard.addFile(x);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoard.loadPreview$
      .pipe(
        tap(x => {
          this.whiteBoardFileService.loadPreview$.next(x);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoardFileService.previewLoaded$
      .pipe(
        tap(x => {
          this.whiteBoard.addPreview(x);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoardLayoutServiceSubscribe();

    this.whiteBoard.wId = this.wId;
    this.whiteBoard.currentUserId = this.currentUserId;

    this.updateWbInitState(this.isOwner ?? false, this.whiteBoardMode ?? WhiteBoardMode.self);

    fromEvent(this.whiteboard.nativeElement, 'wheel')
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        tap(() => this.onToolChanged(this.toolState)),
      )
      .subscribe();

    fromEvent(document, 'mouseup')
      .pipe(
        untilDestroyed(this),
        map(event => event as MouseEvent),
        filter(event => event.button === 1),
        debounceTime(200),
        tap(() => this.onToolChanged(this.toolState)),
      )
      .subscribe();

    this.whiteBoard
      .getMemoShaper()
      .undoDisabled$.pipe(
        untilDestroyed(this),
        tap((disabled: boolean) => (this.undoDisabled = disabled)),
      )
      .subscribe();

    this.whiteBoard
      .getMemoShaper()
      .redoDisabled$.pipe(
        untilDestroyed(this),
        tap((disabled: boolean) => (this.redoDisabled = disabled)),
      )
      .subscribe();

    this.whiteBoard.click$
      .pipe(
        untilDestroyed(this),
        filter(_ => this.editState),
        tap(_ => this.discardRename())
      ).subscribe()

    this.whiteBoard.zoomIncreasing$
      .pipe(
        untilDestroyed(this),
        throttleTime(300),
        tap(({ touch1, touch2 }) => {
          if (this.zoomIncreasing(touch1, touch2, 10)) {
            this.zoomIn();
          } else {
            this.zoomOut();
          }
        })
      ).subscribe()
      if (this.isMobile) {//Для мобильной версии уменьшаем масштаб, так как полная доска не помещается
        this.zoom = 40;
        this.cdr.detectChanges();
        this.whiteBoard.setScale(this.zoom);
      }
  }

  private whiteBoardLayoutServiceSubscribe() {
    this.whiteBoardLayoutService.width$
      .pipe(
        untilDestroyed(this),
        filter(x => this.whiteBoardMode === WhiteBoardMode.inside),
        bufferTime(500),
        mergeMap(x => x),
        debounceTime(1),
        tap(x => {
          if (x > 0) {
            this.whiteBoard.setWidth(x + 200);
          }
        }),
      )
      .subscribe();

    this.whiteBoardLayoutService.height$
      .pipe(
        untilDestroyed(this),
        filter(x => this.whiteBoardMode === WhiteBoardMode.inside),
        bufferTime(500),
        mergeMap(x => x),
        debounceTime(1),
        tap(x => {
          if (x > 0) {
            this.whiteBoard.setHeight(x + 80);
          }
        }),
      )
      .subscribe();
  }

  prepareToOpenTextTool(shape) {
    this.textAttrs = shape.getTextAttrs();

    this.overlayRef = this.toolManager.open(
      this.textToolPortalContent,
      this.tempEditBox.getBoundingClientRect()
    );
  }

  createTempEditBox() {
    if (this.tempEditBox) {
      document.body.removeChild(this.tempEditBox);
    }
    const canvasNode = document.getElementsByClassName('konvajs-content')[0].childNodes[0] as Element;
    const canvasBounds = canvasNode.getBoundingClientRect();
    this.tempEditBox = document.createElement('div');
    const clientRect = this.editedShape.konvajsShape.getClientRect();
    this.tempEditBox.style.position = "absolute";
    this.tempEditBox.style.zIndex = "-1";
    this.tempEditBox.style.width = `${clientRect?.width ?? 0}px`;
    this.tempEditBox.style.height = `${clientRect?.height ?? 0}px`;
    this.tempEditBox.style.top = `${(clientRect?.y ?? 0) + canvasBounds.top}px`;
    this.tempEditBox.style.left = `${(clientRect?.x ?? 0) + canvasBounds.left}px`;
    document.body.appendChild(this.tempEditBox);
    this.waitToolsComponent(canvasBounds);

  }


  waitToolsComponent(canvasBounds: DOMRect) {
    //@ts-ignore
    if ((this.shapeTools || this.fileTool || this.textTools) && this.overlayRef?._pane?.children?.[0] && this.overlayRef?._pane?.children?.[0]?.getBoundingClientRect()?.width > 0) {
      //@ts-ignore
      this.checkMargins(this.overlayRef._pane.children[0].getBoundingClientRect(), this.tempEditBox.getBoundingClientRect(), canvasBounds);
    }
    else {

      const waitPaneTimeout = setTimeout(() => {
        this.waitToolsComponent(canvasBounds);
        clearTimeout(waitPaneTimeout);
      }, 10);
    }

  }

  getTopPosition(paneClientRect: any, clientRect: any, canvasBounds: any) {
    if (paneClientRect.top <= canvasBounds.top) {
      return `${parseInt(clientRect.y + clientRect.height + 10)}px`;
    }
    return `${parseInt(paneClientRect.top)}px`;
  }

  getLeftPosition(paneClientRect: any, canvasBounds: any) {
    if (paneClientRect?.left <= canvasBounds.left) {
      return `${parseInt(10 + canvasBounds.left)}px`;
    }

    return `${parseInt(paneClientRect?.left)}px`;
  }

  getRightPosition(paneClientRect: any, canvasBounds: any) {

    if (paneClientRect?.right >= canvasBounds.right) {
      return '10px';
    }
    return null;
  }

  checkMargins(paneClientRect: any, clientRect: any, canvasBounds: any) {
    const rightMargin = this.getRightPosition(paneClientRect, canvasBounds)
    if (rightMargin) {
      this.overlayRef.updatePositionStrategy(
        this.overlay.position()
          .global()
          .top(this.getTopPosition(paneClientRect, clientRect, canvasBounds))
          .left(this.getLeftPosition(paneClientRect, canvasBounds))
          .right(rightMargin)
      )

    }
    else {
      this.overlayRef.updatePositionStrategy(
        this.overlay.position()
          .global()
          .top(this.getTopPosition(paneClientRect, clientRect, canvasBounds))
          .left(this.getLeftPosition(paneClientRect, canvasBounds))

      )
    }
    this.overlayRef.updatePosition();

  }

  initOverlay() {
    const shapeType = this.editedShape.konvajsShape.attrs.type;
    this.createTempEditBox();
    if (shapeType === ShapeTypes.file || shapeType === ShapeTypes.image) {
      this.shapeTools?.hide();
      this.overlayRef = this.toolManager.open(
        this.fileToolPortalContent,
        this.tempEditBox.getBoundingClientRect()
      );
      const fileTimeout = setTimeout(() => {
        this.fileTool?.open();
        clearTimeout(fileTimeout);
      }, 300);
    } else if (shapeType === ShapeTypes.text) {
      this.shapeTools?.hide();
      this.fileTool?.close();
      this.prepareToOpenTextTool(this.editedShape);
    } else {
      this.fileTool?.close();
      this.overlayRef = this.toolManager.open(
        this.shapeToolPortalContent,
        this.tempEditBox.getBoundingClientRect()
      );
      const shapeTimeout = setTimeout(() => {
        this.calcAndUpdateShape(this.editedShape);
        clearTimeout(shapeTimeout);
      }, 300)
    }
  }

  private updateShapeTool(shapes) {
    if (shapes && shapes.length === 1) {
      this.editedShape = shapes[0];
      this.initOverlay();
    } else {
      this.editedShape = undefined;
      this.shapeTools?.hide();
      this.fileTool?.close();
      this.toolManager?.close();
      this.whiteboardToolsVisible = true;
    }
  }

  private calcAndUpdateShape(x: WhiteBoardShape) {

    this.shapeTools?.setColors(x.konvajsShape);
    this.shapeTools?.setOpacity(x.konvajsShape.attrs.opacity);
    this.shapeTools?.setStrokeWidth(x.konvajsShape);

    if (x.konvajsShape.attrs.type !== 'text') {
      const config = this.getShapeToolConfig(x);
      this.shapeTools?.show(config);
    }
    this.whiteboardToolsVisible = false;
  }

  private getShapeToolConfig(shape: WhiteBoardShape): ShapeToolConfig {
    const config: ShapeToolConfig = DefaultShapeToolConfig;
    config.enableFill = !(shape.konvajsShape.attrs.type === ShapeTypes.freeline);
    config.enableStroke = !(shape.konvajsShape.attrs.type === ShapeTypes.sticker);
    config.enableOpacity = !(shape.konvajsShape.attrs.type === ShapeTypes.sticker);

    return config;
  }

  private hideShapeTools() {
    this.shapeTools?.hide();
    this.fileTool?.close();
    this.toolManager?.close();
    this.whiteboardToolsVisible = true;
  }

  private showShapeTools() {
    if (this.editedShape) {
      this.initOverlay();
    }
  }

  // обновлять координаты панели если фигура передвигается или трансформируется
  ngOnChanges(changes: SimpleChanges) {
    const { wId, currentUserId, isOwner, whiteBoardMode } = changes;

    if (this.whiteBoard) {
      if (wId?.currentValue) {
        this.whiteBoard.wId = wId?.currentValue;

      }
      if (currentUserId?.currentValue) {
        this.whiteBoard.currentUserId = currentUserId?.currentValue;
      }

      if (isOwner || whiteBoardMode) {
        this.updateWbInitState(
          isOwner?.currentValue ?? this.isOwner ?? false,
          whiteBoardMode?.currentValue ?? this.whiteBoardMode ?? WhiteBoardMode.self,
        );
      }
    }
  }

  updateWbInitState(owner: boolean, mode: WhiteBoardMode) {
    this.setMouse();
    this.whiteBoard.setWhiteBoardMode(mode);
    this.whiteBoard.setIsOwner(owner);
  }


  setReadOnly() {
    this.whiteBoard?.toReadOnlyState();
  }

  setShapeRect() {
    this.toolState = 'rect';
    this.whiteBoard?.toShapeState(ShapeTypes.rect);
  }

  setShapeEllipse() {
    this.toolState = 'ellipse';
    this.whiteBoard?.toShapeState(ShapeTypes.ellipse);
  }

  setShapeTriangle() {
    this.toolState = 'triangle';
    this.whiteBoard?.toShapeState(ShapeTypes.triangle);
  }

  setFreeDraw() {
    this.toolState = 'freeDraw';
    this.whiteBoard?.toShapeState(ShapeTypes.freeline);
  }

  setTextField() {
    this.toolState = 'text';
    this.whiteBoard?.toShapeState(ShapeTypes.text);
  }

  setEraser() {
    this.toolState = 'eraser';
    this.whiteBoard?.toEraserState();
  }

  setStickerField() {
    this.toolState = 'sticker';
    this.whiteBoard?.toShapeState(ShapeTypes.sticker);
  }

  setMoved() {
    this.toolState = 'move';
    this.whiteBoard?.toMoveState();
  }

  setMouse() {
    this.toolState = 'mouse';
    this.whiteBoard?.toMouseState();
  }

  onToolChanged($event: SelectedTool) {
    if ($event === 'mouse') {
      this.setMouse();
      return;
    }
    if ($event === 'move') {
      this.setMoved();
      return;
    }
    if ($event === 'rect') {
      this.setShapeRect();
      return;
    }
    if ($event === 'ellipse') {
      this.setShapeEllipse();
      return;
    }
    if ($event === 'triangle') {
      this.setShapeTriangle();
      return;
    }

    if ($event === 'freeDraw') {
      this.setFreeDraw();
      return;
    }

    if ($event === 'eraser') {
      this.setEraser();
      return;
    }

    if ($event === 'text') {
      this.setTextField();
      return;
    }
    if ($event === 'sticker') {
      this.setStickerField();
      return;
    }
  }

  zoomIn() {
    if (this.zoom === 400) {
      return;
    }
    this.zoom += this.zoomStep;
    this.cdr.detectChanges();
    this.whiteBoard.setScale(this.zoom);
  }

  zoomOut() {
    if (this.zoom === 30) {
      return;
    }
    this.zoom -= this.zoomStep;
    this.cdr.detectChanges();
    this.whiteBoard.setScale(this.zoom);
  }

  moveFront() {
    this.whiteBoard.moveFront();
    this.whiteBoard.hideContextMenu();
  }

  moveBack() {
    this.whiteBoard.moveBack();
    this.whiteBoard.hideContextMenu();
  }

  copyShapes() {
    this.whiteBoard.shapeManager.copyShapes();
    this.whiteBoard.hideContextMenu();
  }
  pasteShapes() {
    this.whiteBoard.shapeManager.pasteShapes(this.whiteBoard.mouseCooridnate);
    this.whiteBoard.hideContextMenu();
  }

  removeShapes(): void {
    this.selectedShapes.forEach(shape => {
      this.whiteBoard.shapeManager.removeShape(shape);
    });
    this.whiteBoard.hideContextMenu();
  }

  undo($event) {
    if (!this.undoDisabled) {
      $event.preventDefault();
      $event.stopPropagation();
      this.whiteBoard.undo();
    }
  }

  redo($event) {
    if (!this.redoDisabled) {
      $event.preventDefault();
      $event.stopPropagation();
      this.whiteBoard.redo();
    }
  }

  colorStrokeChanged($event: ShapeColor) {
    this.whiteBoard.shapeManager.setColor($event, 'stroke');
  }

  colorFillChanged($event: ShapeColor) {
    this.whiteBoard.shapeManager.setColor($event, 'fill');
  }

  opacityChanged($event) {
    this.whiteBoard.shapeManager.setOpacity($event);
  }

  strokeWidthChanged($event) {
    this.whiteBoard.shapeManager.setStrokeWidth($event);
  }

  textAttrsChanged(attrs: ShapeTextAttrsConfig): void {
    this.whiteBoard.shapeManager.updateTextAriaFormatAttrs(attrs);
    this.textAttrs = attrs;
  }

  fullPageView() {
    this.hideShapeTools();
    this.whiteBoard.clearTransform();

    // if (!this.animationEnded) {
    //   return;
    // }

    this.enableFullPageMode = !this.enableFullPageMode;
    this.cdr.detectChanges();
    // Переключаю тип просмотра
    let view: VideoViewType = this.enableFullPageMode ? VideoViewType.full : VideoViewType.all;
    this.groupLessonLayoutService.view$.next(view);

    if (this.insideMode) {
      this.whiteBoardUserService.enableFullPageMode$.next(this.enableFullPageMode);
    } else {
      this.whiteboardFullPageState = this.enableFullPageMode ? 'enableFullPageMode' : 'disableFullPageMode';
    }
    this.whiteBoardLayoutService.animationEnded$.next(false);
  }

  animationDone() {
    this.whiteBoardLayoutService.animationEnded$.next(true);
  }

  setInsideModeState() {
    this.insideMode = this.whiteBoardMode === this.whiteBoardModes.inside;
  }

  addFile(file: File) {
    this.whiteBoardFileService.addFile$.next(file);
  }

  downloadFile() {
    this.whiteBoardFileService.download$.next(this.editedShape?.konvajsShape?.attrs?.fileId);
    this.whiteBoard.shapeManager.endSelect();
  }

  onFileDrop(file: File) {
    this.whiteBoardFileService.addFile$.next(file);
  }

  private checkMenuCoords(element: HTMLElement, position: MenuPosition): IMenuDirection {
    const forwardDirectionX = this.whiteboard.nativeElement.offsetWidth > element.offsetWidth + position.left;
    const forwardDirectionY = this.whiteboard.nativeElement.offsetHeight > element.offsetHeight + position.top;

    return {
      forwardDirectionX,
      forwardDirectionY,
    };
  }

  private checkVerticalEmbedding(top: number, height: number): number {
    if (top - height > 0) {
      return top;
    }
    return top + height / 2;
  }

  private setMenuPosition(menuElement: HTMLElement, position: MenuPosition): void {
    const { forwardDirectionX, forwardDirectionY } = this.checkMenuCoords(menuElement, position);
    if (!forwardDirectionY) {
      position.top = this.checkVerticalEmbedding(position.top, menuElement.offsetHeight);
    }

    position.left = forwardDirectionX ? position.left : position.left - menuElement.clientWidth;
    position.top = forwardDirectionY ? position.top : position.top - menuElement.offsetHeight;

    this.renderer.setStyle(menuElement, 'left', position.left + 'px');
    this.renderer.setStyle(menuElement, 'top', position.top + 'px');
  }

  ngOnDestroy(): void {
    this.whiteBoard.destroy();
    this.whiteBoardShapeService.destroy();
  }
}
