import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import { catchError, concatAll, filter, switchMap, tap, toArray } from 'rxjs/operators';

export enum SettingsTabType {
  sound = 'Звук',
  camera = 'Видео',
}

export enum DeviceType {
  video = 'video',
  audio = 'audio',
}

export enum MediaType {
  videoInput = 'videoinput',
  audioInput = 'audioinput',
  audioOutput = 'audiooutput',
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-settings',
  templateUrl: './group-lesson-settings.component.html',
  styleUrls: ['./group-lesson-settings.component.scss'],
})
export class GroupLessonSettingsComponent implements OnInit {
  settingsTabType = SettingsTabType;
  activeTab = this.settingsTabType.sound;
  @Output() onCloseBtnClick = new EventEmitter();
  selectedMicrophone = 'default';
  selectedCamera = 'default';
  selectedAudioOutput = 'default';
  microphones: MediaDeviceInfo[] = [];
  cameras: MediaDeviceInfo[] = [];
  audioOutputs: MediaDeviceInfo[] = [];
  deviceLoadingHasError = false;
  allDeviceIsLoaded = false;
  loadedCountOfDeviceType = new BehaviorSubject<number>(0);
  checkedTabs: SettingsTabType[] = [];
  currentMediaType: DeviceType;

  constructor(
    private openViduService: OpenViduService,
    private groupLessonWaitService: GroupLessonWaitService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.loadedCountOfDeviceType
      .pipe(
        untilDestroyed(this),
        tap(count => {
          if (count === 3 && this.deviceLoadingHasError) {
            this.allDeviceIsLoaded = true;
            this.openViduService.showMediaConstraintsModal();
          } else if (count === 3) {
            this.allDeviceIsLoaded = true;
          }
        }),
      )
      .subscribe();

    if (this.activeTab === SettingsTabType.sound) {
      this.currentMediaType = DeviceType.audio;
      this.getDevicesByType();
      this.checkedTabs.push(SettingsTabType.sound);
    } else {
      this.currentMediaType = DeviceType.video;
      this.getDevicesByType();
      this.checkedTabs.push(SettingsTabType.camera);
    }
  }

  getDevicesByType() {
    const mediaConstraints = {
      audio: this.currentMediaType === DeviceType.audio,
      video: this.currentMediaType === DeviceType.video,
    };

    fromPromise(navigator.mediaDevices.getUserMedia(mediaConstraints))
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.deviceLoadingHasError = true;
          return EMPTY;
        }),
        switchMap(_ =>
          fromPromise(navigator.mediaDevices.enumerateDevices()).pipe(
            concatAll(),
            filter(device => this.selectDeviceByType(device)),
            toArray(),
            tap(devices => this.addGettingDevices(devices)),
          ),
        ),
      )
      .subscribe();
  }

  selectDeviceByType(device): boolean {
    if (this.currentMediaType === DeviceType.video) {
      return device.kind === MediaType.videoInput;
    }
    if (this.currentMediaType === DeviceType.audio) {
      return device.kind === MediaType.audioInput || device.kind === MediaType.audioOutput;
    }
    return false;
  }

  addGettingDevices(devices: MediaDeviceInfo[]) {
    if (this.currentMediaType === DeviceType.video) {
      if (devices && devices.length > 0) {
        this.cameras = devices.filter(device => device.kind === 'videoinput');
        if (this.cameras.length === 0 || this.cameras[0].deviceId === '') {
          this.cameras.push(this.openViduService.getNotFoundMediaDevice());
        }
      } else {
        this.cameras.push(this.openViduService.getNotFoundMediaDevice());
      }

      if (this.localStorageService.get('videoDeviceId') && this.localStorageService.get('videoDeviceId') !== 'none') {
        this.selectedCamera = this.localStorageService.get('videoDeviceId');
      } else {
        this.selectedCamera =
          this.localStorageService.get('videoDeviceId') && this.localStorageService.get('videoDeviceId') !== 'none'
            ? this.localStorageService.get('videoDeviceId')
            : this.cameras[0].deviceId;
      }
      this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
      this.allDeviceIsLoaded = true;
    }

    if (this.currentMediaType === DeviceType.audio) {
      if (devices && devices.length > 0) {
        this.microphones = devices.filter(device => device.kind === 'audioinput');
        if (this.microphones.length === 0 || this.microphones[0].deviceId === '') {
          this.microphones.push(this.openViduService.getNotFoundMediaDevice());
        }
      } else {
        this.microphones.push(this.openViduService.getNotFoundMediaDevice());
      }
      if (this.localStorageService.get('audioDeviceId') && this.localStorageService.get('audioDeviceId') !== 'none') {
        this.selectedMicrophone = this.localStorageService.get('audioDeviceId');
      } else {
        this.selectedMicrophone =
          this.localStorageService.get('audioDeviceId') && this.localStorageService.get('audioDeviceId') !== 'none'
            ? this.localStorageService.get('audioDeviceId')
            : this.microphones[0].deviceId;
      }
      this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
      this.allDeviceIsLoaded = true;

      if (devices && devices.length > 0 && devices?.[0].deviceId !== '') {
        this.audioOutputs = devices.filter(device => device.kind === 'audiooutput');
      } else {
        this.audioOutputs.push(this.openViduService.getNotFoundMediaDevice());
      }
      if (
        this.localStorageService.get('audioOutputDeviceId') &&
        this.localStorageService.get('audioOutputDeviceId') !== 'none'
      ) {
        this.selectedAudioOutput = this.localStorageService.get('audioOutputDeviceId');
      } else {
        this.selectedAudioOutput =
          this.localStorageService.get('audioOutputDeviceId') &&
            this.localStorageService.get('audioOutputDeviceId') !== 'none'
            ? this.localStorageService.get('audioOutputDeviceId')
            : this.audioOutputs[0].deviceId;
      }
      this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
    }
  }

  activateTab(selectedTab: SettingsTabType) {
    this.activeTab = selectedTab;
    this.currentMediaType = this.activeTab === SettingsTabType.sound ? DeviceType.audio : DeviceType.video;
    const alreadyCheckedTabs = this.checkedTabs.includes(selectedTab);
    if (!alreadyCheckedTabs) {
      this.getDevicesByType();
    }
  }

  close() {
    this.onCloseBtnClick.emit();
  }

  onSelectMicrophone(value: any) {
    this.openViduService
      .getAudioMediaTrack({ audioSource: value, videoSource: false })
      .pipe(
        untilDestroyed(this),
        tap(mediaTrack => {
          if (mediaTrack) {
            this.groupLessonWaitService.audioMediaTrack$.next(mediaTrack);
            this.localStorageService.set('audioDeviceId', value);
          }
        }),
      )
      .subscribe();
  }

  onSelectCamera(value: any) {
    const selectedCamersObj = this.cameras.find(x => x.deviceId === value);
    this.openViduService
      .getVideoMediaTrack({ videoSource: selectedCamersObj.deviceId })
      .pipe(
        untilDestroyed(this),
        tap(mediaTrack => {
          if (mediaTrack) {
            this.groupLessonWaitService.videoMediaTrack$.next(mediaTrack);
            this.groupLessonWaitService.setPublisherVideoResolutionByStreamTrack(mediaTrack);
            this.localStorageService.set('videoDeviceId', selectedCamersObj.deviceId);
            this.localStorageService.set('videoDeviceLabel', selectedCamersObj.label);
          }
        }),
      )
      .subscribe();
  }

  onSelectAudioOutput(value: any) {
    if (value) {
      this.groupLessonWaitService.audioOuputDeviceChanged$.next(value);
      this.localStorageService.set('audioOutputDeviceId', value);
    }
  }
}
