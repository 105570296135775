import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinishedLessonGuard } from '@ezteach/group-lesson/guards/finished-lesson/finished-lesson.guard';
import { VideoViewComponent } from '@ezteach/my-lessons/components/video-view/video-view.component';
import { AdvertisementWizardComponent } from '@ezteach/widgets/advertisement/advertisment-wizard/advertisement-wizard/advertisement-wizard.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { CalendarComponent } from './calendar';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmptyComponent } from './empty/empty.component';
import { FinancesComponent } from './finances/finances.component';
import { MessagesComponent } from './messages/messages.component';
import { ProfileComponent } from './profile-old/profile.component';
import { SearchInstantComponent } from './search-instant/search-instant.component';
import { SearchRegularComponent } from './search-regular/search-regular.component';
import { SettingsComponent } from './settings/settings.component';
import { StudentComponent } from './student/student.component';
import { TeacherComponent } from './teacher/teacher.component';
import { TeachersComponent } from './teachers/teachers.component';
import { WizardComponent } from './wizard/wizard.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'advertisement', component: AdvertisementWizardComponent },
      {
        path: 'search/instant',
        component: SearchInstantComponent,
      },
      {
        path: 'search/regular',
        component: SearchRegularComponent,
      },
      {
        path: 'teachers',
        component: TeachersComponent,
      },
      {
        path: 'search',
        loadChildren: () => import('./search-page/search-page.module').then(m => m.SearchPageModule),
      },
      {
        path: 'calendar',
        component: CalendarComponent,
      },
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'bookmarks',
        component: BookmarksComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'history/:id',
        component: VideoViewComponent,
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      },
      { path: 'profile-old', component: ProfileComponent },
      { path: 'profile-old/:mod', component: ProfileComponent },
      { path: 'wizard', component: WizardComponent },
      { path: 'wizard/:mod', component: WizardComponent },
      { path: 'student/:id', component: StudentComponent },
      { path: 'teachers/:id', component: TeacherComponent },
      { path: 'teachers/:id/:mod', component: TeacherComponent },
      { path: 'lesson', component: EmptyComponent },
      {
        path: 'finances',
        component: FinancesComponent,
      },
      {
        path: 'group-lesson',
        canActivate: [FinishedLessonGuard],
        loadChildren: () => import('../app/group-lesson/group-lesson.module').then(m => m.GroupLessonMergeModule),
      },
      {
        path: 'wait',
        loadChildren: () =>
          import('./group-lesson/components/group-lesson-wait/group-lesson-wait.module').then(
            m => m.GroupLessonWaitModule,
          ),
      },
      // {
      //   path: 'group-lesson-next',
      //   //canActivate: [FinishedLessonGuard],
      //   loadChildren: () =>
      //     import('../app/group-lesson-merge/group-lesson.module').then(m => m.GroupLessonMergeModule),
      // },
      {
        path: 'group-lesson-next-template',
        //canActivate: [FinishedLessonGuard],
        loadChildren: () =>
          import('../app/group-lesson-next/group-lesson-next.module').then(m => m.GroupLessonNextModule),
      },
      {
        path: 'mylessons',
        loadChildren: () => import('../app/my-lessons/my-lessons.module').then(m => m.MyLessonsModule),
      },
      {
        path: 'mylessons/:id',
        loadChildren: () => import('../app/my-lessons/my-lessons.module').then(m => m.MyLessonsModule),
      },
      {
        path: 'homeworks',
        loadChildren: () => import('../app/homeworks/homeworks.module').then(m => m.HomeworksModule),
      },
      {
        path: 'whiteboards',
        loadChildren: () => import('../app/whiteboards/whiteboards.module').then(m => m.WhiteboardsModule),
      },
      {
        path: 'whiteboard/fast',
        loadChildren: () => import('../app/whiteboard/whiteboard-app.module').then(m => m.WhiteboardAppModule),
      },
      {
        path: 'courses',
        loadChildren: () => import('../app/courses/courses.module').then(m => m.CoursesModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('../app/faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: 'file-storage',
        loadChildren: () => import('../app/file-storage/file-storage.module').then(m => m.FileStorageModule),
      },
      {
        path: 'file-storage/:id',
        loadChildren: () => import('../app/file-storage/file-storage.module').then(m => m.FileStorageModule),
      },
    ],
  },
  {
    path: 'subscribesuccess',
    loadChildren: () => import('../app/subscribesuccess/subscribesuccess.module').then(m => m.SubscribeSuccessModule),
  },
  {
    path: 'subscribefail',
    loadChildren: () => import('../app/subscribe-fail/subscribe-fail.module').then(m => m.SubscribeFailModule),
  },
  {
    path: 'fail',
    loadChildren: () => import('../app/subscribe-fail/subscribe-fail.module').then(m => m.SubscribeFailModule),
  },
  {
    path: 'guest',
    loadChildren: () => import('../app/guest-room/guest-room.module').then(m => m.GuestRoomModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
