<ezteach-popup-with-bg [smiley]="smileyType.sad">
  <div title>
    {{ data.title | transloco }}
  </div>
  <div content>
    {{ data.text | transloco }}
  </div>
  <div buttons>
    <ezteach-btn
      ezteachAntiQuickClick
      (singleClick)="close()"
    >
      <div content>{{ 'Закрыть' | transloco }}</div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>