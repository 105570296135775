import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lessonMinRateValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.toString() === '.') {
      return { lessonMinRate: true };
    }

    if (Number(control.value) === 0) {
      return { lessonMinRate: true };
    }

    if (control.value && Number(control.value) < min) {
      return { lessonMinRate: true };
    }

    return null;
  };
}

export function lessonMaxRateValidator(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && +control.value > max) {
      return { lessonMaxRate: true };
    }

    // if (control.value && +control.value.toString()[0] === 0) {
    //   return null;
    // }

    return null;
  };
}
