import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import * as dot from 'dot-object';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestBuilder } from '../api/request-builder';
import { DictionariesService } from '../api/services/dictionaries.service';
import { ToastService } from '../api/services/toast.service';
import { UsersService } from '../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  loaded = false;
  notLoad = false;
  accLoad = false;
  calLoad = false;

  userdata;
  currentData;
  results;

  Notificationmethods = [];
  Externalaccounttypesforsync = [];
  Externalcalendartypesforsync = [];

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    private dictionariesService: DictionariesService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const userdata = this.userService.userData$.value;
    console.log(userdata);

    this.usersService
      .apiV1UsersUserIdProfileGet({
        userId: userdata.id,
      })
      .subscribe(result => {
        this.currentData = result.data;
        this.loaded = true;
      });

    this.dictionariesService.apiV1DictionariesNotificationmethodsGet({}).subscribe(result => {
      this.Notificationmethods = result.data;
      this.notLoad = true;
    });

    this.dictionariesService.apiV1DictionariesExternalaccounttypesforsyncGet({}).subscribe(result => {
      this.Externalaccounttypesforsync = result.data;
      this.accLoad = true;
    });

    this.dictionariesService.apiV1DictionariesExternalcalendartypesforsyncGet({}).subscribe(result => {
      this.Externalcalendartypesforsync = result.data;
      this.calLoad = true;
    });
  }

  save() {
    const userdata = this.userService.userData$.value;

    // Notofications
    const userNotificationMethodIds = [];
    document.querySelectorAll<HTMLInputElement>('.notifications input').forEach(item => {
      if (item && item.checked) {
        userNotificationMethodIds.push(item.name);
      }
    });
    this.currentData.generalSettings.userNotificationMethodIds = userNotificationMethodIds;

    // Accounts
    const userExternalAccountTypeForSyncIds = [];
    document.querySelectorAll<HTMLInputElement>('.accounts input').forEach(item => {
      if (item.checked) {
        userExternalAccountTypeForSyncIds.push(item.name);
      }
    });
    this.currentData.generalSettings.userExternalAccountTypeForSyncIds = userExternalAccountTypeForSyncIds;

    // Calendar
    const userExternalCalendarTypeForSyncIds = [];
    document.querySelectorAll<HTMLInputElement>('.calendars input').forEach(item => {
      if (item.checked) {
        userExternalCalendarTypeForSyncIds.push(item.name);
      }
    });
    this.currentData.generalSettings.userExternalCalendarTypeForSyncIds = userExternalCalendarTypeForSyncIds;

    if (this.currentData.educationDetails && this.currentData.educationDetails.documents) {
      this.currentData.educationDetails.documents.forEach(doc => {
        doc.documentFiles.forEach(file => {
          delete file.fileDocumentType;
        });
        delete doc.validationStatusId;
      });
    }

    if (this.currentData.referenceLetters && this.currentData.referenceLetters.documents) {
      this.currentData.referenceLetters.documents.forEach(doc => {
        doc.documentFiles.forEach(file => {
          delete file.fileDocumentType;
        });
        delete doc.validationStatusId;
      });
    }

    const dotData = dot.dot(this.currentData);

    const rb = new RequestBuilder(environment.apiUrl, '/api/v1/users/{userId}/profile', 'put');
    rb.path('userId', userdata.id, {});

    const body = dotData;
    rb.body(body, 'multipart/form-data');

    this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r),
      )
      .subscribe(() => {
        this.toastService.showSuccess('Сохранено');
      });
  }
}
