import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ezteach-book-checkout',
  templateUrl: './book-checkout.component.html',
  styleUrls: ['./book-checkout.component.scss'],
})
export class BookCheckoutComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<any>) {}

  ngOnInit() {}
}
