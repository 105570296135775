import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getOppositeUserOrMe } from '@ezteach/api/utils/chat-lesson-member-replacement';
import * as moment from 'moment';
import { ChatLesson } from '../api/models';
import { ChatService } from '../api/services/chat.service';
import { FilesService } from '../api/services/files.service';
import { LessonsService } from '../api/services/lessons.service';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'lessons',
  templateUrl: './lessons.component.html',
})
export class LessonsComponent {
  response;
  item: ChatLesson;
  messages;
  video;
  videoLink;
  roomUser;
  hideVideo = false;
  file = false;
  loaded = false;

  constructor(
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private lessonsService: LessonsService,
    private chatService: ChatService,
    private filesService: FilesService,
    private userService: UserService
  ) {}

  ngOnInit() {
    moment.locale('ru');

    this.loaded = false;
    this.getResult();
  }

  get me() {
    return this.userService.userData$.value;
  }

  get lesson() {
    return this.item;
  }

  get date() {
    return moment(this.item.lessonFinishDate).format('DD.MM.YYYY');
  }

  get time() {
    return moment(this.item.lessonFinishDate).format('HH:mm');
  }

  failed() {}

  updateLink() {
    this.filesService
      .apiV1FilesLessoncontentOwnerEntityIdContentTypeGet({
        ownerEntityId: this.item.id.toString(),
        contentType: 'media',
      })
      .subscribe(request => {
        this.videoLink = request.data;
      });
  }

  getResult(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('id'));

    this.lessonsService
      .apiV1LessonsHistoryLessonIdGet({
        lessonId: id,
      })
      .subscribe(response => {
        this.item = response.data;
        const userdata = this.userService.userData$.value;

        this.roomUser = getOppositeUserOrMe(userdata, this.item);

        this.chatService
          .apiV1ChatChatRoomIdMessagesGet({
            chatRoomId: this.item.chatRoomId,
            PageNumber: 1,
            PageSize: 100,
            DescendingOrder: true,
          })
          .subscribe(request => {
            this.messages = request.data;

            this._cdr.detectChanges();
          });

        if (this.item.postProcessingCompleted === true) {
          this.file = true;

          this.filesService
            .apiV1FilesLessoncontentOwnerEntityIdContentTypeGet({
              ownerEntityId: this.item.id.toString(),
              contentType: 'media',
            })
            .subscribe(request => {
              this.videoLink = request.data;
              this.hideVideo = true;
            });
        } else {
          this.hideVideo = true;
        }
      });
  }
}
