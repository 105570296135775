export enum PaymentOrderStatusEnum {
  Initialized = 'Initialized',
  Registered = 'Registered',
  AmountHeld = 'AmountHeld',
  Authorized = 'Authorized',
  AuthorizationCanceled = 'AuthorizationCanceled',
  Refunded = 'Refunded',
  AuthorizationInitiated = 'AuthorizationInitiated',
  AuthorizationDeclined = 'AuthorizationDeclined',
  PaymentByBindingFailed = 'PaymentByBindingFailed',
}
