import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ezteach-custom-toggle',
  templateUrl: './custom-toggle.component.html',
  styleUrls: ['./custom-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomToggleComponent {
  @Input() checked: boolean;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  toggle(): void {
    this.checked = !this.checked;
    this.toggled.emit(this.checked);
  }
}
