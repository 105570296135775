import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDirectivesModule } from '@ezteach/shared/directives/directives.module';
import { IconModule } from '../icon/icon.module';
import {
  circle,
  download,
  eraser,
  fileAdd,
  imageAdd,
  mouse,
  pen,
  rect,
  shapes,
  sticker,
  text,
  triangle,
} from '../icon/icons';
import { UiIconsRegistry } from '../icon/icons-registry.service';
import { ToolsComponent } from './tools.component';

@NgModule({
  imports: [CommonModule, IconModule, SharedDirectivesModule],
  declarations: [ToolsComponent],
  exports: [ToolsComponent],
})
export class ToolsModule {
  constructor(readonly iconsRegistry: UiIconsRegistry) {
    iconsRegistry.registerIcons([
      shapes,
      rect,
      circle,
      triangle,
      mouse,
      pen,
      eraser,
      text,
      sticker,
      fileAdd,
      download,
      imageAdd,
    ]);
  }
}
