import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Directive, Inject, OnDestroy } from "@angular/core";

@Directive({
    selector: '[ezteachCalendarTimeGridMode]',
  })
  export class EzCalendarTimeGridModeDirective implements AfterViewInit, OnDestroy{

    mutationObserver: MutationObserver;

    constructor(
        @Inject(DOCUMENT) private documentRef: Document,
      ) { }

      ngAfterViewInit(): void {

        this.mutationObserver = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            // @ts-ignore
            if (this.documentRef.querySelector('.fc-header-toolbar') && this.documentRef.querySelector('.timeGridType_wrapper')?.style?.display === 'none') {

              const timeGridElement = this.documentRef.querySelector('.timeGridType_wrapper');
              // @ts-ignore
              timeGridElement.style.display = '';
            this.documentRef.querySelector('.fc-header-toolbar')
            .insertBefore(timeGridElement, 
            this.documentRef.querySelector('.fc-header-toolbar').children[0].nextSibling);
            }
          });
        });
        const config = { childList: true, subtree: true };
        this.mutationObserver.observe(this.documentRef.body, config);

      }

      ngOnDestroy(): void {
        this.mutationObserver.disconnect();
      }
      
  }