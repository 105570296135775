import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() cssClass: string;
  @Input() isRequired = false;
  @Input() isReadonly: boolean = false;

  value: Date | null;
  disabled = false;

  private onChange = (value: any) => { };
  private onTouched = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: Date) {
    this.value = outsideValue;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validateValue(event: any, input: HTMLInputElement) {
    const value = event.target.value;
    let date;

    if (!value) {
      this.updateValue(null);
      return
    }

    if (!value || value.length < 10) {
      date = this.min || null;
    } else {
      let [day, month, year] = value.split('.');
      if (month < 1) {
        month = '01';
      }

      if (month > 12) {
        month = 12;
      }

      const count = new Date(+year, +month, 0).getDate();

      if (day < 1) {
        day = '01';
      }

      if (day > count) {
        day = count;
      }

      date = new Date(`${year}.${month}.${day}`);
      if (isNaN(date)) {
        date = new Date(year, month, day);
      }
    }

    if (this.min && date < this.min) {
      date = this.min || null;
    } else if (this.max && date > this.max) {
      date = this.max || null;
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    input.value = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
    this.updateValue(date);
  }

  updateValue(insideValue: Date | null) {
    this.value = insideValue;
    this.onChange(insideValue);
    this.onTouched();
  }
}
