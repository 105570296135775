import * as moment from 'moment';

export const getDate = (date: Date | null) => {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : null;
};

export const getFileSize = (bytes: number) => {
  if (bytes >= 1073741824) {
    return (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    return (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    return bytes + ' bytes';
  } else if (bytes == 1) {
    return '1 byte';
  }

  return '0 bytes';
};
