<div class="container">
  <div class="placeholder"></div>
  <input
    [placeholder]="placeholder"
    class="input"
    [value]="value"
    type="text"
    [style]="style"
    (change)="onChangeValue($event)"
    (keypress)="onKeyPress($event)"
  >
  <div class="currency"></div>
</div>