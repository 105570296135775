import { Pipe, PipeTransform } from '@angular/core';
import { NotificationStatusEnum, UserNotification } from '@ezteach/api/models';

@Pipe({
  name: 'notificationsFilter',
})
export class NotificationsFilterPipe implements PipeTransform {
  transform(value: UserNotification[], statuses: NotificationStatusEnum[], ids: number[] = []): UserNotification[] {
    return value.filter(n => (ids && ids.length > 0 ? ids.includes(n.id) : false) || statuses.includes(n.statusId));
  }
}
