import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { TextShapeToolsComponent } from '../components/text-shape-tools/text-shape-tools.component';
import { ShapeToolsComponent } from '../components/shape-tools/shape-tools.component';
import { FileShapeToolsComponent } from '../components/file-shape-tools/file-shape-tools.component';

export type ToolType = TextShapeToolsComponent | ShapeToolsComponent | FileShapeToolsComponent;

export class ToolManager {
  private overlayRef: OverlayRef;
  constructor(private readonly overlay: Overlay, private readonly viewContainerRef: ViewContainerRef) {}

  open(
    toolComponentType: TemplateRef<ToolType>,
    origin: FlexibleConnectedPositionStrategyOrigin
  ) {
    this.close();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'center', originY: 'top' },
          { overlayX: 'center', overlayY: 'bottom' },
          0,
          -55),
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    const portal = new TemplatePortal(toolComponentType, this.viewContainerRef);

    this.overlayRef.attach(portal);

    return this.overlayRef;
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
