<div
  *ngIf="visible"
  class="ez-tools-wrapper"
>
  <div class="ez-tools">
    <div
      class="ez-tools__item"
      (click)="updatePaletteMode('stroke')"
      *ngIf="enableStrokePanel"
    >
      <div
        [style.borderColor]="selectedStroke.id !== 0 ? selectedStroke.code : '#fff'"
        class="ez-tools__item__stroke"
        [class.ex-tools-pallete__color__empty]="selectedStroke.id === 0"
        [class.ex-tools-pallete__color]="selectedStroke.id === 0"
        [class.ex-tools-pallete__color__shad]="
          selectedStroke.code === emptyColor.code || selectedStroke.code === 'rgba(0,0,0,0)'
        "
      >
        <div
          *ngIf="selectedStroke.id === 0"
          class="ex-tools-pallete__color__line"
        ></div>
      </div>
    </div>
    <div
      class="ez-tools__item"
      (click)="updatePaletteMode('fill')"
      *ngIf="enableFillPanel"
    >
      <div
        [style.backgroundColor]="
          selectedColor.id !== 0 && selectedColor.code !== 'rgba(0,0,0,0)' ? selectedColor.code : '#fff'
        "
        class="ez-tools__item__fill"
        [class.ex-tools-pallete__color__empty]="selectedColor.id === 0"
        [class.ex-tools-pallete__color]="selectedColor.id === 0"
      >
        <div
          *ngIf="selectedColor.id === 0"
          class="ex-tools-pallete__color__line"
        ></div>
      </div>
    </div>
  </div>
  <div
    *ngIf="showPalette"
    class="ex-tools-pallete"
  >
    <div
      *ngIf="mode === 'stroke'"
      class="slider"
    >
      <mat-slider
        [(ngModel)]="strokeWidth"
        (ngModelChange)="onStrokeWidthChanged()"
        min="0"
        max="10"
        step="1"
        tickInterval="auto"
      ></mat-slider>
      <div class="slider__text">
        <div>Толщина</div>
        <div class="slider__text__val">{{ strokeWidth }}</div>
      </div>
    </div>
    <div
      *ngIf="enableOpacityPanel"
      class="slider"
    >
      <mat-slider
        [(ngModel)]="opacity"
        (ngModelChange)="onOpacityChanged()"
        min="0"
        max="100"
        step="10"
        tickInterval="auto"
      ></mat-slider>
      <div class="slider__text">
        <div>Непрозрачность</div>
        <div class="slider__text__val">{{ opacity }}%</div>
      </div>
    </div>
    <div
      class="ex-tools-pallete__colors"
      (wheel)="colorsWhell($event)"
    >
      <div
        ezteachShapeColor
        [selected]="
          (selectedColor?.id === emptyColor.id && mode === 'fill') ||
          (selectedStroke?.id === emptyColor.id && mode === 'stroke')
        "
        class="ex-tools-pallete__color ex-tools-pallete__color__empty"
        (click)="colorClick(emptyColor)"
      >
        <div class="ex-tools-pallete__color__line"></div>
      </div>
      <ng-container *ngFor="let color of colors">
        <div
          ezteachShapeColor
          [selected]="
            (selectedColor?.id === color.id && mode === 'fill') ||
            (selectedStroke?.id === color.id && mode === 'stroke')
          "
          [style.background-color]="color.code"
          class="ex-tools-pallete__color"
          (click)="colorClick(color)"
        ></div>
      </ng-container>
      <div
        colorPicker
        [cpAlphaChannel]="'disabled'"
        ezteachShapeColor
        [style.background-color]="'blue'"
        class="ex-tools-pallete__color ex-tools-pallete__color__add"
        (colorPickerChange)="colorPickerChange($event)"
        (cpToggleChange)="cpToggleChange($event)"
      >
        <div>+</div>
      </div>
    </div>
  </div>
</div>