import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UsersService } from '../../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'widget-bookmarks',
  templateUrl: './bookmarks.component.html',
})
export class WidgetBookmarksComponent {
  pageSize = 3;
  pageNumber = 1;
  loaded = false;

  userdata;
  results;
  teachers;

  constructor(private usersService: UsersService,
              private userService: UserService,
              private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    this.getResults();
  }

  getResults(): void {
    this.pageNumber = 1;

    this.usersService
      .apiV1UsersUserIdBookmarksTutorsGet({
        userId: this.userdata.id,
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;
        console.log(this.results);
        this.teachers = results.data;
      });
  }

  getMore(): void {
    this.pageNumber++;

    this.usersService
      .apiV1UsersUserIdBookmarksTutorsGet({
        userId: this.userdata.id,
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;

        this.teachers = this.teachers.concat(results.data);
      });
  }

  image(item) {
    if (item.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        item.id +
        '/user-avatar?redirect=true&file=' +
        item.avatarFileName
      );
    } else {
      return null;
    }
  }

  initials(item) {
    return item.firstName.charAt(0).toUpperCase() + item.lastName.charAt(0).toUpperCase();
  }

  getId() {
    return '123';
  }

  getDiplom(tutor) {
    return '';
    if (tutor.disciplineSpecializations) {
      let diplom = tutor.disciplineSpecializations.userDocuments
        .filter(item => item.documentTypeId === 'AcademicDegree')
        .map(item => item.academicDegreeName)
        .join(', ')
        .toLowerCase();
      return (diplom = diplom.charAt(0).toUpperCase() + diplom.substring(1));
    } else {
      return false;
    }
  }

  getDisciplines(tutor) {
    const items = tutor.disciplineSpecializations.map(item => this.translocoService.translate(item.discipline.name)).join(', ');
    return items.charAt(0).toUpperCase() + items.substring(1);
  }
}
