import { inject, InjectionToken } from '@angular/core';
import { USER_AGENT } from '@ng-web-apis/common';
// import { NAVIGATOR } from '@ng-web-apis/common';
// import { TUI_IS_IOS } from '@taiga-ui/cdk';

// const IPAD_DESKTOP_REG_EXP = /^Mozilla\/\d+\.\d+\s\(Macintosh;/i;
// const IPAD_MOBILE_REG_EXP = /^Mozilla\/\d+\.\d+\s\(iPad;/i;

export const ET_IS_APPLE_MOBILE = new InjectionToken<boolean>('Mobile apple browser detection', {
  factory: () => isIPhoneOrIPad(),
});

// function IsiOS() {
//   return inject(TUI_IS_IOS);
// }

// function IsiPadOs() {
//   return IsiPadOsDesktopVersion() || IsiPadOsMobileVersion();
// }

// function IsiPadOsDesktopVersion() {
//   return IsMacintoch() && getIsTouchable();
// }

// function IsiPadOsMobileVersion() {
//   return IPAD_MOBILE_REG_EXP.test(getUserAgent());
// }

// function IsMacintoch() {
//   return IPAD_DESKTOP_REG_EXP.test(getUserAgent());
// }

/*Взято из Platform.js OpenVidu*/
function isIPhoneOrIPad() {
  const userAgent = getUserAgent();
  const isTouchable = getIsTouchable();
  const isIPad = /\b(\w*Macintosh\w*)\b/.test(userAgent) && isTouchable;
  const isIPhone = /\b(\w*iPhone\w*)\b/.test(userAgent) && /\b(\w*Mobile\w*)\b/.test(userAgent) && isTouchable;
  return isIPad || isIPhone;
}

function getUserAgent() {
  return inject(USER_AGENT);
}

function getIsTouchable() {
  return 'ontouchend' in document;
}

/*
https://datatracker.ietf.org/doc/html/rfc7231#section-5.5.3

For this reason, most Web browsers use a User-Agent string value as follows:

Mozilla/[version] ([system and browser information]) [platform] ([platform details]) [extensions]
(Mozilla -- engine name)

iPadOS with Desktop version
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Safari/605.1.15

iPadOS with Mobile version
Mozilla/5.0 (iPad; CPU OS 13_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1


MacOS UA Chrome
MacOS Chrome
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.109 Safari/537.36

MacOS UA Safari
MacOS Safari
Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15

Windows UA
Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36
*/
