<div
  mat-dialog-title
  class="modal-feedback__title"
>
  {{ 'Обратная связь' | transloco }}
</div>
<div>
  <p class="modal-feedback__intro-text">
    {{ 'Если у вас возникли вопросы или произошла ошибка при пользовании нашей платформы — заполните формы ниже и мы
    обязательно с вами свяжемся' | transloco }}
  </p>
  <div class="progress-bar-wrapper">
    <mat-divider></mat-divider>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="inProgress"
    ></mat-progress-bar>
  </div>
  <form
    class="modal-feedback__form feedback-form"
    [formGroup]="feedbackGroup"
  >
    <!-- <p class="feedback-form__label">Оставьте комментарий:</p> -->
    <mat-form-field
      appearance="fill"
      class="feedback-form__field"
    >
      <mat-label>{{ 'Тип запроса' | transloco}}</mat-label>
      <mat-select formControlName="requestType">
        <mat-option
          *ngFor="let typeOption of requestTypeOptions"
          [value]="typeOption.id"
          [ngClass]="{mobile: isMobile}"
        >
          {{ typeOption.name | transloco }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="feedback-form__field">
      <mat-label> {{ 'Оставьте комментарий' | transloco }}:</mat-label>
      <textarea
        matInput
        [placeholder]="'Введите сообщение...' | transloco"
        formControlName="message"
      >
      </textarea>
    </mat-form-field>
    <div
      class="feedback-form__field file-field"
      (click)="fileInput.click()"
      (fileDrop)="onFileSelect($event)"
      appFileDrop
    >
      <input
        #fileInput
        class="file-input-hidden"
        type="file"
        [attr.accept]="extentions.join(',')"
        (fileSelect)="onFileSelect($event)"
        [multiple]="false"
        appFileSelect
      />
      <div class="file-field__section file-field__section--info">
        <mat-icon>info</mat-icon>
        <p>
          {{ 'Если необходимо вы можете приложить снимок экрана' | transloco }}. {{ 'Поддерживаемые форматы' | transloco
          }}: {{ extentions.join(', ') }}.
          {{ 'Максимальный размер' | transloco }}: 5MB.
        </p>
      </div>
      <div class="file-field__section file-field__section--attachment">
        <mat-icon>attachment</mat-icon>
        <p>{{ fileText }}</p>
      </div>
    </div>
  </form>
</div>
<div class="modal-feedback__actions">
  <a
    class="btn btn--blue"
    [class.disabled]="inProgress || feedbackGroup.invalid"
    (click)="sendFeedback()"
    (mouseover)="markAsTouched()"
  >
    <span class="btn__label"> {{ 'Отправить' | transloco }} </span>
  </a>
  <a
    mat-dialog-close
    class="btn btn--red-border"
  >
    <span class="btn__label"> {{ 'Отмена' | transloco }}</span>
  </a>
</div>