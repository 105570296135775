import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LangSwitcherModule } from '@ezteach/shared/components/lang-switcher/lang-switcher.module';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ProfileMenuComponent } from './profile-menu.component';
import { SharedModule } from '@ezteach/shared';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    IconSpriteModule,
    TranslocoRootModule,
    LangSwitcherModule,
  ],
  exports: [ProfileMenuComponent],
  declarations: [ProfileMenuComponent],
})
export class ProfileMenuModule {}
