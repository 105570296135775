<div
  class="student_page"
  *ngIf="loaded"
>
  <div class="student_page_header">
    <div class="student_page_header__image">
      <img
        *ngIf="image(user)"
        src="{{ image(user) }}"
      />
      <span *ngIf="!image(user)">{{ initials(user) }}</span>
      <!-- <a routerLink=""></a> -->
      <div class="edit">
        <img src="assets/img/svg/icon-edit-white.svg" />
      </div>
    </div>
    <div class="student_page_header__top">
      <div class="student_page_header__name">
        {{ user.name }}
      </div>
      <div class="student_page_header__year">
        {{ 'Возраст' | transloco }}: {{ getUserAge(user.birthDate) }}
      </div>
    </div>
    <div class="student_page_header__bottom"></div>
  </div>
  <div class="student_page__content">
    <div class="student_page__left">
      <div class="student_page_block">
        <div class="student_page_block__title">{{ 'Категория' | transloco }} </div>
        <div class="student_page_block__content">
          <span class="student_page__tag">Средняя школа (5-9 класс)</span>
        </div>
      </div>
      <div class="student_page_block">
        <div class="student_page_block__title">{{ 'Родитель' | transloco }}</div>
        <div class="student_page_block__content">
          <span class="student_page__tag">Петрова Мария Николаевна</span>

          <a
            class="link"
            routerLink=""
          >Написать</a>
        </div>
      </div>
    </div>
    <div class="student_page__right">
      <div class="student_page_block">
        <div class="student_page_block__title">{{ 'Последние уроки' | transloco }}: </div>
        <div class="student_page_block__content">
          <ng-container *ngFor="let h of history">
            <span class="student_page__tag">{{ h.lessonFinishDate }} {{ h.discipline?.fullName | transloco}}</span>
          </ng-container>
        </div>
      </div>
      <div class="student_page_block">
        <div class="student_page_block__title">{{ 'Запланированные уроки' | transloco}}:</div>
        <div class="student_page_block__content">
          <ng-container *ngFor="let h of history">
            <span class="student_page__tag">{{ h.lessonFinishDate }} {{ "Математика" | transloco}}</span>
          </ng-container>
        </div>
      </div>
      <div class="student_page_block">
        <div class="student_page_block__title">{{ 'Отправить сообщение' | transloco}}:</div>
        <div
          class="student_page_block__content"
          *ngIf="!sended"
        >
          <div class="student_page_message">
            <mat-form-field
              class="message_input"
              appearance="outline"
            >
              <textarea
                matInput
                name="message"
                [placeholder]="'Введите ваше сообщение...' | transloco"
              ></textarea>
            </mat-form-field>
          </div>
          <a
            class="btn btn--blue"
            (click)="sendMessage()"
          >
            <span class="btn__label">{{ 'Отправить' | transloco}}</span>
          </a>
        </div>
        <div
          class="student_page_block__content"
          *ngIf="sended"
        >
          <p>{{'Сообщение было отправлено.' | transloco}}</p>
        </div>
      </div>
    </div>
  </div>
</div>