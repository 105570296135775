<div
  class="group-owner-block"
  [ngClass]="{ 'chat-is-open': isChatOpen }"
>
  <div class="owner-list">
    <div [ngClass]="{
        members2: members.length <= 1,
        members6: members.length >= 2 && members.length <= 5 && !isMobile,
        members12: members.length >= 6 && members.length <= 11 && !isMobile,
        members18: members.length >= 12 && members.length <= 17 && !isMobile
      }">
      <ezteach-ov-video-merge
        [streamManager]="streamManager"
        [speakers]="speakers"
      ></ezteach-ov-video-merge>
    </div>

    <ng-template #mobileMembersContainer>
      <ng-container *ngIf="!isChatOpen">
        <div
          *ngFor="let member of members"
          [ngClass]="{
            members2: members.length <= 1,
            members6: members.length >= 2 && members.length <= 5,
            members12: members.length >= 6 && members.length <= 11,
            members18: members.length >= 12 && members.length <= 17
          }"
        >
          {{ member.id }}
          <ezteach-ov-video-merge
            [streamManager]="member"
            [speakers]="speakers"
          ></ezteach-ov-video-merge>
          <div
            class="action"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            text="Open menu"
            (click)="openMenu(member)"
          >
            <img
              src="/assets/img/svg/icon-menu_white.svg"
              alt="moderate-menu"
            />
          </div>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen(member)"
            [cdkConnectedOverlayPositions]="positions"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
            (backdropClick)="backdropClick($event)"
          >
            <div
              class="action overlay"
              type="button"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              text="Open menu"
              (click)="openMenu(member)"
            >
              <img
                src="/assets/img/svg/icon-menu_white.svg"
                alt="moderate-menu"
              />
            </div>
            <div class="dropdown">
              <div class="checkbox__wrapper">
                <mat-checkbox
                  [checked]="isAudioChecked(member, this.membersPermission)"
                  (change)="audioChanged($event, member)"
                > {{ 'Доступ к микрофону' | transloco }}
                </mat-checkbox>
                <mat-checkbox
                  [checked]="isVideoChecked(member, this.membersPermission)"
                  (change)="videoChanged($event, member)"
                >{{ 'Включить/отключить видео' | transloco }}
                </mat-checkbox>
              </div>
              <hr />
              <div
                class="delete__title"
                (click)="onBanned(member)"
              >
                {{ 'Удалить из звонка' | transloco }}
              </div>
              <div class="tail"></div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="!isMobile; else mobileMembersContainer">
      <div
        *ngFor="let member of members"
        [ngClass]="{
          members2: members.length <= 1,
          members6: members.length >= 2 && members.length <= 5,
          members12: members.length >= 6 && members.length <= 11,
          members18: members.length >= 12 && members.length <= 17
        }"
      >
        {{ member.id }}
        <ezteach-ov-video-merge
          [streamManager]="member"
          [speakers]="speakers"
        ></ezteach-ov-video-merge>
        <div
          class="action"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          text="Open menu"
          (click)="openMenu(member)"
        >
          <img
            src="/assets/img/svg/icon-menu_white.svg"
            alt="moderate-menu"
          />
        </div>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isOpen(member)"
          [cdkConnectedOverlayPositions]="positions"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="backdropClick($event)"
        >
          <div
            class="action overlay"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            text="Open menu"
            (click)="openMenu(member)"
          >
            <img
              src="/assets/img/svg/icon-menu_white.svg"
              alt="moderate-menu"
            />
          </div>
          <div class="dropdown">
            <div class="checkbox__wrapper">
              <mat-checkbox
                [checked]="isAudioChecked(member, this.membersPermission)"
                (change)="audioChanged($event, member)"
              > {{ 'Доступ к микрофону' | transloco }}
              </mat-checkbox>
              <mat-checkbox
                [checked]="isVideoChecked(member, this.membersPermission)"
                (change)="videoChanged($event, member)"
              >{{ 'Включить/отключить видео' | transloco }}
              </mat-checkbox>
            </div>
            <hr />
            <div
              class="delete__title"
              (click)="onBanned(member)"
            >
              {{ 'Удалить из звонка' | transloco }}
            </div>
            <div class="tail"></div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>