<div
  [formGroup]="form"
  class="profile_education"
>
  <div class="profile_content__title">{{ 'Среднее/Среднее специальное образование' | transloco }}</div>
  <ng-container formArrayName="SecondaryEducation">
    <ng-container *ngFor="let control of getControl('SecondaryEducation').controls; let index = index">
      <div
        [formGroupName]="index"
        class="group secondary"
      >
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Название' | transloco }}</mat-label>
          <input
            matInput
            formControlName="educationalInstitutionName"
          />
        </mat-form-field>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <app-date-picker
              formControlName="enrollmentDate"
              [isRequired]="true"
              label="Начало обучения (ДД.ММ.ГГГГ)"
              [min]="minDate"
              [max]="getControlItemValue('SecondaryEducation', index, 'graduateDate') || maxDate"
              cssClass="profile_content__input"
            >
            </app-date-picker>
          </div>
          <div class="profile_content__col">
            <app-date-picker
              formControlName="graduateDate"
              [isRequired]="true"
              label="Конец обучения (ДД.ММ.ГГГГ)"
              [min]="getControlItemValue('SecondaryEducation', index, 'enrollmentDate') || minDate"
              [max]="maxDate"
              cssClass="profile_content__input"
            >
            </app-date-picker>
          </div>
        </div>

        <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
        <input
          class="hideinput"
          multiple
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          (change)="addFile($event, 'secondary-education', index)"
          #file
        />
        <button
          (click)="file.click()"
          class="btn btn--blue-border"
          [disabled]="getControlItemValue('SecondaryEducation', index, 'uploading')"
        >
          <span class="btn__label">{{
            (!getControlItemValue('SecondaryEducation', index, 'uploading') ? 'Загрузить файл' : 'Загружается' ) |
            transloco
            }}</span>
        </button>

        <div
          class="files_list"
          style="margin-top: 1.5rem"
        >
          <div
            *ngFor="let file of getControlItemValue('SecondaryEducation', index, 'documentFiles'); let i = index"
            class="file__wrap"
          >
            <a
              class="file"
              href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                user.id
              }}/secondary-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
              target="_blank"
            >
              <span class="file__icon">
                <img src="/assets/img/svg/icon-file.svg" />
              </span>
              <span class="file__info">
                <span class="file__name">{{ file.displayFileName }}</span>
              </span>
            </a>
            <div class="file__delete">
              <a (click)="removeFile('SecondaryEducation', i, index)">{{ 'Удалить' | transloco }}</a>
            </div>
          </div>
        </div>
        <div class="profile_content__delete">
          <a (click)="removeControl('SecondaryEducation', index)">{{ 'Удалить запись' | transloco }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <button
    class="btn btn--blue-border"
    (click)="addControl('SecondaryEducation')"
  >
    <span class="btn__label">{{ 'Добавить школу' | transloco }}</span>
  </button>
  <hr />

  <div class="profile_content__title">{{ 'Высшее образование' | transloco }}</div>
  <ng-container formArrayName="HigherEducation">
    <ng-container *ngFor="let control of getControl('HigherEducation').controls; let index = index">
      <div
        [formGroupName]="index"
        class="group high"
      >
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Название' | transloco }}</mat-label>
          <input
            matInput
            formControlName="educationalInstitutionName"
          />
        </mat-form-field>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <app-date-picker
              formControlName="enrollmentDate"
              [isRequired]="true"
              label="Начало обучения (ДД.ММ.ГГГГ)"
              [min]="minDate"
              [max]="getControlItemValue('HigherEducation', index, 'graduateDate') || maxDate"
              cssClass="profile_content__input"
            >
            </app-date-picker>
          </div>
          <div class="profile_content__col">
            <app-date-picker
              formControlName="graduateDate"
              [isRequired]="true"
              label="Конец обучения (ДД.ММ.ГГГГ)"
              [min]="getControlItemValue('HigherEducation', index, 'enrollmentDate') || minDate"
              [max]="maxDate"
              cssClass="profile_content__input"
            >
            </app-date-picker>
          </div>
        </div>

        <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
        <input
          class="hideinput"
          multiple
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          (change)="addFile($event, 'higher-education', index)"
          #file
        />
        <button
          (click)="file.click()"
          class="btn btn--blue-border"
          [disabled]="getControlItemValue('HigherEducation', index, 'uploading')"
        >
          <span class="btn__label">{{
            (!getControlItemValue('HigherEducation', index, 'uploading') ? 'Загрузить файл' : 'Загружается') | transloco
            }}</span>
        </button>

        <div
          class="files_list"
          style="margin-top: 1.5rem"
        >
          <div
            *ngFor="let file of getControlItemValue('HigherEducation', index, 'documentFiles'); let i = index"
            class="file__wrap"
          >
            <a
              class="file"
              href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                user.id
              }}/higher-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
              target="_blank"
            >
              <span class="file__icon">
                <img src="/assets/img/svg/icon-file.svg" />
              </span>
              <span class="file__info">
                <span class="file__name">{{ file.displayFileName }}</span>
              </span>
            </a>
            <div class="file__delete">
              <a (click)="removeFile('HigherEducation', i, index)">{{ 'Удалить' | transloco }}</a>
            </div>
          </div>
        </div>
        <div class="profile_content__delete">
          <a (click)="removeControl('HigherEducation', index)">{{ 'Удалить запись' | transloco }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <button
    class="btn btn--blue-border"
    (click)="addControl('HigherEducation')"
  >
    <span class="btn__label">{{ 'Добавить ВУЗ' | transloco }}</span>
  </button>
  <hr />

  <div class="profile_content__title">{{ 'Ученая степень (если есть)' | transloco }}</div>
  <ng-container formArrayName="AcademicDegree">
    <ng-container *ngFor="let control of getControl('AcademicDegree').controls; let index = index">
      <div
        [formGroupName]="index"
        class="group academic"
      >
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Название' | transloco }}</mat-label>
              <select
                formControlName="academicDegreeId"
                matNativeControl
              >
                <option
                  *ngFor="let item of getFilteredAcademicDegrees(index) "
                  [selected]="item.id === getControlItemValue('AcademicDegree', index, 'academicDegreeId')"
                  [value]="item.id"
                >
                  {{ item.name | transloco }}
                </option>
              </select>
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <app-date-picker
              formControlName="issueDate"
              [isRequired]="true"
              label="Дата присвоения (ДД.ММ.ГГГГ)"
              [min]="minDate"
              [max]="maxDate"
              cssClass="profile_content__input"
            >
            </app-date-picker>
          </div>
        </div>

        <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
        <input
          class="hideinput"
          multiple
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          (change)="addFile($event, 'academic-degree', index)"
          #file
        />
        <button
          (click)="file.click()"
          class="btn btn--blue-border"
          [disabled]="getControlItemValue('AcademicDegree', index, 'uploading')"
        >
          <span class="btn__label">{{
            (!getControlItemValue('AcademicDegree', index, 'uploading') ? 'Загрузить файл' : 'Загружается') | transloco
            }}</span>
        </button>

        <div
          class="files_list"
          style="margin-top: 1.5rem"
        >
          <div
            *ngFor="let file of getControlItemValue('AcademicDegree', index, 'documentFiles'); let i = index"
            class="file__wrap"
          >
            <a
              class="file"
              href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                user.id
              }}/academic-degree?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
              target="_blank"
            >
              <span class="file__icon">
                <img src="/assets/img/svg/icon-file.svg" />
              </span>
              <span class="file__info">
                <span class="file__name">{{ file.displayFileName }}</span>
              </span>
            </a>
            <div class="file__delete">
              <a (click)="removeFile('AcademicDegree', i, index)">{{ 'Удалить' | transloco }}</a>
            </div>
          </div>
        </div>
        <div class="profile_content__delete">
          <a (click)="removeControl('AcademicDegree', index)">{{ 'Удалить запись' | transloco }}</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <button
    class="btn btn--blue-border"
    (click)="addControl('AcademicDegree')"
  >
    <span class="btn__label">{{ 'Добавить ученую степень' | transloco }}</span>
  </button>
  <hr />

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="setProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>