<div class="requests2_item__profile">
  <div class="requests2_item__image">
    <img
      *ngIf="image(request)"
      src="{{ image(request) }}"
    />
    <span *ngIf="!image(request)">{{ initials(request) }}</span>
  </div>
  <div class="requests2_item__user">
    <div class="requests2_item__name">{{ request.callee.name }}</div>
  </div>
</div>
<div class="requests2_item__timer">
  <div [style.width.%]="value"></div>
  <span *ngIf="value > 0">{{ countdown }}</span>
  <span *ngIf="value <= 0">{{ ' Время вышло' | transloco }}</span>
</div>
<div class="requests2_item__actions">
  <a
    class="action action-cancel"
    (click)="cancelRequest(request)"
  >
    <svg-icon-sprite
      [src]="'icon-close'"
      [width]="'26px'"
      [height]="'27px'"
      [viewBox]="'0 0 26 27'"
    ></svg-icon-sprite>
  </a>
</div>