<div
  ezteachTutorialStep
  [tutorialElementName]="$any('form-search-instant')"
  class="form_search"
>
  <form
    method="get"
    (ngSubmit)="onSubmit()"
  >
    <div class="form_search__header">
      <div class="form_search__title">{{ '5, 10 или 30 минут' | transloco }}</div>
      <div class="form_search__subtitle">
        {{ 'Задайте свой вопрос' | transloco }} <br />{{ 'профессионалам' | transloco }}!
      </div>
      <div class="form_search__icon">
        <img src="/assets/img/content/icon_search_blitz.svg" />
      </div>
    </div>
    <div class="form_search__content">
      <div class="form_search__line">
        <div class="form_search__col">
          <mat-form-field>
            <input
              type="text"
              name="discipline"
              (ngModelChange)="onChange()"
              [value]="discipline | transloco"
              [(ngModel)]="discipline"
              [placeholder]="'Предмет' | transloco"
              matInput
              [formControl]="disciplinesControl"
              [matAutocomplete]="auto"
              [matAutocompletePosition]="autocompletePosition"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="onDisciplineChange()"
            >
              <mat-option
                *ngFor="let discipline of disciplinesFiltered | async"
                [value]="discipline | transloco"
              >
                {{ discipline | transloco }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form_search__col">
          <mat-form-field>
            <mat-label>{{ 'Любая цель' | transloco }}</mat-label>
            <mat-select
              name="specialization"
              [(value)]="specialization"
              [(ngModel)]="specialization"
              [formControl]="specializationsControl"
            >
              <mat-option>{{ specDefault | transloco }}</mat-option>
              <mat-option
                *ngFor="let specialization of specializationsNames | filterSpecializationNames"
                [value]="specialization.id"
              >
                {{ specialization.name | transloco }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form_search__col full">
          <mat-radio-group
            [(ngModel)]="duration"
            name="duration"
          >
            <mat-radio-button
              *ngFor="let option of durations"
              [value]="option.id"
              [checked]="option.id === duration"
            >{{ option.name | transloco }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form_search__line">
        <div class="form_search__col">
          <mat-form-field>
            <input
              type="text"
              name="name"
              [value]="name"
              [(ngModel)]="name"
              [placeholder]="'Имя организатора' | transloco"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="form_search__col full"></div>
      </div>

      <div class="form_search__line last">
        <div class="form_search__col full">
          <mat-form-field
            name="message"
            class="textarea"
            appearance="outline"
          >
            <mat-label>{{ 'Напишите чего вы хотите от события' | transloco }}?</mat-label>
            <textarea
              matInput
              name="message"
              maxlength="140"
              [value]="message"
              [(ngModel)]="message"
              (keyup)="onMessageChange(message)"
            ></textarea>
          </mat-form-field>
          <div class="counterHint">
            <mat-progress-spinner
              [diameter]="15"
              [value]="messageValue"
              class="spinner"
            ></mat-progress-spinner>
            <mat-hint class="hint"> {{ messageCol }} {{ 'символов осталось' | transloco }} </mat-hint>
          </div>
        </div>
        <div class="form_search__col form_search__button">
          <button
            type="submit"
            class="btn btn--red"
          >
            <span class="btn__label">{{ 'Найти' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>