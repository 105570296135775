
export enum  ShapeSceneQueueActon {
  addShape = 'addShape',
  editShape = 'editShape',
  removeShape = 'removeShape',
  lock = 'lock',
  unlock = 'unlock'
}

export interface ShapeSceneQueueItem {
  data: any;
  action: ShapeSceneQueueActon;
}

/*
* Очередь для рисования сцены на canvas
*/
export class ShapeSceneQueue {
  private sceneEvents: ShapeSceneQueueItem[] = [];

  enqueue(item: ShapeSceneQueueItem): void {
    this.sceneEvents.push(item);
  }
  dequeue(): ShapeSceneQueueItem {
    return this.sceneEvents.shift();
  }
  size(): number {
    return this.sceneEvents.length;
  }
}

