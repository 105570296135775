<div class="stepper">
  <div class="stepper__steps-container">
    <div
      *ngFor="let step of steps; let i = index"
      class="stepper__step"
      [@stepTransition]="_getAnimationDirection(i)"
      [attr.tabindex]="selectedIndex === i ? 0 : null"
      [id]="_getStepContentId(i)"
    >
      <ng-container [ngTemplateOutlet]="step.content"></ng-container>
    </div>
  </div>
  <div class="stepper__step-points step-progress">
    <ng-container *ngFor="let step of steps; let i = index; let last = last">
      <div
        class="step-progress__step-contour"
        [class.step-progress__step-contour--selected]="i === selectedIndex"
      >
        <div
          class="step-progress__step"
          [class.step-progress__step--completed]="i < selectedIndex || allDone"
          [class.step-progress__step--selected]="i === selectedIndex"
          [class.step-progress__step--next]="i > selectedIndex"
        >
          <ng-container *ngIf="i >= selectedIndex && !allDone">{{ i + 1 }}</ng-container>
          <img
            *ngIf="i < selectedIndex || allDone"
            src="/assets/img/svg/icon-check.svg"
            alt="icon-check.svg"
          />
        </div>
      </div>
      <div
        *ngIf="!last"
        [class.step-progress__stripe--completed]="i < selectedIndex || allDone"
        class="step-progress__stripe"
      ></div>
    </ng-container>
  </div>
</div>
