import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrCalendarService {
  private hubConnection: HubConnection;
  private connectionUrl = environment.apiUrl + '/hubs/tutorcalendar';
  private roomId;

  connectionEstablished = new EventEmitter<Boolean>();
  onRoomEnter = new EventEmitter<Boolean>();
  onRoomLeave = new EventEmitter<Boolean>();
  onCalendarEventCreated = new EventEmitter<Boolean>();
  onCalendarEventUpdated = new EventEmitter<Boolean>();
  onCalendarEventCanceled = new EventEmitter<Boolean>();
  onCalendarEventAccepted = new EventEmitter<Boolean>();
  onCalendarAvailabilityPreferenceCreated = new EventEmitter<Boolean>();
  onCalendarAvailabilityPreferenceDeleted = new EventEmitter<Boolean>();
  onSignalToOthers = new EventEmitter<Boolean>();

  constructor(private authStorage: OAuthStorage) {}

  public connect = roomId => {
    this.roomId = roomId;

    this.startConnection();
    this.addListeners();
  };

  private getConnection(): HubConnection {
    return (
      new HubConnectionBuilder()
        .withUrl(this.connectionUrl + '?roomId=' + this.roomId, {
          accessTokenFactory: () => {
            const token = this.authStorage.getItem('access_token');
            return token;
          },
        })
        //  .configureLogging(LogLevel.Trace)
        .build()
    );
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection
      .start()
      .then(() => {
        this.connectionEstablished.emit(true);
      })
      .catch(err => console.log('error while establishing signalr connection: ' + err));
  }

  enter() {
    this.hubConnection.invoke('RoomEnter');
  }

  send(command) {
    const msg = {
      command,
    };
    this.hubConnection.invoke('SignalToOthers', JSON.stringify(msg));
  }

  private addListeners() {
    this.hubConnection.on('onRoomEnter', data => {
      this.onRoomEnter.emit(data);
    });
    this.hubConnection.on('onRoomLeave', data => {
      this.onRoomLeave.emit(data);
    });
    this.hubConnection.on('onCalendarEventCreated', data => {
      this.onCalendarEventCreated.emit(data);
    });
    this.hubConnection.on('onCalendarEventUpdated', data => {
      this.onCalendarEventUpdated.emit(data);
    });
    this.hubConnection.on('onCalendarEventCanceled', data => {
      this.onCalendarEventCanceled.emit(data);
    });
    this.hubConnection.on('onCalendarEventAccepted', data => {
      this.onCalendarEventAccepted.emit(data);
    });
    this.hubConnection.on('onCalendarAvailabilityPreferenceCreated', data => {
      this.onCalendarAvailabilityPreferenceCreated.emit(data);
    });
    this.hubConnection.on('onCalendarAvailabilityPreferenceDeleted', data => {
      this.onCalendarAvailabilityPreferenceDeleted.emit(data);
    });

    this.hubConnection.on('onSignal', data => {});

    this.hubConnection.on('onSignalToOthers', data => {
      // if (data.signalName === "onSignalToOthers") {
      // 	this.onMessageSent.emit(data);
      // }
      this.onSignalToOthers.emit(data);
    });
  }
}
