<div
  class="advertisement"
  *ngIf="!isMobile"
>
  <div class="star-block">
    <div class="logo-block"></div>
  </div>
  <div class="info-wrapper_desktop">
    <div class="info">
      <div class="title">{{ titleText }}</div>
      <div class="description">{{ descriptionText }}</div>
    </div>
    <div
      class="button"
      (click)="navigate()"
    >
      {{ buttonText }}
    </div>
  </div>
</div>

<div
  class="advertisement mobile"
  *ngIf="isMobile"
>
  <div class="logo-block mobile"></div>
  <div class="button-mobile-wrapper">
    <button
      class="button mobile"
      (click)="navigate()"
    >
      {{ buttonText }}
    </button>
  </div>
</div>