import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarInputComponent implements OnInit {
  @Input()
  value: string;
  @Input()
  placeholder: string;
  @Output()
  valueChanged = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  changed($event) {
    this.valueChanged.emit($event);
  }
}
