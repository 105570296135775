import { SceneObjectTypeEnum } from "./scene";

export interface ObjectCreatedArgs {
  wId: string;
  memberId: number;
  objects: ObjectDescription[];
}

export interface ObjectLockedArgs {
  wId: string;
  memberId: number;
  oids: string[];
}

export interface ObjectUpdatedArgs {
  wId: string;
  memberId: number;
  objects: any[];
}

export interface ObjectDeletedArgs {
  wId: string;
  memberId: number;
  oids: string[];
}

export interface ObjectUnlockedArgs {
  wId: string;
  memberId: number;
  oids: string[];
}

export interface ObjectDescription {
    oId: string;
    locked?: boolean;
    data: ObjectData;
}

export interface ObjectData {
    type: SceneObjectTypeEnum;
    args: string;
}

export interface VisualObject {
    x: number;
    y: number;
    z: number;
    height: number;
    width: number;
    rotate: number;
    points: PointD[];
}

export interface PointD {
  x: number;
  y: number
}

export interface CommonShape extends VisualObject {
  text: string;
  fontName: string;
  fontSize: number;
  fontColor: number;
  fill: Fill;
  border: Border;
  opacity: number;
}

export interface EmbeddedShape extends CommonShape {
  title: string;
  alt: string;
}

export interface EmbeddedFileShape extends EmbeddedShape {
  fileId: string;
  type: EmbeddedFileTypeEnum;
}

export enum EmbeddedFileTypeEnum {
    Picture = 'Picture',
    Document = 'Document'
}

export interface Fill {
  color: number;
}

export interface Border {
    color: number;
    thickness: number;
}

export interface UploadedFile {
  fileId: string;
  type: EmbeddedFileTypeEnum;
  src: string;
  width?: number;
  height?: number;
}

export interface ImagePreviewData {
  src: string;
  fileId: string;
  shapeId?: string;
  width?: number;
  height?: number;
}

