import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
  isFileUploading,
  isProfileSaving,
  removeReferenceLettersFile,
  saveProfileReferenceLetters,
  selectReferenceLetters,
  setProfileTitle,
  uploadReferenceLettersFile,
} from '../../+state';
import { environment } from '../../../../environments/environment';
import { ReferenceLetters } from '../../../api/models';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-reference',
  templateUrl: './profile-reference.component.html',
  styleUrls: ['./profile-reference.component.scss'],
})
export class ProfileReferenceComponent implements OnInit {
  localStorageUser: any;
  form: UntypedFormGroup;
  saving$: Observable<boolean>;
  uploading = false;
  token: string;
  environment = environment;

  private title = $localize`Рекомендательные письма`;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profileLocalStorageService: ProfileLocalStorageService,
    private authStorage: OAuthStorage,
    private store: Store,
  ) {}

  ngOnInit() {
    this.createForm();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.token = this.authStorage.getItem('access_token');
    this.subscribeStore();
  }

  subscribeStore() {
    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.localStorageUser = user;
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectReferenceLetters),
        filter(referenceLetters => referenceLetters),
        tap(referenceLetters => {
          this.updateForm(referenceLetters);
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(isFileUploading),
        tap(uploading => {
          this.uploading = uploading;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving));
  }

  createForm() {
    this.form = this.formBuilder.group({
      documents: [[]],
    });
  }

  updateForm(data: ReferenceLetters) {
    this.form.patchValue(data);
  }

  get documents() {
    return this.form.get('documents');
  }

  setProfile() {
    this.store.dispatch(saveProfileReferenceLetters(this.form.value));
  }

  addFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files;
      const form = new FormData();

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < files.length; x++) {
        const file = files[x];
        form.append('files', file);
      }

      const data = {
        contentType: 'reference-letters',
        body: {
          files: form.getAll('files'),
          ownerEntityId: `${this.localStorageUser.id}`,
        },
      };

      this.store.dispatch(uploadReferenceLettersFile(data));
      event.target.value = null;
    }
  }

  removeFile(index: number) {
    const value = this.documents.value.filter((file, i) => i !== index);
    this.documents.patchValue(value);

    this.store.dispatch(removeReferenceLettersFile({ data: this.documents.value }));
  }
}
