<div class="container">
  <div class="general-info">
    <div class="preview">
      <img [src]="block?.iconSrc ?? 'assets/img/block-default.png'" />
    </div>
    <div class="specification">
      <div class="title">{{ block?.name | transloco }}</div>
      <div class="creator-row">
        <a
          routerLink="/teachers/{{ block?.authorId }}"
          class="author"
          >{{ block?.author }}</a
        >
        <div
          class="share-icon-block"
          *ngIf="isMobile"
          (click)="recommend()"
        >
          <img src="assets/img/svg/blocks/share-icon.svg" />
        </div>
      </div>
      <div class="details">
        <div
          *ngFor="let detail of details"
          class="detail"
        >
          <img
            class="detail-img"
            [src]="detail.iconSrc"
          />
          <div class="detail-description">
            {{ detail.name | transloco }}{{ detail.count ? '\: ' + detail.count : '' }}
          </div>
        </div>
      </div>
      <div class="price">{{ 'Бесплатно' | transloco }}</div>
      <div class="buttons">
        <ezteach-btn
          *ngIf="!isMobile && !editMode"
          [type]="buttonType.notFilled"
          [width]="'150px'"
          [height]="'38px'"
          class="button"
          (click)="backToBlocks()"
        >
          <div content>{{ 'К списку блоков' | transloco }}</div>
        </ezteach-btn>
        <ezteach-btn
          *ngIf="user?.isTutor && !editMode"
          class="button"
          [width]="'150px'"
          [height]="'38px'"
          (click)="edit()"
        >
          <div content>{{ 'Редактировать' | transloco }}</div>
        </ezteach-btn>
        <ezteach-btn
          *ngIf="!isMobile && !editMode"
          [type]="buttonType.notFilled"
          [width]="'150px'"
          [height]="'38px'"
          class="button"
          (click)="recommend()"
        >
          <div content>{{ 'Посоветовать' | transloco }}</div>
        </ezteach-btn>
        <ezteach-btn
          *ngIf="editMode"
          [type]="buttonType.filled"
          [width]="'150px'"
          [height]="'38px'"
          class="button"
          (click)="save()"
        >
          <div content>{{ 'Сохранить' | transloco }}</div>
        </ezteach-btn>
        <ezteach-btn
          *ngIf="editMode"
          [type]="buttonType.notFilled"
          [width]="'150px'"
          [height]="'38px'"
          class="button"
          (click)="cancelEdit()"
        >
          <div content>{{ 'Отменить' | transloco }}</div>
        </ezteach-btn>
      </div>
    </div>
  </div>
  <div class="description">
    {{ block?.description | transloco }}
  </div>
  <div
    class="tasks"
    *ngIf="tasks.length"
  >
    <div class="tasks-wrapper">
      <ezteach-block-task
        *ngFor="let task of tasks; index as i"
        [task]="task"
        [index]="i + 1"
        [isMobile]="isMobile"
        [isEdit]="editMode"
        (onRemove)="removeTask($event)"
      ></ezteach-block-task>
    </div>
  </div>
  <ezteach-btn
    *ngIf="user?.isTutor"
    [height]="'38px'"
    [width]="'196px'"
    (click)="addHomework()"
  >
    <div content>{{ 'Добавить задание' | transloco }}</div>
  </ezteach-btn>
  <mat-divider></mat-divider>
  <div class="lessons">
    <div class="lessons-wrapper">
      <ezteach-block-lesson
        *ngFor="let lesson of lessons; index as i"
        [lesson]="lesson"
        [index]="i + 1"
        [isMobile]="isMobile"
        [isEdit]="editMode"
        (onRemove)="removeLesson($event)"
      ></ezteach-block-lesson>
    </div>
    <ezteach-btn
      #createLessonButton
      *ngIf="user?.isTutor"
      [height]="'38px'"
      [width]="'196px'"
      (click)="addLesson()"
    >
      <div content>{{ 'Добавить событие' | transloco }}</div>
    </ezteach-btn>
  </div>
</div>
