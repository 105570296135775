import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional } from '@angular/core';
import { UiIconsRegistry } from './icons-registry.service';

@Component({
  selector: 'ezteach-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.ezteach-icon]': 'true',
    '[class.ezteach-icon-active]': `isActive`,
    '[class.ezteach-icon-fill]': `onlyFill`,
    '[class.ezteach-icon-disabled]': `disabled`,
    '[class.ezteach-icon-strokeless]': `_iconName === 'pen'`,
  },
})
export class IconComponent {
  private svgIcon: SVGElement | undefined;

  constructor(
    private element: ElementRef,
    private iconRegistry: UiIconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: any,
  ) {}

  @Input()
  onlyFill: boolean;
  @Input()
  disabled: boolean;
  @Input()
  isActive: boolean;
  _iconName = '';
  @Input()
  set name(iconName: string) {
    this._iconName = iconName;
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.iconRegistry.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  private svgElementFromString(svgContent: string | undefined): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }
}
