import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppFileDropDirective } from './file-drop.directive';
import { FileInputComponent } from './file-input.component';
import { AppFileSelectDirective } from './file-select.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AppFileSelectDirective, AppFileDropDirective, FileInputComponent],
  exports: [AppFileSelectDirective, AppFileDropDirective, FileInputComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FileModule {}
