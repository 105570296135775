import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { WINDOW } from '@ng-web-apis/common';

export enum Langs {
  RU = 'ru',
  KK = 'kk',
  EN = 'en',
}

@Injectable({ providedIn: 'root' })
export class LangStoreService {
  private readonly currentLangKey = 'CurrentLang';
  constructor(private localStorageService: LocalStorageService, @Inject(WINDOW) private windowRef: Window) {}

  setCurrentLang(lang: Langs) {
    this.localStorageService.set(this.currentLangKey, lang);
    this.windowRef.location.reload();
  }

  getCurrentLang(): Langs {
    const lang = this.localStorageService.get(this.currentLangKey) ?? Langs.RU;
    this.setCultureCookie(lang);
    return lang;
  }

  getLangCode(lang: Langs): string {
    if (lang === Langs.EN) {
      return 'en-US';
    }
    if (lang === Langs.KK) {
      return 'kk-KK';
    }
    return 'ru-RU';
  }

  setCultureCookie(lang: Langs) {
    const expireDate = new Date(2147483647 * 1000).toUTCString();
    const expires = `expires=${expireDate}`;
    const name = '.AspNetCore.Culture';
    const value = `c=${this.getLangCode(lang)}|uic=${this.getLangCode(lang)}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }
}
