import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'modal-logout-confirm',
  templateUrl: 'modal-logout-confirm.component.html',
})
export class ModalLogoutConfirm implements OnInit {
  textContent: string;

  constructor(
    public dialogRef: MatDialogRef<ModalLogoutConfirm>,
    @Inject(MAT_DIALOG_DATA) private data,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.textContent = this.data.isTutor
      ? this.translocoService.translate(`У вас есть незавершённые события. Событие не будет завершено, даже если вы выйдете.`)
      : this.translocoService.translate(
          `У вас есть незавершённые события. Вы всё равно будете числиться в участниках события, даже если выйдете.`,
        );
  }

  finish() {
    this.dialogRef.close('logout');
  }

  close() {
    this.dialogRef.close('continue');
  }
}
