import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.css'],
})
export class FinancesComponent {
  widgets: number[] = [];

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.widgets = [1, 2];
    this._cdr.detectChanges();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.widgets, event.previousIndex, event.currentIndex);

    const widgetsArray = [];

    for (let x = 0; x < this.widgets.length; x++) {
      widgetsArray.push({
        isVisible: true,
        sortIndex: x,
        widgetType: 'OnlineStatus', // this.widgets[x]
      });
    }
  }
}
