import { StreamManager } from 'openvidu-browser';
import { StreamUserData } from '../models/stream-user-data';

const OPENVIDU_DATA_SPLITTER = '%/%';

export function getStreamData(subscriber: StreamManager): StreamUserData | null {
  if (subscriber.stream?.connection?.data?.includes(OPENVIDU_DATA_SPLITTER)) {
    const data = subscriber.stream.connection.data.split(OPENVIDU_DATA_SPLITTER)[0];
    const dataObj = JSON.parse(data)?.clientData;
    return dataObj;
  }

  if (subscriber.stream?.session?.connection?.data?.includes(OPENVIDU_DATA_SPLITTER)) {
    const data = subscriber.stream.session.connection.data.split(OPENVIDU_DATA_SPLITTER)[0];
    const dataObj = JSON.parse(data)?.clientData;
    return dataObj;
  }
  return null;
}
