import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ezteach-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRangeComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  dateStart: Date;
  @Input()
  dateEnd: Date;
  @Output()
  dateStartChanged = new EventEmitter<Date>();
  @Output()
  dateEndChanged = new EventEmitter<Date>();
  date: Date;
  timeStart: string;
  timeEnd: string;
  events = ['blur', 'keyup'];

  @ViewChild('timeStartInput', { read: ElementRef, static: true })
  timeStartInput: ElementRef;
  @ViewChild('timeEndInput', { read: ElementRef, static: true })
  timeEndInput: ElementRef;

  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    fromEvent(this.timeStartInput.nativeElement, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.timeStartChanged(this.timeStart));

    fromEvent(this.timeEndInput.nativeElement, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.timeEndChanged(this.timeEnd));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateStart && changes.dateStart.currentValue) {
      const start = changes.dateStart.currentValue as Date;
      this.date = start;
      this.timeStart = this.datePipe.transform(start, 'HH:mm');
      this.cdr.detectChanges();
    }
    if (changes.dateEnd && changes.dateEnd.currentValue) {
      const end = changes.dateEnd.currentValue as Date;
      this.timeEnd = this.datePipe.transform(end, 'HH:mm');
      this.cdr.detectChanges();
    }
  }

  dateChanged($event: Date | null): void {
    if ($event) {
      const dateStart = new Date($event.toDateString() + ' ' + this.timeStart);
      const dateEnd = new Date($event.toDateString() + ' ' + this.timeEnd);
      this.dateStartChanged.emit(dateStart);
      this.dateEndChanged.emit(dateEnd);
    }
  }
  timeStartChanged($event: string): void {
    const newDate = new Date(this.dateStart.toDateString() + ' ' + $event);
    this.dateStartChanged.emit(newDate);
  }

  timeEndChanged($event: string): void {
    const newDate = new Date(this.dateEnd.toDateString() + ' ' + $event);
    this.dateEndChanged.emit(newDate);
  }
}
