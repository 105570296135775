import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { TimeType } from '@ezteach/group-lesson/pipes/time-declaration/time-declaration.pipe';

@Component({
  selector: 'modal-group-lesson-finish',
  templateUrl: 'modal-group-lesson-finish.component.html',
})
export class ModalGroupLessonFinish {
  timeDuration: number;
  hasHours = false;
  hasMinutes = false;
  hasSeconds = false;
  timeType = TimeType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalGroupLessonFinish>,
    private groupLessonService: GroupLessonService,
  ) {
    this.groupLessonService.timeDuration$.subscribe(v => {
      this.timeDuration = v;
      this.hasHours = new Date(this.timeDuration).getHours() > 0;
      this.hasMinutes = new Date(this.timeDuration).getMinutes() > 0;
      this.hasSeconds = new Date(this.timeDuration).getSeconds() > 0;
    });
  }

  finish() {
    this.dialogRef.close('finish');
  }

  close() {
    this.dialogRef.close('close');
  }
}
