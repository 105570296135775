import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ezteach/shared';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { ActiveLessonItemModule } from '@ezteach/_components/active-lesson-item/active-lesson-item.module';
import { LessonItemModule } from '@ezteach/_components/lesson_item/lesson_item.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { MyLessonsWidgetComponent } from './my-lessons-widget.component';
import { BlocksModule } from '@ezteach/blocks/blocks.module';

@NgModule({
  imports: [
    CommonModule,
    ActiveLessonItemModule,
    LessonItemModule,
    IconSpriteModule,
    RouterModule,
    SharedModule,
    TranslocoRootModule,
    BlocksModule
  ],
  declarations: [MyLessonsWidgetComponent],
  exports: [MyLessonsWidgetComponent],
})
export class MyLessonsWidgetModule {}
