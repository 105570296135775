<img src="assets/img/svg/lang.svg" />
<mat-form-field class="lang-switch-select">
  <mat-select
    [(ngModel)]="lang"
    (selectionChange)="onLangChange($event)"
  >
    <mat-option
      *ngFor="let item of langs"
      [value]="item.id"
    >
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>