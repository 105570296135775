<ezteach-popup-with-bg [smiley]="smileyType.sad">
  <div title>{{ 'Занятие по ссылке не найдено' | transloco }}</div>
  <div content>
    {{ 'Возможно, вы ошиблись при' | transloco }} <br />
    {{ 'вводе строки' | transloco }}.
  </div>
  <div buttons>
    <ezteach-btn (click)="back()">
      <div content> {{ 'Назад на платформу' | transloco }}</div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>
