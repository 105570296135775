<div class="container">
  <div
    class="select-box"
    [class.active]="dropDownOpened"
    #selectBox
  >
    <div
      (click)="dropDownOpened = !dropDownOpened"
      class="selected-value"
    >
      <div
        class="text"
        *ngIf="!showValueWithPlaceholder"
        [class.placeholder]="!selectedValue"
      >{{ selectedValue ? selectedValue.displayValue : placeholder ? placeholder : "Выберите значение"}}</div>
      <div
        class="text placeholderValue"
        *ngIf="showValueWithPlaceholder"
        [class.placeholder]="!selectedValue"
      >
        <div class="placeholder">{{placeholder}}</div>
        <div
          class="value"
          *ngIf="selectedValue"
        >{{selectedValue.displayValue}}</div>
      </div>
      <div
        class="triangle"
        [class.hide]="selectedValue"
        *ngIf="(!selectedValue && showValueWithPlaceholder) || !showValueWithPlaceholder"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="9"
          fill="none"
        >
          <path
            fill="#92929D"
            fill-rule="evenodd"
            d="M9.266.5a.5.5 0 0 1 .384.82L5.717 6.04a.5.5 0 0 1-.768 0L1.017 1.32A.5.5 0 0 1 1.4.5h7.865Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        (click)="clearSelection()"
        class="clear"
        *ngIf="selectedValue"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_96_7388)">
            <line
              x1="14.2305"
              y1="5.84573"
              x2="5.74522"
              y2="14.331"
              stroke="#44444F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="bevel"
            />
            <line
              x1="5.74524"
              y1="5.66895"
              x2="14.2305"
              y2="14.1542"
              stroke="#44444F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="bevel"
            />
          </g>
          <defs>
            <clipPath id="clip0_96_7388">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(9.89948 0.100464) rotate(45)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div
      class="options"
      *ngIf="options.length && dropDownOpened"
    >
      <div
        *ngFor="let option of options"
        class="option"
        [ngClass]="{ 'active': option.value === selectedValue?.value }"
        (click)="changeValue(option)"
      >
        <div class="text">{{ option.displayValue }}</div>
      </div>
    </div>
  </div>
</div>