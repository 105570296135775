<mat-form-field
  class="my-form-field"
  [style.height]="height ? height : ''"
  [ngClass]="{ 'shorten-link-invite': isInvite, 'shorten-link-guest-invite': isGuestInvite }"
>
  <div
    class="icon"
    (click)="copyLink()"
    [title]="('Скопировать' | transloco) "
  >
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 7.125H11.875V4.48875C11.8729 3.92879 11.6495 3.39236 11.2536 2.99641C10.8576 2.60045 10.3212 2.37709 9.76125 2.375H4.48875C3.92879 2.37709 3.39236 2.60045 2.99641 2.99641C2.60045 3.39236 2.37709 3.92879 2.375 4.48875V9.76125C2.37709 10.3212 2.60045 10.8576 2.99641 11.2536C3.39236 11.6495 3.92879 11.8729 4.48875 11.875H7.125V14.25C7.125 14.8799 7.37522 15.484 7.82062 15.9294C8.26602 16.3748 8.87011 16.625 9.5 16.625H14.25C14.8799 16.625 15.484 16.3748 15.9294 15.9294C16.3748 15.484 16.625 14.8799 16.625 14.25V9.5C16.625 8.87011 16.3748 8.26602 15.9294 7.82062C15.484 7.37522 14.8799 7.125 14.25 7.125ZM7.125 9.5V10.2917H4.48875C4.34807 10.2917 4.21316 10.2358 4.11369 10.1363C4.01422 10.0368 3.95833 9.90193 3.95833 9.76125V4.48875C3.95833 4.34807 4.01422 4.21316 4.11369 4.11369C4.21316 4.01422 4.34807 3.95833 4.48875 3.95833H9.76125C9.90193 3.95833 10.0368 4.01422 10.1363 4.11369C10.2358 4.21316 10.2917 4.34807 10.2917 4.48875V7.125H9.5C8.87011 7.125 8.26602 7.37522 7.82062 7.82062C7.37522 8.26602 7.125 8.87011 7.125 9.5Z"
        [style.fill]="isInvite ? '#3DD598' : isGuestInvite ? '#B98EF0' : '#678AFF'"
      />
    </svg>
  </div>
  <input
    matInput
    [value]="shortLink"
    [readonly]="true"
  />
  <span
    *ngIf="tooltipContent"
    class="info-icon"
    #tooltip="matTooltip"
    (click)="tooltip.toggle()"
    matTooltipPosition="above"
    [matTooltip]="tooltipContent | transloco"
    matTooltipClass="tooltip"
    [matTooltipPosition]="tooltipPosition"
    (click)="onMoreClick($event)"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9997 0C3.13422 0 0 3.13422 0 6.9997C0 10.8652 3.13422 14 6.9997 14C10.8652 14 14 10.8652 14 6.9997C14 3.13422 10.8652 0 6.9997 0ZM8.45689 10.8486C8.09659 10.9908 7.80978 11.0987 7.59467 11.1733C7.38015 11.248 7.13067 11.2853 6.84681 11.2853C6.41067 11.2853 6.07111 11.1787 5.82933 10.9659C5.58756 10.7532 5.46726 10.4836 5.46726 10.1559C5.46726 10.0284 5.47615 9.89807 5.49393 9.76533C5.5123 9.63259 5.54133 9.48326 5.58104 9.31556L6.032 7.72267C6.0717 7.56978 6.10607 7.42459 6.13333 7.28948C6.16059 7.15318 6.17363 7.02815 6.17363 6.91437C6.17363 6.7117 6.13156 6.56948 6.048 6.48948C5.96326 6.40948 5.80385 6.37037 5.56622 6.37037C5.45007 6.37037 5.33037 6.38756 5.2077 6.4237C5.08622 6.46104 4.98074 6.49481 4.89422 6.528L5.01333 6.03733C5.30844 5.91704 5.59111 5.81393 5.86074 5.72859C6.13037 5.64207 6.38519 5.59941 6.62519 5.59941C7.05837 5.59941 7.39259 5.70489 7.62785 5.91348C7.86193 6.12267 7.97985 6.39467 7.97985 6.72889C7.97985 6.79822 7.97156 6.9203 7.95556 7.09452C7.93956 7.26933 7.90933 7.42874 7.86548 7.57511L7.41689 9.16326C7.38015 9.29067 7.34756 9.43644 7.31793 9.59941C7.28889 9.76237 7.27467 9.88682 7.27467 9.97037C7.27467 10.1813 7.32148 10.3253 7.4163 10.4018C7.50993 10.4782 7.67407 10.5167 7.90637 10.5167C8.016 10.5167 8.13867 10.4972 8.27733 10.4593C8.41481 10.4213 8.51437 10.3876 8.57719 10.3585L8.45689 10.8486ZM8.37748 4.40237C8.1683 4.59674 7.91644 4.69393 7.62193 4.69393C7.328 4.69393 7.07437 4.59674 6.86341 4.40237C6.65363 4.208 6.54756 3.97156 6.54756 3.69541C6.54756 3.41985 6.65422 3.18281 6.86341 2.98667C7.07437 2.78993 7.328 2.69215 7.62193 2.69215C7.91644 2.69215 8.16889 2.78993 8.37748 2.98667C8.58667 3.18281 8.69156 3.41985 8.69156 3.69541C8.69156 3.97215 8.58667 4.208 8.37748 4.40237Z"
        [style.fill]="isInvite ? '#3DD598' : isGuestInvite ? '#B98EF0' : '#678AFF'"
      />
    </svg>
  </span>
</mat-form-field>