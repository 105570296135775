import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ChatMessageAttachment } from '@ezteach/api/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ezteach-group-lesson-chat-file',
  templateUrl: './group-lesson-chat-file.component.html',
  styleUrls: ['./group-lesson-chat-file.component.scss'],
})
export class GroupLessonChatFileComponent implements OnInit, OnChanges {
  @Input() attachment: ChatMessageAttachment;
  @Output() onAttachmentDownLoad = new EventEmitter<ChatMessageAttachment>();
  fileUrl = '';
  fileSize: number;
  fileName = '';
  attachmentUrl: string;
  previewUrl = '';
  mouseEntered = false;
  isImage = false;
  imgLoaded: boolean;

  constructor(
    private dialog: MatDialog,
    private oauthService: OAuthService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { attachment } = changes;
    if (!attachment) {
      return;
    }

    this.isImage = attachment.currentValue.attachmentTypeId === 'Picture';

    if (attachment.currentValue.previewFileName) {
      this.previewUrl = attachment.currentValue.previewFileName + '?access_token=' + this.oauthService.getAccessToken(); //this.sanitizer.bypassSecurityTrustResourceUrl(attachment.currentValue.previewFileName + '?access_token=' + this.oauthService.getAccessToken());
    }
    this.fileSize = attachment.currentValue.fileSize;
    this.fileName = attachment.currentValue.sourceFileName;
    this.attachmentUrl = attachment.currentValue.fileName + '?access_token=' + this.oauthService.getAccessToken();
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.mouseEntered = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseEntered = false;
  }

  download() {
    this.onAttachmentDownLoad.emit(this.attachment);
  }

  imageCompleted() {
    this.imgLoaded = true;
  }
  openImage(template: TemplateRef<any>) {
    this.dialog.open(template, {panelClass: 'modal-lesson-chat__preview'});
  }

  closeImage() {
    this.dialog.closeAll();
  }
}
