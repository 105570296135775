import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { EmbeddedFileTypeEnum, ObjectDescription } from '../classes/objects-args';
import { SceneObjectTypeEnum, VisualSceneObject } from '../classes/scene';
import { ShapeTypes } from '../classes/white-board';
import { flatPointsToXY } from '../shapes/math/freedraw';
import { ShapeTextAttrsConfig } from '../shapes/shape-attrs-config';
import { num, numToUnSigned, parse, rgbToRgba } from './color.convertor';

export enum FontStyle {
  'none' = 1,
  'bold' = 2,
  'italic' = 4,
  'underline' = 8
}

export enum TextAlign {
  'left',
  'center',
  'right',
  'justify',
}

export class WhiteBoardConvertors {
  static convertShapeToCreateObjectDescription(shape: Shape | Konva.Group): ObjectDescription {
    switch (shape.attrs.type) {
      case ShapeTypes.file:
      case ShapeTypes.image:
        return this.convertToFile(shape);
      default:
        return this.convertToShape(shape);
    }
  }

  private static convertToShape(shape: Shape | Konva.Group) {
    const attrs = shape.attrs;
    let newAttrs = JSON.parse(JSON.stringify(attrs));
    if (newAttrs.fill) {
      newAttrs.fill = rgbToRgba(newAttrs.fill);
      newAttrs.fill = { color: numToUnSigned(num(newAttrs.fill)) };
    }

    newAttrs.border = rgbToRgba(newAttrs.border);
    newAttrs.border = { color: numToUnSigned(num(newAttrs.stroke)), thickness: newAttrs.strokeWidth, style: 'solid' };
    if (newAttrs.points) {
      newAttrs.points = flatPointsToXY(newAttrs.points).map(x => {
        return { x: x[0], y: x[1] };
      });
    }

    newAttrs = this.rotationAttrRename(newAttrs);

    if (shape.attrs.type === ShapeTypes.sticker) {
      this.fillAttrsSticker(newAttrs, shape as Konva.Group);
    }
    // TODO: После добавления параметров на бэке можно удалить
    if (shape instanceof Konva.Text) {
      this.fillAttrsText(newAttrs, shape as Konva.Text);
    }

    return {
      oId: attrs.ezId,
      data: {
        type: this.convertShapeTypeToSceneObjectType(newAttrs),
        args: `  ${JSON.stringify(newAttrs)}  `,
      },
    };
  }

  private static fillAttrsSticker(source: any, shape: Konva.Group) {
    const rectShape = shape.children.find(x => x.constructor.name === Konva.Rect.name);
    const textShape = shape.children.find(x => x.constructor.name === Konva.Text.name);
    source.fill = rgbToRgba(rectShape ? rectShape.attrs.fill : shape.attrs.fill);
    source.fill = { color: numToUnSigned(num(source.fill)) };
    if ((rectShape ?? shape).attrs.stroke) {
      source.border = rgbToRgba(rectShape ? rectShape.attrs.stroke : shape.attrs.stroke);
      source.border = {
        color: numToUnSigned(num(source.border)),
        thickness: rectShape ? rectShape.attrs.strokeWidth : shape.attrs.strokeWidth ?? 1,
        style: 'solid',
      };
    }

    source.text = textShape ? textShape.attrs.text : shape.attrs.text;
    source.width = rectShape ? rectShape.attrs.width : shape.attrs.width;
    source.height = rectShape ? rectShape.attrs.height : shape.attrs.height;
  }

  private static fillAttrsText(source: any, shape: Konva.Text) {
    const attrs = this.convertToShapeTextAttrs(shape);
    const text = shape.text();
    const fontStyleKeys = Object.keys(FontStyle).filter(style => 
      FontStyle[style] === attrs.fontStyle || 
      FontStyle[style] === attrs.fontWeight ||
      FontStyle[style] === attrs.textDecoration);
      
    const fontStyle = (fontStyleKeys.length > 0 ? fontStyleKeys : [FontStyle.none]).map(style => Number(style))
    source.text = text;
    source.fontName = attrs.fontFamily;
    source.fontSize = attrs.fontSize;
    source.fontStyle = fontStyle;
    source.textAlign = Number(Object.keys(TextAlign).find(align => TextAlign[align] === attrs.textAlign));
  }

  static rotationAttrRename(attrs: VisualSceneObject): Exclude<VisualSceneObject, 'rotation'> {
    Object.assign(attrs, { rotate: attrs.rotation });
    delete attrs.rotation;
    return attrs;
  }

  static convertToFile(shape: Shape | Konva.Group) {
    const attrs = shape.attrs;
    let newAttrs = JSON.parse(JSON.stringify(attrs));
    const isFile = shape.attrs.type === ShapeTypes.file;
    newAttrs.type = isFile ? EmbeddedFileTypeEnum.Document : SceneObjectTypeEnum.Image;

    const type = isFile ? SceneObjectTypeEnum.Document : SceneObjectTypeEnum.Image;
    newAttrs = this.rotationAttrRename(newAttrs);

    return {
      oId: attrs.ezId,
      data: {
        type,
        args: `${JSON.stringify(newAttrs)}`,
      },
    };
  }

  static convertShapeTypeToSceneObjectType(attrs: any) {
    switch (attrs.type) {
      case ShapeTypes.rect:
        return SceneObjectTypeEnum.Rectangle;
      case ShapeTypes.ellipse:
        return SceneObjectTypeEnum.Ellipse;
      case ShapeTypes.triangle:
        return SceneObjectTypeEnum.Triangle;
      case ShapeTypes.freeline:
        return SceneObjectTypeEnum.Pencil;
      case ShapeTypes.text:
        return SceneObjectTypeEnum.Text;
      case ShapeTypes.sticker:
        return SceneObjectTypeEnum.Note;
      case ShapeTypes.file:
        return SceneObjectTypeEnum.Document;
      case ShapeTypes.image:
        return SceneObjectTypeEnum.Image;
    }
    return null;
  }

  static convertCreateObjectDescriptionToShape(object: ObjectDescription): any {
    const argsObject = JSON.parse(object.data.args);
    const color = argsObject.fill?.color !== undefined ? 'rgba(' + parse(argsObject.fill.color) + ')' : '';
    const borderColor =
      argsObject.border?.color !== undefined ? 'rgba(' + parse(argsObject.border.color) + ')' : 'black';
    const shapeType = this.convertToSceneObjectTypeToShapeType(object.data.type, argsObject);
    if (argsObject.points) {
      argsObject.points = this.pointDToFlat(argsObject.points);
      if (argsObject.points.length === 2) {
        argsObject.points.push(argsObject.points[0] + 0.5);
        argsObject.points.push(argsObject.points[1] + 0.5);
      }
    }
    const text = this.isJson(argsObject.text) ? JSON.parse(argsObject.text)?.text : argsObject.text;
    const textAttrs: ShapeTextAttrsConfig = {};
    textAttrs.fontFamily = argsObject.fontName;
    textAttrs.fontSize = argsObject.fontSize;
    if (argsObject.fontStyle) {
      textAttrs.fontStyle =
        argsObject.fontStyle.indexOf(FontStyle.italic) === -1 ? 'normal' : FontStyle[FontStyle.italic];
      textAttrs.fontWeight = argsObject.fontStyle.indexOf(FontStyle.bold) === -1 ? 'normal' : FontStyle[FontStyle.bold];
      textAttrs.textDecoration =
        argsObject.fontStyle.indexOf(FontStyle.underline) === -1 ? 'normal' : FontStyle[FontStyle.underline];
    }
    if (argsObject.textAlign) {
      textAttrs.textAlign = TextAlign[argsObject.textAlign];
    }

    return {
      ezId: object.oId,
      fill: color,
      height: argsObject.height,
      lineCap: 'round',
      lineJoin: 'round',
      offsetX: 0,
      offsetY: 0,
      stroke: borderColor,
      strokeWidth: argsObject.border?.thickness ?? 0,
      type: shapeType,
      width: argsObject.width,
      x: argsObject.x,
      y: argsObject.y,
      z: argsObject.z,
      radiusX: argsObject.radiusX,
      radiusY: argsObject.radiusY,
      radius: argsObject.radius,
      sides: argsObject.sides ? Math.round(argsObject.sides) : undefined,
      locked: object.locked,
      points: argsObject.points,
      rotation: argsObject.rotate,
      text: text,
      textAttrs: textAttrs,
      opacity: argsObject.opacity,
      fileId: argsObject.fileId,
      scaleX: argsObject.scaleX,
      scaleY: argsObject.scaleY,
    };
  }

  static convertToSceneObjectTypeToShapeType(type: SceneObjectTypeEnum | string, attr: any): ShapeTypes | null {
    switch (type.toString().toLowerCase()) {
      case SceneObjectTypeEnum.Rectangle:
        return ShapeTypes.rect;
      case SceneObjectTypeEnum.Ellipse:
        return ShapeTypes.ellipse;
      case SceneObjectTypeEnum.Triangle:
        return ShapeTypes.triangle;
      case SceneObjectTypeEnum.Pencil:
        return ShapeTypes.freeline;
      case SceneObjectTypeEnum.Text:
        return ShapeTypes.text;
      case SceneObjectTypeEnum.Note:
        return ShapeTypes.sticker;
      case SceneObjectTypeEnum.Document:
        return ShapeTypes.file;
      case SceneObjectTypeEnum.Image:
        return ShapeTypes.image;
    }
    return null;
  }

  static pointDToFlat(points: any) {
    const p = [];
    for (let i = 0; i < points.length - 1; i++) {
      p.push(points[i].x);
      p.push(points[i].y);
    }
    return p;
  }

  static convertToShapeTextAttrs(shape: Konva.Text): ShapeTextAttrsConfig {
    const attrs = {
      fontFamily: shape.fontFamily()?.replaceAll('"', ''),
      fontSize: shape.fontSize(),
      fontStyle: shape.fontStyle(),
      fontWeight: shape.fontVariant(),
      textDecoration: shape.textDecoration(),
      textAlign: shape.align(),
    } as ShapeTextAttrsConfig;
    return attrs;
  }

  private static isJson(value: string): boolean {
    try {
      const json = this.toJson(value);
      return !!json;
    } catch (e) {
      return false;
    }
  }

  private static toJson(value: string) {
    return JSON.parse(value);
  }
}
