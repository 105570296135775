import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConvaMember } from '../classes/white-board';

@Injectable()
export class WhiteBoardUserService {
  members$ = new BehaviorSubject<ConvaMember[]>([]);
  memberMouseUpdate$ = new Subject<{ memberId: number; x: number; y: number; name?: string }>();
  memberRemoved$ = new Subject<number>();
  enableFullPageMode$ = new BehaviorSubject<boolean>(false);

  initUsers(members: ConvaMember[]) {
    this.members$.next(members);
  }

  addUser(member: ConvaMember) {
    const added = this.members$.value.find(x => x.userId === member.userId);
    if (!added) {
      this.members$.value.push(member);
      this.members$.next([...this.members$.value]);
    }
  }

  removeUser(userId: number) {
    const index = this.members$.value.findIndex(x => x.userId === userId);
    if (index !== -1) {
      this.members$.value.splice(index, 1);
      this.members$.next([...this.members$.value]);

      this.memberRemoved$.next(userId);
    }
  }

  clearUsers() {
    this.members$.value.forEach(x => {
      this.memberRemoved$.next(x.userId);
    });
    this.members$.next([]);
  }

  updateMemberMouse(data: { memberId: number; x: number; y: number; name?: string }) {
    data.name = this.members$?.value?.find(x => x.userId === data.memberId && x.isOwner)?.userName;
    this.memberMouseUpdate$.next(data);
  }

  dispose() {
    this.members$.next([]);
  }
}
