import { User } from '@ezteach/api/models';

export enum WhiteBoardOperations {
  addShape = 'addShape',
  removeShape = 'removeShape',
}

export abstract class ShapeHistoryState {}

export interface HistoryEventSnapshot {
  whiteBoardOperations: WhiteBoardOperations;
  shapeId: string;
  shapeType: ShapeTypes;
  shapeState: ShapeHistoryState;
}

export enum ShapeTypes {
  rect = 'rect',
  triangle = 'triangle',
  ellipse = 'ellipse',
  freeline = 'freeline',
  text = 'text',
  sticker = 'sticker',
  file = 'file',
  image = 'image',
}

export interface Position {
  x: number;
  y: number;
}

export const WidthOffSetBase = 0; // 220;
export const HeightOffsetBase = 0; //56;

export interface ConvaMember {
  wId: string;
  userId: number;
  model: User;
  isOwner: boolean;
  accessRights: string;
  userName: string;
  avatarPath: string;
  avatarIsGiven: boolean;
}

/*
 * режим отображения ватйборда
 * self - только доска в отдельном экране
 * inside - внутри каких-либо окон (например внутри видеозвонка)
 */
export enum WhiteBoardMode {
  self = 'self',
  inside = 'inside',
}

/*
 * Описывает данные для загрузки превью изображения
 */
export interface ImageShapeMeta {
  shapeId: string;
  fileId: string;
  width?: number;
}
