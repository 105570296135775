export enum UserDocumentTypeEnum {
  Generic = 'Generic',
  Passport = 'Passport',
  QualificationCategory = 'QualificationCategory',
  AcademicDegree = 'AcademicDegree',
  AcademicTitle = 'AcademicTitle',
  SecondaryEducation = 'SecondaryEducation',
  HigherEducation = 'HigherEducation',
  ReferenceLetter = 'ReferenceLetter',
}
