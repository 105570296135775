<div class="ez-cal-input-wrapper">
  <mat-form-field appearance="outline">

    <input
      class="ez-input"
      matInput
      type="text"
      [placeholder]="placeholder"
      [ngModel]="value"
      (ngModelChange)="changed($event)"
      maxlength="50"
    >
  </mat-form-field>
</div>
