import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDocument } from '@ezteach/api/models/file-document';
import { environment } from '@ezteach/enviroments';
import { IResponse } from '../models/response.model';

export interface IFeedbackRequestType {
  name: string;
  id: number;
}

interface IFeedback {
  requestType: number;
  message: string;
  attachments: FileDocument[];
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  get baseUrl() {
    return `${environment.apiUrl}/api/v1`;
  }

  constructor(private readonly http: HttpClient) {}

  feedbackRequestTypes() {
    return this.http.get<IResponse<IFeedbackRequestType[]>>(`${this.baseUrl}/dictionaries/feedbackrequesttype`);
  }

  uploadFeedbackAttachment(file: File, ownerEntityId: string) {
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('ownerEntityId', ownerEntityId);

    return this.http.post<IResponse<FileDocument[]>>(`${this.baseUrl}/files/usercontent/feedback-attachment`, formData);
  }

  sendFeedback(feedback: IFeedback) {
    return this.http.post<IResponse<IFeedback[]>>(`${this.baseUrl}/feedback/registration`, feedback);
  }
}
