<div class="modal__header">
  <div class="modal__icon">
    <img src="/assets/img/svg/icon-smile-bad.svg" />
  </div>
</div>
<div class="modal__content modal__content--icon">
  <div class="modal__title">{{ 'Вы действительно хотите выйти' | transloco }}?</div>
  <div
    class="modal__text"
    style="max-width: 40rem"
  >
    {{ textContent }}
  </div>
  <div class="modal__actions">
    <a
      class="btn btn--red btn--full"
      [ngStyle]="{ marginBottom: '10px' }"
      (click)="finish()"
    >
      <span class="btn__label">{{ 'Да' | transloco }} </span>
    </a>
    <a
      class="btn btn--blue btn--full"
      [ngStyle]="{ marginLeft: 0 }"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Нет' | transloco }} </span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>