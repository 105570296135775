export enum CabinetPageModeEnum {
  Unknown = 'Unknown',
  StudentHome = 'StudentHome',
  TutorHome = 'TutorHome',
  StudentProfile = 'StudentProfile',
  TutorProfile = 'TutorProfile',
  TutorProfileWizard = 'TutorProfileWizard',
  UserNotifications = 'UserNotifications',
  TutorSearchInstant = 'TutorSearch_Instant',
  TutorSearchScheduled = 'TutorSearch_Scheduled',
  TutorCalendar = 'TutorCalendar',
  Calendar = 'Calendar',
  LessonHistory = 'LessonHistory',
  TutorDetails = 'TutorDetails',
  Messenger = 'Messenger',
}
