<div class="container">
  <div class="create-homework-container">
    <div class="create-homework-container__header-row">
      <div class="create-homework-container__header-row-title">{{ 'Домашнее задание' | transloco }}:</div>
      <ng-content *ngTemplateOutlet="closeButton"></ng-content>
    </div>
    <div class="create-homework-container__flex-block">
      <mat-select
        #homeworkSelectElement
        (selectionChange)="selectHomeWork($event)"
        (openedChange)="onOpenedHomeworkSelect($event)"
        (closed)="onClosedHomeworkSelect()"
        class="select-custom"
        [placeholder]="'Домашнее задание' | transloco"
      >
        <mat-option>{{ 'Не указано' | transloco }}</mat-option>
        <mat-option
          *ngFor="let homework of homeworks"
          [value]="homework.id"
        >
          {{ 'Домашнее задание' | transloco }}: {{ homework.name | transloco }}
        </mat-option>
        <mat-option
          *ngIf="!isLoadedHomeworkPage && !homeworks?.length"
          disabled="true"
        >
          {{ 'Загрузка домашних заданий...' | transloco }}
        </mat-option>
      </mat-select>
      <div class="create-homework-container__actions">
        <ezteach-btn
          [type]="buttonType.notFilled"
          (click)="close()"
          [width]="'100%'"
        >
          <div content>
            {{ 'Отмена' | transloco }}
          </div>
        </ezteach-btn>
        <ezteach-btn
          (click)="save()"
          [width]="'100%'"
        >
          <div content>
            {{ 'Сохранить' | transloco }}
          </div>
        </ezteach-btn>
      </div>
    </div>
  </div>
</div>
<ng-template #closeButton>
  <span
    class="close-button"
    (click)="close()"
  >
    <img
      src="assets/img/svg/icon-close_dark.svg"
      alt="closeButton"
    />
  </span>
</ng-template>
