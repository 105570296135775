import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomSnackBarModule } from '@ezteach/_components/custom-snackbar/custom-snack-bar.module';
import { CustomToggleComponent } from '@ezteach/_components/custom-toggle/custom-toggle.component';
import { NotificationComponent } from '@ezteach/_components/notification/notification.component';
import { ShortenLinkComponent } from '@ezteach/_components/shorten-link/shorten-link.component';
import { LocalDateNameSpaceService } from '@ezteach/_services/localDateNameSpace.service';
import { DigitOnlyDirective } from '@ezteach/directives/digit-only.directive';
import { OvVideoMergeComponent } from '@ezteach/group-lesson/components/ov-video/ov-video.component';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { RESIZE_OPTION_BOX, ResizeObserverModule, ResizeObserverService } from '@ng-web-apis/resize-observer';
import { ReactiveComponentModule } from '@ngrx/component';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiDataListModule, TuiDropdownModule, TuiHostedDropdownModule } from '@taiga-ui/core';
import { NgCapitalizePipeModule } from 'angular-pipes';
import { AngularResizedEventModule } from 'angular-resize-event';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NgxMaskModule } from 'ngx-mask';
import { SharedComponentsModule } from './components';
import { DateLabelComponent } from './components/icon-label/date-label.component';
import { IconLabelComponent } from './components/icon-label/icon-label.component';
import { TimeLabelComponent } from './components/icon-label/time-label.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { SharedDirectivesModule } from './directives';
import { FixedPositionDirective } from './directives/fixed-position.directive';
import { FileModule } from './file';
import { PipesModule } from './pipes';
import { AssetsPreloadService, CachedImagesRegistryService } from './services';

const MATERIAL_MODULES = [
  MatDialogModule,
  MatInputModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDividerModule,
  MatIconModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatGridListModule,
  MatTabsModule,
  MatSliderModule,
  MatStepperModule,
  MatButtonModule,
  MatSelectModule,
  MatListModule,
  LayoutModule,
  TranslocoRootModule,
];
const NG_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  DragDropModule,
  ScrollingModule,
  OverlayModule,
  AngularResizedEventModule,
  PlatformModule,
  NgCapitalizePipeModule,
];
const LIB_MODULES = [IconSpriteModule, ReactiveComponentModule, ResizeObserverModule];
const CUSTOM_MODULES = [PipesModule, SharedDirectivesModule, FileModule, SharedComponentsModule, CustomSnackBarModule];
const COMPONENTS = [
  DatePickerComponent,
  DateRangePickerComponent,
  ShortenLinkComponent,
  OvVideoMergeComponent,
  NotificationComponent,
  IconLabelComponent,
  DateLabelComponent,
  TimeLabelComponent,
  CustomToggleComponent,
];

const DIRECTIVES = [DigitOnlyDirective, FixedPositionDirective];

const TAIGA_UI_MODULES = [
  TuiHostedDropdownModule,
  TuiDropdownModule,
  TuiDataListModule,
  TuiActiveZoneModule,
  TuiButtonModule,
];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [...NG_MODULES, ...MATERIAL_MODULES, ...LIB_MODULES, ...CUSTOM_MODULES, NgxMaskModule, ...TAIGA_UI_MODULES],
  exports: [
    ...NG_MODULES,
    ...MATERIAL_MODULES,
    ...LIB_MODULES,
    ...CUSTOM_MODULES,
    ...COMPONENTS,
    ...TAIGA_UI_MODULES,
    ...DIRECTIVES,
  ],
  providers: [
    CachedImagesRegistryService,
    AssetsPreloadService,
    {
      provide: APP_INITIALIZER,
      useFactory: (assets: AssetsPreloadService) => () => assets.preloadImages(),
      deps: [AssetsPreloadService],
      multi: true,
    },
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
    LocalDateNameSpaceService,
  ],
})
export class SharedModule { }
