<div
  class="toggle"
  (click)="toggle()"
>
  <input
    class="toggle-input"
    type="checkbox"
  />
  <button
    class="toggle-btn"
    [ngClass]="{ 'toggle-btn-on': checked, 'toggle-btn-off': !checked }"
    [attr.aria-expanded]="checked"
  ></button>
</div>
