<div
  class="settings"
  *ngIf="loaded"
>
  <div class="settings_header">
    <div class="settings_header__title">{{ 'Настройки' | transloco }}</div>
  </div>
  <form
    id="settingsForm"
    action=""
    method="get"
    (ngSubmit)="save()"
  >
    <div
      class="settings_block"
      *ngIf="currentData"
    >
      <div class="settings_block__left">
        <div class="settings_block__title">{{ 'Уведомления' | transloco }}</div>
        <div class="settings_block__text">
          <p>{{ 'Настройка этого блока позволит выбрать, как сервис будет уведомлять вас о событиях' | transloco }}
          </p>
        </div>
      </div>
      <div class="settings_block__right">
        <div class="settings_cols">
          <div
            class="settings_cols__col"
            *ngIf="notLoad"
          >
            <div class="settings_cols__title">{{ 'Настройки уведомлений' | transloco }}</div>
            <div
              class="settings_cols__list notifications"
              *ngIf="currentData.generalSettings"
            >
              <div
                class="settings_cols__item"
                *ngFor="let method of Notificationmethods"
              >
                <mat-slide-toggle
                  [name]="method.id"
                  [checked]="currentData.generalSettings.userNotificationMethodIds.includes(method.id)"
                >{{ method.name }}</mat-slide-toggle>
              </div>
            </div>
          </div>
          <!-- 					<div class="settings_cols__col" *ngIf="accLoad">
						<div class="settings_cols__title">Синхронизация с аккаунтами</div>
						<div class="settings_cols__list accounts" >
							<div class="settings_cols__item settings_cols__item--image" *ngFor="let method of Externalaccounttypesforsync">
								<mat-checkbox [name]="method.id" [checked]="currentData.generalSettings.userExternalAccountTypeForSyncIds.includes(method.id)">{{method.name}}</mat-checkbox>
								<img src="/assets/img/content/icon-{{method.id}}.svg" />
							</div>
						</div>
					</div> -->
        </div>
        <!-- 				<div class="settings_cols">
					<div class="settings_cols__col" *ngIf="calLoad">
						<div class="settings_cols__title">Синхронизация с календарями</div>
						<div class="settings_cols__list calendars" >
							<div class="settings_cols__item settings_cols__item--image" *ngFor="let method of Externalcalendartypesforsync">
								<mat-checkbox [name]="method.id" [checked]="currentData.generalSettings.userExternalCalendarTypeForSyncIds.includes(method.id)">{{method.name}}</mat-checkbox>
								<img src="/assets/img/content/icon-{{method.id}}.svg" />
							</div>
						</div>
					</div>
				</div> -->
      </div>
    </div>
    <div
      class="settings_block"
      *ngIf="userdata && userdata.isTutor"
    >
      <div class="settings_block__left">
        <div class="settings_block__title">{{ 'Стоимость событий' | transloco }}</div>
        <div class="settings_block__text">
          <p>
            {{ 'Настройка этого блока позволит выставлять уровень цен для разных типов событий' | transloco }}. <br />
            {{ 'Блоки, которые не
            будут выделены или у которых не будет выставлена цена, не будут выводится в поиске участников' | transloco }}
          </p>
        </div>
      </div>
      <div class="settings_block__right">
        <div class="settings_cols">
          <div
            class="settings_cols__col"
            *ngIf="calLoad"
          >
            <div class="settings_cols__title">{{ 'Стоимость события в час' | transloco }}</div>
            <div class="settings_cols__list"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="settings_actions">
    <button
      class="btn btn--blue btn--submit"
      type="submit"
      (click)="save()"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
  </div>
</div>