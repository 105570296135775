import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogNavigationStep } from '../helpers';

@Component({
  template: `
    <ezteach-tutorial-dialog-wrapper>
      <div class="welcome-step-image">
        <img
          class="ezteach-tutorial-dialog__image"
          [attr.src]="'/assets/img/tutorial/onboarding-teacher/onboarding-welcome.svg' | cachedImage"
        />
      </div>
      <h1 class="ezteach-tutorial-dialog__heading">Добро пожаловать!</h1>
      <p class="ezteach-tutorial-dialog__info">
        Давайте сделаем так, чтобы вам было легко преподавать! Вы сможете быстро найти себе участника
      </p>
      <div class="ezteach-tutorial-dialog__actions">
        <ezteach-button
          minWidth="248px"
          (click)="goNextStep()"
          >Начнем!</ezteach-button
        >
      </div>
    </ezteach-tutorial-dialog-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .welcome-step-image {
        padding: 5px 100px 10px 78px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step0WelcomeDialogComponent extends DialogNavigationStep {
  constructor(
    protected readonly store: Store<any>,
    protected readonly dialogRef: MatDialogRef<Step0WelcomeDialogComponent>,
  ) {
    super();
  }
}
