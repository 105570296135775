<div
  class="results_none"
  *ngIf="wait"
>
  <div class="global__icon">
    <svg-icon-sprite
      [src]="'icon-smile-good'"
      [width]="'96px'"
      [height]="'96px'"
      [viewBox]="'0 0 96 96'"
    ></svg-icon-sprite>
  </div>
  <div class="global__title"> {{ 'Запрос на подтверждение статуса организатора отправлен' | transloco }}</div>
  <div class="global__text">
    {{ 'На подтверждение может уйти до 12 часов, наберитесь терпения. Мы пришлем вам уведомление и e-mail с ответом' |
    transloco }}.
  </div>
</div>
<div
  class="results_none"
  *ngIf="declined"
>
  <div class="global__icon">
    <svg-icon-sprite
      [src]="'icon-smile-bad'"
      [width]="'80px'"
      [height]="'79px'"
      [viewBox]="'0 0 80 79'"
    ></svg-icon-sprite>
  </div>
  <div class="global__title"> {{ 'Запрос на подтверждение статуса организатора был отклонен' | transloco }}</div>
  <div class="global__text"></div>
</div>
<div
  class="wizard"
  *ngIf="show"
>
  <div class="wizard_header">
    <div class="wizard_header__title">{{ 'Подтверждение личных данных' | transloco }}</div>
  </div>
  <div class="wizard_content">
    <div class="wizard_content__left">
      <p>Этапы регистрации:</p>
      <div class="profile_nav">
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/passport"
            [class]="{ active: mod === 'passport' }"
          >1. {{ 'Паспортные данные' | transloco }}</a>
        </div>
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/avatar"
            [class]="{ active: mod === 'avatar' }"
            *ngIf="modstep >= 1"
          >2. {{ 'Фотография' | transloco }}</a>
          <span *ngIf="modstep < 1">2. {{ 'Фотография' | transloco }}</span>
        </div>
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/education"
            [class]="{ active: mod === 'education' }"
            *ngIf="modstep >= 2"
          >3. {{ 'Образование' | transloco }} </a>
          <span *ngIf="modstep < 2">3. {{ 'Образование' | transloco }}</span>
        </div>
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/work"
            [class]="{ active: mod === 'work' }"
            *ngIf="modstep >= 3"
          >4. {{ 'Трудовая история' | transloco }} </a>
          <span *ngIf="modstep < 3">4. {{ 'Трудовая история' | transloco }}</span>
        </div>
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/specs"
            [class]="{ active: mod === 'specs' }"
            *ngIf="modstep >= 4"
          >5. {{ 'Специализация' | transloco }} </a>
          <span *ngIf="modstep < 4">5. {{ 'Специализация' | transloco }} </span>
        </div>
        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/reference"
            [class]="{ active: mod === 'reference' }"
            *ngIf="modstep >= 5"
          >6. {{ 'Рекомендательные письма' | transloco }} </a>
          <span *ngIf="modstep < 5">6. {{ 'Рекомендательные письма' | transloco }} </span>
        </div>

        <div
          class="wizard_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/wizard/bank"
            [class]="{ active: mod === 'bank' }"
            *ngIf="modstep >= 6"
          >7. {{ 'Финансовая информация' | transloco }}</a>
          <span *ngIf="modstep < 6">7. {{ 'Финансовая информация' | transloco }}</span>
        </div>
      </div>
    </div>
    <div
      class="wizard_content__right"
      *ngIf="loaded"
    >
      <div
        class="profile_avatar"
        *ngIf="mod === 'avatar'"
      >
        <div class="profile_content__title"> {{ 'Загрузка фотографии' | transloco }} </div>
        <div
          class="profile_image"
          style="width: 14rem; margin: 0 0 2rem"
        >
          <img
            src=""
            *ngIf="givenAvatar"
            src="{{ givenAvatar }}"
          />
          <div
            class="profile_image__letters"
            *ngIf="!givenAvatar"
          >
            {{ giverInitials }}
          </div>

          <input
            id="avatar"
            multiple
            type="file"
            (change)="avatarChange($event)"
          />
        </div>
        <label
          for="avatar"
          class="btn btn--blue-border"
        >
          <span class="btn__label">{{ 'Загрузить' | transloco }} </span>
        </label>
        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.avatar"
          >
            <p>{{ 'Загрузите фотографию' | transloco }} </p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveAvatar()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_passport"
        *ngIf="mod === 'passport'"
      >
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <div class="profile_content__title">{{ 'Личная информация' | transloco }} </div>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Фамилия' | transloco }} </mat-label>
              <input
                matInput
                name="lastName"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.lastName }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Имя' | transloco }} </mat-label>
              <input
                matInput
                name="firstName"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.firstName }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Отчество' | transloco }} </mat-label>
              <input
                matInput
                name="patronymic"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.patronymic }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <div class="profile_content__title">{{ 'Адрес регистрации' | transloco }} </div>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Улица, дом, квартира' | transloco }}</mat-label>
              <input
                matInput
                name="addressDetails"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.registrationAddress?.addressDetails }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Город' | transloco }} </mat-label>
              <input
                matInput
                name="city"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.registrationAddress?.city }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Область' | transloco }} </mat-label>
              <input
                matInput
                name="region"
                value="{{ currentData.passportDetails?.registrationAddress?.region }}"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="profile_content__title">{{ 'Подтверждение личности' | transloco }}</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label> {{ 'Номер паспорта (без пробелов)' | transloco }}</mat-label>
              <input
                matInput
                name="number"
                (blur)="blurValue($event)"
                mask="9999 999999"
                value="{{ currentData.passportDetails?.number }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Дата выдачи (ДД.ММ.ГГГГ)' | transloco }} </mat-label>
              <input
                matInput
                name="issueDate"
                (blur)="blurValueDate($event)"
                mask
                value="{{ currentData.passportDetails?.issueDate }}"
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Кем выдан' | transloco }} </mat-label>
          <input
            matInput
            name="issueAuthority"
            (blur)="blurValue($event)"
            value="{{ currentData.passportDetails?.issueAuthority }}"
          />
        </mat-form-field>

        <div class="profile_content__subtitle">{{ 'Загруженные файлы паспорта' | transloco }} </div>
        <div
          class="files_list"
          *ngIf="currentData.passportDetails?.documentFiles?.length !== 0"
        >
          <ng-container *ngFor="let file of currentData.passportDetails?.documentFiles; let i = index">
            <div
              class="file__wrap"
              *ngIf="file"
            >
              <a
                class="file"
                href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{ userdata.id }}/passport?redirect=true&file={{
                  file.fileName
                }}&access_token={{ token }}"
                target="_blank"
              >
                <span class="file__icon">
                  <img src="/assets/img/svg/icon-file.svg" />
                </span>
                <span class="file__info">
                  <span class="file__name">{{ file.displayFileName }}</span>
                  <span class="file__size">{{ size(file.fileSize) }}</span>
                </span>
              </a>
              <div class="file__delete"><a (click)="removePassportFile(i)">Удалить</a></div>
            </div>
          </ng-container>
        </div>

        <input
          id="passport"
          class="hideinput"
          multiple
          type="file"
          (change)="addPassportFile($event)"
        />
        <label
          for="passport"
          class="btn btn--blue-border"
        >
          <span class="btn__label">Загрузить</span>
        </label>

        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.passport"
          >
            <p>{{ 'Заполните все поля и загрузите сканы паспорта' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="savePassport()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_specs"
        *ngIf="mod === 'specs'"
      >
        <div class="profile_content__title">{{ 'Специализация' | transloco }}:</div>
        <div class="profile__row">
          <mat-form-field>
            <input
              type="text"
              name="discipline"
              [value]="discipline | transloco"
              [(ngModel)]="discipline"
              [placeholder]="'Предмет' | transloco"
              matInput
              [formControl]="disciplinesControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
            >
              <mat-option
                *ngFor="let discipline of disciplinesFiltered | async"
                [value]="discipline | transloco"
              >
                {{ discipline | transloco }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field><br />
          <a
            class="btn btn--blue-border"
            (click)="addDiscipline()"
          >
            <span class="btn__label">{{ 'Добавить предмет' | transloco }}</span>
          </a>
        </div>
        <hr />
        <ng-container *ngIf="disciplinesCurrent">
          <div *ngFor="let dis of disciplinesCurrent; let i = index">
            <div
              class="discipline"
              *ngIf="dis"
            >
              <div class="discipline__title">{{ dis.name | transloco}}</div>
              <div class="discipline__list">
                <label *ngFor="let spec of getSpecs(dis.id)">
                  <input
                    type="checkbox"
                    class="spec"
                    name="spec"
                    value="{{ spec.id }}"
                    [checked]="isSpec(spec.id)"
                  />
                  {{ spec.specialization.name | transloco}}
                </label>
              </div>
              <div class="discipline__delete"><a (click)="removeDiscipline(i)">{{ 'Удалить' | transloco }}</a></div>
              <hr />
            </div>
          </div>
        </ng-container>
        <div class="profile_content__title">{{ 'Стоимость события' | transloco }}:</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Быстрый событие (за 5 минут)' | transloco }}</mat-label>
              <input
                matInput
                name="instantLesson5MinuteRate"
                [mask]
                alias="decimal"
                (focus)="selectAll($event)"
                placeholder="Быстрый событие (за 5 минут)"
                value="{{ currentData.specialityDetails?.instantLesson5MinuteRate }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Классическое событие (за 45 минут)' | transloco }}</mat-label>
              <input
                matInput
                name="scheduledLessonHourlyRate"
                [mask]
                alias="decimal"
                (focus)="selectAll($event)"
                placeholder="Классическое событие (за 45 минут)"
                value="{{ currentData.specialityDetails?.scheduledLessonHourlyRate }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="disciplinesError"
          >
            <p>{{ 'Укажите, как минимум одну специализацию у каждого выбранного предмета' | transloco }}</p>
          </div>
          <div
            class="error_label"
            *ngIf="errors.specs"
          >
            <p>{{ 'Добавьте, как минимум один предмет' | transloco }}</p>
          </div>
          <div
            class="error_label"
            *ngIf="errors.price"
          >
            <p>{{ 'Укажите стоимость событий' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveSpecs()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_education"
        *ngIf="mod === 'education'"
      >
        <div class="profile_content__title">{{ 'Среднее/Среднее специальное образование' | transloco }}</div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'SecondaryEducation'">
            <div class="group secondary">
              <mat-form-field class="profile_content__input">
                <mat-label>{{ 'Название' | transloco }}</mat-label>
                <input
                  matInput
                  class="name"
                  (blur)="blurValue($event)"
                  name="educationalInstitutionName{{ ind }}"
                  value="{{ doc.educationalInstitutionName }}"
                />
              </mat-form-field>
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Начало обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="from"
                      matInput
                      mask
                      name="enrollmentDate{{ ind }}"
                      value="{{ doc.enrollmentDate }}"
                      [matDatepicker]="SecEnrollmentDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="SecEnrollmentDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #SecEnrollmentDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Конец обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="to"
                      matInput
                      mask
                      value="{{ doc.graduateDate }}"
                      [matDatepicker]="SecGraduateDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="SecGraduateDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #SecGraduateDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addSecFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/secondary-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ size(file.fileSize) }}</span>
                      </span>
                    </a>
                    <div class="file__delete"><a (click)="removeSecFile(i, ind)">{{ 'Удалить' | transloco }}</a></div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeSec(ind)">{{ 'Удалить запись' | transloco }} </a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addSec()"
        >
          <span class="btn__label">{{ 'Добавить школу' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__title">{{ 'Высшее образование' | transloco }} </div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'HigherEducation'">
            <div class="group high">
              <mat-form-field class="profile_content__input">
                <mat-label>{{ 'Название' | transloco }}</mat-label>
                <input
                  matInput
                  class="name"
                  (blur)="blurValue($event)"
                  name="educationalInstitutionName{{ ind }}"
                  value="{{ doc.educationalInstitutionName }}"
                />
              </mat-form-field>
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Начало обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="from"
                      matInput
                      mask
                      (blur)="blurValue($event)"
                      name="enrollmentDate{{ ind }}"
                      value="{{ doc.enrollmentDate }}"
                      [matDatepicker]="HighEnrollmentDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="HighEnrollmentDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #HighEnrollmentDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Конец обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="to"
                      matInput
                      mask
                      name="graduateDate{{ ind }}"
                      value="{{ doc.graduateDate }}"
                      [matDatepicker]="HighGraduateDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="HighGraduateDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #HighGraduateDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }} </div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addHighFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/higher-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ size(file.fileSize) }}</span>
                      </span>
                    </a>
                    <div class="file__delete"><a (click)="removeHighFile(i, ind)">{{ 'Удалить' | transloco }}</a></div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeHigh(ind)">{{ 'Удалить запись' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addHigh()"
        >
          <span class="btn__label">{{ 'Добавить вуз' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__title">{{ 'Ученая степень (если есть)' | transloco }} </div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'AcademicDegree'">
            <div class="group academic">
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Название' | transloco }} </mat-label>
                    <select
                      matNativeControl
                      class="name"
                      name="academicDegreeName{{ ind }}"
                    >
                      <option
                        *ngFor="let item of academicDegrees"
                        [selected]="item.id === doc.academicDegreeId"
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Дата присвоения (ДД.ММ.ГГГГ)' | transloco }} </mat-label>
                    <input
                      class="date"
                      matInput
                      mask
                      (blur)="blurValue($event)"
                      name="issueDate{{ ind }}"
                      value="{{ doc.issueDate }}"
                      [matDatepicker]="AcademicIssueDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="AcademicIssueDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #AcademicIssueDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }} </div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addAcademicFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
                fuf
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/academic-degree?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ size(file.fileSize) }}</span>
                      </span>
                    </a>
                    <div class="file__delete"><a (click)="removeAcademicFile(i, ind)">{{ 'Удалить' | transloco }}</a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeAcademic(ind)">{{ 'Удалить запись' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addAcademic()"
        >
          <span class="btn__label">{{ 'Добавить ученую степень' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.education"
          >
            <p>{{ 'Добавьте хотя бы одно образование' | transloco }}</p>
          </div>
          <div
            class="error_label"
            *ngIf="educationError"
          >
            <p>{{ 'Заполните все поля по каждой записи' | transloco }} </p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveEducation()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_reference"
        *ngIf="mod === 'reference'"
      >
        <div class="profile_content__subtitle"> {{ 'Загруженные файлы рекомендательных писем' | transloco }}</div>
        <div
          class="reference_list"
          *ngIf="currentData.referenceLetters.documents.length !== 0"
        >
          <ng-container *ngFor="let item of currentData.referenceLetters.documents; let i = index">
            <div
              class="reference_item"
              *ngIf="item"
            >
              <div
                class="reference_item__image"
                *ngIf="item"
              >
                <img
                  src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                    userdata.id
                  }}/reference-letters?redirect=true&file={{ item.documentFiles[0].fileName }}&access_token={{ token }}"
                />
              </div>
              <div
                class="reference_item__content"
                *ngIf="item"
              >
                <div class="reference_item__name">{{ item.documentFiles[0].displayFileName }}</div>
                <div class="reference_item__delete"><a (click)="removeReference(i)">{{ 'Удалить' | transloco }} </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <input
          id="reference"
          class="hideinput"
          multiple
          type="file"
          (change)="addReference($event)"
        />
        <label
          for="reference"
          class="btn btn--blue-border"
        >
          <span class="btn__label">Загрузить</span>
        </label>

        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveReference()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }} </span>
          </button>
        </div>
      </div>
      <div
        class="profile_work"
        *ngIf="mod === 'work'"
      >
        <div class="profile_content__title">{{ 'Данные трудовой книжки' | transloco }} </div>

        <ng-container *ngFor="let item of currentData.workExperience?.workPlaces; let i = index">
          <div class="workCompany">
            <div
              class="profile_content__cols"
              *ngIf="item"
            >
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Учреждение' | transloco }} </mat-label>
                  <input
                    matInput
                    name="companyName[{{ i }}]"
                    (blur)="blurValue($event)"
                    class="companyName"
                    value="{{ item.companyName }}"
                  />
                </mat-form-field>
              </div>
            </div>

            <div
              class="profile_content__cols"
              *ngIf="item"
            >
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Начало рабочей деятельности' | transloco }}</mat-label>
                  <input
                    matInput
                    name="enrollmentDate[{{ i }}]"
                    mask
                    (blur)="blurValue($event)"
                    class="enrollmentDate"
                    value="{{ item.enrollmentDate }}"
                    [matDatepicker]="WorkEnrollmentDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="WorkEnrollmentDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WorkEnrollmentDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Окончание рабочей деятельности' | transloco }} </mat-label>
                  <input
                    matInput
                    name="leavingDate[{{ i }}]"
                    mask
                    class="leavingDate"
                    value="{{ item.leavingDate }}"
                    [matDatepicker]="WorkLeavingDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="WorkLeavingDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WorkLeavingDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div
              class="profile_content__delete"
              *ngIf="item"
            >
              <a (click)="removeWork(i)">{{ 'Удалить запись' | transloco }}</a>
            </div>
          </div>
        </ng-container>

        <button
          class="btn btn--blue-border"
          (click)="addWork()"
        >
          <span class="btn__label">{{ 'Добавить запись' | transloco }}</span>
        </button>
        <br /><br />

        <div class="profile_content__subtitle">{{ 'Загруженные файлы трудовой книжки' | transloco }}</div>
        <div
          class="reference_list"
          *ngIf="currentData.workExperience?.documentFiles?.length !== 0"
        >
          <ng-container *ngFor="let item of currentData.workExperience?.documentFiles; let i = index">
            <div
              class="reference_item"
              *ngIf="item"
            >
              <div
                class="reference_item__image"
                *ngIf="item"
              >
                <img src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                    userdata.id
                  }}/work-experience?redirect=true&file={{ item.fileName }}&access_token={{ token }}" />
              </div>
              <div
                class="reference_item__content"
                *ngIf="item"
              >
                <div class="reference_item__name">{{ item.displayFileName }}</div>
                <div class="reference_item__delete"><a (click)="removeWorkFile(i)">{{ 'Удалить' | transloco }} </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <input
          id="work"
          class="hideinput"
          multiple
          type="file"
          (change)="addWorkFile($event)"
        />
        <label
          for="work"
          class="btn btn--blue-border"
        >
          <span class="btn__label">{{ 'Загрузить' | transloco }} </span>
        </label>

        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.work"
          >
            <p>{{ 'Укажите, как минимум одно, место работы' | transloco }} </p>
          </div>
          <div
            class="error_label"
            *ngIf="workError"
          >
            <p>{{ 'Заполните название, начало и окончание рабочей деятельности по каждой записи' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveWork()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_bankcards"
        *ngIf="mod === 'bankcards'"
      >
        <div class="profile_content__title">{{ 'Ваши привязанные карты' | transloco }}</div>
        <div class="profile_cardlist">
          <div
            class="profile_card"
            *ngFor="let card of currentData.bankCardBindings.bankCards"
          >
            <div class="profile_card__wrap">
              <div class="profile_card__name">{{ card.cardHolderName }}</div>
              <div class="profile_card__type">{{ card.paymentSystemName }}</div>
              <div class="profile_card__num">{{ card.maskedPan }}</div>
              <div class="profile_card__exp">{{ card.expirationFormatted }}</div>
            </div>
            <mat-radio-button
              name="bankcard"
              value="{{ card.id }}"
              [checked]="currentData.bankCardBindings.defaultBankCardId == card.id"
            >{{ 'Основная карта' | transloco }}</mat-radio-button>
            <a
              class="profile_card__delete"
              (click)="removeBankcard(card.id)"
            >{{ 'Удалить карту' | transloco }}</a>
          </div>
        </div>
        <button
          class="btn btn--blue-border"
          (click)="addBankcard()"
        >
          <span class="btn__label">{{ 'Добавить новую карту' | transloco }}</span>
        </button>
        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveBankCards()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_bank"
        *ngIf="mod === 'bank'"
      >
        <div class="profile_content__title">{{ 'Данные банковского счета' | transloco }}</div>
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Номер счета' | transloco }}</mat-label>
          <input
            matInput
            name="accountNumber"
            (blur)="blurValue($event)"
            value="{{ currentData.financialDetails.bankDetails.accountNumber }}"
          />
        </mat-form-field>
        <div class="profile_content__title">{{ 'Информация о банке' | transloco }}</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Полное название' | transloco }}</mat-label>

              <input
                type="text"
                name="fullName"
                [value]="currentData.financialDetails.bankDetails.bank.fullName"
                [(ngModel)]="bankName"
                matInput
                [formControl]="bankControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                (optionSelected)="onBankChange()"
              >
                <mat-option
                  *ngFor="let bank of bankFiltered | async"
                  [value]="bank"
                >
                  {{ bank }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Краткое название' | transloco }}</mat-label>
              <input
                matInput
                name="shortName"
                value="{{ currentData.financialDetails.bankDetails.bank.shortName }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <mat-form-field class="profile_content__input">
            <mat-label>{{ 'Корр. счет' | transloco }}</mat-label>
            <input
              matInput
              name="correspondentAccountNumber"
              value="{{ currentData.financialDetails.bankDetails.bank.correspondentAccountNumber }}"
            />
          </mat-form-field>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'БИК' | transloco }}</mat-label>
              <input
                matInput
                name="bic"
                value="{{ currentData.financialDetails.bankDetails.bank.bic }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>SWIFT</mat-label>
              <input
                matInput
                name="swift"
                value="{{ currentData.financialDetails.bankDetails.bank.swift }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'КПП' | transloco }}</mat-label>
              <input
                matInput
                name="kpp"
                value="{{ currentData.financialDetails.bankDetails.bank.kpp }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Адрес' | transloco }}</mat-label>
              <input
                matInput
                name="address"
                value="{{ currentData.financialDetails.bankDetails.bank.address }}"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveBank()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
