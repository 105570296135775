import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

export interface SelectOptionType {
  value: any,
  displayValue: string
}

@Component({
  selector: 'app-select-component',
  templateUrl: './select-component.component.html',
  styleUrls: ['./select-component.component.scss'],
})
export class AppSelectComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() showValueWithPlaceholder: boolean = false;
  @Input() options: SelectOptionType[] = [];
  @Input() selectedValue: SelectOptionType | null = undefined;
  @Output() onChange: EventEmitter<SelectOptionType | null> = new EventEmitter<SelectOptionType | null>();
  @ViewChild('selectBox') selectBox: ElementRef;

  dropDownOpened: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (!this.selectBox.nativeElement.contains(target)) {
      this.dropDownOpened = false;
    }
  }

  clearSelection() {
    this.selectedValue = null;
    this.onChange.emit(null);
  }

  changeValue(newValue: SelectOptionType) {
    this.selectedValue = newValue;
    this.onChange.emit(newValue);
    this.dropDownOpened = false;
  }
}
