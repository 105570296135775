<div class="widget_bookmarks">
  <div class="widget_bookmarks__title"> {{ 'История событий' | transloco }} </div>
  <div class="widget_bookmarks__icon">
    <svg-icon-sprite
      [src]="'icon-header-history'"
      [width]="'46px'"
      [height]="'61px'"
      [viewBox]="'0 0 46 61'"
    ></svg-icon-sprite>
  </div>
  <div
    class="widget_bookmarks_none"
    *ngIf="results && results.totalRecords === 0"
  >
    <div class="widget_bookmarks_none__wrap">
      <div class="widget_bookmarks_none__icon">
        <svg-icon-sprite
          [src]="'icon-no-bookmarks'"
          [width]="'86px'"
          [height]="'80px'"
          [viewBox]="'0 0 86 80'"
        ></svg-icon-sprite>
      </div>
      <div class="widget_bookmarks_none__title"> {{ 'У вас пока нет истории событий' | transloco }} </div>
      <div
        class="widget_bookmarks_none__text"
        *ngIf="userdata.isTutor"
      >
        {{ 'Интерактивная доска для гостя пока не доступна' | transloco }}
        {{ 'Включайте режим' | transloco }}
        «{{ 'Я' | transloco }} &mdash; {{ 'онлайн' | transloco }} », {{ 'чтобы быстрее найти участника на быстрое событие»' |
        transloco }}
      </div>
      <div
        class="widget_bookmarks_none__text"
        *ngIf="userdata.isStudent"
      >
        {{ 'Воспользуйтесь удобным поиском организатора по любому предмету и специализации' | transloco }}
      </div>
      <div
        class="widget_bookmarks_none__button"
        *ngIf="userdata.isStudent"
      >
        <a
          routerLink="/search/regular"
          class="btn btn--blue"
        >
          <span class="btn__icon">
            <svg-icon-sprite
              [src]="'icon-search'"
              [width]="'20px'"
              [height]="'20px'"
              [viewBox]="'0 0 20 20'"
            ></svg-icon-sprite>
          </span>
          <span class="btn__label"> {{ 'Найти организатора' | transloco }} </span>
        </a>
      </div>
    </div>
  </div>
  <div
    class="widget_bookmarks-history__list"
    *ngIf="results && results.totalRecords > 0"
  >
    <lesson_item
      *ngFor="let item of lessons"
      [item]="item"
      class="lesson_item"
    ></lesson_item>
  </div>
  <div
    class="widget_bookmarks-history__all"
    *ngIf="results && results.totalRecords > 0"
  >
    <a routerLink="/history"> {{ 'Посмотреть всю историю' | transloco }}</a>
  </div>
</div>
