import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomSnackBarService } from '@ezteach/_components/custom-snackbar/custom-snack-bar.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomSnackBarItemInfo, trackBySnackBarItemInfo } from './custom-snack-bar.model';

@Component({
  selector: 'ezteach-snackbar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('snackbarAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(400, style({ opacity: 0 }))),
    ]),
  ],
})
export class CustomSnackBarComponent {
  readonly trackByFn = trackBySnackBarItemInfo;
  readonly items$: Observable<CustomSnackBarItemInfo[]> = this.customSnackBarService.messages$.pipe(
    map(messages =>
      messages.map(d => ({
        avatarSrc: d.avatarSrc ? this.domSanitizer.bypassSecurityTrustResourceUrl(d.avatarSrc) : undefined,
        contentText: d.contentText ? this.domSanitizer.bypassSecurityTrustHtml(d.contentText) : undefined,
        closeButtonText: d.closeButtonText,
        data: d,
        config: d,
      })),
    ),
  );

  constructor(
    private readonly customSnackBarService: CustomSnackBarService,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  onCloseButtonClick(info: CustomSnackBarItemInfo) {
    this.customSnackBarService.closeMessage(info.data);
  }
}
