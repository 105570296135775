import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { LangStoreService, Langs } from '../services/lang-store.service';

export const MONTH_NAMES_KK = [
  'Қаңт.',
  'Ақп.',
  'Наур.',
  'Сәу.',
  'Мам.',
  'Мау.',
  'Шіл.',
  'Там.',
  'Қыр.',
  'Қаз.',
  'Қар.',
  'Жел.',
];

export const MONTH_NAMES_RU = [
  'ЯНВ.',
  'ФЕВР.',
  'МАРТ',
  'АПР.',
  'МАЙ',
  'ИЮНЬ',
  'ИЮЛЬ',
  'АВГ.',
  'СЕНТ.',
  'ОКТ.',
  'НОЯБ.',
  'ДЕК.',
];

export const MONTH_NAMES_EN = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'МAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

@Injectable()
export class TranslateDateAdapter extends NativeDateAdapter {
  private readonly separator = '.';
  constructor(@Inject(LOCALE_ID) public locale, private langStoreService: LangStoreService) {
    super(locale);
  }

  parse(value: string): Date | null {
    const parts = value.split(this.separator);
    if (parts.length == 3) {
      return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]), 12);
    }
    return null;
  }

  format(date: Date, _: Object) {
    return (
      ('0' + date.getDate()).slice(-2) +
      this.separator +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      this.separator +
      date.getFullYear()
    );
  }

  getMonthNames() {
    if (this.langStoreService.getCurrentLang() === Langs.KK) {
      return MONTH_NAMES_KK
    }
    if (this.langStoreService.getCurrentLang() === Langs.RU) {
      return MONTH_NAMES_RU
    }
    return MONTH_NAMES_EN
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const TRANSLATE_DATE_LOCALE = {
  provide: MAT_DATE_LOCALE,
};
export const TRANSLATE_DATE_ADAPTER = {
  provide: DateAdapter,
  useClass: TranslateDateAdapter,
};
