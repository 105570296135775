import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TutorUserSearchResult } from '../api/models/tutor-user-search-result';
import { TutorUserSearchResultPagedApiResponse } from '../api/models/tutor-user-search-result-paged-api-response';
import { SearchService } from '../api/services/search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewportScroller } from '@angular/common';
import { debounceTime, first, tap } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
import { fromEvent } from 'rxjs';
import { UserService } from "@ezteach/_services/user.service";

@UntilDestroy()
@Component({
  selector: 'teachers',
  templateUrl: './teachers.component.html',
})
export class TeachersComponent implements OnInit {
  pageSize = 4;
  pageNumber = 1;
  loaded = false;

  results: TutorUserSearchResultPagedApiResponse;
  teachers: Array<TutorUserSearchResult>;

  Name: string;
  DisciplineName: string;
  SpecializationId: number;

  bookmarks;
  private currentScrollY: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private _cdr: ChangeDetectorRef,
    @Inject(WINDOW) private windowRef: Window,
    private viewportScroller: ViewportScroller,
    private userService: UserService
  ) {}

  ngOnInit() {
    const userdata = this.userService.userData$.value;
    if (!userdata.isTutor) {
      this.router.navigateByUrl('/');
    }

    this.route.queryParamMap.subscribe(queryParamMap => {
      this.Name = queryParamMap.get('name');
      this.DisciplineName = queryParamMap.get('discipline');
      this.SpecializationId = parseInt(queryParamMap.get('specialization'));
      this.getResults();
    });

    fromEvent(this.windowRef, 'scroll')
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(_ => this.searchService.scrollY$.next(this.windowRef.scrollY)),
      )
      .subscribe();

    this.searchService.scrollY$
      .pipe(
        untilDestroyed(this),
        tap(scrollY => (this.currentScrollY = scrollY)),
        first(),
      )
      .subscribe();

    this.searchService.pageNumber$
      .pipe(
        untilDestroyed(this),
        tap(pageNumber => (this.pageNumber = pageNumber)),
      )
      .subscribe();

    this.getResults();
    this._cdr.detectChanges();
  }

  getResults(): void {
    this.searchService
      .apiV1SearchTutorsScheduledGet({
        Name: this.Name,
        DisciplinesNames: this.DisciplineName ? [...this.DisciplineName] : null,
        SpecializationId: this.SpecializationId,
        PageSize: this.pageSize,
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.results = results;

        if (this.searchService.searchTutorHistory$.value) {
          this.teachers = this.searchService.searchTutorHistory$.value;
        } else {
          this.teachers = results.data;
          this.searchService.pageNumber$.next(this.pageNumber++);
        }
        this.loaded = true;

        this._cdr.detectChanges();
        this.viewportScroller.scrollToPosition([0, this.currentScrollY]);
      });
  }

  getMore(): void {
    this.searchService.pageNumber$.next(++this.pageNumber);

    this.searchService
      .apiV1SearchTutorsScheduledGet({
        Name: this.Name,
        DisciplinesNames: this.DisciplineName ? [...this.DisciplineName] : null,
        SpecializationId: this.SpecializationId,
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.loaded = true;
        this.results = results;

        this.teachers = this.teachers.concat(results.data);
        this.searchService.searchTutorHistory$.next(this.teachers);
        this._cdr.detectChanges();
      });
  }
}
