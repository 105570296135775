<div
  class="widget-my-lessons"
  [class]="{ 'half-view': isHalfView }"
>
  <div class="widget-my-lessons__title">{{ 'Мои события' | transloco }}</div>
  <div class="widget-my-lessons__actions">
    <div class="widget-my-lessons__nav">
      <div
        class="widget-my-lessons__nav-item"
        (click)="changeNavItem(navItem)"
        *ngFor="let navItem of navItems; trackBy: trackByNavItem"
        [ngClass]="navItem.isActive ? 'active' : ''"
      >
        {{ navItem.name | transloco }}
      </div>
    </div>

    <div
      class="widget-my-lessons__actions-buttons"
      *ngIf="navItems[2].isActive"
    >
      <ezteach-button
        class="selection-button"
        (click)="changeSelectMode(!isSelectMode)"
        i18n
      >
        {{ isSelectMode ? ('Отменить выбор' | transloco) : ('Выбрать события' | transloco) }}
      </ezteach-button>

      <button
        *ngIf="isSelectMode"
        [disabled]="selectedLessons.length === 0"
        class="delete-button"
        (click)="deleteLessons()"
      >
        {{ 'Удалить' | transloco }}
      </button>
    </div>
  </div>
  <div class="divider-row"></div>
  <div class="widget-my-lessons__content">
    <ezteach-button
      class="create-button"
      (click)="openCreateMeetingPopup()"
      *ngIf="userdata.isTutor && navItems[0].isActive"
    >
      <img
        class="create-button__icon"
        src="assets/img/svg/icon-add.svg"
        i18n-alt
        alt="Создать событие"
      />
      {{ 'Создать событие' | transloco }}
    </ezteach-button>

    <ezteach-button
      class="create-button"
      (click)="openCreateMeetingPopup()"
      *ngIf="userdata.isTutor && navItems[1].isActive && !selectedBlockService.selectedBlock$.value"
    >
      <img
        class="create-button__icon"
        src="assets/img/svg/icon-add.svg"
        i18n-alt
        alt="Создать блок"
      />
      {{ 'Создать блок' | transloco }}
    </ezteach-button>

    <div
      class="widget-my-lessons__list-wrapper"
      [class]="{ 'remove-top': userdata.isTutor && !navItems[0].isActive && !navItems[1].isActive }"
    >
      <ng-container *ngFor="let navItem of navItems">
        <div
          class="widget-my-lessons__list"
          *ngIf="navItem.isActive"
          [class]="{
            'half-view': isHalfView,
            'widget-lessons-empty-wrapper': lessons.length === 0 && navItem.id !== 'blocks'
          }"
        >
          <ng-container *ngIf="lessons.length > 0 || navItem.id === 'blocks'; else emptyLessons">
            <div class="widget-my-lessons__list__container">
              <ng-container *ngIf="navItem.id === 'blocks'">
                <ng-template *ngTemplateOutlet="blocks"></ng-template>
              </ng-container>

              <div
                class="widget-my-lessons__list__container__item"
                *ngFor="let lesson of lessons; trackBy: trackByChatLesson"
              >
                <ng-container *ngIf="navItem.id === 'active'; then activeLesson; else otherLesson"> </ng-container>

                <ng-template #activeLesson>
                  <ezteach-active-lesson-item [lesson]="lesson"> </ezteach-active-lesson-item>
                </ng-template>

                <ng-template #otherLesson>
                  <lesson_item [item]="lesson">
                    <mat-checkbox
                      first
                      class="widget-my-lessons__list__container__checkbox"
                      *ngIf="isSelectMode"
                      (click)="$event.stopPropagation()"
                      (change)="toggleSelectionLesson(lesson)"
                    ></mat-checkbox>
                  </lesson_item>
                </ng-template>
              </div>
            </div>
            <div
              class="search__action"
              *ngIf="totalPages > pageNumber"
            >
              <button class="btn btn--blue">
                <span
                  class="btn__label"
                  (click)="getMore(navItem.id)"
                  >{{ 'Показать еще' | transloco }}</span
                >
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #blocks>
  <ezteach-block-list (onAddLesson)="addLesson($event)"> </ezteach-block-list>
</ng-template>

<ng-template #emptyLessons>
  <div class="widget-lessons-empty">
    <div>
      <div>
        <img
          draggable="false"
          src="assets/img/svg/img-cloud.svg"
        />
      </div>
    </div>
    <div class="widget-lessons-empty__header">{{ 'У вас пока нет ваших событий' | transloco }}</div>
    <!--div class="widget-lessons-empty__text">
      {{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' | transloco }}
    </div-->
  </div>
</ng-template>
