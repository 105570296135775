import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../_services/local-storage.service';
import { RequestsService } from '../_services/requests.service';

@Component({
  selector: 'requests-student',
  templateUrl: './requests-student.component.html',
})
export class RequestsStudentComponent implements OnInit {
  requests = [];

  constructor(
    private _cdr: ChangeDetectorRef,
    private requestsService: RequestsService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.getResults();

    this.requestsService.onChanged.subscribe(data => {
      this.getResults();
    });
  }

  getResults() {
    this.requests = this.localStorageService.get('requests') ?? [];
    this._cdr.detectChanges();
  }
}
