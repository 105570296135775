import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ezteach-icon-label',
  template: `
    <img
      class="ezteach-icon-label__icon"
      [src]="icon | cachedImage"
    />
    <span class="ezteach-icon-label__title"> {{ label }}</span>
  `,
  styleUrls: ['./_style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IconLabelComponent {
  @HostBinding('class.ezteach-icon-label') className = true;
  @Input() icon: string;
  @Input() label: string;
}
