import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '@ezteach/_components/custom-snackbar/component/custom-snack-bar.component';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import {
  CustomSnackBarData,
  CustomSnackBarResult,
  CustomSnackBarDataConfig,
  CustomSnackBarMessageConfig,
  SNACK_BAR_MESSAGE_CONFIG,
  isEqualSnackBarData,
} from './component/custom-snack-bar.model';
import { map, filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CustomSnackBarService {
  private readonly count = 3;
  private readonly eventSubject = new Subject<CustomSnackBarResult>();
  private readonly messagesSubject = new BehaviorSubject<CustomSnackBarDataConfig[]>([]);
  public readonly messages$ = this.messagesSubject.pipe(
    map(messages => messages.slice(0, this.count).map(m => ({ ...m, opened: m.opened ?? new Date() }))),
  );

  constructor(private readonly snackbar: MatSnackBar) {
    const interval$ = interval(100);
    const messages$ = this.messages$.pipe(map(messages => messages.filter(m => !!m.duration)));

    combineLatest([interval$, messages$])
      .pipe(
        map(([_, messages]) =>
          messages.filter(m => m.opened && m.duration && m.opened.getTime() + m.duration <= new Date().getTime()),
        ),
        filter(messages => messages.length > 0),
        tap(messages => messages.forEach(d => this.closeMessage(d))),
        untilDestroyed(this),
      )
      .subscribe(messages => console.log('messages: ', messages));
  }

  showMessage(
    data: CustomSnackBarData | CustomSnackBarData[],
    config: CustomSnackBarMessageConfig = SNACK_BAR_MESSAGE_CONFIG,
  ): Observable<CustomSnackBarResult> {
    const messages = [...this.messagesSubject.value];
    if (data instanceof Array) {
      messages.push(...data.map(d => ({ ...d, ...config })));
    } else {
      messages.push({ ...data, ...config });
    }
    this.messagesSubject.next(messages);
    this.snackbar.openFromComponent(CustomSnackBarComponent, {
      panelClass: 'ezteach-snackbar',
      duration: undefined,
    });
    return this.eventSubject.asObservable();
  }

  closeMessage(data: CustomSnackBarData) {
    const messages = this.messagesSubject.value.filter(m => !isEqualSnackBarData(m, data));
    this.messagesSubject.next(messages);
    this.eventSubject.next({
      closed: data,
      all: messages.length,
      opened: messages.length,
    });
    if (messages.length === 0) {
      this.snackbar.dismiss();
    }
  }
}
