<div
  [formGroup]="form"
  class="profile_bankcards"
>
  <div class="profile_content__title">{{ 'Ваши привязанные карты' | transloco }}</div>
  <ezteach-notification
    *ngIf="this.user?.isStudent && !isBankCardAdded"
    [notificationMessage]="notificationText | transloco"
  ></ezteach-notification>
  <mat-radio-group
    formControlName="defaultBankCardId"
    class="profile_cardlist"
    name="card"
  >
    <div
      *ngFor="let card of profile?.bankCardBindings?.bankCards"
      class="profile_card"
    >
      <div class="profile_card__wrap">
        <div class="profile_card__name">{{ card.cardHolderName }}</div>
        <div class="profile_card__type">{{ card.paymentSystemName }}</div>
        <div class="profile_card__num">{{ card.maskedPan }}</div>
        <div class="profile_card__exp">{{ card.expirationFormatted }}</div>
      </div>
      <mat-radio-button
        [value]="card.id"
        name="card"
      >{{ 'Основная карта' | transloco }}</mat-radio-button>
      <a
        class="profile_card__delete"
        (click)="removeCard(card.id)"
      >{{ 'Удалить карту' | transloco }}</a>
    </div>
  </mat-radio-group>

  <!--Не удалял если нужно будет вернуть сбер -->
  <!--<button *ngIf='!financialsBankLoading; else bankLoadingSpinnerTemplate' class="btn btn--blue-border" (click)="addCard()">
    <span class="btn__label">Добавить новую карту</span>
  </button>
  <ng-template #bankLoadingSpinnerTemplate>
    <mat-spinner class="loader" diameter="30"></mat-spinner>
  </ng-template>
  -->

  <button
    *ngIf="!user?.isStudent"
    class="btn btn--blue-border"
    (click)="addYookassaCard()"
  >
    <span class="btn__label">{{ 'Добавить новую карту' | transloco }}</span>
  </button>

  <div id="checkout-form"></div>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="setProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>

<ng-template #addPayoutTemplate>
  <div id="payout-form"></div>
</ng-template>