<ng-container *ngIf="newDataRange as date">
  <ng-container *ngIf="isEditMode">
    <div
      class="container edit-slot-lesson"
      [ngClass]="{ mobile: isMobile }"
      *ngIf="!data.groupLessonOptions.joinIdentity"
    >
      <div class="header-row edit edit-slot">
        <div class="field-title field-title__edit-slot">{{ dataUI.dateTimeLabel | transloco }}</div>
        <ng-content *ngTemplateOutlet="closeButton"></ng-content>
      </div>

      <div
        *ngIf="!canEditTime"
        class="ezteach-calendar-popup__time-field"
      >
        <ng-content *ngTemplateOutlet="dateLabel"></ng-content>
      </div>
      <div
        class="ezteach-calendar-popup__time-field"
        [ngClass]="{ mobile: isMobile }"
      >
        <div
          *ngIf="canEditTime; else timeLabel"
          class="ezteach-calendar-popup__time-range-wrapper"
        >
          <ezteach-time-range
            [dateStart]="date.start"
            [dateEnd]="date.end"
            (dateStartChanged)="dateStartChanged($event)"
            (dateEndChanged)="dateEndChanged($event)"
          >
          </ezteach-time-range>
          <div
            *ngIf="warningTimeMsg"
            class="ezteach-calendar-popup__time-field-error"
          >
            {{ warningTimeMsg }}
          </div>
        </div>
      </div>

      <div class="button-row">
        <a
          class="btn btn--grey-border ezteach-calendar-popup__button"
          (click)="deleteLesson()"
        >
          <span class="btn__label">{{ 'Удалить' | transloco }}</span>
        </a>
        <a
          class="btn btn--blue ezteach-calendar-popup__button"
          (click)="editSlot()"
        >
          <span class="btn__label">{{ 'Сохранить' | transloco }}</span>
        </a>
      </div>
    </div>

    <div
      class="container edit-group-lesson"
      [ngClass]="{ mobile: isMobile }"
      *ngIf="data.groupLessonOptions.joinIdentity"
    >
      <div class="modal-event-title">
        {{ getTitleText(meetingIsCreated, slotIsCreated, isGroupLesson, isEditMode) | transloco }}
        <ng-content *ngTemplateOutlet="closeButton"></ng-content>
      </div>
      <calendar-input
        [placeholder]="'Тема события' | transloco"
        [value]="data.groupLessonOptions.subject"
        (valueChanged)="subjectChanged($event)"
      ></calendar-input>
      <mat-select
        class="select-custom"
        [placeholder]="'Тип события' | transloco"
        [(value)]="data.groupLessonOptions.selectedDisciplineId"
        (selectionChange)="onChangeDisciplineSelect($event)"
      >
        <mat-option
          *ngFor="let discipline of disciplines"
          [value]="discipline.id"
        >
          {{ discipline.name | transloco }}
        </mat-option>
      </mat-select>
      <div class="field-title">{{ dataUI.dateTimeLabel | transloco }}</div>
      <div
        *ngIf="!canEditTime"
        class="ezteach-calendar-popup__time-field"
      >
        <ng-content *ngTemplateOutlet="dateLabel"></ng-content>
      </div>
      <div class="ezteach-calendar-popup__time-field range">
        <div
          *ngIf="canEditTime; else timeLabel"
          class="ezteach-calendar-popup__time-range-wrapper"
        >
          <ezteach-time-range
            [dateStart]="date.start"
            [dateEnd]="date.end"
            (dateStartChanged)="dateStartChanged($event)"
            (dateEndChanged)="dateEndChanged($event)"
          >
          </ezteach-time-range>
          <div
            *ngIf="warningTimeMsg"
            class="ezteach-calendar-popup__time-field-error"
          >
            {{ warningTimeMsg }}
          </div>
        </div>
      </div>
      <!--mat-select
        (selectionChange)="selectHomeWork($event)"
        class="select-custom"
        [placeholder]="'Домашнее задание' | transloco"
        [(value)]="selectedHomeworkId"
        #homeworkSelectElement
        (openedChange)="onOpenedHomeworkSelect($event)"
        (closed)="onClosedHomeworkSelect()"
      >
        <mat-option
          *ngIf="!isLoadedSelectedHomework"
          [value]="selectedHomeworkId"
          [ngStyle]="{ display: 'none' }"
        >
          {{ selectedHomeworkName }}
        </mat-option>
        <mat-option *ngIf="isLoadedHomeworkPage && !!homeworks?.length">{{ 'Не указано' | transloco }}</mat-option>
        <mat-option
          *ngFor="let homework of homeworks"
          [value]="homework.id"
        >
          {{ homework.name | transloco }}
        </mat-option>
        <mat-option
          *ngIf="!isLoadedHomeworkPage && !homeworks?.length"
          disabled="true"
        >
          {{ 'Загрузка домашних заданий...' | transloco }}
        </mat-option>
      </mat-select-->
      <div class="ezteach-calendar-popup__columns">
        <!--section
          class="policy-block"
          *ngIf="currentPublishingPolicy"
        >
          <div class="field-title">
            {{ 'Тип события' | transloco }}
            <span
              class="info-icon-block"
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              matTooltipPosition="above"
              [matTooltip]="dataUI.tooltipContent | transloco"
              matTooltipClass="tooltip"
              [matTooltipPosition]="tooltipPosition"
            >
              <img src="assets/img/svg/notification_icon.svg" />
            </span>
          </div>
          <mat-radio-group [(ngModel)]="currentPublishingPolicy.publisher">
            <mat-radio-button
              *ngFor="let item of dataUI.chatLessonPublisherPolicies"
              [value]="item.value"
            >
              {{ item.label | transloco }}
            </mat-radio-button>
          </mat-radio-group>
        </section-->

        <section class="privacy-block">
          <div class="field-title privacy">
            {{ 'Приватность' | transloco }}
            <span
              class="info-icon-block"
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              matTooltipPosition="above"
              [matTooltip]="dataUI.lessonPrivacyTooltipContent | transloco"
              matTooltipClass="tooltip"
              [matTooltipPosition]="tooltipPosition"
            >
              <img src="assets/img/svg/notification_icon.svg" />
            </span>
          </div>
          <mat-radio-group
            [(ngModel)]="currentPublishingPolicy.lessonPrivacy"
            (change)="onChangeLessonPrivacy($event)"
          >
            <mat-radio-button
              *ngFor="let item of dataUI.chatLessonPrivacies"
              [value]="item.value"
            >
              {{ item.label | transloco }}
            </mat-radio-button>
          </mat-radio-group>
        </section>
      </div>


      <app-select-parcipants
        *ngIf="currentPublishingPolicy.lessonPrivacy === chatLessonPrivacyEnum.Private"
        [displayWith]="displayWithUser"
        [defaultValue]="membersDefaultValue"
        [onClosed]="onClosedMembersSelectBound"
        [label]="'Введите ФИО или  id' | transloco"
        [suffix]="false"
      >
      </app-select-parcipants>
      <div
        *ngIf="currentPublishingPolicy.lessonPrivacy === chatLessonPrivacyEnum.Private && warningPrivateMeetingMsg"
        class="ezteach-calendar-popup__time-field-error"
      >
        {{ warningPrivateMeetingMsg }}
      </div>

      <!--section
        class="access-block"
        [ngClass]="{ mobile: isMobile }"
      >
        <div class="access-block__label">
          <span>{{ 'Открыть запись для всех' | transloco }} </span>
          <span
            class="info-icon-block"
            #tooltip="matTooltip"
            (click)="tooltip.toggle()"
            matTooltipPosition="above"
            [matTooltip]="dataUI.accessToArchiveLessonTooltipContent | transloco"
            matTooltipClass="tooltip"
            [matTooltipPosition]="tooltipPosition"
          >
            <img src="assets/img/svg/notification_icon.svg" />
          </span>
        </div>
        <ezteach-custom-toggle
          [checked]="isAccessToArchiveLessonForAll"
          (toggled)="toggleAccessToArchiveLesson()"
        ></ezteach-custom-toggle>
      </section-->

      <shorten-link
        class="shorten-link"
        [prefix]="'Ссылка'"
        [link]="link$ | async"
      ></shorten-link>
      <shorten-link
        class="shorten-link"
        i18n-prefix
        *ngIf="
          currentPublishingPolicy.lessonPrivacy !== chatLessonPrivacyEnum.Private && (guestInviteLink$ | async)?.length
        "
        prefix="Гостевой инвайт:"
        [isGuestInvite]="true"
        [tooltipContent]="'Чтобы добавить неавторизованного участника к событию, отправьте ему ссылку на гостевой инвайт'"
        [link]="guestInviteLink$ | async"
      ></shorten-link>

      <div
        *ngIf="warningApiMsg"
        class="api-error-message"
      >
        <img
          src="assets/img/svg/icon-close_dark.svg"
          (click)="closeApiErrorMessage()"
        />
        {{ warningApiMsg }}
      </div>

      <div
        class="button-row"
        *ngIf="meetingIsCreated"
      >
        <a
          class="btn btn--red-border ezteach-calendar-popup__button"
          (click)="deleteLesson()"
        >
          <span class="btn__label">{{ 'Отменить событие' | transloco }}</span>
        </a>
        <a
          class="btn btn--blue ezteach-calendar-popup__button"
          (click)="editLesson()"
          [attr.disabled]="warningTimeMsg ? true : null"
        >
          <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
        </a>
      </div>
    </div>
  </ng-container>

  <div
    class="container create-lesson-container"
    [ngClass]="{ mobile: isMobile }"
    *ngIf="!isEditMode && !meetingIsCreated"
  >
    <div class="header-row">
      <div
        class="title"
        [class.successTitle]="slotIsCreated"
      >
        {{ getTitleText(meetingIsCreated, slotIsCreated, isGroupLesson, isEditMode) | transloco }}
      </div>
      <ng-content *ngTemplateOutlet="closeButton"></ng-content>
    </div>
    <mat-tab-group
      mat-align-tabs="start"
      animationDuration="0ms"
      (selectedTabChange)="changeTab($event)"
      *ngIf="!meetingIsCreated && !slotIsCreated"
      [ngClass]="{ mobile: isMobile }"
      [selectedIndex]="popupState === popupStateType.block ? 1 : 0"
    >
      <mat-tab
        [label]="dataUI.freeSlotLabel | transloco"
        *ngIf="!(onlyGroupLessonTab$ | async)"
      >
        <div class="create-slot-lesson">
          <div class="field-title">{{ dataUI.dateTimeLabel | transloco }}</div>
          <div
            *ngIf="!canEditTime"
            class="ezteach-calendar-popup__time-field"
          >
            <ng-content *ngTemplateOutlet="dateLabel"></ng-content>
          </div>
          <div class="ezteach-calendar-popup__time-field">
            <div
              *ngIf="canEditTime; else timeLabel"
              class="ezteach-calendar-popup__time-range-wrapper"
            >
              <ezteach-time-range
                [dateStart]="date.start"
                [dateEnd]="date.end"
                (dateStartChanged)="dateStartChanged($event)"
                (dateEndChanged)="dateEndChanged($event)"
              >
              </ezteach-time-range>
              <div
                *ngIf="warningTimeMsg"
                class="ezteach-calendar-popup__time-field-error"
              >
                {{ warningTimeMsg }}
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="dataUI.groupLessonLabel | transloco">
        <div class="create-group-lesson">
          <calendar-input
            [placeholder]="'Тема события' | transloco"
            [value]="data.groupLessonOptions.subject"
            (valueChanged)="subjectChanged($event)"
          ></calendar-input>
          <mat-select
            class="select-custom"
            [(value)]="data.groupLessonOptions.selectedDisciplineId"
            [placeholder]="'Тип события' | transloco"
            (selectionChange)="onChangeDisciplineSelect($event)"
          >
            <mat-option
              *ngFor="let discipline of disciplines"
              [value]="discipline.id"
            >
              {{ discipline.name | transloco }}
            </mat-option>
          </mat-select>
          <div class="field-title">{{ dataUI.dateTimeLabel | transloco }}</div>
          <div
            *ngIf="!canEditTime"
            class="ezteach-calendar-popup__time-field"
          >
            <ng-content *ngTemplateOutlet="dateLabel"></ng-content>
          </div>
          <div class="ezteach-calendar-popup__time-field range">
            <div
              *ngIf="canEditTime; else timeLabel"
              class="ezteach-calendar-popup__time-range-wrapper"
            >
              <ezteach-time-range
                [dateStart]="date.start"
                [dateEnd]="date.end"
                (dateStartChanged)="dateStartChanged($event)"
                (dateEndChanged)="dateEndChanged($event)"
              >
              </ezteach-time-range>
              <div
                *ngIf="warningTimeMsg"
                class="ezteach-calendar-popup__time-field-error"
              >
                {{ warningTimeMsg }}
              </div>
            </div>
          </div>
          <!--mat-select
            (selectionChange)="selectHomeWork($event)"
            class="select-custom"
            [placeholder]="'Домашнее задание' | transloco"
            [(value)]="selectedHomeworkId"
            #homeworkSelectElement
            (openedChange)="onOpenedHomeworkSelect($event)"
            (closed)="onClosedHomeworkSelect()"
          >
            <mat-option *ngIf="isLoadedHomeworkPage && !!homeworks?.length">{{ 'Не указано' | transloco }}</mat-option>
            <mat-option
              *ngFor="let homework of homeworks"
              [value]="homework.id"
            >
              {{ homework.name | transloco }}
            </mat-option>
            <mat-option
              *ngIf="!isLoadedHomeworkPage && !homeworks?.length"
              disabled="true"
            >
              {{ 'Загрузка домашних заданий...' | transloco }}
            </mat-option>
          </mat-select-->
          <div class="ezteach-calendar-popup__columns">
            <!--section
              class="policy-block"
              *ngIf="currentPublishingPolicy"
            >
              <div class="field-title">
                {{ 'Тип события' | transloco }}
                <span
                  class="info-icon-block"
                  #tooltip="matTooltip"
                  (click)="tooltip.toggle()"
                  matTooltipPosition="above"
                  [matTooltip]="dataUI.tooltipContent | transloco"
                  matTooltipClass="tooltip"
                  [matTooltipPosition]="tooltipPosition"
                >
                  <img src="assets/img/svg/notification_icon.svg" />
                </span>
              </div>
              <mat-radio-group [(ngModel)]="currentPublishingPolicy.publisher">
                <mat-radio-button
                  *ngFor="let item of dataUI.chatLessonPublisherPolicies"
                  [value]="item.value"
                >
                  {{ item.label | transloco }}
                </mat-radio-button>
              </mat-radio-group>
            </section-->

            <section
              class="privacy-block"
              *ngIf="currentPublishingPolicy"
            >
              <div class="field-title">
                {{ 'Приватность' | transloco }}
                <span
                  class="info-icon-block"
                  #tooltip="matTooltip"
                  (click)="tooltip.toggle()"
                  matTooltipPosition="above"
                  [matTooltip]="dataUI.lessonPrivacyTooltipContent | transloco"
                  matTooltipClass="tooltip"
                  [matTooltipPosition]="tooltipPosition"
                >
                  <img src="assets/img/svg/notification_icon.svg" />
                </span>
              </div>
              <mat-radio-group
                [(ngModel)]="currentPublishingPolicy.lessonPrivacy"
                (change)="onChangeLessonPrivacy($event)"
              >
                <mat-radio-button
                  *ngFor="let item of dataUI.chatLessonPrivacies"
                  [value]="item.value"
                >
                  {{ item.label | transloco }}
                </mat-radio-button>
              </mat-radio-group>
            </section>
          </div>

          <app-select-parcipants
            *ngIf="currentPublishingPolicy.lessonPrivacy === chatLessonPrivacyEnum.Private"
            [displayWith]="displayWithUser"
            [defaultValue]="membersDefaultValue"
            [onClosed]="onClosedMembersSelectBound"
            [label]="'Введите ФИО или  id' | transloco"
            [suffix]="false"
          >
          </app-select-parcipants>
          <div
            *ngIf="currentPublishingPolicy.lessonPrivacy === chatLessonPrivacyEnum.Private && warningPrivateMeetingMsg"
            class="ezteach-calendar-popup__time-field-error"
          >
            {{ warningPrivateMeetingMsg }}
          </div>

          <!--section
            class="access-block"
            [ngClass]="{ mobile: isMobile }"
            *ngIf="currentPublishingPolicy"
          >
            <div class="access-block__label">
              <span>{{ 'Открыть запись для всех' | transloco }} </span>
              <span
                class="info-icon-block"
                #tooltip="matTooltip"
                (click)="tooltip.toggle()"
                matTooltipPosition="above"
                [matTooltip]="dataUI.accessToArchiveLessonTooltipContent | transloco"
                matTooltipClass="tooltip"
                [matTooltipPosition]="tooltipPosition"
              >
                <img src="assets/img/svg/notification_icon.svg" />
              </span>
            </div>
            <ezteach-custom-toggle
              [checked]="isAccessToArchiveLessonForAll"
              (toggled)="toggleAccessToArchiveLesson()"
            ></ezteach-custom-toggle>
          </section-->
        </div>
      </mat-tab>
      <!--mat-tab
        [label]="dataUI.blockLabel | transloco"
        *ngIf="(onlyGroupLessonTab$ | async) && !(showSelectLessonTab$ | async)"
      >
        <ezteach-create-block></ezteach-create-block>
      </mat-tab-->
      <mat-tab
        [label]="dataUI.selectBlockLabel | transloco"
        *ngIf="showSelectLessonTab$ | async"
      >
        <app-select-history-lesson (selectedChanged)="historySelectedChanged($event)"> </app-select-history-lesson>
      </mat-tab>
    </mat-tab-group>

    <div
      class="button-row"
      *ngIf="
        !slotIsCreated &&
        !meetingIsCreated &&
        popupState !== popupStateType.block &&
        popupState !== popupStateType.selectLesson
      "
    >
      <a
        class="btn btn--grey-border ezteach-calendar-popup__button"
        (click)="cancel()"
      >
        <span class="btn__label">{{ 'Отмена' | transloco }}</span>
      </a>
      <a
        class="btn btn--blue ezteach-calendar-popup__button"
        (click)="isHaveSpecialization && save()"
        [attr.disabled]="!isHaveSpecialization ||        
          
          (warningTimeMsg ? true : null) ||
          (warningPrivateMeetingMsg ? true : null)
        "
      >
        <span class="btn__label">{{ 'Создать' | transloco }}</span>
      </a>
    </div>

    <div
      class="button-row"
      *ngIf="popupState === popupStateType.block || popupState === popupStateType.selectLesson"
    >
      <a
        class="btn btn--grey-border ezteach-calendar-popup__button"
        (click)="cancel()"
      >
        <span class="btn__label">{{ 'Отмена' | transloco }}</span>
      </a>
      <a
        class="btn btn--blue ezteach-calendar-popup__button"
        (click)="saveBlock()"
        ezteachAntiQuickClickThrottle
        [throttleTime]="700"
      >
        <span class="btn__label">{{ 'Создать' | transloco }}</span>
      </a>
    </div>

    <div
      *ngIf="slotIsCreated"
      [ngClass]="{ 'created-block-mobile': isMobile }"
    >
      <div
        class="lesson-info-block"
        [ngClass]="{ mobile: isMobile }"
      >
        <div
          *ngIf="isGroupLesson"
          class="lesson-info"
        >
          <div>{{ 'Тема' | transloco }}: {{ subject }}</div>
          <div>{{ 'Предмет' | transloco }}: {{ currentDiscipline | transloco }}</div>
        </div>
        <div class="ezteach-calendar-popup__time-field">
          <ng-content *ngTemplateOutlet="dateLabel"></ng-content>
        </div>
        <div class="ezteach-calendar-popup__time-field">
          <img
            src="/assets/img/svg/clock-outline.svg"
            alt="clock-outline.svg"
          />
          <span class="without-underline">
            {{ newDataRange.start | date: 'HH:mm' }} — {{ newDataRange.end | date: 'HH:mm' }}
          </span>
        </div>

        <div class="ezteach-calendar-popup__time-field">
          <img
            src="/assets/img/svg/icon-locked.svg"
            alt="icon-locked.svg"
          />
          <span class="without-underline">
            {{
            (currentPublishingPolicy.lessonPrivacy === chatLessonPrivacyEnum.Public
            ? 'Открытое событие'
            : 'Закрытое событие'
            ) | transloco
            }}
          </span>
        </div>

        <shorten-link
          class="shorten-link"
          *ngIf="isGroupLesson"
          i18n-prefix
          prefix="Ссылка:"
          [link]="link$ | async"
        ></shorten-link>
        <shorten-link
          class="shorten-link"
          *ngIf="isGroupLesson && currentPublishingPolicy.lessonPrivacy !== chatLessonPrivacyEnum.Private"
          i18n-prefix
          prefix="Гостевой инвайт:"
          [isGuestInvite]="true"
          [tooltipContent]="
            'Чтобы добавить неавторизованного участника к событию, отправьте ему ссылку на гостевой инвайт'
          "
          [link]="guestInviteLink$ | async"
        ></shorten-link>
      </div>

      <div class="button-actions">
        <a
          class="btn btn--grey-border ezteach-calendar-popup__button"
          (click)="cancel()"
        >
          <span class="btn__label">{{ 'Закрыть' | transloco }}</span>
        </a>
        <a
          *ngIf="link"
          class="btn btn--blue ezteach-calendar-popup__button"
          [href]="link"
        >
          <span class="btn__label">{{ 'Перейти' | transloco }}</span>
        </a>
      </div>
    </div>
  </div>

  <ng-template #timeLabel>
    <img
      src="/assets/img/svg/clock-outline.svg"
      alt="clock-outline.svg"
    />
    <span> {{ date.start | date: 'HH:mm' }} — {{ date.end | date: 'HH:mm' }} </span>
  </ng-template>

  <ng-template #dateLabel>
    <img
      src="/assets/img/svg/calendar-outline.svg"
      alt="calendar-outline.svg"
    />
    <span> {{ date | daysRange }} </span>
  </ng-template>
</ng-container>

<ng-template #closeButton>
  <span
    class="close-modal-button"
    (click)="cancel()"
  >
    <img
      src="assets/img/svg/icon-close_dark.svg"
      alt="closeButton"
    />
  </span>
</ng-template>