import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperComponent } from '@ezteach/shared/components';
import { nextStep, updateTutorialStep } from '@ezteach/tutorial/+state/tutorial.actions';
import { OnboardingStudentSteps } from '@ezteach/tutorial/models';
import { delay } from '@ezteach/utils';
import { Store } from '@ngrx/store';
import { indexOf, nth } from 'ramda';
import { DialogNavigationStep } from '../../helpers';

export interface IWelcomeDialogData {
  steps: readonly OnboardingStudentSteps[];
  stepName: OnboardingStudentSteps;
}

@Component({
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeDialogComponent extends DialogNavigationStep implements AfterViewInit {
  @ViewChild(StepperComponent) stepper: StepperComponent;
  allDone = false;

  constructor(
    protected readonly store: Store<any>,
    protected readonly dialogRef: MatDialogRef<WelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected readonly dialogData: IWelcomeDialogData,
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit() {
    if (!this.stepper) {
      return;
    }
    this.stepper.selectedIndex = indexOf(this.dialogData.stepName, this.dialogData.steps);
    this.cd.detectChanges();
    this.stepper._stateChanged();
  }

  async goNextStep() {
    const nextStepIndex = this.stepper.selectedIndex + 1;
    if (nextStepIndex >= this.dialogData.steps.length) {
      this.allDone = true;
      await delay(500);
      this.dialogRef.close(nextStep());
      return;
    }

    const nextStepName = nth(nextStepIndex, this.dialogData.steps);
    this.store.dispatch(updateTutorialStep({ progress: nextStepName }));
    this.stepper.next();
  }

  goPrevStep() {
    const prevStepIndex = this.stepper.selectedIndex - 1;
    if (prevStepIndex < 0) {
      return;
    }

    const prevStepName = nth(prevStepIndex, this.dialogData.steps);
    this.store.dispatch(updateTutorialStep({ progress: prevStepName }));
    this.stepper.previous();
  }
}
