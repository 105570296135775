<ezteach-popup-with-bg
  [smiley]="smileyType.sad"
  [hasCloseBtn]="true"
  (onCloseBtnClick)="close()"
  [isDefaultWidth]="true"
>
  <div title>{{ 'Легко Учиться нужен доступ к камере и микрофону' | transloco }}</div>
  <div content>
    {{ '}Возможно, другие приложения уже используют выбранную камеру. Пожалуйста, закройте приложения и перезагрузите
    страницу' | transloco }.
  </div>
  <div buttons>
    <ezteach-btn
      (click)="close()"
      [type]="buttonType.warnFilled"
    >
      <div content>Закрыть</div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>