import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageVisibilityMonitorService {
  pageVisible$ = new BehaviorSubject<boolean>(true);
  constructor(@Inject(DOCUMENT) private document) {}

  startMonitor() {
    this.initPageVisibleState();

    fromEvent(this.document, 'visibilitychange').subscribe((e: any) => {
      this.handleVisibilityChange(e);
    });
  }

  private initPageVisibleState() {
    const hidden = this.document.hidden;
    this.pageVisible$.next(hidden);
  }

  private handleVisibilityChange($event) {
    this.pageVisible$.next(this.document.hidden);
  }
}
