import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ChatLesson, ChatLessonStatusEnum } from '@ezteach/api/models';
import { TranslocoService } from '@ngneat/transloco';
@Pipe({
  name: 'lessonDateTime',
})
export class LessonDateTimeInfo implements PipeTransform {
  constructor(private datePipe: DatePipe, private translocoService: TranslocoService) {}
  transform(lesson: ChatLesson): string {
    if (lesson.lessonStatusId === ChatLessonStatusEnum.Started) {
      return this.translocoService.translate('Перейти на событие');
    }
    return (
      this.datePipe.transform(lesson.lessonStartDate) +
      '<div class="datetime-devider"></div>' +
      this.datePipe.transform(lesson.lessonStartDate, 'HH:mm')
    );
  }
}
