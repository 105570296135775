<div
  [formGroup]="form"
  class="profile_passport"
>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <div class="profile_content__title">{{ 'Личная информация' | transloco }}</div>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Фамилия' | transloco }} </mat-label>
        <input
          matInput
          maxlength="20"
          formControlName="lastName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Имя' | transloco }}</mat-label>
        <input
          matInput
          maxlength="20"
          formControlName="firstName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Отчество' | transloco }}</mat-label>
        <input
          matInput
          maxlength="30"
          formControlName="patronymic"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div
      formGroupName="registrationAddress"
      class="profile_content__col"
    >
      <div class="profile_content__title">{{ 'Адрес регистрации' | transloco }}</div>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Улица, дом, квартира' | transloco }}</mat-label>
        <input
          matInput
          formControlName="addressDetails"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Город' | transloco }}</mat-label>
        <input
          matInput
          maxlength="30"
          formControlName="city"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Область' | transloco }} </mat-label>
        <input
          matInput
          maxlength="30"
          formControlName="region"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="profile_content__title">{{ 'Подтверждение личности' | transloco }}</div>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Номер паспорта (без пробелов)' | transloco }} </mat-label>
        <input
          matInput
          mask="0000 000000"
          formControlName="number"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div class="profile_content__col">
      <app-date-picker
        formControlName="issueDate"
        label="Дата выдачи (ДД.ММ.ГГГГ)"
        [min]="minDate"
        [max]="maxDate"
        [isRequired]="true"
        cssClass="profile_content__input"
        [isReadonly]="isReadonlyFields"
      >
      </app-date-picker>
    </div>
  </div>
  <mat-form-field class="profile_content__input">
    <mat-label>{{ 'Кем выдан' | transloco }}</mat-label>
    <input
      matInput
      formControlName="issueAuthority"
      readonly="{{ isReadonlyFields }}"
    />
  </mat-form-field>

  <div class="profile_content__subtitle text--required">{{ 'Загруженные файлы паспорта' | transloco }}</div>
  <div class="files_list">
    <ng-container *ngFor="let file of form.get('documentFiles').value; let index = index">
      <div
        class="file__wrap"
        *ngIf="file"
      >
        <a
          class="file"
          href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
            localStorageUser.id
          }}/passport?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
          target="_blank"
        >
          <span class="file__icon">
            <img src="/assets/img/svg/icon-file.svg" />
          </span>
          <span class="file__info">
            <span class="file__name">{{ file.displayFileName }}</span>
          </span>
        </a>
        <div class="file__delete">
          <a (click)="removeFile(index)">{{ 'Удалить' | transloco }}</a>
        </div>
      </div>
    </ng-container>
  </div>

  <input
    class="hideinput"
    multiple
    type="file"
    [accept]="acceptFileTypes"
    (change)="addFile($event)"
    #passport
    readonly="{{ isReadonlyFields }}"
  />
  <button
    (click)="passport.click()"
    class="btn btn--blue-border"
    [disabled]="uploading"
  >
    <span class="btn__label">{{ loadButtonText | transloco }}</span>
  </button>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="saveProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>
