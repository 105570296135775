import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Konva from 'konva';
import { tap } from 'rxjs/operators';
import { WhiteBoardMode } from '../../classes/white-board';

export interface ShapeColor {
  id: number;
  code: string;
}

export interface PickedParams {
  stroke?: string;
  fill?: string;
  strokeWidth?: string;
}

export type ShapeModifyType = 'stroke' | 'fill' | 'opacity';

export interface ShapeToolConfig {
  enableFill?: boolean;
  enableStroke?: boolean;
  enableOpacity?: boolean;
}

export const DefaultShapeToolConfig: ShapeToolConfig = {
  enableFill: false,
  enableStroke: true,
  enableOpacity: true,
};

@UntilDestroy()
@Component({
  selector: 'ezteach-shape-tools',
  templateUrl: './shape-tools.component.html',
  styleUrls: ['./shape-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.top]': 'top',
    '[style.left]': 'left',
    '[style.position]': 'position',
  },
})
export class ShapeToolsComponent implements OnInit {
  @Input()
  selectedColor: ShapeColor = { id: 2, code: '#42a5f5' };
  selectedStroke: ShapeColor;
  showPalette = false;
  opacity = 100;
  strokeWidth = 1;
  mode: ShapeModifyType;
  visible = false;
  emptyColor: ShapeColor = { id: 0, code: 'rgba(255,0,0,0.0)' };
  colors: ShapeColor[] = [
    { id: 1, code: '#ba68c8' },
    { id: 2, code: '#42a5f5' },
    { id: 3, code: '#e57373' },
    { id: 4, code: '#f3e5f5' },
    { id: 5, code: '#ba68c8' },
  ];
  enableFillPanel: boolean;
  enableStrokePanel: boolean;
  enableOpacityPanel: boolean;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  isMobile = false;
  @Input()
  whiteBoardMode: WhiteBoardMode;
  @Input()
  isFullMode: boolean = true;
  @Input()
  set selectedShape(value) {
    this.showPalette = false;
    this.mode = null;
  }
  @Output()
  colorStrokeChanged = new EventEmitter<ShapeColor>();
  @Output()
  colorFillChanged = new EventEmitter<ShapeColor>();
  @Output()
  opacityChanged = new EventEmitter<number>();
  @Output()
  strokeWidthChanged = new EventEmitter<number>();

  left = '0px';
  top = '0px';

  constructor(private cdr: ChangeDetectorRef) {}


  ngOnInit() {
    this.mediaQueryService.match$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.isMobile = x;
        }),
      )
      .subscribe();
  }

  updatePaletteMode(mode: ShapeModifyType) {
    if (this.mode === mode || !this.mode || !this.showPalette) {
      this.showPalette = !this.showPalette;
    }
    this.mode = mode;
    this.cdr.detectChanges();
  }

  colorClick(color) {
    if (this.mode === 'stroke') {
      this.selectedStroke = color;
      this.colorStrokeChanged.emit(color);
    }
    if (this.mode === 'fill') {
      this.selectedColor = color;
      this.colorFillChanged.emit(color);
    }
    this.cdr.detectChanges();
  }

  onOpacityChanged() {
    this.opacityChanged.emit(this.opacity / 100);
  }

  onStrokeWidthChanged() {
    this.strokeWidthChanged.emit(this.strokeWidth);
  }

  addColor() {}

  private pickParams(shape: Konva.Shape | Konva.Group, params: string[]): PickedParams {

    const source = shape.hasChildren()
      ? (shape as Konva.Group).children.find(x => x.constructor.name === Konva.Rect.name)
      : shape;

    return params
      .filter(key => key in source.attrs)
      .reduce((acc, key) => {
        acc[key] = source.attrs[key];
        return acc;
      }, {});
  }

  public setColors(shape: Konva.Shape | Konva.Group) {
    const params = ['stroke', 'fill'];
    const attrs = this.pickParams(shape, params);

    const strokeColor = this.getOrAddColor(attrs?.stroke);
    this.selectedStroke = strokeColor;
    const fillColor = this.getOrAddColor(attrs?.fill);
    this.selectedColor = fillColor;
    this.cdr.detectChanges();
  }

  public setOpacity(value: number) {
    if (!value) {
      this.opacity = 100;
    } else {
      this.opacity = +value * 100;
    }
    this.cdr.detectChanges();
  }

  public setStrokeWidth(shape: Konva.Shape | Konva.Group) {
    const params = ['strokeWidth'];
    const attrs = this.pickParams(shape, params);
    const value = attrs.strokeWidth;

    if (!value) {
      this.strokeWidth = 0;
    } else {
      this.strokeWidth = +value;
    }
    this.cdr.detectChanges();
  }

  getOrAddColor(code: string) {
    if (!code) {
      return this.emptyColor;
    }
    let color = this.colors.find(x => x.code === code);
    if (!color) {
      color = { id: this.colors.length + 1, code: code };
      this.colors.push(color);
    }
    return color;
  }

  colorPickerChange($event) {
    if (this.editedColor) {
      this.editedColor.code = $event;
      this.cdr.detectChanges();
    }
  }

  editedColor: ShapeColor;
  cpToggleChange($event) {
    if ($event) {
      this.editedColor = {
        id: this.colors.length + 1,
        code: this.emptyColor.code,
      };
      this.colors.push(this.editedColor);
    } else {
      this.colorClick(this.editedColor);
      this.editedColor = null;
    }
    this.cdr.detectChanges();
  }

  public show(config: ShapeToolConfig) {
    this.enableFillPanel = config.enableFill;
    this.enableStrokePanel = config.enableStroke;
    this.enableOpacityPanel = config.enableOpacity;
    this.visible = true;
    this.cdr.detectChanges();
  }

  public hide() {
    this.showPalette = false;
    this.visible = false;
    this.cdr.detectChanges();
  }
  

  colorsWhell($event) {
    $event.stopPropagation();
  }
}
