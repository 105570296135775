import { Component, OnInit } from '@angular/core';
import { LessonsService } from '../../api/services/lessons.service';
import { SignalrService } from '../../_services/signalr.service';
import { UserService } from "@ezteach/_services/user.service";

@Component({
  selector: 'widget-history',
  templateUrl: './history.component.html',
})
export class WidgetHistoryComponent implements OnInit {
  pageSize = 4;
  pageNumber = 1;
  loaded = false;

  userdata;
  results;
  lessons;

  constructor(
    private lessonsService: LessonsService,
    private signalrService: SignalrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    this.getResults();
    this.signalrService.onPostProcessingCompleted.subscribe(data => {
      this.getResults();
    });
  }

  getResults(): void {
    this.pageNumber = 1;

    this.lessonsService
      .apiV1LessonsHistoryGet({
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;

        this.lessons = results.data;
      });
  }

  getMore(): void {
    this.pageNumber++;

    this.lessonsService
      .apiV1LessonsHistoryGet({
        PageNumber: this.pageNumber,
        PageSize: this.pageSize,
      })
      .subscribe(results => {
        this.loaded = true;
        this.results = results;

        this.lessons = this.lessons.concat(results.data);
      });
  }
}
