<div class="container">
  <button
    class="close-btn"
    (click)="dismissRequest()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
  <div class="notification">{{ notificationMessage }}</div>
  <hr />
  <div class="buttons">
    <ezteach-btn
      [type]="buttonType.warnNotFilled"
      [width]="buttonWidth"
      (click)="dismissRequest()"
    >
      <div content>{{dismissMessage | transloco}}</div>
    </ezteach-btn>
    <ezteach-btn
      [type]="buttonType.filled"
      [width]="buttonWidth"
      (click)="changeMediaState()"
    >
      <div content>{{requestStateMessage | transloco | capitalize}} {{deviceType | transloco}}</div>
    </ezteach-btn>
  </div>
</div>
