import { finishTutorial, lastStep, nextStep, previousStep } from '@ezteach/tutorial/+state/tutorial.actions';
import { ITooltipOverlayRef } from '@ezteach/tutorial/models';

export abstract class TooltipNavigationStep {
  protected abstract readonly overlayRef: ITooltipOverlayRef;

  goPrevStep() {
    this.overlayRef.close(previousStep());
  }

  goNextStep() {
    this.overlayRef.close(nextStep());
  }

  goLastStep() {
    this.overlayRef.close(lastStep());
  }

  finishTutorial() {
    this.overlayRef.close(finishTutorial());
  }
}
