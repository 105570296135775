import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="5 из 6"
      [isSkippedToLastStep]="false"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Календарь</h1>
      <p class="ezteach-tutorial-tooltip__info">
        В календаре вы сможете выбрать любые свободные слоты и через него послать запрос на событие. Календарь — живое
        сердце нашего сервиса.
      </p>
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step5TooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
