export class DragManager {
  private el: any;
  private parentEl: any;

  constructor(el: any, parentEl: any) {
    this.el = el;
    this.parentEl = parentEl;
  }

  public calculateCornerPositions() {
    const elBoundings = this.el.getBoundingClientRect();
    const parentElBoundings = this.parentEl.getBoundingClientRect();
    const left = elBoundings.left - parentElBoundings.left;
    const right = parentElBoundings.right - elBoundings.right;
    const top = elBoundings.top - parentElBoundings.top;
    const bottom = parentElBoundings.bottom - elBoundings.bottom;

    const isRightCorner = left > right;
    const isBottomCorner = top > bottom;

    if (isRightCorner && isBottomCorner) {
      const calcY_axis = Math.round(parentElBoundings.height - elBoundings.height) - 12;
      return { x: 0, y: calcY_axis };
    } else if (isRightCorner && !isBottomCorner) {
      return { x: 0, y: 0 };
    } else if (!isRightCorner && isBottomCorner) {
      const calcX_axis = Math.round(parentElBoundings.width - elBoundings.width) - 12;
      const calcY_axis = Math.round(parentElBoundings.height - elBoundings.height) - 12;
      return { x: -calcX_axis, y: calcY_axis };
    } else if (!isRightCorner && !isBottomCorner) {
      const calcX_axis = Math.round(parentElBoundings.width - elBoundings.width) - 12;
      return { x: -calcX_axis, y: 0 };
    }
    return { x: 0, y: 0 };
  }
}
