import { Injectable } from '@angular/core';
import {
  ChatLessonMemberReactionArgs,
  ChatLessonModerationMemberReactionsArgs,
} from '@ezteach/api/models/lesson/chat-lesson-reaction';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonReactionService {
  currentMemberId: number;
  currentUserHandState$ = new BehaviorSubject<boolean>(false);
  reactions$ = new BehaviorSubject<ChatLessonMemberReactionArgs[]>([]);
  updateReactionEmit$ = new Subject<{ users: number[]; reaction: { type: ReactionTypeEnum; show: boolean } }>();
  constructor() {}

  addOrUpdateReactions(args: ChatLessonMemberReactionArgs) {
    const value = [...this.reactions$.value];
    this.addOrUpdate(args, value);
    this.updateState(value);
  }

  setMemberId(memberId: number){
    this.currentMemberId = memberId;
  }

  addOrUpdateModerateReactions(args: ChatLessonModerationMemberReactionsArgs) {
    const reactions = args.members.map((x: number) => {
      return { byMemberId: x, type: args.reaction.type, show: args.reaction.show };
    });

    const value = [...this.reactions$.value];
    reactions.forEach(x => {
      this.addOrUpdate(x, value);
    });

    this.updateState(value);
  }

  updateReactionEmit(data: { users: number[]; reaction: { type: ReactionTypeEnum; show: boolean } }) {
    this.updateReactionEmit$.next(data);
  }

  private addOrUpdate(args: ChatLessonMemberReactionArgs, value: ChatLessonMemberReactionArgs[]) {
    const item = value.find(x => x.byMemberId === args.byMemberId && x.type === args.type);
    if (!item) {
      if (args.show) {
        value.push(args);
      }
    } else {
      const index = value.indexOf(item);
      value.splice(index, 1);
    }
  }

  private updateState(value: ChatLessonMemberReactionArgs[]) {
    this.reactions$.next([...value]);
    const currentUserHandState =
      value.find(x => x.byMemberId === this.currentMemberId && x.type === ReactionTypeEnum.RaiseHand) !== undefined;
    this.currentUserHandState$.next(currentUserHandState);
  }

  removeUserReaction(memberId) {
    const currentReactionsArray = this.reactions$.value.filter(member => member.byMemberId !== memberId);
    this.reactions$.next([...currentReactionsArray]);
  }
}
