import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { BankCardAddApiResponse } from '../models/bank-card-add-api-reponse';
import { BankCardApiResponse } from '../models/bank-card-api-response';
import { BankCardBindingsApiResponse } from '../models/bank-card-bindings-api-response';
import { BaseApiResponse } from '../models/base-api-response';
import { CabinetPageModeEnum } from '../models/cabinet-page-mode-enum';
import { ExternalAccountTypeEnum } from '../models/external-account-type-enum';
import { ExternalCalendarTypeEnum } from '../models/external-calendar-type-enum';
import { Int32IEnumerableApiResponse } from '../models/int-32-i-enumerable-api-response';
import { NotificationMethodEnum } from '../models/notification-method-enum';
import { PaymentHistorySortsRequest } from '../models/payment-history-sorts-api-request';
import { PhoneConfirmationParamsApiResponse } from '../models/phone-confirmation-params-api-response';
import { ProcessLessonBookingPaymentResponseApiResponse } from '../models/process-lesson-booking-payment-response-api-response';
import { ProcessLessonPaymentModeEnum } from '../models/process-lesson-payment-mode-enum';
import { ProfilePaymentHistoryResponse } from '../models/profile-payment-history.responce';
import { StringApiResponse } from '../models/string-api-response';
import { TutorAvailabilityApiResponse } from '../models/tutor-availability-api-response';
import { UserApiResponse } from '../models/user-api-response';
import { UserPagedApiResponse } from '../models/user-paged-api-response';
import { UserProfileApiResponse } from '../models/user-profile-api-response';
import { UserProfileTypeEnum } from '../models/user-profile-type-enum';
import { WidgetSettings } from '../models/widget-settings';
import { WidgetSettingsIEnumerableApiResponse } from '../models/widget-settings-i-enumerable-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsBankcardsGet
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsGetPath = '/api/v1/users/{userId}/financials/bankcards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsBankcardsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsGet$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<BankCardBindingsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdFinancialsBankcardsGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BankCardBindingsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsBankcardsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsGet(params: { userId: number }): Observable<BankCardBindingsApiResponse> {
    return this.apiV1UsersUserIdFinancialsBankcardsGet$Response(params).pipe(
      map((r: StrictHttpResponse<BankCardBindingsApiResponse>) => r.body as BankCardBindingsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsBankcardsPost
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsPostPath = '/api/v1/users/{userId}/financials/bankcards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsBankcardsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsBankcardsPost$Response(params: {
    userId: number;
    body?: { ReturnUrl?: string; FailUrl?: string };
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdFinancialsBankcardsPostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsBankcardsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsBankcardsPost(params: {
    userId: number;
    body?: { ReturnUrl?: string; FailUrl?: string };
  }): Observable<StringApiResponse> {
    return this.apiV1UsersUserIdFinancialsBankcardsPost$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsBankcardsFinalizePatch
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsFinalizePatchPath =
    '/api/v1/users/{userId}/financials/bankcards/finalize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsBankcardsFinalizePatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsBankcardsFinalizePatch$Response(params: {
    userId: number;
    body?: { OrderId?: string };
  }): Observable<StrictHttpResponse<BankCardApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdFinancialsBankcardsFinalizePatchPath,
      'patch',
    );
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BankCardApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsBankcardsFinalizePatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsBankcardsFinalizePatch(params: {
    userId: number;
    body?: { OrderId?: string };
  }): Observable<BankCardApiResponse> {
    return this.apiV1UsersUserIdFinancialsBankcardsFinalizePatch$Response(params).pipe(
      map((r: StrictHttpResponse<BankCardApiResponse>) => r.body as BankCardApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsBankCardIdDeletePath =
    '/api/v1/users/{userId}/financials/bankcards/{bankCardId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete$Response(params: {
    userId: number;
    bankCardId: number;
  }): Observable<StrictHttpResponse<BankCardApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdFinancialsBankcardsBankCardIdDeletePath,
      'delete',
    );
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('bankCardId', params.bankCardId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BankCardApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete(params: {
    userId: number;
    bankCardId: number;
  }): Observable<BankCardApiResponse> {
    return this.apiV1UsersUserIdFinancialsBankcardsBankCardIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<BankCardApiResponse>) => r.body as BankCardApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatchPath =
    '/api/v1/users/{userId}/financials/bankcards/finalize-pendings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatchPath,
      'patch',
    );
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch(params: {
    userId: number;
  }): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1UsersUserIdFinancialsBankcardsFinalizePendingsPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsLessonsBookPost
   */
  static readonly ApiV1UsersUserIdFinancialsLessonsBookPostPath = '/api/v1/users/{userId}/financials/lessons/book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdFinancialsLessonsBookPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsLessonsBookPost$Response(params: {
    userId: number;
    body?: {
      ChatLessonId?: number;
      UserId?: number;
      BankCardId?: number;
      Mode?: ProcessLessonPaymentModeEnum;
      ReturnUrl?: string;
      FailUrl?: string;
    };
  }): Observable<StrictHttpResponse<ProcessLessonBookingPaymentResponseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdFinancialsLessonsBookPostPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    console.log('apiV1UsersUserIdFinancialsLessonsBookPost');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ProcessLessonBookingPaymentResponseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdFinancialsLessonsBookPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdFinancialsLessonsBookPost(params: {
    userId: number;
    body?: {
      ChatLessonId?: number;
      UserId?: number;
      BankCardId?: number;
      Mode?: ProcessLessonPaymentModeEnum;
      ReturnUrl?: string;
      FailUrl?: string;
      JoinIdentity?: string;
    };
  }): Observable<ProcessLessonBookingPaymentResponseApiResponse> {
    return this.apiV1UsersUserIdFinancialsLessonsBookPost$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ProcessLessonBookingPaymentResponseApiResponse>) =>
          r.body as ProcessLessonBookingPaymentResponseApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdGet
   */
  static readonly ApiV1UsersUserIdGetPath = '/api/v1/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdGet$Response(params: {
    userId: number;
    mode?: null | string;
  }): Observable<StrictHttpResponse<UserApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('mode', params.mode, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdGet(params: { userId: number; mode?: null | string }): Observable<UserApiResponse> {
    return this.apiV1UsersUserIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserApiResponse>) => r.body as UserApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdProfileGet
   */
  static readonly ApiV1UsersUserIdProfileGetPath = '/api/v1/users/{userId}/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdProfileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdProfileGet$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<UserProfileApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdProfileGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserProfileApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdProfileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdProfileGet(params: { userId: number }): Observable<UserProfileApiResponse> {
    return this.apiV1UsersUserIdProfileGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfileApiResponse>) => r.body as UserProfileApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdProfilePut
   */
  static readonly ApiV1UsersUserIdProfilePutPath = '/api/v1/users/{userId}/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdProfilePut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfilePut$Response(params: {
    userId: number;
    wizardStepId?: null | number;
    body?: {
      UserId?: number;
      'UserAvatar.UserId'?: number;
      'UserAvatar.AvatarFileName'?: string;
      'UserAvatar.FormFieldsPrefix'?: string;
      'UserAvatar.CollectionItemIndexName'?: string;
      'UserAvatar.CollectionItemIndexValue'?: string;
      'UserAvatar.IsProfileWizardStep'?: boolean;
      'GeneralSettings.UserId'?: number;
      'GeneralSettings.UserNotificationMethodIds'?: Array<NotificationMethodEnum>;
      'GeneralSettings.UserExternalAccountTypeForSyncIds'?: Array<ExternalAccountTypeEnum>;
      'GeneralSettings.UserExternalCalendarTypeForSyncIds'?: Array<ExternalCalendarTypeEnum>;
      'GeneralSettings.FormFieldsPrefix'?: string;
      'GeneralSettings.CollectionItemIndexName'?: string;
      'GeneralSettings.CollectionItemIndexValue'?: string;
      'GeneralSettings.IsProfileWizardStep'?: boolean;
      'FinancialDetails.UserId'?: number;
      'FinancialDetails.BankDetails.AccountNumber'?: string;
      'FinancialDetails.BankDetails.Bank.FullName'?: string;
      'FinancialDetails.BankDetails.Bank.ShortName'?: string;
      'FinancialDetails.BankDetails.Bank.Address'?: string;
      'FinancialDetails.BankDetails.Bank.BIC'?: string;
      'FinancialDetails.BankDetails.Bank.SWIFT'?: string;
      'FinancialDetails.BankDetails.Bank.KPP'?: string;
      'FinancialDetails.BankDetails.Bank.CorrespondentAccountNumber'?: string;
      'FinancialDetails.FormFieldsPrefix'?: string;
      'FinancialDetails.CollectionItemIndexName'?: string;
      'FinancialDetails.CollectionItemIndexValue'?: string;
      'FinancialDetails.IsProfileWizardStep'?: boolean;
      'BankCardBindings.UserId'?: number;
      'BankCardBindings.DefaultBankCardId'?: number;
      'BankCardBindings.FormFieldsPrefix'?: string;
      'BankCardBindings.CollectionItemIndexName'?: string;
      'BankCardBindings.CollectionItemIndexValue'?: string;
      'BankCardBindings.IsProfileWizardStep'?: boolean;
      ProfileTypeId?: UserProfileTypeEnum;
    };
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdProfilePutPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('wizardStepId', params.wizardStepId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdProfilePut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfilePut(params: {
    userId: number;
    wizardStepId?: null | number;
    body?: {
      UserId?: number;
      'UserAvatar.UserId'?: number;
      'UserAvatar.AvatarFileName'?: string;
      'UserAvatar.FormFieldsPrefix'?: string;
      'UserAvatar.CollectionItemIndexName'?: string;
      'UserAvatar.CollectionItemIndexValue'?: string;
      'UserAvatar.IsProfileWizardStep'?: boolean;
      'GeneralSettings.UserId'?: number;
      'GeneralSettings.UserNotificationMethodIds'?: Array<NotificationMethodEnum>;
      'GeneralSettings.UserExternalAccountTypeForSyncIds'?: Array<ExternalAccountTypeEnum>;
      'GeneralSettings.UserExternalCalendarTypeForSyncIds'?: Array<ExternalCalendarTypeEnum>;
      'GeneralSettings.FormFieldsPrefix'?: string;
      'GeneralSettings.CollectionItemIndexName'?: string;
      'GeneralSettings.CollectionItemIndexValue'?: string;
      'GeneralSettings.IsProfileWizardStep'?: boolean;
      'FinancialDetails.UserId'?: number;
      'FinancialDetails.BankDetails.AccountNumber'?: string;
      'FinancialDetails.BankDetails.Bank.FullName'?: string;
      'FinancialDetails.BankDetails.Bank.ShortName'?: string;
      'FinancialDetails.BankDetails.Bank.Address'?: string;
      'FinancialDetails.BankDetails.Bank.BIC'?: string;
      'FinancialDetails.BankDetails.Bank.SWIFT'?: string;
      'FinancialDetails.BankDetails.Bank.KPP'?: string;
      'FinancialDetails.BankDetails.Bank.CorrespondentAccountNumber'?: string;
      'FinancialDetails.FormFieldsPrefix'?: string;
      'FinancialDetails.CollectionItemIndexName'?: string;
      'FinancialDetails.CollectionItemIndexValue'?: string;
      'FinancialDetails.IsProfileWizardStep'?: boolean;
      'BankCardBindings.UserId'?: number;
      'BankCardBindings.DefaultBankCardId'?: number;
      'BankCardBindings.FormFieldsPrefix'?: string;
      'BankCardBindings.CollectionItemIndexName'?: string;
      'BankCardBindings.CollectionItemIndexValue'?: string;
      'BankCardBindings.IsProfileWizardStep'?: boolean;
      ProfileTypeId?: UserProfileTypeEnum;
    };
  }): Observable<BaseApiResponse> {
    return this.apiV1UsersUserIdProfilePut$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdAvailabilityGet
   */
  static readonly ApiV1UsersUserIdAvailabilityGetPath = '/api/v1/users/{userId}/availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdAvailabilityGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdAvailabilityGet$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<TutorAvailabilityApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdAvailabilityGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<TutorAvailabilityApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdAvailabilityGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdAvailabilityGet(params: { userId: number }): Observable<TutorAvailabilityApiResponse> {
    return this.apiV1UsersUserIdAvailabilityGet$Response(params).pipe(
      map((r: StrictHttpResponse<TutorAvailabilityApiResponse>) => r.body as TutorAvailabilityApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdAvailabilityPut
   */
  static readonly ApiV1UsersUserIdAvailabilityPutPath = '/api/v1/users/{userId}/availability';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdAvailabilityPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdAvailabilityPut$Response(params: {
    userId: number;
    body?: { IsAvailable?: boolean; DurationMinutes?: number; InstantLessonDurations?: Array<number> };
  }): Observable<StrictHttpResponse<TutorAvailabilityApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdAvailabilityPutPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<TutorAvailabilityApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdAvailabilityPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdAvailabilityPut(params: {
    userId: number;
    body?: { IsAvailable?: boolean; DurationMinutes?: number; InstantLessonDurations?: Array<number> };
  }): Observable<TutorAvailabilityApiResponse> {
    return this.apiV1UsersUserIdAvailabilityPut$Response(params).pipe(
      map((r: StrictHttpResponse<TutorAvailabilityApiResponse>) => r.body as TutorAvailabilityApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdProfileValidationPhoneCreatecodePost
   */
  static readonly ApiV1UsersUserIdProfileValidationPhoneCreatecodePostPath =
    '/api/v1/users/{userId}/profile/validation/phone/createcode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdProfileValidationPhoneCreatecodePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfileValidationPhoneCreatecodePost$Response(params: {
    userId: number;
    body?: { Phone?: string };
  }): Observable<StrictHttpResponse<PhoneConfirmationParamsApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdProfileValidationPhoneCreatecodePostPath,
      'post',
    );
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<PhoneConfirmationParamsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdProfileValidationPhoneCreatecodePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfileValidationPhoneCreatecodePost(params: {
    userId: number;
    body?: { Phone?: string };
  }): Observable<PhoneConfirmationParamsApiResponse> {
    return this.apiV1UsersUserIdProfileValidationPhoneCreatecodePost$Response(params).pipe(
      map((r: StrictHttpResponse<PhoneConfirmationParamsApiResponse>) => r.body as PhoneConfirmationParamsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdProfileValidationPhoneValidatecodePost
   */
  static readonly ApiV1UsersUserIdProfileValidationPhoneValidatecodePostPath =
    '/api/v1/users/{userId}/profile/validation/phone/validatecode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdProfileValidationPhoneValidatecodePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfileValidationPhoneValidatecodePost$Response(params: {
    userId: number;
    body?: { Phone?: string; ConfirmationCode?: string };
  }): Observable<StrictHttpResponse<StringApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdProfileValidationPhoneValidatecodePostPath,
      'post',
    );
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<StringApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdProfileValidationPhoneValidatecodePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdProfileValidationPhoneValidatecodePost(params: {
    userId: number;
    body?: { Phone?: string; ConfirmationCode?: string };
  }): Observable<StringApiResponse> {
    return this.apiV1UsersUserIdProfileValidationPhoneValidatecodePost$Response(params).pipe(
      map((r: StrictHttpResponse<StringApiResponse>) => r.body as StringApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdProfileValidationPhoneParamsGet
   */
  static readonly ApiV1UsersUserIdProfileValidationPhoneParamsGetPath =
    '/api/v1/users/{userId}/profile/validation/phone/params';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdProfileValidationPhoneParamsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdProfileValidationPhoneParamsGet$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<PhoneConfirmationParamsApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdProfileValidationPhoneParamsGetPath,
      'get',
    );
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<PhoneConfirmationParamsApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdProfileValidationPhoneParamsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdProfileValidationPhoneParamsGet(params: {
    userId: number;
  }): Observable<PhoneConfirmationParamsApiResponse> {
    return this.apiV1UsersUserIdProfileValidationPhoneParamsGet$Response(params).pipe(
      map((r: StrictHttpResponse<PhoneConfirmationParamsApiResponse>) => r.body as PhoneConfirmationParamsApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersOnlineGet
   */
  static readonly ApiV1UsersOnlineGetPath = '/api/v1/users/online';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersOnlineGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersOnlineGet$Response(params?: {}): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersOnlineGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersOnlineGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersOnlineGet(params?: {}): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1UsersOnlineGet$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersAvailableGet
   */
  static readonly ApiV1UsersAvailableGetPath = '/api/v1/users/available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersAvailableGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersAvailableGet$Response(params?: {}): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersAvailableGetPath, 'get');
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersAvailableGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersAvailableGet(params?: {}): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1UsersAvailableGet$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdBookmarksTutorsGet
   */
  static readonly ApiV1UsersUserIdBookmarksTutorsGetPath = '/api/v1/users/{userId}/bookmarks/tutors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdBookmarksTutorsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdBookmarksTutorsGet$Response(params: {
    userId: number;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<UserPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdBookmarksTutorsGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdBookmarksTutorsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdBookmarksTutorsGet(params: {
    userId: number;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<UserPagedApiResponse> {
    return this.apiV1UsersUserIdBookmarksTutorsGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserPagedApiResponse>) => r.body as UserPagedApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdWidgetsLayoutGet
   */
  static readonly ApiV1UsersUserIdWidgetsLayoutGetPath = '/api/v1/users/{userId}/widgets/layout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdWidgetsLayoutGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdWidgetsLayoutGet$Response(params: {
    userId: number;
    mode?: CabinetPageModeEnum;
  }): Observable<StrictHttpResponse<WidgetSettingsIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdWidgetsLayoutGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('mode', params.mode, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<WidgetSettingsIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdWidgetsLayoutGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdWidgetsLayoutGet(params: {
    userId: number;
    mode?: CabinetPageModeEnum;
  }): Observable<WidgetSettingsIEnumerableApiResponse> {
    return this.apiV1UsersUserIdWidgetsLayoutGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<WidgetSettingsIEnumerableApiResponse>) => r.body as WidgetSettingsIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdWidgetsLayoutPut
   */
  static readonly ApiV1UsersUserIdWidgetsLayoutPutPath = '/api/v1/users/{userId}/widgets/layout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdWidgetsLayoutPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdWidgetsLayoutPut$Response(params: {
    userId: number;
    body?: { CabinetPageMode?: CabinetPageModeEnum; Widgets?: Array<WidgetSettings> };
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdWidgetsLayoutPutPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdWidgetsLayoutPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UsersUserIdWidgetsLayoutPut(params: {
    userId: number;
    body?: { CabinetPageMode?: CabinetPageModeEnum; Widgets?: Array<WidgetSettings> };
  }): Observable<BaseApiResponse> {
    return this.apiV1UsersUserIdWidgetsLayoutPut$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdWidgetsLayoutResetPatch
   */
  static readonly ApiV1UsersUserIdWidgetsLayoutResetPatchPath = '/api/v1/users/{userId}/widgets/layout/reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UsersUserIdWidgetsLayoutResetPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdWidgetsLayoutResetPatch$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdWidgetsLayoutResetPatchPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UsersUserIdWidgetsLayoutResetPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UsersUserIdWidgetsLayoutResetPatch(params: { userId: number }): Observable<BaseApiResponse> {
    return this.apiV1UsersUserIdWidgetsLayoutResetPatch$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1UsersUserIdFinancialsSynonim
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsSynonimPost =
    '/api/v1/users/{userId}/financials/bankcards/synonim';

  apiV1UsersUserIdFinancialsBankcardsSynonimPost(params: {
    userId: number;
    body?: { BankName: string; Panmask: string; Synonim: string; Type: string };
  }): Observable<BankCardAddApiResponse> {
    console.log('ApiV1UsersUserIdFinancialsBankcardsSynonimPost$');
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdFinancialsBankcardsSynonimPost, 'post');
    if (params) {
      rb.path('userId', params.userId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body as BankCardAddApiResponse),
      );
  }

  /**
   * Path part for operation get user total payment
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsGetTotalPayment = '/api/v1/users/{userId}/profile/payments/total';

  apiV1UsersUserIdFinancialsBankcardsGetTotalPayment$Response(params: { userId: number }): Observable<number> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.ApiV1UsersUserIdFinancialsBankcardsGetTotalPayment, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body.data as number),
      );
  }

  /**
   * Path part for operation get user total payment
   */
  static readonly ApiV1UsersUserIdFinancialsBankcardsGetMonthlyPaymentAmount =
    '/api/v1/users/{userId}/profile/payments/monthly';

  apiV1UsersUserIdFinancialsBankcardsGetMonthlyPaymentAmount(params?: {
    userId: number;
    Date?: string;
  }): Observable<number[]> {
    console.log(params);
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdFinancialsBankcardsGetMonthlyPaymentAmount,
      'get',
    );
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('Date', params.Date, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body.data as number[]),
      );
  }

  static readonly ApiV1UsersUserIdFinancialsBankcardsGetPaymentHistory =
    '/api/v1/users/{userId}/profile/payments/history';

  apiV1UsersUserIdFinancialsBankcardsGetPaymentHistory(params?: {
    userId: number;
    offset?: number;
    take?: number;
    orderBy: PaymentHistorySortsRequest;
    pageNumber: number;
    pageSize: number;
  }): Observable<ProfilePaymentHistoryResponse> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UsersService.ApiV1UsersUserIdFinancialsBankcardsGetPaymentHistory,
      'get',
    );
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('offset', params.offset, {});
      rb.query('take', params.take, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('orderBy', params.orderBy, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body as ProfilePaymentHistoryResponse),
      );
  }
}
