import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../../toast/toast.component';

type PanelClass = 'success' | 'warn' | 'error';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _snackBar: MatSnackBar) {}

  showSuccess(message: string) {
    this.show(message, 'success');
  }

  showError(message: string) {
    this.show(message, 'error');
  }

  showWarn(message: string) {
    this.show(message, 'warn');
  }

  /**
   * Show toast
   *
   * @param message
   * @param panelClass success | warn | error
   */
  private show(message: string, panelClass: PanelClass) {
    const config = this.createConfig(panelClass);
    const snackbarRef = this._snackBar.openFromComponent(ToastComponent, config);
    setTimeout(() => {
      snackbarRef.instance.message = message;
    }, 50);
  }

  private createConfig(panelClass: string): MatSnackBarConfig {
    const config: MatSnackBarConfig = {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: [`toast-${panelClass}`],
      duration: 5000,
    };
    return config;
  }
}
