import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
})
export class AdvertisementComponent {
  titleText = 'Международная олимпиада по экспериментальной физике';
  descriptionText = 'Олимпиада пройдёт 20-28 ноября в Беларуси';
  buttonText = 'Подробнее';
  isMobile: boolean;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.breakpointObserver
      .observe('(min-width: 1280px)')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this.isMobile = !state.matches;
      });
  }

  navigate() {
    window.open('http://iepho.ru/', '_blank');
    /*
    const navigationExtras: NavigationExtras = {
      queryParams: {
        banner: true,
      },
    };
    this.router.navigate(['courses'], navigationExtras);
    */
  }
}
