import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { UserService } from '@ezteach/_services/user.service';
import { RequestChangePublishingState } from '@ezteach/api/models/chat-lesson-member';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { User } from '@ezteach/api/models/user';
import {
  AddHomeworkPopupComponent
} from '@ezteach/group-lesson/components/group-lesson-toolbar/add-homework-popup/add-homework-popup.component';
import { GroupLessonParticipantsOverlayService } from '@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service';
import { GroupLessonPublishingStateService } from '@ezteach/group-lesson/services/group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonSettingsService } from '@ezteach/group-lesson/services/group-lesson-settings.service';
import { GroupLessonWhiteBoardService } from '@ezteach/group-lesson/services/group-lesson-whiteboard-service/group-lesson-whiteboard-service';
import {
  GroupLessonService
} from '@ezteach/group-lesson/services/group-lesson.service';
import { IMediaDevicesConstraints, OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import {
  DialogMediaStateResult,
  ModalRequestMediaStateChange
} from '@ezteach/modals/request-media-state-change/modal-request-media-state-change';
import { ET_IS_APPLE_MOBILE } from '@ezteach/shared/tokens/is-mobile-apple';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TUI_IS_ANDROID, TUI_IS_IOS } from '@taiga-ui/cdk';
import * as _ from 'lodash';
import { Device } from 'openvidu-browser';
import { WhiteBoardMode } from 'projects/ng-konva/src/lib/whiteboard/classes/white-board';
import { Subject, zip } from 'rxjs';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import tippy from 'tippy.js';
import { AccessTypes } from '../group-lesson-moderate-dropdown/group-lesson-moderate-dropdown.component';

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-toolbar-merge',
  templateUrl: './group-lesson-toolbar.component.html',
  styleUrls: ['./group-lesson-toolbar.component.scss'],
})
export class GroupLessonToolbarComponent implements OnInit, OnChanges {
  @ViewChild('addHomeworkToolbar') createMeetingButton: ElementRef;
  @Input() audioEnabled: boolean;
  @Input() videoEnabled: boolean;
  @Input() shareActive: boolean;
  @Input() shareDisabled: boolean;
  @Input() chatOpen: boolean;
  @Input() groupOpen: boolean;
  @Input() canAudio: boolean;
  @Input() canVideo: boolean;
  @Input() canShare: boolean;
  @Input() isMobile: boolean;
  @Input() isOwner = false;
  @Input() unreadMessageCounter: number;
  @Input() userTotalCount: number = null;
  @Input() topPositionOffsetByChat: number;
  @Input() ownerName: string;
  @Input() idLesson: number;
  @Input() isStarted: boolean;
  @Input() lessonLink: string;
  @Input() inviteLink: string;

  @Input() set publisherStateChangingValue(v) {
    if (this.isWhiteboardToggle) {
      this.isWhiteboardToggle = false;
      return;
    }
    this._publisherStateChangingValue = v;
    if (!v && this.queueStateChange.length > 0) {
      this.stateChange$.next(false);
    }
  }
  get publisherStateChangingValue(): boolean {
    return this._publisherStateChangingValue;
  }
  @Input() set lazyIniting(v) {
    this._lazyIniting = v;
    if (!v && this.queueStateChange.length > 0) {
      this.lazyIniting$.next(false);
    }
  }
  get lazyIniting(): boolean {
    return this._lazyIniting;
  }
  readonly scrollY$ = new Subject<void>();
  @Output() onLeaveSession = new EventEmitter();
  @Output() onStartShare = new EventEmitter();
  @Output() onAudioStatusChanged = new EventEmitter();
  @Output() onVideoStatusChanged = new EventEmitter();
  @Output() onStopShare = new EventEmitter();
  @Output() onChatOpenToggle = new EventEmitter();
  @Output() onGroupOpenToggle = new EventEmitter();
  @Output() onStartCall = new EventEmitter();
  @Output() onMuteChanged = new EventEmitter<AccessTypes>();
  @Output() onVideoMembersStatusChanged = new EventEmitter<AccessTypes>();
  @Output() fullScreenButtonClick = new EventEmitter();
  @Output() onHandOn = new EventEmitter();
  @Output() onHandOff = new EventEmitter();
  @ViewChild('fullScreenBtn') elementRef: ElementRef;
  @ViewChild('participantButton') participantButton: ElementRef;
  @ViewChild('tooltipAudioBtn') tooltipAudioBtn: ElementRef;
  mediaDevicesAccess: boolean;
  toolbarDropDownIsOpen = false;
  isSpeech: boolean;
  selectedCamera = 'default';
  cameras: Device[] = [];
  tooltipAlreadyCreated = false;
  currentTooltip: any;

  buttonType = ButtonType;

  infoPopupPositionsAudio = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }, -60, -280
    )
  ];

  infoPopupPositionsShareScreen = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }, -100, -280
    )
  ];

  infoPopupPositionsVideo = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }, -100, -280
    )
  ];

  infoPopupPositionsDropDown = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'end', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }, 22, -11
    ),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }, -100, -280
    )
  ];

  menuPositions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }, 0, -10),
  ];

  isAudioInfoPopupOpen = false;
  isVideoInfoPopupOpen = false;
  isShareInfoPopupOpen = false;
  isHaveUnreadMessages = false;
  isMenuOpen = false;
  isParticipantsOpen = false;
  private dialogRef: MatDialogRef<any>;
  private requestQueue: RequestChangePublishingState[] = [];
  private currentRequest: RequestChangePublishingState | null;
  private _lazyIniting = false;
  private _publisherStateChangingValue: boolean;
  private queueStateChange: ChatLessonMemberPublishingStateEnum[] = [];
  private readonly stateChange$: Subject<boolean> = new Subject<boolean>();
  private readonly lazyIniting$: Subject<boolean> = new Subject<boolean>();
  whiteBoardEnabled = false;
  whiteBoardMode = WhiteBoardMode;
  isWhiteboardToggle = false;
  readonly tooltipLeaveContent: string = this.translocoService.translate(`Завершить занятие`);
  readonly tooltipChatContent: string = this.translocoService.translate(`Чат`);
  readonly tooltipAudioContent: string = this.translocoService.translate(`Вкл/выкл микрофон`);
  readonly tooltipVideoContent: string = this.translocoService.translate(`Вкл/выкл камеру`);
  readonly tooltipParticipantContent: string = this.translocoService.translate(`Количество участников`);
  readonly tooltipSettingsContent: string = this.translocoService.translate(`Настройки аудио/видео`);
  readonly tooltipWhiteboardContent: string = this.translocoService.translate(`Интерактивная доска`);
  readonly tooltipShareScreenContent: string = this.translocoService.translate(`Выбор экрана для демонстрации`);
  readonly tooltipFullscreenContent: string = this.translocoService.translate(`Полноэкранный режим`);
  readonly tooltipAddHomework: string = this.translocoService.translate(`Добавить домашнее задание`);
  readonly userdata: User = this.userService.userData$.value;


  constructor(
    public groupLessonService: GroupLessonService,
    private openViduService: OpenViduService,
    private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService,
    private groupLessonSettingsService: GroupLessonSettingsService,
    private groupLessonWhiteBoardService: GroupLessonWhiteBoardService,
    public groupLessonReactionService: GroupLessonReactionService,
    private matDialog: MatDialog,
    private translocoService: TranslocoService,
    private groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private userService: UserService,
    @Inject(TUI_IS_IOS) readonly isIOS: boolean,
    @Inject(TUI_IS_ANDROID) readonly isANDROID: boolean,
    @Inject(ET_IS_APPLE_MOBILE) readonly isAppleMobile: boolean,
  ) { }

  ngOnInit() {
    // console.log('IsAppleMobile', this.isAppleMobile, 'isANDROID', this.isANDROID);

    this.openViduService.mediaDevicesConstraints$
      .pipe(
        untilDestroyed(this),
        tap((v: IMediaDevicesConstraints) => {
          console.log('mediaDevicesAccess ', this.mediaDevicesAccess, v)
          this.mediaDevicesAccess = this.groupLessonService.isMediaDevicesAccess(v);
          if (this.audioEnabled) {
            this.audioEnabled = v.audio;
          }
          if (this.videoEnabled) {
            this.videoEnabled = v.video;
          }
        }),
      )
      .subscribe();

    this.groupLessonService.toolbarDropDownIsOpen
      .pipe(
        untilDestroyed(this),
        tap(value => (this.toolbarDropDownIsOpen = value)),
      )
      .subscribe();

    this.groupLessonService.isSpeech
      .pipe(
        untilDestroyed(this),
        tap(v => (this.isSpeech = v)),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.isOpened
      .pipe(
        untilDestroyed(this),
        tap(v => (this.isParticipantsOpen = v)),
      )
      .subscribe();

    this.groupLessonSettingsService.popupIsOpened
      .pipe(
        untilDestroyed(this),
        tap(v => (this.isMenuOpen = !v)),
      )
      .subscribe();

    this.groupLessonWhiteBoardService.whiteBoardOpened$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          if (x) {
            this.isMenuOpen = false;
          }
        }),
      )
      .subscribe();

    this.groupLessonWhiteBoardService.whiteBoardClosed$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          if (x) {
            this.isMenuOpen = false;
          }
        }),
      )
      .subscribe();

    this.openViduService.speakingWhenMuted$
      .pipe(
        untilDestroyed(this),
        debounceTime(4000),
        filter((v: boolean) => v && !this.audioEnabled),
        tap(() => this.createSpeechTooltip()),
      )
      .subscribe();

    this.groupLessonService.onRequestMediaState$
      .pipe(
        untilDestroyed(this),
        tap(newState => this.updateRequestQueue(newState)),
      )
      .subscribe();

    zip(this.lazyIniting$, this.stateChange$)
      .pipe(
        untilDestroyed(this),
        tap(_ => this.updateStateChangeQueue()),
      )
      .subscribe();

    this.groupLessonPublishingStateService.membersState$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          x.map(x => x.currentValue);
          const state = _.flatten(x.map(x => x.currentValue));
          this.whiteBoardEnabled =
            state.findIndex(
              s => s.name.toUpperCase() === ChatLessonMemberPublishingStateEnum.Whiteboard.toUpperCase(),
            ) !== -1;
        }),
      )
      .subscribe();
  }

  chatOpenToggle() {
    this.isHaveUnreadMessages = false;
    this.groupLessonService.parcipantsOpen = false;
    this.groupLessonService.chatOpenToggle();
  }

  groupOpenToggle() {
    this.groupLessonService.chatOpen$.next(false);
    this.groupLessonService.parcipantsOpenOpenToggle();
  }

  audioStatusChanged() {
    console.log('toggle mic audioEnabled mediaDevicesAccess', this.audioEnabled, this.mediaDevicesAccess)

    console.log('lazyIniting || publisherStateChangingValue || !sessionInitiated ', this.lazyIniting, this.publisherStateChangingValue, this.openViduService.sessionInitiated)
    if (this.lazyIniting || this.publisherStateChangingValue || !this.openViduService.sessionInitiated) {
      return;
    }
    if (this.mediaDevicesAccess) {
      this.onAudioStatusChanged.emit();
    } else {
      this.openViduService.showMediaConstraintsModal();
    }
  }

  videoStatusChanged() {
    if (this.lazyIniting || this.publisherStateChangingValue || !this.openViduService.sessionInitiated) {
      return;
    }

    if (this.mediaDevicesAccess) {
      this.onVideoStatusChanged.emit();
    } else {
      this.openViduService.showMediaConstraintsModal();
    }
  }

  leaveSession() {
    this.onLeaveSession.emit();
  }

  startShare() {
    this.onStartShare.emit();
  }

  stopShare() {
    this.onStopShare.emit();
  }

  startCall() {
    this.onStartCall.emit();
  }

  onMuteChangedClick($event) {
    this.onMuteChanged.emit($event);
  }

  onVideoStatusChangedClick($event) {
    this.onVideoMembersStatusChanged.emit($event);
  }

  onFullScreenButtonClick(e) {
    this.groupLessonService.fullScreen$.next(e);
  }

  get usersCount() {
    return this.userTotalCount;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { unreadMessageCounter } = changes;
    if (unreadMessageCounter) {
      this.isHaveUnreadMessages = unreadMessageCounter.currentValue > 0;
    }
  }

  showUserList() {
    const origin = this.participantButton;
    this.groupLessonParticipantsOverlayService.open(origin, this.isMobile, false, this.isOwner, this.isSpeech, {
      backdropClass: 'backdrop-without-background',
    });
  }

  @HostListener('window:keydown', ['$event'])
  onHotKeysPress(event: KeyboardEvent) {
    // event.keyCode === 68 in different keyboard layout: 'en' = 'd' ; 'rus' = 'в'
    if (event.ctrlKey && event.keyCode === 68 && this.canAudio) {
      event.preventDefault();
      this.audioStatusChanged();
    }
    // event.keyCode === 69 in different keyboard layout: 'en' = 'e' ; 'rus' = 'у'
    if (event.ctrlKey && event.keyCode === 69 && this.canVideo) {
      event.preventDefault();
      this.videoStatusChanged();
    }
  }

  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  openAudioInfoPopup() {
    this.isAudioInfoPopupOpen = !this.isAudioInfoPopupOpen;
  }

  openVideoInfoPopup() {
    this.isVideoInfoPopupOpen = !this.isVideoInfoPopupOpen;
  }

  openShareInfoPopup() {
    this.isShareInfoPopupOpen = !this.isShareInfoPopupOpen;
  }

  closeInfoPopup() {
    this.isAudioInfoPopupOpen = false;
    this.isVideoInfoPopupOpen = false;
    this.isShareInfoPopupOpen = false;
  }

  handToggle() {
    this.groupLessonReactionService.currentUserHandState$.value ? this.groupLessonService.handOff() : this.groupLessonService.handOn();
  }


  createTooltip(tooltipContent: string, el: Element) {
    if (!el) {
      return;
    }
    if (this.tooltipAlreadyCreated) {
      return;
    }
    if (this.isANDROID || this.isAppleMobile || this.isMobile || this.isIOS) {//В мобильной версии подсказки не отображаются
      return;
    }
    this.currentTooltip = tippy(el, this.getTooltipOptions(tooltipContent));
    this.tooltipAlreadyCreated = true;
  }

  removeTooltip() {
    if (!this.currentTooltip) {
      return;
    }
    this.currentTooltip.destroy();
    this.currentTooltip = undefined;
    this.tooltipAlreadyCreated = false;
  }

  getTooltipOptions(tooltipContent: string) {
    return {
      content: tooltipContent,
      allowHTML: true,
      theme: 'tooltip',
    };
  }

  createSpeechTooltip() {
    if (this.tooltipAlreadyCreated) {
      return;
    }
    this.currentTooltip = tippy(this.tooltipAudioBtn.nativeElement, this.getSpeechTooltipOptions());
    this.currentTooltip.show();
    this.tooltipAlreadyCreated = true;
    setTimeout(() => {
      this.removeTooltip();
    }, 5000);
  }

  getSpeechTooltipOptions() {
    const self = this;
    return {
      content: this.translocoService.translate(
        `Собеседники Вас не слышат, так как Ваш микрофон выключен. Чтобы включить его, нажмите либо на значок микрофона, либо Ctrl+D`,
      ),
      allowHTML: true,
      theme: 'tooltip',
      onHidden() {
        self.removeTooltip();
      },
    };
  }

  private createRequestModal(request: RequestChangePublishingState): void {
    this.dialogRef = this.matDialog.open(ModalRequestMediaStateChange, {
      panelClass: 'modal-request-media-state-change',
      data: {
        ownerName: this.ownerName,
        deviceType: request.name,
        requestState: request.isOn,
      },
    });
    this.dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        first(),
        tap(_ => this.handleRequestsQueue()),
        filter(v => v !== DialogMediaStateResult.dismiss),
        tap(_ => this.changeMediaStateByType(request.name)),
      )
      .subscribe();
  }

  private handleRequestsQueue(): void {
    if (this.requestQueue.length > 0 && this.dialogRef?.getState() !== MatDialogState.OPEN) {
      this.currentRequest = this.requestQueue[0];
      this.requestQueue.splice(0, 1);
      this.createRequestModal(this.currentRequest);
    } else {
      this.currentRequest = null;
    }
  }

  private changeMediaStateByType(deviceType: ChatLessonMemberPublishingStateEnum): void {
    if (this.publisherStateChangingValue || this.lazyIniting) {
      this.queueStateChange.push(deviceType);
      return;
    }
    if (deviceType === ChatLessonMemberPublishingStateEnum.Video) {
      this.videoStatusChanged();
    } else {
      this.audioStatusChanged();
    }
  }

  private updateRequestQueue(request: RequestChangePublishingState): void {
    if (!this.presentInQueue(request)) {
      this.requestQueue.push(request);
    }
    if (!this.currentRequest) {
      this.handleRequestsQueue();
    }
  }

  private presentInQueue(request: RequestChangePublishingState): boolean {
    const alreadyPresent = this.requestQueue.some(r => r.name === request.name && r.isOn === request.isOn);

    const equalCurrentRequest =
      this.currentRequest && this.currentRequest?.name === request.name && this.currentRequest?.isOn === request.isOn;
    return alreadyPresent || equalCurrentRequest;
  }

  private updateStateChangeQueue(): void {
    if (this.queueStateChange.length > 0) {
      this.changeMediaStateByType(this.queueStateChange[0]);
      this.queueStateChange.splice(0, 1);
    }
  }

  toggleAudioInfoPopup() {
    this.isAudioInfoPopupOpen = !this.isAudioInfoPopupOpen;
  }

  toggleVideoInfoPopup() {
    this.isVideoInfoPopupOpen = !this.isVideoInfoPopupOpen;
  }

  toggleWhiteboard() {
    this.isWhiteboardToggle = true;
    this.whiteBoardEnabled ? this.groupLessonService.disableWhiteBoardMode() : this.groupLessonService.enableWhiteBoardMode();
  }
  addHomework() {
    this.dialogRef = this.matDialog.open(AddHomeworkPopupComponent, { data: { idLesson: this.idLesson, isStarted: this.isStarted } });
  }
}
