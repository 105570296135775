import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { InstantLessonDurationEnum } from '../models/instant-lesson-duration-enum';
import { TutorUserSearchResultPagedApiResponse } from '../models/tutor-user-search-result-paged-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { TutorUserSearchResult } from '@ezteach/api/models/tutor-user-search-result';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  readonly searchTutorHistory$: BehaviorSubject<TutorUserSearchResult[] | null> = new BehaviorSubject(null);
  readonly scrollY$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly pageNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  constructor(config: ApiConfiguration, http: HttpClient, private location: Location, public router: Router) {
    super(config, http);

    this.location.onUrlChange((url: string) => {
      const savingStatePaths = ['teacher', 'regular'];
      const saveState = savingStatePaths.some(path => url.includes(path));
      if (this.searchTutorHistory$.value && !saveState) {
        this.searchTutorHistory$.next(null);
        this.pageNumber$.next(1);
        this.scrollY$.next(0);
      }
    });
  }

  /**
   * Path part for operation apiV1SearchTutorsInstantGet
   */
  static readonly ApiV1SearchTutorsInstantGetPath = '/api/v1/search/tutors/instant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SearchTutorsInstantGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SearchTutorsInstantGet$Response(params?: {
    InstantLessonDuration?: InstantLessonDurationEnum;
    DisciplinesNames?: null | string[];
    SpecializationId?: null | number;
    PageNumber?: number;
    PageSize?: number;
    TutorName: null | string;
  }): Observable<StrictHttpResponse<TutorUserSearchResultPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SearchService.ApiV1SearchTutorsInstantGetPath, 'get');
    if (params) {
      rb.query('TutorName', params.TutorName, {});
      rb.query('InstantLessonDuration', params.InstantLessonDuration, {});
      rb.query('DisciplineName', params.DisciplinesNames, {});
      rb.query('SpecializationId', params.SpecializationId, {});
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<TutorUserSearchResultPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SearchTutorsInstantGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SearchTutorsInstantGet(params?: {
    InstantLessonDuration?: InstantLessonDurationEnum;
    DisciplinesNames?: null | string[];
    SpecializationId?: null | number;
    PageNumber?: number;
    PageSize?: number;
    TutorName: null | string;
  }): Observable<TutorUserSearchResultPagedApiResponse> {
    return this.apiV1SearchTutorsInstantGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TutorUserSearchResultPagedApiResponse>) =>
          r.body as TutorUserSearchResultPagedApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1SearchTutorsScheduledGet
   */
  static readonly ApiV1SearchTutorsScheduledGetPath = '/api/v1/search/tutors/scheduled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SearchTutorsScheduledGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SearchTutorsScheduledGet$Response(params?: {
    PriceFrom?: null | number;
    PriceTo?: null | number;
    Name?: null | string;
    RatingFrom?: null | number;
    LessonsAccomplished?: null | number;
    AcademicDegreeId?: null | number;
    DisciplinesNames?: null | string[];
    SpecializationId?: null | number;
    RegisteredAfter?: null | Date;
    LessonAfter?: null | Date;
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<TutorUserSearchResultPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SearchService.ApiV1SearchTutorsScheduledGetPath, 'get');
    if (params) {
      rb.query('PriceFrom', params.PriceFrom, {});
      rb.query('PriceTo', params.PriceTo, {});
      rb.query('Name', params.Name, {});
      rb.query('RatingFrom', params.RatingFrom, {});
      rb.query('LessonsAccomplished', params.LessonsAccomplished, {});
      rb.query('AcademicDegreeId', params.AcademicDegreeId, {});
      rb.query('DisciplineName', params.DisciplinesNames, {});
      rb.query('RegisteredAfter', params.RegisteredAfter?.toISOString(), {});
      rb.query('LessonAfter', params.LessonAfter?.toISOString(), {});
      rb.query('SpecializationId', params.SpecializationId, {});
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<TutorUserSearchResultPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SearchTutorsScheduledGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SearchTutorsScheduledGet(params?: {
    PriceFrom?: null | number;
    PriceTo?: null | number;
    Name?: null | string;
    RatingFrom?: null | number;
    LessonsAccomplished?: null | number;
    AcademicDegreeId?: null | number;
    DisciplinesNames?: null | string[];
    SpecializationId?: null | number;
    PageNumber?: number;
    PageSize?: number;
    RegisteredAfter?: null | Date;
    LessonAfter?: null | Date;
  }): Observable<TutorUserSearchResultPagedApiResponse> {
    return this.apiV1SearchTutorsScheduledGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TutorUserSearchResultPagedApiResponse>) =>
          r.body as TutorUserSearchResultPagedApiResponse,
      ),
    );
  }
}
