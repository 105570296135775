<div
  class="container"
  [class]="{ 'half-view': isHalfView, 'nav-item': isNavItem }"
>
  <h1>{{ 'История переводов' | transloco }}</h1>
  <mat-form-field>
    <mat-select
      [(value)]="selectedType"
      (selectionChange)="updateSortType()"
    >
      <mat-option
        *ngFor="let type of sortType"
        [value]="type.name"
      >{{ type.value | transloco }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="gridList">
    <div
      class="gridElem"
      *ngFor="let payment of paymentDataHistory"
    >
      <div class="first-row">
        <div class="cell-description">{{ payment.name | transloco }}</div>
        <div
          class="cell-icon"
          routerLink="/history/{{payment.chatLessonId}}"
        >
          <svg-icon-sprite
            [src]="payment.lessonFormatId === lessonFormat.Group
            ? 'icon-lesson-group'
            : 'icon-lesson-one'"
            [width]="'32px'"
            [height]="'32px'"
          ></svg-icon-sprite>
        </div>
        <div
          *ngIf="userData.isTutor"
          class="cell-sum tutor"
          [ngClass]="payment.resultType === paymentResult.Success ? 'success' : 'error'"
        >
          {{
          payment.tutorPayout > 0
          ? (payment.tutorPayout | currency: 'RUB ':'symbol':'3.2':'ru-ru')
          : (payment.tutorPayout | currency: 'RUB ':'symbol-narrow')
          }}
        </div>
        <div
          *ngIf="!userData.isTutor"
          class="cell-sum student"
          [ngClass]="payment.resultType === paymentResult.Success ? 'success' : 'error'"
        > {{ payment.lessonCost > 0
          ? (payment.lessonCost | currency: 'RUB ':'symbol':'3.2':'ru-ru')
          : (payment.lessonCost | currency: 'RUB ':'symbol-narrow')
          }}
        </div>
      </div>
      <div class="second-row">
        <div>
          <div class="cell-date">{{ payment.date | date }}</div>
          <div class="cell-payment-info">{{ 'Чек отсутствует' | transloco }}</div>
        </div>
        <div class="download-block">
          <p class="download-block__title">{{ 'Скачать в формате PDF' | transloco }} </p>
          <a class="download-block__icon">
            <img
              src="assets/img/svg/download-disabled.svg"
              alt="Загрузка недоступна"
            />
          </a>
        </div>
      </div>
    </div>
  </div>


  <div
    *ngIf="totalPages > currentPage"
    class="widget-my-lessons__search_action"
  >
    <button class="more-btn">
      <span
        class=""
        (click)="showMoreBtnClick()"
      >{{ 'Показать еще' | transloco }} </span>
    </button>
  </div>

</div>