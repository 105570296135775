import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@ezteach/api/api-configuration';
import { BaseService } from '@ezteach/api/base-service';
import {
  CreateHomeWorkActualRequest,
  CreateHomeWorkTemplateRequest,
  EditHomeWorkTemplateRequest,
  HomeWorkTemplate,
  RateHomeworkMark
} from '@ezteach/api/models/homework';
import { HttpStatusCode } from '@ezteach/api/models/http-status-code';
import { RequestBuilder } from '@ezteach/api/request-builder';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { ApiResult } from '@ezteach/core/models/api-response.interface';
import {
  HomeWorkActualAnswerInterface,
  HomeWorkTemplateInterface,
  HomeworkRecords
} from '@ezteach/homeworks/homework.interface';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface HomeworkTemplateApiPagedDto {
  currentPage: number;
  records: HomeWorkTemplate[];
  totalPages: number;
  totalRecords: number;
}

export interface HomeworkRewiewApiPagedDto {
  currentPage: number;
  records: HomeWorkTemplateInterface[];
  totalPages: number;
  totalRecords: number;
}

export interface HomeworkTemplatePagedApiResponse {
  data?: null | HomeworkTemplateApiPagedDto;
  pageNumber?: null | number;
  pageSize?: null | number;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
  totalPages?: null | number;
  totalRecords?: null | number;
}

export interface HomeworkRewiewPagedApiResponse {
  data?: null | HomeworkRewiewApiPagedDto;
  pageNumber?: null | number;
  pageSize?: null | number;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
  totalPages?: null | number;
  totalRecords?: null | number;
}

export interface HomeworkTemplateApiResponse {
  data?: HomeWorkTemplate;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}
export interface HomeworkAllApiResponse {
  data?: HomeWorkTemplateInterface[];
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}
export interface HomeworkStudentApiResponse {
  data?: HomeWorkTemplateInterface[];
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
  pageNumber?: null | number;
  pageSize?: null | number;
  totalPages?: null | number;
  totalRecords?: null | number;
}

export interface DeleteHomeworkTemplateApiResponse {
  data?: boolean;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}

export interface UpdateHomeWorkTemplateRequest {
  name: string;
  description: string;
  topic: string;
}


export interface HomeWorkTemplateFileDto {
  file: HomeWorkTemplateFile;
}

export interface HomeWorkTemplateFile {
  id: number;
  userFileTypeId: string;
  attachmentTypeId: string;
  fileName: string;
  sourceFileName: string;
  fileSize: number;
  contentType: string;
}

export interface HomeworkActualAnswerApiResponse {
  data?: HomeWorkActualAnswerInterface;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HomeworksService extends BaseService {

  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getHomeworkReview(params: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<HomeworkRewiewPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/review', 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkRewiewPagedApiResponse>),
      );
  }

  getHomeworkTemplates(params: {
    PageNumber?: number;
    PageSize?: number;
    SortByDesc?: string;
  }): Observable<StrictHttpResponse<HomeworkTemplatePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate', 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});

      if (!!params?.SortByDesc?.length) {
        rb.query('SortByDesc', params.SortByDesc, {});
      }
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplatePagedApiResponse>),
      );
  }

  GetHomeworkTemplateByDisciplineId(params: {
    PageNumber?: number;
    PageSize?: number;
    SortByDesc?: string;
    DisciplineId: number;
  }): Observable<StrictHttpResponse<HomeworkTemplatePagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/discipline/{disciplineId}', 'get');
    if (params) {
      rb.path('disciplineId', params.DisciplineId, {});

      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});

      if (!!params?.SortByDesc?.length) {
        rb.query('SortByDesc', params.SortByDesc, {});
      }
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplatePagedApiResponse>),
      );
  }

  getHomeworkTemplateByUid(params: {
    uid: string;
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}', 'get');
    if (params) {
      rb.path('uid', params.uid, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }

  createHomeworkTemplate(params: {
    body: CreateHomeWorkTemplateRequest;
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate', 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }
  editHomeworkTemplate(params: {
    body: EditHomeWorkTemplateRequest;
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}', 'patch');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
      rb.path('uid', params.body.uid, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }

  deleteHomeworkTemplate(params?: {
    uid: string;
  }): Observable<StrictHttpResponse<DeleteHomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}', 'delete');
    if (params) {
      rb.path('uid', params.uid, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<DeleteHomeworkTemplateApiResponse>),
      );
  }

  addAttachmentsOnHomeworkTemplate(params?: {
    uId: string;
    attachments: File[];
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}/attachment', 'post');
    if (params) {
      rb.path('uid', params.uId, {});
      rb.body({ attachments: params.attachments }, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }

  deleteAttachmentOnHomeworkTemplate(params?: {
    uid: string;
    fileId: number;
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}/attachment/{fileid}', 'delete');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.path('fileid', params.fileId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }

  deleteAttachmentsOnHomeworkTemplate(params?: {
    uId: string;
    fileIds: number[];
  }): Observable<StrictHttpResponse<HomeworkTemplateApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}/attachment', 'delete');
    if (params) {
      rb.path('uid', params.uId, {});
    }
    rb.body({ filesId: params.fileIds }, 'multipart/form-data');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkTemplateApiResponse>),
      );
  }


  updateHomeworkTemplate(params?: {
    uid: string;
    vm: UpdateHomeWorkTemplateRequest;
  }): Observable<StrictHttpResponse<HomeworkStudentApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homeWorkTemplate/{uid}', 'patch');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.body(params.vm);
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkStudentApiResponse>),
      );
  }

  getActualHomework(): Observable<StrictHttpResponse<HomeworkAllApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/actual', 'get');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkAllApiResponse>),
      );
  }

  getAllActualHomework(params: {
    PageNumber?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<HomeworkRewiewPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/actual', 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkRewiewPagedApiResponse>),
      );
  }

  getArchiveHomework(): Observable<StrictHttpResponse<HomeworkAllApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/homework/archive', 'get');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkAllApiResponse>),
      );
  }
  // getStudentHomework(params: { PageNumber?: number; PageSize?: number }): Observable<StrictHttpResponse<HomeworkStudentApiResponse>> {
  //   const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/completed', 'get');
  //   if (params) {
  //     rb.query('PageNumber', params.PageNumber, {});
  //     rb.query('PageSize', params.PageSize, {});
  //   }
  //   return this.http
  //     .request(
  //       rb.build({
  //         responseType: 'json',
  //         accept: 'application/json',
  //       }),
  //     )
  //     .pipe(
  //       filter((r: any) => r instanceof HttpResponse),
  //       map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkStudentApiResponse>),
  //     );
  // }

  // rateHomework(params): Observable<StrictHttpResponse<HomeWorkTemplateInterface>> {
  //   const rb = new RequestBuilder(this.rootUrl, `/api/v1/lessons/homework/${params.homeWorkId}/assessment`, 'patch');
  //   if (params) {
  //     rb.query('templateName', params.templateName, {});
  //     rb.query('disciplineId', params.disciplineId, {});
  //     rb.query('teacherComment', params.teacherComment, {});
  //     rb.query('attachments', params.attachments, {});
  //     rb.query('teacherMark', params.teacherMark, {});
  //   }
  //   return this.http.request(rb.build({responseType: 'json', accept: 'application/json'}))
  //     .pipe(
  //       filter((r: any) => r instanceof HttpResponse),
  //       map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeWorkTemplateInterface>),
  //     );
  // }
  /**
   * обновил запрос, надо потестировать
   * для запроса прописываем тип запроса get,post,pathc и т.д ( this.http.get(...)
   */

  rateHomework(body) {
    const data = {
      templateName: body.templateName,
      disciplineId: body.disciplineId,
      teacherComment: body.teacherComment,
      attachments: body.attachments,
      teacherMark: body.teacherMark,
    };
    return this.http.patch<ApiResult<HomeWorkTemplateInterface>>(this.rootUrl + `/api/v1/lessons/homework/${body.homeWorkId}/assessment`, { data });
  }
  getExecutionHomeworks() {
    return this.http.get<ApiResult<HomeworkRecords>>(this.rootUrl + '/api/v1/lessons/homework/execution');
  }
  getCompletedHomeworks() {
    return this.http.get<ApiResult<HomeworkRecords>>(this.rootUrl + '/api/v1/lessons/homework/completed');
  }
  getHomeworActualkUid(
    homeWorkId: number
  ): Observable<StrictHttpResponse<HomeworkActualAnswerApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, `/api/v1/lessons/homework/${homeWorkId}`, 'get');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkActualAnswerApiResponse>),
      );
  }

  getOpenFile(id: string, file: number): Observable<any> {
    const rb = new RequestBuilder(this.rootUrl, `/api/v1/homeworktemplate/${id}/${file}`, 'get');
    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json',
      }),
    );
  }

  getOpenTeacherFile(homeWorkId: number, fileId: number): Observable<any> {
    const rb = new RequestBuilder(this.rootUrl, `/api/v1/lessons/${homeWorkId}/${fileId}`, 'get');
    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json',
      }),
    );
  }

  createActualHomeworkPost(params?: {
    homeWorkId: number;
    body: CreateHomeWorkActualRequest;
  }): Observable<StrictHttpResponse<HomeworkActualAnswerApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/{homeWorkId}/answer', 'patch');
    if (params) {
      rb.path('homeWorkId', params.homeWorkId, {});
      rb.body({ memberAnswer: params.body.memberAnswer, memberAnswerFile: params.body.memberAnswerFile }, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkActualAnswerApiResponse>),
      );
  }

  rateHomeworkMark(params?: {
    homeWorkId: number;
    body: RateHomeworkMark;
  }): Observable<StrictHttpResponse<HomeworkActualAnswerApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/lessons/homework/{homeWorkId}/assessment', 'patch');
    if (params) {
      rb.path('homeWorkId', params.homeWorkId, {});
      rb.body({ teacherComment: params.body.teacherComment, teacherMark: params.body.teacherMark, teacherFile: params.body.teacherFile }, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<HomeworkActualAnswerApiResponse>),
      );
  }
}
