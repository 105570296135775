import { Component, Input } from '@angular/core';

@Component({
  selector: 'ezteach-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent {
  @Input() notificationMessage: string;
}
