import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { BaseApiResponse } from '../models/base-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class SystemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1SystemValidationEmailValidatecodePost
   */
  static readonly ApiV1SystemValidationEmailValidatecodePostPath = '/api/v1/system/validation/email/validatecode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SystemValidationEmailValidatecodePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SystemValidationEmailValidatecodePost$Response(params?: {
    Email?: null | string;
    ConfirmationCode?: null | string;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.ApiV1SystemValidationEmailValidatecodePostPath, 'post');
    if (params) {
      rb.query('Email', params.Email, {});
      rb.query('ConfirmationCode', params.ConfirmationCode, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SystemValidationEmailValidatecodePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SystemValidationEmailValidatecodePost(params?: {
    Email?: null | string;
    ConfirmationCode?: null | string;
  }): Observable<BaseApiResponse> {
    return this.apiV1SystemValidationEmailValidatecodePost$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }
}
