<div
  *ngFor="let message of items$ | async; trackBy: trackByFn"
  [@snackbarAnimation]="'in'"
  class="container dark-snackbar"
  [ngClass]="message.config.containerClass"
>
  <div
    class="content-wrapper"
    [ngClass]="message.config.contentClass"
  >
    <img
      class="avatar-snackbar"
      *ngIf="message.avatarSrc"
      [src]="message.avatarSrc"
      [ngClass]="message.config.avatarClass"
    />
    <div
      class="content-snackbar"
      [innerHtml]="message.contentText"
    ></div>
  </div>
  <button
    mat-button
    class="button-snackbar"
    [ngClass]="message.config.buttonClass"
    (click)="onCloseButtonClick(message)"
  >
    {{ message.data.closeButtonText | uppercase }}
  </button>
</div>