import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { setProfileTitle } from '../../+state';
import { ProfileApiService } from '@ezteach/profile/services/profile-api.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ezteach-profile-telegram-bot',
  templateUrl: './profile-telegram-bot.component.html',
  styleUrls: ['./profile-telegram-bot.component.scss']
})
export class ProfileTelegramBotComponent implements OnInit {

  form: UntypedFormGroup;
  title = `Телеграм бот`;
  constructor(@Inject(DOCUMENT) private document: Document,
  private readonly profileApi: ProfileApiService,
  private readonly store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(setProfileTitle({ title: this.title }));
  }

  redirectToTelegram(){
    this.profileApi.getTelegramAuthorizationPath().subscribe(result =>{
          if(result.succeeded && result.data){
              this.document.location.href = result.data;         
          }        
       });
  }

}
