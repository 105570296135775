import { Injectable } from '@angular/core';
import { ChatLessonMemberPublishingStateValue } from '@ezteach/api/models/chat-lesson-member';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonWaitService {
  readonly defaultResultion: ResolutionInternal = { width: 1920, height: 1080 };

  publishingState: ChatLessonMemberPublishingStateValue[] = [];
  audioMediaTrack$ = new BehaviorSubject<MediaStreamTrack>(null);
  videoMediaTrack$ = new BehaviorSubject<MediaStreamTrack>(null);
  audioOuputDeviceChanged$ = new BehaviorSubject<string>(null);
  audioEnabled: boolean;
  videoEnabled: boolean;

  constructor(private localStorageService: LocalStorageService) {}

  setPublisherVideoResolutionByStream(mediaStream: MediaStream) {
    const mediaTrack = mediaStream?.getVideoTracks()?.[0];
    this.setUpPublisherVideoResolutionByTrack(mediaTrack);
  }

  setPublisherVideoResolutionByStreamTrack(track: MediaStreamTrack) {
    this.setUpPublisherVideoResolutionByTrack(track);
  }

  setUpPublisherVideoResolutionByTrack(track?: MediaStreamTrack) {
    const trackResolution = this.getTrackResolution(track);
    this.setUpPublisherVideoResolution(trackResolution);
  }

  getTrackResolution(track: MediaStreamTrack): ResolutionInternal {
    const settings = track?.getSettings();
    if (settings && settings.width && settings.height) {
      return { width: settings.width, height: settings.height };
    }
    return this.defaultResultion;
  }

  setUpPublisherVideoResolution(resolution: ResolutionInternal) {
    this.localStorageService.set('publisherVideoWidth', resolution.width);
    this.localStorageService.set('publisherVideoHeight', resolution.height);
  }
}

export interface ResolutionInternal {
  width: number;
  height: number;
}
