import { Pipe, PipeTransform } from '@angular/core';
import { StreamManager } from 'openvidu-browser';
import { Optional } from '@ezteach/utils';

enum Roles {
  Moderator = 'MODERATOR',
  Publisher = 'PUBLISHER',
}

interface ConnectionData {
  userName: string;
  userRole: Roles;
}

const OPENVIDU_DATA_SPLITTER = '%/%';

function getConnectionData(data: string): ConnectionData {
  if (data.includes(OPENVIDU_DATA_SPLITTER)) {
    data = data.split(OPENVIDU_DATA_SPLITTER)[0];
  }

  return JSON.parse(data).clientData;
}

@Pipe({
  name: 'withoutModerator',
})
export class WithoutModeratorPipe implements PipeTransform {
  transform(subscriber: StreamManager[], publisher?: StreamManager): StreamManager[] {
    Optional.of(publisher).ifPresent(value => subscriber.push(value));

    return subscriber.filter(sub =>
      Optional.of(sub.stream)
        .map(({ connection }) => connection)
        .map(({ data }) => data)
        .map(value => getConnectionData(value).userRole !== Roles.Moderator)
        .orElse(false),
    );
  }
}
