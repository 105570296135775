import Konva from 'konva';
import { BackLayerPoint } from './backlayer.model';

/** Класс, кэширующий отрисованные точки и объекты канвы */
export class PointStorage {
  /*
   * Отрисованные точки
   */
  private readonly points = new Map<string, Konva.Rect>();

  has(point: BackLayerPoint): boolean {
    return this.points.has(toKey(point));
  }
  add(point: BackLayerPoint, rect: Konva.Rect): void {
    this.points.set(toKey(point), rect);
  }

  remove(point: BackLayerPoint): void {
    this.points.delete(toKey(point));
  }

  filter(condition: (item: BackLayerPoint) => boolean): { point: BackLayerPoint; rect: Konva.Rect }[] {
    const result = [];
    // Удаляем объекты разметки, которые находятся за пределами границ видимости
    this.points.forEach((rect, key) => {
      const point = toPoint(key);
      if (condition(point)) result.push({ point, rect });
    });
    return result;
  }
}

function toKey(point: BackLayerPoint): string {
  return `${point.x}_${point.y}`;
}

function toPoint(key: string): BackLayerPoint {
  const points = key.split('_');
  return { x: +points[0], y: +points[1] };
}
