<div
  class="calendar"
  ezteachTutorialStep
  ezteachEzCalendarLegend
  ezteachCalendarTimeGridMode
  [tutorialElementName]="$any('calendar')"
>
  <div
    class="calendar_el"
    [class]="{ calendarWidget: widget, stickyToolbar: !widget || isMobile }"
    (waResizeObserver)="onContainerResize()"
  >
    <button
      *ngIf="widget"
      (click)="scrollTop()"
      class="fc-prev-button fc-button fc-button-primary fc-top"
      type="button"
      aria-label="prev"
    >
      <span class="fc-icon fc-icon-left-single-arrow"></span>
    </button>
    <button
      *ngIf="widget"
      (click)="scrollBottom()"
      class="fc-next-button fc-button fc-button-primary fc-bottom"
      type="button"
      aria-label="next"
    >
      <span class="fc-icon fc-icon-left-single-arrow"></span>
    </button>
  </div>
  <div
    class="calendar_button_wrapper"
    *ngIf="(userdata && userdata.isTutor && !user && typeView === 'timeGridWeek') || userdata.id == user"
    [ngClass]="{ sticky: fixedElementPosition, 'calendar_button-clicked': editMode }"
  ></div>
  <div
    class="timeGridType_wrapper"
    style="display: 'none'"
  >
    <span class="timeGridType_text"> {{ 'Показывать' | transloco }}:</span>
    <mat-form-field class="timeGridType">
      <mat-select
        [(value)]="typeView"
        (selectionChange)="$typeView.next(typeView)"
      >
        <mat-option value="timeGridWeek">{{ timeGrid.timeGridWeek | transloco }}</mat-option>

        <mat-option value="dayGridMonth">{{ timeGrid.dayGridMonth | transloco }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>