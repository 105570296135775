import { createReducer, on } from '@ngrx/store';
import { none, Option } from 'fp-ts/lib/Option';
import { DefaultSteps, ITutorialData, TutorialTypes, TutorialWithStepTuple } from '../models';
import * as TutorialActions from './tutorial.actions';

export const tutorialFeatureKey = 'tutorial';

export interface State extends ITutorialData {
  currentStepShown: Option<TutorialWithStepTuple>;
}

export const initialState: State = {
  type: TutorialTypes.NOOP,
  progress: DefaultSteps.NOOP,
  currentStepShown: none,
};

export const reducer = createReducer(
  initialState,
  on(TutorialActions.updateTutorial, (state, { tutorial }) => ({ ...state, ...tutorial })),
  on(TutorialActions.updateTutorialStep, (state, { progress }) => ({ ...state, progress })),
  on(TutorialActions.startTutorial, (state, { tutorial }) => ({ ...state, ...tutorial })),
  on(TutorialActions.finishTutorial, state => ({ ...initialState })),
  on(TutorialActions.updateCurrentStepShown, (state, { currentStepShown }) => ({ ...state, currentStepShown })),
);
