import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Input() exclude: HTMLElement;
    @Output() clickOutside = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event.target'])
    onClick(target: HTMLElement) {
        if (!this.elementRef.nativeElement.contains(target) && !this.isExcluded(target)) {
            this.clickOutside.emit();
        }
    }

    private isExcluded(target: HTMLElement): boolean {
        if (this.exclude) {
            return this.exclude.contains(target);
        }
        return false;
    }
}