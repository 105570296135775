import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TypedAction } from '@ngrx/store/src/models';
import { DialogNavigationStep } from '../helpers';

@Component({
  selector: 'ezteach-tutorial-dialog-wrapper',
  template: `
    <div>
      <ng-container *ngIf="!skipLink; else skipLinkTmpl">
        <a
          class="ezteach-tutorial-dialog__skip"
          (click)="handleSkipClick()"
        >
          Пропустить
        </a>
      </ng-container>
      <ng-template #skipLinkTmpl>
        <ng-content select=".ezteach-tutorial-dialog__skip"></ng-content>
      </ng-template>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./tutorial-dialog-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialDialogWrapperComponent extends DialogNavigationStep {
  @HostBinding('class.ezteach-tutorial-dialog__wrapper') className = true;
  @ContentChild('skipLink') skipLink: ElementRef;
  @Input() isSkippedToLastStep = true;

  constructor(protected readonly dialogRef: MatDialogRef<TutorialDialogWrapperComponent, TypedAction<string>>) {
    super();
  }

  handleSkipClick() {
    if (this.isSkippedToLastStep) {
      this.goLastStep();
      return;
    }

    this.finishTutorial();
  }
}
