import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="6 из 6"
      [isSkippedToLastStep]="false"
      nextButtonText="Готово!"
      [isLastStep]="true"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Наши виджеты</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Настройте свою рабочую доску, как удобнее вам! Благодаря нашим умным виджетам, вы можете перетаскивать их, как
        вам удобно.
      </p>
      <img
        class="ezteach-tutorial-tooltip__image"
        [attr.src]="'/assets/img/tutorial/onboarding-student/step6.svg' | cachedImage"
      />
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step6TooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
