import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: number): string {
    const slots = this.calculateTimeslots(duration);
    const separator = ':';
    const durationText = slots.reduce(
      (txt, slot) => (txt.length === 0 ? `${`0${slot}`.slice(-2)}` : `${txt}${separator}${`0${slot}`.slice(-2)}`),
      '',
    );

    return durationText.trim();
  }

  private calculateTimeslots(duration: number) {
    const timeslots = [];

    const seconds = Math.floor(duration / 1000);
    const secondsSlot = Math.floor(seconds % 60);
    timeslots.push(secondsSlot);

    const minutes = Math.floor((seconds - secondsSlot) / 60);
    const minutesSlot = Math.floor(minutes % 60);
    timeslots.push(minutesSlot);

    return timeslots.reverse();
  }
}
