<div class="container">
  <img
    *ngIf="!isMobile"
    src="assets/img/svg/blocks/task.svg"
  />
  <div class="description">{{ 'Задание' | transloco}} {{index}}</div>
  <div class="subject">{{task.name}}</div>
  <!--<img
    *ngIf="!isMobile"
    [src]="task.iconSrc"
    class="icon"
  >-->
  <img
    *ngIf="!isMobile && isEdit"
    src="assets/img/svg/blocks/delete.svg"
    class="icon icon-act"
    (click)="deleteTask()"
  >
</div>
