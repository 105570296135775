import { Component, OnInit, ViewChild } from '@angular/core';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';

enum StepName {
  discipline,
  class,
  lesson,
}

@UntilDestroy()
@Component({
  selector: 'ezteach-advertisement-wizard',
  templateUrl: './advertisement-wizard.component.html',
  styleUrls: ['./advertisement-wizard.component.scss'],
})
export class AdvertisementWizardComponent implements OnInit {
  classes = [];
  disciplines = [];
  videos = [];
  links = [];
  selectedClass: string;
  selectedDiscipline: string;
  selectedVideo: string;
  selectedLink: string;
  disciplineSelected = false;
  classSelected = false;
  lessonSelected = false;
  stepName = StepName;

  filteredClasses = [];
  filteredVideos = [];

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private lessonsService: LessonsService) {}

  ngOnInit(): void {
    this.getCourseDate();
  }

  getCourseDate() {
    this.lessonsService
      .apiV1CourseDataGet()
      .pipe(
        untilDestroyed(this),
        tap(({ body }) => {
          this.classes = body.data.classes;
          this.disciplines = body.data.disciplines;
          this.videos = body.data.videos;
          this.links = body.data.links;
        }),
      )
      .subscribe();
  }

  updateStepperState(event: StepperSelectionEvent) {
    switch (event.selectedIndex) {
      case 0:
        this.disciplineSelected = false;
        this.classSelected = false;
        this.lessonSelected = false;
        this.selectedDiscipline = '';
        return;
      case 1:
        this.disciplineSelected = true;
        this.classSelected = false;
        this.lessonSelected = false;
        this.selectedClass = '';
        return;
      case 2:
        this.disciplineSelected = true;
        this.classSelected = true;
        this.lessonSelected = false;
        this.selectedLink = '';
    }
  }

  chooseDiscipline(e) {
    const selectedDisciplineId = e.value;
    this.filteredClasses = this.classes.filter(v =>
      this.links.find(l => l.courseClassId === v.id && l.courseDisciplineId === selectedDisciplineId),
    );
    this.disciplineSelected = true;
  }

  chooseClass(e) {
    const selectedClassId = e.value;
    this.filteredVideos = this.videos.filter(v =>
      this.links.find(
        l =>
          l.courseVideoId === v.id &&
          l.courseDisciplineId === this.selectedDiscipline &&
          l.courseClassId === selectedClassId,
      ),
    );
    this.classSelected = true;
  }

  selectLesson(e) {
    const videoId = e.option.value;
    this.selectedLink = this.videos.find(v => v.id === videoId)?.url;
    this.lessonSelected = true;
  }

  changeStepperVisibility() {
    this.lessonSelected = false;
    this.stepper.selectedIndex = 2;
  }
}
