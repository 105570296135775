import { ChatLesson, User } from '@ezteach/api/models';

///Obsolete
export function getTutorUser(lesson: ChatLesson): User {
  return lesson.members?.find(x => x.isTutor === true)?.user;
}

///Obsolete
export function getStudentUser(lesson: ChatLesson): User {
  return lesson.members?.find(x => x.isTutor === false)?.user;
}

export function getOppositeUserOrMe(me: User, lessonWithMe: ChatLesson): User {
  if (lessonWithMe.members.length === 0) {
    return me;
  }

  if (lessonWithMe.members.length === 1) {
    return me;
  }

  if (me.isStudent) {
    return getTutorUser(lessonWithMe);
  }

  return getStudentUser(lessonWithMe) ?? me;
}
