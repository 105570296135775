import { Injectable } from '@angular/core';
import {
  MouseMovedArgs,
  WhiteboardArchivedArgs,
  WhiteboardJoinedArgs,
  WhiteboardLeftArgs,
} from '@ezteach/api/models/whiteboard/whiteboard-api.model';
import { environment } from '@ezteach/enviroments';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';
import {
  ObjectCreatedArgs,
  ObjectDeletedArgs,
  ObjectLockedArgs,
  ObjectUnlockedArgs,
  ObjectUpdatedArgs,
} from 'projects/ng-konva/src/lib/whiteboard/classes/objects-args';
import { Scene } from 'projects/ng-konva/src/lib/whiteboard/classes/scene';
import { Subject } from 'rxjs';

@Injectable()
export class WhiteboardSignalrService {
  private hubConnection: HubConnection | null;
  private readonly connectionUrl = environment.apiUrl + '/hub/whiteboard?wid=';
  readonly onMouseMoved$ = new Subject<MouseMovedArgs>();
  readonly onWhiteboardLeft$ = new Subject<WhiteboardLeftArgs>();
  readonly onWhiteboardJoined$ = new Subject<WhiteboardJoinedArgs>();
  readonly onWhiteboardArchived$ = new Subject<WhiteboardArchivedArgs>();
  readonly onObjectsCreated$ = new Subject<ObjectCreatedArgs>();
  readonly onObjectsDeleted$ = new Subject<ObjectDeletedArgs>();
  readonly onObjectsLocked$ = new Subject<ObjectLockedArgs>();
  readonly onObjectsUnlocked$ = new Subject<ObjectUnlockedArgs>();
  readonly onObjectsUpdated$ = new Subject<ObjectUpdatedArgs>();
  readonly onWhiteboardData$ = new Subject<Scene>();

  constructor(private readonly authStorage: OAuthStorage) {}

  public async connect(wid: string): Promise<void> {
    if (this.hubConnection) {
      return;
    }
    await this.startConnection(wid);
    return this.addListeners();
  }

  public async closeHubConnection(): Promise<void> {
    if (this.hubConnection) {
      await this.hubConnection.stop();
      this.hubConnection = null;
    }
  }

  public myMouseMoved(data: { wid; x; y }): Promise<void> {
    return this.sendIfConnected('myMouseMoved', data);
  }

  public myObjectsCreated(data: ObjectCreatedArgs): Promise<void> {
    return this.sendIfConnected('myObjectsCreated', data);
  }

  public myObjectsDeleted(data: { oids: any[] }): Promise<void> {
    return this.sendIfConnected('myObjectsDeleted', data);
  }

  public myObjectsLocked(data: any): Promise<void> {
    return this.sendIfConnected('myObjectsLocked', data);
  }

  public myObjectsUnlocked(data: any): Promise<void> {
    return this.sendIfConnected('myObjectsUnlocked', data);
  }

  public myObjectsUpdated(data: any): Promise<void> {
    return this.sendIfConnected('myObjectsUpdated', data);
  }

  public waitForWhiteboardData(): Promise<void> {
    return this.sendIfConnected('waitForWhiteboardData');
  }

  private async sendIfConnected(name: string, data: unknown = {}): Promise<void> {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      await this.hubConnection.send(name, data);
    }
  }

  private async startConnection(wid: string): Promise<void> {
    this.hubConnection = this.getConnection(wid);
    try {
      await this.hubConnection.start();
      return await this.waitForWhiteboardData();
    } catch (err) {
      return console.log('error while establishing signalr connection: ' + err);
    }
  }
  private getConnection(wid: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl + wid, {
        accessTokenFactory: () => this.authStorage.getItem('access_token') as string,
      })
      .build();
  }

  private addListeners(): void {
    if (this.hubConnection) {
      this.hubConnection.on('onMouseMoved', data => this.onMouseMoved$.next(data));
      this.hubConnection.on('onWhiteboardJoined', data => this.onWhiteboardJoined$.next(data));
      this.hubConnection.on('onWhiteboardLeft', data => this.onWhiteboardLeft$.next(data));
      this.hubConnection.on('onWhiteboardArchived', data => this.onWhiteboardArchived$.next(data));
      this.hubConnection.on('objectsCreated', data => this.onObjectsCreated$.next(data));
      this.hubConnection.on('objectsDeleted', data => this.onObjectsDeleted$.next(data));
      this.hubConnection.on('objectsLocked', data => this.onObjectsLocked$.next(data));
      this.hubConnection.on('objectsUpdated', data => this.onObjectsUpdated$.next(data));
      this.hubConnection.on('objectsUnlocked', data => this.onObjectsUnlocked$.next(data));
      this.hubConnection.on('whiteboardData', (data: Scene) => this.onWhiteboardData$.next(data));
    }
  }
}
