import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { BooleanApiResponse } from '../models/boolean-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class BookmarksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1BookmarksTutorTogglePost
   */
  static readonly ApiV1BookmarksTutorTogglePostPath = '/api/v1/bookmarks/tutor/toggle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1BookmarksTutorTogglePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1BookmarksTutorTogglePost$Response(params?: {
    body?: { UserId?: number; TutorId?: number };
  }): Observable<StrictHttpResponse<BooleanApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, BookmarksService.ApiV1BookmarksTutorTogglePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BooleanApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1BookmarksTutorTogglePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1BookmarksTutorTogglePost(params?: {
    body?: { UserId?: number; TutorId?: number };
  }): Observable<BooleanApiResponse> {
    return this.apiV1BookmarksTutorTogglePost$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>) => r.body as BooleanApiResponse),
    );
  }
}
