import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wbDragFile]',
})
export class WbDragFileDirective {
  @Input() wbDragFile: boolean = true;
  @Output()
  onFileDrop: EventEmitter<File> = new EventEmitter<File>();

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  @HostListener('dragover', ['$event'])
  onDragOver(event: Event) {
    if (!this.wbDragFile) return;
    const transfer: DataTransfer = (<DragEvent>event).dataTransfer;
    if ((transfer.items && transfer.items.length > 1) || (<any>transfer).mozItemCount > 1) {
      transfer.dropEffect = 'none';
    }
    this.stopEvent(event);
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: Event) {
    if (!this.wbDragFile) return;
    this.stopEvent(event);
    this.renderer.addClass(this.element.nativeElement, 'ez-canvas-drag-enter');
  }

  @HostListener('drop', ['$event'])
  onDrop(event: Event): void {
    if (!this.wbDragFile) return;
    this.stopEvent(event);
    const transfer: DataTransfer = (<DragEvent>event).dataTransfer;
    const files: FileList = transfer.files;
    if (files.length) {
      this.onFileDrop.emit(files[0]);
    }
    this.renderer.removeClass(this.element.nativeElement, 'ez-canvas-drag-enter');
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: Event): void {
    if (!this.wbDragFile) return;
    this.renderer.removeClass(this.element.nativeElement, 'ez-canvas-drag-enter');
  }

  private stopEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
