import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HammerKonvaLoaderService {
  src = '/assets/scripts/hammer-konva.js';

  constructor(@Inject(DOCUMENT) private documentRef: Document) {}

  public loadHammerKonvaScript(): HTMLScriptElement {
    const script = this.documentRef.createElement('script');
    script.src = this.documentRef.location.origin + this.src;
    script.async = true;
    script.defer = true;
    this.documentRef.getElementsByTagName('head')[0].appendChild(script);
    return script;
  }
}
