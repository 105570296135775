import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateDateAdapter } from '@ezteach/shared/cdk/translat-date-adapter';
import { Langs, LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { TimeRangeComponent } from './time-range.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
  declarations: [TimeRangeComponent],
  exports: [TimeRangeComponent],
  providers: [
    DatePipe,
    {
      provide: MAT_DATE_LOCALE,
      deps: [LangStoreService],
      useFactory: (langStoreService: LangStoreService) =>
        langStoreService.getCurrentLang() === Langs.KK ? 'kk-KZ' : 'ru-RU',
    },
    TranslateDateAdapter,
  ],
})
export class TimeRangeModule {}
