import { Injectable, InjectionToken, Injector } from '@angular/core';
import { applicationLoaded } from '@ezteach/core';
import { noop } from '@ezteach/shared';
import { IClosablePopupRef } from '@ezteach/shared/models';
import { TutorialHelpersStrategy } from '@ezteach/tutorial/helpers';
import { logErrorSilently, notEquals, to } from '@ezteach/utils';
import { BrowserService } from '@ezteach/_services/browser.service';
import { Actions, concatLatestFrom, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/lib/Option';
import { defaultTo, identity, not, propOr } from 'ramda';
import { from, merge, NEVER, Observable } from 'rxjs';
import { debounceTime, filter, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { ITutorialStepConfig, ITutorialStepValidator, TutorialTypes, TutorialWithStepTuple } from '../models';
import {
  TutorialApiService,
  TutorialDialogService,
  TutorialRegistryService,
  TutorialTooltipService,
} from '../services';
import { isDialogStep, isTooltipStep } from '../utils';
import { COMMON_VALIDATOR } from '../validators';
import * as TutorialActions from './tutorial.actions';
import * as TutorialState from './tutorial.reducer';
import * as TutorialSelectors from './tutorial.selectors';

type StepWithCurrentState = Tuple<ITutorialStepConfig, TutorialState.State>;

@Injectable()
export class TutorialEffects implements OnRunEffects {
  /* Helpers to calculate tutorial navigation on e.g. next / last/ prev steps */
  private helpersStrategy = new TutorialHelpersStrategy();
  /* Effect to init load of tutorial, usually after application has bootstraped */
  requestTutorialLoad$ = createEffect(() =>
    this.actions$.pipe(ofType(applicationLoaded), mapTo(TutorialActions.loadTutorial())),
  );
  /* Effect to load tutorial through api and start it */
  loadTutorial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TutorialActions.loadTutorial),
      switchMap(() => this.api.getTutorial()),
      /* TODO: don't forget to remove */
      // mapTo({ type: TutorialTypes.ONBOARDING_STUDENT, progress: OnboardingStudentSteps.WELCOME1 }),
      map(tutorial => TutorialActions.startTutorial({ tutorial })),
      logErrorSilently(),
    ),
  );
  /* Effect to update tutorial through api */
  updateTutorial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TutorialActions.updateTutorial),
        /* TODO: don'tforget to remove */
        // switchMap(() => NEVER),
        switchMap(({ tutorial }) => this.api.updateTutorial(tutorial)),
        logErrorSilently(),
      ),
    { dispatch: false },
  );
  /* Effect to update tutorial progress through api */
  updateTutorialProgress$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TutorialActions.updateTutorialStep),
        /* TODO: don'tforget to remove */
        // switchMap(() => NEVER),
        concatLatestFrom(() => this.store.select(TutorialSelectors.selectTutorialType)),
        switchMap(([{ progress }, type]) =>
          this.api.updateTutorial({
            type,
            progress,
          }),
        ),
        logErrorSilently(),
      ),
    { dispatch: false },
  );
  /* Effect to finish current tutorial */
  finishTutorial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TutorialActions.finishTutorial),
        /* TODO: don'tforget to remove */
        // switchMap(() => NEVER),
        /* Finish tutorial */
        switchMap(() => this.api.finishTutorial()),
        logErrorSilently(),
      ),
    { dispatch: false },
  );
  /* Effect to trigger either initial tutorial or refrsh the state  */
  refreshStep$ = this.createNavigationStepsEffects(
    this.actions$.pipe(ofType(TutorialActions.startTutorial, TutorialActions.refreshTutorialStep)),
    state => this.helpersStrategy[state.type].getStepConfig(state.progress),
  );
  /* Effect to handle next step event */
  nextStep$ = this.createNavigationStepsEffects(this.actions$.pipe(ofType(TutorialActions.nextStep)), state =>
    this.helpersStrategy[state.type].nextStep(state.progress),
  );
  /* Effect to handle previous step event */
  prevStep$ = this.createNavigationStepsEffects(this.actions$.pipe(ofType(TutorialActions.previousStep)), state =>
    this.helpersStrategy[state.type].previousStep(state.progress),
  );
  /* Effect to handle last step event */
  lastStep$ = this.createNavigationStepsEffects(this.actions$.pipe(ofType(TutorialActions.lastStep)), state =>
    this.helpersStrategy[state.type].lastStep(),
  );

  constructor(
    private readonly injector: Injector,
    private readonly actions$: Actions,
    private readonly browserService: BrowserService,
    private readonly api: TutorialApiService,
    private readonly tooltipElRegistry: TutorialRegistryService,
    private readonly dialog: TutorialDialogService,
    private readonly tooltip: TutorialTooltipService,
    private readonly store: Store<{ [TutorialState.tutorialFeatureKey]: TutorialState.State }>,
  ) {}
  /* Helper method to create effect for e.g. next / last/ prev steps */
  private createNavigationStepsEffects(
    action$: Observable<ActionCreator>,
    /* Function that should return next step info */
    stepConfigGetter: (state: TutorialState.State) => ITutorialStepConfig,
  ) {
    return createEffect(() =>
      action$.pipe(
        debounceTime(100),
        /* Get current state */
        concatLatestFrom(() => this.store.select(TutorialSelectors.selectTutorialState)),
        /* Filter out Noop state */
        filter(([_, curState]) => notEquals(curState.type, TutorialTypes.NOOP)),
        /* Calculate next tep info */
        map(([_, curState]): StepWithCurrentState => [stepConfigGetter(curState), curState]),
        /* Filter state that the step is currently opened */
        filter(([stepConfig, { currentStepShown }]) =>
          pipe(
            currentStepShown,
            //@ts-ignore
            O.map(([_, openedProgress]) => notEquals(stepConfig.name, openedProgress)),
            O.getOrElse(to(true)),
          ),
        ),
        switchMap(([stepConfig, curState]) => {
          /* Get step validator to check if should skip the step */
          const validator: InjectionToken<ITutorialStepValidator> = propOr(
            COMMON_VALIDATOR,
            'withValidator',
            stepConfig,
          );
          const validationService = this.injector.get(validator);
          const shouldSkip$ = validationService.shouldSkipStep().pipe(shareReplay(1));
          /* If should not proceed then move to next step or finish */
          const proceedNext$ = shouldSkip$.pipe(
            filter(identity),
            map(() => (stepConfig.isLast ? TutorialActions.finishTutorial() : TutorialActions.nextStep())),
          );
          /* If should proceed then execute logic */
          const continueCurrent$ = shouldSkip$.pipe(
            filter(not),
            switchMap(() => {
              const stepData: TutorialWithStepTuple = [curState.type, stepConfig.name];
              /* Show step popup */
              const maybeStepTypRef = this.showStep(stepConfig, curState.type);
              /* Execute actions after step popup closes */
              const maybeAfterClosedAction$ = pipe(
                maybeStepTypRef,
                //@ts-ignore
                O.map(stepTypeRef =>
                  stepTypeRef.afterClosed().pipe(
                    switchMap(action =>
                      from([
                        /* Current step shown action */
                        TutorialActions.updateCurrentStepShown({ currentStepShown: O.none }),
                        /* Any action that comes from popup */
                        defaultTo(noop(), action),
                      ]),
                    ),
                  ),
                ),
                /* Or just ignore if there are no any popup ref */
                O.getOrElse(() => NEVER),
              );
              /* Execute actions immediatly */
              const action$ = from([
                /* Update state action */
                TutorialActions.updateTutorial({
                  tutorial: { progress: stepConfig.name, type: curState.type },
                }),
                /* Current step shown action */
                TutorialActions.updateCurrentStepShown({
                  currentStepShown: pipe(maybeStepTypRef, O.map(to(stepData))),
                }),
              ]);

              return merge(maybeAfterClosedAction$, action$);
            }),
          );

          return merge(proceedNext$, continueCurrent$);
        }),
      ),
    );
  }
  /* Helper method to trigger show step functionality, e.g. show dialog or tooltip */
  private showStep(step: ITutorialStepConfig, type: TutorialTypes): O.Option<IClosablePopupRef<TypedAction<string>>> {
    const stepData: TutorialWithStepTuple = [type, step.name];
    if (isDialogStep(step)) {
      return O.some(this.dialog.openTutorialDialog(step.component, step.componentData));
    }
    if (isTooltipStep(step)) {
      /* Get element from registry for triggering the tooltip */
      const elementRef = this.tooltipElRegistry.getElementForStepData(stepData);

      return pipe(
        elementRef,
        O.map(el => this.tooltip.showTooltipForElement(el, step.component, step.componentData)),
      );
    }

    return O.none;
  }
  /* Block effects in case unsupported browser */
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.browserService.isSupportedBrowser ? resolvedEffects$ : NEVER;
  }
}
