import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { BlocksModule } from '@ezteach/blocks/blocks.module';
import { SharedModule } from '@ezteach/shared';
import { SelectAutocompleteModule } from '@ezteach/shared/components/select-autocomplete/select-autocomplete.module';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { TutorialModule } from '@ezteach/tutorial';
import { CalendarComponent, CreateMeetingPopupComponent, OpenMeetingPopupComponent } from './components';
import { CalendarInputComponent } from './components/calendar-input/calendar-input.component';
import { SelectParcipantsModule } from './components/select-parcipants/select-parcipants.module';
import { TimeRangeModule } from './components/time-range/time-range.module';
import { EzCalendarLegendDirective } from './directives/ez-calendar-legend.directive';
import { EzCalendarTimeGridModeDirective } from './directives/ez-calendar-time-grid-mode.directive';
import { DaysRangePipe } from './pipes';

@NgModule({
  imports: [
    SharedModule,
    TutorialModule,
    RouterModule,
    FormsModule,
    TimeRangeModule,
    SelectAutocompleteModule,
    TranslocoRootModule,
    BlocksModule,
    SelectParcipantsModule,
    ButtonModule
  ],
  declarations: [
    CalendarComponent,
    CreateMeetingPopupComponent,
    OpenMeetingPopupComponent,
    DaysRangePipe,
    EzCalendarLegendDirective,
    EzCalendarTimeGridModeDirective,
    CalendarInputComponent,
  ],
  exports: [CalendarComponent, CalendarInputComponent],
})
export class CalendarModule { }
