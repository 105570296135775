import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ezteach-bank-card-warning-dialog',
  templateUrl: './bank-card-warning-dialog.component.html',
  styleUrls: ['./bank-card-warning-dialog.component.scss'],
})
export class BankCardWarningDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<BankCardWarningDialogComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close('close');
  }

  add() {
    this.dialogRef.close('close');
  }
}
