import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';
import { OnboardingStudentSteps } from './onboarding-student.models';
import { OnboardingTeacherSteps } from './onboarding-teacher.models';
import { ProfileSteps } from './profile.models';
import { ITutorialStepValidator } from './validator.model';

export enum TutorialTypes {
  ONBOARDING_TEACHER = 'ONBOARDING-TEACHER',
  ONBOARDING_STUDENT = 'ONBOARDING-STUDENT',
  PROFILE = 'PROFILE',
  NOOP = 'NOOP',
}

export enum StepType {
  DIALOG = 'DIALOG',
  TOOLTIP = 'TOOLTIP',
  NOOP = 'NOOP',
}

export interface ITutorialStepConfig<T extends TutorialSteps = TutorialSteps, D = any> {
  readonly type: StepType;
  readonly name: T;
  readonly component: ComponentType<any>;
  readonly componentData?: D;
  readonly withValidator?: InjectionToken<ITutorialStepValidator>;
  readonly isLast?: boolean;
}

export enum DefaultSteps {
  NOOP = 'NOOP',
}

export type TutorialSteps = OnboardingStudentSteps | OnboardingTeacherSteps | ProfileSteps | DefaultSteps;
export type TutorialWithStepTuple = [TutorialTypes, TutorialSteps];
