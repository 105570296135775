import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class YookassaLoaderService {
  src = 'https://static.yoomoney.ru/payouts-data-widget-front/widget.js';
  checkout = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';

  constructor() {}

  public renderYookassaScript(): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = this.src;
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return script;
  }

  public renderСheckoutScript(): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = this.checkout;
    script.async = true;
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    return script;
  }
}
