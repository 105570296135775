import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { Publisher, StreamManager } from 'openvidu-browser';
import { BehaviorSubject } from 'rxjs';

export class ChatLessonMemberClient {
  private _stream?: StreamManager | Publisher = null;
  private readonly _member?: ChatLessonMember;
  streamAdded$ = new BehaviorSubject<StreamManager | Publisher>(null);

  constructor(member: ChatLessonMember, stream?: StreamManager | Publisher) {
    this._member = member;
    this._stream = stream;
  }

  public setStream(stream: StreamManager | Publisher) {
    this._stream = stream;
    this.streamAdded$.next(stream);
  }

  public get stream(): StreamManager | Publisher | null {
    return this._stream;
  }

  public get member(): ChatLessonMember {
    return this._member;
  }

  public removeStream() {
    this._stream = null;
  }
}
