import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { TranslocoService } from '@ngneat/transloco';

export enum DialogMediaStateResult {
  confirm,
  dismiss,
}

@Component({
  selector: 'modal-request-media-state-change',
  templateUrl: 'modal-request-media-state-change.html',
  styleUrls: ['modal-request-media-state-change.scss'],
})
export class ModalRequestMediaStateChange {
  deviceType: string;
  requestStateMessage: string;
  dismissMessage = 'Позже';
  buttonType = ButtonType;
  buttonWidth = '194px';
  notificationMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalRequestMediaStateChange>,
    private translocoService: TranslocoService,
  ) {
    this.requestStateMessage = data.requestState ? 'включить' : 'выключить';
    this.setNotificationMessage();
  }

  private setDeviceType(deviceType: ChatLessonMemberPublishingStateEnum) {
    this.deviceType = deviceType === ChatLessonMemberPublishingStateEnum.Video ? 'камеру' : 'микрофон';
  }

  private setNotificationMessage() {
    this.setDeviceType(this.data.deviceType);
    this.notificationMessage =
      `${this.data.ownerName} ${this.translocoService.translate(`просит Вас ${this.requestStateMessage} ${this.deviceType}`)}`;
  }

  changeMediaState() {
    this.dialogRef.close(DialogMediaStateResult.confirm);
  }

  dismissRequest() {
    this.dialogRef.close(DialogMediaStateResult.dismiss);
  }
}
