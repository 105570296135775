<div class="container">
  <button
    class="close-btn"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
  <div class="notification">{{notificationMessage}}</div>
  <hr />
  <div class="buttons">
    <ezteach-btn
      [type]="buttonType.filled"
      [width]="buttonWidth"
      (click)="close()"
    >
      <div content>{{buttonMessage | transloco}}</div>
    </ezteach-btn>
  </div>
</div>
