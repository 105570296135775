import { isLeft } from 'fp-ts/lib/Either';
import * as T from 'io-ts';
import reporter from 'io-ts-reporters';
import { identity } from 'ramda';
import { delay } from './other.utils';

export function ValidateComponentInput<K = any>(decoder: T.Type<K>) {
  return (target: Object, propertyKey: string) => {
    const _store = new WeakMap<object, T.Validation<K>>();
    const { set: currentSetter = identity, get: currentGetter = identity } =
      Object.getOwnPropertyDescriptor(target, propertyKey) || {};
    const getter = function (this) {
      return _store.get(this);
    };
    const setter = function (this, newVal: any) {
      currentSetter(newVal);
      const decodedValue = decoder.decode(currentGetter(newVal));
      if (isLeft(decodedValue)) {
        console.error(`[${this.constructor.name}.${propertyKey}]: ${reporter.report(decodedValue).join('\n')}`);
      }
      _store.set(this, decodedValue);
    };
    Object.defineProperty(target, propertyKey, {
      get: getter,
      set: setter,
      configurable: true,
    });
  };
}

export function Delay(param: number) {
  return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    const oldFunc = descriptor.value;
    descriptor.value = async function (...args) {
      await delay(param);
      const result = await oldFunc.apply(this, args);
      return result;
    };
  };
}
