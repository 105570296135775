<ezteach-popup-with-bg
  *ngIf="!isCameraAvailable"
  [smiley]="smileyType.sad"
>
  <div
    title
    i18n
  >{{ 'Для того чтобы использовать камеру, её необходимо включить' | transloco }} </div>
  <div
    content
    i18n
  >
    {{ ' Возможно, камера выключена. Для того чтобы использовать камеру, её нужно включить. Вы так же можете
    отказаться.' | transloco }}
  </div>
  <div buttons>
    <ezteach-btn
      (click)="close()"
      [type]="buttonType.warnNotFilled"
    >
      <div
        content
        i18n
      >{{ 'Закрыть' | transloco }} </div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>