<div class="modal__header"></div>
<div class="modal__content modal__content--icon">
  <div class="modal__title">{{ 'Удалить карту' | transloco }}?</div>
  <div class="modal__actions">
    <a
      class="btn btn--red btn--middle"
      (click)="removeBankcard(data.id)"
    >
      <span class="btn__label">{{ 'Удалить' | transloco }}</span>
    </a>
    <a
      class="btn btn--blue btn--middle"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Отменить' | transloco }}</span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>