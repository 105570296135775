import { ITutorialStepConfig, TutorialSteps } from '@ezteach/tutorial/models';
import { lengthEquals } from '@ezteach/utils';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { findIndex, head, last, propEq } from 'ramda';

const fromArrIndex = indexOfCurStep => (indexOfCurStep < 0 ? O.none : O.some(indexOfCurStep));

/* Helper class that is responsible to calculate next tutorial step state */
export abstract class Stepper {
  lastStep = (): ITutorialStepConfig => last(this._steps);
  firstStep = (): ITutorialStepConfig => head(this._steps);

  constructor(private _steps: readonly ITutorialStepConfig[]) {
    if (lengthEquals(0, _steps)) {
      throw new Error('[Tutorial Stepper]: Steps array must not be empty');
    }
  }

  getStepConfig(curStep: TutorialSteps): ITutorialStepConfig {
    return pipe(
      this._steps,
      findIndex(propEq('name', curStep)),
      fromArrIndex,
      O.map(indexOfCurStep => O.fromNullable(this._steps[indexOfCurStep])),
      O.flatten,
      O.getOrElse(this.firstStep),
    );
  }

  nextStep(curStep: TutorialSteps): ITutorialStepConfig {
    return pipe(
      this._steps,
      findIndex(propEq('name', curStep)),
      fromArrIndex,
      O.map(indexOfCurStep => O.fromNullable(this._steps[indexOfCurStep + 1])),
      O.flatten,
      O.getOrElse(this.lastStep),
    );
  }

  previousStep(curStep: TutorialSteps): ITutorialStepConfig {
    return pipe(
      this._steps,
      findIndex(propEq('name', curStep)),
      fromArrIndex,
      O.map(indexOfCurStep => O.fromNullable(this._steps[indexOfCurStep - 1])),
      O.flatten,
      O.getOrElse(this.firstStep),
    );
  }
}
