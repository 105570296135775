<div
  class="ez-text-shape-tools-wrapper"
>
  <div class="ez-text-shape-tools">
    <mat-select
      [formControl]="fontFamilyControl"
      [compareWith]="compareBy"
      matTooltip="Шрифт"
      onmousedown="event.preventDefault();"
    >
      <mat-option
        *ngFor="let option of fontFamilies"
        onmousedown="event.preventDefault();"
        [value]="option"
      >{{ option }}</mat-option>
    </mat-select>

    <mat-select
      [formControl]="fontSizeControl"
      [compareWith]="compareById"
      matTooltip="Размер"
      onmousedown="event.preventDefault();"
    >
      <mat-option
        *ngFor="let option of fontSizes"
        onmousedown="event.preventDefault();"
        [value]="option"
        [ngStyle]="{ fontSize: option.size }"
      >{{ option.name }}</mat-option>
    </mat-select>

    <mat-button-toggle-group
      [multiple]="true"
      name="fontStyle"
      onmousedown="event.preventDefault();"
    >
      <mat-button-toggle
        (click)="formatDoc('bold')"
        [checked]="isFormat('bold')"
        matTooltip="Жирный"
        value="bold"
      >
        <mat-icon>format_bold</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="formatDoc('italic')"
        [checked]="isFormat('italic')"
        matTooltip="Курсив"
        value="italic"
      >
        <mat-icon>format_italic</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="formatDoc('underline')"
        [checked]="isFormat('underline')"
        matTooltip="Подчеркивание"
        value="underline"
      >
        <mat-icon>format_underline</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-button-toggle-group
      name="textAlign"
      onmousedown="event.preventDefault();"
    >
      <mat-button-toggle
        (click)="formatDoc('justifyleft')"
        [checked]="isFormat('justifyleft')"
        matTooltip="Выравнивание слева"
        value="left"
      >
        <mat-icon>format_align_left</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="formatDoc('justifycenter')"
        [checked]="isFormat('justifycenter')"
        matTooltip="Выравнивание по центру"
        value="center"
      >
        <mat-icon>format_align_center</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="formatDoc('justifyright')"
        [checked]="isFormat('justifyright')"
        matTooltip="Выравнивание справа"
        value="right"
      >
        <mat-icon>format_align_right</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="formatDoc('justifyfull')"
        [checked]="isFormat('justifyfull')"
        matTooltip="Выравнивание по ширине"
        value="stretch"
      >
        <mat-icon>format_align_justify</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>
</div>