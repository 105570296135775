import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ezteach-notifications-not-found',
  templateUrl: './notifications-not-found.component.html',
  styleUrls: ['./notifications-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsNotFoundComponent {
  @Input() title: string;
  @Input() content: string;
}
