import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfile from './profile.reducer';
import { ProfileState } from './profile.reducer';

export const selectProfileState = createFeatureSelector<fromProfile.ProfileState>(fromProfile.profileFeatureKey);

export const selectProfile = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile,
);

export const isProfileSaving = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state?.isSaving,
);

export const isFileUploading = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.isFileUploading,
);

export const selectPersonalDetails = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.personalDetails,
);

export const selectEducationDetails = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.educationDetails,
);

export const selectFinancialDetails = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.financialDetails,
);

export const selectPassportDetails = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.passportDetails,
);

export const selectPassportDetailsFiles = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.passportDetails?.documentFiles,
);

export const selectSpecialityDetails = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.specialityDetails,
);

export const selectWorkExperience = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.workExperience,
);

export const selectReferenceLetters = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.referenceLetters,
);

export const selectStudentPatron = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.studentPatron,
);

export const selectBankCardBindings = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.profile?.bankCardBindings,
);

export const selectPersonalTitle = createSelector<object, fromProfile.ProfileState, any>(
  selectProfileState,
  (state: ProfileState) => state.title,
);
