import { EventEmitter, Injectable, Output } from '@angular/core';
import { LessonStatusChangedArg } from '@ezteach/api/models/waitroom/lesson-status-changed-arg';
import { environment } from '@ezteach/enviroments';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonWaitSignalrService {
  private hubConnection: HubConnection;
  private connectionUrl = environment.apiUrl + '/hubs/lesson/wait';
  joinIdentity: number;

  @Output()
  onLessonStatusChanged = new EventEmitter<LessonStatusChangedArg>();
  onLessonCanceled = new EventEmitter<Boolean>();

  constructor(private authStorage: OAuthStorage) {}

  public connect = joinIdentity => {
    if (this.hubConnection) {
      return;
    }
    this.joinIdentity = joinIdentity;

    this.startConnection();
    this.addListeners();
  };

  public closeHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        this.hubConnection = null;
      });
    }
  }

  private startConnection() {
    this.hubConnection = this.getConnection();
    this.hubConnection.start().catch(err => console.log('error while establishing signalr connection: ' + err));
  }
  private getConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl + '?joinIdentity=' + this.joinIdentity, {
        accessTokenFactory: () => {
          const token = this.authStorage.getItem('access_token');
          return token;
        },
      })
      .withAutomaticReconnect()
      .build();
  }

  private addListeners() {
    // изменён статус урока
    this.hubConnection.on('onLessonStatusChanged', (data: LessonStatusChangedArg) => {
      this.onLessonStatusChanged.emit(data);
    });

    this.hubConnection.on('onLessonCanceled', data => {
      this.onLessonCanceled.emit(data);
    });
  }
}
