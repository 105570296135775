import Konva from 'konva';

const rotatePoint = ({ x, y }, rad) => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
  };
  
// Поворот квадратных фигур вокруг центра
export function rotateAroundCenter(node, rotation) {
    const konva = Konva as any;
    const topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
    const current = rotatePoint(topLeft, konva.getAngle(node.rotation()));
    const rotated = rotatePoint(topLeft, konva.getAngle(rotation));
    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;
    console.log(22222);
    node.rotation(rotation);
    node.x(node.x() + dx);
    node.y(node.y() + dy);
}

/*
*
*/
export function getBaseRectXY(node) {
  const konva = Konva as any;
  const topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
  const current = rotatePoint(topLeft, konva.getAngle(node.rotation()));
  const rotated = rotatePoint(topLeft, konva.getAngle(0));
  const dx = rotated.x - current.x;
  const dy = rotated.y - current.y;
  return { dx: dx, dy: dy };
}