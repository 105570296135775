<ezteach-popup-with-bg
  [smiley]="smileyType.none"
  [hasCloseBtn]="true"
  (onCloseBtnClick)="close()"
  [isDefaultWidth]="true"
>
  <div title>{{ 'Доступ к камере' | transloco }} </div>
  <div content>
    {{ 'Предоставьте "Легко Учиться" доступ к камере и микрофону, чтобы собеседники смогли вас видеть и слышать' |
    transloco }}.
  </div>
  <div buttons>
    <ezteach-btn
      (click)="close()"
      [type]="buttonType.warnFilled"
    >
      <div content>{{ 'Закрыть' | transloco }} </div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>