import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { buffer, debounceTime, map } from 'rxjs/operators';

/*
 * Директива которая собирает быстрые клики в 1.
 */
@Directive({
  selector: '[ezteachAntiQuickClick]',
})
export class AntiQuickClickDirective {
  private click$ = new Subject<MouseEvent>();
  @Input()
  debounceValue = 250;
  @Output()
  singleClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(event) {
    this.click$.next(event);
  }

  ngOnInit() {
    this.click$
      .pipe(
        buffer(this.click$.pipe(debounceTime(this.debounceValue))),
        map(list => list[1]),
      )
      .subscribe(this.singleClick);
  }

  ngOnDestroy() {
    this.click$.complete();
  }
}
