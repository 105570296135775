import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ezteach-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  disabled: boolean;
  @HostBinding('class.ezteach-button') className = true;

  @HostBinding('style.background-color')
  @Input()
  bgColor = '#678AFF';

  @HostBinding('style.color')
  @Input()
  textColor = '#FFFFFF';

  @HostBinding('style.min-width')
  @Input()
  minWidth = 'none';
}
