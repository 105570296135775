import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ImagePreviewData, UploadedFile } from '../classes/objects-args';
import { ImageShapeMeta } from '../classes/white-board';

@Injectable()
export class WhiteBoardFileService {
  addFile$ = new Subject<File>();
  fileUploading$ = new Subject<boolean>();
  fileUploaded$ = new Subject<UploadedFile>();
  download$ = new Subject<string>();
  loadPreview$ = new Subject<ImageShapeMeta>();
  previewLoaded$ = new Subject<ImagePreviewData>();
}
