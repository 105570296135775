import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LessonsService } from '../../api/services/lessons.service';

@Component({
  selector: 'modal-instant-request',
  templateUrl: 'modal-instant-request.component.html',
})
export class ModalInstantRequest {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalInstantRequest>,
    private lessonsService: LessonsService,
  ) {}

  cancel(id) {
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdCancelPatch({
        requestId: id,
      })
      .subscribe(response => {});
    this.dialogRef.close('cancel');
  }

  close() {
    this.dialogRef.close('close');
  }
}
