<div class="container">
  <ng-container *ngIf="selectedBlockService.selectedBlock$ | async as selectedBlock; else list">
    <ezteach-block (onAddLesson)="addLesson($event)"></ezteach-block>
  </ng-container>

  <ng-template #list>
    <ng-container *ngFor="let item of items">
      <ezteach-block-item [item]="item"></ezteach-block-item>
    </ng-container>
  </ng-template>

</div>