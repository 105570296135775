export function delay(ms: number) {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function getDataUrlFromImage(img: HTMLImageElement): string {
  // Create canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // Set width and height
  canvas.width = img.width;
  canvas.height = img.height;
  // Draw the image
  ctx.drawImage(img, 0, 0);
  return canvas.toDataURL('image/png');
}
