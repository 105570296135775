import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { HostListener, HostBinding, Host, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[appFileSelect]',
})
export class AppFileSelectDirective {
  private _multiple = false;

  @Input()
  set multiple(multiple: boolean) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  @Output() fileSelect: EventEmitter<FileList | File> = new EventEmitter<FileList | File>();
  @HostBinding('attr.multiple')
  get multipleBinding(): string {
    return this._multiple ? '' : undefined;
  }
  constructor(@Optional() @Host() private model: NgModel) {}

  @HostListener('change', ['$event'])
  onChange(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      const fileInputEl: HTMLInputElement = <HTMLInputElement>event.target;
      const files: FileList = fileInputEl.files;
      if (files.length) {
        const value: FileList | File = this._multiple ? (files.length > 1 ? files : files[0]) : files[0];
        this.model ? this.model.update.emit(value) : this.fileSelect.emit(value);
      }
    }
  }
}
