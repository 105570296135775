<div class="container">
  <div class="container__sidebar">
    <div class="container__title">{{ 'Настройки' | transloco }}</div>

    <div class="tabs">
      <div
        class="container__sidebar-item"
        (click)="activateTab(settingsTabType.sound)"
      >
        <div
          *ngIf="activeTab === settingsTabType.sound"
          class="container__sidebar-item-active-link"
        ></div>
        <img
          class="container__sidebar-item-img"
          [ngClass]="{ active: activeTab === settingsTabType.sound }"
          [style.display]="activeTab === settingsTabType.sound ? 'none' : ''"
          src="/assets/img/svg/settings-sound-off.svg"
          alt="settings-sound-off"
        />
        <img
          class="container__sidebar-item-img"
          [ngClass]="{ active: activeTab === settingsTabType.sound }"
          [style.display]="activeTab !== settingsTabType.sound ? 'none' : ''"
          src="/assets/img/svg/settings-sound-on.svg"
          alt="settings-sound-on"
        />
        <span
          class="container__sidebar-item-name"
          [ngClass]="{ active: activeTab === settingsTabType.sound }"
        >{{ 'Звук' | transloco }} </span>
      </div>
      <div
        class="container__sidebar-item"
        (click)="activateTab(settingsTabType.camera)"
      >
        <div
          *ngIf="activeTab === settingsTabType.camera"
          class="container__sidebar-item-active-link"
        ></div>
        <img
          class="container__sidebar-item-img"
          [ngClass]="{ active: activeTab === settingsTabType.camera }"
          [style.display]="activeTab === settingsTabType.camera ? 'none' : ''"
          src="/assets/img/svg/settings-camera-off.svg"
          alt="settings-camera-off"
        />
        <img
          class="container__sidebar-item-img"
          [ngClass]="{ active: activeTab === settingsTabType.camera }"
          [style.display]="activeTab !== settingsTabType.camera ? 'none' : ''"
          src="/assets/img/svg/settings-camera-on.svg"
          alt="settings-camera-on"
        />
        <span
          class="container__sidebar-item-name"
          [ngClass]="{ active: activeTab === settingsTabType.camera }"
        >{{ 'Видео' | transloco }} </span>
      </div>
    </div>

  </div>
  <div class="container__divider"></div>

  <div class="container__content">
    <div class="container__title">{{ activeTab | transloco }}</div>
    <div class="container__content-block">
      <ng-container *ngIf="activeTab === settingsTabType.sound">
        <ng-template *ngTemplateOutlet="soundSettings"></ng-template>
      </ng-container>
      <ng-container *ngIf="activeTab === settingsTabType.camera">
        <ng-template *ngTemplateOutlet="cameraSettings"></ng-template>
      </ng-container>
    </div>
  </div>
  <button
    class="container__close-btn"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>

<ng-template #soundSettings>
  <div class="dropdown__select-device-type">
    <div class="dropdown__select-device-type-title"> {{ 'Укажите Микрофон' | transloco }} </div>
    <mat-form-field
      appearance="outline"
      class="dropdown__select-device-type-control"
    >
      <mat-select
        [(value)]="selectedMicrophone"
        (selectionChange)="onSelectMicrophone($event.value)"
        [disabled]="!allDeviceIsLoaded"
      >
        <mat-option
          *ngFor="let microphone of microphones"
          [value]="microphone.deviceId"
        >{{ microphone.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="dropdown__select-device-type">
    <div class="dropdown__select-device-type-title">{{ 'Укажите Динамики' | transloco}}</div>
    <mat-form-field
      appearance="outline"
      class="dropdown__select-device-type-control"
    >
      <mat-select
        [(value)]="selectedAudioOutput"
        (selectionChange)="onSelectAudioOutput($event.value)"
        [disabled]="!allDeviceIsLoaded"
      >
        <mat-option
          *ngFor="let audioOutput of audioOutputs"
          [value]="audioOutput.deviceId"
        >{{ audioOutput.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #cameraSettings>
  <div class="dropdown__select-device-type">
    <div class="dropdown__select-device-type-title"> {{ 'Укажите Камеру' | transloco }}</div>
    <mat-form-field
      appearance="outline"
      class="dropdown__select-device-type-control"
    >
      <mat-select
        [(value)]="selectedCamera"
        (selectionChange)="onSelectCamera($event.value)"
        [disabled]="!allDeviceIsLoaded"
      >
        <mat-option
          *ngFor="let camera of cameras"
          [value]="camera.deviceId"
        >{{ camera.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>