import { Component, Input, OnInit } from '@angular/core';
import { ChatLessonMemberReactionArgs } from '@ezteach/api/models/lesson/chat-lesson-reaction';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
//import { ModerateUser } from '@ezteach/group-lesson/components/group-lesson-participants-list/group-lesson-participants-list.component';
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-participants-reactions',
  templateUrl: './group-lesson-participants-reactions.component.html',
  styleUrls: ['./group-lesson-participants-reactions.component.scss'],
})
export class GroupLessonParticipantsReactionsComponent implements OnInit {
  @Input()
  user: any;
  @Input()
  isOwner: boolean;
  @Input()
  lessonId: number;
  reactions: ChatLessonMemberReactionArgs[] = [];
  constructor(public groupLessonReactionService: GroupLessonReactionService) { }

  ngOnInit() {
    // console.log('user ', this.user);
    this.groupLessonReactionService.reactions$
      .pipe(
        untilDestroyed(this),
        tap((x: ChatLessonMemberReactionArgs[]) => {
          this.reactions = x;
          // console.log('this.reactions ', this.reactions)
        }),
      )
      .subscribe();
  }

  @tuiPure
  handOn(reactions: any, userId: number) {
    const reaction =
      reactions.find(x => x.byUserId === userId && x.type === ReactionTypeEnum.RaiseHand && x.show) !== undefined;
    return reaction;
  }
}
