import { ElementRef, Injectable, Optional, SkipSelf } from '@angular/core';
import { pipe } from 'fp-ts/lib/function';
import { flatten, fromNullable, map, Option } from 'fp-ts/Option';
import { TutorialWithStepTuple } from '../models';

const stringFromTuple = ([tutorial, step]: TutorialWithStepTuple): StringFromStepData => `${tutorial}__${step}`;
type StringFromStepData = string;
type ElementIdentificator = string;

/* Helper elements+data registry service, used for assoc element with tutorial info */
@Injectable({ providedIn: 'root' })
export class TutorialRegistryService {
  private _elementsRegistry = new Map<ElementIdentificator, ElementRef>();
  private _elementsNamesRegistry = new Map<StringFromStepData, ElementIdentificator>();

  constructor(
    @Optional()
    @SkipSelf()
    duplicate: TutorialRegistryService,
  ) {
    if (duplicate) {
      throw new Error('There should be just 1 root instance of TutorialElementsRegistryService in application');
    }
  }

  registerElementNameForStepData(data: TutorialWithStepTuple, elementName: ElementIdentificator) {
    const dataName = stringFromTuple(data);
    const curElemName = this._elementsNamesRegistry.get(dataName);
    if (curElemName) {
      console.warn(`There is already element ${curElemName} for tutorial data ${dataName}`);
    }
    this._elementsNamesRegistry.set(dataName, elementName);
  }

  unregisterElementNameForStepData(data: TutorialWithStepTuple) {
    this._elementsNamesRegistry.delete(stringFromTuple(data));
  }

  registerElementWithName(elementName: ElementIdentificator, element: ElementRef) {
    this._elementsRegistry.set(elementName, element);
  }

  unregisterElementWithName(elementName: ElementIdentificator) {
    this._elementsRegistry.delete(elementName);
  }

  getElementIdForStepDAta(data: TutorialWithStepTuple): Option<ElementIdentificator> {
    const idFromData = stringFromTuple(data);

    return fromNullable(this._elementsNamesRegistry.get(idFromData));
  }

  getElementForStepData(data: TutorialWithStepTuple): Option<ElementRef> {
    const idFromData = stringFromTuple(data);

    return pipe(
      this._elementsNamesRegistry.get(idFromData),
      fromNullable,
      map(name => this._elementsRegistry.get(name)),
      map(fromNullable),
      flatten,
    );
  }
}
