import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankCardWarningDialogComponent } from '@ezteach/modals/bank-card-warning-dialog/bank-card-warning-dialog.component';
import { IProfileData } from '@ezteach/profile/models';
import { ProfileApiService } from '@ezteach/profile/services/profile-api.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, interval, NEVER, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { TutorAvailability } from '../api/models';
import { UsersService } from '../api/services/users.service';
import { UserService } from "@ezteach/_services/user.service";

interface ISelection {
  value: number;
  name: string;
  selected: boolean;
}
const ONE_SECOND = 1000;

@UntilDestroy()
@Component({
  selector: 'online',
  templateUrl: './online.component.html',
  styleUrls: ['online-local.component.scss'],
})
export class OnlineComponent implements OnInit {
  userProfile: IProfileData;
  available: TutorAvailability;
  isAvailableControl = new UntypedFormControl(false);
  get isAvailable(): boolean {
    return this.isAvailableControl.value;
  }
  countdown: string;
  countdown$: Observable<number>;
  time = 0;
  minutesControl = new UntypedFormControl(60);
  get minutes() {
    return this.minutesControl.value;
  }

  dialogRef: MatDialogRef<any>;

  lessonDurations: number[] = [];
  durationControl = new UntypedFormControl();
  durationGroup: UntypedFormGroup;
  selectedDurations: [number];
  isOpen = false;
  isMobile: boolean;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  //fix: уже было.
  durationList: ISelection[] = [
    { value: 5, name: `5 минут`, selected: false },
    { value: 10, name: `10 минут`, selected: false },
    { value: 30, name: `30 минут`, selected: false },
  ];

  turnOffList: ISelection[] = [
    { value: 60, name: `1 час`, selected: false },
    { value: 30, name: `30 минут`, selected: false },
    { value: 1, name: `1 минуту`, selected: false },
  ];

  positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 13),
  ];

  constructor(
    private usersService: UsersService,
    private readonly profileApi: ProfileApiService,
    private readonly fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.isOpen = this.isAvailable;
    this.getResults();
    this.durationGroup = new UntypedFormGroup({
      durations: this.createDuration(),
    });
    this.isAvailableControl.disable();

    const userdata = this.userService.userData$.value;
    this.profileApi
      .getProfile()
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.userProfile = x;
          this.isAvailableControl.enable();
          this.setupCountdown();
        }),
      )
      .subscribe();

    const durationUpdate$ = this.durationGroup.valueChanges.pipe(
      /* TODO: figure out why needs to check durationList */
      map(({ durations }) =>
        durations.map((duration, i) => duration.value && this.durationList[i].value).filter(value => !!value),
      ),
      switchMap(InstantLessonDurations =>
        this.usersService.apiV1UsersUserIdAvailabilityPut({
          userId: userdata.id,
          body: {
            IsAvailable: this.isAvailable,
            DurationMinutes: this.minutes,
            InstantLessonDurations,
          },
        }),
      ),
    );
    this.usersService
      .apiV1UsersUserIdAvailabilityGet({
        userId: userdata.id,
      })
      .pipe(
        untilDestroyed(this),
        tap(response => this.isAvailableControl.setValue(response.data.isAvailable)),
        first(),
      )
      .subscribe();

    durationUpdate$.pipe(untilDestroyed(this)).subscribe();

    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
    });
  }

  private setupCountdown() {
    const oneSecond$ = interval(ONE_SECOND);
    const onlineDuration$ = this.minutesControl.valueChanges.pipe(
      startWith(this.minutesControl.value),
      distinctUntilChanged(),
    );

    this.countdown$ = combineLatest([onlineDuration$]).pipe(
      switchMap(() => {
        const isAvailable = this.isAvailable;
        const duration = this.minutes;
        if (!isAvailable) {
          return NEVER;
        }

        const finish = duration * 60 * 1000; // ms
        return oneSecond$.pipe(
          startWith(0),
          scan((acc, _) => acc - 1000, finish), // how much miliseconds left
          map(n => Math.max(n, 0)), // check duration not goes below 0
        );
      }),
      tap(n => {
        if (n <= 0) {
          this.isAvailableControl.setValue(false);
        }
      }),
    );
  }

  createDuration(): UntypedFormArray {
    const arr = this.durationList.map(duration => this.fb.control(duration.selected));

    return this.fb.array(arr);
  }

  getResults() {
    if (this.durationGroup) {
      this.durationGroup.controls.durations.value.forEach((item, i) => {
        if (this.available.instantLessonDurations.includes(this.durationList[i].value)) {
          this.durationGroup.controls.durations.value[i].setValue(true);
        } else {
          this.durationGroup.controls.durations.value[i].setValue(false);
        }
      });
    }
  }

  showBankCardWarningDialog(isAvailable: boolean) {
    if (isAvailable) {
      this.dialogRef = this.dialog.open(BankCardWarningDialogComponent, {
        panelClass: 'modal-reclamation',
      });
      this.dialogRef.afterClosed().subscribe(x => {
        this.isAvailableControl.patchValue(false, { emitEvent: false });
      });
    }
  }

  openSettings(){
    this.isOpen = !this.isOpen;
  }

  closeSettings() {
    this.isOpen = false;
  }

  updateIsAvailableState(enabled: boolean) {
    const available = enabled;
    this.isOpen = available;
    this.isAvailableControl.setValue(available);

    const userId = this.userService.userData$.value.id;

    this.usersService
      .apiV1UsersUserIdAvailabilityPut({
        userId,
        body: {
          IsAvailable: this.isAvailable,
          DurationMinutes: this.minutes,
          InstantLessonDurations: this.lessonDurations,
        },
      })
      .pipe(untilDestroyed(this), first())
      .subscribe();
  }
}
