import { Component, OnInit } from '@angular/core';

interface NavItem {
  id: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'ezteach-finance-general',
  templateUrl: './finance-general.component.html',
  styleUrls: ['./finance-general.component.scss'],
})
export class FinanceGeneralComponent implements OnInit {
  navItems: NavItem[] = [
    {
      id: 0,
      name: $localize`Мои финансы`,
      isActive: true,
    },
    {
      id: 1,
      name: $localize`История переводов`,
      isActive: false,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  activateNavItem(navItem: NavItem) {
    this.navItems = this.navItems.map(x => {
      if (x.id === navItem.id) {
        x.isActive = true;
      } else {
        x.isActive = false;
      }
      return x;
    });
  }
}
