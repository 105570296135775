<div
  class="finance"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
>
  <div
    class="widget"
    *ngFor="let widget of widgets"
    cdkDrag
  >
    <div
      class="widget-drag"
      cdkDragHandle
    >
      <svg-icon-sprite
        [src]="'icon-drag'"
        [width]="'8px'"
        [height]="'13px'"
        [viewBox]="'0 0 8 13'"
      ></svg-icon-sprite>
    </div>
    <div
      class="widget-placeholder"
      *cdkDragPlaceholder
    ></div>
    <ezteach-widget-finance *ngIf="widget == 1"></ezteach-widget-finance>
    <ezteach-finance-history *ngIf="widget == 2"></ezteach-finance-history>
  </div>
</div>

<!--
<div class="results_none">
	<div class="global__icon">
		<svg-icon-sprite [src]="'icon-smile-bad'" [width]="'78px'" [height]="'78px'" [viewBox]="'0 0 78 78'"></svg-icon-sprite>
	</div>
	<div class="global__title">У вас пока нет истории переводов</div>
</div>
-->
