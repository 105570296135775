import {Component, Input } from '@angular/core';
import {SortDirection} from '@ezteach/shared/components/sort-arrows/sort-direction';

@Component({
  selector: 'ezteach-sort-arrows',
  templateUrl: './sort-arrows.component.html',
  styleUrls: ['./sort-arrows.component.scss']
})
export class SortArrowsComponent {
  public SortDirection = SortDirection;

  @Input() sortColumn: string;
  @Input() sortDirection: SortDirection;
  @Input() columnProperty: string;
  @Input() disabled = false;
}
