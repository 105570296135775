import { HttpStatusCode } from '@ezteach/api/models';
import { EmbeddedFileTypeEnum } from 'projects/ng-konva/src/lib/whiteboard/classes/objects-args';

export interface WhiteboardConfiguration {
  anotherUsersPermission: WhiteboardMemberAccessRightsEnum;
  snapping: WhiteboardSnappingEnum[];
}

export enum WhiteboardMemberAccessRightsEnum {
  ReadWrite = 'ReadWrite',
  Readonly = 'Readonly',
}

export enum WhiteboardSnappingEnum {
  Grid,
  AnotherObject,
}

export interface WhiteboardMember {
  wId: string;
  userId: number;
  isOwner: boolean;
  accessRights: string;
}

export interface WhiteboardDto {
  wId: string;
  title: string;
  isArchived: boolean;
  creationDate: Date;
  configuration: WhiteboardConfiguration;
  members: WhiteboardMember[];
}

export interface WhiteboardApiResponse {
  data?: WhiteboardDto;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}

export interface WhiteboardsApiResponse {
  data?: WhiteboardApiPagedDto;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}

export interface WhiteboardApiPagedDto {
  currentPage: number;
  records: WhiteboardDto[];
  totalPages: number;
  totalRecords: number;
}

export interface WhiteboardJoinedArgs {
  wId: string;
  member: WhiteboardMember;
}

export interface WhiteboardArchivedArgs {
  wid: string;
}

export interface WhiteboardChangedArgs {
  wId: string;
  field: string;
  value: string;
}

export interface WhiteboardLeftArgs {
  wId: string;
  member: WhiteboardMember;
}

export interface MouseMovedArgs {
  wId: string;
  memberId: number;
  x: number;
  y: number;
}


export interface UploadedFile {
  fileId: string;
  type: EmbeddedFileTypeEnum;
  src: string;
  url: string;
  width?: number;
  height?: number;
}
export interface UploadedFileApiResponse {
  data?: UploadedFile;
  statusCode?: HttpStatusCode;
  succeeded?: boolean;
}
