import { Injectable } from '@angular/core';
import { ChatLessonMemberPublishingStateValue } from '@ezteach/api/models/chat-lesson-member';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { ChatLessonMemberPublishingState } from '@ezteach/group-lesson/models/chat-lesson-member-publishing-state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLessonPublishingStateService {
  membersState$ = new BehaviorSubject<ChatLessonMemberPublishingState[]>([]);

  getMemberState(memberId: number): ChatLessonMemberPublishingStateValue[] {
    const state = this.membersState$.value.find(x => x.memberId === memberId)?.currentValue;
    if (!state || state.length === 0) {
      return [{ name: ChatLessonMemberPublishingStateEnum.None, arg: '' }];
    }
    return state;
  }

  setMemberState(memberState: ChatLessonMemberPublishingState) {
    const currentState = this.membersState$.value;
    const index = this.membersState$.value.findIndex(x => x.memberId === memberState.memberId);
    if (index !== -1) {
      currentState.splice(index, 1);
    }
    currentState.push(memberState);
    this.membersState$.next([...currentState]);
  }

  setMembersState(membersState: ChatLessonMemberPublishingState[]) {
    this.membersState$.next(membersState);
  }

  addOrUpdateMemberPublishingStateService(state: {
    memberId: number;
    type: ChatLessonMemberPublishingStateEnum;
    enabled: boolean;
    arg?: string;
  }) {
    const memberState = this.membersState$.value.find(x => x.memberId === state.memberId);
    if (memberState) {
      const index = memberState.currentValue.findIndex(x => x.name === state.type);
      if (index === -1 && state.enabled) {
        memberState.currentValue.push({ name: state.type, arg: state.arg });
      } else {
        if (index !== -1 && !state.enabled) {
          memberState.currentValue.splice(index, 1);
        }
      }
      // добавляем/удаляем состояние 'None'
      this.addOrDeleteNoneState(memberState);
      this.membersState$.next([...this.membersState$.value]);
    }
  }

  addOrDeleteNoneState(memberState: ChatLessonMemberPublishingState) {
    if (memberState.currentValue.length >= 1) {
      memberState.currentValue = memberState.currentValue.filter(
        ({ name }) => name !== ChatLessonMemberPublishingStateEnum.None,
      );
    } else {
      memberState.currentValue.push({ name: ChatLessonMemberPublishingStateEnum.None, arg: '' });
    }
    return memberState;
  }

  getMembersState() {
    return this.membersState$.value;
  }

  dispose() {
    this.membersState$.next([]);
  }
}
