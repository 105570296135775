import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';

@Component({
  selector: 'modal-lesson-media-constraints',
  templateUrl: 'modal-lesson-media-constraints.component.html',
})
export class ModalLessonMediaConstraints {
  smileyType = SmileyType;
  buttonType = ButtonType;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ModalLessonMediaConstraints>) {}

  close() {
    this.dialogRef.close('close');
  }
}
