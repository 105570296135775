import { DefaultSteps, ITutorialStepConfig, StepType } from '@ezteach/tutorial/models';
import { NoopComponent } from '@ezteach/tutorial/components/common';

export const NOOP_STEPS_LIST: readonly ITutorialStepConfig<DefaultSteps>[] = [
  {
    name: DefaultSteps.NOOP,
    type: StepType.NOOP,
    component: NoopComponent,
    isLast: true,
  },
];
