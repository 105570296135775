import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockItem, BlockСostCurrencyEnum, BlockСostTypeEnum } from '@ezteach/blocks/services/block-api.service';
import { SelectedBlockService } from '@ezteach/blocks/services/selected-block.service';

@Component({
  selector: 'ezteach-block-item',
  templateUrl: './block-item.component.html',
  styleUrls: ['./block-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockItemComponent implements OnInit {
  costCurrency: string;
  costType: string;
  _item: BlockItem | undefined;
  @Input('item') set item(value: BlockItem | undefined) {
    this._item = value;
    this.costCurrency = BlockСostCurrencyEnum[value.costCurrency];
    this.costType = BlockСostTypeEnum[value.costType];
  }

  get item(): BlockItem | undefined {
    return this._item;
  }

  constructor(
    private selectedBlockService: SelectedBlockService,
    private router: Router,
  ) // private _snackBar: MatSnackBar,
  {}

  ngOnInit(): void {}

  selectBlock() {
    if (this.item?.id !== 26812) {
      //this._snackBar.open('Нет доступа к курсу', 'Ок');
      //return;
    }
    this.selectedBlockService.selectedBlock$.next(this.item?.id?.toString());
    this.router.navigate(['/mylessons/blocks'], { queryParams: { blockId: this.item?.id } });
  }

  openBlock() {
    if (this.item?.id !== 26812) {
      //this._snackBar.open('Нет доступа к курсу', 'Ок');
      //return;
    }
    this.selectedBlockService.selectedBlock$.next(this.item?.id?.toString());
    this.router.navigate(['/mylessons/blocks'], { queryParams: { blockId: this.item?.id } });
  }
}
