import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@ezteach/api/models';
import {
  UploadedFile,
  WhiteboardConfiguration,
  WhiteboardMemberAccessRightsEnum,
  WhiteboardSnappingEnum,
} from '@ezteach/api/models/whiteboard/whiteboard-api.model';
import { UsersService } from '@ezteach/api/services';
import { WhiteboardService } from '@ezteach/api/services/whiteboard.service';
import { environment } from '@ezteach/enviroments';
import { HammerKonvaLoaderService } from '@ezteach/shared/services/hammer-konva-loader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ImagePreviewData,
  ObjectCreatedArgs,
  ObjectLockedArgs,
  ObjectUnlockedArgs,
  ObjectUpdatedArgs,
} from 'projects/ng-konva/src/lib/whiteboard/classes/objects-args';
import { ConvaMember, WhiteBoardMode } from 'projects/ng-konva/src/lib/whiteboard/classes/white-board';
import { WhiteBoardConvertors } from 'projects/ng-konva/src/lib/whiteboard/convertors/shape.convertors';
import { WhiteBoardFileService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-file.service';
import { WhiteBoardSceneService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-scene.service';
import { WhiteBoardShapeService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-shape.service';
import { WhiteBoardUserService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-users.service';
import { forkJoin, iif, of, Subscription } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { WhiteboardSignalrService } from './services/whiteboard-signalr.service';
import { UserService } from "@ezteach/_services/user.service";

@UntilDestroy()
@Component({
  selector: 'ezteach-app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss'],
})
export class WhiteboardAppComponent implements OnInit, AfterViewInit, OnDestroy {
  _parentWidth?: number;
  @Input()
  set parentWidth(width: number) {
    this._parentWidth = width;
  }
  @Input()
  whiteBoardMode: WhiteBoardMode;
  @Input()
  whiteBoardId: string;
  @Input()
  isOwner: boolean;
  @Input() whiteBoardTopOffset: number;

  @Output()
  wbCreated = new EventEmitter<string>();
  @Output()
  onFullSizeButtonClick = new EventEmitter<boolean>();
  wId = '';
  title = '';
  user: any;
  subscriptions = new Subscription();
  whiteBoardModes = WhiteBoardMode;
  isArchived: boolean;
  isUnavailable: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private whiteboardApiService: WhiteboardService,
    private whiteboardSignalrService: WhiteboardSignalrService,
    private whiteBoardUserService: WhiteBoardUserService,
    private whiteBoardShapeService: WhiteBoardShapeService,
    private whiteBoardSceneService: WhiteBoardSceneService,
    private whiteBoardFileService: WhiteBoardFileService,
    private usersService: UsersService,
    private hammerKonvaLoaderService: HammerKonvaLoaderService,
    private userService: UserService
  ) {}
  ngAfterViewInit(): void {
    this.hammerKonvaLoaderService.loadHammerKonvaScript();
  }

  ngOnInit() {
    this.user = this.userService.userData$.value;
    this.isUnavailable = this.user?.id < 0 && this.whiteBoardMode !== this.whiteBoardModes.inside;
    this.wId = this.route.snapshot.queryParams.wId ?? this.whiteBoardId;

    this.initWhiteboard();

    this.whiteBoardUserService.enableFullPageMode$
      .pipe(
        untilDestroyed(this),
        tap(v => this.onFullSizeButtonClick.emit(v)),
      )
      .subscribe();

    this.whiteboardApiService
      .getWhiteboardById(this.wId)
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.title = x.body.data.title;
          this.isArchived = x.body.data.isArchived;
        }),
      )
      .subscribe();
  }

  create() {
    const request: WhiteboardConfiguration = {
      anotherUsersPermission: WhiteboardMemberAccessRightsEnum.Readonly,
      snapping: [WhiteboardSnappingEnum.Grid],
    };

    this.whiteboardApiService.create(request).subscribe(x => {
      this.wId = x.body.data.wId;
      if (this.whiteBoardMode !== this.whiteBoardModes.inside) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { wId: x.body.data.wId },
        });
      } else {
        this.wbCreated.emit(this.wId);
      }
      this.initWhiteboard();
    });
  }

  initWhiteboard() {
    if (this.wId) {
      this.subscribeSignal();
      this.whiteboardApiService
        .join(this.wId)
        .pipe(
          untilDestroyed(this),
          switchMap(x =>
            forkJoin([
              of(x),
              ...x.body.data.members.map(m =>
                this.usersService.apiV1UsersUserIdGet({
                  userId: m.userId,
                  mode: 'public',
                }),
              ),
            ]),
          ),
          tap((x: Array<any>) => {
            const wb = x[0];
            const members = x
              .filter(i => i !== wb)
              .map(m => ({
                wId: this.wId,
                userId: m.data.id,
                model: m.data,
                userName: m.data.name,
                avatarIsGiven: m.data.avatarFileName ? true : false,
                avatarPath:
                  environment.apiUrl +
                  '/api/v1/files/usercontent/' +
                  m.data.id +
                  '/user-avatar?redirect=true&file=' +
                  m.data.avatarFileName,
                isOwner: m.data.isTutor,
                accessRights: '',
              }));

            this.whiteBoardUserService.initUsers(members);

            this.isOwner = wb.body.data.members.find(x => x.userId === +this.user.id)?.isOwner;
          }),
        )
        .subscribe();
    }
  }

  public initOrCreateWhiteBoard(whiteBoardId: string) {
    console.log('initOrCreateWhiteBoard');
    if (this.wId) {
      return;
    }
    if (!whiteBoardId) {
      this.create();
    } else {
      this.wId = whiteBoardId;
      this.initWhiteboard();
    }
  }

  getUserInitials(user: User) {
    return user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
  }

  onMousePositionChanged($event) {
    this.whiteboardSignalrService.myMouseMoved({
      wid: this.wId,
      x: $event.x,
      y: $event.y,
    });
  }

  subscribeSignal() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.subscriptions = new Subscription();
    this.whiteboardSignalrService.connect(this.wId);

    const onMouseMoved = this.whiteboardSignalrService.onMouseMoved$
      .pipe(
        untilDestroyed(this),
        filter(x => x.memberId !== +this.user.id),
        tap(x => {
          this.whiteBoardUserService.updateMemberMouse(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onMouseMoved);

    const onWhiteboardJoined = this.whiteboardSignalrService.onWhiteboardJoined$
      .pipe(
        untilDestroyed(this),
        switchMap(x =>
          forkJoin([
            of(x),
            this.usersService.apiV1UsersUserIdGet({
              userId: x.member.userId,
              mode: 'public',
            }),
          ]),
        ),
        tap(([_, m]) => {
          const member = {
            wId: this.wId,
            userId: m.data.id,
            model: m.data,
            userName: m.data.name,
            avatarIsGiven: m.data.avatarFileName ? true : false,
            avatarPath:
              environment.apiUrl +
              '/api/v1/files/usercontent/' +
              m.data.id +
              '/user-avatar?redirect=true&file=' +
              m.data.avatarFileName,
            isOwner: true,
            accessRights: '',
          } as ConvaMember;
          this.whiteBoardUserService.addUser(member);
        }),
      )
      .subscribe();

    this.subscriptions.add(onWhiteboardJoined);

    const onWhiteboardLeft = this.whiteboardSignalrService.onWhiteboardLeft$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardUserService.removeUser(x.member.userId);
        }),
      )
      .subscribe();
    this.subscriptions.add(onWhiteboardLeft);

    const onWhiteboardArchived = this.whiteboardSignalrService.onWhiteboardArchived$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
          });
          this.wId = undefined;
          this.whiteBoardUserService.clearUsers();
          this.whiteboardSignalrService.closeHubConnection();
          this.subscriptions.unsubscribe();
        }),
      )
      .subscribe();
    this.subscriptions.add(onWhiteboardArchived);

    // Оповещения по веб сокетам
    const objectsCreated = this.whiteBoardShapeService.objectsCreated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const obj = WhiteBoardConvertors.convertShapeToCreateObjectDescription(x);
          const request: ObjectCreatedArgs = {
            wId: this.wId,
            memberId: this.user.id,
            objects: [obj],
          };
          this.whiteboardSignalrService.myObjectsCreated(request);
        }),
      )
      .subscribe();
    this.subscriptions.add(objectsCreated);

    const objectsDeleted = this.whiteBoardShapeService.objectsDeleted$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const request = { oids: x };
          this.whiteboardSignalrService.myObjectsDeleted(request);
        }),
      )
      .subscribe();
    this.subscriptions.add(objectsDeleted);

    const objectsLocked = this.whiteBoardShapeService.objectsLocked$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const request: ObjectLockedArgs = {
            wId: this.wId,
            memberId: this.user.id,
            oids: x,
          };
          this.whiteboardSignalrService.myObjectsLocked(request);
        }),
      )
      .subscribe();
    this.subscriptions.add(objectsLocked);

    const objectsUnlocked = this.whiteBoardShapeService.objectsUnlocked$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const request: ObjectUnlockedArgs = {
            wId: this.wId,
            memberId: this.user.id,
            oids: x,
          };
          this.whiteboardSignalrService.myObjectsUnlocked(request);
        }),
      )
      .subscribe();
    this.subscriptions.add(objectsUnlocked);

    const objectsUpdated = this.whiteBoardShapeService.objectsUpdated$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const items = x.map(s => WhiteBoardConvertors.convertShapeToCreateObjectDescription(s));
          const request: ObjectUpdatedArgs = {
            wId: this.wId,
            memberId: this.user.id,
            objects: items,
          };
          this.whiteboardSignalrService.myObjectsUpdated(request);
        }),
      )
      .subscribe();
    this.subscriptions.add(objectsUpdated);

    const onObjectsCreated = this.whiteboardSignalrService.onObjectsCreated$
      .pipe(
        filter(x => x.memberId !== +this.user.id),
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.onObjectsCreated$.next(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onObjectsCreated);

    const onObjectsDeleted = this.whiteboardSignalrService.onObjectsDeleted$
      .pipe(
        filter(x => x.memberId !== +this.user.id),
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.onObjectsDeleted$.next(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onObjectsDeleted);

    const onObjectsLocked = this.whiteboardSignalrService.onObjectsLocked$
      .pipe(
        filter(x => x.memberId !== +this.user.id),
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.addToLocked(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onObjectsLocked);

    const onObjectsUnlocked = this.whiteboardSignalrService.onObjectsUnlocked$
      .pipe(
        filter(x => x.memberId !== +this.user.id),
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.unlockShapes(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onObjectsUnlocked);

    const onObjectsUpdated = this.whiteboardSignalrService.onObjectsUpdated$
      .pipe(
        filter(x => x.memberId !== +this.user.id),
        untilDestroyed(this),
        tap(x => {
          this.whiteBoardShapeService.onObjectsUpdated$.next(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(onObjectsUpdated);

    const whiteboardData = this.whiteboardSignalrService.onWhiteboardData$
      .pipe(
        untilDestroyed(this),
        take(1),
        tap(x => {
          this.whiteBoardSceneService.sceneData$.next(x);
        }),
      )
      .subscribe();
    this.subscriptions.add(whiteboardData);

    this.whiteBoardFileService.addFile$
      .pipe(
        tap(x => {
          this.whiteBoardFileService.fileUploading$.next(true);
        }),
        switchMap(x => this.whiteboardApiService.fileUpload(this.wId, x)),
        map(x => x.body.data),
        mergeMap(x =>
          iif(() => x.type === 'Picture', this.whiteboardApiService.getPreviewUrl(this.wId, x.fileId), of(x)),
        ),
        tap((x: any) => {
          this.whiteBoardFileService.fileUploading$.next(false);
          this.whiteBoardFileService.fileUploaded$.next(x);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoardFileService.download$
      .pipe(
        tap((x: any) => {
          this.whiteboardApiService.fileGet(this.wId, x);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.whiteBoardFileService.loadPreview$
      .pipe(
        mergeMap(x =>
          this.whiteboardApiService
            .getPreviewUrl(this.wId, x.fileId, x.width)
            .pipe(map((p: UploadedFile) => ({ x, p }))),
        ),
        tap(x => {
          const preview: ImagePreviewData = {
            shapeId: x.x.shapeId,
            src: x.p.src,
            fileId: x.p.fileId,
            width: x.p.width,
            height: x.p.height,
          };
          this.whiteBoardFileService.previewLoaded$.next(preview);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.whiteboardSignalrService.closeHubConnection();
  }
}
