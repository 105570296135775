import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export interface SelectOptionType {
  value: any,
  displayValue: string
}

@Component({
  selector: 'app-datepicker-component',
  templateUrl: './datepicker-component.component.html',
  styleUrls: ['./datepicker-component.component.scss'],
})
export class AppDatepickerComponent implements OnInit {
  @Input() placeholder: string = '';
  @Output() onDateChanged = new EventEmitter<Date>();
  @Input() date: Date | null;

  @ViewChild('picker') picker: any;

  constructor() { }

  dateChanged(event: MatDatepickerInputEvent<Date>): void {
    const selectedDate = event.value;
    if (selectedDate) {
      const _date = new Date(selectedDate.toDateString());
      this.date = _date;
      this.onDateChanged.emit(_date);
    }
  }

  openDatePicker(): void {
    this.picker.open()
  }

  clearDate(): void {
    this.picker.datepickerInput.value = null;
    this.date = null;
    this.onDateChanged.emit(null);
  }

  ngOnInit(): void {
  }
}
