import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonValidationStatusEnum } from '@ezteach/api/models/common-validation-status-enum';
import { UserProfile } from '@ezteach/api/models/user-profile';
import { ProfileService } from '@ezteach/profile';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';

@Component({
  selector: 'profile-warning',
  templateUrl: './profile-warning.component.html',
  styleUrls: ['./profile-warning.component.scss'],
})
export class ProfileWarningComponent implements OnChanges {
  @Input() validationStatus: CommonValidationStatusEnum;
  @Input() userProfile: UserProfile;

  succesfulApprovedMessageKey = 'showSuccesfulApprovedMessage';

  showSuccesfulApprovedMessage = false;

  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private profileService: ProfileService,
  ) {}

  readonly CommonValidationStatusEnum = CommonValidationStatusEnum;

  ngOnChanges() {
    if (this.validationStatus === CommonValidationStatusEnum.Approved) {
      this.showSuccesfulApprovedMessage = this.localStorageService.get(this.succesfulApprovedMessageKey) ?? true;
    }
  }

  goToProfile() {
    this.router.navigateByUrl('/profile/specialty');
  }

  close() {
    this.showSuccesfulApprovedMessage = false;
    this.localStorageService.set(this.succesfulApprovedMessageKey, false);
    this.profileService.setAdditionalProperty({ disableValidationNotification: true }).subscribe();
  }
}
