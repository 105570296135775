import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChatRequest } from '@ezteach/api/models';
import { LocalStorageService } from '../_services/local-storage.service';
import { RequestsService } from '../_services/requests.service';

@Component({
  selector: 'requests-tutor',
  templateUrl: './requests-tutor.component.html',
})
export class RequestsTutorComponent implements OnInit {
  requests: ChatRequest[] = [];

  constructor(
    private _cdr: ChangeDetectorRef,
    private requestsService: RequestsService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.requestsService.update();
    this.getResults();

    this.requestsService.onChanged.subscribe(data => {
      this.getResults();
    });
    this._cdr.detectChanges();
  }

  getResults() {
    this.requests = this.localStorageService.get('requests') ?? [];
    this._cdr.detectChanges();
  }
}
