<div
  class="group-lesson-subject"
  *ngIf="lesson"
>
  <div class="discipline">{{lesson?.discipline?.fullName | transloco}}
    <span *ngIf="subject">:</span>
  </div>
  <div
    *ngIf="!edit"
    class="group-lesson-subject-lbl"
    [ngClass]="groupLessonService.role === roles.Member ? 'group-lesson-subject-lbl-readonly' : ''"
    (click)="onEdit()"
  >
    {{subject}}
    <img
      class="group-lesson-subject-edit"
      src="assets/img/svg/group-lesson/subject/edit.svg"
    />
  </div>

  <div
    *ngIf="edit"
    class="group-lesson-subject-lbl"
    [ngClass]="edit ? 'group-lesson-subject-edit-wr' : ''"
  >
    <input
      #inputSubject
      class="group-lesson-subject-input"
      [(ngModel)]="subject"
      maxlength="50"
      (keydown)="handleKey($event)"
    />
    <img
      class="group-lesson-subject-cancel"
      src="assets/img/svg/group-lesson/subject/cancel.svg"
      (click)="onCancel()"
    />
    <img
      class="group-lesson-subject-accept"
      src="assets/img/svg/group-lesson/subject/accept.svg"
      (click)="onApprove()"
    />
  </div>

</div>
