import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { isProfileSaving, saveProfileFinancialDetails, selectFinancialDetails, setProfileTitle } from '../../+state';
import { FinancialDetails, UserProfile } from '../../../api/models';

@UntilDestroy()
@Component({
  selector: 'app-profile-financial',
  templateUrl: './profile-financial.component.html',
  styleUrls: ['./profile-financial.component.scss'],
})
export class ProfileFinancialComponent implements OnInit {
  profile: UserProfile;
  form: UntypedFormGroup;
  saving$: Observable<boolean>;

  title = $localize`Финансовая информация`;
  isReadonlyFields: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) { }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(setProfileTitle({ title: this.title }));
    this.subscribeStore();
  }

  subscribeStore() {
    this.store
      .pipe(
        untilDestroyed(this),
        select(selectFinancialDetails),
        filter(financialDetails => financialDetails),
        take(1),
        tap(financialDetails => {
          this.updateForm(financialDetails);
          this.isReadonlyFields = false;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving), tap(isProfileSaving => {
      if (this.isReadonlyFields && !isProfileSaving) {
        this.isReadonlyFields = false;
      }
    }));
  }

  createForm() {
    this.form = this.formBuilder.group({
      inn: [''],
      bankDetails: this.formBuilder.group({
        accountNumber: [''],
        bank: this.formBuilder.group({
          fullName: [''],
          shortName: [''],
          correspondentAccountNumber: [''],
          bic: [''],
          swift: [''],
          kpp: [''],
          address: [''],
        }),
      }),
    });
  }

  updateForm(data: FinancialDetails) {
    this.form.patchValue(data);
  }

  saveProfile() {
    this.isReadonlyFields = true;
    this.store.dispatch(saveProfileFinancialDetails(this.form.value));
  }
}
