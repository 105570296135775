<div
  class="container"
  [ngClass]="{ chatOpen: chatOpen }"
>
  <div class="modal__header">
    <button
      class="minimize-button"
      [class.narrow-screen]="narrowScreen"
      (click)="minimize()"
    >
      <img src="assets/img/svg/minimize-window-icon.svg" />
      <div>{{ 'скрыть окно' | transloco }} </div>
    </button>
    <div class="timer_wrapper">
      <div
        class="modal__title"
        [class.narrow-screen]="narrowScreen"
      >
        {{ mainHeaderText | transloco}}
      </div>
      <easy-teach-timer
        class="modal__timer"
        [totalSecondsLeft]="totalSecondsLeft"
      ></easy-teach-timer>
    </div>
  </div>

  <div class="content__wrapper">
    <div class="modal__content">
      <div
        class="modal__title modal__title--dark"
        [class.narrow-screen]="narrowScreen"
      >
        {{ titleText | transloco}}
      </div>
      <div
        class="description"
        *ngIf="isMobile"
        >{{'Хотите добавить дополнительное время' | transloco}}?
      </div>
      <div
      class="description"
      *ngIf="!isMobile"
      >{{'Хотите добавить дополнительное время' | transloco }}?
    </div>


      <div
        class="modal__time-btns"
        [class.narrow-screen]="narrowScreen"
      >
        <ezteach-btn
          [type]="buttonType.notFilled"
          [width]="isMobile ? '90%' : '315px'"
          *ngFor="let prolongTime of data.extensionTimeList"
          (click)="extended(prolongTime)"
        >
          <div content>{{ prolongTime }} {{ 'минут' | transloco }} </div>
        </ezteach-btn>
      </div>
      <div class="control__panel">
        <div
          class="action action-red"
          (click)="leaveSession()"
        >
          <img
            class="leaveSession"
            src="assets/img/svg/icon-lesson-stop.svg"
            alt="Leave session"
          />
        </div>
      </div>
    </div>
  </div>
</div>
