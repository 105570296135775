import { Injectable, InjectionToken } from '@angular/core';
import { of } from 'rxjs';
import { ITutorialStepValidator } from '../models';

export const COMMON_VALIDATOR = new InjectionToken<ITutorialStepValidator>('COMMON_VALIDATOR');

@Injectable({ providedIn: 'root' })
export class CommonValidatorService implements ITutorialStepValidator {
  shouldSkipStep() {
    return of(false);
  }
}
