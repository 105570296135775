<div
  [formGroup]="form"
  class="profile_bank"
>
  <div class="profile_content__title">{{ title }}</div>
  <mat-form-field class="profile_content__input">
    <mat-label> {{ 'ИНН' | transloco }} </mat-label>
    <input
      matInput
      maxlength="15"
      formControlName="inn"
      readonly="{{ isReadonlyFields }}"
    />
  </mat-form-field>
  <hr />
  <div class="profile_content__title">{{ 'Данные банковского счета' | transloco }}</div>
  <div formGroupName="bankDetails">
    <mat-form-field class="profile_content__input">
      <mat-label> {{ 'Номер счета' | transloco }}</mat-label>
      <input
        matInput
        maxlength="32"
        formControlName="accountNumber"
        readonly="{{ isReadonlyFields }}"
      />
    </mat-form-field>
    <div formGroupName="bank">
      <div class="profile_content__title">{{ 'Информация о банке' | transloco }}</div>
      <div class="profile_content__cols">
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label> {{ 'Полное название' | transloco }} </mat-label>
            <input
              matInput
              maxlength="50"
              formControlName="fullName"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label> {{ 'Краткое название' | transloco }} </mat-label>
            <input
              matInput
              maxlength="50"
              formControlName="shortName"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="profile_content__cols">
        <mat-form-field class="profile_content__input">
          <mat-label> {{ 'Корр. счет' | transloco }} </mat-label>
          <input
            matInput
            formControlName="correspondentAccountNumber"
            readonly="{{ isReadonlyFields }}"
          />
        </mat-form-field>
      </div>
      <div class="profile_content__cols">
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label> {{ 'БИК' | transloco }} </mat-label>
            <input
              matInput
              maxlength="15"
              formControlName="bic"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label i18n>SWIFT</mat-label>
            <input
              matInput
              maxlength="15"
              formControlName="swift"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="profile_content__cols">
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label> {{ 'КПП' | transloco }} </mat-label>
            <input
              matInput
              maxlength="15"
              formControlName="kpp"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
        <div class="profile_content__col">
          <mat-form-field class="profile_content__input">
            <mat-label> {{ 'Адрес' | transloco }} </mat-label>
            <input
              matInput
              formControlName="address"
              readonly="{{ isReadonlyFields }}"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="saveProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label"> {{ 'Сохранить изменения' | transloco }} </span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>
