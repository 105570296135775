import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';

/* Helper service responsible for showing tutorial dialogs */
@Injectable({
  providedIn: 'root',
})
export class TutorialDialogService {
  constructor(private readonly matDialog: MatDialog, private readonly overlay: Overlay) {}

  openTutorialDialog(component: ComponentType<any>, data: any = {}) {
    this.matDialog.closeAll();

    return this.matDialog.open(component, {
      width: '100%',
      disableClose: true,
      closeOnNavigation: false,
      panelClass: ['ezteach-tutorial-dialog'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data,
    });
  }
}
