import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { User } from '@ezteach/api/models/user';
import { environment } from '@ezteach/enviroments';
import { PageVisibilityMonitorService } from '@ezteach/shared/services/page-visibility-monitor.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TUI_IS_IOS } from '@taiga-ui/cdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';

export enum DefaultIconType {
  Veteran,
  Newbie,
}

export class NotificationSettings {
  defaultIconType: DefaultIconType;
}

export const SystemUser: User = {
  name: 'Легко учиться',
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GroupLessonNotificationsService {
  notificationGranted$ = new BehaviorSubject<boolean>(false);
  notificationEnabled$ = new BehaviorSubject<boolean>(true);
  notificationAllowed$ = new BehaviorSubject<boolean>(false);
  soundNotificationPath$ = new BehaviorSubject<string>('assets/sounds/notifications/Windows_Balloon.mp3');
  soundNotification$ = new Subject();

  constructor(
    private pageVisibilityMonitorService: PageVisibilityMonitorService,
    private platform: Platform,
    @Inject(TUI_IS_IOS) readonly isIOS: boolean,
  ) {
    this.initNotificationAllowed();
    this.notificationGranted$.pipe(untilDestroyed(this)).subscribe(() => this.initNotificationAllowed());
    this.notificationEnabled$.pipe(untilDestroyed(this)).subscribe(() => this.initNotificationAllowed());
    this.soundNotification$
      .pipe(
        untilDestroyed(this),
        throttleTime(2000),
        filter(() => this.notificationAllowed$.value),
        tap(() => this.playSoundNotification(this.soundNotificationPath$.value)),
      )
      .subscribe();
  }

  initNotificationAllowed() {
    this.notificationAllowed$.next(
      this.notificationEnabled$.value &&
      this.notificationGranted$.value &&
      !this.pageVisibilityMonitorService.pageVisible$.value,
    );
  }

  askNotificationPermission() {
    if (this.checkNotificationPromise()) {
      Notification.requestPermission().then(permission => {
        this.handlePermission(permission);
      });
    } else {
      Notification.requestPermission(permission => this.handlePermission(permission));
    }
  }

  handlePermission(permission: NotificationPermission) {
    // устанавливаем статус permission, на основании ответа
    if (permission === 'denied' || permission === 'default') {
      this.notificationGranted$.next(false);
    } else {
      this.notificationGranted$.next(true);
    }
  }

  //необходимо для проверки обрабатывает браузер запрос нотификаций, с помощью Promise или callback
  checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  }

  toggleNotificationActivity() {
    const currentValue = this.notificationEnabled$.value;
    this.notificationEnabled$.next(!currentValue);
  }

  // параметр header передаем если хотим чтобы в заголовке, вместо имени пользователя отображался конкретный текст
  createNotification(
    member: ChatLessonMember | User,
    body: string,
    notificationOptions: NotificationSettings = null,
    header?: string,
  ) {
    if (!this.notificationAllowed$.value) {
      return;
    }

    const title = header || member.name;
    const iconUrl =
      this.getAvatarUrl(member as ChatLessonMember) ??
      this.getDefaultAvatar(notificationOptions?.defaultIconType ?? DefaultIconType.Newbie);
    const options = this.getNotifyOptions(body, iconUrl);

    // добавляем проверку для мобильных устройств
    if (!this.platform.ANDROID && !this.isIOS) {
      new Notification(title, options);
    }
  }

  getNotifyOptions(body: string, avatarUrl: string): NotificationOptions {
    return {
      body,
      icon: avatarUrl,
      silent: true,
    };
  }

  getAvatarUrl(user: ChatLessonMember): string {
    if (user.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        user.avatarFileName +
        '/user-avatar?redirect=true&file=' +
        user.avatarFileName
      );
    }
    return '';
  }

  private getDefaultAvatar(defaultIconType: DefaultIconType) {
    switch (defaultIconType) {
      case DefaultIconType.Newbie:
        return environment.appUrl + '/assets/img/svg/group-lesson/icon-student_default.png';
      case DefaultIconType.Veteran:
        return environment.appUrl + '/assets/img/svg/group-lesson/icon-tutor_default.png';
    }
  }

  playSoundNotification(soundPath: string): void {
    const sound = new Audio(soundPath);
    sound.play();
  }
}
