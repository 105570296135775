import { createFeatureSelector, createSelector } from '@ngrx/store';
import { propOr } from 'ramda';
import { TutorialTypes, DefaultSteps, TutorialSteps } from '../models';
import * as fromTutorial from './tutorial.reducer';

export const selectTutorialState = createFeatureSelector<fromTutorial.State>(fromTutorial.tutorialFeatureKey);

export const selectTutorialType = createSelector<object, fromTutorial.State, TutorialTypes>(
  selectTutorialState,
  propOr(TutorialTypes.NOOP, 'type'),
);

export const selectTutorialProgress = createSelector<object, fromTutorial.State, TutorialSteps>(
  selectTutorialState,
  propOr(DefaultSteps.NOOP, 'progress'),
);
