import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { VideoViewType } from '../components/group-lesson-header/group-lesson-header.component';

export enum WhiteboardViewState {
  full = 'fullPageWhiteboardView',
  standard = 'standardWhiteboardView',
}

@Injectable({
  providedIn: 'root',
})
export class GroupLessonLayoutMergeService {
  view$ = new Subject<VideoViewType>();
  containerStylesToDefault$ = new Subject<number>();
  layoutView$ = new BehaviorSubject<WhiteboardViewState>(WhiteboardViewState.standard);
  constructor() { }

  updateWhiteboardViewState(state: WhiteboardViewState) {
    this.layoutView$.next(state);
  }
}
