<div
  class="widget_finance"
  [class]="{ 'half-view': isHalfView, 'nav-item': isNavItem }"
>
  <div class="widget_finance__wrap">
    <div class="widget_finance__content">
      <div class="widget_finance__content__alltime">
        <div class="widget_finance__title">{{ 'Мои финансы' | transloco }}</div>
        <div class="widget_finance__subtitle">
          {{ userdata.isTutor ? ('Доходы за все время' | transloco) : ('Расходы за все время' | transloco) }}
        </div>
        <div class="widget_finance__total">
          <div *ngIf="total$ | async as totalAmount">
            {{ totalAmount | currency: 'RUB ' }}
          </div>
        </div>
      </div>
      <div class="widget_finance__content__stat">
        <ng-template #mobileContainer>
          <div class="widget_finance__content__stat__title">
            <div class="widget_finance__content__stat__title-left-side-text">
              {{ userdata.isTutor ? ( 'Зачисления' | transloco ) : ( 'Расходы' | transloco) }}
            </div>
            <div class="widget_finance__content__stat__title-left-side-date">
              <div class="widget_finance__content__stat__title-left-side-date-lbl">{{ 'Месяц и год' | transloco }}</div>
              <mat-form-field class="widget_finance__content__stat__title-left-side-date-value">
                <mat-select
                  [(ngModel)]="selectedDate"
                  (selectionChange)="dateChange($event)"
                >
                  <mat-option
                    *ngFor="let option of date"
                    [value]="option"
                  >{{ option | date : 'LLL yyyy' }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="widget_finance__content__stat__title-right-side-sum">
              {{ monthlyTotal | currency: 'RUB ' }}
            </div>
            <div class="widget_finance__content__stat__title-right-side-lbls">
              <div class="widget_finance__content__stat__title-right-side-lbls-item">
                <div class="widget_finance__content__stat__title-right-side-lbls-item-text">{{ 'Ежедневный показатель' |
                  transloco }}</div>
                <div class="widget_finance__content__stat__title-right-side-lbls-item-everyday-color"></div>
              </div>
              <div class="widget_finance__content__stat__title-right-side-lbls-item">
                <div class="widget_finance__content__stat__title-right-side-lbls-item-text">
                  {{ 'Самый высокий показатель' | transloco }}
                </div>
                <div class="widget_finance__content__stat__title-right-side-lbls-item-max-color"></div>
              </div>
            </div>
          </div>
        </ng-template>
        <div
          class="widget_finance__content__stat__title"
          *ngIf="!isMobile; else mobileContainer"
        >
          <div class="widget_finance__content__stat__title-left-side">
            <div class="widget_finance__content__stat__title-left-side-text">
              {{ userdata.isTutor ? ( 'Зачисления' | transloco ) : ( 'Расходы' | transloco) }}
            </div>
            <div class="widget_finance__content__stat__title-left-side-date">
              <div class="widget_finance__content__stat__title-left-side-date-lbl">{{ 'Месяц и год' | transloco }}</div>
              <mat-form-field class="widget_finance__content__stat__title-left-side-date-value">
                <mat-select
                  [(ngModel)]="selectedDate"
                  (selectionChange)="dateChange($event)"
                >
                  <mat-option
                    *ngFor="let option of date"
                    [value]="option"
                  >{{ option | date: 'LLL yyyy' }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="widget_finance__content__stat__title-right-side">
            <div class="widget_finance__content__stat__title-right-side-sum">
              {{ monthlyTotal | currency: 'RUB ' }}
            </div>
            <div class="widget_finance__content__stat__title-right-side-lbls">
              <div class="widget_finance__content__stat__title-right-side-lbls-item">
                <div class="widget_finance__content__stat__title-right-side-lbls-item-text">{{ 'Ежедневный показатель' |
                  transloco }}</div>
                <div class="widget_finance__content__stat__title-right-side-lbls-item-everyday-color"></div>
              </div>
              <div class="widget_finance__content__stat__title-right-side-lbls-item">
                <div class="widget_finance__content__stat__title-right-side-lbls-item-text">
                  {{ 'Самый высокий показатель' | transloco }}
                </div>
                <div class="widget_finance__content__stat__title-right-side-lbls-item-max-color"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="widget_finance__content__stat__panel">
          <div style="overflow: scroll; padding-top: 15px">
            <tui-axes
              class="axes"
              axisY="none"
              [axisXLabels]="axisXLabels"
              [axisYLabels]="axisYLabels"
              [horizontalLines]="4"
              [verticalLines]="0"
            >
              <tui-bar-chart
                class="chart"
                size="l"
                [value]="value"
                [hintContent]="hint"
                [max]="maxChartValue"
                [collapsed]="true"
              ></tui-bar-chart>
            </tui-axes>

            <ng-template
              #hint
              let-index
            >
              <div class="fin-toolip">
                <div class="fin-toolip__amount">{{ getAmount(index) | currency: 'RUB ' }}</div>
                <div class="fin-toolip__date">{{ getDate(index) | moment: 'MMMM' | transloco }} {{ getDate(index) |
                  moment: 'DD, YYYY' }}</div>
              </div>
            </ng-template>
          </div>
        </div>

        <!--          <div class="widget_finance__content__stat__panel">-->
        <!--            <div class="widget_finance__content__stat__panel__controls">-->
        <!--              <div class="widget_finance__content__stat__panel__controls__lbl">Месяц и год</div>-->
        <!--              <mat-form-field style="width: 130px;" appearance="none">-->
        <!--                <mat-select [(ngModel)]='selectedDate' (selectionChange)="dateChange($event)">-->
        <!--                  <mat-option *ngFor='let option of dates' [value]="option">{{option | date: 'MMM yyyy'}}</mat-option>-->
        <!--                </mat-select>-->
        <!--              </mat-form-field>-->
        <!--            </div>-->
        <!--            <div class="widget_finance__content__stat__panel__lbls">-->
        <!--              <div class="widget_finance__content__stat__panel__lbls__amount">-->
        <!--                {{ monthlyTotal  | currency: 'RUB' }}-->
        <!--              </div>-->
        <!--              <div class="widget_finance__content__stat__panel__lbls__axeslbl">-->
        <!--                <div class="widget_finance__content__stat__panel__lbls__axeslbl__text">-->
        <!--                  Ежедневный показатель-->
        <!--                </div>-->
        <!--                <div class="widget_finance__content__stat__panel__lbls__axeslbl__day">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="widget_finance__content__stat__panel__lbls__axeslbl">-->
        <!--                <div class="widget_finance__content__stat__panel__lbls__axeslbl__text">-->
        <!--                  Самый высокий показатель-->
        <!--                </div>-->
        <!--                <div class="widget_finance__content__stat__panel__lbls__axeslbl__max">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div style="overflow: scroll;padding-top: 5px;">-->
        <!--            <tui-root>-->
        <!--              <tui-axes-->
        <!--                  class="axes"-->
        <!--                  axisY="none"-->
        <!--                  [axisXLabels]="axisXLabels"-->
        <!--                  [axisYLabels]="axisYLabels"-->
        <!--                  [horizontalLines]="4"-->
        <!--                  [verticalLines]="0"-->
        <!--              >-->
        <!--                  <tui-bar-chart-->
        <!--                      class="chart"-->
        <!--                      size='l'-->
        <!--                      [value]="value"-->
        <!--                      [hintContent]="hint"-->
        <!--                      [max]="maxChartValue"-->
        <!--                      [collapsed]="true"-->
        <!--                  ></tui-bar-chart>-->
        <!--              </tui-axes>-->

        <!--              <ng-template #hint let-index>-->
        <!--                  <div class="fin-toolip" >-->
        <!--                     <div class="fin-toolip__amount">{{ getAmount(index) | currency: 'RUB' }}</div>-->
        <!--                     <div class="fin-toolip__date">{{ getDate(index) | date: 'MMMM d, EEEE' }}</div>-->
        <!--                  </div>-->
        <!--              </ng-template>-->

        <!--              </tui-root>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</div>
