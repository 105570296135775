import { Component, OnInit } from '@angular/core';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-unavailable-camera',
  templateUrl: './unavailable-camera.component.html',
})
export class ModalUnavailableCamera implements OnInit {
  isCameraAvailable: boolean;
  smileyType = SmileyType;
  buttonType = ButtonType;

  constructor(private openViduService: OpenViduService, public dialogRef: MatDialogRef<ModalUnavailableCamera>) {}

  ngOnInit(): void {
    this.openViduService.mediaDevicesAvailable$.subscribe(v => (this.isCameraAvailable = v));
  }

  close() {
    this.dialogRef.close('close');
  }
}
