import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ezteachShapeColor]'
})
export class ShapeColorDirective implements OnChanges {
  @Input()
  selected: boolean
  div: HTMLElement;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
    ) { }
  
  ngOnChanges(changes: SimpleChanges): void {
    const { selected } = changes;
    if (selected?.currentValue === true) {
      this.renderer.addClass(this.elementRef.nativeElement , 'ez-selected-color');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement , 'ez-selected-color');
    }
  }

}
