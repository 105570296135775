import { Pipe, PipeTransform } from '@angular/core';
import { getOrElse } from 'fp-ts/lib/Either';
import { Validation } from '@ezteach/utils';

const alwaysNull = () => null;

@Pipe({
  name: 'fold',
})
export class FoldPipe implements PipeTransform {
  transform<T = any>(value: Validation<T>): T {
    console.log(value);
    return getOrElse(alwaysNull)(value);
  }
}
