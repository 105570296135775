import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from "@ezteach/_services/user.service";
import { LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileDocumentIEnumerableApiResponse, UserProfile } from '../api/models';
import { loadProfile, selectPersonalTitle, selectProfile, updateUserAvatar } from './+state';
import { ProfileService } from './profile.service';
import { ProfileLocalStorageService } from './services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: any;
  profile: UserProfile;
  title: string;
  title$: Observable<string>;
  lang: string;
  environment = environment;

  constructor(
    private profileService: ProfileService,
    private profileLocalStorageService: ProfileLocalStorageService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private langStoreService: LangStoreService
  ) { }

  ngOnInit() {
    this.environment = environment;
    this.user = this.userService.userData$.value;
    this.profileLocalStorageService.localStorageUser$.next(this.user);
    this.profileService.user.next(this.user);

    this.store.dispatch(loadProfile());

    this.lang = this.langStoreService.getCurrentLang();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectPersonalTitle),
        tap(title => {
          this.title = title;
          this.cd.detectChanges();
        }),
      )
      .subscribe();

    this.store
      .pipe(
        untilDestroyed(this),
        select(selectProfile),
        filter(profile => !!profile),
        tap(profile => {
          this.profile = profile;
          this.cd.detectChanges();
        }),
      )
      .subscribe();
  }

  avatarChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.profileService
        .uploadUserAvatar({
          file: event.target.files[0]
        })
        .subscribe((request: FileDocumentIEnumerableApiResponse) => {
          if (request.data) {
            const userAvatar = {
              ...this.profile.userAvatar,
              avatarFileName: request.data[0].fileName,
            };
            this.store.dispatch(updateUserAvatar(userAvatar));
          }
        });
    }
  }
}
