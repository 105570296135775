<mat-form-field [ngClass]="cssClass">
  <mat-label>{{ label }}</mat-label>
  <mat-date-range-input
    [rangePicker]="picker"
    [min]="min"
    [max]="max"
  >
    <input
      matInput
      (change)="validateValue($event, dateStart, 0)"
      [ngModel]="value[0] | date: 'dd.MM.yyyy'"
      mask="00.00.0000"
      [dropSpecialCharacters]="false"
      [placeholder]="placeholderStart"
      [disabled]="disabled"
      #dateStart
    />
    <input
      matStartDate
      (ngModelChange)="updateValue($event, 0)"
      [ngModel]="value[0]"
      style="position: absolute; z-index: -1; opacity: 0"
    />

    <input
      matInput
      (change)="validateValue($event, dateEnd, 1)"
      [ngModel]="value[1] | date: 'dd.MM.yyyy'"
      mask="00.00.0000"
      [dropSpecialCharacters]="false"
      [placeholder]="placeholderStart"
      [disabled]="disabled"
      #dateEnd
    />
    <input
      matEndDate
      (ngModelChange)="updateValue($event, 1)"
      [ngModel]="value[1]"
      style="position: absolute; z-index: -1; opacity: 0"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle
    matSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
