import { Pipe, PipeTransform } from '@angular/core';
import { Specialization } from '@ezteach/api/models';
import { Langs, LangStoreService } from '@ezteach/shared/services/lang-store.service';

@Pipe({
  name: 'filterSpecializationNames'
})
export class FilterSpecializationNamesPipe implements PipeTransform {
  constructor(private langStoreService: LangStoreService) {}
  transform(value: Specialization[]): Specialization[] {
    if(this.langStoreService.getCurrentLang() === Langs.EN ||
    this.langStoreService.getCurrentLang() === Langs.KK)
   {
    //Фильтруем без ЕГЭ, ОГЭ, Олимпиады
     value = value.filter(x =>  x.id !== 20 && 
                                x.id !== 21 && 
                                x.id !== 22);
   }
   return value;
  }

}
