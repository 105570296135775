<div class="active-lesson-item">
  <div class="active-lesson-item__content">
    <div class="active-lesson-item__content__icon">
      <div
        *ngIf="lesson.lessonStatusId === chatLessonStatusEnum.Started"
        class="active-lesson-item__content__icon__status"
      >
        <svg-icon-sprite
          [src]="'icon-alert'"
          [width]="'22px'"
          [height]="'22px'"
          [viewBox]="'0 0 22 22'"
        ></svg-icon-sprite>
      </div>
      <span class="active-lesson-item__content__icon__text">
        {{ disciplineNameShort }}
      </span>
    </div>
    <div class="active-lesson-item__content__info">
      <div class="active-lesson-item__content__info__name">{{ disciplineName }}</div>
      <div class="active-lesson-item__content__info__subname"></div>
      <div class="lesson_item__info">
        <div
          class="lesson_item__info_icon"
          *ngIf="lesson.lessonStatusId !== chatLessonStatusEnum.Started"
        >
          <svg-icon-sprite
            [src]="'icon-date'"
            [width]="'20px'"
            [height]="'20px'"
            [viewBox]="'0 0 20 20'"
          ></svg-icon-sprite>
        </div>
        <div
          class="active-lesson-item__content__info__go"
          *ngIf="lesson.lessonStatusId === chatLessonStatusEnum.Started"
        >
          <img
            class="active-lesson-item__content__info__go__img"
            src="/assets/img/svg/go.svg"
          />
        </div>
        <ng-container *ngIf="lesson.lessonStatusId === chatLessonStatusEnum.Started">
          <a
            class="active-lesson-item__content__info__go-link-wrap"
            [href]="linkLesson"
          >
            <div
              class="active-lesson-item__content__info__date active-lesson-item__content__info__go-link"
              [innerHtml]="lesson | lessonDateTime"
            ></div>
          </a>
        </ng-container>
        <ng-container *ngIf="lesson.lessonStatusId !== chatLessonStatusEnum.Started">
          <div
            class="active-lesson-item__content__info__date"
            [innerHtml]="lesson | lessonDateTime"
          ></div>
        </ng-container>
      </div>
      <div class="lesson_item__info">
        <div class="lesson_item__info_icon active-lesson-item__content__info__payment">
          <svg-icon-sprite
            [src]="'icon-card'"
            [width]="'20px'"
            [height]="'20px'"
            [viewBox]="'0 0 20 20'"
          ></svg-icon-sprite>
        </div>
        <div class="lesson_item__info_name">
          {{ lesson | lessonPaymentInfo }}
        </div>
      </div>
      <div class="lesson_item__info">
        <a
          routerLink="/teachers/{{ (lesson | findTutorUser)?.id }}"
          class="lesson_item__info"
        >
          <div class="lesson_item__info_icon">
            <svg-icon-sprite
              [src]="'icon-person'"
              [width]="'20px'"
              [height]="'20px'"
              [viewBox]="'0 0 20 20'"
            ></svg-icon-sprite>
          </div>
          <div class="lesson_item__info_name">
            {{ (lesson | findTutorUser)?.name }}
          </div>
        </a>
      </div>
      <div class="lesson_item__info">
        <div
          class="active-lesson-item__content__info__link"
          *ngIf="lesson.lessonStatusId === chatLessonStatusEnum.Started; else startLesson"
        >
          <shorten-link
            [link]="linkLesson"
            class="link-block"
          ></shorten-link>
        </div>
        <ng-template #startLesson>
          <div class="btn-row-wrapper">
            <ezteach-btn
              #editBtn
              (click)="isScheduled ? cancelLesson(lesson.id) : openEditMeetingPopup()"
              [type]="buttonType.filled"
              [width]="isMobile ? '100%' : '150px'"
              [height]="'35px'"
            >
              <div content>{{ isScheduled ? 'Отменить' : ('Редактировать' | transloco) }}</div>
            </ezteach-btn>
            <ezteach-btn
              (click)="initiateLesson()"
              [type]="buttonType.filled"
              [width]="isMobile ? '100%' : '100px'"
              [height]="'35px'"
            >
              <div content>{{ 'Перейти' | transloco }}</div>
            </ezteach-btn>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="active-lesson-item__icon-type">
    <svg-icon-sprite
      *ngIf="lesson.lessonFormatId === lessonFormatEnum.Group"
      [src]="'icon-lesson-group'"
      [width]="'32px'"
      [height]="'32px'"
      [viewBox]="'0 0 32 32'"
    ></svg-icon-sprite>
    <svg-icon-sprite
      *ngIf="lesson.lessonFormatId === lessonFormatEnum.Scheduled"
      [src]="'icon-lesson-one'"
      [width]="'30px'"
      [height]="'30px'"
      [viewBox]="'0 0 30 30'"
    ></svg-icon-sprite>
  </div>
</div>
