<a
  class="linkfull"
  routerLink="/teachers/{{ item.id }}"
></a>
<div class="info">
  <div class="image">
    <img
      *ngIf="image(item)"
      src="{{ image(item) }}"
    />
    <span *ngIf="!image(item)">{{ initials(item) }}</span>
    <div
      class="status"
      [ngClass]="{ online: item.isOnline }"
    ></div>
  </div>
  <!-- <div class="rating" *ngIf="item.tutor.rating" [ngClass]="{green: item.tutor.rating > 4, blue: item.tutor.rating === 4, yellow: item.tutor.rating < 4}">
		{{item.tutor.rating}}
	</div> -->
  <div class="lessons">
    {{ item.tutor.chatLessonsAccomplished }}
    <span
      appPlural
      [value]="item.tutor.chatLessonsAccomplished"
      [arVariants]="['событие', 'события', 'событий']"
    ></span>
  </div>
</div>
<div
  class="content"
  [ngClass]="{ bookmarked: marked }"
>
  <div
    class="bookmark"
    (click)="bookmark($event)"
    *ngIf="userdata.isStudent"
  >
    <svg-icon-sprite
      [src]="'bookmark'"
      [width]="'29px'"
      [height]="'42px'"
      [viewBox]="'0 0 29 42'"
    ></svg-icon-sprite>
  </div>
  <div class="content__head">
    <div class="name">{{ item.name }}</div>
    <div
      class="diplom"
      *ngIf="getDiplom(item) !== ''"
    >
      <div class="diplom__icon">
        <svg-icon-sprite
          [src]="'icon-diplom'"
          [width]="'25px'"
          [height]="'25px'"
          [viewBox]="'0 0 25 25'"
        ></svg-icon-sprite>
      </div>
      <div class="diplom__name">
        {{ getDiplom(item) }}
      </div>
    </div>
    <div
      class="disciplines"
      *ngIf="getDisciplines(item.tutor) !== ''"
    >
      <div class="disciplines__icon">
        <svg-icon-sprite
          [src]="'icon-desciplines'"
          [width]="'19px'"
          [height]="'17px'"
          [viewBox]="'0 0 19 17'"
        ></svg-icon-sprite>
      </div>
      <div class="disciplines__name">
        {{ getDisciplines(item.tutor) }}
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="actions__left">
      <div class="price">
        <div
          class="price__title"
          *ngIf="type === 'bookmark'"
        >
          {{'Стоимость' | transloco }}({{ '5 минут / классический' | transloco }})
        </div>
        <div
          class="price__title"
          *ngIf="type === 'regular'"
        >
          {{ 'Стоимость в час' | transloco }}:
        </div>
        <div
          class="price__title"
          *ngIf="type === 'instant'"
        >
          {{'Стоимость события' | transloco }}:
        </div>
        <div
          class="price__value"
          *ngIf="type === 'bookmark'"
        >
          {{ item.tutor.instantLesson5MinuteRate }} ₽ / {{ item.tutor.scheduledLessonHourlyRate }} ₽
        </div>

        <div
          class="price__value"
          *ngIf="type === 'regular'"
        >
          {{ item.tutor.scheduledLessonHourlyRate }} ₽
        </div>
        <div
          class="price__value"
          *ngIf="type === 'instant'"
        >
          {{ item.tutor.calculatedInstantLessonPrice }} ₽
        </div>
      </div>
    </div>
    <div class="actions__right">
      <a
        routerLink="/teachers/{{ item.id }}/calendar"
        class="btn btn--blue"
        *ngIf="type === 'regular'"
      >
        <span class="btn__label">{{ 'Расписание' | transloco }} </span>
      </a>
      <a
        class="btn btn--blue"
        *ngIf="type === 'instant' && !requestsService.sending$.value"
        (click)="sendRequestInstant(item)"
      >
        <span class="btn__label">{{ 'Запрос на событие' | transloco }}</span>
      </a>
      <a
        class="btn btn--green"
        *ngIf="type === 'instant' && requestsService.sending$.value"
      >
        <span class="btn__label">{{ 'Запрос отправлен' | transloco }}</span>
      </a>
    </div>
  </div>
</div>
