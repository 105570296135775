import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appFixedPosition]'
})
export class FixedPositionDirective implements OnInit {
    @Input('appFixedPosition') targetElement: any;
    @Output() onClickOutside = new EventEmitter<void>();

    private clickedInside = false;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        this.updatePosition();
    }

    @HostListener('window:scroll', ['$event'])
    @HostListener('window:resize', ['$event'])
    onWindowEvent() {
        this.updatePosition();
    }

    @HostListener('document:mousedown', ['$event'])
    onMouseDown(event: Event) {
        this.clickedInside = this.isClickInside(event);
    }

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        if (!this.clickedInside) {
            this.onClickOutside.emit();
            this.el.nativeElement.style.position = undefined;
            this.el.nativeElement.style.top = undefined;
            this.el.nativeElement.style.left = undefined;
        }
        this.clickedInside = false;
    }

    private isClickInside(event: Event): boolean {
        const target = event.target as HTMLElement;
        return this.el.nativeElement.contains(target) ||
            this.targetElement.contains(target) ||
            this.isChildOf(this.el.nativeElement, target) || document.getElementsByClassName('cdk-overlay-backdrop')[0];
    }

    private isChildOf(parent: any, child: any): boolean {
        let node = child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    private updatePosition() {
        if (this.targetElement) {
            const rect = this.targetElement.getBoundingClientRect();
            const marginTop = (this.targetElement?.clientHeight + 8) || 0;
            this.el.nativeElement.style.position = 'fixed';
            this.el.nativeElement.style.top = `${rect.top + marginTop}px`;
            this.el.nativeElement.style.left = `${rect.left}px`;
        }
    }
}
